import { css } from 'styled-components';
import { em, rem } from 'polished';
import media from './media';

// Tscale 1 : mobile/tablet
// Tscale 2: desktop
function calulateFontScaleSize(
  desktopScale2,
  lineHeightScale2,
  tabletScale1,
  mobileScale1,
  lineHeightScale1
) {
  return css`
    font-size: ${em(desktopScale2)};
    line-height: ${lineHeightScale2} ${media.tablet`
          font-size : ${em(tabletScale1)};
          line-height:${lineHeightScale1}
        `};
    ${media.phone`
          font-size : ${rem(mobileScale1)};
          line-height:${lineHeightScale1}
        `};
  `;
}

// Tscale 1 : mobile/tablet
// Tscale 2: desktop
// calulateFontScaleSize (Desktop-Font-size,Desktop-linehight,Table-font-size,mobile-font-size,Tablet-Mobile-Line-height)
export const FontSize = {
  size1: calulateFontScaleSize(44, 1.15, 33, 33, 1.2),
  size2: calulateFontScaleSize(35, 1.2, 28, 28, 1.3),
  size3: calulateFontScaleSize(28, 1.3, 23, 23, 1.3),
  size4: calulateFontScaleSize(22, 1.4, 19, 19, 1.4),
  size5: calulateFontScaleSize(16, 1.5, 16, 16, 1.5),
  size6: calulateFontScaleSize(14, 1.4, 14, 14, 1.4),
  size7: calulateFontScaleSize(12, 1.3, 12, 12, 1.3)
};

export const FontFamily = {
  RegularFontW01: css`
    font-family: UniversNextforHSBC-Regular;
  `,
  LightFontW01: css`
    font-family: UniversNextforHSBC-Light;
  `,
  BoldFontW01: css`
    font-family: UniversNextforHSBC-Bold;
  `,
  ThinFontW01: css`
    font-family: UniversNextforHSBC-Thin;
  `,
  MediumFontW01: css`
    font-family: UniversNextforHSBC-Medium;
  `
};

function calulateFonts(desktop, tablet, mobile) {
  return css`
    font-size: ${em(desktop)};
    ${media.tablet`
          font-size : ${em(tablet)};
        `};
    ${media.phone`
          font-size : ${em(mobile)};
        `};
  `;
}
export const Fonts = {
  t48: calulateFonts(48, 42, 42),
  t46: calulateFonts(46, 42, 42),
  t38: calulateFonts(38, 32, 32),
  t28: calulateFonts(28, 22, 20),
  t22: calulateFonts(22, 20, 20),
  t20: calulateFonts(20, 17, 17),
  t18: calulateFonts(18, 18, 16),
  t16: calulateFonts(16, 16, 14),
  t14: calulateFonts(14, 14, 14),
  t12: calulateFonts(12, 12, 12)
};

export const fontSize1 = css`
  ${FontSize.size1}
`;
export const fontSize2 = css`
  ${FontSize.size2}
`;
export const fontSize3 = css`
  ${FontSize.size3}
`;
export const fontSize4 = css`
  ${FontSize.size4}
`;
export const fontSize5 = css`
  ${FontSize.size5}
`;
export const fontSize6 = css`
  ${FontSize.size6}
`;
export const fontSize7 = css`
  ${FontSize.size7}
`;
