import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {
  SectionLink,
  SectionLabel,
  SectionIconWrapper,
  ImagePath,
  SectionIconRightWrapper,
  InstructionHistoryWrapper
} from '../detailPageStyle';
import Context from '../../app/Context';
import { trackEvents } from '../../utils/tealiumUtils';
import { isServicngOptionApplicableToDemise } from '../sectionUtils/SectionsUtils';

const QuickLinks = function QuickLinks(props) {
  const {
    id,
    ariaLabel,
    iconSrc,
    linkLabel,
    iconRight,
    linkSrc,
    tealiumHeaderContent,
    target,
    noMarginBottom,
    serviceType,
    encodePolicyNumber,
    isServiceUnavailable,
    updateModalState,
    tealiumSectionHeading,
    isTransferFundInstruction,
    setIconWidthHeight,
    updatePaymentInstructionModal,
    isGIPolicy,
    isLifePolicy,
    setLinkVisited
  } = props;

  const digiServicingOptions = ['TransferOfFundsPIB', 'TransferOfFundsP2G'];
  const callTealiumEventForServicing = function callTealiumEventForServicing() {
    trackEvents({
      eventName: 'digiServiceLinks',
      eventContent: `${tealiumSectionHeading}: transfer of funds`
    });
  };

  const isLinkNotEligibleP2GDemise = isServicngOptionApplicableToDemise(
    isLifePolicy,
    id
  );

  const quickLinkSectionWrapper = () => (
    <>
      <SectionIconWrapper setIconWidthHeight={setIconWidthHeight}>
        <ImagePath aria-hidden="true" alt="" src={iconSrc} />
      </SectionIconWrapper>
      <SectionLabel>{linkLabel}</SectionLabel>
      <SectionIconRightWrapper>
        <ImagePath aria-hidden="true" alt="" src={iconRight} />
      </SectionIconRightWrapper>
    </>
  );
  if (serviceType === 'instructionHistory') {
    return (
      <Context.Consumer>
        {contextProvider => {
          const {
            applicationUrl: { forLink }
          } = contextProvider;
          const { instructionhistory } = forLink || {};
          return (
            <InstructionHistoryWrapper noMarginBottom={noMarginBottom}>
              <Link
                to={`${instructionhistory}${encodePolicyNumber}`}
                id={id}
                aria-label={ariaLabel}
                onClick={() =>
                  trackEvents({
                    eventName: 'quickLinks',
                    eventContent: `${tealiumHeaderContent}:${id.toLowerCase()}`
                  })
                }
              >
                {quickLinkSectionWrapper()}
              </Link>
            </InstructionHistoryWrapper>
          );
        }}
      </Context.Consumer>
    );
  }
  return (
    <>
      <SectionLink
        href={
          (isServiceUnavailable && isTransferFundInstruction) ||
          isLinkNotEligibleP2GDemise
            ? '#'
            : linkSrc
        }
        id={id}
        aria-label={ariaLabel}
        rel="noopener noreferrer"
        target={target}
        onClick={e => {
          _.includes(digiServicingOptions, id) &&
          isServiceUnavailable &&
          isTransferFundInstruction
            ? callTealiumEventForServicing()
            : trackEvents({
                eventName: 'quickLinks',
                eventContent: `${tealiumHeaderContent}:${id.toLowerCase()}`
              });
          isServiceUnavailable &&
            isTransferFundInstruction &&
            updateModalState(isModalOpen => !isModalOpen);
          setLinkVisited(id);
          if (
            isServicngOptionApplicableToDemise(isGIPolicy, id) ||
            isLinkNotEligibleP2GDemise
          ) {
            e.preventDefault();
            updatePaymentInstructionModal();
          }
        }}
        noMarginBottom={noMarginBottom}
      >
        {quickLinkSectionWrapper()}
      </SectionLink>
    </>
  );
};

export default QuickLinks;

QuickLinks.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  iconRight: PropTypes.string.isRequired,
  linkSrc: PropTypes.string.isRequired,
  tealiumHeaderContent: PropTypes.string.isRequired,
  target: PropTypes.string,
  noMarginBottom: PropTypes.bool.isRequired,
  serviceType: PropTypes.string,
  encodePolicyNumber: PropTypes.string.isRequired,
  isServiceUnavailable: PropTypes.bool.isRequired,
  updateModalState: PropTypes.func.isRequired,
  tealiumSectionHeading: PropTypes.string.isRequired,
  isTransferFundInstruction: PropTypes.bool,
  setIconWidthHeight: PropTypes.bool,
  updatePaymentInstructionModal: PropTypes.func.isRequired,
  isGIPolicy: PropTypes.bool.isRequired,
  isLifePolicy: PropTypes.bool.isRequired,
  setLinkVisited: PropTypes.func.isRequired
};

QuickLinks.defaultProps = {
  target: '_self',
  serviceType: '',
  isTransferFundInstruction: false,
  setIconWidthHeight: false
};
