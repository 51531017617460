/*
 * tealiumSectionNameMapping object defines section names and its keys are aligned
 * to the content keys
 * getTealiumSectionName function checks if the any event's section present in the
 * mapping obj and return matched section name
 */

const tealiumSectionNameMapping = {
  policyOverview: 'policy overview',
  payment: 'payment information',
  BenifitAndCoverageLabel: 'benefits and coverage',
  returnsHeaderAnnuityOn: 'policy value',
  FundHoldingLabel: 'fund details'
};

const getTealiumSectionName = function getTealiumSectionName(sectionHeading) {
  if (sectionHeading in tealiumSectionNameMapping) {
    return tealiumSectionNameMapping[sectionHeading];
  }
  return sectionHeading;
};

export default getTealiumSectionName;
