import React from 'react';
import PropTypes from 'prop-types';
import { NoteSection } from '../instructionHistoryStyle';
import { canShowSLATextForStatus } from '../instructionUtils';

const SlaNoteSection = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { labelId, status } = props;

  return (
    <>
      {canShowSLATextForStatus(status) && (
        <NoteSection>{formatMessage({ id: labelId })}</NoteSection>
      )}
    </>
  );
};

SlaNoteSection.propTypes = {
  labelId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

SlaNoteSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SlaNoteSection;
