import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import specialCaseResolver from '../../sectionUtils/OverviewAmountUtils';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  ChildProtectionWrapper,
  ChildProtectionAmountClubWrapper,
  SpcialCaseWrapper,
  RiderSubCategoryWrapper
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';
import getDescription from '../../../utils/reducerUtils';

export function hasArrayAllNulls(arrary) {
  return arrary.every(value => value === null);
}

const renderRiderSubCategory = (
  fieldData,
  dependentField,
  dependentFieldTwo,
  fieldName,
  formatMessage,
  locale
) => {
  return _.map(fieldData, (field, index) => {
    let amountCurrencyComponent;
    const coverageAmount = field[`${fieldName}`];
    const subCategoryDescription = field[`${dependentField}`];
    const riderDuration = field[`${dependentFieldTwo}`];
    if (
      typeof coverageAmount !== 'object' ||
      !coverageAmount.currency ||
      (!coverageAmount.amount && coverageAmount.amount !== 0) ||
      !subCategoryDescription
    ) {
      return null;
    }

    if (subCategoryDescription && !subCategoryDescription.length) {
      return null;
    }

    const contentDescription = getDescription(locale, subCategoryDescription);

    if (riderDuration) {
      const returnSpecialCaseKey = specialCaseResolver(
        riderDuration,
        'spcialCase'
      );
      amountCurrencyComponent = (
        <SpcialCaseWrapper>
          <FormattedMessage
            id={returnSpecialCaseKey}
            aria-label={formatMessage({
              id: `${returnSpecialCaseKey}`
            })}
            values={{
              amountValue: (
                <Currency
                  currencyCode={coverageAmount.currency}
                  amount={coverageAmount.amount}
                />
              )
            }}
          />
        </SpcialCaseWrapper>
      );
    } else {
      amountCurrencyComponent = (
        <Currency
          id="CurrencyLabel"
          currencyCode={coverageAmount.currency}
          amount={coverageAmount.amount}
        />
      );
    }

    return (
      <ChildProtectionAmountClubWrapper id={`amountLabelDD_${index}`}>
        {amountCurrencyComponent}
        <RiderSubCategoryWrapper>{contentDescription}</RiderSubCategoryWrapper>
      </ChildProtectionAmountClubWrapper>
    );
  });
};

const ChildClubbedCoverageAmt = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const {
    fieldData,
    dependentField,
    fieldLabel,
    fieldName,
    toolTipContent,
    dependentFieldTwo
  } = props;

  if (!fieldLabel) {
    return '';
  }

  const subCategoryComponent = renderRiderSubCategory(
    fieldData,
    dependentField,
    dependentFieldTwo,
    fieldName,
    formatMessage,
    locale
  );
  return (
    <ChildProtectionWrapper id={fieldLabel}>
      {!hasArrayAllNulls(subCategoryComponent) && (
        <LabelWrapper
          {...props}
          labelContent={fieldLabel}
          toolTipContent={toolTipContent}
        />
      )}
      {subCategoryComponent}
    </ChildProtectionWrapper>
  );
};

ChildClubbedCoverageAmt.propTypes = {
  fieldData: PropTypes.array.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldTwo: PropTypes.string,
  dependentField: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string
};

ChildClubbedCoverageAmt.defaultProps = {
  toolTipContent: null,
  dependentField: '',
  dependentFieldTwo: '',
  fieldLabel: '',
  fieldName: ''
};

ChildClubbedCoverageAmt.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ChildClubbedCoverageAmt;
