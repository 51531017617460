import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  LastMonthlyAnnuityPaymentWrapper,
  LastMonthlyAnnuityAmountCurrency
} from '../../sections/SectionStyle';
import Currency from '../../../common/currency/Currency';

const LastMonthlyAnnuityPaymentAmount = function LastMonthlyAnnuityPaymentAmount(
  props,
  context
) {
  const {
    labelContent,
    fieldData,
    fieldData: { amount, currency }
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  if (
    !labelContent ||
    typeof fieldData !== 'object' ||
    !currency ||
    (amount === undefined || amount === null || amount < 0)
  ) {
    return null;
  }
  return (
    <LastMonthlyAnnuityPaymentWrapper id={labelContent}>
      <FormattedMessage
        id={labelContent}
        aria-label={formatMessage({
          id: `${labelContent}`
        })}
        values={{
          lastMonthlyAnnuityAmountCurrency: (
            <LastMonthlyAnnuityAmountCurrency>
              <Currency amount={amount} currencyCode={currency} />
            </LastMonthlyAnnuityAmountCurrency>
          )
        }}
      />
    </LastMonthlyAnnuityPaymentWrapper>
  );
};

LastMonthlyAnnuityPaymentAmount.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired
};

LastMonthlyAnnuityPaymentAmount.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default LastMonthlyAnnuityPaymentAmount;
