import * as actionTypes from '../actionTypes';

export const INITIAL_STATE = {
  serviceConfig: {},
  genericConfig: { policyRowCount: 0 },
  productConfig: { subProductFamilies: [], productFamilyOrder: [] },
  detailsConfig: { products: [], sections: [], fields: {} },
  exploreConfig: { exploreSection: [] },
  highlightsConfig: { highlightsSection: [] },
  faqConfig: { faqSection: {} },
  reprojectionConfig: { products: [], sections: [], fields: {} },
  reprojectionTabList: {},
  reprojectionChartConfig: {},
  disclaimerConfig: {},
  megaMenuConfig: {},
  contactUsConfig: {},
  eWelcomeDocumentConfig: {},
  eWelcomeKeyFeatureConfig: {},
  surrenderChargeConfig: {},
  policyTagsMedicalAndCIConfig: {},
  eStatementPreferenceConfig: {},
  indicativeGainLossConfig: {},
  upsellingBannersConfig: {}
};

const configurationReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case actionTypes.SET_EXTERNAL_CONFIGURATION:
      return {
        ...state,
        genericConfig: payload.genericConfig,
        serviceConfig: payload.serviceConfig,
        productConfig: payload.productConfig,
        detailsConfig: payload.detailsConfig,
        exploreConfig: payload.exploreConfig,
        highlightsConfig: payload.highlightsConfig,
        faqConfig: payload.faqConfig,
        reprojectionConfig: payload.reprojectionConfig,
        reprojectionTabList: payload.reprojectionTabConfiguration,
        reprojectionChartConfig: payload.reprojectionChartConfig,
        disclaimerConfig: payload.disclaimerConfig,
        megaMenuConfig: payload.megaMenuConfig,
        contactUsConfig: payload.contactUsConfig,
        sectionNotificationConfig: payload.sectionNotificationConfig,
        eWelcomeKeyFeatureConfig: payload.eWelcomeKeyFeatureConfig,
        eWelcomeDocumentConfig: payload.eWelcomeDocumentConfig,
        surrenderChargeConfig: payload.surrenderChargeConfig,
        policyTagsMedicalAndCIConfig: payload.policyTagsMedicalAndCIConfig,
        eStatementPreferenceConfig: payload.eStatementPreferenceConfig,
        indicativeGainLossConfig: payload.indicativeGainLossConfig,
        upsellingBannersConfig: payload.upsellingBannersConfig
      };
    case actionTypes.SERVICE_CONFIGS_STUBBING:
      return {
        ...state,
        serviceConfig: payload
      };

    default:
      return state;
  }
};

export default configurationReducer;
