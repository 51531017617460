import React from 'react';
import PropTypes from 'prop-types';
import errorIcon from '../../assets/images/NotificationIcons/error-flat.svg';
import {
  Wrapper,
  Icon,
  IconWrapper,
  WarningOrErrorMessage,
  NoPolicyAvailable
} from './warningsOrErrorsStyles';
import { trackViews } from '../../utils/tealiumUtils';
import { apiErrorOrWarningResolver } from '../../policysummary/summaryUtils/summaryWarningMessageUtils';

const warningOrError = function warningOrError(props, context) {
  const { warningsOrErrors } = props;
  const { errors, warnings } = warningsOrErrors;

  if (errors || warnings) {
    trackViews({
      viewName: 'onSummaryPageLoadErrorMsg',
      errorDetails: errors || warnings
    });
  }

  const {
    intl: { formatMessage }
  } = context;
  const { textId, displayAs } = apiErrorOrWarningResolver(warningsOrErrors);
  /* Error or Warning banner appears only when there is any of it existing via API's response. */
  if (displayAs === 'WarningOrErrors') {
    return (
      <Wrapper id="error_warning_banner">
        <IconWrapper>
          <Icon src={errorIcon} aria-hidden="true" alt="" />
        </IconWrapper>
        <WarningOrErrorMessage>
          {formatMessage({
            id: textId,
            defaultMessage: formatMessage({ id: 'defaultErrorOrWarningCode' })
          })}
        </WarningOrErrorMessage>
      </Wrapper>
    );
  }
  if (displayAs === 'PlainText') {
    return (
      <NoPolicyAvailable>
        {' '}
        {formatMessage({
          id: textId,
          defaultMessage: formatMessage({ id: 'defaultErrorOrWarningCode' })
        })}
      </NoPolicyAvailable>
    );
  }
  return null;
};

warningOrError.propTypes = {
  warningsOrErrors: PropTypes.object.isRequired
};

warningOrError.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default warningOrError;
