import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import getDescription from '../../../utils/reducerUtils';
import { RiderDesc } from '../../detailPageStyle';
import { getFieldDataObj } from '../../../utils/policyUtils';

const ChildRiderDescription = function RiderDescription(props, context) {
  const {
    intl: { formatMessage, locale }
  } = context;

  const { fieldData, fieldName, fieldLabel } = props;
  let contentDescription = '';
  let fieldArr = [];
  contentDescription = formatMessage({ id: fieldLabel });

  if (fieldData && fieldData.length) {
    const fieldDataObj = getFieldDataObj(fieldData, fieldName);

    if (!_.isEmpty(fieldDataObj)) {
      fieldArr = fieldDataObj[`${fieldName}`];
    }
  }

  if (fieldArr && fieldArr.length) {
    contentDescription =
      getDescription(locale, fieldArr) || formatMessage({ id: fieldLabel });
  }

  if (!contentDescription) {
    return <></>;
  }

  return <RiderDesc>{contentDescription}</RiderDesc>;
};

ChildRiderDescription.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldData: PropTypes.array.isRequired
};

ChildRiderDescription.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ChildRiderDescription;
