import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Currency from '../../../common/currency/Currency';
import { displayIfNull } from '../../../common/util/commonUtil';

import {
  PayoutBenefitAnnuityPayoutLegends,
  AnnualAnnuityPayoutLegendWrapper,
  AnnualAnnuityPayoutLegend,
  AnnualAnnuityPayoutKey,
  AnnualAnnuityPayoutValue,
  SelectedAge,
  TotalGuaranteedPayoutLabel,
  GuranteedPayoutKey,
  GuranteedPayoutValue,
  TotalGuranteedPayoutLegendIcon,
  TotalNonGuranteedPayoutLegendIcon,
  CumulativeGuranteedPayoutKey,
  CumulativeGuranteedPayoutValue,
  TotalGuranteedPayoutKey
} from './payoutLegendStyle';
import { getToggleBasedLegendTotalValue } from '../../utils/reprojectionDataUtils';

const PayoutCumulativeAnnuityLegends = function PayoutCumulativeAnnuityLegends(
  props,
  context
) {
  const {
    isNonGuaranteedChecked,
    policyCurrency,
    payoutDetails,
    ageSelection,
    legends,
    reprojectionsData
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const payoutBenefitLegendsData = payoutDetails[ageSelection];

  if (!payoutBenefitLegendsData) {
    return <></>;
  }

  const {
    header: {
      keyName,
      keyNameSecondary,
      keyValueGuaranteed,
      keyValueNonGuaranteed,
      decimalRange: decimalRangeTotPayout
    },
    subHeader,
    guaranteed: {
      keyName: guaranteedKeyName,
      keyValue: guaranteedKeyValue,
      color: guaranteedColor,
      decimalRange: decimalRangeGuaranteed
    },
    nonGuaranteed: {
      color: nonGuarColor,
      mandatoryKey: mandatoryKeyNonGuar,
      keyName: nonGuarKeyName,
      decimalRange: decimalRangeNonGuar,
      keyValue: nonGuarKeyValue
    }
  } = legends;

  const {
    keyParent,
    mandatoryKey,
    keyName: labelName,
    keyValue: labelValue,
    decimalRange
  } = subHeader[0];
  const { keyName: fromAgeLabel } = subHeader[1];

  const isBeforeAgeFieldValid = _.get(
    _.get(reprojectionsData, keyParent),
    mandatoryKey
  );
  const previousPayoutLegendData = _.get(
    _.get(reprojectionsData, keyParent),
    labelValue
  );

  const guaranteedAmount = payoutBenefitLegendsData[guaranteedKeyValue];
  const nonGuarAmount = payoutBenefitLegendsData[nonGuarKeyValue];

  const isNonGuarPayOutFieldValid =
    payoutBenefitLegendsData[mandatoryKeyNonGuar];
  const isToggleActivatedAndNonGuarPayOutField =
    isNonGuaranteedChecked && isNonGuarPayOutFieldValid;

  const getSelectedAgeDetails = function getSelectedAgeDetails(
    defaultKey,
    selectedAgeByCustomer
  ) {
    return (
      <FormattedMessage
        id={defaultKey}
        values={{
          selectedAge: selectedAgeByCustomer
        }}
      />
    );
  };

  let projectedTotalPayoutValue = getToggleBasedLegendTotalValue(
    keyValueNonGuaranteed,
    keyValueGuaranteed,
    isNonGuaranteedChecked
  );

  projectedTotalPayoutValue =
    payoutBenefitLegendsData[projectedTotalPayoutValue];

  return (
    <PayoutBenefitAnnuityPayoutLegends styleUpdate>
      <AnnualAnnuityPayoutLegendWrapper>
        <AnnualAnnuityPayoutLegend styleUpdate>
          <>
            <>
              <AnnualAnnuityPayoutKey styleUpdate id="projectedTotalPayout_Key">
                {formatMessage({ id: keyName })}
                <SelectedAge>
                  <FormattedMessage
                    id={keyNameSecondary}
                    values={{
                      startAge: ageSelection
                    }}
                  />
                </SelectedAge>
              </AnnualAnnuityPayoutKey>
              <AnnualAnnuityPayoutValue
                styleUpdate
                id="projectedTotalPayout_Value"
              >
                <Currency
                  currencyCode={policyCurrency}
                  amount={displayIfNull(projectedTotalPayoutValue, 0)}
                  decimalRange={decimalRangeTotPayout}
                />
              </AnnualAnnuityPayoutValue>
            </>
            <TotalGuranteedPayoutKey styleUpdate />
            {isBeforeAgeFieldValid ? (
              <>
                <GuranteedPayoutKey styleUpdate id="beforeAge_Key">
                  {formatMessage({ id: labelName })}
                </GuranteedPayoutKey>
                <GuranteedPayoutValue styleUpdate id="beforeAge_Value">
                  <Currency
                    currencyCode={policyCurrency}
                    amount={previousPayoutLegendData}
                    decimalRange={decimalRange}
                  />
                </GuranteedPayoutValue>
              </>
            ) : null}

            <GuranteedPayoutKey styleUpdate id="fromAgeBetween_Key">
              {getSelectedAgeDetails(fromAgeLabel, ageSelection)}
            </GuranteedPayoutKey>
            <>
              <CumulativeGuranteedPayoutKey id="acmltedGuarPayOutAmt_Key">
                <TotalGuranteedPayoutLegendIcon
                  legendBgColor={guaranteedColor}
                />
                <TotalGuaranteedPayoutLabel>
                  {formatMessage({ id: guaranteedKeyName })}
                </TotalGuaranteedPayoutLabel>
              </CumulativeGuranteedPayoutKey>
              <CumulativeGuranteedPayoutValue id="acmltedGuarPayOutAmt_Value">
                <Currency
                  currencyCode={policyCurrency}
                  amount={displayIfNull(guaranteedAmount, 0)}
                  decimalRange={decimalRangeGuaranteed}
                />
              </CumulativeGuranteedPayoutValue>
            </>
            {/*  non-guranteed amount  */}
            {isToggleActivatedAndNonGuarPayOutField ? (
              <>
                <CumulativeGuranteedPayoutKey id="acmltedNonGuarPayOutAmt_Key">
                  <TotalNonGuranteedPayoutLegendIcon
                    legendBgColor={nonGuarColor}
                  />
                  <TotalGuaranteedPayoutLabel>
                    {formatMessage({ id: nonGuarKeyName })}
                  </TotalGuaranteedPayoutLabel>
                </CumulativeGuranteedPayoutKey>
                <CumulativeGuranteedPayoutValue id="acmltedNonGuarPayOutAmt_Value">
                  <Currency
                    currencyCode={policyCurrency}
                    amount={displayIfNull(nonGuarAmount, 0)}
                    decimalRange={decimalRangeNonGuar}
                  />
                </CumulativeGuranteedPayoutValue>
              </>
            ) : null}
          </>
          <TotalGuranteedPayoutKey />
        </AnnualAnnuityPayoutLegend>
      </AnnualAnnuityPayoutLegendWrapper>
    </PayoutBenefitAnnuityPayoutLegends>
  );
};

PayoutCumulativeAnnuityLegends.propTypes = {
  ageSelection: PropTypes.number,
  payoutDetails: PropTypes.array.isRequired,
  isNonGuaranteedChecked: PropTypes.bool.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  legends: PropTypes.object.isRequired,
  reprojectionsData: PropTypes.object.isRequired
};

PayoutCumulativeAnnuityLegends.defaultProps = {
  ageSelection: 0
};
PayoutCumulativeAnnuityLegends.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PayoutCumulativeAnnuityLegends;
