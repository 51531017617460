import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import SummaryComponentsWrapper from './components/SummaryComponentsWrapper';
import WarningOrError from '../common/warningOrErrorHandler/WarningsOrErrors';
import NotificationMessages from './components/NotificationMessages';
import ExploreSection from './components/ExploreSection';
import SimplyBook from './components/SimplyBook';
import titleHandler from '../utils/titleUtils';
import {
  SummaryHeaderSection,
  SummaryParentSection,
  SummaryTitle,
  ExploreParentSection,
  ExploreTitle,
  PageLevelWarningSection,
  LearnMoreLink,
  WelcomeNotificationSection
} from './summaryPageStyles';
import {
  NotificationWrapper,
  NotificationMessageIcon,
  NotificationMessageType,
  NotificationMessage,
  NotificationIconSpan,
  ImagePath,
  FullWidthSectionContainer
} from '../common/styles/commonStyle';

import DisclaimerSectionNote from '../policydetail/DisclaimerSection';
import {
  getPolicyInfo,
  updateIdleFlag,
  startLoader,
  endLoader,
  updateSAASPageFail,
  setSelectedTab,
  updateLoaderOpacity
} from '../app/appActions';
import getContentInSpecificLang from '../utils/localeUtils';
import {
  trackEvents,
  createEventActionContent,
  getPlanName
} from '../utils/tealiumUtils';
import ErrorBoundary from '../app/ErrorBoundary';
import * as icons from '../assets/images/NotificationIcons';

import ContactLink from '../common/contactLink/contactLink';
import fireCelebrus from '../utils/fireCelebrusUtils';
import HighlightsSection from './components/HighlightsSection';
import Tabs from '../common/carouselTabs/Tabs';
import Tab from '../common/carouselTabs/Tab';
import { fetchSaasToken } from '../utils/saasAuthModuleUtil';
import WelcomeNotification from './components/WelcomeNotification';
import { isApplicationStubbed } from '../utils/routeUtils';
import { ErrorMessageSectHeader } from './components/welcomeNotificationStyles';
import getDescription from '../utils/reducerUtils';
import WelcomeModal from '../eWelcome/WelcomeModal';
import { setBackGroundColorDetailSummaryInstPage } from '../utils/domUtils';

class SummaryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcomeModelOverlay: false,
      eWelcomePolicyNumberClicked: '',
      customerPolicy: null
    };
    this.policyInfoByFamily = {};
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    const {
      updateIdleState,
      startLoaderHandler,
      endLoaderHandler,
      updateSAASPageFailHandler,
      updateLoaderOpacityHandler
    } = this.props;
    updateLoaderOpacityHandler(false);
    startLoaderHandler();
    if (isApplicationStubbed()) {
      this.onPageMount();
    } else {
      fetchSaasToken()
        .then(() => {
          this.onPageMount();
        })
        .catch(() => {
          updateIdleState(true);
          updateSAASPageFailHandler();
          endLoaderHandler();
        });
    }
  }

  componentDidUpdate() {
    const {
      intl: { formatMessage }
    } = this.context;
    const title = formatMessage({ id: 'summary_page_title' });
    titleHandler(title);
  }

  onWelcomeModelClick(
    e,
    encodePolicyNumber,
    customerPolicy,
    selectedLocale,
    planName
  ) {
    getPlanName(getDescription('en', planName)); // to get plan name only in English for tealium tagging
    const planNameValue = getDescription(selectedLocale, planName);
    const eventContent = `your new policy '${planNameValue}' is ready`;
    e.preventDefault();
    trackEvents({
      eventName: 'eWelcomeModellearnMore',
      eventData: { ...createEventActionContent('button', 'learn more', eventContent), customerPolicy}
    });
    this.setState({
      showWelcomeModelOverlay: true,
      eWelcomePolicyNumberClicked: encodePolicyNumber,
      customerPolicy
    });
  }

  onCancel() {
    this.setState({ showWelcomeModelOverlay: false });
  }

  onPageMount() {
    const {
      fetchPolicyInfo,
      needToAnchorToPolicies,
      location: { pathname }
    } = this.props;
    const {
      intl: { formatMessage }
    } = this.context;
    const title = formatMessage({ id: 'summary_page_title' });
    titleHandler(title);

    /**
     * ANCHORING FEATURE FOR LANDING PAGE.
     * If the user comes through HSBC customer portal and has clicked on "My Insurance" link, the user must be
     * navigated to Dashboard Summary Page and anchored towards Policy Section under the Explore section.
     *
     * ----------------------------------------------------------------------------------------------------------
     */

    if (needToAnchorToPolicies) {
      const element = document.getElementById('myPolicies');
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = element.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      window.scrollTo(0, offset - 120);
    }
    fetchPolicyInfo();
    fireCelebrus(pathname);
  }

  onFieldChange(selectedValue) {
    const { setSelectedTabHandler } = this.props;
    setSelectedTabHandler(selectedValue);
  }

  getProductInfoByFamily(byFamily, byEncodeId) {
    _.each(byFamily, (encodedPolicyIdArray, family) => {
      if (encodedPolicyIdArray.length > 0) {
        this.policyInfoByFamily[family] = [];
        _.each(encodedPolicyIdArray, encodedPolicyId => {
          this.policyInfoByFamily[family].push(byEncodeId[encodedPolicyId]);
        });
      }
    });
  }

  render() {
    const {
      productFamilyOrder,
      subProductCategories,
      policyByFamily,
      policyByEncodeId,
      policyRowCount,
      notificationMessages,
      summaryWarningsOrErrors: { summary: summaryErrorsOrWarnings = null },
      summaryWarningsPageLevel,
      selectedLocale,
      exploreSection,
      langObj,
      pageSaasFail,
      isLoading,
      highlightsSection,
      tealiumMappings,
      simplyBook,
      customer,
      customerSegmentValues,
      selectedTabId,
      eWelcomePack = [],
      eligibilityDetails
    } = this.props;

    setBackGroundColorDetailSummaryInstPage();
    const { intl: { messages = {}, formatMessage } = {} } = this.context;
    const {
      showWelcomeModelOverlay,
      eWelcomePolicyNumberClicked,
      customerPolicy
    } = this.state;
    if (Object.keys(messages).length < 1) {
      return null;
    }
    this.getProductInfoByFamily(policyByFamily, policyByEncodeId);
    let alertStyleObj = {};
    const typeOfCall = 'general enquiry';
    const bannerImageContentKey = getContentInSpecificLang(
      'bannerImage',
      selectedLocale,
      langObj
    );
    if (summaryWarningsPageLevel && summaryWarningsPageLevel.length > 0) {
      alertStyleObj = notificationMessages.find(
        message => message.type === 'warning'
      );
    }
    let labelContent = '';
    const warningMessageComponent =
      summaryWarningsPageLevel &&
      summaryWarningsPageLevel.length > 0 &&
      summaryWarningsPageLevel.map(alert => {
        labelContent = `${alert.label}`;
        return (
          <NotificationMessageIcon role="alert" key={labelContent}>
            <NotificationIconSpan>
              <ImagePath
                aria-hidden="true"
                alt=""
                src={icons[alertStyleObj.style.icon]}
              />
            </NotificationIconSpan>
            <NotificationMessage
              aria-label={formatMessage(
                { id: labelContent },
                { contact0: formatMessage({ id: 'lifeContactUsNumber' }) }
              )}
            >
              <ContactLink
                sentenceWithContact={labelContent}
                contactsList={['lifeContactUsNumber']}
                typeOfCall={typeOfCall}
              />
            </NotificationMessage>
          </NotificationMessageIcon>
        );
      });
    return (
      <>
        {/*
            <FullWidthSectionContainer> Carries full viewport width And
             which has some background color/image
      */}
        <FullWidthSectionContainer>
          {/*
            <FullWidthSectionContainer> this block will align to parent viewport.
            which will be align with 1280/1180 max width
            NOTE:Avoid to give any style to this block ,
            You can add child div and align the ui structure
        */}

          <NotificationWrapper>
            <NotificationMessages notificationMessages={notificationMessages} />
          </NotificationWrapper>
          {!!bannerImageContentKey && (
            <img
              src={formatMessage({ id: bannerImageContentKey })}
              alt=""
              id="bar"
            />
          )}
        </FullWidthSectionContainer>
        {eWelcomePack.length
          ? eWelcomePack.map(
              eWelcome =>
                eWelcome.showWelcomePack && (
                  <WelcomeNotificationSection key={eWelcome.encodePolicyNumber}>
                    <WelcomeNotification>
                      <ErrorMessageSectHeader>
                        <FormattedMessage
                          id="congratsMessage"
                          defaultMessage="Congratulation. your new policy '{planName}' is ready! You can click learn more from here or see details on your new policy below for more details. {learnMoreLink} "
                          values={{
                            planName: getDescription(
                              selectedLocale,
                              eWelcome.planName
                            ),
                            learnMoreLink: (
                              <LearnMoreLink
                                data-locator-id="learn_more_link"
                                href=""
                                onClick={e =>
                                  this.onWelcomeModelClick(
                                    e,
                                    eWelcome.encodePolicyNumber,
                                    eWelcome.customerPolicy,
                                    selectedLocale,
                                    eWelcome.planName
                                  )
                                }
                                tabIndex={0}
                                id="Learn_More_Links"
                              >
                                <FormattedMessage
                                  id="learnMore"
                                  defaultMessage="Learn more"
                                />
                              </LearnMoreLink>
                            )
                          }}
                        />
                      </ErrorMessageSectHeader>
                    </WelcomeNotification>{' '}
                  </WelcomeNotificationSection>
                )
            )
          : null}
        {showWelcomeModelOverlay ? (
          <WelcomeModal
            isOpenModal={showWelcomeModelOverlay}
            onCancel={() => this.onCancel()}
            encodePolicyNumber={eWelcomePolicyNumberClicked}
            customerPolicy={customerPolicy}
          />
        ) : null}
        <PageLevelWarningSection id="pageLevelWarningSection">
          {alertStyleObj && alertStyleObj.style && (
            <NotificationMessageType
              bgColor={alertStyleObj.style.bgColor}
              borderColor={alertStyleObj.style.borderColor}
            >
              {warningMessageComponent}
            </NotificationMessageType>
          )}
        </PageLevelWarningSection>
        <ExploreParentSection id="discoverInsuranceOnline">
          <ExploreTitle id="explore_section_title">
            {formatMessage({ id: 'exploreTitle' })}
          </ExploreTitle>
          <Tabs
            id="dashboard"
            onFieldChange={this.onFieldChange}
            selectedTab={selectedTabId}
          >
            <Tab label={formatMessage({ id: 'explore_highlight' })}>
              <HighlightsSection
                langObj={langObj}
                highlightsSection={highlightsSection}
                tealiumMappings={tealiumMappings}
                eligibilityDetails={eligibilityDetails}
              />
            </Tab>
            <Tab label={formatMessage({ id: 'explore_browse' })}>
              <ExploreSection exploreSection={exploreSection} />
            </Tab>
          </Tabs>
          {simplyBook && (
            <SimplyBook
              simplyBookData={simplyBook}
              customer={customer}
              customerSegmentValues={customerSegmentValues}
            />
          )}
        </ExploreParentSection>
        <ErrorBoundary>
          {!isLoading && pageSaasFail ? (
            <></>
          ) : (
            <SummaryParentSection>
              <SummaryHeaderSection id="myPolicies">
                <SummaryTitle id="policy_summary_title">
                  {formatMessage({ id: 'summaryTitle' })}
                </SummaryTitle>
              </SummaryHeaderSection>
              {summaryErrorsOrWarnings !== null && (
                <WarningOrError warningsOrErrors={summaryErrorsOrWarnings} />
              )}
              <SummaryComponentsWrapper
                eWelcomePack={eWelcomePack}
                productFamilyOrder={productFamilyOrder}
                superCategoryStack={this.policyInfoByFamily}
                subProductCategories={subProductCategories}
                policyRowCount={policyRowCount}
                notificationMessages={notificationMessages}
              />
            </SummaryParentSection>
          )}
        </ErrorBoundary>
        <DisclaimerSectionNote />
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    configurations: {
      exploreConfig: { exploreSection },
      productConfig: { subProductFamilies },
      genericConfig: {
        policyRowCount,
        notificationMessages,
        simplyBook,
        customerSegmentValues
      },
      highlightsConfig: { highlightsSection, tealiumMappings }
    },
    policy: {
      isSummaryCalled,
      totalPolicyCount,
      eWelcomePack,
      byFamily,
      byEncodeId,
      productFamilyOrder,
      warningsOrErrors,
      summaryWarningsPageLevel
    },
    app: {
      isLoading,
      needToAnchorToPolicies,
      pageSaasFail,
      selectedTabId,
      eligibilityDetails
    },
    content: { selectedLocale, langObj },
    customer
  } = state;

  return {
    productFamilyOrder,
    subProductCategories: subProductFamilies,
    isSummaryCalled,
    totalPolicyCount,
    eWelcomePack,
    policyByFamily: byFamily,
    policyByEncodeId: byEncodeId,
    policyRowCount,
    summaryWarningsOrErrors: warningsOrErrors,
    summaryWarningsPageLevel,
    notificationMessages,
    selectedLocale,
    langObj,
    exploreSection,
    needToAnchorToPolicies,
    isLoading,
    pageSaasFail,
    selectedTabId,
    highlightsSection,
    tealiumMappings,
    simplyBook,
    customer,
    customerSegmentValues,
    eligibilityDetails
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPolicyInfo: getPolicyInfo,
      updateIdleState: updateIdleFlag,
      startLoaderHandler: startLoader,
      endLoaderHandler: endLoader,
      updateSAASPageFailHandler: updateSAASPageFail,
      setSelectedTabHandler: setSelectedTab,
      updateLoaderOpacityHandler: updateLoaderOpacity
    },
    dispatch
  );

SummaryPage.contextTypes = {
  intl: PropTypes.object.isRequired
};

SummaryPage.propTypes = {
  productFamilyOrder: PropTypes.array.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  policyByFamily: PropTypes.object.isRequired,
  policyByEncodeId: PropTypes.object.isRequired,
  fetchPolicyInfo: PropTypes.func.isRequired,
  policyRowCount: PropTypes.number.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  summaryWarningsOrErrors: PropTypes.object.isRequired,
  summaryWarningsPageLevel: PropTypes.array.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  exploreSection: PropTypes.array.isRequired,
  needToAnchorToPolicies: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  updateIdleState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageSaasFail: PropTypes.bool.isRequired,
  startLoaderHandler: PropTypes.func.isRequired,
  endLoaderHandler: PropTypes.func.isRequired,
  updateSAASPageFailHandler: PropTypes.func.isRequired,
  highlightsSection: PropTypes.array.isRequired,
  tealiumMappings: PropTypes.object.isRequired,
  simplyBook: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  customerSegmentValues: PropTypes.array.isRequired,
  setSelectedTabHandler: PropTypes.func.isRequired,
  selectedTabId: PropTypes.number.isRequired,
  eWelcomePack: PropTypes.array.isRequired,
  updateLoaderOpacityHandler: PropTypes.func.isRequired,
  eligibilityDetails: PropTypes.object
};

SummaryPage.defaultProps = {
  eligibilityDetails: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage);
