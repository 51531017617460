import styled from 'styled-components';
import media from '../utils/media';

export const BeneficiaryWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const ChartWrapper = styled.div`
  width: 132px;
  height: 132px;
  margin: auto 0;
  padding-left: 5px;
  ${media.miniDesktop`
    padding:0;
  `}
  ${media.tablet`
  margin: 0 auto;
  margin-left:24%;
`}
  ${media.mediumTablet`
    margin-left:-8px;
  `}
  ${media.phoneToMiniTablet`
    margin:0 auto
  `}
`;
