import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReprojectionCharts from '../ReprojectionCharts';
import BelowGraphNotification from './templates/BelowGraphNotification';
import ReprojectionTableDataFormatter from '../ReprojectionTableDataFormatter';
import ChartLegendsCheckbox from '../../charts/ChartLegendsCheckbox';
import {
  ChartTitle,
  GraphInfoSubHeaderDiv,
  InfoMessageSpan
} from '../reprojectionPageStyle';
import { trackEvents } from '../../utils/tealiumUtils';
import { FutureValueTabSubHeader } from '../templates';
import { getChartCheckboxInfo } from '../../utils/reprojectionsUtils';

/**
 * @param {Object} props
 * @description : contains sectionFieldConfig which can be iterated to render field component inside this template
 *  sectionFieldConfig[0] : array of fields object for chart
    sectionFieldConfig[1] : array of table header object for table
    sectionFieldConfig[2] : array of fields object of table main subsection
 */

const FutureValueDeathBenefit = (props, context) => {
  const {
    productCategory,
    sectionFieldConfig,
    tableSectionAPIFieldParentKey,
    reprojectionsDetails,
    dependentFieldConfig,
    policyCurrencyFormat,
    reprojectionGenericConfig,
    sectionToRender,
    reprojectionChartConfig: { deathBenefitChart, chartFields },
    sectionNotificationConfig,
    customerAge,
    setCustomerAge,
    setCustomerMinPayAge,
    customerMinPayAge,
    toggleIncludeNonGuaranteedPayout,
    tabValues: {
      deathBenefits: { includeNonGuaranteedPayout }
    },
    selectedFutureBenefitsTab,
    policyPhaseFlag,
    reprojectionTealiumHandlers
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const { captureCheckBoxEvents } = reprojectionTealiumHandlers;
  const {
    chartIncludeNGCheckbox: { show: showCheckboxAsPerConfiguration }
  } = getChartCheckboxInfo(chartFields, sectionToRender);
  const [isDisplayCheckBox, displayChkBoxSetter] = useState(
    showCheckboxAsPerConfiguration
  );

  const {
    reprojectionDetails: { deathDetails }
  } = reprojectionsDetails;

  return (
    <>
      <ChartTitle>{formatMessage({ id: 'deathBenefitLabel' })}</ChartTitle>
      {Object.keys(deathDetails).length > 1 && (
        <GraphInfoSubHeaderDiv>
          <InfoMessageSpan>
            {formatMessage({ id: 'graphInfoSubHeaderLabel' })}
          </InfoMessageSpan>
        </GraphInfoSubHeaderDiv>
      )}
      <FutureValueTabSubHeader
        reprojectionsDetails={reprojectionsDetails}
        selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        id="deathBenefitsChart"
        selectedTabName="DeathBenefit"
      />
      {isDisplayCheckBox && (
        <ChartLegendsCheckbox
          includeNonGuranteedPayout={includeNonGuaranteedPayout}
          onCheckboxChange={() =>
            captureCheckBoxEvents(
              toggleIncludeNonGuaranteedPayout,
              'deathBenefits',
              includeNonGuaranteedPayout,
              'non-guaranteed death benefit',
              trackEvents
            )
          }
          label="legendCheckboxIncludeNonGuranteedDeathBenefit"
          checkboxId="future-value-death-benefit"
        />
      )}
      <ReprojectionCharts
        productCategory={productCategory}
        reprojectionsData={reprojectionsDetails}
        chartConfig={deathBenefitChart}
        sectionToRender={sectionToRender}
        showCheckboxAsPerConfiguration={showCheckboxAsPerConfiguration}
        tooltipContent="tooltipTotalAmountAllocate"
        isNonGuaranteedChecked={includeNonGuaranteedPayout}
        policyCurrencyFormat={policyCurrencyFormat}
        id="deathBenefitsChart"
        setValue={setCustomerAge}
        value={customerAge}
        customerMinPayAge={customerMinPayAge}
        setCustomerMinPayAge={setCustomerMinPayAge}
        displayChkBoxSetter={displayChkBoxSetter}
        selectedTabName="DeathBenefit"
      />
      <BelowGraphNotification
        selectedTabName="DeathBenefit"
        reprojectionsDetails={reprojectionsDetails}
        sectionNotificationConfig={sectionNotificationConfig}
      />
      <ReprojectionTableDataFormatter
        tableSectionFieldConfig={sectionFieldConfig && sectionFieldConfig[2]}
        apiFieldParentKey={tableSectionAPIFieldParentKey}
        reprojectionsDetails={reprojectionsDetails}
        dependentFieldConfig={dependentFieldConfig}
        policyCurrencyFormat={policyCurrencyFormat}
        reprojectionGenericConfig={reprojectionGenericConfig}
        selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        tableHeaderText="deathBenefitTableHeader"
        selectedTabName="DeathBenefit"
        policyPhaseFlag={policyPhaseFlag}
      />
    </>
  );
};

FutureValueDeathBenefit.propTypes = {
  productCategory: PropTypes.string.isRequired,
  sectionFieldConfig: PropTypes.array.isRequired,
  tableSectionAPIFieldParentKey: PropTypes.string.isRequired,
  policyCurrencyFormat: PropTypes.string.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  sectionToRender: PropTypes.string.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  customerAge: PropTypes.number.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  tabValues: PropTypes.object.isRequired,
  toggleIncludeNonGuaranteedPayout: PropTypes.func.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  policyPhaseFlag: PropTypes.bool.isRequired,
  reprojectionTealiumHandlers: PropTypes.object.isRequired
};

FutureValueDeathBenefit.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default FutureValueDeathBenefit;
