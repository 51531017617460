import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import { PaymentInfoFieldWrapper, TextLabel } from '../../detailPageStyle';
import DateComponent from '../../../common/date/Date';

const PaymentInfoDateField = function PaymentInfoDateField(props, context) {
  const {
    intl: { locale, formatMessage }
  } = context;
  const {
    labelContent,
    fieldData,
    dependentFieldData,
    dependentContentData
  } = props;

  let hasIndicator = false;
  if (!labelContent || (!fieldData && !dependentFieldData)) {
    return null;
  }
  if (dependentFieldData) {
    hasIndicator = true;
  }
  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      {hasIndicator && dependentContentData ? (
        <TextLabel>{formatMessage({ id: dependentContentData })}</TextLabel>
      ) : (
        <TextLabel>
          <DateComponent
            date={fieldData}
            locale={locale}
            apiDateFormat="YYYY-MM-DD"
            id="standaradDateFormat"
          />
        </TextLabel>
      )}
    </PaymentInfoFieldWrapper>
  );
};

PaymentInfoDateField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dependentContentData: PropTypes.string
};

PaymentInfoDateField.defaultProps = {
  toolTipContent: null,
  dependentFieldData: '',
  dependentContentData: ''
};

PaymentInfoDateField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PaymentInfoDateField;
