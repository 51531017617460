import React, { useState } from 'react';
import * as _ from 'lodash';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { FontIcon } from 'ui-component-library';
import ProductCards from './ProductCardsBuilder';
import ShowIf from '../../utils/showIf';
import {
  ProductFamilyHeaderSection,
  ProductCardSection,
  ShowMoreSection,
  FullWidthButton,
  TextWrapper,
  TextSpan,
  CategoryIconImg,
  CategoryLabel,
  CategoryHeader,
  SuperFamilyWrapper
} from '../summaryPageStyles';
import theme from '../../common/styles/theme';
import lifeIcon from '../../assets/images/life.svg';
import savingIcon from '../../assets/images/saving_investment.svg';
import healthIcon from '../../assets/images/medical_critical_illness.svg';
import travelIcon from '../../assets/images/travel.svg';
import houseHoldIcon from '../../assets/images/home.svg';
import { trackEvents } from '../../utils/tealiumUtils';
import { setFocus } from '../../utils/domUtils';

const SuperProductFamilyBuilder = (props, context) => {
  const {
    customerProductList,
    superCategory,
    superCategoryName,
    subProductCategories,
    policyRowCount,
    notificationMessages,
    categoryStackIndex,
    eWelcomePack
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  const [showMore, setShowMore] = useState(false);

  /**
   * Method that returns an array of Products rendering component - <ProductCards />
   * customerProductList - List of products/policies bought for a particular customer from APIs response
   * subProductCategories - Product Families configured in Product Configurator.
   */

  const productCards = _.map(customerProductList, (product, index) => {
    const productFieldsObject = {};

    /**
     * matchedConfiguredProduct - Product configuration which matched for a particular super category.
     */

    const matchedConfiguredProduct = _.find(subProductCategories, [
      'productCategoryName',
      product.productCategory
    ]);

    /**
     * uiTemplate - The way of representing a particular product on the UI
     */

    const { uiTemplate } = matchedConfiguredProduct;

    /**
     * Finding out field bundle from the matchedConfiguredProduct, and sending them to each product cards.
     */
    _.map(matchedConfiguredProduct.fieldBundle, (fieldObject, indexNo) => {
      productFieldsObject[`field${indexNo + 1}`] = {
        fieldLabel: fieldObject.fieldLabel,
        fieldName: fieldObject.fieldName,
        conditionalFieldName: fieldObject.conditionalFieldName,
        conditionalFieldLabel: fieldObject.conditionalFieldLabel,
        conditionalFieldNameOne: fieldObject.conditionalFieldNameOne,
        conditionalFieldLabelOne: fieldObject.conditionalFieldLabelOne,
        conditionalFieldNameTwo: fieldObject.conditionalFieldNameTwo,
        conditionalFieldLabelTwo: fieldObject.conditionalFieldLabelTwo,
        value: _get(product, fieldObject.fieldName),
        conditionalFieldValue: _get(product, fieldObject.conditionalFieldName),
        conditionalFieldValueOne: _get(
          product,
          fieldObject.conditionalFieldNameOne
        ),
        conditionalFieldValueTwo: _get(
          product,
          fieldObject.conditionalFieldNameTwo
        ),
        channel: fieldObject.channel,
        showOnUI: fieldObject.showOnUI,
        showValuationDate: fieldObject.showForThisFamily
      };
    });

    const { productCategory, encodePolicyNumber, onHoldIndicator } = product;
    return (
      <React.Fragment key={index}>
        <ShowIf condition={index < policyRowCount || showMore === true}>
          <ProductCardSection id={`${superCategory}_${index}`}>
            <ProductCards
              eWelcomePack={eWelcomePack}
              productFieldsObject={productFieldsObject}
              superCategory={superCategory}
              notificationMessages={notificationMessages}
              productCategory={productCategory}
              uiTemplate={uiTemplate}
              encodePolicyNumber={encodePolicyNumber}
              policyOnHold={onHoldIndicator}
            />
          </ProductCardSection>
        </ShowIf>
        <ShowIf
          condition={
            index === policyRowCount - 1 &&
            showMore === false &&
            index < customerProductList.length - 1
          }
        >
          <ShowMoreSection>
            <FullWidthButton
              id={`showMore_${superCategoryName}`}
              type="button"
              onClick={() => {
                setShowMore(true);
                trackEvents({ eventName: 'showAll' });
                setFocus(index, superCategory);
              }}
              aria-label={formatMessage(
                {
                  id: `showMoreAriaLabel`
                },
                { productfamily: superCategoryName }
              )}
            >
              <TextWrapper>
                <TextSpan>{formatMessage({ id: 'showMore' })}</TextSpan>
                <FontIcon
                  type="chevron-down"
                  fontSize={[14, 14, 14]}
                  color={theme.downIconColor}
                />
              </TextWrapper>
            </FullWidthButton>
          </ShowMoreSection>
        </ShowIf>
        <ShowIf
          condition={
            index === customerProductList.length - 1 && showMore === true
          }
        >
          <ShowMoreSection>
            <FullWidthButton
              id={`showLess_${superCategoryName}`}
              type="button"
              onClick={() => setShowMore(false)}
              aria-label={formatMessage(
                {
                  id: `showLessAriaLabel`
                },
                { productfamily: superCategoryName }
              )}
            >
              <TextWrapper>
                <TextSpan>{formatMessage({ id: 'showLess' })}</TextSpan>
                <FontIcon
                  type="chevron-up"
                  aria-hidden="true"
                  fontSize={[14, 14, 14]}
                />
              </TextWrapper>
            </FullWidthButton>
          </ShowMoreSection>
        </ShowIf>
      </React.Fragment>
    );
  });

  /**
   * Rendering Super Category name (superCategoryName) and the number of products under it. Name (No. of Products).
   * productCards - Array of Products to be listed under a super category. Calculated from the method - productCards()
   */

  const productFamilyIconMapping = {
    life: { src: lifeIcon, alt: 'lifeIconAlt' },
    health: { src: healthIcon, alt: 'healthIconAlt' },
    savingsInvestment: { src: savingIcon, alt: 'savingsIconAlt' },
    travelInsurance: { src: travelIcon, alt: 'travelIconAlt' },
    householdProtection: { src: houseHoldIcon, alt: 'houseHoldIconAlt' }
  };

  return (
    <>
      <SuperFamilyWrapper id={`${superCategory}_stack`}>
        <ProductFamilyHeaderSection categoryStackIndex={categoryStackIndex}>
          <CategoryHeader>
            <CategoryIconImg superCategory={superCategory}>
              <img
                aria-hidden="true"
                alt=""
                src={productFamilyIconMapping[superCategory].src}
              />
            </CategoryIconImg>
            <CategoryLabel id={`${superCategory}_title`}>
              {`${formatMessage({ id: superCategory })} `}
              <span
                id={`${superCategory}_count`}
                aria-label={`${formatMessage({
                  id: 'policyCount'
                })}${`(${productCards.length})`}`}
              >
                {`(${productCards.length})`}
              </span>
            </CategoryLabel>
          </CategoryHeader>
        </ProductFamilyHeaderSection>
        {productCards}
      </SuperFamilyWrapper>
    </>
  );
};

export default SuperProductFamilyBuilder;

SuperProductFamilyBuilder.contextTypes = {
  intl: PropTypes.object.isRequired
};

SuperProductFamilyBuilder.propTypes = {
  customerProductList: PropTypes.array.isRequired,
  superCategory: PropTypes.string.isRequired,
  superCategoryName: PropTypes.string.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  policyRowCount: PropTypes.number.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  categoryStackIndex: PropTypes.number.isRequired,
  eWelcomePack: PropTypes.object.isRequired
};
