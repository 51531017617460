import PropTypes from 'prop-types';
import React from 'react';
import { NoSecondaryContainer } from './beneficiarySectionStyles';
import NoDataImages from './NoDataImages';

const BeneficiarySectionNoData = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { type } = props;

  return type === 'primary' ? (
    <NoDataImages />
  ) : (
    <NoSecondaryContainer>
      {formatMessage({ id: 'manage_Beneficiaries_no_secondary_beneficiaries' })}
    </NoSecondaryContainer>
  );
};

BeneficiarySectionNoData.contextTypes = {
  intl: PropTypes.object.isRequired
};

BeneficiarySectionNoData.propTypes = {
  type: PropTypes.string
};

BeneficiarySectionNoData.defaultProps = {
  type: 'primary'
};

export default BeneficiarySectionNoData;
