import React from 'react';
import _ from 'lodash';
import {
  localeMappingForDescription,
  fallbackLocalePriority
} from '../configure/localeDataConfig';

const implementLineBreak = function implementLineBreak(
  description,
  toLineBreak
) {
  let descriptionToUpdate = description;
  if (toLineBreak) {
    descriptionToUpdate = descriptionToUpdate.split('\n').map(item => {
      return (
        <>
          {item}
          <br />
        </>
      );
    });
  }
  return descriptionToUpdate;
};

/**
 * @returns: locale based description, incase of non availability returns based on the following logic:-
 * EN: ['TC', 'SC'], // English
   SC: ['TC', 'EN'], // Simplified Chinese
   TC: ['SC', 'EN'] // Traditional Chinese
 * @description : To fetch the  description in selected locale.
 * @argument:1) selectedLocale
 *           2) listOfDescInLocales: Array Object in the following format:
 *                                    [{language: 'EN', description:'EN'},
 *                                     {language: 'SC', description:'SC'},
 *                                     {language: 'TC', description:'TC'}]
 * }
 */
const getDescription = (
  selectedLocale,
  listOfDescInLocales,
  returnByKey = 'description',
  toLineBreak = false
) => {
  const localeForDescription = localeMappingForDescription[selectedLocale];
  let objectInSelectedLocale = _.find(listOfDescInLocales, {
    language: localeForDescription
  });
  if (objectInSelectedLocale && objectInSelectedLocale[returnByKey]) {
    return implementLineBreak(objectInSelectedLocale[returnByKey], toLineBreak);
  }
  let fallbackDescription = null;
  _.some(fallbackLocalePriority[localeForDescription], language => {
    objectInSelectedLocale = _.find(listOfDescInLocales, {
      language
    });
    if (objectInSelectedLocale && objectInSelectedLocale[returnByKey]) {
      fallbackDescription = implementLineBreak(
        objectInSelectedLocale[returnByKey],
        toLineBreak
      );
      return true;
    }
    return false;
  });
  return fallbackDescription;
};
export default getDescription;
