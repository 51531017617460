import _ from 'lodash';
import { doAND } from '../../common/util/commonUtil';
import { isValidMaturityAmount } from '../../charts/chartUtils';

const tableDataWithoutSubHeaderUtil = function tableDataWithoutSubHeaderUtil(
  args
) {
  const [tableData, header, columns, policyCurrency, data] = args;
  const { key: id, fieldLabel, fieldType, mandatoryField } = tableData[
    header
  ][0];
  if (doAND(id, fieldLabel, fieldType)) {
    columns.push({
      Header: fieldLabel,
      accessor: id,
      fieldType,
      mandatoryField
    });
  }
  const dataAccessor = {};
  _.mapValues(tableData[header], (rowData, index) => {
    if (rowData) {
      const { key, fieldData, fieldDataSecondary, dependentContent } = rowData;
      if (key) {
        dataAccessor[`${key}`] = {
          fieldData,
          policyCurrency,
          fieldType
        };
        if (doAND(fieldDataSecondary, dependentContent, fieldType)) {
          dataAccessor[`${key}`] = {
            fieldData,
            secondaryDataPrefix: dependentContent,
            fieldDataSecondary,
            fieldType
          };
        }
        data[index] = { ...data[index], ...dataAccessor };
      }
    }
  });
};

const tableDataWithSubHeaderUtil = function tableDataWithSubHeaderUtil(args) {
  const [tableData, header, policyCurrency, data, columns] = args;
  const subHeaders = Object.keys(tableData[header]);
  const subColumns = [];
  let mobileField = '';
  let typeOfField = '';
  let fieldMandatory = '';
  let fieldHasDecimal;
  _.mapValues(subHeaders, subHeader => {
    if (tableData[header][subHeader].length) {
      /**
       * tableData[header][subHeader][0] => because the basic information of all the data rows(key, fieldType etc) are same
       * so basic info is extracted from the 0'th index, otherwise if no. of subHeaders is more than data rows, it will result in code breakage.
       */
      const {
        fieldLabel,
        fieldMobile,
        fieldType,
        showField,
        mandatoryField,
        key,
        allowDecimals
      } = tableData[header][subHeader][0];
      if (doAND(fieldLabel, fieldMobile, fieldType, showField)) {
        subColumns.push({
          Header: fieldLabel,
          accessor: key,
          fieldType,
          allowDecimals
        });
        mobileField = fieldMobile;
        typeOfField = fieldType;
        fieldMandatory = mandatoryField;
        fieldHasDecimal = allowDecimals;
      }

      const dataAccessor = {};
      _.mapValues(tableData[header][subHeader], (rowData, index) => {
        if (rowData) {
          const {
            key: id,
            fieldData,
            fieldDataSecondary,
            dependentContent
          } = rowData;

          if (doAND(id, showField)) {
            dataAccessor[`${id}`] = {
              fieldData,
              policyCurrency,
              fieldType,
              allowDecimals
            };
            if (doAND(fieldDataSecondary, dependentContent)) {
              dataAccessor[`${id}`] = {
                ...dataAccessor[`${id}`],
                secondaryDataPrefix: dependentContent,
                fieldDataSecondary
              };
            }
            data[index] = { ...data[index], ...dataAccessor };
          }
        }
      });
    }
  });
  columns.push({
    Header: header,
    fieldMobile: mobileField,
    fieldType: typeOfField,
    columns: subColumns,
    mandatoryField: fieldMandatory,
    allowDecimals: fieldHasDecimal
  });
};

const tableDataUtilHelper = function tableDataUtilHelper(args) {
  const [headers, data, columns, tableData, policyCurrency] = args;
  _.map(headers, header => {
    if (tableData[header].length) {
      tableDataWithoutSubHeaderUtil([
        tableData,
        header,
        columns,
        policyCurrency,
        data
      ]);
    } else {
      tableDataWithSubHeaderUtil([
        tableData,
        header,
        policyCurrency,
        data,
        columns
      ]);
    }
    return header;
  });
};

export const tableDataUtil = function tableDataUtil(tableData, policyCurrency) {
  const reprojectionTableData = { columns: [], data: [] };
  const columns = [];
  const data = [];
  const headers = Object.keys(tableData);
  tableDataUtilHelper([headers, data, columns, tableData, policyCurrency]);
  reprojectionTableData.columns = columns;
  reprojectionTableData.data = data;
  return reprojectionTableData;
};

export const filterTabList = function filterTabList(
  tabList = {},
  apiData = {}
) {
  const { apiKeytoMatch, expectedValues } = tabList;
  let apiKeyValue = _.get(apiData, apiKeytoMatch);
  if (apiKeyValue !== undefined) {
    apiKeyValue = apiKeyValue.toString();
    return expectedValues[apiKeyValue];
  }
  return [];
};

export const getChartSeries = function getChartSeries(
  chartType,
  opacity = null
) {
  switch (chartType) {
    case 'dashed':
      return { type: 'line', lineDashStyle: [4, 4], pointsVisible: false };

    case 'line':
      return { areaOpacity: 0, type: 'area' };

    default:
      if (opacity) {
        return { type: 'area', areaOpacity: opacity };
      }
      return { type: 'area' };
  }
};

export const getRemaingBalanceLegendsData = function getRemaingBalanceLegendsData(
  payoutBenefitLegendsData,
  isLastPolicyYearSelected,
  policyCurrency,
  legendSectionID
) {
  const { nonGuarSurValAmt, guarSurValAmt } = payoutBenefitLegendsData;

  return {
    guranteedRemainingBal: {
      matPayOutAmt: isValidMaturityAmount(guarSurValAmt),
      remainingBalanceLabel: 'guaranteedRemainingBalanceLabel',
      surValAmt: guarSurValAmt,
      lastPolicyYearSelected: isLastPolicyYearSelected,
      legendID: legendSectionID,
      policyCurrency
    },
    nonGuranteedRemainingBal: {
      matPayOutAmt: isValidMaturityAmount(nonGuarSurValAmt),
      remainingBalanceLabel: 'nonGuaranteedRemainingBalanceLabel',
      surValAmt: nonGuarSurValAmt,
      lastPolicyYearSelected: isLastPolicyYearSelected,
      legendID: legendSectionID,
      policyCurrency
    }
  };
};

export const getToggleBasedLegendTotalValue = function getToggleBasedLegendTotalValue(
  totalPayoutAmount,
  guranteedPayoutAmt,
  isNonGuaranteedChecked
) {
  if (isNonGuaranteedChecked) {
    return totalPayoutAmount;
  }
  return guranteedPayoutAmt;
};

export const getPayoutAnnualLegendLabel = function getPayoutAnnualLegendLabel(
  productCategory,
  labelName,
  generateLabelKey
) {
  return generateLabelKey
    ? `${labelName}_${_.camelCase(productCategory)}`
    : labelName;
};

export const getPlanCodeList = () => ['JW1', 'JW2', 'JW3'];
