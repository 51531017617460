import _ from 'lodash';
import getDescription from '../../utils/reducerUtils';

export const chartDataForTealium = {
  columnChart: {
    chartEventType: 'click'
  },
  lineChart: {
    chartEventType: 'hover-over'
  }
};

export const getReprojectionPageDetails = function getReprojectionPageDetails(
  selectedTAB
) {
  const pageURL = _.lowerCase(selectedTAB)
    .split(' ')
    .join('-');
  const pageName = _.lowerCase(selectedTAB); // Converts string as space separated words to lower case.
  const pageType = 'product';
  return {
    page_url: `/my-insurance/policy-details/future-value-project/${pageURL}`,
    page_name: `ib:my insurance:policy details:future value project: ${pageName}`,
    page_type: pageType
  };
};

export const prodcutCategoriesForMedicalAndCI = (
  tagForMedical,
  tagForCriticalIllness
) => {
  let dynamicProductCategoryTag = '';
  if (tagForMedical) {
    dynamicProductCategoryTag = 'medical';
  }
  if (tagForCriticalIllness) {
    dynamicProductCategoryTag = 'critical illness';
  }
  return dynamicProductCategoryTag;
};

export const getPlanCodeBasedProductCategory = (
  productSubCategory,
  tagForMedical,
  tagForCriticalIllness
) => {
  return tagForMedical || tagForCriticalIllness
    ? prodcutCategoriesForMedicalAndCI(tagForMedical, tagForCriticalIllness)
    : `${_.toLower(productSubCategory)}`;
};

export const getReprojectionProductDetails = function getReprojectionProductDetails(
  productFamilyForReprojections,
  productCategory,
  planName
) {
  const planNameEN = getDescription('en', planName); // to get plan name only in English for tealium tagging
  return {
    product_category: productFamilyForReprojections,
    product_subcategory: getPlanCodeBasedProductCategory(productCategory),
    product_id: _.toLower(planNameEN)
  };
};

export const getReprojectionsTooltipContent = function getReprojectionsTooltipContent(
  eventContent
) {
  const tooltipContent = {
    tooltipTotalAmountAllocate: 'cumulative received/projected payouts',
    asOfTooltipLabel: 'as of date',
    projectedTotalPremiumPaidTooltip: 'total premiums paid',
    projectedGainsTooltip: 'projected benefits'
  };
  return tooltipContent[eventContent];
};

export const captureCheckBoxEvents = function captureCheckBoxEvents(
  toggleChkBox,
  section,
  isNonGuaranteedChecked,
  chkBoxLabel,
  trackEvents
) {
  toggleChkBox({
    section,
    includeNonGuaranteedPayout: !isNonGuaranteedChecked
  });
  trackEvents({
    eventName: 'includeNonGuranteedCheckBox',
    eventContent: chkBoxLabel
  });
  return true;
};

export const captureChartEvents = function captureChartEvents(
  selectedAge,
  maxAge,
  trackEvents,
  chartName,
  chatrInteractionType
) {
  if (
    typeof maxAge !== 'object' &&
    typeof selectedAge !== 'object' &&
    maxAge === selectedAge
  ) {
    return trackEvents({
      eventName: 'captureReprojectionChartsInteraction',
      eventContent: _.lowerCase(chartName),
      eventSubcategory: chatrInteractionType
    });
  }
  return false;
};

const reprojectionTealiumHandlers = {
  getReprojectionPageDetails,
  getReprojectionProductDetails,
  getReprojectionsTooltipContent,
  captureCheckBoxEvents
};

export default reprojectionTealiumHandlers;
