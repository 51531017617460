import styled from 'styled-components';
import { rem, em } from 'polished';
import { FontFamily } from '../utils/font';
import { Section } from '../common/styles/commonStyle';
import media from '../utils/media';
import theme from '../common/styles/theme';

export const FullWidthSectionContainer = styled.div`
  display: block;
  width: 100%;
`;
export const OverviewSection = styled.div`
  background-color: ${theme.bgParentColor};
`;
export const PolicyDetailSection = styled(Section)`
  display: block;
  margin-bottom: 0;
  padding-top: ${rem('30px')};
  padding-bottom: ${rem('20px')};
  ${media.tablet`
  padding:  ${rem('25px')}  ${rem('20px')} ${rem('20px')} ${rem('20px')};
`};
  ${media.phone`
padding:  ${rem('20px')}  ${rem('15px')} ${rem('10px')}  ${rem('15px')};
`};
`;
export const PolicyDetailWrapper = styled.div`
  margin-bottom: ${rem('10px')};
  ${media.tablet`
margin-bottom: ${rem('14px')};
`};
  ${media.phone`
margin-bottom: ${rem('11px')};
`};
`;
export const PlanNameRow = styled.div`
  margin-bottom: ${rem('20px')};
  ${media.tablet`
  margin-bottom: ${rem('14px')};
  `};
  ${media.phone`
  margin-bottom: ${rem('11px')};
  `};
`;

export const PlanNameHeader = styled.h1`
  font-size: ${em(`35px`)};
  color: ${theme.fontColor};
  line-height: ${rem('42px')};

  ${FontFamily.LightFontW01};
  ${media.tablet`
  font-size: ${em(`28px`)};
  line-height: ${rem('36px')};
  `}
  ${media.phone`
  font-size: ${em(`28px`)};
  line-height: ${rem('38px')};
  `};
`;

export const PolicyTagsWrapper = styled.div`
  margin-bottom: ${rem('8px')};
  display: flex;
  flex-wrap: wrap;
`;

export const PolicyNumber = styled.div`
  font-size: ${em(`14px`)};
  line-height: ${rem('20px')};

  ${FontFamily.LightFontW01};
`;

export const InstructionHistoryWrapper = styled(Section)`
  display: block;
  background-color: ${theme.whiteColor};
  padding-top: ${em('24px')};
`;

export const InstructionHeader = styled.div`
  padding-bottom: ${em('16px')};
  ${media.tablet`
    padding-top:${em('12px')};
    padding-bottom: ${em('12px')};
  `}
`;
export const Header = styled.h2``;

export const Text = styled.span`
  padding-left: 15px;
  font-size: ${em('28px')};
  line-height: ${em('36px')};
  ${FontFamily.LightFontW01}
  &:before {
    border-left: 3px solid rgb(219, 0, 17);
    content: '';
    right: 15px;
    position: relative;
  }
  ${media.tablet`
  font-size: ${em('23px')};
  line-height: ${em('30px')};
  `}
`;

export const RequestWrapper = styled.div`
  padding-bottom: 24px;
  ${media.tablet`
    padding-bottom: 20px;
  `}
`;
export const RequestTitle = styled.div`
  font-size: ${em('16px')};
  line-height: ${em('24px')};
  ${FontFamily.MediumFontW01}
`;
export const RequestSectionWrapper = styled.div`
  border: 1px solid #d7d8d6;
  padding: 24px 20px;
  ${media.tablet`
    padding: 20px 16px;
  `}
  ${media.phone`
    padding: 20px 16px 20px 16px;
  `}
`;
export const RequestList = styled.div`
  padding-top: ${em('16px')};
  ${media.tablet`
  padding-top: ${em('12px')};
  `}
`;

export const ToggleTitleSection = styled.div`
  width: 100%;
  display: flex;
  ${media.phone`
    flex-direction: column;
  `}
`;
export const TitleLeftSection = styled.div`
  width: 80%;
  ${media.phone`
    width: 100%;
  `}
`;
export const TitleRightSection = styled.div`
  width: 20%;
  text-align: right;
  ${media.phone`
    width : 100%;
    text-align: left;
    padding-top: 10px;
  `}
`;
export const TitleStyle = styled.div`
  font-size: ${em('16px')};
  line-height: ${em('24px')};
  ${FontFamily.RegularFontW01};
  padding-bottom: ${em('8px')};
  text-align: left;
`;

export const SubTitleStyle = styled.div`
  font-size: ${em('12px')};
  line-height: ${em('16px')};
  ${FontFamily.LightFontW01};
  display: flex;
  flex-direction: row;
  ${media.phone`
    flex-direction: column;
  `}
  > div {
    padding-right: 8px;
    ${media.phone`
      padding-right:0;
      padding-bottom: 3px;
    `}
  }
`;
export const RefNumberText = styled.div`
  padding-right: 5px;
  border-right: 1px solid #333333;
  ${media.phone`
    border: none;
  `}
`;

export const SubmitText = styled.div`
  padding-left: 5px;
  ${media.phone`
    padding-left: 0;
  `}
`;
export const StatusCode = styled.span`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${props => props.colorCode};
  margin-right: 7px;
  position: relative;
  bottom: 1px;
`;
export const StatusText = styled.span`
  font-size: ${em('14px')};
  line-height: 20px;
  ${FontFamily.RegularFontW01};
  padding-right: 9px;
`;
export const DescriptionWrapper = styled.dl`
  padding: 16px 20px 24px 0px;
  display: flex;
  flex-wrap: wrap;
  ${props => props.type === 'history' && 'padding-bottom: 0px'};
  ${media.phone`
    flex-direction: column;
    padding: 16px 20px 18px 0px;
    ${props => props.type === 'history' && 'padding-bottom: 0px'};
  `}
`;

export const PolicyNameHeader = styled.dt`
  width: 38%;
  font-size: 12px;
  line-height: 16px;
  ${FontFamily.LightFontW01};
  ${media.mediumTablet`
      width: 43%;
  `}
  ${media.phone`
    width: 100%;
    padding-bottom: 4px;
  `}
`;

export const PolicyNameValue = styled.dd`
  width: 62%;
  font-size: 12px;
  line-height: 16px;
  ${FontFamily.MediumFontW01};
  ${media.mediumTablet`
    width: 57%;
`}
  ${media.phone`
  width: 100%;
  `}
`;

export const FundDetailWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  ${media.phone`
    padding-top: 16px;
  `}
`;

export const FundTypeHeading = styled.div`
  font-size: 12px;
  line-height: 16px;
  ${FontFamily.MediumFontW01};
  padding-bottom: 8px;
`;

export const FundName = styled.dt`
  font-size: 12px;
  line-height: 16px;
  ${FontFamily.LightFontW01};
  padding-left: ${props => (props.removeLeftSpacing ? '0' : '17px')};
  width: ${props => (props.removeLeftSpacing ? '38%' : '36.5%')};
  ${media.tablet`
    width: ${props => (props.removeLeftSpacing ? '38%' : '36.2%')};
  `}
  ${media.mediumTablet`
    width: ${props => (props.removeLeftSpacing ? '43%' : '40.5%')};
`}
  ${media.phone`
    width: 100%;
    padding-bottom: 4px;
  `}
`;

export const SwitchOutName = styled(FundName)`
  width: 38%;
  padding-left: 0;
  ${media.tablet`
    width: 38%;
    padding-left: 0;
  `}
  ${media.mediumTablet`
  width: 43%;
  padding-left: 0;
`}
${media.phone`
  width: 100%;
  padding-bottom: 4px;
  padding-left: 0;
`}
`;

export const FundAllocation = styled.dd`
  font-size: 12px;
  line-height: 16px;
  ${FontFamily.MediumFontW01};
  ${props =>
    props.hideSpace
      ? `span > span > span {
    margin-right: 5px;
  }`
      : `span > span {
    margin-right: 5px;
  }`};

  ${media.phone`
  width: 100%;
  padding-left: ${props => (props.removeLeftSpacing ? '0' : '17px')};
`}
`;
export const SwitchOutAllocation = styled(FundAllocation)`
  ${media.phone`
    width: 100%;
    padding-left: 0px;
  `}
`;
export const FundNameSection = styled.div`
  display: flex;
  padding-bottom: 8px;
  ${media.phone`
    flex-direction: column;
  `}
`;

export const NoteSection = styled.div`
  font-size: 12px;
  line-height: 16px;
  ${FontFamily.LightFontW01};
  padding-top: 12px;
`;

export const HorizontalLine = styled.hr`
  color: #d7d8d6;
  margin-top: 12px;
  padding-bottom: 32px;
  border-width: 1px;
  border-style: solid none none;
`;
export const ParentLinkWrapper = styled.div`
display: flex;
align-items: center;
color: ${theme.fontColor};
line-height: ${rem('24px')};
font-size: ${rem('16px')};
padding-bottom: 10px;
${media.tablet`
line-height: ${rem('24px')};
font-size: ${rem('16px')};
`};
${media.phone`
width:15%;
line-height: ${rem('24px')};
font-size: ${rem('16px')};
`};
> a {
  padding:0
  display: flex;
  cursor: pointer;
  background: ${theme.whiteColor};
  border: none;
  color: ${theme.fontColor};
  text-decoration:none;
  &:focus, &:hover {
    outline: 1px dotted black;
  }
}
`;
export const SeeDetail = styled.span`
  display: block;
`;
export const ImgWrapper = styled.span`
  width: ${rem('8px')};
  height: ${rem('12px')};
  display: block;
  margin-right: ${rem('8px')};
`;
export const IconRightImg = styled.img`
  width: 100%;
  height: 100%;
`;
export const CancelRequestWrapper = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
color: ${theme.fontColor};
line-height: ${rem('16px')};
font-size: ${rem('12px')};
${media.tablet`
line-height: ${rem('16px')};
font-size: ${rem('12px')};
`};
${media.phone`
line-height: ${rem('16px')};
font-size: ${rem('12px')};
`};
> a {
  padding:0
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${theme.whiteColor};
  border: none;
  color: ${theme.fontColor};
  text-decoration:none;
  &:focus, &:hover {
    outline: 1px dotted black;
  }
}
`;
export const CancelImgWrapper = styled.span`
  width: ${rem('18px')};
  height: ${rem('18px')};
  display: block;
  margin-right: ${rem('8px')};
  ${media.tablet`
    width:${rem('18px')};
    height:${rem('18px')};
  `}
`;

export const NoRequestSection = styled.div`
  line-height: ${rem('20px')};
  font-size: ${rem('14px')};
  padding-top: 16px;
  ${FontFamily.LightFontW01};
`;

export const CancelRequestLink = styled.a`
  color: ${theme.fontColor};
  text-decoration: none;
  &:focus,
  &:hover {
    outline: 1px dotted white;
  }
`;
export const SubmitNotificationSection = styled.div`
  display: block;
  padding-top: 16px;
`;
export const InstructionNotificationSpan = styled.span`
  display: block;
  width: 18px;
  height: 18px;
`;

export const SubmitNotificationMessage = styled.div`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  padding-left: 8px;
`;

export const SubmitImagePath = styled.img`
  width: 100%;
  height: 100%;
  ${media.phone`
    width: auto;
    height: auto;
  `}
`;

export const ErrorButtonStyle = styled.button`
  ${FontFamily.RegularFontW01};
  color: ${theme.downIconColor};
  text-decoration: underline;
  padding-left: 3px;
  background: none;
  -webkit-appearance: none;
  display: inline;
  padding: 0;
  border: 0;
  font-size: ${rem('14px')};
  text-align: center;
  cursor: pointer;
  color: #333333;
  &:focus,
  &:hover {
    outline: 1px dotted #333;
  }
  ${media.phone`display: block;`}
`;

export const InstructionDisclaimer = styled.div`
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};
  padding-bottom: 30px;
  ${media.tablet`
    padding-bottom: 20px;
  `}
`;
export const FundCurrency = styled.span`
  margin-right: 5px;
`;

export const SettlementWrapper = styled.div`
  padding-top: 12px;
`;

export const UpdateStmtPrefSection = styled.div`
  display: flex;
  padding-bottom: 8px;
  ${media.phone`
    flex-direction: column;
  `}
`;

export const UpdateStmtPreWrapper = styled(FundDetailWrapper)``;

export const UpdateStmtPrefHeading = styled(FundTypeHeading)``;

export const NewStmtPrefLabel = styled(FundName)``;

export const NewStmtPrefValue = styled(FundAllocation)`
  span > span {
    display: block;
  }
`;
