import styled from 'styled-components';
import { em, rem } from 'polished';
import theme from '../../common/styles/theme';
import media from '../../utils/media';
import { FontFamily } from '../../utils/font';

export const ContactInfoWrapper = styled.div`
  display: block;
  align-items: center;
  margin: ${props => (props.isFor ? 0 : `${rem('30px')} 0 ${rem('30px')} 0`)};
  padding: ${rem('38px')} ${rem('40px')} ${rem('30px')} ${rem('40px')};
  background: ${theme.whiteColor};
  border: 1px solid #d9d9d9;
  ${media.mediumTablet`
  margin: ${props => (props.isFor ? 0 : `${rem('24px')} 0 ${rem('24px')} 0`)};
  padding: ${rem('30px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};
  `}
  ${media.phone`
  margin: ${props => (props.isFor ? 0 : `${rem('15px')} 0 ${rem('15px')} 0`)};
  padding: ${rem('15px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
  `}
`;

export const ContactExceptionInfoWrapper = styled.div`
  display: block;
  align-items: center;
  margin: ${props => (props.isFor ? 0 : `${rem('30px')} 0 ${rem('30px')} 0`)};
  padding: ${rem('38px')} ${rem('40px')} ${rem('30px')} ${rem('40px')};
  background: ${theme.whiteColor};
  border: 1px solid #d9d9d9;
  ${media.mediumTablet`
  margin: ${props => (props.isFor ? 0 : `${rem('24px')} 0 ${rem('24px')} 0`)};
  padding: ${rem('30px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};
  `}
  ${media.phone`
  margin: ${props => (props.isFor ? 0 : `${rem('15px')} 0 ${rem('15px')} 0`)};
  padding: ${rem('15px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
  `}
`;

export const ContactInfoHeader = styled.div`
  display: flex;
`;
export const ContactImgWrapper = styled.span`
  width: ${rem('25px')};
  height: ${rem('25px')};
  ${media.phone`
  width: ${rem('18px')};
  height: ${rem('18px')};
  position: relative;
  top: ${rem('4px')};
  `}
`;
export const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
`;
export const ContactInfoLabel = styled.h2`
   font-weight:normal
  ${FontFamily.LightFontW01}
  font-size:${em('28px')};
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  padding-left: ${rem('16px')};
  ${media.tablet`
    font-size:${em('24px')};
  `}
  ${media.phone`
    font-size:${em('20px')};
    padding-left:${rem('10px')};
  `}
`;
export const ContactInfoBody = styled.div`
  margin: ${rem('18px')} ${rem('5px')} ${rem('27px')} ${rem('5px')};
  ${media.mediumTablet`
    margin: ${rem('26px')} 0 ${rem('22px')} 0;
`}
  ${media.phone`
  margin : ${rem('17px')} 0 ${rem('18px')} 0;
`}
`;
export const ContactInfoFooter = styled.div`
  ${FontFamily.RegularFontW01}
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  font-size: ${rem('16px')};
  display:flex;
  justify-content:flex-end;
`;
export const UpdateContactLink = styled.a`
  padding:0
  display: flex;
  cursor: pointer;
  background: white;
  border: none;
  color: ${theme.fontColor};
  text-decoration:none;
  justify-content: flex-end
  &:focus, &:hover {
    outline: 1px dotted black;
  }
  ${media.phone`
  width:100%;
  align-items: flex-end;
`}
`;
export const UpdateContactLabel = styled.span`
  display: block;
  margin-right: ${rem('9px')};
  ${media.phone`
    width:100%;
  `}
`;
export const UpdateIconWrapper = styled.span`
  width: ${rem('7.8px')};
  height: ${rem('12px')};
  position: relative;
  top: ${rem('1px')};
  ${media.phone`
    width:${rem('9.7px')};
    height:${rem('16px')};
    top:0;
  `}
`;
export const ContactInfoRow = styled.dl`
  margin: 0;
  width: 100%;
  font-size: ${em('14px')};
  color: ${theme.greyNumberColor};
  display: flex;
  flex-wrap: wrap;
`;

const contactInfoCommonStyle = `
  margin: 0;
  padding: ${rem('12px')} 0 ${rem('12px')} 0;
  border-bottom: 1px solid ${theme.lightGreyColor};
  width: 50%;
  flex-grow: 1;
`;
export const ContactInfoLeft = styled.dt`
  ${contactInfoCommonStyle}
  text-align: left;
  line-height: ${rem('18px')};
  ${media.phoneToMiniTablet`
    width:50%;
  `}
  ${media.phone`
    width:50%;
  `}
`;
export const ContactInfoRight = styled.dd`
  ${contactInfoCommonStyle}
  text-align: right;
  word-break: break-word;
  ${props =>
    props.fontChange ? FontFamily.RegularFontW01 : FontFamily.BoldFontW01};
  color: ${theme.fontColor};
  line-height: ${rem('18px')};
  ${media.phoneToMiniTablet`
    width:50%;
  `}
  ${media.phone`
    width:50%;
  `}
`;
export const ContactCardBody = styled.div`
  margin-top: ${rem('30px')};
  display: flex;
  width: 100%;
  ${media.tablet`
    margin-top: ${rem('25px')};
`}
  ${media.phone`
    margin-top : ${rem('20px')};
    flex-direction:column;
`}
`;

export const ContactCardLeft = styled.div`
  width: 30%;
  ${media.tablet`
    width:45%;
  `}
  ${media.mediumTablet`
    padding-right: ${rem('20px')};
  `}
  ${media.phone`
    width:100%;
    padding:0;
  `};
`;

export const ContactCardRight = styled.div`
  width: 70%;
  ${media.tablet`
    width:55%;
  `}
  ${media.phone`
    width:100%;
  `}
`;

export const ContactCardImageGeneral = styled.div`
  width: 255px;
  height: 155px;
  ${media.mediumTablet`
      width:308px;
      height:187px;
      padding-top:${rem('20px')};
    `}
  ${media.phone`
      width:100%;
      padding:0;
    `}
`;

export const ContactCardImage = styled.div`
  width: 354px;
  height: 215px;
  ${media.mediumTablet`
      width:308px;
      height:187px;
      padding-top:${rem('20px')};
    `}
  ${media.phone`
      width:100%;
      padding:0;
    `}
`;

export const ContactCardDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  ${media.tablet`
      flex-direction:column;
    `}
`;
export const ContactCardDetails = styled.div`
  padding: ${rem('50px')} ${rem('36px')} ${rem('50px')} ${rem('36px')};
  position: relative;
  width: ${props => (props.cardInfoWidth ? `${props.cardInfoWidth}%` : '50%')};
  word-break: ${props => (props.cardInfoWidth ? 'break-word' : 'normal')};
  > span {
    display: block;
  }
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: ${props =>
      props.cardHeight
        ? `calc(${props.cardHeight}px - 85px)`
        : `${rem('97px')}`};
    top: ${rem('52px')};
    right: 0;
    background: ${theme.bgGreyColor};
  }
  &:last-of-type {
    &:after {
      visibility: hidden;
    }
  }
  ${media.tablet`
      width:100%;
      padding : ${rem('30px')} 0 ${rem('30px')} 0;
      box-sizing: border-box;
      border-bottom: solid 1px #d8d8d8;
      &:last-of-type {
        padding-bottom:${rem('20px')};
        border-bottom:none;
      }
      &:after {
        display: none;
      }
  `}
  ${media.phone`
    padding : ${rem('15px')} 0 ${rem('15px')} 0;
    &:last-of-type {
      padding-bottom:${rem('5px')};
    }
  `}
  span#N5_0_span {
    @media (max-width: 360px) {
      width: calc(100% - 90px);
    }
  }
`;
export const ContactCardNumber = styled.span`
  font-size: 20px;
  line-height: 20px;
  ${FontFamily.BoldFontW01};
  padding-bottom: 10px;
  ${media.tablet`
    font-size:18px;
  `}
`;

export const ContactCardDesc = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    display: block;
  }
`;
export const ContactCardTitle = styled.span`
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
  ${media.tablet`
    font-size:14px;
  `}
`;

export const ContactCardText = styled.div`
  margin-top: 30px;
  font-size: 14px;
  ${media.tablet`
      margin-top: ${rem('25px')};
      line-height:20px;
    `}
  ${media.phone`
      margin-top : ${rem('20px')};
    `}
`;

export const ContactNumberText = styled.span`
  padding-top: 5px;
`;

export const ContactCardDetailsGeneralWrapper = styled.div`
  width: 100%;
`;
export const ContactCardDetailsGeneral = styled.div`
  padding: ${rem('30px')} ${rem('0px')} ${rem('30px')} ${rem('0px')};
  border-bottom: solid 1px #d8d8d8;
  &:first-of-type {
    padding-top: ${rem('20px')};
  }
  &:last-of-type {
    padding-bottom: ${rem('20px')};
    border-bottom: none;
  }
  ${media.phone`
    padding: ${rem('11px')} ${rem('0px')} ${rem('14px')} ${rem('0px')};
    border-bottom: solid 1px #d8d8d8;
    &:first-of-type {
      padding-top: ${rem('15px')};
    }
    &:last-of-type {
      padding-bottom: ${rem('10px')};
      border-bottom: none;
    }
  `}
`;
export const ContactCardNumberGeneral = styled.span`
  float: right;
  font-size: 22px;
  line-height: 20px;
  ${FontFamily.MediumFontW01};
  padding-bottom: 10px;
  ${media.tablet`
    float: none;
    display: block;
    font-size: 19px;
    padding-bottom: 0px;
    padding-top: 10px;
  `}
  ${media.phone`
    float: none;
    display: block;
    font-size: 19px;
    padding-bottom: 0px;
    padding-top: 10px;
  `}
`;
export const ContactCardDescGeneral = styled.div`
  span {
    font-size: 14px;
    line-height: 20px;
    display: block;
    padding-top: ${rem('8px')};
  }
`;
export const ContactCardImageGeneralWrapper = styled.div`
  margin-top: ${rem('20px')};
  ${media.phone`
    margin-top: ${rem('0px')};
  `}
`;
