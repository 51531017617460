import React from 'react';
import PropTypes from 'prop-types';
import { HeaderDependentSpan } from '../../reprojectionPageStyle';

const FutureValuePaymentOptionText = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { labelContent, fieldData } = props;
  if (!labelContent || fieldData === undefined || fieldData === null) {
    return <></>;
  }

  return (
    <HeaderDependentSpan data-test="paymentOption" id={`${labelContent}`}>
      {formatMessage({
        id: `${labelContent}`
      })}
    </HeaderDependentSpan>
  );
};
FutureValuePaymentOptionText.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.bool.isRequired
};

FutureValuePaymentOptionText.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FutureValuePaymentOptionText;
