import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  DisclaimerSection,
  DisclaimerHeader,
  DisclaimerBody
} from '../common/styles/commonStyle';
import TooltipWrapper from '../common/tooltipWrapper/TooltipWrapper';

const DisclaimerSectionNote = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { policySuperFamily } = props;
  let pageDisclaimer = '';
  if (policySuperFamily) {
    // to handle Policy Details - Disclaimer based on superFamily GI or Life
    pageDisclaimer = (
      <>
        <span>
          {formatMessage({
            id: `policyDisclaimer_line1${policySuperFamily}`
          })}
        </span>
        <span>
          {formatMessage({
            id: `policyDisclaimer_line2${policySuperFamily}`
          })}
        </span>
        {policySuperFamily !== 'life' && (
          <>
            <span>
              {formatMessage({
                id: `policyDisclaimer_line3${policySuperFamily}`
              })}
            </span>
            <span>
              {formatMessage({
                id: `policyDisclaimer_line4${policySuperFamily}`
              })}
            </span>
          </>
        )}
        {policySuperFamily === 'life' && (
          <FormattedMessage
            id="policyDetailsDisclaimer_line3"
            aria-label={formatMessage({
              id: `policyDetailsDisclaimer_line3`
            })}
            values={{
              policyDetailsTooltip: (
                <TooltipWrapper
                  id="tooltip_detail_disclaimer"
                  labelContent="policyDetailsTooltipDisclaimer"
                  contentKey="policyDetailsTooltipDisclaimer"
                  maxWidth={350}
                  isFormatHtml
                  sectionHeading="policyDetailsPageDisclaimer"
                />
              )
            }}
          />
        )}
      </>
    );
  } else {
    // to handle Policy Summary - Disclaimer
    pageDisclaimer = (
      <>
        <span>{formatMessage({ id: `policyDisclaimer_line1gi` })}</span>
        <span>{formatMessage({ id: `policyDisclaimer_line2gi_summary` })}</span>
        <span>{formatMessage({ id: `policyDisclaimer_line3gi` })}</span>
        <span>{formatMessage({ id: `policyDisclaimer_line4gi` })}</span>
      </>
    );
  }

  if (!pageDisclaimer) {
    return <></>;
  }

  return (
    <>
      <DisclaimerSection id="disclaimer">
        <DisclaimerHeader>
          {formatMessage({
            id: 'disclaimer'
          })}
        </DisclaimerHeader>
        <DisclaimerBody>{pageDisclaimer}</DisclaimerBody>
      </DisclaimerSection>
    </>
  );
};

DisclaimerSectionNote.propTypes = {
  policySuperFamily: PropTypes.string
};
DisclaimerSectionNote.defaultProps = {
  policySuperFamily: ''
};
DisclaimerSectionNote.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default DisclaimerSectionNote;
