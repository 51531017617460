import {
  SET_E_WELCOME_DOCUMENT_RETRIEVE,
  SET_E_WELCOME_GET_DOCUMENTS,
  E_WELCOME_RESET_DOCUMENTS_ERRORS_WARNINGS,
  SET_E_WELCOME_DOCUMENTS_ERRORS_WARNINGS,
  E_WELCOME_START_LOADER,
  POLICY_DETAILS_START_LOADER,
  SET_E_WELCOME_DOWNLOAD_ALL_DETAILS,
  E_WELCOME_END_LOADER,
  END_LOADER
} from '../actionTypes';

export const INITIAL_STATE = {
  documentsList: {},
  documentsWarningsOrErrors: {},
  isEWelcomeDocumentsLoading: false,
  isPolicyDetailsLoading: false
};

const eWelcomePolicyDocumentsReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case E_WELCOME_START_LOADER: {
      return {
        ...state,
        isEWelcomeLoading: true
      };
    }
    case POLICY_DETAILS_START_LOADER: {
      return {
        ...state,
        isPolicyDetailsLoading: true
      };
    }
    case E_WELCOME_END_LOADER: {
      return {
        ...state,
        isEWelcomeLoading: false
      };
    }
    case END_LOADER: {
      return {
        ...state,
        isPolicyDetailsLoading: false
      };
    }
    case SET_E_WELCOME_GET_DOCUMENTS: {
      const { currentIdentifier, documentData } = payload;
      const { documentsList } = state;
      return {
        ...state,
        documentsList: {
          ...documentsList,
          [currentIdentifier]: documentData
        }
      };
    }
    case SET_E_WELCOME_DOCUMENT_RETRIEVE: {
      const { currentIdentifier, documentData, encodePolicyNumber } = payload;
      const { documentsList } = state;
      return {
        ...state,
        documentsList: {
          ...documentsList,
          [encodePolicyNumber]: {
            ...documentsList[encodePolicyNumber],
            documentData: {
              ...documentsList[encodePolicyNumber].documentData,
              [currentIdentifier]: documentData
            }
          }
        }
      };
    }
    case SET_E_WELCOME_DOWNLOAD_ALL_DETAILS: {
      const { documentData, encodePolicyNumber } = payload;
      const { documentsList } = state;
      return {
        ...state,
        documentsList: {
          ...documentsList,
          [encodePolicyNumber]: {
            ...documentsList[encodePolicyNumber],
            downloadAllContent: documentData.content
          }
        }
      };
    }
    case SET_E_WELCOME_DOCUMENTS_ERRORS_WARNINGS: {
      const warnings = {
        ...state.documentsWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        documentsWarningsOrErrors: warnings
      };
    }
    case E_WELCOME_RESET_DOCUMENTS_ERRORS_WARNINGS: {
      return {
        ...state,
        documentsWarningsOrErrors: {}
      };
    }
    default:
      return state;
  }
};

export default eWelcomePolicyDocumentsReducer;
