import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import {
  ContactCardDetailsGeneral,
  ContactCardNumberGeneral,
  ContactCardDescGeneral,
  ContactCardTitle,
  ContactNumberText
} from '../sections/contactInfoStyles';
import ContactLink from '../../common/contactLink/contactLink';
import TooltipWrapper from '../../common/tooltipWrapper/TooltipWrapper';
import { isMiniMobile } from '../../utils/domUtils';

const ContactGeneralField = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { fieldData, contactUsConfig } = props;

  const [width, setWidth] = useState(window.innerWidth);
  const cardRef = useRef(null);

  const updateWidth = () => {
    setWidth(document.body.clientWidth);
  };

  useEffect(() => {
    /* eslint-disable no-console */
    console.log(width);
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });

  if (!fieldData || typeof fieldData !== 'string') {
    return <></>;
  }

  const getTooltipWidth = () => {
    return isMiniMobile() ? 170 : 300;
  };

  const removeSpaces = str => {
    return _.trim(str);
  };

  const generateCardInfoWidth = uniqueKeys => {
    const totalUniqueKeys = uniqueKeys && uniqueKeys.length;
    return 100 / totalUniqueKeys;
  };

  const getCardElementHeight = cardInfoRef => {
    return (
      cardInfoRef && cardInfoRef.current && cardInfoRef.current.offsetHeight
    );
  };

  const listOfUniqueKeys = _.split(removeSpaces(fieldData), '-');
  const cardInfoWidth = generateCardInfoWidth(listOfUniqueKeys);

  return _.map(listOfUniqueKeys, (currentUniquKey, idx) => {
    const currentKey = removeSpaces(currentUniquKey);
    const capturedKey = contactUsConfig[currentKey];
    const {
      contactLabel,
      contactNumber,
      operationHours,
      toolTipContent,
      dependentContent
    } = capturedKey;
    const cardInfoID = `${currentKey}_${idx}`;
    return (
      <>
        <ContactCardDetailsGeneral
          id={cardInfoID}
          cardInfoWidth={cardInfoWidth}
          ref={cardRef}
          cardHeight={getCardElementHeight(cardRef)}
        >
          <ContactCardTitle id={`${currentKey}_${idx}_span`}>
            {formatMessage({ id: contactLabel })}
            {toolTipContent && (
              <TooltipWrapper
                id={`${currentKey}_${idx}_tooltip`}
                contentKey={toolTipContent}
                sectionHeading="contactCardGeneral"
                labelContent={contactLabel}
                maxWidth={getTooltipWidth()}
              />
            )}
          </ContactCardTitle>
          <ContactCardNumberGeneral>
            <ContactLink
              sentenceWithContact="emergencyHotLineNumber"
              contactsList={[contactNumber]}
              typeOfCall="emergency hotline"
            />
          </ContactCardNumberGeneral>
          <ContactCardDescGeneral isGeneral>
            <span>{formatMessage({ id: operationHours })}</span>
            {dependentContent && (
              <ContactNumberText>
                {currentKey === 'N5' ? (
                  <FormattedHTMLMessage id={dependentContent} />
                ) : (
                  formatMessage({ id: dependentContent })
                )}
              </ContactNumberText>
            )}
          </ContactCardDescGeneral>
        </ContactCardDetailsGeneral>
      </>
    );
  });
};

ContactGeneralField.propTypes = {
  fieldData: PropTypes.string.isRequired,
  contactUsConfig: PropTypes.object.isRequired
};

ContactGeneralField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ContactGeneralField;
