import styled, { createGlobalStyle, css } from 'styled-components';
import { em, rem } from 'polished';
import theme from './theme';
import media from '../../utils/media';
import { FontFamily } from '../../utils/font';
import { SummaryParentSection } from '../../policysummary/summaryPageStyles';

export const GlobalStyle = createGlobalStyle`
 body {
  font-family: "UniversNextforHSBC-Regular","Arial","PingFang SC","Microsoft YaHei","Lantinghei SC","Heiti SC","simhei","sans-serif";
  font-size: ${em('16px')};
  overflow-x: hidden;

  dl,dt,dd,p{
    padding:0;
    margin:0;
  }
}
.ReactModal__Body--open{
  position: fixed;
  left: 0;
  right: 0;
  overflow: hidden;
}
[role="button"]:focus{
  outline: 1px dotted black;
}
.ReactModal__Content--after-open{
  overflow-x: hidden !important;
}
${media.phone`
.ReactModal__Overlay--after-open.overlay{
  background-color: white;
}
.ReactModal__Content--after-open{
  position: absolute;
  top: 1%;
}
`};

#ModalWithoutScroll {
  height: 390px;
  max-height: 100%;
  ${media.tablet`
    height: auto;
    width: auto;
`};
${media.phone`
    height: auto;
    width: auto;
`};
}

`;

export const FullWidthSection = styled.div`
  display: block;
`;
export const FullWidthSectionContainer = styled.div`
  display: block;
  width: 100%;
`;

export const NotificationSection = styled.section`
  display: block;
  background: ${theme.bgHeaderColor};
  padding: ${rem('45px')};
  ${media.phone`
  padding:${rem('60px')};
`};
`;

export const ParentContainer = styled.div`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: ${theme.bgParentColor};
  ${FontFamily.RegularFontW01};
`;

export const MainContainer = styled.main``;

export const FooterSection = styled.footer`
  height: auto;
  clear: both;
`;

export const SectionContainer = styled.div``;

export const Section = styled.section`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: ${em(theme.maxWidth)};
  width: 100%;
  margin: 0 auto;
  clear: both;
  background-color: transparent;
  padding: 0 ${rem('50px')};
  margin-bottom: ${rem('20px')};

  ${media.tablet`
		padding: 0 ${rem('18px')};
    `} ${media.phone`
		padding: 0 ${rem('14px')};
	`};
`;

export const HeaderSection = styled.header`
  background-color: white;
  color: black;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  border-bottom: 1px solid ${theme.lightGreyColor};
`;

export const IntroSection = styled.section`
  display: block;
  background: ${theme.whiteColor};
  padding: ${rem('150px')};
  ${media.tablet`
		padding:  ${rem('83px')};
    `} ${media.phone`
		padding:${rem('112px')};
	`};
`;

export const DisclaimerSection = styled(Section)`
  padding-top: ${rem('10px')};
  padding-bottom: ${rem('20px')};
`;

export const DisclaimerHeader = styled.span`
  ${FontFamily.BoldFontW01};
  line-height: ${rem('20px')};
  letter-spacing: 0;
  color:${theme.fontColor}
  font-size:${rem('14px')};

`;
export const DisclaimerBody = styled.div`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  letter-spacing: 0;
  margin: ${rem('10px')} 0 0 0;
  > span {
    display: block;
    line-height: ${rem('20px')};
    padding-bottom: ${rem('10px')};
    & ol li {
      padding-bottom: ${rem('5px')};
      line-height: ${rem('18px')};
    }
  }
`;

export const hideClass = `
	display:none;
`;

export const AccessibleHidden = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  ${props => props.toHide && hideClass};
`;

export const DivComponent = styled.div`
  position: relative;
  display: ${props => (props.toDisplay ? 'block' : 'none')};
`;

export const UL = styled.ul`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d7d8d6;
  list-style: none;
  margin: 0;
  padding: ${rem('45px')} 0 0 ${rem('28px')};
  ${media.tablet`
    padding: ${rem('35px')} 0 0 ${rem('18px')};
  `};
  ${media.phone`
    padding: ${rem('25px')} 0 0 0;
`};
`;

const commonReproTabStyle = css`
  background: ${theme.whiteColor};
  box-shadow: ${props =>
    props.selectedTab
      ? `inset 0 ${rem('1px')} 0 0 ${theme.lightGreyColor},inset 0 ${rem(
          '5px'
        )} 0 0 ${theme.stepSelected}`
      : `${rem('1px')} 0 0 0 ${theme.lightGreyColor}, inset 0 ${rem(
          '1px'
        )} 0 0 ${theme.lightGreyColor}`};
  &:after {
    box-shadow: none;
  }
`;

const renderReproTabLI = css`
  box-shadow: ${props =>
    props.selectedTab
      ? `inset 0 ${rem('5px')} 0 -1px ${theme.stepSelected}`
      : 'none'};
  background: ${props =>
    props.selectedTab ? `${theme.whiteColor}` : `${theme.stepDefault}`};
  &:last-child:after {
    box-shadow: none;
  }

  &:active {
    box-shadow: ${props =>
      props.selectedTab
        ? `inset ${rem('1px')} ${rem('1px')} 0 0 ${theme.lightGreyColor},${rem(
            '1px'
          )} 0 0 0 ${theme.lightGreyColor},inset 0  ${rem('5px')} 0 0 ${
            theme.stepSelected
          }`
        : 'none'};
  }
  &:hover {
    ${commonReproTabStyle}
  }
  &:focus {
    outline: ${rem('1px')} dotted ${theme.fontColor};
    ${commonReproTabStyle}
  }
`;
export const LI = styled.li`
  font-size: ${em('16px')};
  cursor: pointer;
  padding: ${rem('17px')} ${rem('15px')};
  text-align: center;
  justify-content: center;
  display: inline-block;
  float: none;
  vertical-align: middle;
  &:focus,
  &:hover {
    outline: ${rem('1px')} dotted ${theme.fontColor};
  }

  ${props => props.sectionStyle === 'reprojection' && renderReproTabLI}

  ${media.phone`
    font-size: ${em('14px')};
  `}
`;
export const TabLabel = styled.span`
  cursor: pointer;
  padding: 0 0 ${rem('14px')} 0;
  border-bottom: ${props => (props.selectedTab ? '4px solid #db0011' : 'none')};
  font-weight: ${props => (props.selectedTab ? 'bold' : 'normal')};
`;

export const WhiteSpaceContainer = styled.div`
  background: white;
  height: calc(100vh - 431px);
  ${media.tablet`
  height:  calc(100vh - 460px);
`};
  ${media.phone`
  height:  calc(100vh - 608px);
`}
`;

export const SliderWrapper = styled.div`
  width: 95%;
  padding-left: ${rem('55px')};
  margin-bottom: ${rem('40px')};
  ${media.tablet`
    width: 94%;
    margin-bottom: ${rem('30px')};
  `};
  ${media.mediumTablet`
    width: 92%;
  `};
  @media only screen and (max-width: 414px) and (min-width: 386px) {
    width: 78%;
    padding-left: ${rem('63px')};
  }
  @media only screen and (max-width: 385px) and (min-width: 360px) {
    width: 75%;
    padding-left: ${rem('64px')};
  }
  @media only screen and (max-width: 359px) {
    width: 71%;
    padding-left: ${rem('65px')};
  }
`;

export const CustomInputBoxWrapper = styled.div`
  width: ${rem('146px')};
  height: ${rem('42px')};
  border: 1px solid ${theme.subLinksText};
  background-color: ${theme.whiteColor};
  display: flex;
  padding: 6px 0;
  box-sizing: border-box;
  margin: 0 ${rem('20px')} 0 ${rem('22px')};
  ${media.phone`
      margin: 0 ${rem('10px')} 0 ${rem('10px')};
      width: ${rem('129px')};
  `};
`;
export const InputBoxButton = styled.button`
  border-right: ${props =>
    props.id === 'minusButton' ? `1px solid ${theme.bgGreyColor}` : `none`};
  border-left: ${props =>
    props.id === 'plusButton' ? `1px solid ${theme.bgGreyColor}` : `none`};
  text-align: center;
  width: 30%;
  cursor: pointer;
  padding: 5px 0;
  background: transparent;
  border-top: 0;
  border-bottom: 0;
  line-height: 0;
  &:focus {
    outline: ${rem('1px')} dotted ${theme.fontColor};
  }
`;

export const InputBoxImage = styled.img`
  width: ${rem('18px')};
  height: ${rem('18px')};
  vertical-align: middle;
`;

export const InputField = styled.input`
  display: inline-block;
  margin: 0;
  vertical-align: top;
  box-shadow: none;
  width: 40%;
  padding: 0 5px;
  text-align: center;
  border: transparent;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

/**
 *  Overview section - Tags
 */

export const TagValue = styled.span`
font-size: ${rem('16px')};
  line-height: ${rem('20px')};
  ${FontFamily.RegularFontW01};
  padding-right: 15px;
  margin-right: 10px;
  box-sizing: border-box;
  color:${theme.greyNumberColor}
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 2px;
    background: ${theme.greyNumberColor};
    right: 0;
    top: 40%;
    display:${props => (props.styleUpdate ? `block` : `none`)};
    border-radius: 50%;
  }
`;
/**
 * Error/Warning css used across application
 */
export const NotificationMessageWrapper = styled(SummaryParentSection)`
  /* To check whether all of 3 banners are display on UI using props */
  ${props =>
    !props.isAllNotificationsVisible
      ? `padding: ${rem('17px')} ${rem('50px')} ${rem('7px')}`
      : null}
  ${media.tablet`
    ${props =>
      !props.isAllNotificationsVisible
        ? `padding: ${rem('17px')} ${rem('24px')} ${rem('7px')}`
        : null}
  `};
`;
export const ImagePath = styled.img`
  height: ${rem('21px')};
  width: ${rem('21px')};
  vertical-align: bottom;
  padding-right: ${rem('10px')};
  ${props =>
    props.rpqNotification &&
    `
    height: ${rem('18px')};
    width: ${rem('18px')};
  `}
`;

export const NotificationMessageIcon = styled.div`
  display: flex;
  align-items: normal;
  padding-bottom: ${rem('5px')};
  ${props =>
    props.rpqNotification &&
    `
    padding-bottom: 0;
  `}
`;
export const NotificationWrapper = styled.div`
  margin-top: ${rem('41px')};
  ${media.tablet`
  margin-top: ${rem('31px')};
`};
  ${media.phone`
margin-top: ${rem('30px')};
`};
`;

export const NotificationMessageType = styled.div`
  display: block;
  font-size: ${rem('14px')};
  padding: ${rem('15px')};
  margin-bottom: ${rem('10px')};
  &:last-child {
    margin-bottom: ${props => (props.paymentAlert ? rem('20px') : rem('30px'))};
  }
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor};
  ${media.tablet`
  &:last-child {
    margin-bottom: ${props =>
      props.type === 'cardLevel' ? '0px' : rem('20px')};  }
  `}
  ${media.phone`
  &:last-child {
    margin-bottom: ${props =>
      props.type === 'cardLevel' ? '0px' : rem('20px')};
  }
  `}
  ${props =>
    props.rpqNotification &&
    `
    &:last-child {
      margin-bottom:16px;
    }
    margin:16px 0;
    padding: ${rem('13px')};
    @media (max-width: 667px) {
      margin-top:0;
      &:last-child {
        margin-top:0;
      }
    }
  `}
  ${props =>
    props.unchangeRPQ &&
    ` padding:0;
  `}

`;
export const NotificationMessageErrorType = styled.div`
  display: block;
  font-size: ${rem('14px')};
  padding: ${rem('15px')};
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor};
`;

export const NotificationIconSpan = styled.span`
  display: block;
  height: ${rem('18px')};
  width: ${rem('18px')};
  margin-right: ${rem('5px')};
`;

export const NotificationMessage = styled.div`
  span {
    font-size: ${rem('14px')};
    line-height: ${props => (props.paymentAlert ? rem('22px') : rem('17px'))};
    display: block;
    ${media.phone`
    display:${props => (props.rpqNotification ? 'contents' : 'inline')};
    `}
  }
  ${props =>
    props.rpqNotification &&
    `
    display: flex;
    align-items: flex-end;

  `}
`;

export const ExceptionLapsedNotificationMessage = styled(NotificationMessage)`
  > span {
    font-size: ${rem('14px')};
    line-height: 25px;
    margin-left: 5px;
    span {
      display: inline;
    }
  }
`;

export const IconDiv = styled.span`
  font-size: 24px;
  &:focus {
    outline: 1px dotted #333;
    outline: -webkit-focus-ring-color auto 5px;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    &:focus {
      outline: 1px dotted #333;
    }
  }
`;
export const BoldTextSpan = styled.span`
  font-size: ${rem('16px')};
  ${FontFamily.MediumFontW01};
  padding: 0 2px;
`;

export const NotificationTextSpan = styled.span`
  span {
    font-size: ${rem('16px')}!important;
    line-height: 24px;
  }
  display: flex !important;
  flex-wrap: wrap !important;
  font-size: ${rem('16px')}!important;
  line-height: 24px;
  ${FontFamily.RegularFontW01};
  padding: 0 2px;
  button {
    line-height: 1.0625rem;
    font-size: ${rem('16px')}!important;
    padding: 0 2px;
    border: 1px solid transparent;
    display: inline !important;
    ${media.phone`
    display: contents !important;
   `}
  }
  button:hover {
    border-bottom: 1px solid ${theme.fontColor};
  }
`;
export const RPQNotificationWrapper = styled.div`
  ${media.phone`
    padding: 0 15px;
  `}
`;

export const SectionRow = styled.section`
  box-sizing: border-box;
  max-width: ${rem(theme.maxWidth)};
  height: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem('50px')};
  ${media.tablet`
    padding: 0 ${rem('24px')};
  `}
  ${media.phone`
    padding: 0 ${rem('14px')};
  `}
`;
