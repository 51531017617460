import React from 'react';
import PropTypes from 'prop-types';
import RpqPopupTableDesktop from './RpqPopupTableDesktop';
import RpqPopupTableMobile from './RpqPopupTableMobile';
import { ShowIfDesktop, ShowIfMobile } from '../../../../utils/showIf';
import { getFundPerformanceColumnData } from '../../../sectionUtils/FundUtils';

const RpqPopupTableFormatter = function RpqPopupTableFormatter(props) {
  const {
    fundsColumnData,
    fundsTableData,
    matchedRiskLevel,
    rpqUTBConfig
  } = props;
  let fundPerformanceColumnData = [];
  if (fundsTableData === undefined || fundsTableData === null) {
    return <></>;
  }
  if (fundsTableData) {
    const { fundPerformanceDetails } = fundsTableData;
    fundPerformanceColumnData =
      fundPerformanceDetails &&
      getFundPerformanceColumnData(fundPerformanceDetails);
  }
  return (
    <>
      <ShowIfDesktop>
        <RpqPopupTableDesktop
          fundsColumnData={fundsColumnData}
          fundsTableData={fundsTableData}
          fundPerformanceColumnData={fundPerformanceColumnData}
          matchedRiskLevel={matchedRiskLevel}
          rpqUTBConfig={rpqUTBConfig}
        />
      </ShowIfDesktop>
      <ShowIfMobile>
        <RpqPopupTableMobile
          fundsColumnData={fundsColumnData}
          fundsTableData={fundsTableData}
          fundPerformanceColumnData={fundPerformanceColumnData}
          matchedRiskLevel={matchedRiskLevel}
          rpqUTBConfig={rpqUTBConfig}
        />
      </ShowIfMobile>
    </>
  );
};

RpqPopupTableFormatter.propTypes = {
  fundsColumnData: PropTypes.array.isRequired,
  fundsTableData: PropTypes.object.isRequired,
  matchedRiskLevel: PropTypes.object.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired
};

export default RpqPopupTableFormatter;
