const dashboardPageCategory = 'accounts';
const dashboardPageSubCategory = 'transactions and details';
const policyReady =
  '/accounts/transcations-and-details/your-policy-is-now-ready';
const policyReadyContent =
  'ib:accounts:transcations and details:your policy is now ready';
const bendetFunnelName = 'change beneficiary';
const estmtPageUrl =
  '/accounts/transcations-and-details/statement-preferences/select';
const estmtPageName =
  'ib:accounts:transcations and details:statement preferences:select';
const estmtConfirmationPageUrl =
  '/accounts/transcations-and-details/statement-preferences/confirmation';
const estmtConfirmationPageName =
  'ib:accounts:transcations and details:statement preferences:confirmation';

const tealiumMapping = {
  commonTags: {
    page_business_line: 'rbwm',
    page_customer_group: 'general',
    page_security_level: '30',
    site_section: 'ib'
  },
  commonDashboardTags: {
    page_category: dashboardPageCategory,
    page_subcategory: dashboardPageSubCategory
  },
  commonReprojectionPageTags: {
    page_category: 'my insurance',
    page_subcategory: 'policy details'
  },
  summaryPage: {
    page_url: '/accounts/transactions-and-details/policy-summary',
    page_name: 'ib:accounts:transactions and details:policy summary',
    page_type: 'category'
  },
  detailsPage: {
    page_url: '/accounts/transactions-and-details/insurance',
    page_name: 'ib:accounts:transactions and details:insurance',
    page_type: 'product'
  },
  instructionPage: {
    page_url:
      '/accounts/transactions-and-details/insurance/instruction-and-claim-history',
    page_name:
      'ib:accounts:transactions and details:insurance:instruction and claim history',
    page_type: 'product'
  },
  cancelInstructionPopup: {
    page_url:
      '/accounts/transactions-and-details/insurance/instruction-and-claim-history/cancel-request-popup',
    page_name:
      'ib:accounts:transactions and details:insurance:instruction and claim history:cancel request popup',
    page_type: 'product'
  },
  eecModalPageDetails: {
    page_url:
      '/accounts/transactions-and-details/insurance/how-early-encashment-charges-work',
    page_name:
      'ib:accounts:transactions and details:insurance:how early encashment charges work',
    page_type: 'product'
  },
  summaryPageWithNotificationMsg: {
    event_category: 'content',
    event_action: 'message'
  },
  pageLevelErrorMsg: {
    event_category: 'error'
  },
  logOff: {
    event_category: 'content',
    event_action: 'button',
    event_content: 'log off'
  },
  dropDown: {
    event_category: 'content',
    event_action: 'dropdown'
  },
  captureAccordian: {
    event_category: 'content',
    event_action: 'accordion'
  },
  showAll: {
    event_category: 'content',
    event_action: 'accordion',
    event_content: 'show all'
  },
  viewDetails: {
    event_category: 'content',
    event_action: 'onsite',
    event_content: 'view details'
  },
  myInsurance: {
    event_category: 'content',
    event_action: 'button',
    event_content: 'my insurance'
  },
  contactUs: {
    event_category: 'content',
    event_action: 'button',
    event_content: 'contact us',
    page_type: 'product'
  },
  detailsTooltip: {
    event_category: 'content'
  },
  updatePaymentInstruction: {
    event_category: 'content'
  },
  updateContactInformation: {
    event_category: 'content'
  },
  makeClaim: {
    page_category: dashboardPageCategory,
    page_subcategory: dashboardPageSubCategory,
    page_type: 'article',
    page_url: '/accounts/transactions-and-details/insurance/make-a-claim',
    page_name: 'ib:accounts:transactions and details:insurance:make a claim'
  },
  sectionLinks: {
    event_category: 'content'
  },
  quickLinks: {
    event_category: 'content',
    event_action: 'onsite'
  },
  popup: {
    event_category: 'content',
    event_action: 'popup'
  },
  call: {
    event_category: 'content'
  },
  highlights: {
    event_category: 'content',
    event_subcategory: 'banner',
    event_action: 'impression'
  },
  highlightsProductLink: {
    event_category: 'content',
    event_subcategory: 'banner',
    event_action: 'click'
  },
  redirectPopup: {
    page_category: dashboardPageCategory,
    page_subcategory: dashboardPageSubCategory,
    page_type: 'article',
    page_url: '/accounts/transactions-and-details/insurance/redirect-popup',
    page_name: 'ib:accounts:transactions and details:insurance:redirect popup'
  },
  popupButtons: {
    event_category: 'content',
    event_action: 'button'
  },
  includeNonGuranteedCheckBox: {
    event_category: 'content',
    event_action: 'checkbox'
  },
  commonButtonEvents: {
    event_category: 'content',
    event_action: 'button'
  },
  simplyBook: {
    page_type: 'other',
    page_url:
      '/accounts/transactions-and-details/insurance/continue-to-insurance-specialists-popup',
    page_name:
      'ib:accounts:transactions and details:insurance:continue to insurance specialists popup'
  },
  megaMenuLinks: {
    event_category: 'content',
    event_action: 'button'
  },
  downloadDocument: {
    event_category: 'content',
    event_action: 'download',
    page_type: 'product'
  },
  downloadDocumentPopup: {
    page_url: '/accounts/transactions-and-details/insurance/pdf-open-error',
    page_name: 'ib:accounts:transactions and details:insurance:pdf open error'
  },
  downloadDocumentError: {
    event_content: 'pdf open error',
    event_category: 'error',
    event_action: 'download',
    page_type: 'product'
  },
  downloadDocumentCTA: {
    event_category: 'content',
    event_action: 'button',
    page_type: 'product'
  },
  eWelcomeModalView: {
    page_type: 'category',
    page_url: policyReady,
    page_name: policyReadyContent
  },
  eWelcomeViewDetails: {
    event_category: 'content',
    page_type: 'category',
    page_url: '/accounts/transcations-and-details/policy-summary',
    page_name: 'ib:accounts:transcations and details:policy summary'
  },
  eWelcomeModalLearnMore: {
    event_category: 'content',
    page_type: 'category',
    page_url: '/accounts/transcations-and-details/policy-summary',
    page_name: 'ib:accounts:transcations and details:policy summary',
    funnel_name: 'saving or investment link'
  },
  eWelcomeDownloadDocument: {
    event_category: 'content',
    page_type: 'category',
    page_url: policyReady,
    page_name: policyReadyContent
  },
  downloadAll: {
    event_action: 'download',
    page_name: policyReadyContent,
    page_type: 'category'
  },
  gotIt: {
    event_category: 'content',
    event_action: 'button',
    event_content: 'understand and dont show again',
    page_url: policyReady,
    page_name: policyReadyContent,
    page_type: 'category'
  },
  onboardingDocument: {
    event_category: 'content',
    event_action: 'button',
    event_content: 'onboarding documents',
    page_name: 'ib:accounts:transcations and details:insurance',
    page_type: 'product'
  },
  downloadDocumentErrDetails: {
    event_category: 'error',
    event_action: 'system',
    page_url: '/accounts/transcations-and-details/sorry-please-try-again',
    page_name: 'ib:accounts:transactions and details:sorry please try again',
    page_type: 'product'
  },
  eWelcomeErrorMsg: {
    event_category: 'error',
    event_action: 'system',
    page_name: 'ib:accounts:transactions and details:insurance',
    page_type: 'product'
  },
  fundRPQNotification: {
    page_url:
      '/accounts/transactions-and-details/insurance/updates-required-upon-your-investment-choice',
    page_name:
      'ib:accounts:transactions and details:insurance:updates required upon your investment choice',
    page_type: 'product'
  },
  transactionModalEvent: {
    event_category: 'error',
    event_action: 'system'
  },
  assignee: {
    page_url:
      '/accounts/transactions-and-details/insurance/service-unavailable',
    page_name:
      'ib:accounts:transactions and details:insurance:service unavailable',
    page_type: 'product'
  },
  vl1: {
    page_url:
      '/accounts/transactions-and-details/insurance/service-unavailable-product-code-is-vl1',
    page_name:
      'ib:accounts:transactions and details:insurance:service unavailable product code is vl1',
    page_type: 'product'
  },
  pendingTransaction: {
    page_url:
      '/accounts/transactions-and-details/insurance/instruction-pending-to-be-processed',
    page_name:
      'ib:accounts:transactions and details:insurance:instruction pending to be processed',
    page_type: 'product'
  },
  // ------Start of DSI tagging---------------- //
  commonTagsBeneficiary: {
    site_section: 'ib',
    page_type: 'servicing',
    page_customer_group: 'general',
    page_security_level: '30',
    page_category: 'account services',
    page_subcategory: 'personal & address details'
  },
  bendetPolicyDetails: {
    page_name: `ib:account services:personal & address details:change beneficiary:policy details`,
    page_url: `/account-services/personal-&-address-details/change-beneficiary/policy-details`
  },
  bendetPolicyDetailsFunnel: {
    funnel_name: bendetFunnelName,
    funnel_step_name: 'policy details',
    raw_datalayer: '6653v1'
  },

  bendetSorryCaseIrrevocableModalDetails: {
    page_name: `ib:account services:personal & address details:change beneficiary:case irrevocable`,
    page_url: `/account-services/personal-&-address-details/change-beneficiary/case-irrevocable`
  },
  bendetSorryCaseIrrevocableModalFunnel: {
    funnel_name: bendetFunnelName,
    funnel_step_name: 'case irrevocable',
    raw_datalayer: '6653v5'
  },
  bendetSorryCaseIrrevocableModal: {
    event_action: `system`,
    event_category: `error`,
    event_content: `sorry we cannot process your request`
  },
  bendetSorryCaseAssigneeModalDetails: {
    page_name: `ib:account services:personal & address details:change beneficiary:sorry case assignee`,
    page_url: `/account-services/personal-&-address-details/change-beneficiary/sorry-case-assignee`
  },
  bendetSorryCaseAssigneeModal: {
    event_action: `system`,
    event_category: `error`,
    event_content: `sorry we cannot process your request here`
  },
  bendetSorryCaseAssigneeModalFunnel: {
    funnel_name: bendetFunnelName,
    funnel_step_name: 'sorry case assignee',
    raw_datalayer: '6653v8'
  },
  eStatementLinkClk: {
    event_action: `button`,
    event_category: `content`,
    event_content: `statement preferences`,
    raw_datalayer: '7326v1',
    page_type: 'product'
  },
  eStatemetView: {
    page_url: estmtPageUrl,
    page_name: estmtPageName,
    page_type: 'category',
    raw_datalayer: '7326v2'
  },
  eStatemetCheckbox: {
    page_url: estmtPageUrl,
    page_name: estmtPageName,
    page_type: 'category',
    event_action: `checkbox`,
    event_category: `content`,
    event_content: 'submit change',
    raw_datalayer: '7326v3'
  },
  eStatemetBackBtn: {
    page_url: estmtPageUrl,
    page_name: estmtPageName,
    page_type: 'category',
    event_action: `button`,
    event_category: `content`,
    event_content: 'back',
    raw_datalayer: '7326v4'
  },
  eStatemetConfirmationView: {
    page_url: estmtConfirmationPageUrl,
    page_name: estmtConfirmationPageName,
    page_type: 'category',
    raw_datalayer: '7326v5'
  },
  eStatementTnCLinkClk: {
    page_url: estmtConfirmationPageUrl,
    page_name: estmtConfirmationPageName,
    event_action: `onsite`,
    event_category: `content`,
    event_content: `view estatement and eadvices tnc link`,
    raw_datalayer: '7326v6',
    page_type: 'category'
  },
  eStatementCommonLinkClk: {
    page_url: estmtConfirmationPageUrl,
    page_name: estmtConfirmationPageName,
    page_type: 'category',
    event_action: `button`,
    event_category: `content`
  }
};

export default tealiumMapping;
