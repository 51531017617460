import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Currency from '../../../common/currency/Currency';
import ShowIf from '../../../utils/showIf';
import { truncDigits } from '../../../utils/formatter';
import {
  PayoutFieldWrapper,
  AnnuityField,
  AnnuityLabel,
  AnnuityValue,
  GuaranteedWrapper,
  GuaranteedLabel,
  GuaranteedValue,
  CashValueBox,
  GuaranteedRow
} from '../../reprojectionPageStyle';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';

const isValidField = function isValidField(fieldValue, fieldCurrency) {
  const checkIsValidField =
    typeof fieldValue === 'number' &&
    truncDigits(fieldValue, 0) > 0 &&
    fieldCurrency;
  return !!checkIsValidField;
};
const PremiumPaid = function PremiumPaid(props, context) {
  const {
    intl: { formatMessage }
  } = context;
  const {
    labelContent,
    fieldData,
    dependentFieldData,
    secondDependentFieldData,
    toolTipContent
  } = props;

  const fieldDataValue = _get(fieldData, 'amount');
  const fieldDataCurrency = _get(fieldData, 'currency');
  const dependentFieldDataValue = _get(dependentFieldData, 'amount');
  const dependentFieldDataCurrency = _get(dependentFieldData, 'currency');
  const secondDependentFieldDataValue = _get(
    secondDependentFieldData,
    'amount'
  );
  const secondDependentFieldDataCurrency = _get(
    secondDependentFieldData,
    'currency'
  );
  if (!labelContent || !isValidField(fieldDataValue, fieldDataCurrency)) {
    return <></>;
  }

  // set flag for non-guranteed data
  const isNonGuaranteedFieldEmpty = isValidField(
    secondDependentFieldDataValue,
    secondDependentFieldDataCurrency
  );
  // set flat for guranteed data
  const isGuaranteedFieldEmpty = isValidField(
    dependentFieldDataValue,
    dependentFieldDataCurrency
  );
  const isPayoutFieldEmpty =
    isGuaranteedFieldEmpty || isNonGuaranteedFieldEmpty;

  return (
    <PayoutFieldWrapper isPayoutFieldEmpty={isPayoutFieldEmpty}>
      <CashValueBox>
        <AnnuityField isPayoutFieldEmpty={isPayoutFieldEmpty} id={labelContent}>
          <AnnuityLabel
            id={`${labelContent}_dt`}
            data-test="premiumpaid-annuitylabel"
          >
            {formatMessage({
              id: `${labelContent}`
            })}
            {
              <TooltipWrapper
                id="tooltip_projected_amount_allocated_to_you"
                contentKey={toolTipContent}
                sectionHeading=" "
                labelContent={labelContent}
                maxWidth={300}
                isReprojectionsTooltip
              />
            }
          </AnnuityLabel>
          <AnnuityValue>
            <Currency
              id="culmulative_paid_currency"
              currencyCode={fieldData.currency}
              amount={fieldData.amount}
              decimalRange={0}
            />
          </AnnuityValue>
        </AnnuityField>
        <>
          {isPayoutFieldEmpty && (
            <GuaranteedWrapper id="guranteedBox">
              <ShowIf condition={isGuaranteedFieldEmpty}>
                <GuaranteedRow>
                  <GuaranteedLabel
                    id="totalGuaranteedPayout"
                    data-testid="premiumpaid-dependantLabel"
                  >
                    {formatMessage({
                      id: `${dependentFieldData.labelContent}`
                    })}
                  </GuaranteedLabel>
                  <GuaranteedValue data-testid="premiumpaid-dependantValue">
                    <Currency
                      currencyCode={dependentFieldData.currency}
                      amount={dependentFieldData.amount}
                      decimalRange={0}
                    />
                  </GuaranteedValue>
                </GuaranteedRow>
              </ShowIf>
              <ShowIf condition={isNonGuaranteedFieldEmpty}>
                <GuaranteedRow>
                  <GuaranteedLabel
                    data-testid="premiumpaid-secondDependantLabel"
                    id="totalNonGuaranteedPayout"
                  >
                    {formatMessage({
                      id: `${secondDependentFieldData.labelContent}`
                    })}
                  </GuaranteedLabel>
                  <GuaranteedValue data-testid="premiumpaid-secondDependantValue">
                    <Currency
                      currencyCode={secondDependentFieldData.currency}
                      amount={secondDependentFieldData.amount}
                      decimalRange={0}
                    />
                  </GuaranteedValue>
                </GuaranteedRow>
              </ShowIf>
            </GuaranteedWrapper>
          )}
        </>
      </CashValueBox>
    </PayoutFieldWrapper>
  );
};

PremiumPaid.propTypes = {
  labelContent: PropTypes.string,
  fieldData: PropTypes.object,
  dependentFieldData: PropTypes.object,
  secondDependentFieldData: PropTypes.object,
  toolTipContent: PropTypes.string
};

PremiumPaid.defaultProps = {
  dependentFieldData: {},
  secondDependentFieldData: {},
  labelContent: '',
  fieldData: {},
  toolTipContent: ''
};

PremiumPaid.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PremiumPaid;
