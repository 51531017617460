/**
 *  Displays "Update insurance statement preference" link
 *  in the Policy-details page
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClaimWrapper from './ClaimWrapper';
import statementPreference from '../../assets/images/QuickLinks/statementPreference.svg';
import Context from '../../app/Context';
import {
  checkPendingEstatementInstruction,
  captureCustomerGroup
} from '../sectionUtils/SectionsUtils';
import { getPaymentMode, trackEvents } from '../../utils/tealiumUtils';
import getContentInSpecificLang from '../../utils/localeUtils';

const EstatementPreference = (props, context) => {
  const history = useHistory();
  const {
    intl: { formatMessage }
  } = context;
  const {
    id,
    encodedProductId,
    // estatementService,
    displayQuickLink,
    currentPolicyDetail,
    langObj
  } = props;
  const {
    policyOverview: { paperStatementApplicableIndicator } = {},
    premiumPaymentDetails: { paymentModeReference } = {}
  } = currentPolicyDetail || {};
  const displayStatementPrefLink =
    displayQuickLink && paperStatementApplicableIndicator;

  const handleClick = (
    e,
    estatementUrl,
    pendingInstructionUrl,
    customer,
    genericConfig
  ) => {
    e.preventDefault();
    const customerGroup = captureCustomerGroup(customer, genericConfig);
    trackEvents({
      eventName: 'onEStmtLinkClk',
      currentPolicyDetail,
      customerGroup
    });
    const paymentModeKeyLangSpecific = getContentInSpecificLang(
      `premium_label_${paymentModeReference.trim()}`,
      'en',
      langObj
    );
    getPaymentMode(paymentModeKeyLangSpecific);
    history.push(
      checkPendingEstatementInstruction(currentPolicyDetail)
        ? pendingInstructionUrl
        : estatementUrl
    );
  };
  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;
        const { estatement, estatementpendinginstruction } = forLink || {};
        const { customer, genericConfig } = props;
        return (
          <>
            {displayStatementPrefLink ? (
              <ClaimWrapper
                id={`${id}`}
                ariaLabel={formatMessage({ id: 'estatement_link_label' })}
                altText="estatement-preference"
                iconSrc={statementPreference}
                linkLabel={formatMessage({ id: 'estatement_link_label' })}
                altTextIconRight="right-icon"
                href=""
                onClick={e =>
                  handleClick(
                    e,
                    `${estatement}${encodedProductId}`,
                    `${estatementpendinginstruction}${encodedProductId}`,
                    customer,
                    genericConfig
                  )
                }
              />
            ) : null}
          </>
        );
      }}
    </Context.Consumer>
  );
};

EstatementPreference.defaultProps = {
  // estatementService: null,
  displayQuickLink: false
};

EstatementPreference.propTypes = {
  id: PropTypes.string.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  // estatementService: PropTypes.objectOf(PropTypes.any),
  displayQuickLink: PropTypes.bool,
  currentPolicyDetail: PropTypes.object.isRequired,
  langObj: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired
};

EstatementPreference.contextTypes = {
  intl: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  const {
    configurations: {
      genericConfig: {
        servicingOptions: {
          digiService: { estatement }
        }
      }
    }
  } = state;
  return {
    // estatementService: estatement,
    displayQuickLink:
      state?.configurations?.detailsConfig?.eStatementPreferenceLink?.display ??
      false
  };
};
export default connect(mapStateToProps, null)(EstatementPreference);
