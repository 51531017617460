import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import {
  IntroHeaderSection,
  OverviewWrapper,
  PolicyTagsRow,
  DetailsPageMiddleSection,
  DetailsPageOverviewSection,
  UpdateClaimWrapper
} from '../detailPageStyle';
import { AccessibleHidden } from '../../app/AppStyles';
import getContentInSpecificLang from '../../utils/localeUtils';
import { tagGenerator, getFieldDataFromConfig } from '../../utils/policyUtils';
import { FullWidthSectionContainer } from '../../common/styles/commonStyle';
import UpdatePersonalDetails from './UpdatePersonalDetails';
import MakeAClaim from './MakeAClaim';
import EstatementAndAdvices from './EstatementAndAdvices';
import ServicingOption from './ServicingOption';
import ContactUsFooter from '../../app/components/footer/ContactUsFooter';
import AlertIndicators from './AlertIndicators';
import getPaymentAlertList from '../sectionUtils/PaymentUtils';
import { getProductDetails } from '../../utils/tealiumUtils';
import getDescription from '../../utils/reducerUtils';
import DocumentHandling from './DocumentHandling';
import ChangeBeneficiary from './ChangeBeneficiary';
import OnboardingDocuments from '../../eWelcome/OnboardingDocuments';
import EstatementPreference from './EstatementPreference';
import OverviewNotification from '../templates/PolicyOverview/OverviewNotification';
import { getNotification } from '../../reprojection/tabsections/templates/BelowGraphNotification';

const PolicyOverview = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    currentPolicyDetail,
    subProductCategories,
    productCategory,
    selectedLocale,
    langObj,
    genericConfig,
    sectionNotificationConfig,
    policyTagsMedicalAndCIConfig,
    eStatementPreferenceConfig,
    superFamily,
    fieldsStack,
    servicingOptionsURL,
    dependentSectionField,
    dependentSectionFieldTwo,
    fieldConfig,
    sectionHeading,
    summaryResponse,
    setContactUsPopupHandler,
    showContactUsFooterPopup,
    encodedProductId,
    functionEligibility,
    customerWhiteListedForDigiServiceLinks,
    customer
  } = props;
  const { notificationMessages, claimURLGILink } = genericConfig;
  const {
    estatements_advices: estatementsAdvices
  } = eStatementPreferenceConfig;

  let paymentAlerts = [];
  if (!fieldsStack || (fieldsStack.length && fieldsStack.length < 1)) {
    return <></>;
  }

  if (currentPolicyDetail) {
    const {
      policyOverview: {
        productFamily,
        productCategory: productCategoryCurrent,
        planName,
        planCode
      }
    } = currentPolicyDetail;
    getProductDetails(
      productFamily,
      productCategoryCurrent,
      getDescription('en', planName),
      planCode,
      policyTagsMedicalAndCIConfig
    );
  }

  const matchedConfiguredProduct = _find(subProductCategories, [
    'productCategoryName',
    productCategory
  ]);

  const { eWelcomePackFlags: { eWelcomePackFlag } = {} } =
    currentPolicyDetail || {};
  const eWelcomePackEnabled = eWelcomePackFlag === 'Y';

  /**
   * Utility method tagGenerator creates the UI of tags by consuming all the parameters passed to it. In case policy is not a general policy,
   * the tag values of 1st and 2nd tags will be taken from summary response instead of details response.
   */

  const policyTags = () => {
    return (
      matchedConfiguredProduct &&
      matchedConfiguredProduct.tags && (
        <PolicyTagsRow>
          <AccessibleHidden>
            {formatMessage({ id: 'policyTags' })}
          </AccessibleHidden>
          {tagGenerator(
            matchedConfiguredProduct,
            currentPolicyDetail,
            selectedLocale,
            langObj,
            getContentInSpecificLang,
            formatMessage,
            summaryResponse,
            policyTagsMedicalAndCIConfig
          )}
        </PolicyTagsRow>
      )
    );
  };
  const footerButtonList = [
    {
      className: 'whiteBtn',
      label: formatMessage({ id: 'cancelPopup' }),
      id: 'cancel_btn',
      align: 'left'
    },
    {
      className: 'redBtn',
      label: formatMessage({ id: 'continuePopup' }),
      id: 'continue_btn',
      align: 'right'
    }
  ];
  const overDueAlertIndicator = getFieldDataFromConfig(
    currentPolicyDetail,
    fieldConfig[dependentSectionField],
    true
  );
  // using notificationmessages object for styling the alert/ adding an extra fields array for iterating the alerts based on errorType
  if (overDueAlertIndicator) {
    paymentAlerts = getPaymentAlertList(
      paymentAlerts,
      fieldConfig[dependentSectionField],
      notificationMessages
    );
  }
  const aplAlertIndicator = getFieldDataFromConfig(
    currentPolicyDetail,
    fieldConfig[dependentSectionFieldTwo],
    true
  );
  if (aplAlertIndicator) {
    paymentAlerts = getPaymentAlertList(
      paymentAlerts,
      fieldConfig[dependentSectionFieldTwo],
      notificationMessages
    );
  }
  let hasNotification = null;
  let encodePolicyNumber = '';
  if (currentPolicyDetail && sectionNotificationConfig) {
    encodePolicyNumber = currentPolicyDetail.policyOverview.encodePolicyNumber;
    const selectedTabName =
      currentPolicyDetail.policyOverview.productCategory === 'Annuity'
        ? 'PayoutBenefit'
        : 'SurrenderValue';
    hasNotification = getNotification(
      selectedTabName,
      currentPolicyDetail,
      sectionNotificationConfig
    );
  }

  return (
    <FullWidthSectionContainer>
      <DetailsPageOverviewSection>
        <IntroHeaderSection>
          {hasNotification ? (
            <OverviewNotification
              hasNotification={hasNotification}
              encodePolicyNumber={encodePolicyNumber}
            />
          ) : null}
          {(process.env.REACT_APP_ENV === 'local' ||
            process.env.REACT_APP_ENV === 'dev') &&
            productCategory}
          {paymentAlerts && paymentAlerts.length > 0 && (
            <AlertIndicators
              alerts={paymentAlerts}
              superFamily={superFamily}
              position="pageLevel"
            />
          )}
          {fieldsStack[0]}
          {policyTags()}
          <OverviewWrapper>
            {fieldsStack[1].map(fieldInstance => (
              <React.Fragment
                key={`${fieldInstance.props.id}_${fieldInstance.key}`}
              >
                {fieldInstance}
              </React.Fragment>
            ))}
          </OverviewWrapper>
          <UpdateClaimWrapper>
            <UpdatePersonalDetails
              genericConfig={genericConfig}
              superFamily={superFamily}
              footerButtonList={footerButtonList}
              langObj={langObj}
            />
            <MakeAClaim
              superFamily={superFamily}
              footerButtonList={footerButtonList}
              langObj={langObj}
              encodedProductId={encodedProductId}
              claimURLGILink={claimURLGILink}
              selectedLocale={selectedLocale}
            />
            <ServicingOption
              servicingOptionsURL={servicingOptionsURL}
              sectionHeading={sectionHeading}
              encodedProductId={encodedProductId}
              currentPolicyDetail={currentPolicyDetail}
              tealiumSectionHeading="overview"
              customerWhiteListedForDigiServiceLinks={
                customerWhiteListedForDigiServiceLinks
              }
              superFamily={superFamily}
              genericConfig={genericConfig}
              langObj={langObj}
              calledFrom="PolicyOverview"
            />
            <DocumentHandling
              id="document_upload"
              encodedProductId={encodedProductId}
              functionEligibility={functionEligibility}
              superFamily={superFamily}
            />
            {eWelcomePackEnabled ? (
              <OnboardingDocuments currentPolicyDetail={currentPolicyDetail} />
            ) : null}
            {superFamily === 'life' ? (
              <EstatementAndAdvices
                estatementsAdvices={estatementsAdvices}
                customer={customer}
                genericConfig={genericConfig}
              />
            ) : null}
            <EstatementPreference
              id="estatement-preference"
              encodedProductId={encodedProductId}
              currentPolicyDetail={currentPolicyDetail}
              langObj={langObj}
              customer={customer}
              genericConfig={genericConfig}
            />
            <ContactUsFooter
              sectionHeading={sectionHeading}
              showContactUsFooterPopup={showContactUsFooterPopup}
              setContactUsPopupHandler={setContactUsPopupHandler}
              langObj={langObj}
            />
            <ChangeBeneficiary {...props} />
          </UpdateClaimWrapper>
        </IntroHeaderSection>
      </DetailsPageOverviewSection>
      <DetailsPageMiddleSection />
    </FullWidthSectionContainer>
  );
};

export default PolicyOverview;

PolicyOverview.contextTypes = {
  intl: PropTypes.object.isRequired
};

PolicyOverview.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  productCategory: PropTypes.string.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired,
  eStatementPreferenceConfig: PropTypes.object.isRequired,
  superFamily: PropTypes.string.isRequired,
  servicingOptionsURL: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  dependentSectionField: PropTypes.string,
  dependentSectionFieldTwo: PropTypes.string,
  sectionHeading: PropTypes.string,
  summaryResponse: PropTypes.object.isRequired,
  setContactUsPopupHandler: PropTypes.func.isRequired,
  showContactUsFooterPopup: PropTypes.bool.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  functionEligibility: PropTypes.array,
  customerWhiteListedForDigiServiceLinks: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired
};

PolicyOverview.defaultProps = {
  dependentSectionField: '',
  dependentSectionFieldTwo: '',
  sectionHeading: '',
  functionEligibility: []
};
