/**
 * Function to check if the data has the expected data type.
 * gerally is expected to be called in checkmarks.
 * function to be called rather than using {typeof data === 'string' && data.length} multiple times for conditional rendering
 * pass check for zero as true if condition is {typeof data === 'number' && data > 0}
 * Returns boolean
 */
export const validateDataType = function validateDataType(
  data,
  checkForZeroOrEmpty = false
) {
  switch (typeof data) {
    case 'number':
      if (!checkForZeroOrEmpty) {
        return true;
      }
      return data > 0;
    case 'string':
      if (!checkForZeroOrEmpty) {
        return true;
      }
      return data.trim().length > 0;
    case 'object':
      if (!checkForZeroOrEmpty) {
        return data !== undefined && data !== null;
      }
      return (
        data !== null &&
        data !== undefined &&
        !!data &&
        !!Object.keys(data).length
      );

    case 'boolean':
      return data;

    default:
      return false;
  }
};

/**
 * Based on the type of data, it is null or empty
 * The function returnd the expected value passed as argument
 * e.g displayIfNull(data, []). Here if data is null or empty array it would return [] otherwise the data
 */
export const displayIfNull = function displayIfNull(
  data,
  displayForNull,
  displayForNotNull = data,
  checkForNegative = false
) {
  switch (typeof data) {
    case 'number':
      if (!checkForNegative) {
        return displayForNotNull;
      }
      return data < 0 ? displayForNull : displayForNotNull;
    case 'string':
      return data.trim().length ? displayForNotNull : displayForNull;
    case 'object':
      return data && Object.keys(data).length
        ? displayForNotNull
        : displayForNull;
    case 'boolean':
      return data ? displayForNotNull : displayForNull;
    default:
      return displayForNull;
  }
};

/**
 * Returns a dash if string is empty or null
 * Returns a dash is type of data is number and its value is less than or equal to zero
 * Function is generally expected to be called for display purpose
 */

export const displayDash = function DisplayDash(data) {
  switch (typeof data) {
    case 'number':
      return data <= 0 ? '-' : data;
    case 'string':
      return data.trim().length ? data : '-';
    default:
      return '-';
  }
};

export const doOR = function doOR(...args) {
  let res = false;
  for (let i = 0; i < args.length; i += 1) {
    const val = !!args[i];
    res = res || val;
  }
  return res;
};

export const doAND = function doAND(...args) {
  let res = true;
  for (let i = 0; i < args.length; i += 1) {
    const val = !!args[i];
    res = res && val;
  }
  return res;
};
