import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { isMiniMobile } from '../utils/domUtils';
import {
  FirstRecapHeading,
  IconSpan,
  NeedInfo,
  DocumentsBox,
  CategorySection,
  CategorySectionCol1,
  CategorySectionCol2,
  DownloadAllWrapper
} from './WelcomeModalStyle';
import download from '../assets/images/Modal/icon/download.svg';
import DownloadDocument from './DownloadDocument';

const DownloadSection = props => {
  const {
    featuresConfig,
    downloadAllClick,
    categoryConfig,
    encodePolicyNumber,
    policyNumber,
    documentIDList,
    totalDocuments,
    setDownloadAllClicked,
    downloadAllContent,
    eWelcomeDownloadAllHandler,
    policy,
    documentData
  } = props;
  return (
    <>
      <FirstRecapHeading>
        {' '}
        <FormattedMessage
          id="welcome_documents"
          defaultMessage=" 2. You can download the welcome pack to get started"
          values={{
            value: featuresConfig.length ? '2' : '1'
          }}
        />
      </FirstRecapHeading>
      <NeedInfo setMargin noPadding>
        <DownloadAllWrapper
          id="downloadAllWrapper"
          tabIndex="0"
          cursorPointer
          setbottomMargin
          onClick={() =>
            downloadAllClick(
              encodePolicyNumber,
              policyNumber,
              documentIDList,
              totalDocuments,
              policyNumber,
              setDownloadAllClicked,
              downloadAllContent,
              eWelcomeDownloadAllHandler,
              policy
            )
          }
          onKeyDown={e => {
            if (e.key === 'Enter') {
              downloadAllClick(
                encodePolicyNumber,
                policyNumber,
                documentIDList,
                totalDocuments,
                policyNumber,
                setDownloadAllClicked,
                downloadAllContent,
                eWelcomeDownloadAllHandler,
                policy
              );
            }
          }}
        >
          {' '}
          <IconSpan>
            {' '}
            <img data-locator-id="download-icon" alt="" src={download} />
          </IconSpan>{' '}
          <FormattedMessage
            id="downloadAllLabel"
            defaultMessage="Download all 11 documents"
            values={{
              totalDocuments
            }}
          />
        </DownloadAllWrapper>
        <DocumentsBox isMiniMobile={isMiniMobile()}>
          {categoryConfig.map((category, index) => {
            return (
              <CategorySection
                key={category.categoryNameLabel}
                hideBorder={categoryConfig.length - 1 === index}
                isMiniMobile={isMiniMobile()}
              >
                <CategorySectionCol1 isMiniMobile={isMiniMobile()}>
                  <FormattedMessage
                    id={category.categoryNameLabel}
                    defaultMessage="Category type"
                  />
                </CategorySectionCol1>
                <CategorySectionCol2 isMiniMobile={isMiniMobile()}>
                  <DownloadDocument
                    key={category.categoryNameLabel}
                    encodePolicyNumber={encodePolicyNumber}
                    categoryName={category.categoryName}
                    policyNumber={policyNumber}
                    documents={category.documents}
                    documentData={documentData}
                    policy={policy}
                  />
                </CategorySectionCol2>
              </CategorySection>
            );
          })}
        </DocumentsBox>
      </NeedInfo>
    </>
  );
};

DownloadSection.propTypes = {
  featuresConfig: PropTypes.array.isRequired,
  downloadAllClick: PropTypes.func.isRequired,
  categoryConfig: PropTypes.array.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  documentIDList: PropTypes.array.isRequired,
  totalDocuments: PropTypes.number.isRequired,
  setDownloadAllClicked: PropTypes.func.isRequired,
  downloadAllContent: PropTypes.string.isRequired,
  eWelcomeDownloadAllHandler: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  documentData: PropTypes.object.isRequired
};
export default DownloadSection;
