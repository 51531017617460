import * as _ from 'lodash';

export const planCodeLabelGenerator = function planCodeLabelGenerator(
  fieldObject,
  messages
) {
  const { fieldLabel, conditionalFieldValue } = fieldObject;
  const trimmedValue =
    typeof conditionalFieldValue === 'string'
      ? conditionalFieldValue.trim()
      : conditionalFieldValue;
  const labelContent = `${fieldLabel}_${trimmedValue}`;
  if (!!messages[labelContent] && messages[labelContent] !== '') {
    return labelContent;
  }
  return fieldLabel;
};

export const labelGenerator = (
  fieldObject,
  lifecycleValidity,
  formatMessage,
  messages
) => {
  if (lifecycleValidity.value !== undefined) {
    if (lifecycleValidity.value === 'AF' || lifecycleValidity.value === 'ON') {
      return formatMessage({ id: fieldObject.conditionalFieldLabel });
    }
    return formatMessage({ id: fieldObject.fieldLabel });
  }
  if (
    fieldObject.conditionalFieldValue &&
    typeof fieldObject.conditionalFieldValue === 'string'
  ) {
    const labelContent = planCodeLabelGenerator(fieldObject, messages);
    return formatMessage({ id: labelContent });
  }

  return formatMessage({ id: fieldObject.fieldLabel });
};

export const checkForAmount = FieldValue => {
  const isFieldAmountExist =
    FieldValue &&
    FieldValue.conditionalFieldValue &&
    FieldValue.conditionalFieldValue.amount;
  if (isFieldAmountExist) {
    return FieldValue.conditionalFieldValue.amount;
  }
  return 0;
};

export const checkAccumulationFlag = accumulationFlg => {
  return accumulationFlg === 'AF' || accumulationFlg === 'ON';
};

export const amountGenerator = (fieldObject, lifecycleValidity) => {
  const { value } = lifecycleValidity;
  if (checkAccumulationFlag(value)) {
    return checkForAmount(fieldObject);
  }
  return fieldObject.value.amount;
};

export const checkFieldAmtBasedOnAccumulationFlag = (
  fieldAmt,
  lifecycleValidity
) => {
  if (checkAccumulationFlag(lifecycleValidity.value)) {
    return true;
  }
  return fieldAmt;
};

export const personInsuredGenerator = function personInsuredGenerator(
  fieldObject
) {
  const involvedParty = [];

  /**
   * Mappings to be externalized in the generic configs
   */

  const involvedPartyRoleMapping = {
    policyholder: 'policyholder',
    insured: 'insured',
    LifeAssured: 'insured',
    JointLifeAssured: 'insured',
    Owner: 'policyholder',
    JointOwner: 'policyholder',
    CoveredChild: 'insured'
  };

  _.map(fieldObject.value, involvedPerson => {
    const { fullName } = involvedPerson;
    if (
      _.some(involvedPerson.involvedPartyRole, role => {
        return involvedPartyRoleMapping[role] === 'insured';
      }) &&
      fullName
    ) {
      involvedParty.push(`${fullName}`);
    }
  });

  /**
   * If the list of involved parties is requested for summary/details page, then straight away return the list of all the names (Without "You" for policyholders)
   */

  return involvedParty.length > 0 ? involvedParty.join(', ') : '';
};

export const payerNameGenerator = involvedParties => {
  const payerList = [];
  _.map(involvedParties, involvedPerson => {
    if (
      _.some(involvedPerson.involvedPartyRole, role => {
        return role.toLowerCase() === 'payer';
      })
    ) {
      const { fullName = '' } = involvedPerson;
      if (fullName) {
        payerList.push(`${fullName}`);
      }
    }
  });
  return payerList;
};
