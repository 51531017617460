import { getCookie } from './cookieUtils';

const URLsConfiguration = urlsObj => {
  const cookie = getCookie('LEGACY_SITE_ID');
  if (urlsObj) {
    switch (cookie) {
      case '1':
        return [
          {
            text: 'internetBanking',
            url: urlsObj.internetBankingP2GTKO
          },
          {
            text: 'myInsurance',
            url: urlsObj.myinsurnaceP2GTKO
          }
        ];
      case '2':
        return [
          {
            text: 'internetBanking',
            url: urlsObj.internetBankingP2GSKM
          },
          {
            text: 'myInsurance',
            url: urlsObj.myinsurnaceP2GSKM
          }
        ];

      default:
        return [
          {
            text: 'internetBanking',
            url: urlsObj.internetBankingDefault
          },
          {
            text: 'myInsurance',
            url: urlsObj.myinsurnaceDefault
          }
        ];
    }
  }
  return [];
};

export default URLsConfiguration;
