import React from 'react';
import PropTypes from 'prop-types';
import documentUpload from '../../assets/images/QuickLinks/documentUpload.svg';
import ClaimWrapper from './ClaimWrapper';
import isCustomerWhiteListed from '../../utils/customerUtils';
import { doOR } from '../../common/util/commonUtil';

const DocumentHandling = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { id, encodedProductId, functionEligibility, superFamily } = props;

  const redirectTo = `${window.location.origin}/documents-service/${encodedProductId}`;
  if (
    doOR(
      !isCustomerWhiteListed('Document_Web', functionEligibility),
      superFamily !== 'life'
    )
  ) {
    return <></>;
  }
  return (
    <ClaimWrapper
      id={id}
      ariaLabel={formatMessage({ id: 'documentUploadLink' })}
      altText="document-upload"
      iconSrc={documentUpload}
      linkLabel={formatMessage({ id: 'documentUploadLink' })}
      altTextIconRight="right-icon"
      href={redirectTo}
    />
  );
};

DocumentHandling.propTypes = {
  id: PropTypes.string.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  functionEligibility: PropTypes.array,
  superFamily: PropTypes.string.isRequired
};

DocumentHandling.defaultProps = {
  functionEligibility: []
};
DocumentHandling.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default DocumentHandling;
