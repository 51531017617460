import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconRightImg } from '../../policysummary/summaryPageStyles';
import {
  ReprojectionLinkWrapper,
  ReprojectionLabel,
  ReprojectionImgWrapper
} from '../../reprojection/reprojectionPageStyle';
import getDescription from '../../utils/reducerUtils';
import iconRight from '../../assets/images/Icon_right.svg';
import Context from '../../app/Context';

const ReprojectionLink = (props, context) => {
  const {
    policyOverview,
    policyOverview: { encodePolicyNumber, policyNumber, planName }
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  if (!policyOverview) {
    return <></>;
  }

  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;
        const { reprojections } = forLink || {};
        const policyName = getDescription(locale, planName);
        return (
          <ReprojectionLinkWrapper>
            <Link
              to={`${reprojections}${encodePolicyNumber}`}
              id={`reprojection_${policyNumber}`}
              aria-label={`${policyName} ${formatMessage({
                id: 'returnsReProjectionLabel'
              })}`}
            >
              <ReprojectionLabel>
                {formatMessage({ id: 'returnsReProjectionLabel' })}
              </ReprojectionLabel>
              <ReprojectionImgWrapper>
                <IconRightImg aria-hidden="true" alt="" src={iconRight} />
              </ReprojectionImgWrapper>
            </Link>
          </ReprojectionLinkWrapper>
        );
      }}
    </Context.Consumer>
  );
};

ReprojectionLink.propTypes = {
  policyNumber: PropTypes.string.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyOverview: PropTypes.object.isRequired
};
ReprojectionLink.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ReprojectionLink;
