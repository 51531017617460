import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DateComponent from '../../../common/date/Date';
import { ReprojectionFieldRow } from '../../reprojectionPageStyle';

import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';

const FutureValueProjection = function FutureValueProjection(props, context) {
  const {
    intl: { locale }
  } = context;
  const { labelContent, fieldData, toolTipContent } = props;

  // based on this variable tooltip event has been captured for reporjections
  const isReprojectionsTooltip = true;

  if (!labelContent || !fieldData || typeof fieldData !== 'string') {
    return null;
  }

  return (
    <ReprojectionFieldRow
      id={labelContent}
      data-testid="futureValueProjection-reprojectionFieldRow"
    >
      <FormattedMessage
        id={labelContent}
        values={{
          valuationDate: (
            <DateComponent
              date={fieldData}
              locale={locale}
              apiDateFormat="YYYY-MM-DD"
              id="standaradDateFormat"
            />
          )
        }}
      />
      <TooltipWrapper
        id="asOfDate"
        contentKey={toolTipContent}
        sectionHeading=" "
        labelContent="asOfTooltipAriaText"
        maxWidth={300}
        isReprojectionsTooltip={isReprojectionsTooltip}
      />
    </ReprojectionFieldRow>
  );
};

FutureValueProjection.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string.isRequired
};

FutureValueProjection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FutureValueProjection;
