import _ from 'lodash';
import {
  localeMappingForDescription,
  idcFallBackLocaleMapping
} from '../../../../../configure/localeDataConfig';

export const getDocDetails = function getDocDetails(
  localePassed,
  documentDetails
) {
  const localeMapping = localeMappingForDescription[localePassed];
  let objInSelectedLocale = _.find(documentDetails, {
    language: localeMapping
  });
  if (objInSelectedLocale && !_.isEmpty(objInSelectedLocale)) {
    return objInSelectedLocale;
  }

  let fallbackDocument = null;
  _.some(idcFallBackLocaleMapping[localeMapping], language => {
    objInSelectedLocale = _.find(documentDetails, {
      language
    });
    if (objInSelectedLocale && !_.isEmpty(objInSelectedLocale)) {
      fallbackDocument = objInSelectedLocale;
      return true;
    }
    return false;
  });
  return fallbackDocument;
};

export const base64toBlob = function base64toBlob(base64Pdf) {
  const byteCharacters = window.atob(base64Pdf);
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
};

export const checkToDisplayDisclaimer = function checkToDisplayDisclaimer(
  documentsList,
  currentDocument
) {
  return _.last(documentsList) === currentDocument;
};

export const orderObjectByDocumentType = function orderObjectByDocumentType(
  documentsList,
  documentTypeToCheck,
  documentOrder
) {
  const listObj = {};
  _.forEach(documentOrder, orderKey => {
    const filterByOrder = _.filter(
      documentsList,
      list => orderKey === list[`${documentTypeToCheck}`]
    );
    if (!_.isEmpty(filterByOrder)) {
      listObj[orderKey] = filterByOrder;
    }
  });
  return listObj;
};

export const getSurgicalChildDocuments = function getSurgicalChildDocuments(
  fieldData,
  fieldName
) {
  return _.find(fieldData, response => {
    if (response[fieldName] && response[fieldName].length) {
      return response;
    }
    return null;
  });
};
