import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';
import getDescription from '../../../utils/reducerUtils';

const ReturnsOptionField = (props, context) => {
  const { labelContent, fieldData, dependentFieldDataTwo } = props;
  let { toolTipContent } = props;
  const {
    intl: { locale }
  } = context;
  if (dependentFieldDataTwo && dependentFieldDataTwo.length) {
    toolTipContent = _.camelCase(getDescription('en', dependentFieldDataTwo));
  }
  const fieldDataNarrativeArray =
    fieldData &&
    fieldData.narrative &&
    fieldData.key &&
    fieldData.narrative.length !== 0;
  if (!fieldDataNarrativeArray) {
    return <></>;
  }
  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper {...props} toolTipContent={toolTipContent} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>
          {getDescription(locale, fieldData.narrative)}
        </DataTextBold>
      </OverviewInfoFieldDataValue>
    </PaymentInfoFieldWrapper>
  );
};

ReturnsOptionField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dependentFieldDataTwo: PropTypes.array
};

ReturnsOptionField.defaultProps = {
  toolTipContent: null,
  dependentFieldData: null,
  dependentFieldDataTwo: []
};

ReturnsOptionField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReturnsOptionField;
