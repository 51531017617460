import {
  SET_ESTATEMENT_PREFERENCE_ACK_DETAILS,
  SET_ESTATEMENT_PREFERENCE_WARNINGS_OR_ERRORS,
  SAVE_PAPER_STATEMENT_PREFERENCE,
  SET_VIH_LINK_STATEMENT_PREFERENCE
} from '../actionTypes';

export const INITIAL_STATE = {
  eStatementPreferenceDetails: {},
  eStatementPreferenceWarningsOrErrors: undefined,
  paperStatementIndicator: undefined,
  isVIHLinkVisitedFromEstmt: false
};

const eStatementPrefReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case SET_ESTATEMENT_PREFERENCE_ACK_DETAILS: {
      return {
        ...state,
        ...payload
      };
    }
    case SET_ESTATEMENT_PREFERENCE_WARNINGS_OR_ERRORS: {
      const warnings = {
        ...state.eStatementPreferenceWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        eStatementPreferenceWarningsOrErrors: warnings
      };
    }
    case SAVE_PAPER_STATEMENT_PREFERENCE: {
      return {
        ...state,
        paperStatementIndicator: payload
      };
    }
    case SET_VIH_LINK_STATEMENT_PREFERENCE: {
      return {
        ...state,
        isVIHLinkVisitedFromEstmt: payload
      };
    }
    default:
      return state;
  }
};

export default eStatementPrefReducer;
