import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';
import getDescription from '../../../utils/reducerUtils';

const OverviewAreaNarrativeField = (props, context) => {
  const {
    intl: { locale }
  } = context;

  const { fieldData, labelContent } = props;
  let contentDescription = '';
  const fieldDataNarrativeArray =
    fieldData && fieldData.narrative && fieldData.narrative.length !== 0;

  if (fieldDataNarrativeArray) {
    contentDescription = getDescription(locale, fieldData.narrative);
  }
  if (!fieldDataNarrativeArray || !contentDescription) {
    return <></>;
  }
  return (
    <OverviewFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>{contentDescription}</DataTextBold>
      </OverviewInfoFieldDataValue>
    </OverviewFieldWrapper>
  );
};

OverviewAreaNarrativeField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string
};

OverviewAreaNarrativeField.defaultProps = {
  toolTipContent: null
};

OverviewAreaNarrativeField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default OverviewAreaNarrativeField;
