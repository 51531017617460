import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { tableDataUtil } from './utils/reprojectionDataUtils';
import ReprojectionTable from './ReprojectionTable';
import { displayIfNull , doAND } from '../common/util/commonUtil';


function getReprojectionTableData(
  formattedTableData,
  policyCurrency,
  policyPhaseIndicator
) {
  return _.isEmpty(formattedTableData)
    ? null
    : tableDataUtil(formattedTableData, policyCurrency, policyPhaseIndicator);
}

const getReprojectionTable = (
  reprojectionTableData,
  policyCurrency,
  reprojectionGenericConfig,
  selectedFutureBenefitsTab,
  tableHeaderText,
  selectedTabName,
  policyPhaseIndicator
) =>
  reprojectionTableData ? (
    <ReprojectionTable
      reprojectionTableData={reprojectionTableData}
      policyCurrency={policyCurrency}
      reprojectionGenericConfig={reprojectionGenericConfig}
      selectedFutureBenefitsTab={selectedFutureBenefitsTab}
      tableHeaderText={tableHeaderText}
      selectedTabName={selectedTabName}
      policyPhaseIndicator={policyPhaseIndicator}
    />
  ) : null;

/**
 * @param {Object} props
 * @description : contains tableFieldsConfig List  which can be iterated to render field component inside this table cell
 *    tableSectionFieldConfig: List of fields object ( treated as table columns)
 *    apiFieldParentKey: key to use from API response
 *    reprojectionsDetails: API response
 *   dependentFieldConfig: List of dependentFields Object
 */

const _tableDataFormatter = function _tableDataFormatter(
  fieldConfigInstance, // field Configuration
  formattedTableData, // data till now
  apiIndex, // index o data
  dependentFieldTable, // component
  depFieldLabel
) {
  const outcomeTableFormatter = formattedTableData;
  if (fieldConfigInstance.fieldLabel in outcomeTableFormatter) {
    if (
      depFieldLabel in outcomeTableFormatter[fieldConfigInstance.fieldLabel]
    ) {
      outcomeTableFormatter[fieldConfigInstance.fieldLabel][depFieldLabel][
        apiIndex
      ] = dependentFieldTable;
    } else {
      outcomeTableFormatter[fieldConfigInstance.fieldLabel][depFieldLabel] = [
        dependentFieldTable
      ];
    }
  } else {
    const dependentFieldFormat = {};
    dependentFieldFormat[depFieldLabel] = [dependentFieldTable];
    outcomeTableFormatter[
      fieldConfigInstance.fieldLabel
    ] = dependentFieldFormat;
  }
  return outcomeTableFormatter;
};

/**
 *
 * @param {Object} fieldConfigInstance
 * @param {String} uiFormatKey
 * @param {Object} fieldDataInstance
 * @param {String} fieldNameKey
 * @param {String} fieldIndex
 * @description  built each table cell component
 */

const getDepFieldLabel = function getDepFieldLabel(
  depFieldLabel,
  dependentFieldInstance
) {
  if (dependentFieldInstance.dependentFieldLabel[depFieldLabel]) {
    return dependentFieldInstance.dependentFieldLabel[depFieldLabel];
  }
  return dependentFieldInstance.dependentFieldLabel.default;
};

const getPlanCodeTypeDepFieldLabel = function getPlanCodeTypeDepFieldLabel(
  dependentFieldInstance,
  planCodesCheckList,
  depApiValue
) {
  if (planCodesCheckList.includes(depApiValue)) {
    return `${dependentFieldInstance.dependentFieldLabel.planCode}_${depApiValue}`;
  }
  return dependentFieldInstance.dependentFieldLabel.default;
};
const _getTableCellComponent = function _getTableCellComponent(
  fieldConfigInstance,
  fieldDataInstance,
  fieldDataKey,
  fieldIndex,
  fieldLabel,
  mandatoryField = '',
  fieldMobile = '',
  showField = true,
  allowDecimals = false
) {
  const fieldData = fieldDataInstance[fieldConfigInstance[fieldDataKey]];

  let fieldDataSecondary;
  if (fieldConfigInstance.fieldNameSecondary) {
    fieldDataSecondary =
      fieldDataInstance[fieldConfigInstance.fieldNameSecondary];
  }

  return {
    key: fieldConfigInstance[fieldDataKey],
    id: fieldIndex,
    fieldData,
    fieldDataSecondary,
    fieldLabel,
    fieldMobile,
    mandatoryField,
    showField,
    fieldType: fieldConfigInstance.fieldType,
    dependentContent: fieldConfigInstance.dependentContent,
    allowDecimals
  };
};

/**
 * Component Built to restructure the API response and table fields
 */

const ReprojectionTableDataFormatter = ({
  tableSectionFieldConfig,
  apiFieldParentKey,
  reprojectionsDetails,
  dependentFieldConfig,
  policyCurrencyFormat,
  reprojectionGenericConfig,
  selectedFutureBenefitsTab,
  tableHeaderText,
  selectedTabName,
  policyPhaseFlag
}) => {
  let formattedTableData = {};
  const apiDataToRenderTable = _.get(reprojectionsDetails, apiFieldParentKey);
  const policyCurrency = _.get(reprojectionsDetails, policyCurrencyFormat);
  const policyPhaseIndicator = _.get(reprojectionsDetails, policyPhaseFlag);
  apiDataToRenderTable &&
    _.forEach(apiDataToRenderTable, function loopApiDataToRenderTable(
      fieldDataInstance,
      apiIndex
    ) {
      _.forEach(tableSectionFieldConfig, function loopTableSectionFieldConfig(
        fieldConfigInstance,
        index
      ) {
        if (!_.isEmpty(fieldConfigInstance.dependentFields)) {
          const {
            dependentFields,
            fieldMobile,
            mandatoryField
          } = fieldConfigInstance;
          _.forEach(dependentFields, (field, depIndex) => {
            const dependentFieldInstance = dependentFieldConfig[field];
            const {
              dependentFieldIndicator,
              dependentFieldDynamicIndicator,
              allowDecimals,
              planCodesCheckList = [],
              dependentFieldToCheck = ''
            } = dependentFieldInstance;
            let showField = true;
            if (dependentFieldIndicator) {
              showField = displayIfNull(
                _.get(reprojectionsDetails, dependentFieldIndicator),
                false,
                true
              );
            }
            let depFieldLabel = _.get(
              reprojectionsDetails,
              dependentFieldDynamicIndicator
            );
            depFieldLabel = _.camelCase(depFieldLabel);
            depFieldLabel = getDepFieldLabel(
              depFieldLabel,
              dependentFieldInstance
            );

            if (doAND(planCodesCheckList.length, dependentFieldToCheck)) {
              const depApiValue = _.get(
                reprojectionsDetails,
                dependentFieldToCheck
              );

              depFieldLabel = getPlanCodeTypeDepFieldLabel(
                dependentFieldInstance,
                planCodesCheckList,
                depApiValue
              );
            }
            const dependentFieldTableComponent = _getTableCellComponent(
              dependentFieldInstance,
              fieldDataInstance,
              'dependentFieldName',
              `${index}_${apiIndex}_${depIndex}`,
              depFieldLabel,
              mandatoryField,
              fieldMobile,
              showField,
              allowDecimals
            );
            formattedTableData = _tableDataFormatter(
              fieldConfigInstance,
              formattedTableData,
              apiIndex,
              dependentFieldTableComponent,
              depFieldLabel
            );
          });
        } else {
          const tableFieldComponent = _getTableCellComponent(
            fieldConfigInstance,
            fieldDataInstance,
            'fieldName',
            `${index}_${apiIndex}`,
            fieldConfigInstance.fieldLabel,
            fieldConfigInstance.mandatoryField
          );

          if (fieldConfigInstance.fieldLabel in formattedTableData) {
            formattedTableData[fieldConfigInstance.fieldLabel][
              apiIndex
            ] = tableFieldComponent;
          } else {
            formattedTableData[fieldConfigInstance.fieldLabel] = [
              tableFieldComponent
            ];
          }
        }
      });
    });
  const reprojectionTableData = getReprojectionTableData(
    formattedTableData,
    policyCurrency,
    policyPhaseIndicator
  );
  return (
    <>
      {getReprojectionTable(
        reprojectionTableData,
        policyCurrency,
        reprojectionGenericConfig,
        selectedFutureBenefitsTab,
        tableHeaderText,
        selectedTabName,
        policyPhaseIndicator
      )}
    </>
  );
};

ReprojectionTableDataFormatter.propTypes = {
  tableSectionFieldConfig: PropTypes.array.isRequired,
  apiFieldParentKey: PropTypes.string.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  policyCurrencyFormat: PropTypes.string.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  tableHeaderText: PropTypes.string.isRequired,
  selectedTabName: PropTypes.string.isRequired,
  policyPhaseFlag: PropTypes.bool.isRequired
};

export default ReprojectionTableDataFormatter;
