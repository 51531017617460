import {
  CANCEL_REQUEST_SUCCESS,
  CANCEL_REQUEST_ERRORS_WARNINGS,
  RESET_CANCER_ERROR_STORE
} from '../actionTypes';

export const INITIAL_STATE = {
  cancelRequestData: {},
  cancelRequestWarningsOrErrors: {}
};

const cancelRequestReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case CANCEL_REQUEST_SUCCESS: {
      const cancelRequestData = { ...state.cancelRequestData, ...payload };
      return {
        ...state,
        cancelRequestData
      };
    }
    case CANCEL_REQUEST_ERRORS_WARNINGS: {
      const cancelRequestWarningsOrErrors = {
        ...state.cancelRequestWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        cancelRequestWarningsOrErrors
      };
    }
    case RESET_CANCER_ERROR_STORE: {
      const { cancelRequestWarningsOrErrors } = state;
      delete cancelRequestWarningsOrErrors[payload];
      return {
        ...state,
        cancelRequestWarningsOrErrors
      };
    }
    default:
      return state;
  }
};

export default cancelRequestReducer;
