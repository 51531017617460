import { getCookie, setCookie } from './cookieUtils';

class LocaleManager {
  getLocaleForHSBCWebApps() {
    this.localeString = getCookie('HSBC_CLIENT_COOKIE');
    if (!this.localeString) {
      return null;
    }
    let locale = null;
    const localeSplit = this.localeString.split('=')[1];
    locale = localeSplit || null;
    return locale;
  }

  setLocaleForHSBCWebApps(locale = process.env.REACT_APP_DEFAULT_LOCALE) {
    this.configuration = {
      path: '/'
    };
    const preferedLocale = `PreferredLocale=${locale}`;
    setCookie('HSBC_CLIENT_COOKIE', preferedLocale, this.configuration);
  }
}

export default new LocaleManager();
