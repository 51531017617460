import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Context from '../../../app/Context';
import BackIcon from '../../../assets/images/cevrron-right.svg';
import {
  DetailsNotification,
  NotificationRow,
  LeftNotificationRow,
  ButtonWrapperOL,
  ListLI,
  ImageSpan
} from './instructionHistoryExceptionStyles';
import titleHandler from '../../../utils/titleUtils';
import { trackEvents } from '../../../utils/tealiumUtils';
import { BackButton } from '../breadCrumbStyle';
import { ImagePath } from '../toggleHandlerStyle';

const InstructionHistoryExceptionBreadCrumb = function InstructionHistoryExceptionBreadCrumb(
  props,
  context
) {
  const {
    intl: { formatMessage }
  } = context;

  const title = formatMessage({ id: 'instruction_history' });
  titleHandler(title);

  const { policyDetailsTealium } = props;

  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;

        return (
          <>
            <DetailsNotification>
              <NotificationRow>
                <LeftNotificationRow>
                  <ButtonWrapperOL>
                    <ListLI>
                      <Link
                        to={forLink.summary}
                        id="my_insurance_back_link"
                        onClick={() =>
                          trackEvents({
                            eventName: 'myInsurance',
                            policyDetailsTealium
                          })
                        }
                      >
                        <BackButton
                          aria-label={formatMessage({
                            id: 'BackMyInsuranceAriaText'
                          })}
                        >
                          {formatMessage({ id: 'BackMyInsurance' })}
                          <ImageSpan>
                            <ImagePath src={BackIcon} alt="" />
                          </ImageSpan>
                        </BackButton>
                      </Link>
                    </ListLI>
                    <ListLI currentPage>
                      <span aria-current="page">
                        {formatMessage({ id: 'instruction_history' })}
                      </span>
                    </ListLI>
                  </ButtonWrapperOL>
                </LeftNotificationRow>
              </NotificationRow>
            </DetailsNotification>
          </>
        );
      }}
    </Context.Consumer>
  );
};

InstructionHistoryExceptionBreadCrumb.contextTypes = {
  intl: PropTypes.object.isRequired,
  applicationUrl: PropTypes.object
};

InstructionHistoryExceptionBreadCrumb.propTypes = {
  policyDetailsTealium: PropTypes.object.isRequired
};

export default InstructionHistoryExceptionBreadCrumb;
