import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import iconRight from '../../assets/images/Icon_right.svg';
import edit from '../../assets/images/Edit.svg';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import ClaimWrapper from './ClaimWrapper';
import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';
import { setLinkFocus } from '../../utils/domUtils';
import getContentInSpecificLang from '../../utils/localeUtils';
import ModalPopupGI from './ModalPopupGI';
import { getCurrentPolicyType } from '../sectionUtils/SectionsUtils';

const UpdatePersonalDetails = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const { genericConfig, superFamily, footerButtonList, langObj } = props;
  const eventAction = 'onsite';
  const linkRef = React.createRef();
  const linkName = 'policy overview';
  let eventData = {};
  const { isLifePolicy, isGIPolicy } = getCurrentPolicyType(superFamily);

  const updateContactKeyLangSpecific = getContentInSpecificLang(
    'update_contact_information',
    'en',
    langObj
  );

  eventData = createEventActionContent(
    eventAction,
    updateContactKeyLangSpecific,
    linkName
  );
  const [isModalOpen, updateModalState] = useState(false);
  const onContinueClick = () => {
    trackEvents({
      eventName: 'popup',
      eventContent: updateContactKeyLangSpecific.toLowerCase()
    });
  };

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
    trackEvents({
      eventName: 'popup',
      eventContent: updateContactKeyLangSpecific.toLowerCase()
    });
    setLinkFocus(linkRef.current);
  };

  return (
    <>
      <ClaimWrapper
        id="update_contact_information"
        ariaLabel={formatMessage({ id: 'update_contact_information' })}
        onClick={e => {
          updateModalState(!isModalOpen);
          trackEvents({
            eventName: 'updateContactInformation',
            eventData
          });
          e.preventDefault();
        }}
        altText="edit-icon"
        iconSrc={edit}
        linkLabel={formatMessage({ id: 'update_contact_information' })}
        altTextIconRight="right-icon"
        iconRight={iconRight}
        linkRef={linkRef}
      />
      {isLifePolicy && (
        <>
          <ModalPopUp
            isModalOpen={isModalOpen}
            onClose={onCancelClick}
            modalHeading=""
            footerButtonList={footerButtonList}
            btnHandler={{
              continue_btn: onContinueClick,
              cancel_btn: onCancelClick
            }}
            proceedToLink={genericConfig.contactUpdateURL[locale]}
          >
            <ParagraphDiv>
              {formatMessage({ id: 'makeaClaimPopUpText' })}
            </ParagraphDiv>
          </ModalPopUp>
        </>
      )}
      {isGIPolicy && (
        <ModalPopupGI
          linkLabel={formatMessage({
            id: 'updatePaymentInstructionPopupTitle'
          })}
          isModalOpen={isModalOpen}
          onClose={onCancelClick}
          footerButtonList={[
            {
              className: 'redBtn',
              label: formatMessage({
                id: 'updatePaymentInstructionClosePopupButton'
              }),
              id: 'close_btn',
              align: 'left'
            }
          ]}
          btnHandler={{
            close_btn: onCancelClick
          }}
        />
      )}
    </>
  );
};

export default UpdatePersonalDetails;

UpdatePersonalDetails.contextTypes = {
  intl: PropTypes.object.isRequired
};

UpdatePersonalDetails.propTypes = {
  genericConfig: PropTypes.object.isRequired,
  footerButtonList: PropTypes.array.isRequired,
  langObj: PropTypes.object.isRequired,
  superFamily: PropTypes.object.isRequired
};
