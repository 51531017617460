import styled from 'styled-components';
import { rem } from 'polished';
import theme from './theme';
import media from '../../utils/media';
import { FontFamily, FontSize } from '../../utils/font';

export const StepTitleDiv = styled.div`
  display: flex;
  align-item:center:
  justify-content: center;
  padding-bottom: ${rem('24px')};
`;

export const StepTitleHeader = styled.h2`
  ${FontFamily.LightFontW01};
  ${FontSize.size4};
  color: ${theme.fontColor};
  padding-left: ${rem('16px')};
  position:relative;
  width:64%;
  ${media.tablet`
  width:100%;
    `}
  &:before{
   content:'';
   position:absolute;
   width:3px;
   height: 24px;
   top: 2px;
  background-color:${theme.primary}
   left: 0;
    right: auto;
    ${media.tablet`
      height:21px;
    `}
  }
`;

export const BannerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
  margin-bottom: 40px;
  box-sizing: border-box;
  ${media.tablet`
    width: 100%; 
  `};
  ${media.phone` 
    flex-direction: column;
  `};
`;

export const BannerDetails = styled.div`
  height: 450px;
  border: 1px solid #d7d7d6;
  ${media.tablet`
    width: 33.3%;
  `}
  ${media.phone`
    width: 100%;
  `}
`;

export const BannerLinkDescription = styled.div`
  padding: 20px;
`;

export const BannerLinkWrapper = styled.div`
  margin-bottom: 12px;
  font-size: ${rem('28px')};
  padding-right: 2px;
  ${media.phone`
    font-size: ${rem('23px')};
  `};
`;

export const BannerLink = styled.a`
  line-height: 36px;
  cursor: pointer;
  color: ${theme.blackColor};
  text-decoration: none;
  &:focus,
  &:hover {
    outline: 1px dotted black;
  }
  ${media.phone`
    line-height: 30px;
  `};
`;

export const BaneerDescription = styled.span`
  font-size: 16px;
  line-height: 24px;
  ${FontFamily.LightFontW01}
`;

export const BannerImage = styled.img`
  width: ${rem('380px')};
  height: ${rem('225px')};
  ${media.tablet`
    width: 100%;
    height: ${rem('160px')};
  `};
  ${media.phone`
    height: ${rem('200px')};
  `};
`;

export const CaretImg = styled.img`
  width: ${rem('7px')};
  height: ${rem('14px')};
  margin-left: 8px;
`;
