import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import warningIcon from '../../../assets/images/warning-colour.svg';

import {
  SupplementaryStatusWrapper,
  SupplementaryStatusImgSpan,
  SupplementaryStatus,
  ImagePath
} from '../../detailPageStyle';

const ChildRiderBenefitStatus = function ChildRiderBenefitStatus(
  props,
  context
) {
  const {
    intl: { formatMessage }
  } = context;

  const { fieldData, fieldName, fieldLabel } = props;
  let contentDescription = '';
  let isInActive;
  contentDescription = formatMessage({ id: fieldLabel });

  if (fieldData && fieldData.length) {
    isInActive = _.some(fieldData, response => {
      return response[fieldName] !== 'IF';
    });
  }

  if (!fieldData || !fieldLabel || !isInActive) {
    return <></>;
  }

  return (
    <SupplementaryStatusWrapper>
      <SupplementaryStatusImgSpan>
        <ImagePath aria-hidden="true" alt="" src={warningIcon} />
      </SupplementaryStatusImgSpan>
      <SupplementaryStatus>
        <span>{contentDescription}</span>
      </SupplementaryStatus>
    </SupplementaryStatusWrapper>
  );
};

ChildRiderBenefitStatus.propTypes = {
  fieldData: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired
};

ChildRiderBenefitStatus.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ChildRiderBenefitStatus;
