import {
  START_LOADER,
  END_LOADER,
  COMPLETE_APP_BOOTSTRAP,
  SET_IS_STUBBED,
  SET_ANCHORING_TO_POLICIES,
  SET_IDLE_FLAG,
  SET_PAGE_SAAS_FAIL,
  SET_PAGE_CONTACTUS_POP,
  SET_SELECT_TAB,
  UPDATE_LOADER_OPACITY,
  SET_GA_ELIGIBLE
} from '../actionTypes';

export const INITIAL_STATE = {
  appBootstrapComplete: false,
  isLoading: false,
  isLoaderTransparent: false,
  isStubbed: false,
  needToAnchorToPolicies: false,
  isIdle: false,
  pageSaasFail: false,
  showContactUsFooterPopup: false,
  selectedTabId: 0,
  eligibilityDetails: {}
};

const appReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
  switch (type) {
    case START_LOADER:
      return { ...state, isLoading: true };
    case END_LOADER:
      return { ...state, isLoading: false };
    case COMPLETE_APP_BOOTSTRAP:
      return { ...state, appBootstrapComplete: true };
    case SET_IS_STUBBED:
      return { ...state, isStubbed: true };
    case SET_ANCHORING_TO_POLICIES:
      return { ...state, needToAnchorToPolicies: true };
    case SET_IDLE_FLAG:
      return { ...state, isIdle: payload };
    case SET_PAGE_SAAS_FAIL:
      return { ...state, pageSaasFail: true };
    case SET_PAGE_CONTACTUS_POP:
      return { ...state, showContactUsFooterPopup: payload };
    case SET_SELECT_TAB:
      return { ...state, selectedTabId: payload };
    case UPDATE_LOADER_OPACITY:
      return { ...state, isLoaderTransparent: payload };
    case SET_GA_ELIGIBLE:
      return { ...state, eligibilityDetails: payload };
    default:
      return state;
  }
};

export default appReducer;
