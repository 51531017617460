import React from 'react';
import PropTypes from 'prop-types';
import { OverviewNumber, ValuationText } from '../../reprojectionPageStyle';

const OverviewPolicyNumber = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { labelContent, fieldData } = props;
  if (!labelContent || !fieldData) {
    return <></>;
  }

  return (
    <OverviewNumber id="reprojection_PolicyNumber">
      <ValuationText>
        {formatMessage({
          id: labelContent
        })}
      </ValuationText>
      {fieldData}
    </OverviewNumber>
  );
};
OverviewPolicyNumber.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired
};

OverviewPolicyNumber.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default OverviewPolicyNumber;
