import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import { OverviewItem } from '../../detailPageStyle';
import DateComponent from '../../../common/date/Date';

const ValuationDate = function ValuationDate(props, context) {
  const { fieldData, labelContent } = props;
  const {
    intl: { formatMessage, locale }
  } = context;
  if (!fieldData || typeof fieldData !== 'string') {
    return <></>;
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>
        <OverviewItem id="fundValuationDate">
          {`${formatMessage({ id: labelContent })} `}
          <DateComponent
            date={fieldData}
            locale={locale}
            apiDateFormat="YYYY-MM-DD"
            id="standaradDateFormat"
          />
        </OverviewItem>
      </ShowIf>
    </>
  );
};

ValuationDate.propTypes = {
  fieldData: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired
};

ValuationDate.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ValuationDate;
