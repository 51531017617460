import _ from 'lodash';
import { getRoutesPagePath } from '../../../../utils/routeUtils';
import { checkCustomerSegment } from '../../../../policysummary/summaryUtils/simplyBookUtils';

export const _getHTMLElementIDs_ = () => {
  return {
    megaMenuEle: document.getElementById('megaMenu'),
    myInsuranceLinkEle: document.getElementById('myInsurance_link'),
    policiesLinkEle: document.getElementById('policies_link')
  };
};

export const hideMegaMenu = () => {
  const { megaMenuEle } = _getHTMLElementIDs_();
  megaMenuEle.style.display = 'none';
  setTimeout(() => {
    megaMenuEle.style.display = '';
  }, 1000);
};

export const toggleMegaMenu = displayProperty => {
  const { megaMenuEle } = _getHTMLElementIDs_();
  megaMenuEle.style.display = displayProperty;
};

export const checkMegaMenuStyle = () => {
  const { megaMenuEle } = _getHTMLElementIDs_();
  return (
    megaMenuEle.style.display === 'none' || megaMenuEle.style.display === ''
  );
};

export const checkEvent = e => {
  return (
    (e.keyCode ? e.keyCode : e.which) === 13 ||
    (e.keyCode ? e.keyCode : e.which) === 32
  );
};

export const handleMegaMenuStateOnHover = () => {
  const { myInsuranceLinkEle } = _getHTMLElementIDs_();
  myInsuranceLinkEle.style.boxSizing = 'border-box';
  myInsuranceLinkEle.style.borderBottom = '4px solid #DB0011';
  document.querySelector('#myInsurance_link span').style.fontFamily =
    'UniversNextforHSBC-Bold';
};

export const handleMegaMenuStateOnOut = () => {
  const { myInsuranceLinkEle } = _getHTMLElementIDs_();
  myInsuranceLinkEle.style.boxSizing = 'border-box';
  myInsuranceLinkEle.style.borderBottom = '';
  document.querySelector('#myInsurance_link span').style.fontFamily = '';
};

export const keyboardHandler = function keyboardHandler(e) {
  e.preventDefault();
  const {
    megaMenuEle,
    myInsuranceLinkEle,
    policiesLinkEle
  } = _getHTMLElementIDs_();
  if (checkEvent(e) && e.target.parentNode.classList.contains('hasMegaMenu')) {
    if (checkMegaMenuStyle()) {
      toggleMegaMenu('flex');
      handleMegaMenuStateOnHover();
    } else {
      hideMegaMenu();
      // handleMegaMenuStateOnOut();
    }
    if (myInsuranceLinkEle) {
      myInsuranceLinkEle.setAttribute(
        'aria-expanded',
        megaMenuEle.style.display === 'flex'
      );
    }
    if (policiesLinkEle) {
      policiesLinkEle.focus();
    }
  }
};

/*
* return linkTo object as below
  { policies: '/dashboard-servicing/policies' }
  { browsebycategories: "/dashboard-servicing/browsebycategories" }
*
*/
export const megaMenuRouterLinks = pathToMatch => {
  const linkTo = {};
  _.forIn(getRoutesPagePath().forLink, function(value, key) {
    const isMatch = key === pathToMatch; // ex. key = policies and pathToMatch = policies
    if (isMatch) {
      linkTo[key] = value;
    }
  });
  return linkTo;
};

export const checkMegaMenuFor = (currentLink, megaMenuForMastHeadLink) => {
  return currentLink === megaMenuForMastHeadLink;
};

export const checkMastheadMenuLinks = (
  currentLink,
  megaMenuForMastHeadLink,
  currentLinkUrl
) => {
  if (currentLink !== megaMenuForMastHeadLink) {
    return currentLinkUrl;
  }
  return false;
};

export const getProductLink = function getProductLink(productLink, locale) {
  if (typeof productLink === 'object') {
    return productLink[locale];
  }
  return productLink;
};

export const handleFocusOutClick = function handleFocusOutClick(e) {
  const { megaMenuEle, myInsuranceLinkEle } = _getHTMLElementIDs_();
  const lastElement = 'makeClaim_link';
  const firstElement = 'myInsurance_link';
  const checkLinkParentClass =
    e.relatedTarget &&
    e.relatedTarget.offsetParent.classList.contains('megaMenuWrapper');
  const focusOutCheck =
    e.relatedTarget === null ||
    (e.target.id === lastElement &&
      e.target.parentNode.previousSibling &&
      !checkLinkParentClass) ||
    e.relatedTarget.id === firstElement;
  if (focusOutCheck && megaMenuEle.style.display === 'flex') {
    megaMenuEle.removeAttribute('style');
    handleMegaMenuStateOnOut();
    if (myInsuranceLinkEle) {
      myInsuranceLinkEle.setAttribute('aria-expanded', false);
    }
  }
};

export const canDisplayMegaMenuLink = (menuObject, displayConditionParams) => {
  const { customer, customerSegmentValuesArray } = displayConditionParams;
  if (menuObject.condition === 'simplyBook') {
    return !checkCustomerSegment(customer, customerSegmentValuesArray);
  }
  return true;
};
