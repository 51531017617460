import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ParentLinkWrapper,
  SeeDetail,
  ImgWrapper,
  IconRightImg
} from '../instructionHistoryStyle';
import iconLeft from '../../assets/images/icon_left.svg';
import Context from '../../app/Context';

const BackToParentLink = (props, context) => {
  const { encodePolicyNumber } = props;
  const {
    intl: { formatMessage }
  } = context;
  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;
        const { details } = forLink || {};
        return (
          <ParentLinkWrapper>
            <Link
              to={`${details}${encodePolicyNumber}`}
              id="back_policy_details"
            >
              <ImgWrapper>
                <IconRightImg aria-hidden="true" alt="" src={iconLeft} />
              </ImgWrapper>
              <SeeDetail>{formatMessage({ id: 'back_btn' })}</SeeDetail>
            </Link>
          </ParentLinkWrapper>
        );
      }}
    </Context.Consumer>
  );
};

BackToParentLink.propTypes = {
  encodePolicyNumber: PropTypes.string.isRequired
};
BackToParentLink.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default BackToParentLink;
