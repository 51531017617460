import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { truncDecimalIfGreaterThanBuffer } from '../../utils/formatter';
import {
  Table,
  TableRow,
  TableData,
  TableBody,
  TableHeader,
  TableHeaderText
} from './styles/SurrenderChargeTableStyle';
import { TableCaption } from '../../common/styles/reprojectionTableStyle';
import { AccessibleHidden } from '../../common/styles/commonStyle';

const SurrenderChargeTable = (props, context) => {
  const { surrRateData, toDisplayPlus, decimalRange, maxYear } = props;
  const {
    intl: { formatMessage }
  } = context;
  const displayRate = function displayRate(rate) {
    return _.isNaN(Number(rate))
      ? rate
      : `${truncDecimalIfGreaterThanBuffer(rate, decimalRange)}%`;
  };

  const showPlus = function showPlus(idx) {
    return idx === 16 && toDisplayPlus ? '+' : '';
  };

  const table = [];
  const children = [];
  for (let i = 1; i <= maxYear; i += 1) {
    const { rate } = surrRateData[i];
    children.push(
      <TableRow key={`key_${i}`} data-testid="tableMobile-tableRow">
        <TableHeader
          scope="row"
          id={`policyYear_${i}`}
          headers="endOfPolicyYear"
          showBorderBottom={i !== maxYear}
        >
          <TableHeaderText>
            {formatMessage({ id: 'endOfPolicyYear' })}
          </TableHeaderText>
          {i}
          {showPlus(i)}
        </TableHeader>
        <TableData
          headers={`surrenderChargeRate  policyYear_${i}`}
          showBorderLeft
          showBorderBottom={i !== maxYear}
        >
          {displayRate(rate)}
        </TableData>
      </TableRow>
    );
  }
  table.push(
    <thead>
      <TableRow policyYear>
        <TableHeader showBorderBottom scope="col" id="endOfPolicyYear" />
        <TableHeader
          showBorderBottom
          scope="col"
          headers="endOfPolicyYear"
          id="surrenderChargeRate"
          showBorderLeft
        >
          {formatMessage({ id: 'surrenderChargeRate' })}
        </TableHeader>
      </TableRow>
    </thead>
  );
  table.push(<TableBody>{children}</TableBody>);

  return (
    <Table>
      <TableCaption>
        <AccessibleHidden>
          {formatMessage({ id: 'tableCaptionDisclaimer' })}
        </AccessibleHidden>
      </TableCaption>
      <>{table}</>
    </Table>
  );
};

SurrenderChargeTable.propTypes = {
  surrRateData: PropTypes.object.isRequired,
  toDisplayPlus: PropTypes.bool.isRequired,
  decimalRange: PropTypes.number.isRequired,
  maxYear: PropTypes.number.isRequired
};

SurrenderChargeTable.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SurrenderChargeTable;
