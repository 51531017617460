import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';
import { getPaymentMode } from '../../../utils/tealiumUtils';
import getDescription from '../../../utils/reducerUtils';
import getContentInSpecificLang from '../../../utils/localeUtils';

function getPaymentModeOnUI(superFamily, paymentModeKey, formatMessage) {
  return superFamily === 'gi'
    ? paymentModeKey
    : formatMessage({ id: paymentModeKey });
}

function hasValidSecondDependentFieldData(secondDependentFieldData) {
  return (
    secondDependentFieldData &&
    (secondDependentFieldData.trim() === 'AGG' ||
      secondDependentFieldData.trim() === 'S01')
  );
}

function getPaymentModeKeyFromAPI(messages, fieldData, locale) {
  return messages[`paymentMode_label_${fieldData.key}`]
    ? `paymentMode_label_${fieldData.key}`
    : fieldData &&
        fieldData.narrative &&
        getDescription(locale, fieldData.narrative);
}

function getPaymentModeKeyValue(
  messages,
  dependentFieldData,
  fieldData,
  paymentModeKey,
  locale
) {
  let tempPaymentModeKey = paymentModeKey;
  if (messages[`paymentMode_label_${dependentFieldData.key}${fieldData.key}`]) {
    tempPaymentModeKey = `paymentMode_label_${dependentFieldData.key}${fieldData.key}`;
  } else {
    /**
     * In case there is no particular payment modes that are configured in the application's content,
     * the fallback kicks in from APIs response for Payment mode.
     */
    tempPaymentModeKey = getPaymentModeKeyFromAPI(messages, fieldData, locale);
  }
  return tempPaymentModeKey;
}

function hasValidDependentFieldData(dependentFieldData) {
  return dependentFieldData && dependentFieldData.key;
}

function hasValidFieldData(fieldData) {
  return fieldData && fieldData.narrative;
}

const PaymentInfoMode = function PaymentInfoModeComponent(props, context) {
  const {
    labelContent,
    fieldData,
    dependentFieldData,
    secondDependentFieldData,
    superFamily,
    langObj
  } = props;
  const {
    intl: { messages, formatMessage, locale }
  } = context;
  let paymentModeKey = '';
  let paymentModeOnUI = '';
  /**
   * GI policies : We directly consume what API sends us for paymentMode in
   * narrative form.
   */

  if (superFamily === 'gi') {
    if (hasValidFieldData(fieldData)) {
      paymentModeKey = getDescription(locale, fieldData.narrative);
    } else {
      return null;
    }
  } else {
    /**
     * Life Policies : In case Payment Method(dependentFieldData) returns any of the
     * values like X, S, or N, we maintain a combination of these letters with paymentMode
     * values like 01, 02, 03, 00, etc.
     *
     * If a combination of payment mode and method exists in our content repo
     * eg: X01, N00 etc, we show the payment mode as per that combination.
     * If it doesn't exist, we show the content of payment mode as per
     * paymentMode's value i.e. 01, 02 etc.
     *
     * On top of this, if paymentModeReference(secondDependentFieldData) is either
     * S01 or AGG, we cater them differently.
     * For both of S01 and AGG, We straight away show the content from the content repo.
     * We don't consider the payment Method's value at all.
     */

    if (hasValidDependentFieldData(dependentFieldData)) {
      paymentModeKey = getPaymentModeKeyValue(
        messages,
        dependentFieldData,
        fieldData,
        paymentModeKey,
        locale
      );
    }
    if (hasValidSecondDependentFieldData(secondDependentFieldData)) {
      paymentModeKey = `paymentMode_label_${secondDependentFieldData.trim()}`;
    }

    if (!paymentModeKey) {
      return null;
    }
  }

  const paymentModeKeyLangSpecific = getContentInSpecificLang(
    paymentModeKey,
    'en',
    langObj
  );
  getPaymentMode(paymentModeKeyLangSpecific);

  /**
   * paymentModeOnUI - string to be displayed on the UI representing Payment Mode
   *
   *
   */
  paymentModeOnUI = getPaymentModeOnUI(
    superFamily,
    paymentModeKey,
    formatMessage
  );

  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>{paymentModeOnUI}</DataTextBold>
      </OverviewInfoFieldDataValue>
    </PaymentInfoFieldWrapper>
  );
};

PaymentInfoMode.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.object,
  secondDependentFieldData: PropTypes.string,
  superFamily: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired
};

PaymentInfoMode.defaultProps = {
  toolTipContent: null,
  dependentFieldData: null,
  secondDependentFieldData: ''
};

PaymentInfoMode.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PaymentInfoMode;
