import { combineReducers } from 'redux';
import content from './contentReducer';
import saasToken from './saasTokenReducer';
import app from './appReducer';
import configurations from './configurationReducer';
import customer from './customerReducer';
import policy from './policyReducer';
import reprojection from './reprojectionReducer';
import policyDocuments from './policyDocumentsReducer';
import eWelcomePolicyDocuments from './eWelcomePolicyDocumentsReducer';
import instHistory from './instructionHistoryReducer';
import fundSelection from './fundSelectionReducer';
import cancelInstructionRequest from './cancelRequestReducer';
import eStatementPreferenceData from './eStatementPrefReducer';

const reducer = combineReducers({
  saasToken,
  content,
  app,
  configurations,
  customer,
  policy,
  reprojection,
  policyDocuments,
  eWelcomePolicyDocuments,
  instHistory,
  fundSelection,
  cancelInstructionRequest,
  eStatementPreferenceData
});

export default reducer;
