import React from 'react';
import PropTypes from 'prop-types';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import { ParagraphDiv } from '../../common/styles/modalStyles';

const ModalPopupGI = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    linkLabel,
    isModalOpen,
    onClose,
    footerButtonList,
    btnHandler
  } = props;

  return (
    <ModalPopUp
      isModalOpen={isModalOpen}
      onClose={onClose}
      modalHeading={linkLabel}
      footerButtonList={footerButtonList}
      btnHandler={btnHandler}
    >
      <ParagraphDiv>
        {formatMessage({
          id: 'updatePaymentInstructionPopupDescLine1'
        })}
      </ParagraphDiv>
      <ParagraphDiv>
        {formatMessage({
          id: 'updatePaymentInstructionPopupDescLine2'
        })}
      </ParagraphDiv>
      <ParagraphDiv>
        {formatMessage({
          id: 'updatePaymentInstructionPopupDescLine3'
        })}
      </ParagraphDiv>
      <ParagraphDiv>
        {formatMessage({
          id: 'updatePaymentInstructionPopupDescLine4'
        })}
      </ParagraphDiv>
    </ModalPopUp>
  );
};

export default ModalPopupGI;

ModalPopupGI.contextTypes = {
  intl: PropTypes.object.isRequired
};

ModalPopupGI.propTypes = {
  linkLabel: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  footerButtonList: PropTypes.array.isRequired,
  btnHandler: PropTypes.func.isRequired
};
