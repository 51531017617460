import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import _some from 'lodash/some';
import _map from 'lodash/map';
import _isArray from 'lodash/isArray';
import * as Sections from './sections';
import * as templates from './templates';
import { getFieldDataFromConfig } from '../utils/policyUtils';
import { isPolicyInException } from '../utils/reprojectionsUtils';
import { doOR, doAND, displayIfNull } from '../common/util/commonUtil';

// Following function will generate field for specific template
const generateReprojectionsFields = (
  sectionFieldConfigInstance,
  reprojectionsDetails,
  configuratorIdentifier,
  sectionName,
  subsectionIndex,
  policyTagsMedicalAndCIConfig
) =>
  sectionFieldConfigInstance.map((fieldConfigInstance, index) => {
    const FieldComponent = templates[fieldConfigInstance.uiFormat];
    const fieldData = getFieldDataFromConfig(
      reprojectionsDetails,
      fieldConfigInstance
    );
    const {
      dependentContent = '',
      isMandatoryToShow = false,
      allowDecimals = false,
      planCodesCheckList = [],
      planCodeFieldLabel = '',
      dependentFieldToCheck = '',
      planCodeFieldTooltip = ''
    } = fieldConfigInstance;
    let labelContent = fieldConfigInstance.fieldLabel;
    // need to check if this extra label required
    const fieldLabelSecondayKey = `fieldLabel_${configuratorIdentifier}`;
    if (fieldConfigInstance[fieldLabelSecondayKey]) {
      labelContent = fieldConfigInstance[fieldLabelSecondayKey];
    }

    let dependentFieldData;
    if (fieldConfigInstance.dependentField) {
      dependentFieldData = getFieldDataFromConfig(
        reprojectionsDetails,
        fieldConfigInstance,
        true,
        'dependentField'
      );
      if (doAND(dependentFieldData, fieldConfigInstance.dependentFieldLabel)) {
        dependentFieldData.labelContent =
          fieldConfigInstance.dependentFieldLabel;
      }
    }
    let secondDependentFieldData;
    if (fieldConfigInstance.dependentFieldTwo) {
      secondDependentFieldData = getFieldDataFromConfig(
        reprojectionsDetails,
        fieldConfigInstance,
        true,
        'dependentFieldTwo'
      );
      if (
        doAND(
          secondDependentFieldData,
          fieldConfigInstance.dependentFieldTwoLabel
        )
      ) {
        secondDependentFieldData.labelContent =
          fieldConfigInstance.dependentFieldTwoLabel;
      }
    }
    let thirdDependentFieldData;
    if (fieldConfigInstance.dependentFieldThree) {
      thirdDependentFieldData = getFieldDataFromConfig(
        reprojectionsDetails,
        fieldConfigInstance,
        true,
        'dependentFieldThree'
      );
      if (
        doAND(
          thirdDependentFieldData,
          fieldConfigInstance.dependentFieldThreeLabel
        )
      ) {
        thirdDependentFieldData.labelContent =
          fieldConfigInstance.dependentFieldThreeLabel;
      }
    }
    let dependantValue;
    if (dependentFieldToCheck) {
      dependantValue = _.get(reprojectionsDetails, dependentFieldToCheck);
    }
    if (!FieldComponent) {
      return <React.Fragment key={fieldConfigInstance.fieldLabel} />;
    }
    return (
      <FieldComponent
        id={`field_${sectionName}_${subsectionIndex}_${index}`}
        idx={index}
        key={fieldConfigInstance.fieldLabel}
        labelContent={labelContent}
        fieldData={fieldData}
        toolTipContent={fieldConfigInstance.toolTip}
        dependentFieldData={dependentFieldData}
        secondDependentFieldData={secondDependentFieldData}
        thirdDependentFieldData={thirdDependentFieldData}
        dependentContentData={dependentContent}
        isMandatoryToShow={isMandatoryToShow}
        allowDecimals={allowDecimals}
        policyTagsMedicalAndCIConfig={policyTagsMedicalAndCIConfig}
        planCodesCheckList={planCodesCheckList}
        planCodeFieldLabel={planCodeFieldLabel}
        dependantValue={dependantValue}
        planCodeFieldTooltip={planCodeFieldTooltip}
      />
    );
  });

const ReprojectionSectionBuilder = props => {
  const {
    reprojectionsDetails,
    productCategory,
    configuratorIdentifier,
    selectedLocale,
    sectionConfig,
    sectionFieldMapper,
    subSectionsMapper,
    fieldConfig,
    dependentFieldConfig,
    reprojectionGenericConfig,
    reprojectionChartConfig,
    sectionNotificationConfig,
    customerAge,
    setCustomerAge,
    setCustomerMinPayAge,
    setCustomerMaxPayAge,
    customerMinPayAge,
    customerMaxPayAge,
    toggleIncludeNonGuaranteedPayout,
    tabList,
    tabValues,
    disclaimerConfig,
    setReprojectionsSelectTabHandler,
    selectedFutureBenefitsTab,
    warningsOrErrors,
    isFor,
    policyTagsMedicalAndCIConfig
  } = props;

  let policySectionOrder =
    configuratorIdentifier && sectionConfig[configuratorIdentifier];

  if (!policySectionOrder) {
    policySectionOrder = sectionConfig.DEFAULT;
  }
  const sectionsStack = _map(policySectionOrder, policySection => {
    const {
      [policySection]: {
        subSections,
        fields,
        template,
        headerContent,
        headerDependentContent,
        showInException = false
      }
    } = sectionFieldMapper;

    /**
     * Checking for Exceptional Policy.
     */

    const hasException = isPolicyInException(warningsOrErrors);

    /**
     * If policy is exceptional, omits the sections here which are not supposed to be shown on exceptional state(when errors found)
     */

    if (doAND(hasException, !showInException)) {
      return <React.Fragment key={policySection} />;
    }
    if (doAND(!hasException, showInException)) {
      return <React.Fragment key={policySection} />;
    }

    let sectionFieldList = fields;
    // checking sub section which given in reprojection config file
    if (subSections) {
      sectionFieldList = _map(
        subSections,
        subSectionInstance => subSectionsMapper[subSectionInstance].fields
      );
    }
    // assigning template
    const policySectionTemplate = template;
    // checking null check fir template and section/subsection
    if (
      doOR(
        !policySectionTemplate,
        doAND(sectionFieldList, sectionFieldList.length === 0, !subSections)
      )
    ) {
      return <React.Fragment key={policySection} />;
    }
    let fieldsStack;
    let sectionFieldConfig;
    const isNestedFields = _some(sectionFieldList, _isArray);

    if (isNestedFields) {
      sectionFieldConfig = _map(sectionFieldList, subSectionFieldList =>
        _map(subSectionFieldList, fieldName => fieldConfig[fieldName])
      );
      fieldsStack = sectionFieldConfig.map(
        (sectionFieldConfigInstance, subsectionIndex) => {
          return generateReprojectionsFields(
            sectionFieldConfigInstance,
            reprojectionsDetails,
            configuratorIdentifier,
            policySectionTemplate,
            subsectionIndex,
            policyTagsMedicalAndCIConfig
          );
        }
      );
    } else {
      sectionFieldConfig = _map(
        sectionFieldList,
        fieldName => fieldConfig[fieldName]
      );
      const subsectionIndex = 0;
      fieldsStack = generateReprojectionsFields(
        sectionFieldConfig,
        reprojectionsDetails,
        configuratorIdentifier,
        policySectionTemplate,
        subsectionIndex,
        policyTagsMedicalAndCIConfig
      );
    }
    const {
      byProductCategory: disclaimerConfigByProdCategory,
      explanatoryNotesConfigurations
    } = disclaimerConfig;
    const SpecificSection = Sections[policySectionTemplate];
    return (
      <SpecificSection
        reprojectionsDetails={reprojectionsDetails}
        productCategory={productCategory}
        fieldsStack={fieldsStack}
        key={fieldsStack}
        sectionFieldMapper={sectionFieldMapper}
        subSectionsMapper={subSectionsMapper}
        fieldConfig={fieldConfig}
        dependentFieldConfig={dependentFieldConfig}
        selectedLocale={selectedLocale}
        sectionHeading={headerContent}
        headerDependentContent={headerDependentContent}
        reprojectionGenericConfig={reprojectionGenericConfig}
        reprojectionChartConfig={reprojectionChartConfig}
        sectionNotificationConfig={sectionNotificationConfig}
        customerAge={customerAge}
        setCustomerAge={setCustomerAge}
        customerMinPayAge={customerMinPayAge}
        customerMaxPayAge={customerMaxPayAge}
        setCustomerMinPayAge={setCustomerMinPayAge}
        setCustomerMaxPayAge={setCustomerMaxPayAge}
        toggleIncludeNonGuaranteedPayout={toggleIncludeNonGuaranteedPayout}
        tabList={tabList}
        tabValues={tabValues}
        disclaimerConfig={displayIfNull(
          disclaimerConfigByProdCategory[productCategory],
          disclaimerConfigByProdCategory.DEFAULT
        )}
        explanatoryNotesConfigurations={explanatoryNotesConfigurations}
        setReprojectionsSelectTabHandler={setReprojectionsSelectTabHandler}
        selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        isFor={isFor}
        policyTagsMedicalAndCIConfig={policyTagsMedicalAndCIConfig}
      />
    );
  });
  return <>{sectionsStack}</>;
};

ReprojectionSectionBuilder.propTypes = {
  reprojectionsDetails: PropTypes.object.isRequired,
  productCategory: PropTypes.string.isRequired,
  configuratorIdentifier: PropTypes.string.isRequired,
  sectionConfig: PropTypes.object.isRequired,
  sectionFieldMapper: PropTypes.object.isRequired,
  subSectionsMapper: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  customerAge: PropTypes.number.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  toggleIncludeNonGuaranteedPayout: PropTypes.func.isRequired,
  tabValues: PropTypes.object.isRequired,
  disclaimerConfig: PropTypes.object.isRequired,
  setReprojectionsSelectTabHandler: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  warningsOrErrors: PropTypes.object.isRequired,
  isFor: PropTypes.string.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired
};

ReprojectionSectionBuilder.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    configurations: {
      reprojectionConfig: {
        products,
        sections,
        fields,
        subSections,
        dependentFields
      }
    }
  } = state;
  return {
    sectionConfig: products,
    sectionFieldMapper: sections,
    subSectionsMapper: subSections,
    fieldConfig: fields,
    dependentFieldConfig: dependentFields
  };
};

export default connect(mapStateToProps)(ReprojectionSectionBuilder);
