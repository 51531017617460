import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formatCurrency, currencyMap } from '../../utils/formatter';
/**
 * Utility function to provide a currency amount along with the currency symbol as a prefix to it.
 * @param {*} props
 *              currencyCode: The currency code(E.g HKD,EUR)
 *              amount: Number String.
 *
 */

export default function currencywithSymbol(props) {
  const { currencyCode, amount, decimalRange } = props;

  /**
   *  Some values for which we do not show the Currency Component.
   */

  const suppressedForValues = [undefined, null, ''];
  if (
    _.includes(suppressedForValues, currencyCode) ||
    _.includes(suppressedForValues, amount)
  ) {
    return null;
  }

  /**
   * Formatting the amount coming from the API from raw number to currency formats i.e. including commas and dots. eg: 1,000,000.000
   */
  const formattedAmount = formatCurrency(amount, decimalRange);

  return (
    <>
      {currencyMap[currencyCode]}&nbsp;{formattedAmount}
    </>
  );
}

currencywithSymbol.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decimalRange: PropTypes.number
};

currencywithSymbol.defaultProps = {
  decimalRange: 2
};
