import React from 'react';
import PropTypes from 'prop-types';
import LifeTemplate from '../productTemplates/LifeTemplate';
import GITemplate from '../productTemplates/GITemplate';
import Context from '../../app/Context';

const ProductCards = props => {
  const {
    productFieldsObject,
    uiTemplate,
    productCategory,
    superCategory,
    encodePolicyNumber,
    policyOnHold,
    notificationMessages,
    eWelcomePack
  } = props;
  /**
   * productFieldsObject - An object received as props which contains all the configured fields for the particular product.
   * Format - {fieldName1 : {value : a, prop1 : b, prop2 : c}}
   *
   * uiTemplate - type of ui template to be chosen for a particular product. E.g - Life, etc
   *
   * Currently returning only LifeTemplate as other GI product templates are not yet configured.
   */
  switch (uiTemplate) {
    case 'life':
      return (
        <Context.Consumer>
          {context => (
            <LifeTemplate
              eWelcomePack={eWelcomePack}
              productFieldsObject={productFieldsObject}
              superCategory={superCategory}
              productCategory={productCategory}
              encodePolicyNumber={encodePolicyNumber}
              colorCodesForPolicyStatus={context.colorCodesForPolicyStatus}
              notificationMessages={notificationMessages}
              policyOnHold={policyOnHold}
            />
          )}
        </Context.Consumer>
      );
    case 'gi':
      return (
        <Context.Consumer>
          {context => (
            <GITemplate
              eWelcomePack={eWelcomePack}
              productFieldsObject={productFieldsObject}
              superCategory={superCategory}
              productCategory={productCategory}
              encodePolicyNumber={encodePolicyNumber}
              policyOnHold={policyOnHold}
              colorCodesForPolicyStatus={context.colorCodesForPolicyStatus}
              notificationMessages={notificationMessages}
            />
          )}
        </Context.Consumer>
      );
    default:
      return '';
  }
};

ProductCards.propTypes = {
  productFieldsObject: PropTypes.object.isRequired,
  superCategory: PropTypes.string.isRequired,
  productCategory: PropTypes.string.isRequired,
  uiTemplate: PropTypes.string.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  eWelcomePack: PropTypes.object.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyOnHold: PropTypes.bool.isRequired
};

export default ProductCards;
