const getPolicySectionOrder = function getPolicySectionOrder(
  dashboardCategory,
  sectionConfig
) {
  return (
    (dashboardCategory && sectionConfig[dashboardCategory]) ||
    sectionConfig.DEFAULT
  );
};

const checkPolicyStatus = function checkPolicyStatus(policyStatus = '') {
  return policyStatus && policyStatus === 'LA';
};

const policySectionUtils = {
  getPolicySectionOrder,
  checkPolicyStatus
};

export default policySectionUtils;
