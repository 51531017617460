import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import { PaymentInfoFieldWrapper, TextLabel } from '../../detailPageStyle';
import DateComponent from '../../../common/date/Date';
import { getFieldDataObj } from '../../../utils/policyUtils';

const ChildCessationDateField = function ChildCessationDateField(
  props,
  context
) {
  const {
    intl: { locale, formatMessage }
  } = context;
  const {
    fieldLabel,
    fieldData,
    fieldName,
    dependentField,
    dependentContent
  } = props;

  let hasIndicator = false;
  let dependentFieldData;
  let fieldValue;

  if (fieldData && fieldData.length) {
    const fieldDataObj = getFieldDataObj(fieldData, fieldName);
    if (!_.isEmpty(fieldDataObj)) {
      fieldValue = fieldDataObj[`${fieldName}`];
      dependentFieldData = fieldDataObj[`${dependentField}`];
    }
  }

  if (!fieldLabel || (!fieldValue && !dependentFieldData)) {
    return null;
  }
  if (dependentFieldData) {
    hasIndicator = true;
  }
  return (
    <PaymentInfoFieldWrapper id={fieldLabel}>
      <LabelWrapper {...props} labelContent={fieldLabel} />
      {hasIndicator && dependentContent ? (
        <TextLabel>{formatMessage({ id: dependentContent })}</TextLabel>
      ) : (
        <TextLabel>
          <DateComponent
            date={fieldValue}
            locale={locale}
            apiDateFormat="YYYY-MM-DD"
            id="standaradDateFormat"
          />
        </TextLabel>
      )}
    </PaymentInfoFieldWrapper>
  );
};

ChildCessationDateField.propTypes = {
  id: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldData: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  dependentField: PropTypes.string,
  dependentContent: PropTypes.string
};

ChildCessationDateField.defaultProps = {
  dependentField: '',
  dependentContent: ''
};

ChildCessationDateField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ChildCessationDateField;
