import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BackIcon from '../assets/images/cevrron-right.svg';
import titleHandler from '../utils/titleUtils';
import {
  DetailsNotification,
  NotificationRow,
  LeftNotificationRow,
  NotificationBorder,
  BackButton,
  ButtonWrapperOL,
  ListLI,
  ImageSpan,
  ImagePath
} from '../policydetail/detailPageStyle';
import Context from '../app/Context';
import { trackEvents } from '../utils/tealiumUtils';

const ReprojectionNotification = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { encodePolicyNumber, reprojectionTealium } = props;
  const title = formatMessage({ id: 'reprojection_page_title' });
  titleHandler(title);

  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;

        return (
          <>
            <DetailsNotification>
              <NotificationRow>
                <NotificationBorder>
                  <LeftNotificationRow>
                    <ButtonWrapperOL>
                      <ListLI>
                        <Link
                          to={forLink.summary}
                          id="my_insurance_back_link"
                          onClick={() =>
                            trackEvents({
                              eventName: 'myInsurance',
                              reprojectionTealium
                            })
                          }
                        >
                          <BackButton
                            aria-label={formatMessage({
                              id: 'BackMyInsuranceAriaText'
                            })}
                          >
                            <span>
                              {formatMessage({ id: 'BackMyInsurance' })}
                            </span>
                            <ImageSpan>
                              <ImagePath src={BackIcon} alt="" />
                            </ImageSpan>
                          </BackButton>
                        </Link>
                      </ListLI>
                      <ListLI>
                        <Link
                          to={`${forLink.details}${encodePolicyNumber}`}
                          id="policy_details_back_link"
                          onClick={() =>
                            trackEvents({
                              eventName: 'policyDetails',
                              reprojectionTealium
                            })
                          }
                        >
                          <BackButton
                            aria-label={formatMessage({
                              id: 'BackPolicyDetailsAriaText'
                            })}
                          >
                            <span>
                              {formatMessage({ id: 'BackPolicyDetails' })}
                            </span>
                            <ImageSpan>
                              <ImagePath src={BackIcon} alt="" />
                            </ImageSpan>
                          </BackButton>
                        </Link>
                      </ListLI>
                      <ListLI currentPage>
                        <span aria-current="page">
                          {formatMessage({ id: 'FutureValueProjectionLabel' })}
                        </span>
                      </ListLI>
                    </ButtonWrapperOL>
                  </LeftNotificationRow>
                </NotificationBorder>
              </NotificationRow>
            </DetailsNotification>
          </>
        );
      }}
    </Context.Consumer>
  );
};

ReprojectionNotification.contextTypes = {
  intl: PropTypes.object.isRequired,
  applicationUrl: PropTypes.object
};

ReprojectionNotification.propTypes = {
  reprojectionTealium: PropTypes.object.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired
};

export default ReprojectionNotification;
