/**


 * @param {Array} memberList  - Array of members

 * @description - Processing members list and filtering out members with no member names
 *  and additional information like relationship, membership number etc

 */

export const sanitizingMemberList = function sanitizingMemberList(memberList) {
  const finalMemberArray = [];
  memberList.forEach(member => {
    Object.keys(member).forEach(memberField => {
      if (
        member[memberField].props.isMandatoryToShow &&
        member[memberField].props.fieldData &&
        member[memberField].props.fieldData !== ''
      ) {
        finalMemberArray.push(member);
      }
    });
  });
  return finalMemberArray;
};

export const checkMemberData = function checkMemberData(finalMemberArray = []) {
  let dataFound = false;
  if (finalMemberArray.length) {
    finalMemberArray.forEach(member => {
      if (!dataFound) {
        dataFound = Object.keys(member).some(memberField => {
          if (!member[memberField].props.isMandatoryToShow) {
            const { fieldData } = member[memberField].props;
            return (
              (typeof fieldData === 'string' && fieldData !== '') ||
              (typeof fieldData === 'object' &&
                fieldData &&
                fieldData.narrative &&
                fieldData.narrative.length !== 0)
            );
          }
          return null;
        });
      }
    });
  }
  return (dataFound && finalMemberArray) || [];
};

export const getInstanceComponentProps = function getInstanceComponentProps(
  sectionField,
  fieldDataInstance
) {
  const {
    fieldName,
    dependentField,
    dependentFieldTwo,
    fieldType,
    fieldLabel
  } = sectionField;
  const arrayFieldData = fieldDataInstance[fieldName];

  let dependentFieldData;
  let dependentFieldDataTwo;
  let tableFieldType;
  let labelContents;
  if (dependentField) {
    dependentFieldData = fieldDataInstance[dependentField];
  }
  if (dependentFieldTwo) {
    dependentFieldDataTwo = fieldDataInstance[dependentFieldTwo];
  }
  if (fieldType) {
    tableFieldType = fieldType;
  }
  if (fieldLabel) {
    labelContents = fieldLabel;
  }
  return {
    fieldData: arrayFieldData,
    dependentField: dependentFieldData,
    dependentFieldTwo: dependentFieldDataTwo,
    fieldType: tableFieldType,
    labelContent: labelContents
  };
};
