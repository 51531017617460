const fireCelebrus = function fireCelebrus(pageName) {
  if (window.HSBCHKDEVdoReInit) {
    window.HSBCHKDEVPageID = pageName;
    window.HSBCHKDEVdoReInit();
  } else if (window.HSBCHKUATdoReInit) {
    window.HSBCHKUATPageID = pageName;
    window.HSBCHKUATdoReInit();
  } else if (window.HSBCHKdoReInit) {
    window.HSBCHKPageID = pageName;
    window.HSBCHKdoReInit();
  }
};

export default fireCelebrus;
