import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { PrimaryButton } from 'ui-component-library';
import Button from 'ui-component-library/lib/button/Button';
import { em, rem } from 'polished';
import theme from '../../../common/styles/theme';
import media from '../../../utils/media';
import { FontFamily, fontSize5 } from '../../../utils/font';

export const slideIn = keyframes`
from {left: -720px;}
to {left:  0;}
`;

export const slideOut = keyframes`
from {left: 0px;}
to {left:-720px;}
`;
export const GlobalBodyCSS = createGlobalStyle`
body {
  overflow: ${props => (props.hideOverFlow ? 'hidden' : 'auto')};
}
`;
export const ParentWrapper = styled.div`
  display: none;
  ${media.phoneToMiniTablet`
display:block;
padding : ${rem('22px')} ${rem('20px')};
`};
`;
export const MenuButton = Button.extend`
  fontsize: ${em(20)};
  padding: 0;
  &:focus {
    outline: ${rem('1px')} dotted;
  }
`;

export const LOGO = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const HiddenDiv = styled.div`
  display: ${props => (props.showMenu ? 'block' : 'none')};
`;

export const BurgerMenuWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  background: ${theme.whiteColor};
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: ${rem('2px')} solid #d7d8d6;
  overflow: auto;
  animation: ${props => (props.slide === 'in' ? slideIn : slideOut)} 1s ease 0s
    forwards;
`;

export const CloseIconContainer = styled.div`
  text-align: right;
  padding: ${rem('18px')} ${rem('20px')} ${rem('20px')} ${rem('0px')};
  ${props =>
    props.isSubMenuOpen &&
    `
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
  &:focus {
    text-decoration: underline;
  }
  button {
    padding: 0;
    font-size: ${rem('16px')};
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListElement = styled.li`
  border-top: ${rem('1px')} solid rgb(215, 216, 214);
  position: relative;
  ${fontSize5}
  button {
    ${fontSize5}
  }
  a {
    padding: 14px 14px 14px 18px;
    text-decoration: none;
    color: ${theme.fontColor};
    ${FontFamily.RegularFontW01}
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${fontSize5}
    width:100%;
    box-sizing: border-box;
    &:hover,
    &:focus {
      text-decoration: underline;
      color: ${theme.fontColor};
    }
  }
  &:last-child {
    border-bottom: ${rem('1px')} solid rgb(215, 216, 214);
  }
  ${props => props.noBorder && `border : none;`}
  ${props =>
    props.greyBackgroundColor &&
    `
  background: ${theme.languageButtonBG}; 
  border-top:  1px solid ${theme.languageButtonBG};
  color: ${theme.lightGreyColor};
  &:last-child {
    border-bottom:  1px solid ${theme.languageButtonBG};
  }
  button{
    color: ${theme.lightGreyColor};
  }
  `}
`;

export const CountryLabel = styled.div`
  padding: 14px 14px 14px 18px;
  img {
    margin-top: ${rem('2px')};
  }
  span {
    display: inline-block;
    font-size: ${rem(16)};
    box-sizing: border-box;
    padding: 3px 0;
  }
`;

export const LogoffBtn = PrimaryButton.extend`
  width: 100%;
  text-align: left;
  padding: 14px 14px 14px 18px;
  background: ${theme.greyButton};
  color: ${theme.whiteColor};
`;
export const WhiteButton = Button.extend`
  padding: 14px 14px 14px 18px;
  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }
  width: ${props => (props.autoWidth ? 'auto' : '100%')};
  text-align: ${props => (props.right ? 'right' : 'left')};
  ${props =>
    props.subMenuButton &&
    `
  display: flex;
  align-items: center;
  justify-content: space-between;`}
`;
export const AgreeIcon = css`
  right: ${rem('15px')};
  font-size: ${rem(25)} !important;
  text-decoration: none !important;
`;
export const HiddenButton = styled.button`
  width: 0;
  height: 0;
  border: none;
  background: ${theme.whiteColor};
`;

export const ListHeaderLI = styled.span`
  padding: 14px 14px 14px 18px;
  text-decoration: none;
  color: ${theme.fontColor};
  ${FontFamily.MediumFontW01}
  box-sizing: border-box;
  display: block;
`;

export const ListHeaderButton = styled.button`
  ${FontFamily.RegularFontW01}
  ${FontFamily.RegularFontW01};
  background: none;
  -webkit-appearance: none;
  border: 0;
  ${fontSize5}
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  color: ${theme.fontColor};
  span {
    padding: 0 0 0 14px;
  }
`;

export const BackToIconImg = styled.img`
  padding: ${props => (props.selectedIcon ? '0 14px 0 0' : '0 0 0 14px')};
`;

export const SpanText = styled.span`
  display: flex;
  align-items: center;
`;
