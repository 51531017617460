import React from 'react'
import PropTypes from 'prop-types';
import { ProgressBarDiv, ColouredProgressDiv, TransparentDiv, EmptyProgressDiv } from './ProgressBarStyles'
import { progressBarWidthUtil } from './chartUtils'

const ProgressBar = (props) => {
  const {
    currentProgress
  } = props;
  return (
    <>
      <ProgressBarDiv>
        <ColouredProgressDiv width={currentProgress} />
        <EmptyProgressDiv showWidth={progressBarWidthUtil(currentProgress)} />
        <TransparentDiv width={100 - currentProgress} />
      </ProgressBarDiv>
    </>
  )
}
ProgressBar.propTypes = {

  currentProgress: PropTypes.number.isRequired
};

export default ProgressBar