const lifeHeaderTitle = 'policyHeaderLife';
const generalEnquiry = 'general enquiry';
const giHeaderTitle = 'policyHeaderGI';
const emergencyHotline = 'emergency hotline';
const policyApplyContactNumber = 'policyApplyPlans_Contact_Number';

export const ContactList = [
  {
    listHeader: 'policyGeneralInfo',
    singlePolicy: false,
    policyContactLabel: 'policyHotline_Contact_label',
    policyContactNumber: 'policyHotline_Contact_Number',
    numberLabel: policyApplyContactNumber,
    policyContactDetails: 'policyHotline_Contact_Details',
    policyTypes: [
      {
        title: lifeHeaderTitle,
        typeOfCall: generalEnquiry,
        contact: [
          {
            label: 'policyGeneralInfo_Life_Contact_label',
            number: 'policyGeneralInfo_Life_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyGeneralInfo_Life_Contact_Details'
          },
          {
            label: 'policyGeneralInfo_Voluntary_Health_label',
            number: 'policyGeneralInfo_Voluntary_Health_Number',
            numberLabel: 'policyApplyPlans_Contact_Number',
            details: 'policyGeneralInfo_Voluntary_Health_Details'
          }
        ]
      },
      {
        title: giHeaderTitle,
        typeOfCall: emergencyHotline,
        contact: [
          {
            label: 'policyGeneralInfo_GI_Contact_label',
            number: 'policyGeneralInfo_GI_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyGeneralInfo_GI_Contact_Details'
          }
        ]
      }
    ]
  },
  {
    listHeader: 'policyMakingClaims',
    singlePolicy: false,
    policyTypes: [
      {
        title: lifeHeaderTitle,
        typeOfCall: generalEnquiry,
        contact: [
          {
            label: 'policyMakingClaims_Life_Contact_label',
            number: 'policyMakingClaims_Life_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyMakingClaims_Life_Contact_Details'
          },
          {
            label: 'policyMakingClaims_Voluntary_Health_label',
            number: 'policyMakingClaims_Voluntary_Health_Number',
            numberLabel: 'policyApplyPlans_Contact_Number',
            details: 'policyMakingClaims_Voluntary_Health_Details'
          }
        ]
      },
      {
        title: giHeaderTitle,
        typeOfCall: emergencyHotline,
        contact: [
          {
            label: 'policyMakingClaims_GI_Medical_Contact_label',
            number: 'policyMakingClaims_GI_Medical_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyMakingClaims_GI_Medical_Contact_Details',
            hideBottomSpacing: true
          },
          {
            label: 'policyMakingClaims_GI_Medical_Contact_Details_1'
          }
        ]
      }
    ]
  },
  {
    listHeader: 'policyApplyPlans',
    singlePolicy: false,
    policyTypes: [
      {
        title: lifeHeaderTitle,
        typeOfCall: emergencyHotline,
        contact: [
          {
            label: 'policyApplyPlans_Life_Contact_label',
            number: 'policyApplyPlans_Life_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyApplyPlans_Life_Contact_Details'
          },
          {
            label: 'policyApplyPlans_Life_Others_Contact_label',
            number: 'policyApplyPlans_Life_Others_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyApplyPlans_Life_Others_Contact_Details'
          }
        ]
      },
      {
        title: giHeaderTitle,
        typeOfCall: emergencyHotline,
        contact: [
          {
            label: 'policyApplyPlans_GI_Contact_label',
            number: 'policyApplyPlans_GI_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyApplyPlans_GI_Contact_Details'
          }
        ]
      }
    ]
  },
  {
    listHeader: 'policyEmergency',
    singlePolicy: false,
    policyTypes: [
      {
        title: 'policyHeaderLife',
        typeOfCall: emergencyHotline,
        contact: [
          {
            label: 'policyEmergency_Voluntary_Health_Contact_label',
            hideBottomSpacing: true
          },
          {
            label: 'policyEmergency_LI_Medical_Contact_label',
            number: 'policyEmergency_LI_Medical_Contact_Number',
            numberLabel: 'policyApplyPlans_Contact_Number',
            details: 'policyEmergency_Voluntary_Health_Contact_details'
          }
        ]
      },
      {
        title: 'policyHeaderGI',
        typeOfCall: emergencyHotline,
        contact: [
          {
            label: 'policyEmergency_GI_Medical_Contact_label',
            number: 'policyEmergency_GI_Medical_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyEmergency_GI_Medical_Contact_Details'
          },
          {
            label: 'policyEmergency_GI_Contact_label',
            number: 'policyEmergency_GI_Contact_Number',
            numberLabel: policyApplyContactNumber,
            details: 'policyEmergency_GI_Contact_Details',
            detailsClass: 'policyEmergency'
          }
        ]
      }
    ]
  }
];

export default ContactList;
