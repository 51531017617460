import { createAction } from 'redux-actions';
import {
  COMPLETE_APP_BOOTSTRAP,
  START_LOADER,
  END_LOADER,
  CHANGE_LOCALE,
  GET_CONTENT,
  ADD_LANG_DATA,
  SET_SAAS_TOKEN,
  MERGE_PREVIEW_CONTENT,
  GET_EXTERNAL_CONFIGURATIONS,
  SET_EXTERNAL_CONFIGURATION,
  INIT_LOGGER,
  LOGGER_READY,
  APP_BOOTSTRAP,
  SERVICE_CONFIGS_STUBBING,
  REFRESH_SAAS_TOKEN,
  SET_CUSTOMER,
  SET_POLICY_SUMMARY,
  GET_POLICY,
  SET_POLICY,
  SET_ERRORS_WARNINGS,
  SET_IS_STUBBED,
  SET_POLICY_CHECK_VALUE,
  SET_POLICY_STATE,
  SET_ANCHORING_TO_POLICIES,
  SET_IDLE_FLAG,
  SET_PAGE_SAAS_FAIL,
  SET_PAGE_CONTACTUS_POP,
  SET_SELECT_TAB,
  GET_REPROJECTIONS,
  SET_REPROJECTIONS,
  SET_REPROJECTIONS_ERRORS_WARNINGS,
  SET_REPROJECTIONS_AGE_SELECTION,
  SET_REPROJECTIONS_MIN_AGE,
  SET_REPROJECTIONS_MAX_AGE,
  SET_INCLUDE_NON_GUARANTEED_PAYOUT,
  TOGGLE_INCLUDE_NON_GUARANTEED_PAYOUT,
  SET_REPROJECTIONS_SELECTED_TAB,
  RESET_REPROJECTIONS_ERRORS_WARNINGS,
  SET_POLICY_DOCUMENTS_DETAILS,
  GET_POLICY_DOCUMENTS_DETAILS,
  SET_DOCUMENTS_ERRORS_WARNINGS,
  RESET_DOCUMENTS_ERRORS_WARNINGS,
  RESET_DOCUMENTS_DETAILS,
  E_WELCOME_PACK_ACK,
  E_WELCOME_DOCUMENT_RETRIEVE,
  E_WELCOME_GET_DOCUMENTS,
  SET_E_WELCOME_DOCUMENT_RETRIEVE,
  SET_E_WELCOME_GET_DOCUMENTS,
  E_WELCOME_RESET_DOCUMENTS_ERRORS_WARNINGS,
  SET_E_WELCOME_DOCUMENTS_ERRORS_WARNINGS,
  E_WELCOME_START_LOADER,
  E_WELCOME_END_LOADER,
  POLICY_DETAILS_START_LOADER,
  E_WELCOME_DOWNLOAD_ALL,
  SET_E_WELCOME_DOWNLOAD_ALL_DETAILS,
  SET_INSTRUCTION_HISTORY,
  GET_INSTRUCTION_DETAIL,
  GET_CORE_FUND_DETAILS,
  SET_CORE_FUND_DETAILS,
  SET_FUND_SELECTION_ERRORS_WARNINGS,
  CANCEL_REQUEST,
  CANCEL_REQUEST_SUCCESS,
  CANCEL_REQUEST_ERRORS_WARNINGS,
  SET_INSTRUCTION_HISTORY_ERRORS_WARNINGS,
  UPDATE_LOADER_OPACITY,
  SET_INSTRUCTION_HISTORY_DETAIL_ERROR,
  RESET_CANCER_ERROR_STORE,
  SET_GA_ELIGIBLE,
  SUBMIT_ESTATEMENT_PREFERENCE,
  SET_ESTATEMENT_PREFERENCE_ACK_DETAILS,
  SET_ESTATEMENT_PREFERENCE_WARNINGS_OR_ERRORS,
  SAVE_PAPER_STATEMENT_PREFERENCE,
  SET_VIH_LINK_STATEMENT_PREFERENCE,
  REMOVE_POLICY_DETAIL
} from '../actionTypes';

export const setAppBootstapAsComplete = createAction(COMPLETE_APP_BOOTSTRAP);

export const changeLocale = createAction(CHANGE_LOCALE);

export const getContent = createAction(GET_CONTENT);

export const addLanguageData = createAction(ADD_LANG_DATA);

export const setSAASToken = createAction(SET_SAAS_TOKEN);

export const setIsStubbed = createAction(SET_IS_STUBBED);

export const refreshSAASToken = createAction(REFRESH_SAAS_TOKEN);

export const mergePreviewContent = createAction(MERGE_PREVIEW_CONTENT);

export const getServiceConfiguration = createAction(
  GET_EXTERNAL_CONFIGURATIONS
);

export const setExternalConfiguration = createAction(
  SET_EXTERNAL_CONFIGURATION
);

export const bootstrapApplication = createAction(APP_BOOTSTRAP);

export const initLogger = createAction(INIT_LOGGER);

export const loggerReady = createAction(LOGGER_READY);
export const stubServiceConfig = createAction(SERVICE_CONFIGS_STUBBING);

export const startLoader = createAction(START_LOADER);

export const endLoader = createAction(END_LOADER);

export const setCustomerInfo = createAction(SET_CUSTOMER);

export const getPolicyInfo = createAction(GET_POLICY);

export const setPolicyInfo = createAction(SET_POLICY);

export const setPolicySummary = createAction(SET_POLICY_SUMMARY);

export const setWarningsOrErrors = createAction(SET_ERRORS_WARNINGS);
export const setPolicyCheckValue = createAction(SET_POLICY_CHECK_VALUE);
export const setPolicyState = createAction(SET_POLICY_STATE);
export const setPolicyAnchoring = createAction(SET_ANCHORING_TO_POLICIES);
export const updateIdleFlag = createAction(SET_IDLE_FLAG);
export const updateSAASPageFail = createAction(SET_PAGE_SAAS_FAIL);
export const setContactUsPopup = createAction(SET_PAGE_CONTACTUS_POP);
export const setSelectedTab = createAction(SET_SELECT_TAB);

// Reprojection app actions
export const getReprojectionsInfo = createAction(GET_REPROJECTIONS);
export const setReprojectionsInfo = createAction(SET_REPROJECTIONS);
export const setReprojectionsWarningsOrErrors = createAction(
  SET_REPROJECTIONS_ERRORS_WARNINGS
);
export const setReprojectionsAgeSelection = createAction(
  SET_REPROJECTIONS_AGE_SELECTION
);

export const setReprojectionsMinAge = createAction(SET_REPROJECTIONS_MIN_AGE);

export const setReprojectionsMaxAge = createAction(SET_REPROJECTIONS_MAX_AGE);

export const setIncludeNonGuaranteedPayout = createAction(
  SET_INCLUDE_NON_GUARANTEED_PAYOUT
);

export const toggleIncludeNonGuaranteedPayout = createAction(
  TOGGLE_INCLUDE_NON_GUARANTEED_PAYOUT
);

export const setReprojectionsSelectedTab = createAction(
  SET_REPROJECTIONS_SELECTED_TAB
);
export const resetReprojectionsErrorsWarnings = createAction(
  RESET_REPROJECTIONS_ERRORS_WARNINGS
);

export const setPolicyDocumentsDetails = createAction(
  SET_POLICY_DOCUMENTS_DETAILS
);

export const getPolicyDocumentsDetails = createAction(
  GET_POLICY_DOCUMENTS_DETAILS
);

export const setDocumentsWarningsOrErrors = createAction(
  SET_DOCUMENTS_ERRORS_WARNINGS
);

export const resetDocumentsErrorsWarnings = createAction(
  RESET_DOCUMENTS_ERRORS_WARNINGS
);

export const resetDocumentDetails = createAction(RESET_DOCUMENTS_DETAILS);

export const eWelcomePackAck = createAction(E_WELCOME_PACK_ACK);

export const retrievePolicyDocument = createAction(E_WELCOME_DOCUMENT_RETRIEVE);

export const getEWelcomeDocuments = createAction(E_WELCOME_GET_DOCUMENTS);

export const setEWelcomeDownloadPolicyDocumentDetails = createAction(
  SET_E_WELCOME_DOCUMENT_RETRIEVE
);

export const setEWelcomePolicyDocumentsDetails = createAction(
  SET_E_WELCOME_GET_DOCUMENTS
);

export const eWelcomeResetDocumentsErrorsWarnings = createAction(
  E_WELCOME_RESET_DOCUMENTS_ERRORS_WARNINGS
);

export const setEWelcomeDocumentsErrorsWarnings = createAction(
  SET_E_WELCOME_DOCUMENTS_ERRORS_WARNINGS
);

export const startLoaderEWelcome = createAction(E_WELCOME_START_LOADER);

export const endLoaderEWelcome = createAction(E_WELCOME_END_LOADER);

export const startPolicyDetailsLoader = createAction(
  POLICY_DETAILS_START_LOADER
);

export const eWelcomeDownloadAll = createAction(E_WELCOME_DOWNLOAD_ALL);

export const setEWelcomeDownloadAllDetails = createAction(
  SET_E_WELCOME_DOWNLOAD_ALL_DETAILS
);
export const setInstructionHistory = createAction(SET_INSTRUCTION_HISTORY);
export const getInstructionDetails = createAction(GET_INSTRUCTION_DETAIL);
export const cancelRequest = createAction(CANCEL_REQUEST);
export const setCancelRequestSuccess = createAction(CANCEL_REQUEST_SUCCESS);
export const setCancelRequestErrorsorWarnings = createAction(
  CANCEL_REQUEST_ERRORS_WARNINGS
);

export const setInstructionHistoryErrorOrWarnings = createAction(
  SET_INSTRUCTION_HISTORY_ERRORS_WARNINGS
);
export const getCoreFundDetails = createAction(GET_CORE_FUND_DETAILS);
export const setCoreFundDetails = createAction(SET_CORE_FUND_DETAILS);
export const setFundSelectionErrorsWarnings = createAction(
  SET_FUND_SELECTION_ERRORS_WARNINGS
);

export const setInstructionHistoryDetailErrors = createAction(
  SET_INSTRUCTION_HISTORY_DETAIL_ERROR
);
export const updateLoaderOpacity = createAction(UPDATE_LOADER_OPACITY);
export const resetCancelErrorStore = createAction(RESET_CANCER_ERROR_STORE);
export const setEligibilityInfo = createAction(SET_GA_ELIGIBLE);

export const submitEstatementPreference = createAction(
  SUBMIT_ESTATEMENT_PREFERENCE
);
export const setEstatementPrefAckDetails = createAction(
  SET_ESTATEMENT_PREFERENCE_ACK_DETAILS
);
export const setEstatementPrefWarningsOrErrors = createAction(
  SET_ESTATEMENT_PREFERENCE_WARNINGS_OR_ERRORS
);
export const savePaperStatementPreference = createAction(
  SAVE_PAPER_STATEMENT_PREFERENCE
);

export const setVIHLinkOnStatementPreference = createAction(
  SET_VIH_LINK_STATEMENT_PREFERENCE
);

export const removeOldPolicyDetail = createAction(REMOVE_POLICY_DETAIL);
