import React from 'react';
import PropTypes from 'prop-types';
import yesIcon from '../../../assets/images/yes.svg';
import getDescription from '../../../utils/reducerUtils';
import BasicPlanOptionLayout from './BasicPlanOptionLayout';

const BasicPlanOptionNarrative = (props, context) => {
  const {
    intl,
    intl: { formatMessage, locale }
  } = context;

  const { fieldData, dependentContentData, labelContent } = props;
  let contentDescription = '';
  const contentKey =
    fieldData && fieldData.key && `${labelContent}_${fieldData.key}`;

  if (
    contentKey &&
    !!intl.messages[contentKey] &&
    intl.messages[contentKey] !== ''
  ) {
    contentDescription = formatMessage({ id: contentKey });
  } else {
    contentDescription =
      fieldData && getDescription(locale, fieldData.narrative);
  }
  if (!contentDescription) {
    return <></>;
  }
  return (
    <BasicPlanOptionLayout
      id={labelContent}
      srcIcon={yesIcon}
      altText="yes-icon"
      dependentContentData={dependentContentData}
      contentDescription={contentDescription}
      formatMessage={formatMessage}
    />
  );
};

BasicPlanOptionNarrative.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string,
  dependentContentData: PropTypes.string
};
BasicPlanOptionNarrative.defaultProps = {
  fieldData: ''
};
BasicPlanOptionNarrative.defaultProps = {
  dependentContentData: ''
};
BasicPlanOptionNarrative.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BasicPlanOptionNarrative;
