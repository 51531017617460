import React from 'react';
import PropTypes from 'prop-types';
import {
  FundName,
  FundAllocation,
  FundNameSection,
  SwitchOutName
} from '../instructionHistoryStyle';

const ServicingKeyValue = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { label, fieldValue, removeLeftSpacing, individualFund } = props;

  if (!fieldValue) {
    return null;
  }

  return (
    <FundNameSection>
      {individualFund ? (
        <SwitchOutName removeLeftSpacing={removeLeftSpacing}>
          {formatMessage({ id: label })}
        </SwitchOutName>
      ) : (
        <FundName removeLeftSpacing={removeLeftSpacing}>
          {formatMessage({ id: label })}
        </FundName>
      )}
      <FundAllocation removeLeftSpacing={removeLeftSpacing}>
        {fieldValue}
      </FundAllocation>
    </FundNameSection>
  );
};

ServicingKeyValue.propTypes = {
  label: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  removeLeftSpacing: PropTypes.bool,
  individualFund: PropTypes.bool
};

ServicingKeyValue.defaultProps = {
  fieldValue: '',
  removeLeftSpacing: false,
  individualFund: false
};

ServicingKeyValue.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ServicingKeyValue;
