import React from 'react';
import PropTypes from 'prop-types';
import { ServiceDownHeading } from './appServiceErrorPageStyle';
import {
  Section,
  NotificationMessageType,
  NotificationMessageIcon,
  NotificationIconSpan,
  ImagePath,
  NotificationMessage
} from '../../../common/styles/commonStyle';
import theme from '../../../common/styles/theme';
import { ErrorIcon } from '../../../assets/images/NotificationIcons';

const AppServiceErrorPage = function AppServiceErrorPage(props, context) {
  const {
    intl: { formatMessage }
  } = context;

  return (
    <>
      <Section>
        <ServiceDownHeading>
          {formatMessage({
            id: 'error_API_Fail'
          })}
        </ServiceDownHeading>
        <NotificationMessageType
          bgColor={theme.errorNotificationBG}
          borderColor={theme.primaryActive}
        >
          <NotificationMessageIcon>
            <NotificationIconSpan>
              <ImagePath aria-hidden="true" alt="" src={ErrorIcon} />
            </NotificationIconSpan>
            <NotificationMessage>
              <span>
                {formatMessage({
                  id: 'error_API_Fail_Message'
                })}
              </span>
            </NotificationMessage>
          </NotificationMessageIcon>
        </NotificationMessageType>
      </Section>
    </>
  );
};

export default AppServiceErrorPage;

AppServiceErrorPage.contextTypes = {
  intl: PropTypes.object.isRequired
};
