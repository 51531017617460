import styled, { css } from 'styled-components';
import { rem } from 'polished';
import theme from './theme';
import media from '../../utils/media';
import { FontFamily, FontSize } from '../../utils/font';
import { ParagraphDiv } from './modalStyles';

export const ContinueButton = styled.div`
  float: right;
`;
export const SubHeaderText = styled.p`
  font-size: ${rem('16px')};
  ${FontFamily.RegularFontW01};
  line-height: 1.5;
  padding-bottom: 24px;
`;

export const ListWrapperDiv = styled.div``;

export const ListWrapperUL = styled.ul`
  margin: 0;
  padding: 0;
  ${props =>
    props.isIE &&
    `  width:48em;

  `};
`;

export const ListHeading = styled.h3`
  font-size: ${rem('19px')};
  ${FontFamily.LightFontW01};
  line-height: 1.42;
  padding-bottom: 3px;
`;

export const ListWrapperLI = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 16px;
  padding-bottom: 20px;
  border-bottom: ${props => (props.hideBorder ? ' ' : '1px solid #d7d8d6')};
  ${media.phone`
  flex-direction: column;
  align-items: flex-start;
  `}
`;

export const ListHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  ${props =>
    props.isIE &&
    ` 
       width: 65%;
  `};
  ${media.phone`
  width: 100%;
`}
`;

export const ListHeadingLI = styled.span`
  font-size: ${rem('16px')};
  ${FontFamily.MediumFontW01};
  line-height: 1.5;
`;

export const ListInfoLI = styled.span`
  font-size: ${rem('16px')};
  ${FontFamily.RegularFontW01};
  line-height: 1.5;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 7px;
  padding-top: 5px;
  span span span {
    padding-right: 5px;
  }
  ${media.phone`
    padding-left: 0;
    padding-bottom: 16px;
`}
`;

export const ListLinksWrapper = styled.div`
  a span {
    line-height: 18px;
  }
  ${props =>
    props.isIE &&
    ` 
   width: 30%;
 `};
`;

export const ListLinks = styled.span`
  font-size: ${rem('16px')};
  ${FontFamily.RegularFontW01};
  line-height: 1.5;
`;

const CommonBorderMaker = css`
  border-right: 1px solid ${theme.borderColor};
  border-bottom: 1px solid ${theme.borderColor};
`;

const CommonBackgroundColorTransparent = css`
  background-color: transparent;
`;

export const FundsTableWrapper = styled.div`
  margin-bottom: ${props => (props.planSummaryReviewTable ? 0 : '28px')};
  margin-top: ${props => (props.planSummaryReviewTable ? '28px' : 0)};
`;

export const FundTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border: 1px solid ${theme.borderColor};
`;

export const FundTableCaption = styled.caption`
  text-align: left;
  font-size: ${rem('16px')};
  ${FontFamily.LightFontW01};
  line-height: 1.5;
  padding-bottom: 16px;
`;

const CommonBackgroundColorForTable = css`
  background-color: ${theme.stepDefault};
`;

export const FundTableHeader = styled.thead`
  ${CommonBackgroundColorForTable};
  ${FontFamily.RegularFontW01};
`;

export const FundTableRow = styled.tr`
  &:last-child > td {
    border-bottom: 0;
  }
`;

export const FundTableTH = styled.th`
  border-spacing: 0;
  padding: 12px;
  vertical-align: baseline;
  text-align: left;
  width: ${props => props.colWidth.colWidthForDeskTop}%};
  word-break: break-word;
  font-weight: 500;
  ${CommonBorderMaker};
  ${FontSize.size6};
  cursor: ${props => (props.focusHeader ? 'pointer' : 'default')};
  &:last-child {
    border-right: 0;
  }
  ${props =>
    props.focusHeader &&
    `
    &:focus{
      outline: 1px solid black;
    }
  `}
  display: table-cell;
  ${media.tablet`
    width: ${props => props.colWidth.colWidthForTablet}%};
    &:last-child {
      border-right: 0;
    }
  `}
  ${media.mediumTablet`
    width: ${props =>
      props.hasWidthForMediumTab
        ? `${props.colWidth.colWidthForMediumTablet}%`
        : `${props.colWidth.colWidthForTablet}%`};
  `}
`;

export const FundTableBody = styled.tbody``;

export const FundTableCell = styled.td`
  ${props =>
    props.isIE &&
    ` 
    max-width: 1px;
  `};
  border-spacing: 0;
  vertical-align: ${props => (props.isTopAlign ? 'baseline' : 'middle')};
  ${CommonBorderMaker};
  padding: 10px;
  ${FontSize.size6};
  text-align: ${props => (props.isTransferFundsJourney ? 'center' : 'left')};
  &:first-child {
    .pb-Checkbox__Checkbox {
      justify-content: center;
    }
    ${props =>
      props.planSummaryReviewCell
        ? `${CommonBackgroundColorTransparent};`
        : `${CommonBackgroundColorForTable};`};
  }
  &:nth-child(2) {
    ${props =>
      props.noBackground ? '#fff' : `${CommonBackgroundColorForTable}`};
  }
  &:last-child {
    border-right: 0;
  }
  ${media.tablet`
    display: ${props => (props.isHideOnTab ? 'none' : 'table-cell')};
  `}
`;

export const LinkImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnNameSpan = styled.span`
  width: calc(100% - 10px);
`;

export const RiskLevelWrapper = styled.div`
  display: table-cell;
  padding: 0;
  ${media.phone`
    display: flex;
    padding-left: ${props => (props.isPlanSummaryReview ? 0 : '17px')};
    padding-top: ${props => (props.isPlanSummaryReview ? '5px' : '10px')};
    ${FontSize.size7};
    ${FontFamily.RegularFontW01};
    align-items: center;
  `}
`;

export const RiskLevelDiv = styled.div`
  width: 100%;
  ${media.phone`
    width: 50%;
  `}
`;

export const RatingIcon = styled(RiskLevelDiv)`
  display: flex;
  text-align: left;
  align-items: ${props => (props.isRPQ ? `center` : `baseline`)};
  margin-bottom: ${rem('6px')};
  ${media.phone`
    text-align: ${props => (props.isRPQ ? `start` : `end`)};
    justify-content: ${props => (props.isRPQ ? `flex-start` : `flex-end`)};
    width: ${props => (props.isSelectedFund ? '100%' : '50%')}
  `}
`;

export const RiskTypeColor = styled.span`
  ${props =>
    props.isRPQ ? `${FontFamily.MediumFontW01}` : `${FontFamily.LightFontW01}`};
  margin-top: ${rem('2px')};
  margin-right: ${rem('8px')};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: ${props => (props.isRPQ ? '30px' : '20px')};
  width: ${props => (props.isRPQ ? '30px' : '20px')};
  background-color: ${props => props.riskTypeColor};
  color: #ffffff;
  font-size: ${props => (props.isRPQ ? '18px' : '12px')};
  line-height: 16px;
  display: flex;
  ${media.phone`
    margin-right: ${props => (props.isRPQ ? '8px' : 0)};
    margin-top: ${props => (props.isRPQ ? '2px' : 0)};
  `}
`;

export const RiskLevel = styled.span`
  ${props =>
    props.isFontBold
      ? `${FontFamily.RegularFontW01}`
      : `${FontFamily.LightFontW01}`};
  ${media.phone`
      padding-right: 4px;
      margin-right: 0;
      margin-left: ${props => (props.isSelectedFund ? '5px' : 0)}
  `}
`;

export const RiskTolerance = styled.div`
  ${FontFamily.LightFontW01};
  margin-top: ${props => (props.isPlanSummaryReview ? '5px' : 0)};

  ${media.phone`
    text-align: ${props => (props.isPlanSummaryReview ? 'left' : 'right')};
    margin-top: 0;
    padding-top: 5px;
    padding-right: 5px;
    span {
      padding-right: 3px;
    }
    ${FontSize.size7};
    }
  `}
`;

export const RiskIconSpan = styled.span`
  margin-left: ${rem('3px')};
  ${media.phone`
     ${FontSize.size6};
  `}
`;

export const FundRemovedIconWrapper = styled.span`
  color: ${theme.gold};
  margin-right: ${rem('8px')};
`;

export const FundNameRemoved = styled.span`
  color: ${theme.fundRemovedColor};
  margin-bottom: ${rem('8px')};
`;

export const FundRemovedParagraph = styled.p`
  padding-bottom: ${rem('30px')};
  display: flex;
  ${media.phone`
    padding-bottom: ${rem('15px')};
  `}
`;

export const FundDetailLinkIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem('5px')};
  ${props =>
    props.isIE &&
    `width: 247px;
  `}
`;

export const FundDetailLink = styled.a`
  ${FontFamily.RegularFontW01};
  margin-right: ${rem('5px')};
  color: ${theme.fontColor};
  text-decoration: none;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  position: relative;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  ${media.phone`
    ${FontSize.size5};
  `}
`;

export const LinkImgSpan = styled.span`
  margin-right: ${props => props.marginRight}px;
  margin-left: ${props => props.marginLeft}px;
  img {
    width: ${props => props.imageWidth}px;
    height: ${props => props.imageHeight}px;
  }
`;

export const FundSelectionWrapper = styled.div`
  width: 100%;
  border: solid 1px #d7d8d6;
  padding: 15px;
  margin-bottom: 13px;
  box-sizing: border-box;
`;

export const FundNameWrapper = styled.div`
  width: calc(100% - 32px);
  ${FontSize.size6};
  ${FontFamily.RegularFontW01};
  color: #333333;
  ${media.phone`
    ${props =>
      props.type &&
      `
      margin-bottom: 17px;
      width:100%;
    `}
  `}
`;

export const FundNameDiv = styled.div`
  display: flex;
`;

export const FundListRow = styled.div`
  margin-bottom: ${props => (props.noMargin ? `0` : `13px`)};
  display: ${props => (props.upcomingAllocation ? 'block' : 'flex')};
`;

export const FundListLabel = styled.div`
  width: 50%;
  ${FontSize.size6};
`;

export const FundListValue = styled.div`
  ${FontFamily.RegularFontW01};
  ${FontSize.size7};
  width: 50%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;

export const RatingIconMobile = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

export const GainIcon = styled.img`
  width: 12px;
  height: 6px;
`;

export const FundHeaderWrapper = styled.div`
  display: flex;
`;

export const ErrorDescription = styled(ParagraphDiv)`
  font-size: 16px;
  line-height: 24px;
`;

export const PendingDealingNotificationWrapper = styled.div`
  background-color: #f8f8f8;
  padding: 30px;
  margin: 30px 0 30px 0;
  display: flex;
  align-items: center;
  ${FontSize.size5};
  ${media.tablet`
    ${FontSize.size6};    
  `}
  ${media.phone`
    margin: 0 15px 15px 15px;
    align-items: flex-start;
  `}
`;

export const PendingDealingNotificationIcon = styled.img`
  height: ${rem('24px')};
  width: ${rem('24px')};
  vertical-align: bottom;
`;

export const PendingDealingNotificationMessage = styled.div`
  margin-left: 8px;
  line-height: 20px;
`;
