import React from 'react';
import PropTypes from 'prop-types';
import {
  NotificationMessageWrapper,
  NotificationMessageIcon,
  NotificationMessageType,
  NotificationMessage,
  NotificationIconSpan,
  ImagePath
} from '../../common/styles/commonStyle';
import * as icons from '../../assets/images/NotificationIcons';
import { trackViews } from '../../utils/tealiumUtils';

function getNotificationMessages(notificationMessages) {
  const notificationComponent = {};
  if (
    notificationMessages &&
    notificationMessages.constructor.name === 'Array'
  ) {
    notificationMessages.forEach((msg, index) => {
      notificationComponent[index] = msg;
    });
    return notificationComponent;
  }
  return {};
}

const NotificationMessages = function NotificationMessageComponent(
  props,
  context
) {
  const {
    intl: { formatMessage }
  } = context;
  const { notificationMessages } = props;
  const notificationComponent = getNotificationMessages(notificationMessages);
  let listOfMessageIcon;
  let notificationMsg;
  const notificationBanners = Object.values(notificationComponent).map(
    function notifications(notificationObject) {
      notificationMsg = '';
      if (
        notificationObject.keys &&
        notificationObject.keys.length !== 0 &&
        notificationObject.isDisplayBanner
      ) {
        notificationMsg = '';
        listOfMessageIcon = notificationObject.keys.map(msg => {
          if (msg.display) {
            notificationMsg = formatMessage({ id: msg.key });
            trackViews({
              viewName: 'onSummaryPageLoadNotificationMsg',
              message: notificationMsg.toLowerCase(),
              messageType: notificationObject.type
            });
            return (
              <NotificationMessageIcon key={msg.key}>
                <NotificationIconSpan>
                  <ImagePath
                    aria-hidden="true"
                    alt=""
                    src={icons[notificationObject.style.icon]}
                  />
                </NotificationIconSpan>
                <NotificationMessage>
                  <span>{notificationMsg}</span>
                </NotificationMessage>
              </NotificationMessageIcon>
            );
          }
          return true;
        });
      }

      if (!notificationMsg) {
        return null;
      }
      return (
        <NotificationMessageType
          key={notificationObject.type}
          bgColor={notificationObject.style.bgColor}
          borderColor={notificationObject.style.borderColor}
        >
          {listOfMessageIcon}
        </NotificationMessageType>
      );
    }
  );

  const isAllNotificationsVisible = notificationBanners.every(
    value => value === null
  );

  return (
    <NotificationMessageWrapper
      isAllNotificationsVisible={isAllNotificationsVisible}
    >
      {notificationBanners}
    </NotificationMessageWrapper>
  );
};

NotificationMessages.contextTypes = {
  intl: PropTypes.object.isRequired
};

NotificationMessages.propTypes = {
  notificationMessages: PropTypes.array.isRequired
};

export default NotificationMessages;
