import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';

const FundCode = function FundCode(props) {
  const { fieldData } = props;

  if (!fieldData) {
    return <React.Fragment />;
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>{fieldData}</ShowIf>
    </>
  );
};

FundCode.propTypes = {
  fieldData: PropTypes.array.isRequired
};

export default FundCode;
