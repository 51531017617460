import React, { useState } from 'react';
import PropTypes from 'prop-types';
import chevronUp from '../assets/images/cevrron-up.svg';
import chevronDown from '../assets/images/cevrron-down.svg';
import {
  ToggleSectionWrapper,
  ToggleTitle,
  ToggleImgWrapper,
  ToggleDescription,
  ImagePath,
  ToggleTitleHeading
} from './toggleHandlerStyle';
import { AccessibleHidden } from '../common/styles/commonStyle';

const ToggleHandler = function ToggleHandler(props, context) {
  const { children, sectionTitleFormat, expandedSectionId, titleId } = props;
  const {
    intl: { formatMessage }
  } = context;

  const [isOpen, toggleAccordion] = useState(false);

  return (
    <ToggleSectionWrapper>
      <ToggleTitleHeading>
        <ToggleTitle
          onClick={() => {
            toggleAccordion(!isOpen);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              toggleAccordion(!isOpen);
            }
          }}
          aria-controls={expandedSectionId}
          aria-expanded={isOpen}
          id={titleId}
          hideBorderBottom={!isOpen}
        >
          {sectionTitleFormat && sectionTitleFormat.map(component => component)}
          <AccessibleHidden aria-hidden={isOpen} toHide={isOpen}>
            {formatMessage({ id: 'SelectToExpand' })}
          </AccessibleHidden>
          <AccessibleHidden aria-hidden={!isOpen}>
            {formatMessage({ id: 'SelectToCollapse' })}
          </AccessibleHidden>
        </ToggleTitle>
      </ToggleTitleHeading>
      <ToggleImgWrapper
        onClick={() => {
          toggleAccordion(!isOpen);
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            toggleAccordion(!isOpen);
          }
        }}
        data-attribute="img"
      >
        {isOpen ? (
          <ImagePath aria-hidden="true" alt="" src={chevronUp} />
        ) : (
          <ImagePath aria-hidden="true" alt="" src={chevronDown} />
        )}
      </ToggleImgWrapper>
      {isOpen && (
        <ToggleDescription
          id={expandedSectionId}
          aria-labelledby={titleId}
          aria-hidden={!isOpen}
        >
          {React.cloneElement(children, { toggleAccordion })}
        </ToggleDescription>
      )}
    </ToggleSectionWrapper>
  );
};

ToggleHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  sectionTitleFormat: PropTypes.array.isRequired,
  expandedSectionId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired
};

ToggleHandler.defaultProps = {};

ToggleHandler.contextTypes = {
  intl: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};
export default ToggleHandler;
