import React, { Component } from 'react';
import { FontIcon } from 'ui-component-library';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ParentWrapper,
  LOGO,
  HiddenDiv,
  BurgerMenuWrapper,
  CloseIconContainer,
  List,
  ListElement,
  LogoffBtn,
  WhiteButton,
  CountryLabel,
  MenuButton,
  GlobalBodyCSS,
  HiddenButton,
  ListHeaderButton,
  BackToIconImg,
  SpanText
} from './BurgerMenuStyles';
import HSBCLogo from '../../../assets/images/HSBCLogo.svg';
import BackToIcon from '../../../assets/images/chevron-left.svg';
import RightIcon from '../../../assets/images/cevrron-right.svg';
import SelectedIcon from '../../../assets/images/white-yes.svg';

import { AccessibleHidden } from '../../AppStyles';
import { trackEvents } from '../../../utils/tealiumUtils';
import { setLanguageCode } from '../../../utils/domUtils';
import MegaMenuMobile from '../masthead/MegaMenu/MegaMenuMobile';

class BurgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      slide: '',
      isSubMenuOpen: false
    };
  }

  componentDidMount() {
    this.rootEle = document.getElementById('root');
    this.burgerRoot = document.createElement('div');
    this.burgerRoot.setAttribute('id', 'burgerRoot');
    document.getElementsByTagName('body')[0].appendChild(this.burgerRoot);
    window.addEventListener('resize', this.handleClose);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleClose);
  }

  handleMenuClick = () => {
    this.setState({ showMenu: true, slide: 'in' }, () => {
      if (this.rootEle) {
        this.rootEle.setAttribute('aria-hidden', true);
      }
      const nodeBurgerMenu = document.getElementById('burgerMenu');
      if (nodeBurgerMenu) {
        this.burgerRoot.appendChild(nodeBurgerMenu);
      }
      if (this.closeBtnRef) {
        this.closeBtnRef.focus();
      }
    });
  };

  handleClose = () => {
    const { slide } = this.state;
    if (slide !== 'out') {
      this.setState({ slide: 'out' }, () => {
        setTimeout(() => {
          this.setState({ showMenu: false, isSubMenuOpen: false });
          if (this.rootEle) {
            this.rootEle.setAttribute('aria-hidden', false);
          }
          if (this.menuBtnRef) {
            this.menuBtnRef.focus();
          }
        }, 1000);
      });
    }
  };

  handleLastElementFocus = () => {
    if (this.closeBtnRef) {
      this.closeBtnRef.focus();
    }
  };

  handleSubMenuClick = () => {
    this.setState({ isSubMenuOpen: true });
  };

  handleCloseSubMenuClick = () => {
    this.setState({ isSubMenuOpen: false });
  };

  menuHederLinks = (internetBankingURL, myInsuranceURL) => {
    return (
      <>
        <ListElement role="none">
          <a href={internetBankingURL} id="internetBanking">
            <FormattedMessage
              id="internetBanking"
              defaultMessage="Internet Banking"
            />
            <BackToIconImg src={RightIcon} alt="" />
          </a>
        </ListElement>
        <ListElement noBorder role="none">
          <WhiteButton
            hreff={myInsuranceURL}
            onClick={this.handleSubMenuClick}
            id="myInsurance"
            subMenuButton
          >
            <FormattedMessage id="myInsurance" defaultMessage="My Insurance" />
            <BackToIconImg src={RightIcon} alt="" />
          </WhiteButton>
        </ListElement>
      </>
    );
  };

  render() {
    const { showMenu, slide, isSubMenuOpen } = this.state;
    const {
      onLogOff,
      selectedLanguage,
      onLanguageChange,
      languageConfig,
      mastHeaderLinks,
      megaMenuConfig: { megaMenuSection },
      totalPolicyCount,
      setSelectedTabHandler,
      langObj,
      intl: { messages = {} } = {},
      customer,
      customerSegmentValues
    } = this.props;

    const internetBanking = _.find(
      mastHeaderLinks,
      {
        text: 'internetBanking'
      },
      {}
    );
    const myInsurance = _.find(mastHeaderLinks, { text: 'myInsurance' }, {});
    return (
      <>
        <GlobalBodyCSS hideOverFlow={showMenu} />
        <ParentWrapper>
          <MenuButton
            onClick={this.handleMenuClick}
            aria-controls="menu"
            aria-expanded="false"
            aria-label={messages.menuBtnLabel}
            innerRef={ele => {
              this.menuBtnRef = ele;
            }}
            id="menuBtn"
          >
            <FontIcon type="menu" cursor="pointer" />
          </MenuButton>
          <LOGO src={HSBCLogo} alt={messages.logoAltText} id="logo" />
        </ParentWrapper>
        <HiddenDiv showMenu={showMenu} id="burgerMenu">
          {showMenu && (
            <BurgerMenuWrapper slide={slide}>
              <CloseIconContainer isSubMenuOpen={isSubMenuOpen}>
                {isSubMenuOpen && (
                  <ListHeaderButton
                    type="button"
                    onClick={this.handleCloseSubMenuClick}
                  >
                    <BackToIconImg src={BackToIcon} alt="" aria-hidden="true" />
                    <FormattedMessage
                      id="myInsurance"
                      defaultMessage="My Insurance"
                    />
                  </ListHeaderButton>
                )}
                <WhiteButton
                  right
                  autoWidth
                  innerRef={ele => {
                    this.closeBtnRef = ele;
                  }}
                  aria-label={messages.closeBtnLabel}
                  onClick={this.handleClose}
                  id="closeBtn"
                >
                  <FontIcon type="delete" cursor="pointer" />
                </WhiteButton>
              </CloseIconContainer>
              <List aria-labelledby="menu">
                {isSubMenuOpen && (
                  <MegaMenuMobile
                    megaMenuItems={megaMenuSection}
                    totalPolicyCount={totalPolicyCount}
                    setSelectedTabHandler={setSelectedTabHandler}
                    handleClose={this.handleClose}
                    langObj={langObj}
                    customer={customer}
                    customerSegmentValues={customerSegmentValues}
                  />
                )}
                {!isSubMenuOpen &&
                  this.menuHederLinks(internetBanking.url, myInsurance.url)}
                <ListElement noBorder role="none">
                  <LogoffBtn
                    id="logOffBtn"
                    onClick={() => {
                      onLogOff();
                      trackEvents({ eventName: 'logOff' });
                    }}
                  >
                    <FormattedMessage id="log_Off" defaultMessage="Log off" />
                  </LogoffBtn>
                </ListElement>
                <ListElement noBorder greyBackgroundColor role="none">
                  <CountryLabel>
                    <FormattedMessage
                      id="hong_Kong"
                      defaultMessage="Hong kong"
                    />
                  </CountryLabel>
                </ListElement>
                {languageConfig.map(item => (
                  <ListElement
                    greyBackgroundColor
                    key={`langEle${item.languageValue}`}
                    role="none"
                  >
                    <WhiteButton
                      onClick={() => onLanguageChange(item.languageValue)}
                      id={`lang_${item.languageValue}`}
                      aria-label={item.languageText}
                      lang={setLanguageCode(item.languageValue)}
                      aria-describedby={
                        selectedLanguage === item.languageValue
                          ? 'selected'
                          : null
                      }
                    >
                      <SpanText>
                        {selectedLanguage === item.languageValue && (
                          <>
                            <AccessibleHidden id="selected">
                              <FormattedMessage
                                id="selected"
                                defaultMessage="selected"
                              />
                            </AccessibleHidden>
                            <>
                              <BackToIconImg
                                selectedIcon
                                src={SelectedIcon}
                                alt=""
                                aria-hidden="true"
                              />
                            </>
                          </>
                        )}
                        {item.languageText}
                      </SpanText>
                    </WhiteButton>
                  </ListElement>
                ))}
              </List>
              <HiddenButton
                onFocus={this.handleLastElementFocus}
                id="hiddenBtn"
              />
            </BurgerMenuWrapper>
          )}
        </HiddenDiv>
      </>
    );
  }
}
BurgerMenu.propTypes = {
  onLogOff: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  mastHeaderLinks: PropTypes.array.isRequired,
  languageConfig: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  megaMenuConfig: PropTypes.object.isRequired,
  megaMenuSection: PropTypes.object.isRequired,
  setSelectedTabHandler: PropTypes.func.isRequired,
  totalPolicyCount: PropTypes.number.isRequired,
  langObj: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  customerSegmentValues: PropTypes.array.isRequired
};
export default injectIntl(BurgerMenu);
