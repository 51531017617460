import React from 'react';
import PropTypes from 'prop-types';
import {
  CashSurrenderSubHeaderDiv,
  InfoIconSpan,
  InfoMessageSpan,
  InfoIconImg
} from '../../reprojectionPageStyle';
import { Information } from '../../../assets/images/NotificationIcons';
import {
  checkFlagCondition,
  getTabSubHeaderKey,
  getTabSubHeader,
  getTabSubHeaderKeyForType
} from '../../utils/tabSubHeaderUtils';

const getRemainingBalanceAmountForAge = function getRemainingBalanceAmountForAge(
  age,
  reprojectionDetailsData,
  remainingBalanceMappingField
) {
  let remainingBalance = 0;
  if (reprojectionDetailsData) {
    const reprojectionDataItem = reprojectionDetailsData[age];
    if (reprojectionDataItem) {
      remainingBalance =
        reprojectionDataItem[remainingBalanceMappingField] || 0;
    }
  }
  return remainingBalance;
};

function canShowTabSubHeader(
  isCashSurrenderDeathBenefitTab,
  isCashPayout,
  histTotalAmt
) {
  return isCashSurrenderDeathBenefitTab
    ? isCashPayout
    : isCashPayout && histTotalAmt > 0;
}

function canShowRemainingBalanceSubHeader(
  isCashSurrenderDeathBenefitTab,
  payoutDetails,
  customerMaxPayAge
) {
  let remainingBalance = 0;
  /*
    As per the current acceptance criteria, if payout tab is selected, then check
    whether remaining balance in payout details for last selected age is greater than zero
  */
  if (!isCashSurrenderDeathBenefitTab) {
    remainingBalance = getRemainingBalanceAmountForAge(
      customerMaxPayAge,
      payoutDetails,
      'totMaturityAmt'
    );
  }

  return remainingBalance > 0;
}

const FutureValueTabSubHeader = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    reprojectionsDetails: {
      reprojectionDetails: {
        indicators: { isCashPayout },
        historicReprojectionDetails: { histTotalAmt },
        payoutBenefit: { payoutDetails }
      }
    },
    selectedFutureBenefitsTab,
    customerMaxPayAge,
    selectedTabName
  } = props;

  const isCashSurrenderDeathBenefitTab = checkFlagCondition(
    selectedFutureBenefitsTab
  );
  const showSubHeader = canShowTabSubHeader(
    isCashSurrenderDeathBenefitTab,
    isCashPayout,
    histTotalAmt
  );

  // Show the remaining balance when the current tab is payout tab and remaining balance is greater than zero
  const showSubHeaderRemainingBalance = canShowRemainingBalanceSubHeader(
    isCashSurrenderDeathBenefitTab,
    payoutDetails,
    customerMaxPayAge
  );

  const displayText = getTabSubHeader(selectedFutureBenefitsTab);
  const displayRemainingBalanceText = getTabSubHeaderKeyForType(
    selectedFutureBenefitsTab,
    'RemainingBalance'
  );

  return (
    <>
      {showSubHeaderRemainingBalance && (
        <CashSurrenderSubHeaderDiv id={displayRemainingBalanceText}>
          <InfoIconSpan>
            <InfoIconImg aria-hidden="true" alt="" src={Information} />
          </InfoIconSpan>
          <InfoMessageSpan>
            {formatMessage({ id: `${displayRemainingBalanceText}` })}
          </InfoMessageSpan>
        </CashSurrenderSubHeaderDiv>
      )}
      {showSubHeader && (
        <CashSurrenderSubHeaderDiv
          id={`${getTabSubHeaderKey(selectedFutureBenefitsTab)}_${selectedTabName}`}
        >
          <InfoIconSpan>
            <InfoIconImg aria-hidden="true" alt="" src={Information} />
          </InfoIconSpan>
          <InfoMessageSpan>
            {formatMessage({ id: `${displayText}` })}
          </InfoMessageSpan>
        </CashSurrenderSubHeaderDiv>
      )}
    </>
  );
};

FutureValueTabSubHeader.propTypes = {
  reprojectionsDetails: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  customerMaxPayAge: PropTypes.number,
  selectedTabName: PropTypes.string.isRequired
};

FutureValueTabSubHeader.defaultProps = {
  customerMaxPayAge: -1
};

FutureValueTabSubHeader.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FutureValueTabSubHeader;
