import React from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from '../common/tooltipWrapper/TooltipWrapper';
import {
  ChartLegendsWrapper,
  ChartLegend,
  ChartLegendIcon,
  ChartLegendCaption,
  ChartLegendCaptionWrapper
} from './ChartLegendsStyles';

const isReprojectionsTooltip = true;
const ChartLegends = (
  { legends, imagePath, includeNonGuranteedPayout, selectedTabName },
  { intl: { formatMessage } }
) => (
    <ChartLegendsWrapper data-testid="chart-legends-wrapper">
      {legends.map(
        (
          { isNonGuranteedField, label, imagePosition, tooltipContent },
          index
        ) => (
            <ChartLegend
              data-testid={`chart-legend-${index}`}
              id={`${selectedTabName}_chart-legend-${index}`}
              key={label}
              showLegend={
                isNonGuranteedField
                  ? isNonGuranteedField === true &&
                  includeNonGuranteedPayout === true
                  : true
              }
            >
              <ChartLegendIcon
                data-testid="chart-legend-icon"
                id={`${selectedTabName}_chart-legend-icon-${index}`}
                imagePath={imagePath}
                imagePosition={imagePosition}
              />
              <ChartLegendCaptionWrapper id={`${selectedTabName}_chart-legend-caption-${index}`}>
                <ChartLegendCaption data-testid="chart-legend-caption">
                  {formatMessage({ id: label })}
                </ChartLegendCaption>
                {tooltipContent && (
                  <TooltipWrapper
                    id={`tooltip_id_${label}`}
                    contentKey={tooltipContent}
                    sectionHeading=" "
                    labelContent={label}
                    maxWidth={300}
                    isReprojectionsTooltip={isReprojectionsTooltip}
                  />
                )}
              </ChartLegendCaptionWrapper>
            </ChartLegend>
          )
      )}
    </ChartLegendsWrapper>
  );

ChartLegends.propTypes = {
  legends: PropTypes.array.isRequired,
  imagePath: PropTypes.string.isRequired,
  includeNonGuranteedPayout: PropTypes.bool,
  selectedTabName: PropTypes.string.isRequired
};

ChartLegends.contextTypes = {
  intl: PropTypes.object.isRequired
};

ChartLegends.defaultProps = {
  includeNonGuranteedPayout: false
};

export default ChartLegends;
