import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SubMenuTitle, LinkText, LinkWrapper } from './MegaMenuStyles';
import { megaMenuRouterLinks, getProductLink } from './megaMenuUtils';
import { trackEvents } from '../../../../utils/tealiumUtils';
import { isMobile } from '../../../../utils/domUtils';
import getContentInSpecificLang from '../../../../utils/localeUtils';

const MegaMenuLinks = function MegaMenuLinks(props, context) {
  const {
    totalMenuItems,
    currentSubMenuItemIdx,
    subMenuList,
    totalPolicyCount,
    handleClose,
    langObj
  } = props;
  const {
    menuHeading,
    isNavigateByRoutes = false,
    pathToMatch,
    productLink
  } = subMenuList;
  const {
    intl: { formatMessage, locale }
  } = context;

  const closeMenuOnMobile = () => {
    if (isMobile()) {
      return handleClose;
    }
    return false;
  };

  const callTealiumEvent = linkName => {
    trackEvents({
      eventName: 'megaMenuLinks',
      eventContent: _.toLower(getContentInSpecificLang(linkName, 'en', langObj))
    });
  };

  const dynamicRouteLinkContent = currentHeading => {
    const isMyPolicies =
      currentHeading === 'myPolicies' &&
      totalPolicyCount &&
      totalPolicyCount >= 0;
    if (isMyPolicies) {
      return (
        <FormattedMessage
          id={`${currentHeading}_withTotalPolicyCnt`}
          values={{
            totalPolicies: totalPolicyCount
          }}
        />
      );
    }
    return <FormattedMessage id={currentHeading} />;
  };

  if (isNavigateByRoutes) {
    const linkTo = megaMenuRouterLinks(pathToMatch);
    return (
      <LinkWrapper>
        <Link
          to={linkTo[pathToMatch]}
          id={`${pathToMatch}_link`}
          role="menuitem"
          aria-setsize={totalMenuItems}
          aria-posinset={currentSubMenuItemIdx}
          onClick={() => {
            callTealiumEvent(menuHeading);
            closeMenuOnMobile();
          }}
        >
          <SubMenuTitle>{dynamicRouteLinkContent(menuHeading)}</SubMenuTitle>
        </Link>
      </LinkWrapper>
    );
  }

  return (
    <LinkText
      id={`${menuHeading}_link`}
      rel={productLink && productLink.rel}
      target={productLink && productLink.target}
      href={getProductLink(productLink && productLink.url, locale)}
      role="menuitem"
      aria-setsize={totalMenuItems}
      aria-posinset={currentSubMenuItemIdx}
      onClick={() => {
        callTealiumEvent(menuHeading);
        closeMenuOnMobile();
      }}
    >
      <SubMenuTitle>{formatMessage({ id: menuHeading })}</SubMenuTitle>
    </LinkText>
  );
};

MegaMenuLinks.propTypes = {
  subMenuList: PropTypes.object.isRequired,
  totalPolicyCount: PropTypes.number.isRequired,
  totalMenuItems: PropTypes.number.isRequired,
  currentSubMenuItemIdx: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  langObj: PropTypes.object.isRequired
};

MegaMenuLinks.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default MegaMenuLinks;
