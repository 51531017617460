import React from 'react';
import PropTypes from 'prop-types';
import {
  FundName,
  FundAllocation,
  FundNameSection,
  FundCurrency,
  SwitchOutName
} from '../instructionHistoryStyle';

const ServicingAccountCurrency = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    label,
    accountNumber,
    removeLeftSpacing,
    currency,
    individualFund
  } = props;

  if (!accountNumber) {
    return null;
  }

  return (
    <FundNameSection>
      {individualFund ? (
        <SwitchOutName removeLeftSpacing={removeLeftSpacing}>
          {formatMessage({ id: label })}
        </SwitchOutName>
      ) : (
        <FundName removeLeftSpacing={removeLeftSpacing}>
          {formatMessage({ id: label })}
        </FundName>
      )}
      <FundAllocation removeLeftSpacing={removeLeftSpacing}>
        {currency && (
          <FundCurrency>
            {formatMessage({ id: `currency_${currency}` })}
          </FundCurrency>
        )}
        <span>{accountNumber}</span>
      </FundAllocation>
    </FundNameSection>
  );
};

ServicingAccountCurrency.propTypes = {
  label: PropTypes.string.isRequired,
  accountNumber: PropTypes.string,
  removeLeftSpacing: PropTypes.bool,
  currency: PropTypes.string,
  individualFund: PropTypes.bool
};

ServicingAccountCurrency.defaultProps = {
  accountNumber: '',
  removeLeftSpacing: false,
  currency: '',
  individualFund: false
};

ServicingAccountCurrency.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ServicingAccountCurrency;
