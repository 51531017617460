import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'intl';
import { IntlProvider, addLocaleData } from 'react-intl';
import PropTypes from 'prop-types';
import { HSBCApp } from 'ui-component-library';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  localMapping,
  localeData,
  langConfig
} from '../configure/localeDataConfig';
import Routes from './routes';
import Loader from '../common/loader';
import {
  refreshSAASToken,
  changeLocale,
  updateIdleFlag,
  setContactUsPopup,
  setSelectedTab
} from './appActions';
import Footer from './components/footer/Footer';
import Masthead from './components/masthead/Masthead';
import URLsConfiguration from '../utils/HSBCdataCenterDetector';
import Context from './Context';
import { getRoutesPagePath } from '../utils/routeUtils';
import {
  ParentContainer,
  FullWidthSection,
  HeaderSection,
  FooterSection,
  GlobalStyle
} from '../common/styles/commonStyle';
import SessionManager from './components/sessionmanager/SessionManager';
import SessionTimeoutDialog from './components/sessiontimeout/SessionTimeoutPopUp';

addLocaleData(localeData);

class App extends PureComponent {
  render() {
    const {
      locale = 'en',
      langContent,
      isLoading,
      appBootstrapComplete,
      isIdle,
      genericConfig: {
        mastHeadURL,
        currencyCodes,
        dateFormatAsPerLocale,
        colorCodesForPolicyStatus,
        timeout,
        minInactivityTime,
        footerCrossBorderURL,
        customerSegmentValues
      },
      refreshSAASTokenHandler,
      setLocale,
      isStubbed,
      updateIdleFlagHandler,
      setContactUsPopupHandler,
      showContactUsFooterPopup,
      encryptedCIN,
      setSelectedTabHandler,
      megaMenuConfig,
      totalPolicyCount,
      isLoaderTransparent,
      customer
    } = this.props;
    const idleTimeout = timeout;
    const mastHeaderLinks = URLsConfiguration(mastHeadURL);
    const contextValue = {
      currencyCodes,
      copyResponse: langContent,
      applicationUrl: getRoutesPagePath(),
      dateFormatAsPerLocale,
      colorCodesForPolicyStatus
    };
    return (
      <HSBCApp x-ms-format-detection="none">
        <GlobalStyle />
        {(Object.keys(langContent).length === 0 || isLoading) && (
          <Loader
            opacity={isLoaderTransparent ? 0.5 : 1}
            messages={
              langContent[localMapping[locale]] &&
              langContent[localMapping[locale]].content
            }
            id="loadingText"
          />
        )}
        {appBootstrapComplete && (
          <>
            <Context.Provider value={contextValue}>
              <IntlProvider
                locale={locale}
                messages={
                  langContent[localMapping[locale]] &&
                  langContent[localMapping[locale]].content
                }
              >
                {/*
              <ParentContainer> parent view div,
              NOTE:Avoid to give any style which will be affecting to child node
            */}
                <ParentContainer id="parentContainer">
                  {!isStubbed && (
                    <SessionManager
                      idleTimeout={idleTimeout}
                      minInactivityTime={minInactivityTime}
                      onActionHandler={saasToken =>
                        refreshSAASTokenHandler(saasToken)
                      }
                      isIdle={isIdle}
                      updateIdleState={idleFlag =>
                        updateIdleFlagHandler(idleFlag)
                      }
                    >
                      <SessionTimeoutDialog />
                    </SessionManager>
                  )}

                  {/* <FullWidthSection> Carries full viewport width  */}
                  <Router>
                    <FullWidthSection>
                      {/* <HeaderSection> Masthead section */}
                      <HeaderSection>
                        <Masthead
                          onLanguageChange={setLocale}
                          languageConfig={langConfig}
                          selectedLanguage={locale}
                          mastHeaderLinks={mastHeaderLinks}
                          megaMenuConfig={megaMenuConfig}
                          totalPolicyCount={totalPolicyCount}
                          setSelectedTabHandler={setSelectedTabHandler}
                          langObj={langContent}
                          customer={customer}
                          customerSegmentValues={customerSegmentValues}
                        />
                      </HeaderSection>
                    </FullWidthSection>
                    {/* <FullWidthSection> Carries full viewport width  */}
                    <FullWidthSection>
                      <Routes
                        encryptedCIN={encryptedCIN}
                        setContactUsPopupHandler={setContactUsPopupHandler}
                        setSelectedTabHandler={setSelectedTabHandler}
                      />
                    </FullWidthSection>
                    {/* <FullWidthSection> Carries full viewport width  */}
                    <FullWidthSection>
                      {/* <FooterSection> footer section */}
                      <FooterSection>
                        <Footer
                          showContactUsFooterPopup={showContactUsFooterPopup}
                          setContactUsPopupHandler={setContactUsPopupHandler}
                          footerCrossBorderURL={footerCrossBorderURL}
                          selectedLanguage={locale}
                        />
                      </FooterSection>
                    </FullWidthSection>
                  </Router>
                </ParentContainer>
              </IntlProvider>
            </Context.Provider>
          </>
        )}
      </HSBCApp>
    );
  }
}
const mapStateToProps = state => {
  return {
    locale: state.content.selectedLocale,
    langContent: state.content.langObj,
    isLoading: state.app.isLoading,
    genericConfig: state.configurations.genericConfig,
    isStubbed: state.app.isStubbed,
    appBootstrapComplete: state.app.appBootstrapComplete,
    showContactUsFooterPopup: state.app.showContactUsFooterPopup,
    isIdle: state.app.isIdle,
    encryptedCIN: state.customer.encryptedCIN,
    megaMenuConfig: state.configurations.megaMenuConfig,
    totalPolicyCount: state.policy.totalPolicyCount,
    isLoaderTransparent: state.app.isLoaderTransparent,
    customer: state.customer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refreshSAASTokenHandler: refreshSAASToken,
      setLocale: changeLocale,
      updateIdleFlagHandler: updateIdleFlag,
      setContactUsPopupHandler: setContactUsPopup,
      setSelectedTabHandler: setSelectedTab
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
  locale: PropTypes.string.isRequired,
  langContent: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isStubbed: PropTypes.bool.isRequired,
  genericConfig: PropTypes.object.isRequired,
  refreshSAASTokenHandler: PropTypes.func.isRequired,
  updateIdleFlagHandler: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
  appBootstrapComplete: PropTypes.bool,
  isIdle: PropTypes.bool.isRequired,
  setContactUsPopupHandler: PropTypes.func.isRequired,
  showContactUsFooterPopup: PropTypes.bool.isRequired,
  setSelectedTabHandler: PropTypes.func.isRequired,
  encryptedCIN: PropTypes.string.isRequired,
  megaMenuConfig: PropTypes.object.isRequired,
  totalPolicyCount: PropTypes.number.isRequired,
  isLoaderTransparent: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired
};

App.defaultProps = {
  appBootstrapComplete: false
};
