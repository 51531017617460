import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as icons from '../assets/images/NotificationIcons';
import theme from '../common/styles/theme';
import {
  NotificationMessageIcon,
  NotificationMessageErrorType,
  NotificationIconSpan,
  ImagePath,
  ExceptionLapsedNotificationMessage
} from '../common/styles/commonStyle';
import { ReprojectionsPageMiddleSection } from './reprojectionPageStyle';

const ReprojectionsException = (props, context) => {
  const {
    warningsOrErrors: { errors = [] }
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  /* show only error banner if api fails  */
  if (errors.length) {
    return (
      <ReprojectionsPageMiddleSection>
        <NotificationMessageErrorType
          bgColor={theme.errorNotificationBG}
          borderColor={theme.primaryActive}
        >
          {errors.map((alert, index) => {
            return (
              <NotificationMessageIcon role="alert" key={alert.errorCode}>
                <NotificationIconSpan>
                  <ImagePath aria-hidden="true" alt="" src={icons.ErrorIcon} />
                </NotificationIconSpan>
                <ExceptionLapsedNotificationMessage
                  id={`policyDetails_error_${index}`}
                  aria-label={formatMessage({ id: 'reprojectionsError' })}
                >
                  <FormattedMessage
                    id={
                      alert.errorCode !== 'defaultErrorOrWarningCode'
                        ? 'reprojectionsError'
                        : ''
                    }
                    aria-label={formatMessage({
                      id: 'reprojectionsError'
                    })}
                    values={{
                      errorCode: alert.errorCode
                    }}
                  />
                </ExceptionLapsedNotificationMessage>
              </NotificationMessageIcon>
            );
          })}
        </NotificationMessageErrorType>
      </ReprojectionsPageMiddleSection>
    );
  }
  return null;
};

ReprojectionsException.propTypes = {
  warningsOrErrors: PropTypes.array
};

ReprojectionsException.defaultProps = {
  warningsOrErrors: []
};

ReprojectionsException.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReprojectionsException;
