import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  MegaMenuWrapper,
  MenuItemParent,
  MenuTitle,
  SubMenuItem
} from './MegaMenuStyles';
import MegaMenuLinks from './MegaMenuLinks';
import {
  handleFocusOutClick,
  handleMegaMenuStateOnHover,
  handleMegaMenuStateOnOut,
  canDisplayMegaMenuLink
} from './megaMenuUtils';

const MegaMenuDesktop = function MegaMenuDesktop(props, context) {
  const {
    megaMenuItems,
    totalPolicyCount,
    langObj,
    customerSegmentValues,
    customer
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  useEffect(() => {
    const megaMenuDiv = document.getElementById('megaMenu');
    megaMenuDiv.addEventListener('focusout', handleFocusOutClick);
    megaMenuDiv.addEventListener('blur', handleFocusOutClick);
    megaMenuDiv.addEventListener('mouseover', handleMegaMenuStateOnHover);
    megaMenuDiv.addEventListener('mouseout', handleMegaMenuStateOnOut);
  });

  return (
    <MegaMenuWrapper
      id="megaMenu"
      role="group"
      aria-labelledby="mega menu"
      className="megaMenuWrapper"
      locale={locale}
    >
      {_.map(megaMenuItems, megaMenuItem => {
        const { menuHeading, isDisplayMenu = false, menuItems } = megaMenuItem;
        return (
          isDisplayMenu && (
            <MenuItemParent
              key={`${menuHeading}_list`}
              id={`${menuHeading}_list`}
              aria-label={formatMessage({ id: menuHeading })}
              className="megaMenuUL"
            >
              <MenuTitle>{formatMessage({ id: menuHeading })}</MenuTitle>
              {_.map(menuItems, (subMenu, subMenuIdx) => {
                const {
                  menuHeading: subMenuHeading,
                  isDisplayMenu: isDisplaySubMenu = false
                } = subMenu;
                let currentSubMenuItemIdx = subMenuIdx;
                currentSubMenuItemIdx += 1;
                const displayConditionParams = {
                  customer,
                  customerSegmentValuesArray: customerSegmentValues
                };

                return (
                  isDisplaySubMenu &&
                  canDisplayMegaMenuLink(subMenu, displayConditionParams) && (
                    <SubMenuItem
                      key={`${menuHeading}_${subMenuHeading}_list_item`}
                      id={`${menuHeading}_${subMenuHeading}_list_item`}
                      aria-label={formatMessage({ id: subMenuHeading })}
                      role="presentation"
                    >
                      <MegaMenuLinks
                        totalMenuItems={menuItems.length}
                        currentSubMenuItemIdx={currentSubMenuItemIdx}
                        subMenuList={subMenu}
                        totalPolicyCount={totalPolicyCount}
                        langObj={langObj}
                      />
                    </SubMenuItem>
                  )
                );
              })}
            </MenuItemParent>
          )
        );
      })}
    </MegaMenuWrapper>
  );
};

MegaMenuDesktop.propTypes = {
  megaMenuItems: PropTypes.array.isRequired,
  totalPolicyCount: PropTypes.number.isRequired,
  langObj: PropTypes.object.isRequired,
  customerSegmentValues: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired
};

MegaMenuDesktop.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default MegaMenuDesktop;
