import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CurrencyWithSymbol from '../../../common/currency/CurrencyWithSymbol';
import {
  FundsTotalCalculationFieldWrapper,
  TotalCalculationLabel,
  TotalCalculationAmount
} from '../../detailPageStyle';

const FundsTotalCalculationField = function FundsTotalCalculationField(props) {
  const {
    labelContent,
    fieldData,
    fieldData: { currency, amount },
    idx
  } = props;
  if (
    !labelContent ||
    typeof fieldData !== 'object' ||
    !currency ||
    (!amount && amount === 0)
  ) {
    return null;
  }
  return (
    <FundsTotalCalculationFieldWrapper id="fundsTotalCalculationField">
      <TotalCalculationLabel id={`label_${idx}`}>
        <FormattedMessage id={labelContent} />
      </TotalCalculationLabel>
      <TotalCalculationAmount>
        <CurrencyWithSymbol currencyCode={currency} amount={amount} />
      </TotalCalculationAmount>
    </FundsTotalCalculationFieldWrapper>
  );
};

FundsTotalCalculationField.propTypes = {
  idx: PropTypes.number.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired
};

export default FundsTotalCalculationField;
