import styled from 'styled-components';
import { em, rem } from 'polished';
import theme from '../../../common/styles/theme';
import { FontFamily } from '../../../utils/font';
import media from '../../../utils/media';
import { Section } from '../../../common/styles/commonStyle';

export const FooterContainer = styled.section`
  display: block;
  box-sizing: border-box;
  max-width: ${em(theme.maxWidth)};
  height: auto;
  width: 100%;
  margin: 0 auto;
`;

export const FooterText = styled.span`
  display: block;
  font-size: ${rem('16px')};
  ${FontFamily.RegularFontW01};
  color: ${theme.footerTextColor};
  line-height: ${rem('23px')};
  text-align: left;
  padding: ${rem('10px')} ${rem('50px')};
  ${media.tablet`
    ${FontFamily.RegularFontW01};
    padding: ${rem('10px')} ${rem('23px')};
    line-height: ${rem('20px')};
    font-size: ${rem('14px')};
`};
  ${media.phone`
   ${FontFamily.RegularFontW01};
    padding: ${rem('20px')} ${rem('15px')};
    font-size: ${rem('14px')};
    line-height: ${rem('20px')};
  `}
`;

export const FooterWrapper = styled.div`
  background: ${props => props.bgColor};
`;
export const ContactSection = styled(Section)`
  margin-top: 40px;
  height: 50px;
  color: white;
  margin-bottom: 0;
`;
export const LinkSection = styled.div`
  box-sizing: border-box;
  display: flex;
  align-item: center;
  height: 100%;
  button {
    color: ${theme.whiteColor};
    font-size: ${em('14px')};
    padding: 0 20px;
    align-items: center;
    box-sizing: border-box;
    border-left: 1px solid ${theme.footerTextColor};
    border-right: 1px solid ${theme.footerTextColor};
    &:focus,
    &:hover {
      outline: 2px dotted ${theme.footerTextColor};
    }
  }
`;

export const ContactImagePath = styled.img`
  height: 100%;
  width: 100%;
`;
export const ContactImgSpan = styled.span`
  height: ${rem('16px')};
  width: ${rem('16px')};
  margin-right: ${rem('10px')};
  display: block;
  ${media.phone`
    margin-right: ${rem('5px')};
  `}
`;

export const ModalDescWrapper = styled.div`
  box-sizing: border-box;
`;

export const ModalNoteWrapper = styled.div`
  padding: 0 0 20px 0;
  box-sizing: border-box;
  ${media.phone`
  border-bottom: solid 1px ${theme.detailNotificationBorder};
  margin-bottom:${rem('20px')};
`}
`;
export const NoteText = styled.div`
${FontFamily.RegularFontW01}
font-size: ${rem('16px')};
line-height:${rem('22px')};
color:${theme.fontColor};
${media.tablet`
font-size: ${rem('14px')};
`}
`;
export const ModalListHeaderSection = styled.div``;

export const ListItem = styled.h2`
${FontFamily.BoldFontW01}
font-size: ${rem('16px')};
line-height:${rem('20px')};
color:${theme.fontColor};
margin-bottom: 11px;

`;
export const ListItemDetails = styled.span`
${FontFamily.RegularFontW01}
font-size: ${rem('14px')};
line-height:${rem('20px')};
color:${theme.fontColor};
margin-bottom:20px;
display:block;
`;

export const HeaderSectionWrapper = styled.table`
  width: 100%;
  table-layout: fixed;
  border-color: unset;
  border-spacing: unset;
  border: solid 1px #d8d8d8;
  margin-bottom: 40px;
  ${media.phone`
     position:relative;
    &:after{
      background: #d8d8d8;
      height: 1px;
      width: 100%;
      content: '';
      position: absolute;
      bottom: -25px;
    }
  `}
`;
export const HeaderSection = styled.thead`
  width: 100%;
`;
export const ListRow = styled.tr`
  background-color: #ededed;
  width: 100%;
`;
export const ListRowHeader = styled.th`
  font-weight: unset;
  vertical-align: top;
  ${FontFamily.BoldFontW01}
  font-size: ${rem('16px')};
  line-height:${rem('22px')};
  color:${theme.fontColor};
  box-sizing:border-box;
  padding:9.5px 20px;
  text-align: left;
  height:100%;
  width: ${props => (props.singlePolicy ? '100%' : '50%')};

  &:first-child {
    border-right: ${props => (props.singlePolicy ? '' : '1px solid  #d8d8d8')};
  }
  ${media.tablet`
  font-size: ${rem('14px')};
  line-height:${rem('18px')};
`}
${media.phone`
  font-size: ${rem('12px')};
  padding:${rem('9.5px')} ${rem('14px')};
`}
`;
export const SectionBody = styled.tbody``;
export const ListRowDetails = styled.tr`
  width: 100%;
`;
export const ListRowData = styled.td`
  vertical-align: top;
  padding: 20px;
  width: ${props => (props.singlePolicy ? '100%' : '50%')};
  &:first-child {
    border-right: ${props => (props.singlePolicy ? '' : '1px solid  #d8d8d8')};
    padding-right: 19px;
    ${media.phone`
    padding-right:${rem('14px')};
  `}
  }
  ${media.phone`
  padding:${rem('14px')};
`}
`;

export const ContactLabel = styled.span`
display:flex;
${FontFamily.RegularFontW01}
font-size: ${rem('16px')};
line-height:${rem('20px')};
letter-spacing: -0.32px;
color: #252525;
margin-bottom:11px;
${media.phone`
font-size: ${rem('12px')};
line-height:${rem('18px')};
letter-spacing: -0.24px;
`}
`;
export const ContactNumber = styled.span`
display:flex;
${FontFamily.RegularFontW01}
font-size: ${rem('20px')};
line-height:${rem('20px')};
letter-spacing: -0.21px;
color: #333333;
margin-bottom:11px;
${media.phone`
${FontFamily.BoldFontW01}
font-size: ${rem('12px')};
line-height:${rem('18px')};
letter-spacing: -0.13px;
`}
`;
export const ContactDetail = styled.span`
display:flex;
${FontFamily.RegularFontW01}
font-size: ${rem('14px')};
line-height:${rem('20px')};
letter-spacing: -0.15px;
color: #333333;
&#policyEmergency{
  width:${props => (props.isIE ? '70%' : '')};
}
${media.phone`
font-size: ${rem('12px')};
line-height:${rem('18px')};
letter-spacing: -0.13px;
`}
`;

export const ContactLabelWrapper = styled.div`
  margin-bottom: ${props => (props.hideBottomSpacing ? '0' : '30px')};
`;
export const ContactCard = styled.div`
  display: flex;
  margin-bottom: 30px;
  span {
    margin-right: 30px;
  }
  #Contact_Details {
  }
  ${media.tablet`
  flex-direction: column;
  #Contact_Number{
    ${FontFamily.BoldFontW01}
    font-size: ${rem('16px')};
  }

  ${media.phone`
  #Contact_label{
    font-size: ${rem('16px')};
  }
  #Contact_Details{
    font-size: ${rem('14px')};
  }
`}
`}
`;
export const ContactCardLabel = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DisclaimerWrapper = styled.div`
  section {
    padding-left: 0;
    padding-right: 0;
    max-width: ${props => (props.isIE ? '100%' : '')};
  }
`;

export const FooterSubText = styled.p`
  padding: 0 50px 10px 50px;
  font-size: ${rem('14px')};
  ${FontFamily.RegularFontW01};
  color: ${theme.footerTextColor};
  line-height: ${rem('23px')};
  text-align: left;
  span {
    margin-right: 3px;
  }
  ${media.tablet`
  padding: 0 23px 10px 23px;
  `}
  ${media.phone`
    padding: 0 15px 10px 15px;
    display: flex;
    flex-direction: column;
  `}
`;

export const FooterSubLinkText = styled.a`
  ${FontFamily.RegularFontW01};
  color: ${theme.footerTextColor};
  text-decoration: none;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    text-decoration: 'underline';
  }
  &:hover {
    border-bottom: 1px solid ${theme.footerTextColor};
  }
`;
