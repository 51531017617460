import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { isMiniMobile, isIE } from '../utils/domUtils';
import PdfIcon from '../assets/images/Modal/icon/PDF.svg';
import { base64toBlob } from '../policydetail/templates/Benefits/IDC/utils/idcUtils';
import {
  retrievePolicyDocument,
  eWelcomeResetDocumentsErrorsWarnings,
  startLoaderEWelcome
} from '../app/appActions';
import { DocumentList, IconDiv, PdfLinks } from './WelcomeModalStyle';
import DownloadErrorModal from './DownloadErrorModal';
import { trackEvents, createEventActionContent } from '../utils/tealiumUtils';

class DownloadDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false
    };
    this.selectedDocId = '';
    this.selectedDocName = '';
  }

  componentDidUpdate() {
    const { documentData } = this.props;
    const { isDownload } = this.state;

    if (_.has(documentData, this.selectedDocId) && isDownload) {
      const { docContent = {} } = documentData[this.selectedDocId];
      const { inlineDocument = {} } = docContent;
      const { documentContent = '' } = inlineDocument;
      this.openBase64NewTab(this.selectedDocName, documentContent);
    }
  }

  openBase64NewTab = (fileName, pdfString) => {
    const blob = base64toBlob(pdfString);
    const url = URL.createObjectURL(blob);
    if (isIE) {
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    } else {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: url,
        rel: 'noopener noreferrer nofollow',
        title: fileName
      }).click();
    }
    this.selectedDocId = '';
  };

  getBaseString = (
    documentID,
    apiCallCount,
    documentTitle,
    documentCode,
    categoryName
  ) => {
    const {
      retrievePolicyDocumentHandler,
      encodePolicyNumber,
      startLoaderHandler,
      documentData,
      policy,
      policyNumber
    } = this.props;
    this.selectedDocId = documentID;
    this.selectedDocName = documentTitle;
    trackEvents({
      eventName: 'eWelcomeModelDownloadDocument',
      eventData: createEventActionContent(
        'download',
        documentTitle,
        categoryName
      )
    });
    this.setState({
      isDownload: true
    });
    const doc = documentData[this.selectedDocId] || {};
    const { docContent = {} } = doc;
    const { inlineDocument = {} } = docContent;
    const { documentContent = '' } = inlineDocument;
    if (!documentContent) {
      startLoaderHandler();
      retrievePolicyDocumentHandler(
        documentID,
        1,
        encodePolicyNumber,
        documentCode,
        policy,
        policyNumber
      );
    }
  };

  getFormatMessage = doc => {
    if (doc.documentCodeRider) {
      const indexNum = doc.documentType.slice(-2);
      return (
        <FormattedMessage
          id={doc.documentNameLabel}
          defaultMessage={doc.documentType}
          values={{ indexNum }}
        />
      );
    }
    return (
      <FormattedMessage
        id={doc.documentNameLabel}
        defaultMessage={doc.documentType}
      />
    );
  };

  render() {
    const { documents, categoryName } = this.props;
    const { intl: { formatMessage } = {} } = this.context;
    return (
      <>
        {documents.map(doc => {
          return (
            <React.Fragment key={doc.documentID}>
              <DocumentList isMiniMobile={isMiniMobile()}>
                <PdfLinks
                  id={doc.documentID}
                  tabIndex={0}
                  data-locator-id="document-session-urls"
                  onClick={() =>
                    this.getBaseString(
                      doc.documentID,
                      1,
                      doc.documentType,
                      doc.documentCode,
                      categoryName
                    )
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      this.getBaseString(
                        doc.documentID,
                        1,
                        doc.documentType,
                        doc.documentCode,
                        categoryName
                      );
                    }
                  }}
                >
                  <IconDiv>
                    <img
                      data-locator-id="Pdf-icon"
                      alt={formatMessage({ id: 'pdfIcon_Alt_Text' })}
                      src={PdfIcon}
                    />
                  </IconDiv>
                  {this.getFormatMessage(doc)}
                  <FormattedMessage
                    id={`lang_${doc.languageCode}`}
                    defaultMessage="English"
                  />
                </PdfLinks>
              </DocumentList>
            </React.Fragment>
          );
        })}
        <DownloadErrorModal selectedDocId={this.selectedDocId} />
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retrievePolicyDocumentHandler: (
        documentID,
        apiCallCount,
        encodePolicyNumber,
        documentCode,
        policy,
        policyNumber
      ) =>
        retrievePolicyDocument({
          documentID,
          apiCallCount,
          encodePolicyNumber,
          documentCode,
          policy,
          policyNumber
        }),
      resetDocumentsErrorsWarnings: () =>
        eWelcomeResetDocumentsErrorsWarnings(),
      startLoaderHandler: startLoaderEWelcome
    },
    dispatch
  );

const mapStateToProps = state => {
  const {
    eWelcomePolicyDocuments: { documentsWarningsOrErrors },
    content: { selectedLocale, langObj }
  } = state;
  return {
    documentsWarningsOrErrors,
    selectedLocale,
    langObj
  };
};

DownloadDocument.contextTypes = {
  intl: PropTypes.object.isRequired
};

DownloadDocument.propTypes = {
  documents: PropTypes.array,
  documentData: PropTypes.object.isRequired,
  retrievePolicyDocumentHandler: PropTypes.func.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  startLoaderHandler: PropTypes.func.isRequired,
  policy: PropTypes.func.isRequired,
  policyNumber: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired
};

DownloadDocument.defaultProps = {
  documents: []
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocument);
