import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import iconRight from '../../assets/images/Icon_right.svg';
import * as images from '../../assets/images/QuickLinks';
import getTealiumSectionName from '../../tealium/teliumSectionName';
import QuickLinks from './QuickLinks';
import TransactionModal from '../templates/Funds/TransactionModal';
import { servicingLinkAvailable } from '../sectionUtils/FundUtils';
import ModalPopupGI from './ModalPopupGI';
import {
  getCurrentPolicyType,
  isServicngOptionApplicableToDemise,
  SERVICING_OPTIONS_KEPT_HIDDEN
} from '../sectionUtils/SectionsUtils';
import getContentInSpecificLang from '../../utils/localeUtils';
import { trackEvents } from '../../utils/tealiumUtils';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import ModalPopUp from '../../common/modal/PopUpTemplate';

const ServicingOption = function ServicingOption(props, context) {
  const {
    intl: { formatMessage, locale }
  } = context;
  const {
    servicingOptionsURL,
    sectionHeading,
    noMarginBottom,
    encodedProductId,
    currentPolicyDetail = null,
    tealiumSectionHeading,
    setIconWidthHeight,
    customerWhiteListedForDigiServiceLinks,
    superFamily,
    langObj,
    genericConfig,
    calledFrom
  } = props;
  const quickLinkHeaderContent = getTealiumSectionName(sectionHeading);
  const [isModalOpen, updateModalState] = useState(false);
  const [currentLinkVisited, setLinkVisited] = useState('');
  const { isLifePolicy, isGIPolicy } = getCurrentPolicyType(superFamily);
  const [
    isPaymentInstructionModalOpen,
    updatePaymentInstructionModalState
  ] = useState(false);

  const updateContactKeyLangSpecific = getContentInSpecificLang(
    'update_payment_information',
    'en',
    langObj
  );

  const onContinueClick = () => {
    trackEvents({
      eventName: 'popup',
      eventContent: updateContactKeyLangSpecific.toLowerCase()
    });
  };

  const onCancelClick = (updateModalStateFunc, isModalOpenState) => {
    updateModalStateFunc(!isModalOpenState);
  };

  const isTransferFundInstruction = servicingOptionKey => {
    if (servicingOptionKey === 'TransferOfFundsP2G') {
      return true;
    }
    if (servicingOptionKey === 'TransferOfFundsPIB') {
      return true;
    }
    return false;
  };
  const isServiceUnavailable =
    currentPolicyDetail &&
    servicingLinkAvailable(
      'TransferOfFunds_Link',
      currentPolicyDetail,
      customerWhiteListedForDigiServiceLinks
    );
  const pendingTransactions =
    isServiceUnavailable && isServiceUnavailable === 'pendingTransactions';
  const updatePaymentInstructionModal = () => {
    updatePaymentInstructionModalState(!isPaymentInstructionModalOpen);
  };

  const isInstructionHistoryHidden = servicingOptionLinkId => {
    return (
      isGIPolicy && _.includes(servicingOptionLinkId, SERVICING_OPTIONS_KEPT_HIDDEN)
    )
  }

  const isDDNotConfirmed =
    isServiceUnavailable && isServiceUnavailable === 'ddNotconfirmed';

  return (
    <>
      {servicingOptionsURL &&
        Object.keys(servicingOptionsURL).map(linkdetailsKey => {
          return (
            !isInstructionHistoryHidden(linkdetailsKey) && (
              <QuickLinks
                id={linkdetailsKey}
                key={linkdetailsKey}
                ariaLabel={formatMessage({ id: linkdetailsKey })}
                altText="edit-icon"
                iconSrc={images[linkdetailsKey]}
                linkLabel={formatMessage({ id: linkdetailsKey })}
                altTextIconRight="right-icon"
                iconRight={iconRight}
                linkSrc={
                  isServiceUnavailable &&
                    isTransferFundInstruction(linkdetailsKey)
                    ? '#'
                    : servicingOptionsURL[linkdetailsKey].url
                }
                tealiumHeaderContent={quickLinkHeaderContent}
                noMarginBottom={noMarginBottom}
                target={
                  servicingOptionsURL[linkdetailsKey].target
                    ? servicingOptionsURL[linkdetailsKey].target
                    : '_self'
                }
                isServiceUnavailable={isServiceUnavailable}
                isModalOpen={isModalOpen}
                updateModalState={updateModalState}
                serviceType={servicingOptionsURL[linkdetailsKey].type || ''}
                encodePolicyNumber={encodedProductId}
                tealiumSectionHeading={tealiumSectionHeading}
                isTransferFundInstruction={isTransferFundInstruction(
                  linkdetailsKey
                )}
                setIconWidthHeight={setIconWidthHeight}
                updatePaymentInstructionModal={() =>
                  updatePaymentInstructionModal()
                }
                isGIPolicy={isGIPolicy}
                isLifePolicy={isLifePolicy}
                setLinkVisited={setLinkVisited}
              />
            )
          );
        })}
      {isServiceUnavailable && (
        <TransactionModal
          isModalOpen={isModalOpen}
          onClose={() => onCancelClick(updateModalState, isModalOpen)}
          reasonServiceUnavailable={isServiceUnavailable}
          pendingTransactions={pendingTransactions}
          isDDNotConfirmed={isDDNotConfirmed}
        />
      )}
      {currentLinkVisited !== '' &&
        calledFrom === 'PolicyOverview' &&
        isServicngOptionApplicableToDemise(isLifePolicy, currentLinkVisited) ? (
        <>
          <ModalPopUp
            isModalOpen={isPaymentInstructionModalOpen}
            onClose={updatePaymentInstructionModal}
            modalHeading=""
            footerButtonList={[
              {
                className: 'whiteBtn',
                label: formatMessage({ id: 'cancelPopup' }),
                id: 'cancel_btn',
                align: 'left'
              },
              {
                className: 'redBtn',
                label: formatMessage({ id: 'continuePopup' }),
                id: 'continue_btn',
                align: 'right'
              }
            ]}
            btnHandler={{
              continue_btn: onContinueClick,
              cancel_btn: updatePaymentInstructionModal
            }}
            proceedToLink={
              genericConfig.p2gDemiseList[currentLinkVisited][locale]
            }
          >
            <ParagraphDiv>
              {formatMessage({ id: 'makeaClaimPopUpText' })}
            </ParagraphDiv>
          </ModalPopUp>
        </>
      ) : (
        <></>
      )}
      {isGIPolicy && (
        <ModalPopupGI
          linkLabel={formatMessage({
            id: 'updatePaymentInstructionPopupTitle'
          })}
          isModalOpen={isPaymentInstructionModalOpen}
          onClose={updatePaymentInstructionModal}
          const
          footerButtonList={[
            {
              className: 'redBtn',
              label: formatMessage({
                id: 'updatePaymentInstructionClosePopupButton'
              }),
              id: 'close_btn',
              align: 'left'
            }
          ]}
          btnHandler={{
            close_btn: updatePaymentInstructionModal
          }}
        />
      )}
    </>
  );
};

export default ServicingOption;

ServicingOption.contextTypes = {
  intl: PropTypes.object.isRequired
};

ServicingOption.propTypes = {
  servicingOptionsURL: PropTypes.object.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  noMarginBottom: PropTypes.bool,
  encodedProductId: PropTypes.string.isRequired,
  currentPolicyDetail: PropTypes.object,
  tealiumSectionHeading: PropTypes.string.isRequired,
  superFamily: PropTypes.string.isRequired,
  setIconWidthHeight: PropTypes.bool,
  customerWhiteListedForDigiServiceLinks: PropTypes.object,
  langObj: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired,
  calledFrom: PropTypes.string.isRequired
};

ServicingOption.defaultProps = {
  noMarginBottom: false,
  currentPolicyDetail: null,
  setIconWidthHeight: false,
  customerWhiteListedForDigiServiceLinks: {}
};
