import styled from 'styled-components';
import { rem } from 'polished';
import media from '../../../utils/media';
import { SectionRow } from '../../../common/styles/commonStyle';
import theme from '../../../common/styles/theme';
import { FontFamily } from '../../../utils/font';

/**
 * Error/Warning css used across application
 */

export const InstructionHistoryExceptionWrapper = styled.div`
  padding-top: 15px;
`;

export const PolicyNameNumberWrapper = styled.div`
  margin-bottom: 20px;
`;

export const PolicyNameRow = styled.div`
  display: block;
  margin: ${rem('25px')} 0 ${rem('20px')} 0;
`;

export const PolicyNameHeader = styled.h1`
  font-size: ${rem(`38px`)};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('46px')};
  ${FontFamily.LightFontW01};
  ${media.phone`
    font-size: ${rem(`28px`)};
    line-height: ${rem('38px')};
  `};
`;

export const PolicyNumberRow = styled.div`
  display: block;
  margin-bottom: ${rem('15px')};
`;

export const PolicyFieldWrapper = styled.dl`
  width: 100%;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  ${FontFamily.RegularFontW01};
  font-size: ${rem('16px')};
  color: ${theme.fontColor};
  ${media.phone`
    font-size: ${rem('14px')};
  `}
`;

export const PolicyNumberLabel = styled.dt`
  margin: 0;
  width: 100%;
  flex-grow: 1;
  text-align: left;
`;

export const PolicyNumberValue = styled.dd`
  ${FontFamily.BoldFontW01};
  margin-top: 5px;
  width: auto;
  flex-grow: 1;
  text-align: right;
  word-break: break-word;
  color: ${theme.fontColor};
`;

export const NotificationMessageWrapper = styled(SectionRow)`
  padding: ${rem('17px')} ${rem('50px')} ${rem('7px')};
  ${media.tablet`
    padding: ${rem('17px')} ${rem('24px')} ${rem('7px')};
  `};
`;
export const ImagePath = styled.img`
  height: ${rem('21px')};
  width: ${rem('21px')};
  vertical-align: bottom;
  padding-right: ${rem('10px')};
`;

export const NotificationMessageIcon = styled.div`
  display: flex;
  align-items: normal;
  padding-bottom: ${rem('5px')};
`;
export const NotificationWrapper = styled.div`
  margin-top: ${rem('41px')};
  ${media.tablet`
    margin-top: ${rem('31px')};
  `};
  ${media.phone`
    margin-top: ${rem('30px')};
  `};
`;

export const NotificationMessageType = styled.div`
  display: block;
  font-size: ${rem('14px')};
  padding: ${rem('15px')};
  margin: 0 0 ${rem('25px')} 0;
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor};
`;

export const NotificationMessageErrorType = styled.div`
  display: block;
  font-size: ${rem('14px')};
  padding: ${rem('15px')};
  background-color: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor};
`;

export const NotificationIconSpan = styled.span`
  display: block;
  height: ${rem('18px')};
  width: ${rem('18px')};
  margin-right: ${rem('5px')};
`;

export const NotificationMessage = styled.div`
  span {
    font-size: ${rem('14px')};
    line-height: ${rem('22px')}
    display: block;
    ${media.phone`
    display: contents;
    `}
  }  
`;

// servicing bread crumb style

export const DetailsNotification = styled.div`
  display: block;
  background-color: ${theme.whiteColor};
  padding-bottom: 30px;
`;

export const NotificationRow = styled.div`
  display: block;
  margin-bottom: 0;
`;
export const LeftNotificationRow = styled.nav`
  padding: ${rem('20px')} 0 ${rem('15px')} 0;
  letter-spacing: 0;
  font-size: ${rem('14px')};
  ${media.phone`
    font-size: ${rem('12px')};
    padding: ${rem('20px')} 0 ${rem('15px')} 0;
  `};
`;

export const NotificationBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.detailNotificationBorder};
`;
export const RightNotificationRow = styled.div`
  line-height: ${rem('20px')};
  font-size: ${rem('14px')};
  position: relative;
`;

export const ButtonWrapperOL = styled.ol`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  span {
    color: ${theme.fontColor};
    ${media.phone`
      line-height : ${rem('18px')}
    `}
  }
`;

export const BreadCrumbLink = styled.a`
  display: flex;
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: #333;
  &:hover,
  &:focus {
    outline: 1px dotted black;
  }
  ${media.phone`
    padding-bottom:3px;
  `}
`;

export const ListLI = styled.li`
  ${props =>
    props.currentPage
      ? `${FontFamily.BoldFontW01}`
      : `${FontFamily.LightFontW01}`};
  padding:0;
  margin:0;
  cursor: ${props => (props.currentPage ? `default` : 'pointer')};
  display:flex;
  border: none;
  text-decoration: none;
  &:focus {
    outline: 1px dotted black;
  }
  > a{
    text-decoration: none;
  }
  ${media.phone`
    padding-bottom:3px;
  `}
  }
`;

export const ImageSpan = styled.span`
  height: ${rem('10px')};
  width: ${rem('6px')};
  margin: 0 ${rem('10px')};
  display: block;
`;

export const ImageIconPath = styled.img`
  width: 100%;
  height: 100%;
`;
