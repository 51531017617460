import * as _ from 'lodash';

const specialCaseResolver = function specialCaseResolver(
  dependentFieldData,
  specialCaseText
) {
  /*
    dependentFieldData will be the hospitalClaimRiderDuration field with value as "Per Day" for now
    specialCaseText will be the text having value as "specialCase"
  */

  return _.camelCase(`${specialCaseText}${dependentFieldData}`);
};

export default specialCaseResolver;
