import React from 'react';
import PropTypes from 'prop-types';
import SuperProductFamily from './SuperProductFamilyBuilder';

const ComponentsWrapper = (props, context) => {
  const {
    productFamilyOrder = [],
    superCategoryStack,
    subProductCategories,
    policyRowCount,
    notificationMessages,
    eWelcomePack
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  /**
   * Finding a stackLayout in the form of Array which would contain Array of Super Category Objects
   * superCategoryStack - Object with supercategories as keys and value as products under it
   * <SuperProductFamily/> React stateless component which renders super category stack one by one on summary page.
   */

  const stackLayout = productFamilyOrder.map(superCategory => {
    return (
      <SuperProductFamily
        key={superCategory}
        eWelcomePack={eWelcomePack}
        customerProductList={superCategoryStack[superCategory]}
        superCategory={superCategory}
        superCategoryName={formatMessage({ id: superCategory })}
        subProductCategories={subProductCategories}
        policyRowCount={policyRowCount}
        notificationMessages={notificationMessages}
        categoryStackIndex={Object.keys(superCategoryStack).indexOf(
          superCategory
        )}
      />
    );
  });

  return <>{stackLayout}</>;
};

ComponentsWrapper.contextTypes = {
  intl: PropTypes.object.isRequired
};

ComponentsWrapper.propTypes = {
  productFamilyOrder: PropTypes.array.isRequired,
  superCategoryStack: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  policyRowCount: PropTypes.number.isRequired,
  eWelcomePack: PropTypes.object.isRequired
};

export default ComponentsWrapper;
