import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  setEWelcomeDocumentsErrorsWarnings,
  endLoaderEWelcome,
  startLoaderEWelcome,
  setEWelcomePolicyDocumentsDetails
} from '../app/appActions';
import { E_WELCOME_GET_DOCUMENTS } from '../actionTypes';
import {
  policyDocumentsSagaErrorHandler,
  prepareEWelcomeGetDocumentAPIConfig,
  prepareEWelcomePolicyDocumentsStorePayload
} from '../utils/sagaUtils';

const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* eWelcomePolicyDocuments(action) {
  const {
    payload: { encodePolicyNumber, policy }
  } = action;
  try {
    const configurationObj = yield select(getConfigurations);
    const saasAuth = yield select(getSaasToken);
    const {
      genericConfig: { commonHeaders, addnCustomersHeader },
      serviceConfig: { eWelcomeDocuments }
    } = configurationObj;
    const idcHeaders = {
      Authorization: `Bearer ${saasAuth}`,
      ...commonHeaders,
      ...addnCustomersHeader,
      'content-type': 'application/json',
      'X-HSBC-Global-Channel-Id': 'OHI',
      'Accept-Language': 'en-US,en;q=0.9'
    };

    let { planCode, policyNumber } = policy;
    if (!planCode || !policyNumber) {
      const {
        policyOverview: { planCode: productCode, policyNumber: policyNum }
      } = policy;
      planCode = productCode;
      policyNumber = policyNum;
    }

    const requestPayload = {
      paginationRequest: {
        startRecordIndex: 1,
        searchRecordCount: 1000
      },
      searchConditionList: [
        {
          docProperty: {
            propertyName: 'policyNumber',
            dataType: 'String',
            stringValue: policyNumber
          },
          operator: '='
        },
        {
          docProperty: {
            propertyName: 'productCode',
            dataType: 'String',
            stringValue: planCode
          }
        }
      ]
    };

    const getDocumentsConfig = prepareEWelcomeGetDocumentAPIConfig(
      encodePolicyNumber,
      eWelcomeDocuments,
      idcHeaders,
      requestPayload
    );

    yield put(startLoaderEWelcome());
    const documentResponse = yield call(axios, getDocumentsConfig); // add request payload
    const { data } = documentResponse;
    const policyDocumentsData = prepareEWelcomePolicyDocumentsStorePayload(
      encodePolicyNumber,
      data
    );
    yield put(setEWelcomePolicyDocumentsDetails(policyDocumentsData));
    yield put(endLoaderEWelcome());
  } catch (err) {
    const errorDispatchObject = policyDocumentsSagaErrorHandler(
      encodePolicyNumber,
      err
    );
    yield put(setEWelcomeDocumentsErrorsWarnings(errorDispatchObject));
    yield put(endLoaderEWelcome());
  }
}

export default function* eWelcomeDocumentsSaga() {
  yield takeEvery(E_WELCOME_GET_DOCUMENTS, eWelcomePolicyDocuments);
}
