import { css } from 'styled-components';

export const sizes = {
  miniPhone: 479,
  phone: 667,
  phoneToMiniTablet: 720,
  mediumTablet: 959,
  tablet: 1024,
  miniDesktop: 1181,
  desktop: 1280,
  largeDesktop: 2500
};

const media = Object.keys(sizes).reduce((finalMedia, size) => {
  return {
    ...finalMedia,
    [size](...args) {
      return css`
        @media (max-width: ${sizes[size]}px) {
          ${css(...args)};
        }
      `;
    }
  };
}, {});

export default media;
