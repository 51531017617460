import React, { useState } from 'react';
import PropTypes from 'prop-types';
import chevronUp from '../../assets/images/cevrron-up.svg';
import chevronDown from '../../assets/images/cevrron-down.svg';
import {
  ToggleSectionWrapper,
  ToggleTitle,
  ToggleImgWrapper,
  ToggleDescription
} from '../sections/SectionStyle';
import { AccessibleHidden } from '../../common/styles/commonStyle';
import { trackEvents } from '../../utils/tealiumUtils';
import { ImagePath } from '../detailPageStyle';
import getContentInSpecificLang from '../../utils/localeUtils';

const ToggleSection = function ToggleSection(props, context) {
  const {
    children,
    sectionTitleFormat,
    expandedSectionId,
    titleId,
    tealiumEventContent,
    langObj
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  const [isOpen, toggleAccordion] = useState(false);

  const toggleSectionTitleLangSpecific = getContentInSpecificLang(
    tealiumEventContent,
    'en',
    langObj
  );

  return (
    <ToggleSectionWrapper>
      <ToggleTitle
        role="region"
        onClick={() => {
          toggleAccordion(!isOpen);
          trackEvents({
            eventName: 'captureAccordian',
            eventContent: toggleSectionTitleLangSpecific.toLowerCase()
          });
        }}
        aria-controls={expandedSectionId}
        aria-expanded={isOpen}
        id={titleId}
      >
        {sectionTitleFormat && sectionTitleFormat.map(component => component)}
        <AccessibleHidden aria-hidden={isOpen} toHide={isOpen}>
          {formatMessage({ id: 'SelectToExpand' })}
        </AccessibleHidden>
        <AccessibleHidden aria-hidden={!isOpen}>
          {formatMessage({ id: 'SelectToCollapse' })}
        </AccessibleHidden>
        <ToggleImgWrapper data-attribute="img">
          {isOpen ? (
            <ImagePath aria-hidden="true" alt="" src={chevronUp} />
          ) : (
            <ImagePath aria-hidden="true" alt="" src={chevronDown} />
          )}
        </ToggleImgWrapper>
      </ToggleTitle>
      {isOpen && (
        <ToggleDescription
          id={expandedSectionId}
          aria-labelledby={titleId}
          aria-hidden={!isOpen}
        >
          {children}
        </ToggleDescription>
      )}
    </ToggleSectionWrapper>
  );
};

ToggleSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  sectionTitleFormat: PropTypes.array.isRequired,
  expandedSectionId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  tealiumEventContent: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired
};

ToggleSection.contextTypes = {
  intl: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};
export default ToggleSection;
