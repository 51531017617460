import React from 'react';
import PropTypes from 'prop-types';
import {
  SectionLink,
  SectionLabel,
  SectionIconWrapper,
  ImagePath,
  SectionIconRightWrapper
} from '../detailPageStyle';

const ClaimWrapper = props => {
  const {
    id,
    ariaLabel,
    onClick,
    iconSrc,
    linkLabel,
    iconRight,
    linkRef,
    href
  } = props;
  return (
    <SectionLink
      href={href}
      id={id}
      aria-label={ariaLabel}
      onClick={onClick}
      rel="noopener noreferrer"
      ref={linkRef}
    >
      <SectionIconWrapper>
        <ImagePath aria-hidden="true" alt="" src={iconSrc} />
      </SectionIconWrapper>
      <SectionLabel>{linkLabel}</SectionLabel>
      <SectionIconRightWrapper>
        <ImagePath aria-hidden="true" alt="" src={iconRight} />
      </SectionIconRightWrapper>
    </SectionLink>
  );
};

export default ClaimWrapper;

ClaimWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkLabel: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  iconRight: PropTypes.string,
  linkRef: PropTypes.object.isRequired,
  href: PropTypes.string
};
ClaimWrapper.defaultProps = {
  iconRight: '',
  href: '#'
};
