import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';

const PaymentInfoPayerField = props => {
  const { labelContent, fieldData } = props;
  if (
    !labelContent ||
    !fieldData ||
    typeof fieldData !== 'object' ||
    !fieldData.length
  ) {
    return <></>;
  }

  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        {fieldData.map(name => {
          return (
            <DataTextBold name={name} key={name}>
              {name}
            </DataTextBold>
          );
        })}
      </OverviewInfoFieldDataValue>
    </PaymentInfoFieldWrapper>
  );
};

PaymentInfoPayerField.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.array.isRequired
};

export default PaymentInfoPayerField;
