import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldAmountCurrency,
  LevyTextWrapper
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';
import { getPaymentMode } from '../../../utils/tealiumUtils';
import getContentInSpecificLang from '../../../utils/localeUtils';

const PremiumAmountField = (props, context) => {
  const {
    id,
    fieldData,
    dependentFieldData,
    secondDependentFieldData,
    thirdDependentFieldData,
    toolTipContent,
    langObj
  } = props;
  const {
    intl: { messages, formatMessage }
  } = context;
  let labelContent = '';

  if (
    dependentFieldData &&
    dependentFieldData.key &&
    secondDependentFieldData &&
    secondDependentFieldData.key
  ) {
    if (
      messages[
        `premium_label_${secondDependentFieldData.key}${dependentFieldData.key}`
      ]
    ) {
      labelContent = `premium_label_${secondDependentFieldData.key}${dependentFieldData.key}`;
    } else {
      /**
       * In case the combination of Payment Mode isn't available in Application's content,
       * fallback kicks in to show just "Premium" as a label.
       */

      labelContent = messages[`premium_label_${dependentFieldData.key}`]
        ? `premium_label_${dependentFieldData.key}`
        : 'premium_label';
    }
  } else {
    labelContent = 'premium_label';
  }

  if (
    thirdDependentFieldData &&
    (thirdDependentFieldData.trim() === 'AGG' ||
      thirdDependentFieldData.trim() === 'S01')
  ) {
    labelContent = `premium_label_${thirdDependentFieldData.trim()}`;
  }
  if (!fieldData.currency || !fieldData.amount || fieldData.amount === 0) {
    return <></>;
  }

  const paymentModeKeyLangSpecific = getContentInSpecificLang(
    labelContent,
    'en',
    langObj
  );
  getPaymentMode(paymentModeKeyLangSpecific);

  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper
        {...props}
        id={id}
        labelContent={labelContent}
        toolTipContent={toolTipContent}
      />
      <OverviewInfoFieldAmountCurrency>
        <Currency currencyCode={fieldData.currency} amount={fieldData.amount} />
      </OverviewInfoFieldAmountCurrency>
      <LevyTextWrapper>
        {formatMessage({ id: 'premium_levy_text' })}
      </LevyTextWrapper>
    </PaymentInfoFieldWrapper>
  );
};

PremiumAmountField.propTypes = {
  id: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.object,
  secondDependentFieldData: PropTypes.object,
  thirdDependentFieldData: PropTypes.string,
  langObj: PropTypes.object.isRequired
};

PremiumAmountField.defaultProps = {
  toolTipContent: null,
  dependentFieldData: null,
  secondDependentFieldData: null,
  thirdDependentFieldData: ''
};

PremiumAmountField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PremiumAmountField;
