import styled from 'styled-components';
import { rem } from 'polished';
// import theme from '../../../common/styles/theme';
import media from '../../../utils/media';
import { FontSize, FontFamily } from '../../../utils/font';

export const TransactionWrapper = styled.div`
  display: block;
  max-height: 100%;
`;

export const TransactionSubheader = styled.span`
  ${FontFamily.RegularFontW01};
  ${FontSize.size6};
`;

export const TransactionContactText = styled.h3`
  ${FontFamily.BoldFontW01};
  margin-top: 40px;
  ${props =>
    props.pendingTransactions &&
    `
    margin: 20px 0 10px 0;
  `}
`;

export const ImgStyle = styled.img`
  margin-right: 8px;
  vertical-align: text-bottom;
`;

export const ImagePath = styled.img`
  height: 100%;
  width: 100%;
`;

export const HeaderText = styled.div`
  margin-bottom: 8px;
  ${FontSize.size5};
`;

export const ContactNumber = styled.div`
  ${FontFamily.BoldFontW01};
  ${FontSize.size4};
`;

export const ContactDay = styled.div`
  ${FontFamily.RegularFontW01};
  ${FontSize.size5};
  width: 260px;
  ${media.phone`
   width:70%;
  `}
`;

export const TransactionModalBody = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  ${media.tablet`
    margin-top: ${rem('25px')};
`}
  ${media.phone`
    display: block;
    margin-top : ${rem('20px')};
`}
`;

export const TransactionModalLeft = styled.div`
  width: 40%;
  ${media.tablet`
    width:45%;
  `} 
  ${media.mediumTablet`
    padding-right: ${rem('20px')};
  `} 
  ${media.phone`
    width:100%;
    padding:0;
  `};
`;

export const TransactionModalRight = styled.div`
  ${media.tablet`
    width:45%;
  `}
  ${media.phone`
    width:100%;
  `}
`;

export const TransactionModalImage = styled.div`
  width: 202px;
  height: 123px;
  ${media.mediumTablet`
      width:308px;
      height:187px;
      padding-top:${rem('20px')};
    `}
  ${media.phone`
      width:100%;
      padding:0;
    `}
`;

export const ContactCardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
  width: 100%;
  ${media.tablet`
      flex-direction:column;
      margin-top: 45px;
    `}
  ${media.phone`
      margin-top: 10px;
    `}
`;
