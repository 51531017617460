import React, { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CurrencyWithSymbol from '../currency/CurrencyWithSymbol';
import { truncDigits } from '../../utils/formatter';
import {
  ReprojectionTable,
  TableHead,
  TableBody,
  TableRow,
  TableData,
  TableFooter,
  DataSpan,
  HeaderSpan,
  TableDataCollapsable,
  TableDataContainer,
  DataCollapsableParent,
  DataCollapsableHeader,
  CollapsableSpanHeader,
  CollapsableSpanData,
  ChevronImage,
  HeaderDiv,
  TableDataValueSpan,
  CollapsableDivData,
  TableCaptionMobile,
  HeaderTh,
  TableDataTd,
  AccordionButton,
  TrAccordion,
  TdAccordion,
  TableHeaderMobile,
  TableHeaderText,
  CurrencyLabel,
  CurrencySymbol,
  CurrencyFormat
} from '../styles/reprojectionTableStyle';
import TableShowMoreLessButtonIcon from './TableShowMoreLessButtonIcon';
import chevronUp from '../../assets/images/reprojections/chevronUp.svg';
import chevronDown from '../../assets/images/reprojections/chevronDown.svg';
import chevronDisabled from '../../assets/images/reprojections/chevronDisabled.svg';
import addIcon from '../../assets/images/reprojections/Add.svg';
import subtractIcon from '../../assets/images/reprojections/Subtract.svg';
import { AccessibleHidden } from '../styles/commonStyle';
import { trackEvents } from '../../utils/tealiumUtils';
import { displayIfNull } from '../util/commonUtil';

const commonCheker = function commonCheker(args) {
  const [policyCurrency, fieldData, allowDecimals] = args;
  return !!policyCurrency && typeof fieldData === 'number' && allowDecimals;
};

const getAllowedDecimalData = function getAllowedDecimalData(args) {
  const [policyCurrency, fieldData, allowDecimals, formatMessage] = args;
  if (
    fieldData <= 0 &&
    commonCheker([policyCurrency, fieldData, allowDecimals])
  ) {
    return (
      <>
        <span aria-hidden="true">-</span>
        <AccessibleHidden>
          {formatMessage({ id: 'tableEmptyData' })}
        </AccessibleHidden>
      </>
    );
  }
  if (
    fieldData > 0 &&
    commonCheker([policyCurrency, fieldData, allowDecimals])
  ) {
    return (
      <CurrencyWithSymbol
        amount={fieldData}
        currencyCode={policyCurrency}
        decimalRange={2}
      />
    );
  }
  return null;
};

const subSectionsGenerator = function subSectionsGenerator(
  context,
  columnRow,
  rowData,
  subSectionFieldCount,
  invalidFieldsInSubSection
) {
  const {
    intl: { formatMessage }
  } = context;
  let invalidFieldsCount = invalidFieldsInSubSection;
  const subSections = _.map(columnRow.columns, subHeader => {
    const {
      fieldData,
      policyCurrency,
      secondaryDataPrefix,
      fieldDataSecondary
    } = rowData[subHeader.accessor];

    const { allowDecimals } = subHeader;
    let isNotValidValue = fieldData ? fieldData <= 0 : true;
    if (!allowDecimals) {
      isNotValidValue = fieldData ? truncDigits(fieldData, 0) <= 0 : true;
    }

    if (isNotValidValue) {
      invalidFieldsCount += 1;
    }

    return (
      subHeader &&
      subHeader.Header &&
      typeof fieldData === 'number' &&
      !isNotValidValue && (
        <React.Fragment key={subHeader.Header}>
          <CollapsableSpanHeader id={`dt_${subHeader.Header}`}>
            {formatMessage({
              id: subHeader.Header
            })}
          </CollapsableSpanHeader>
          <CollapsableDivData
            id={`dd_${subHeader.Header}`}
            role="definition"
            aria-labelledby={`dt_${subHeader.Header}`}
          >
            <CollapsableSpanData id="currencyWithSymbol">
              <CurrencyWithSymbol
                amount={fieldData}
                currencyCode={policyCurrency}
                decimalRange={allowDecimals ? 2 : 0}
              />
              {policyCurrency &&
                secondaryDataPrefix &&
                typeof fieldDataSecondary === 'number' &&
                truncDigits(fieldDataSecondary, 0) > 0 && (
                  <TableDataValueSpan id="secondaryDataPrefix">
                    <FormattedMessage
                      id={secondaryDataPrefix}
                      values={{
                        fieldSecondary: (
                          <CurrencyWithSymbol
                            amount={fieldDataSecondary}
                            currencyCode={policyCurrency}
                            decimalRange={0}
                          />
                        )
                      }}
                    />
                  </TableDataValueSpan>
                )}
            </CollapsableSpanData>
          </CollapsableDivData>
        </React.Fragment>
      )
    );
  });
  /**
   * subsectionFieldCount => no of fields after the accordian is expanded(guranteed, nonGuranteed, bonus, total)
   * invalidFieldsCount => no of fields with values less than equal to 0 after being truncated.
   * subSectionFieldCount - 1 => '-1' is added becasue if subSectionFieldCount is one or one more than validFieldsCount, then the entire block is suppressed.
   */
  return (
    subSectionFieldCount - 1 > invalidFieldsCount && (
      <DataCollapsableParent id={`dataCollapsableParent_${columnRow.Header}`}>
        <DataCollapsableHeader id={`dataCollapsableHeader_${columnRow.Header}`}>
          <strong>{formatMessage({ id: columnRow.Header })}</strong>
        </DataCollapsableHeader>
        {subSections}
      </DataCollapsableParent>
    )
  );
};

const getExpandedAccordionDetails = function getExpandedAccordionDetails(
  context,
  columns,
  rowData
) {
  return _.map(columns, columnRow => {
    const subSectionFieldCount = columnRow.columns
      ? columnRow.columns.length
      : 0;
    const invalidFieldsInSubSection = 0;
    return (
      !columnRow.accessor &&
      subSectionFieldCount > 0 && (
        <Fragment key={columnRow.Header}>
          {subSectionsGenerator(
            context,
            columnRow,
            rowData,
            subSectionFieldCount,
            invalidFieldsInSubSection
          )}
        </Fragment>
      )
    );
  });
};

const isValidFieldData = function isValidFieldData(cellData) {
  const { fieldData, allowDecimals } = cellData;
  if (fieldData) {
    if (allowDecimals && fieldData > 0) {
      return 1;
    }
    if (!allowDecimals && truncDigits(fieldData > 0)) {
      return 1;
    }
  }
  return 0;
};
const checkIfChevronIsDisabledHelper = function checkIfChevronIsDisabledHelper(
  columns,
  rowData
) {
  let count = 0;
  for (let i = 0; i < columns.length; i += 1) {
    const { accessor } = columns[i];
    if (rowData[accessor]) {
      const cellData = rowData[accessor];
      if (cellData) {
        const toAdd = isValidFieldData(cellData);
        count += toAdd;
      }
    }
  }
  return count > 1;
};

const checkIfChevronIsDisabled = function checkIfChevronIsDisabled(
  columns,
  rowData
) {
  for (let i = 0; i < columns.length; i += 1) {
    const { columns: subColumn } = columns[i];
    if (
      subColumn &&
      subColumn.length > 0 &&
      checkIfChevronIsDisabledHelper(subColumn, rowData)
    ) {
      return false;
    }
  }
  return true;
};

const footerSection = function footerSection(args) {
  const footer = [];
  const [
    totalSize,
    maxRowCount,
    pageSize,
    columns,
    displayRowHandler,
    showMoreLessButtonDetails,
    formatMessage,
    selectedTabName
  ] = args;
  if (totalSize > maxRowCount) {
    footer.push(
      <TableRow>
        <TableData
          tabIndex={0}
          role="button"
          id={`showMoreLess_Button_${selectedTabName}`}
          aria-expanded={pageSize !== maxRowCount}
          data-testid="tablemobile-tabledata"
          colSpan={columns && columns.length}
          onClick={() => displayRowHandler(showMoreLessButtonDetails.title)}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              displayRowHandler(showMoreLessButtonDetails.title);
            }
          }}
          aria-describedby="showText"
        >
          <AccessibleHidden id="showText" role="alert" aria-live="assertive">
            {pageSize !== maxRowCount
              ? `${formatMessage({ id: 'showLessAriaLabelTable' })}`
              : `${formatMessage({ id: 'showMoreAriaLabelTable' })}`}
          </AccessibleHidden>
          <TableShowMoreLessButtonIcon
            iconSrc={showMoreLessButtonDetails.srcIcon}
            expanded={pageSize !== maxRowCount}
            title={showMoreLessButtonDetails.title}
            testId="tablemobile-showmorelessbuttonicon"
          />
        </TableData>
      </TableRow>
    );
  }
  return footer;
};

const headerSection = function headerSection(args) {
  const [columns, accessor, widthModulator, formatMessage] = args;
  const tableHeader = [];
  tableHeader.push(
    <TableRow id="headerId">
      <>
        {columns.map((column, index) => {
          if (column.accessor) {
            accessor.push(column.accessor);
          } else {
            accessor.push(column.fieldMobile);
          }
          return (
            <HeaderTh
              index={index}
              widthModulator={widthModulator - 1}
              id={`header_${column.Header}`}
            >
              <HeaderDiv
                data-testid="tablemobile-headerdiv"
                fieldType={column.fieldType}
                index={index}
                key={column.Header}
              >
                <HeaderSpan index={index}>
                  {formatMessage({ id: column.Header })}
                </HeaderSpan>
              </HeaderDiv>
            </HeaderTh>
          );
        })}
      </>
    </TableRow>
  );
  return tableHeader;
};

const rowDataFormatter = function rowDataFormatter(rowData, accessor) {
  const cellData = [];
  _.map(accessor, dataKey => {
    return cellData.push(rowData[dataKey]);
  });
  return cellData;
};

const getAccordian = args => {
  const [idx, isChevronDisabled, rowIndex] = args;
  if (isChevronDisabled) {
    return chevronDisabled;
  }
  return rowIndex !== idx ? chevronDown : chevronUp;
};

const bodySection = function bodySection(args) {
  const tableBody = [];
  const [
    data,
    accessor,
    rowIndex,
    formatMessage,
    context,
    columns,
    toggleAccordion,
    widthModulator,
    selectedTabName
  ] = args;
  data.map((rowData, i) => {
    const cellData = rowDataFormatter(rowData, accessor);
    let keySuffix = `${i}`;
    let currentCellIndex = -1;
    const isChevronDisabled = checkIfChevronIsDisabled(columns, rowData);

    const handleKeyDown = (event, index) => {
      if (event.keyCode === 13) {
        toggleAccordion(index, data, isChevronDisabled);
      }
    };

    tableBody.push(
      <>
        <TableRow
          data-testid="tablemobile-tablerow"
          selectedRow={rowIndex === i}
          key={`tableRow_${rowIndex}_${keySuffix}`}
          id={`tbody_${i}`}
          onClick={() => toggleAccordion(i, data, isChevronDisabled)}
          aria-expanded={rowIndex === i}
          data-toggle="collapse"
          aria-controls={`accordion_${i}`}
          onKeyDown={e => handleKeyDown(e, i)}
        >
          <>
            {cellData.map((displayData, index) => {
              currentCellIndex += 1;
              keySuffix = `${i}_${index}`;
              const {
                fieldData,
                policyCurrency,
                fieldType,
                secondaryDataPrefix,
                fieldDataSecondary,
                allowDecimals
              } = displayData;
              const parentHeaderValue = columns[currentCellIndex].Header;
              const parentHeader = displayIfNull(
                parentHeaderValue,
                '',
                `header_${parentHeaderValue}`
              );
              return (
                <TableDataTd
                  headers={parentHeader}
                  type={fieldType}
                  index={index}
                  widthModulator={widthModulator - 1}
                  expanded={rowIndex === i}
                >
                  <TableDataContainer
                    index={index}
                    type={fieldType}
                    key={`${displayData}_${keySuffix}`}
                  >
                    {index === 0 && (
                      <AccordionButton
                        id={`accordionButton_${selectedTabName}_${i}`}
                        onClick={() =>
                          toggleAccordion(i, data, isChevronDisabled)
                        }
                        aria-expanded={rowIndex === i}
                        data-toggle="collapse"
                        aria-controls={`accordion_${i}`}
                        onKeyDown={e => handleKeyDown(e, i)}
                        aria-label={
                          rowIndex === i
                            ? `${formatMessage({
                                id: 'showLessAriaLabelButton'
                              })}`
                            : `${formatMessage({
                                id: 'showMoreAriaLabelButton'
                              })}`
                        }
                      >
                        <ChevronImage
                          src={getAccordian([i, isChevronDisabled, rowIndex])}
                          data-testid={`tablemobile-chevronimage-${i}`}
                          alt=""
                          aria-hidden="true"
                        />
                      </AccordionButton>
                    )}
                    <DataSpan selected={rowIndex === i}>
                      {policyCurrency &&
                        typeof fieldData === 'number' &&
                        truncDigits(fieldData, 0) <= 0 &&
                        !allowDecimals && (
                          <>
                            <span aria-hidden="true">-</span>
                            <AccessibleHidden>
                              {formatMessage({ id: 'tableEmptyData' })}
                            </AccessibleHidden>
                          </>
                        )}
                      {getAllowedDecimalData([
                        policyCurrency,
                        fieldData,
                        allowDecimals,
                        formatMessage
                      ])}
                      {typeof fieldData !== 'number' &&
                        typeof fieldData !== 'string' && (
                          <>
                            <span aria-hidden="true">-</span>
                            <AccessibleHidden>
                              {formatMessage({ id: 'tableEmptyData' })}
                            </AccessibleHidden>
                          </>
                        )}
                      {policyCurrency &&
                        typeof fieldData === 'number' &&
                        truncDigits(fieldData, 0) > 0 &&
                        !allowDecimals && (
                          <CurrencyWithSymbol
                            amount={fieldData}
                            currencyCode={policyCurrency}
                            decimalRange={allowDecimals ? 2 : 0}
                          />
                        )}
                      {!policyCurrency &&
                        secondaryDataPrefix &&
                        fieldDataSecondary &&
                        formatMessage(
                          { id: secondaryDataPrefix },
                          {
                            fieldPrimary: fieldData,
                            fieldSecondary: fieldDataSecondary
                          }
                        )}
                    </DataSpan>
                  </TableDataContainer>
                </TableDataTd>
              );
            })}
          </>
        </TableRow>

        {rowIndex === i && !isChevronDisabled && (
          <>
            <TrAccordion
              id={`accordion_${i}`}
              aria-labelledby={`accordionButton_${selectedTabName}_${i}`}
            >
              <TdAccordion colSpan={widthModulator}>
                <TableDataCollapsable data-testid="tablemobile-panel">
                  {getExpandedAccordionDetails(context, columns, rowData)}
                </TableDataCollapsable>
              </TdAccordion>
            </TrAccordion>
          </>
        )}
      </>
    );
    return tableBody;
  });
  return tableBody;
};

const checkTableData = function checkTableData(tableData, i) {
  return tableData.length > 0 && typeof tableData[i] === 'object';
};

const rowIndexValue = function rowIndexValue(i, rowIndex) {
  return i === rowIndex ? -1 : i;
};

const TableMobile = (props, context) => {
  const {
    columns,
    data,
    pageSize,
    totalSize,
    displayRowHandler,
    maxRowCount,
    selectedTabName,
    policyPhaseIndicator,
    tableHeaderText,
    policyCurrency,
    symbol
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  const [rowIndex, setRowIndex] = useState(-1);

  const captureAccordion = accordionRow => {
    let tealiumData = {
      fieldData: null,
      fieldDataSecondary: null
    };

    if (accordionRow.payYear) {
      const { payYear } = accordionRow;
      tealiumData = payYear;
    } else {
      const { planYear } = accordionRow;
      tealiumData = planYear;
    }

    if (tealiumData) {
      const { fieldData, fieldDataSecondary } = tealiumData;
      const ageLabel = 'age';
      const tealiumEventContent = `${fieldData} (${ageLabel} ${fieldDataSecondary})`;
      trackEvents({
        eventName: 'captureBenefitsTableAccordion',
        eventContent: tealiumEventContent
      });
    }
    return true;
  };

  const toggleAccordion = (i, tableData, isChevronDisabled = false) => {
    if (checkTableData(tableData, i)) {
      captureAccordion(tableData[i]);
    }
    !isChevronDisabled && setRowIndex(rowIndexValue(i, rowIndex));
  };
  useEffect(() => {
    if (rowIndex !== -1) {
      const selectedRow = `accordionButton_${selectedTabName}_${rowIndex}`;
      const element = document.getElementById(`${selectedRow}`);
      if (element) {
        element.focus();
      }
    }
  });

  const widthModulator = columns.length;

  /**
   * This function is invoked only in mobile view.
   * It is used in mobile view to select only those data from an entire row of data which needs to be displayed
   * @param{rowData} => it represents the entire row of data.
   * @param{accessor} => an array that stores the key names of only those data that needs to be displayed in mobile which helps in extracting the required data only
   * The function returs an array(celldata) that contains the  the data to be displayed
   */

  const accessor = [];

  const showMoreLessButtonDetails = {
    title: 'viewMore',
    srcIcon: addIcon
  };
  if (pageSize !== maxRowCount) {
    showMoreLessButtonDetails.title = 'viewLess';
    showMoreLessButtonDetails.srcIcon = subtractIcon;
  }

  return (
    <ReprojectionTable id={`${selectedTabName}_tableMobile`}>
      <TableCaptionMobile>
        <TableHeaderMobile>
          <TableHeaderText>
            {formatMessage({
              id: tableHeaderText
            })}
          </TableHeaderText>
          {policyCurrency && (
            <CurrencyLabel>
              {formatMessage({ id: 'tablePolicyCurrency' })}
              <CurrencySymbol>
                {symbol} <span>=</span>
              </CurrencySymbol>
              <CurrencyFormat>
                {formatMessage({ id: `${policyCurrency}_CURRENCY` })}
              </CurrencyFormat>
            </CurrencyLabel>
          )}
        </TableHeaderMobile>
      </TableCaptionMobile>
      <TableHead>
        {headerSection([columns, accessor, widthModulator, formatMessage])}
      </TableHead>
      <TableBody>
        {bodySection([
          data,
          accessor,
          rowIndex,
          formatMessage,
          context,
          columns,
          toggleAccordion,
          widthModulator,
          selectedTabName,
          policyPhaseIndicator
        ])}
      </TableBody>
      <TableFooter>
        {footerSection([
          totalSize,
          maxRowCount,
          pageSize,
          columns,
          displayRowHandler,
          showMoreLessButtonDetails,
          formatMessage,
          selectedTabName
        ])}
      </TableFooter>
    </ReprojectionTable>
  );
};

TableMobile.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  displayRowHandler: PropTypes.func.isRequired,
  maxRowCount: PropTypes.number.isRequired,
  selectedTabName: PropTypes.string.isRequired,
  policyPhaseIndicator: PropTypes.bool.isRequired,
  tableHeaderText: PropTypes.string.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  symbol: PropTypes.any.isRequired
};

TableMobile.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TableMobile;
