import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PayoutAnnualAnnuityLegends from './tabsections/templates/PayoutAnnualAnnuityLegends';
import PayoutCumulativeAnnuityLegends from './tabsections/templates/PayoutCumulativeAnnuityLegends';
import LineCharts from '../charts/LineCharts';
import ChartLegends from '../charts/ChartLegends';
import ColumnCharts from '../charts/ColumnCharts';
import CustomInputBox from '../common/customInputBox/CustomInputBox';
import { getChartSeries } from './utils/reprojectionDataUtils';
import { calculateChartTicks } from '../charts/chartUtils';
import ReprojectionImageSection from './ReprojectionImageSection';
import {
  PayoutBenefitLegendsWrapper,
  PayouBenefitRangeLegendsWrapper,
  AgeRangeWrapper,
  InputSectionWrapper,
  InputSectionDiv,
  InputSectionLabel,
  PartitionDiv,
  WarningDiv,
  ImagePath
} from './tabsections/templates/payoutLegendStyle';

import warningIcon from '../assets/images/warningBlue.svg';
import {
  displayIfNull,
  doAND,
  validateDataType
} from '../common/util/commonUtil';
import { truncDigits } from '../utils/formatter';

const generateBuffer = val => {
  let num = truncDigits(val);
  num = num.toString();
  let len = num.length;
  let buffer = 1;
  while (len > 2) {
    buffer *= 10;
    len -= 1;
  }
  return buffer;
};
const _dummyDataGenerator = function _dummyDataGenerator(args) {
  const [rowData, max] = args;
  const dividingFactor = 50;
  return displayIfNull(
    rowData[rowData.length - 2],
    null,
    max / dividingFactor,
    true
  );
};

const extractChartData = function extractChartData(args) {
  const [chartDataItem, label] = args;
  return displayIfNull(chartDataItem[label] <= 0, chartDataItem[label], null);
};

const _dataVerifier = function _dataVerifier(rowData) {
  const len = rowData.length;
  let dataFound = displayIfNull(rowData[len - 2], false, true);
  let idx = len - 4;
  while (!dataFound && idx >= 0) {
    if (typeof rowData[idx] === 'number') {
      rowData.splice(idx, 1, null);
    }
    dataFound = displayIfNull(rowData[idx - 2], false, true);
    idx -= 4;
  }
};
const _generateData = function _generateData(args) {
  let colorIndex = 0;
  const [
    id,
    chartData,
    xAxisField,
    chartLabels,
    max,
    chartColors,
    value,
    data,
    defaultColor
  ] = args;
  _.map(chartData, chartDataItem => {
    const rowData = [];
    let previousKey = '';
    if (chartDataItem[xAxisField]) {
      _.map(chartLabels, chartLabelKey => {
        if (chartLabelKey.label === 'dummyData') {
          rowData.push(_dummyDataGenerator([rowData, max]));
        } else if (chartLabelKey.role === 'style') {
          if (previousKey === 'dummyData') {
            rowData.push('color : transparent');
          } else {
            const colorCode = displayIfNull(
              chartDataItem[xAxisField] <= value,
              defaultColor,
              chartColors[colorIndex % chartColors.length]
            );
            colorIndex += 1;
            rowData.push(`color : ${colorCode}`);
          }
        } else if (
          doAND(
            chartDataItem[chartLabelKey.label] || chartDataItem[chartLabelKey]
          )
        ) {
          const label = displayIfNull(
            id === 'payoutBenefitsChart',
            chartLabelKey,
            chartLabelKey.label
          );
          rowData.push(extractChartData([chartDataItem, label, xAxisField]));
        } else {
          const dataPoint = displayIfNull(
            id === 'payoutBenefitsChart',
            0,
            null
          );
          rowData.push(dataPoint);
        }
        previousKey = chartLabelKey.label;
      });
      if (id === 'payoutBenefitsChart') {
        _dataVerifier(rowData);
      }

      data.push(rowData);
    }
  });
};

const _generateChartFields = function _generateChartFields(args) {
  const [chartFields, chartLabels, chartColors, seriesObj] = args;
  const chartFieldsToPlot = _.filter(chartFields, function getChartFieldsToPlot(
    chartField
  ) {
    return chartField.plotOnChart === true;
  });
  _.map(chartFieldsToPlot, chartField => {
    validateDataType(chartField.label, true) &&
      chartLabels.push(chartField.label);
    validateDataType(chartField.color, true) &&
      chartColors.push(chartField.color);
    validateDataType(chartField.chartType, true) &&
      seriesObj.push(getChartSeries(chartField.chartType, chartField.opacity));
  });
};

const _generatePayoutBenefitsFields = function __generatePayoutBenefitsFields(
  args
) {
  const [chartFields, chartLabels, xAxisField] = args;
  _.map(chartFields, (chartField, idx) => {
    validateDataType(chartField.label) &&
      chartLabels.push({ type: 'number', label: `${chartField.label}` });
    validateDataType(chartField.color) &&
      chartLabels.push({ type: 'string', role: 'style' }); // {role: 'style'} is used to introduce color for the bar
    if (
      doAND(idx !== chartFields.length - 1, chartField.label !== xAxisField)
    ) {
      // no dummy data is required after the last bar and at the start
      chartLabels.push({ type: 'number', label: 'dummyData' }); // adding dummyData to introduce gaap between twpo bars.
      chartLabels.push({ type: 'string', role: 'style' });
    }
  });
};
/**
 * Function to find the max and min Age limit as well the max value considering all the values displayed in the graph
 */
const findMax = function findMax(args) {
  const [chartData, allChartLabels, xAxisField] = args;
  let max = 0;
  let maxAgeLimit = 0;
  let minAgeLimit = 1000000;

  _.map(chartData, chartDataItem => {
    _.map(allChartLabels, chartLabelKey => {
      const dataPoint = chartDataItem[chartLabelKey];
      if (doAND(dataPoint, chartLabelKey !== xAxisField)) {
        max = Math.max(dataPoint, max);
      }
      if (doAND(chartLabelKey === xAxisField, dataPoint)) {
        maxAgeLimit = Math.max(dataPoint, maxAgeLimit);
        minAgeLimit = Math.min(dataPoint, minAgeLimit);
      }
    });
  });
  return [max, maxAgeLimit, minAgeLimit];
};

const getMaxValueForPayoutChart = (
  chartData,
  max,
  allChartLabels,
  xAxisField
) => {
  const fields = allChartLabels;
  let sum = 0;
  const dividingFactor = 50;
  _.map(chartData, chartItem => {
    let tempSum = 0;
    if (chartItem) {
      fields.forEach(value => {
        const fieldValue = chartItem[value];
        if (typeof fieldValue === 'number' && value !== xAxisField) {
          tempSum += fieldValue;
        }
      });
      sum = Math.max(sum, tempSum);
    }
  });
  sum += max / dividingFactor; // adding the dummy white space value
  sum += generateBuffer(sum);
  return Math.ceil(sum);
};

/**
 * Function that return the object of a particular line depending on its type (it its a areaChart/lineChart/dashedChart)
 */

const ReprojectionCharts = function ReprojectionCharts(props, context) {
  const {
    productCategory,
    reprojectionsData,
    chartConfig,
    sectionToRender,
    isNonGuaranteedChecked,
    policyCurrencyFormat,
    id,
    value,
    setValue,
    setCustomerMinPayAge,
    setCustomerMaxPayAge,
    customerMinPayAge,
    customerMaxPayAge,
    displayChkBoxSetter,
    showCheckboxAsPerConfiguration,
    selectedTabName
  } = props;
  const {
    reprojectionDetails: {
      payoutBenefit: { payoutDetails }
    }
  } = reprojectionsData;
  const {
    intl: { formatMessage, locale }
  } = context;

  // get chartObject based on product category from chart configurations
  const chartObject = displayIfNull(
    chartConfig[sectionToRender],
    chartConfig.default
  );

  // get chart fields guaranteed or non-guaranteed based on check box clicked
  const chartFields = displayIfNull(
    isNonGuaranteedChecked,
    chartObject.guaranteedFields,
    chartObject.nonGuaranteedFields
  );
  /**
   * allChartLabels contains all the api key labels that we woukd be plotting on the graph.And this is what would be used to calculate the max values.(Essentially all the Non-Guaranteed Values)
   */
  const allChartLabels = _.map(chartObject.nonGuaranteedFields, 'label');

  // get field parent from charObject to get chartData
  const {
    fieldParent,
    xAxisField,
    legends,
    legendsImagePath,
    defaultColor,
    policyYear
  } = chartObject;

  const chartData = _.get(reprojectionsData, fieldParent);
  let chartLabels = [];
  const chartColors = [];
  const data = [];
  const seriesObj = [];
  let vAxisTicks = [];

  _generateChartFields([chartFields, chartLabels, chartColors, seriesObj]);

  const chartLabelsForMaxValue = displayIfNull(allChartLabels, chartLabels);

  const [max, maxAgeLimit, minAgeLimit] = findMax([
    chartData,
    chartLabelsForMaxValue,
    xAxisField
  ]);

  /**
   * If max value is more than 1000, then we introduce a multiplyingFactor of 1000,
   * This is done to trim the data size, because on the y-axis, data will be represented as (1k, 2k, 3k ...)
   * */
  const val = displayIfNull(value, maxAgeLimit);

  if (id === 'payoutBenefitsChart') {
    setValue(val);
    setCustomerMinPayAge(minAgeLimit);
    setCustomerMaxPayAge(maxAgeLimit);
  }

  const [inputValue, setInputValue] = useState(val);
  const [outOfRange, setOutOfRange] = useState(false);
  const [left, setLeft] = useState(-100);

  useEffect(() => {
    setInputValue(inputValue);
    setValue(value);
  }, [inputValue]);

  if (id === 'payoutBenefitsChart') {
    chartLabels = [];
    _generatePayoutBenefitsFields([chartFields, chartLabels, xAxisField]);
  }

  data.push(chartLabels);

  const hAxisPrefix = `${formatMessage({ id: 'chartXAxisPreffix' })}`;
  const policyCurrency = _.get(reprojectionsData, policyCurrencyFormat);

  /**
   * if in a parcticular data-set returned from backend, a key is absent, then we push 0 in the data to be passed to our charts
   * if the key in the label is DummyData, then a dummy value is entered into it
   * if the key in the label is {role: 'style'}, it signifies color of the previous bar
   * if the previous bar is dummyData, we need to set color as transparent, else from the configurator depending on the slider position
   */

  _generateData([
    id,
    chartData,
    xAxisField,
    chartLabels,
    max,
    chartColors,
    value,
    data,
    defaultColor
  ]);

  const payoutBenefitLegendsData = _.get(payoutDetails, value);
  showCheckboxAsPerConfiguration && displayChkBoxSetter(data.length > 2);
  if (data.length === 2) {
    return <ReprojectionImageSection tabId={id} />;
  }
  // on v-axis, K is added depending on the range of data
  if (data.length > 1 && id !== 'payoutBenefitsChart') {
    vAxisTicks = calculateChartTicks(Math.ceil(max), locale, formatMessage);
    return (
      <div aria-hidden="true">
        <ChartLegends
          includeNonGuranteedPayout={isNonGuaranteedChecked}
          legends={legends}
          imagePath={legendsImagePath}
          selectedTabName={selectedTabName}
        />
        <LineCharts
          data={data}
          rawData={chartData}
          colors={chartColors}
          seriesObj={seriesObj}
          hAxisPrefix={hAxisPrefix}
          chartFields={chartFields}
          imagePath={legendsImagePath}
          xAxisField={xAxisField}
          policyCurrency={policyCurrency}
          tooltipId={id}
          vAxisTicks={vAxisTicks}
          maxAllowedVal={maxAgeLimit}
          selectedTabName={selectedTabName}
        />
      </div>
    );
  }

  if (data.length > 1 && id === 'payoutBenefitsChart') {
    const { annualAnnuityLegends, cumulativeAnnuityLegends } = chartObject;
    vAxisTicks = calculateChartTicks(
      getMaxValueForPayoutChart(
        chartData,
        Math.ceil(max),
        chartLabelsForMaxValue,
        xAxisField
      ),
      locale,
      formatMessage
    );
    return (
      <>
        <div aria-hidden="true">
          <ColumnCharts
            data={data}
            hAxisPrefix={hAxisPrefix}
            value={val}
            setLeft={setLeft}
            setValue={setValue}
            setInputValue={setInputValue}
            vAxisTicks={vAxisTicks}
            tooltipId={id}
            chartFields={chartFields}
            rawData={chartData}
            xAxisField={xAxisField}
            policyCurrency={policyCurrency}
            maxAllowedVal={maxAgeLimit}
          />
        </div>
        <PayouBenefitRangeLegendsWrapper left={left}>
          <AgeRangeWrapper>
            <InputSectionWrapper>
              <InputSectionLabel for="ageInput">
                {formatMessage({ id: 'payoutBenefitAgeLabel' })}
              </InputSectionLabel>
              <CustomInputBox
                setValue={setValue}
                inputValue={inputValue}
                setInputValue={setInputValue}
                maxAllowedVal={maxAgeLimit}
                minAllowedVal={customerMinPayAge}
                setOutOfRange={setOutOfRange}
                policyYearValue={_.get(payoutBenefitLegendsData, policyYear)}
              />
              <PartitionDiv />
              <InputSectionDiv id="policyYear">
                {formatMessage({ id: 'payoutBenefitPolicyYearLabel' })}{' '}
                {_.get(payoutBenefitLegendsData, policyYear)}
              </InputSectionDiv>
            </InputSectionWrapper>
            {outOfRange && (
              <WarningDiv role="alert" aria-live="assertive">
                <ImagePath src={warningIcon} alt="warning-icon" />
                {formatMessage(
                  { id: 'inputBoxWarningLabel' },
                  { minAge: customerMinPayAge, maxAge: maxAgeLimit }
                )}
              </WarningDiv>
            )}
          </AgeRangeWrapper>
          <PayoutBenefitLegendsWrapper>
            <PayoutAnnualAnnuityLegends
              productCategory={productCategory}
              isNonGuaranteedChecked={isNonGuaranteedChecked}
              legends={annualAnnuityLegends}
              policyCurrency={policyCurrency}
              payoutDetails={payoutDetails}
              ageSelection={value}
              customerMaxPayAge={customerMaxPayAge}
            />

            <PayoutCumulativeAnnuityLegends
              isNonGuaranteedChecked={isNonGuaranteedChecked}
              policyCurrency={policyCurrency}
              payoutDetails={payoutDetails}
              ageSelection={value}
              customerMinPayAge={customerMinPayAge}
              customerMaxPayAge={customerMaxPayAge}
              legends={cumulativeAnnuityLegends}
              reprojectionsData={reprojectionsData}
            />
          </PayoutBenefitLegendsWrapper>
        </PayouBenefitRangeLegendsWrapper>
      </>
    );
  }
  return null;
};

ReprojectionCharts.propTypes = {
  productCategory: PropTypes.string.isRequired,
  reprojectionsData: PropTypes.object.isRequired,
  chartConfig: PropTypes.object.isRequired,
  sectionToRender: PropTypes.string.isRequired,
  isNonGuaranteedChecked: PropTypes.bool.isRequired,
  policyCurrencyFormat: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  displayChkBoxSetter: PropTypes.func.isRequired,
  showCheckboxAsPerConfiguration: PropTypes.bool.isRequired,
  selectedTabName: PropTypes.string.isRequired
};

ReprojectionCharts.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReprojectionCharts;
