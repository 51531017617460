import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DetailsPageMiddleSection, ImagePath } from '../detailPageStyle';
import {
  ContactInfoWrapper,
  ContactInfoHeader,
  ContactImgWrapper,
  ImgStyle,
  ContactInfoLabel,
  ContactCardBody,
  ContactCardLeft,
  ContactCardRight,
  ContactCardDetailsGeneralWrapper,
  ContactCardImageGeneralWrapper,
  ContactCardText,
  ContactCardImageGeneral
} from './contactInfoStyles';
import Contactphone from '../../assets/images/Contactphone.svg';
import ContactFallBack from '../../assets/images/illustraion-generic-help.svg';

const ContactInfoGeneral = (props, context) => {
  const { fieldsStack, sectionHeading } = props;
  const {
    intl: { formatMessage }
  } = context;
  const isFieldStack =
    fieldsStack && fieldsStack.length && _.isEmpty(fieldsStack[0].props);
  if (isFieldStack) {
    return null;
  }

  return (
    <DetailsPageMiddleSection>
      <ContactInfoWrapper id="contactInfoGeneralCard">
        <ContactInfoHeader>
          <ContactImgWrapper>
            <ImgStyle aria-hidden="true" alt="" src={Contactphone} />
          </ContactImgWrapper>
          <ContactInfoLabel id="contact_card_title">
            {formatMessage({ id: sectionHeading })}
          </ContactInfoLabel>
        </ContactInfoHeader>
        <ContactCardText>
          {formatMessage({ id: 'contactCardText' })}
        </ContactCardText>
        <ContactCardBody>
          <ContactCardLeft isGeneral>
            <ContactCardImageGeneralWrapper>
              <ContactCardImageGeneral>
                <ImagePath
                  aria-hidden="true"
                  alt={formatMessage({ id: 'decorativeImage' })}
                  src={ContactFallBack}
                />
              </ContactCardImageGeneral>
            </ContactCardImageGeneralWrapper>
          </ContactCardLeft>
          <ContactCardRight isGeneral>
            <ContactCardDetailsGeneralWrapper>
              {fieldsStack}
            </ContactCardDetailsGeneralWrapper>
          </ContactCardRight>
        </ContactCardBody>
      </ContactInfoWrapper>
    </DetailsPageMiddleSection>
  );
};

ContactInfoGeneral.contextTypes = {
  intl: PropTypes.object.isRequired
};

ContactInfoGeneral.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  sectionHeading: PropTypes.string.isRequired
};

export default ContactInfoGeneral;
