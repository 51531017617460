import PropTypes from 'prop-types';
import getDescription from '../../../utils/reducerUtils';

const WhoIsCoveredNarrative = (props, context) => {
  const { fieldData } = props;
  const {
    intl: { locale }
  } = context;
  const fieldDataNarrativeArray =
    fieldData &&
    fieldData.narrative &&
    fieldData.key &&
    fieldData.narrative.length !== 0;
  if (!fieldDataNarrativeArray) {
    return '-';
  }
  return getDescription(locale, fieldData.narrative);
};

WhoIsCoveredNarrative.propTypes = {
  fieldData: PropTypes.object.isRequired
};

WhoIsCoveredNarrative.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default WhoIsCoveredNarrative;
