import React from 'react';
import PropTypes from 'prop-types';
import getDescription from '../../../utils/reducerUtils';
import { PlanDescription } from '../../detailPageStyle';

const BasicPlanDescription = function BasicPlanDescription(props, context) {
  const {
    intl: { messages, formatMessage, locale }
  } = context;

  const { fieldData, productCategoryKey } = props;
  let planDescription = '';

  if (fieldData && fieldData.length) {
    planDescription = getDescription(locale, fieldData, 'description', true);
  } else if (messages[`basicPlanDescription_${productCategoryKey}`]) {
    planDescription = formatMessage({
      id: `basicPlanDescription_${productCategoryKey}`
    });
  }
  if (!planDescription) {
    return <></>;
  }
  return <PlanDescription>{planDescription}</PlanDescription>;
};

BasicPlanDescription.propTypes = {
  fieldData: PropTypes.array,
  productCategoryKey: PropTypes.string
};
BasicPlanDescription.defaultProps = {
  fieldData: null,
  productCategoryKey: ''
};
BasicPlanDescription.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BasicPlanDescription;
