import { put, select, takeEvery } from 'redux-saga/effects';
import { loggerReady } from '../app/appActions';
import { INIT_LOGGER } from '../actionTypes';
import { getBrowserInfo } from '../utils/domUtils';
import UILogger from '../utils/UILogger';

export function* connectLogger(action) {
  const genericConfig = yield select(
    state => state.configurations.genericConfig
  );
  const console = action.payload;
  const customerIdentifier = yield select(
    state => state.customer.customerIdentifier
  );

  UILogger.connect(genericConfig.uiLoggerURL); // Connect UI Logger

  const defaultLogOptions = {
    userAgent: getBrowserInfo(navigator.userAgent),
    level: 'info',
    project: 'Service Dashboard',
    customerIdentifier, // Add after Customer API integration
    logMessage: {}
  };

  /**
   *
   * @param {*} level - enum(String): info, debug etc.
   * @param {*} message -String
   * @description Append to log list
   */
  const consoleGeneric = (
    level,
    functionName,
    fileName,
    lineNumber,
    ...message
  ) => {
    const ENV = process.env.REACT_APP_ENV;
    const data = {
      ...defaultLogOptions,
      level: level || 'error',
      env: ENV,
      journeyType: 'PIB',
      timeStamp: Date(),
      logMessage: {
        message,
        functionName,
        fileName,
        line: lineNumber
      }
    };

    const options = {
      action: 'log', // As Defined in AWS API Gateway
      data
    };
    return UILogger.send(options);
  };

  /**
   *
   * @param {*} level  - enum(String): info, debug etc.
   * @param {*} logData - Object with props message and name[Optional]
   * @description Decides if we need to log to the server based on primary and secondary criteria.
   */
  const addLevelToLogs = (level, logData) => {
    const { message, name, functionName, fileName, lineNumber } = logData;
    const loggerConfig = genericConfig.logger;
    const { loggerEnabled, loggerNamespace } = loggerConfig;
    console.debug(loggerNamespace);
    console.debug(loggerNamespace[name]);
    const secondaryCriteria =
      name && loggerNamespace[name] !== undefined
        ? loggerNamespace[name]
        : loggerNamespace.default;
    if (loggerEnabled && secondaryCriteria) {
      consoleGeneric(level, functionName, fileName, lineNumber, message);
    }
  };

  console.logger = {};

  console.logger.log = logData => {
    const loggerConfig = genericConfig.logger;
    const { enableCloudWatchLog } = loggerConfig;
    if (enableCloudWatchLog) {
      addLevelToLogs('log', logData);
    }
  };

  console.logger.warn = logData => {
    const loggerConfig = genericConfig.logger;
    const { enableCloudWatchWarn } = loggerConfig;
    if (enableCloudWatchWarn) {
      addLevelToLogs('warn', logData);
    }
  };

  console.logger.error = logData => {
    const loggerConfig = genericConfig.logger;
    const { enableCloudWatchError } = loggerConfig;
    if (enableCloudWatchError) {
      addLevelToLogs('error', logData);
    }
  };

  /**
   * Using INFO for debug since no debug in JS
   */
  console.logger.debug = logData => {
    const loggerConfig = genericConfig.logger;
    const { enableCloudWatchDebug } = loggerConfig;
    if (enableCloudWatchDebug) {
      addLevelToLogs('info', logData);
    }
  };

  const _error = console.error;
  const _warn = console.warn;
  const _info = console.info;

  /**
   * Override console error to send log to cloud-watch
   */
  console.error = function errorOverride(...args) {
    setTimeout(() => {
      console.logger.error({
        message: args,
        name: `GenericError`
      });
      return _error.apply(console, args);
    }, 1000);
  };

  /**
   * Override console warn to send log to cloud-watch
   */
  console.warn = function warnOverride(...args) {
    setTimeout(() => {
      console.logger.warn({
        message: args,
        name: `GenericWarning`
      });
      return _warn.apply(console, args);
    }, 1000);
  };

  /**
   * Override console info to send log to cloud-watch
   */
  console.info = function infoOverride(...args) {
    setTimeout(() => {
      console.logger.debug({
        message: args,
        name: `GenericInfo`
      });
      return _info.apply(console, args);
    }, 1000);
  };

  /**
   * Catch all browser error for cloud-watch
   */
  window.onerror = function onerrorOverride(...args) {
    console.logger.error({
      message: args,
      name: `GenericError`
    });
  };
  yield put(loggerReady());
}

export default function* loggerSaga() {
  yield takeEvery(INIT_LOGGER, connectLogger);
}
