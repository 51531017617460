import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CurrencyWithSymbol from '../currency/CurrencyWithSymbol';
import { truncDigits } from '../../utils/formatter';
import {
  ReprojectionTable,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableData,
  TableFooter,
  TableDataFieldSpan,
  TableDataValueSpan,
  TableCaption,
  TableDynamicHeader,
  TableHeaderTextWrapper,
  TableHeaderText,

  CurrencyLabel,
  CurrencySymbol,
  CurrencyFormat
} from '../styles/reprojectionTableStyle';
import { AccessibleHidden } from '../styles/commonStyle';

import TableShowMoreLessButtonIcon from './TableShowMoreLessButtonIcon';

import addIcon from '../../assets/images/reprojections/Add.svg';
import subtractIcon from '../../assets/images/reprojections/Subtract.svg';

const commonCheker = function commonCheker(args) {
  const [policyCurrency, fieldData, allowDecimals] = args;
  return !!policyCurrency && typeof fieldData === 'number' && allowDecimals;
};

const getAllowedDecimalData = function getAllowedDecimalData(args) {
  const [policyCurrency, fieldData, allowDecimals, formatMessage] = args;
  if (
    fieldData <= 0 &&
    commonCheker([policyCurrency, fieldData, allowDecimals])
  ) {
    return (
      <TableDataFieldSpan>
        <span aria-hidden="true">-</span>
        <AccessibleHidden>
          {formatMessage({ id: 'tableEmptyData' })}
        </AccessibleHidden>
      </TableDataFieldSpan>
    );
  }
  if (
    fieldData > 0 &&
    commonCheker([policyCurrency, fieldData, allowDecimals])
  ) {
    return (
      <TableDataFieldSpan>
        <CurrencyWithSymbol
          amount={fieldData}
          currencyCode={policyCurrency}
          decimalRange={2}
        />
      </TableDataFieldSpan>
    );
  }
  return null;
};

const footerSection = function footerSection(args) {
  const [
    data,
    totalSize,
    maxRowCount,
    pageSize,
    formatMessage,
    displayRowHandler,
    showMoreLessButtonDetails,
    selectedTabName
  ] = args;
  const footer = [];
  if (totalSize > maxRowCount) {
    footer.push(
      <TableRow>
        <TableData
          id={`showMoreLess_Button_${selectedTabName}`}
          tabIndex={0}
          role="button"
          aria-expanded={pageSize !== maxRowCount}
          data-testid="tabledesktop-tabledata"
          colSpan={data && data[0] && Object.keys(data[0]).length}
          onClick={() => displayRowHandler(showMoreLessButtonDetails.title)}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              displayRowHandler(showMoreLessButtonDetails.title);
            }
          }}
          aria-describedby={`showText_${selectedTabName}`}
        >
          <AccessibleHidden id={`showText_${selectedTabName}`} role="alert" aria-live="assertive">
            {pageSize !== maxRowCount
              ? `${formatMessage({ id: 'showLessAriaLabelTable' })}`
              : `${formatMessage({ id: 'showMoreAriaLabelTable' })}`}
          </AccessibleHidden>
          <TableShowMoreLessButtonIcon
            testId="tabledesktop-showmorelessbuttonicon"
            iconSrc={showMoreLessButtonDetails.srcIcon}
            expanded={pageSize !== maxRowCount}
            title={showMoreLessButtonDetails.title}
          />
        </TableData>
      </TableRow>
    );
  }
  return footer;
};

const headerSection = function headerSection(args) {
  const headerData = [];
  const [headSections, formatMessage, selectedTabName] = args;
  headSections.map((headSection, index) => {
    headerData.push(
      <TableRow
        id={`${selectedTabName}_headerRow_${index}`}
        key={`tr_${headSection[index].colSpan}_${headSection[index].header}`}
      >
        {headSection.map(headers => {
          return (
            <TableHeader
              data-testid="tabledesktop-tableheader"
              align={headers.align}
              key={`th_${headers.header}`}
              colSpan={headers.colSpan}
              rowSpan={headers.rowSpan}
              nosubcolumns={headers.header.trim().length === 0}
              hideTopBorder={index}
              scope="col"
              id={`${selectedTabName}_header_${headers.header}`}
            >
              {headers.header.trim() !== ''
                ? formatMessage({ id: headers.header })
                : ''}
            </TableHeader>
          );
        })}
      </TableRow>
    );
    return headerData;
  });
  return headerData;
};

const bodySection = function bodySection(args) {
  const bodyData = [];
  const [data, columnMapping, formatMessage, selectedTabName] = args;
  _.map(data, (rowData, index) => {
    let currentCellIndex = -1;
    bodyData.push(
      <TableRow
        data-testid="tabledesktop-tablerow"
        id={`${selectedTabName}_rowBody_${index}`}
        key={`rowBody_${rowData}_${index}`}
      >
        {_.map(rowData, (cellData, i) => {
          currentCellIndex += 1;
          const {
            fieldData,
            policyCurrency,
            fieldType,
            secondaryDataPrefix,
            fieldDataSecondary,
            allowDecimals
          } = cellData;
          const parentHeaderValue =
            columnMapping[currentCellIndex].parentHeader;
          const parentHeader = parentHeaderValue
            ? `${selectedTabName}_header_${parentHeaderValue}`
            : '';
          const subHeader = `${selectedTabName}_header_${columnMapping[currentCellIndex].header}`;
          const headerIDMapping = `${parentHeader} ${subHeader} `;
          const headerID =
            currentCellIndex !== 0
              ? `${selectedTabName}_td_${columnMapping[0].header}_${index} ${headerIDMapping}`
              : `${headerIDMapping}`;
          let tableRowAttribute = {
            currentCellIndex,
            headers: headerID,
            id: `${selectedTabName}_td_${columnMapping[currentCellIndex].header}_${index}`,
            key: `td_${cellData}_${i}`,
            type: fieldType
          };

          currentCellIndex === 0 &&
            (tableRowAttribute = {
              ...tableRowAttribute,
              scope: 'row',
              'aria-describedby': `${subHeader}`
            });

          const TableDynamicHeaderComponent = TableDynamicHeader(
            tableRowAttribute
          );
          return (
            <TableDynamicHeaderComponent {...tableRowAttribute}>
              {fieldType === 'Number' &&
                !allowDecimals &&
                (!fieldData ||
                  (policyCurrency &&
                    typeof fieldData === 'number' &&
                    truncDigits(fieldData, 0) <= 0)) ? (
                  <TableDataFieldSpan>
                    <span aria-hidden="true">-</span>
                    <AccessibleHidden>
                      {formatMessage({ id: 'tableEmptyData' })}
                    </AccessibleHidden>
                  </TableDataFieldSpan>
                ) : null}
              {getAllowedDecimalData([
                policyCurrency,
                fieldData,
                allowDecimals,
                formatMessage
              ])}
              {policyCurrency &&
                typeof fieldData === 'number' &&
                truncDigits(fieldData, 0) > 0 &&
                !allowDecimals && (
                  <TableDataFieldSpan>
                    <CurrencyWithSymbol
                      amount={fieldData}
                      currencyCode={policyCurrency}
                      decimalRange={0}
                    />
                  </TableDataFieldSpan>
                )}
              {policyCurrency &&
                secondaryDataPrefix &&
                typeof fieldDataSecondary === 'number' &&
                truncDigits(fieldDataSecondary, 0) > 0 && (
                  <TableDataValueSpan>
                    <FormattedMessage
                      id={secondaryDataPrefix}
                      values={{
                        fieldSecondary: (
                          <CurrencyWithSymbol
                            amount={fieldDataSecondary}
                            currencyCode={policyCurrency}
                            decimalRange={0}
                          />
                        )
                      }}
                    />
                  </TableDataValueSpan>
                )}
              {!policyCurrency &&
                secondaryDataPrefix &&
                fieldDataSecondary &&
                formatMessage(
                  { id: secondaryDataPrefix },
                  {
                    fieldPrimary: fieldData,
                    fieldSecondary: fieldDataSecondary
                  }
                )}
            </TableDynamicHeaderComponent>
          );
        })}
      </TableRow>
    );
  });
  return bodyData;
};

const TableDesktop = (props, context) => {
  const {
    columns,
    data,
    displayRowHandler,
    totalSize,
    pageSize,
    maxRowCount,
    tableHeaderText,
    selectedTabName,
    policyCurrency,
    symbol
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  let row1 = [];
  const row2 = [];
  const columnMapping = [];
  let isHavingSubColumns = false;

  columns.map(column => {
    if (column.columns) {
      isHavingSubColumns = true;
      row1.push({
        header: column.Header,
        colSpan: Object.keys(column.columns).length,
        rowSpan: 1,
        align: 'center'
      });
      column.columns.map(subColumn => {
        row2.push({
          header: subColumn.Header,
          colSpan: 1,
          rowSpan: 1,
          accessor: subColumn.accessor,
          align: subColumn.fieldType === 'String' ? 'left' : 'right'
        });

        columnMapping.push({
          parentHeader: column.Header,
          header: subColumn.Header
        });

        return row2;
      });
    } else {
      row1.push({
        header: column.Header,
        colSpan: 1,
        rowSpan: 2,
        align: column.fieldType === 'String' ? 'left' : 'right'
      });
      columnMapping.push({
        header: column.Header
      });
    }
    return row1;
  });

  /* This is done in case if subcolumns are not present at all, hence each
     row will have rowspan 1 by default incase if rowspan is set to 2 */
  if (row1.length > 0 && isHavingSubColumns === false) {
    row1 = row1.map(item => ({ ...item, rowSpan: 1 }));
  }

  // headSections contains the first and second row of tHead in Desktop/Tab View
  const headSections = [row1, row2];

  const showMoreLessButtonDetails = {
    title: 'viewMore',
    srcIcon: addIcon
  };
  if (pageSize !== maxRowCount) {
    showMoreLessButtonDetails.title = 'viewLess';
    showMoreLessButtonDetails.srcIcon = subtractIcon;
  }
  return (
    <ReprojectionTable id={`table_${selectedTabName}`}>
      <TableCaption>
        <TableHeaderTextWrapper>
          <TableHeaderText>
            {formatMessage({
              id: tableHeaderText
            })}
          </TableHeaderText>
          {policyCurrency && (
            <CurrencyLabel>
              {formatMessage({ id: 'tablePolicyCurrency' })}
              <CurrencySymbol>
                {symbol} <span>=</span>
              </CurrencySymbol>
              <CurrencyFormat>
                {formatMessage({ id: `${policyCurrency}_CURRENCY` })}
              </CurrencyFormat>
            </CurrencyLabel>
          )}
        </TableHeaderTextWrapper>
      </TableCaption>

      <TableHead>{headerSection([headSections, formatMessage, selectedTabName])}</TableHead>
      <TableBody>{bodySection([data, columnMapping, formatMessage, selectedTabName])}</TableBody>
      <TableFooter>
        {footerSection([
          data,
          totalSize,
          maxRowCount,
          pageSize,
          formatMessage,
          displayRowHandler,
          showMoreLessButtonDetails,
          selectedTabName
        ])}
      </TableFooter>
    </ReprojectionTable>
  );
};
TableDesktop.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  displayRowHandler: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  maxRowCount: PropTypes.number.isRequired,
  tableHeaderText: PropTypes.string.isRequired,
  selectedTabName: PropTypes.string.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  symbol: PropTypes.any.isRequired
};

TableDesktop.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TableDesktop;
