import React from 'react';
import PropTypes from 'prop-types';
import warningIcon from '../../../assets/images/warning-colour.svg';

import {
  SupplementaryStatusWrapper,
  SupplementaryStatusImgSpan,
  SupplementaryStatus,
  ImagePath
} from '../../detailPageStyle';

const SupplementaryBenefitStatus = function SupplementaryBenefitStatus(
  props,
  context
) {
  const {
    intl: { formatMessage }
  } = context;

  const { fieldData, labelContent } = props;
  let supplementaryStatus = '';
  supplementaryStatus = formatMessage({ id: labelContent });
  if (
    !fieldData ||
    !labelContent ||
    typeof fieldData !== 'string' ||
    fieldData === 'IF'
  ) {
    return <></>;
  }

  return (
    <SupplementaryStatusWrapper>
      <SupplementaryStatusImgSpan>
        <ImagePath aria-hidden="true" alt="" src={warningIcon} />
      </SupplementaryStatusImgSpan>
      <SupplementaryStatus>
        <span>{supplementaryStatus}</span>
      </SupplementaryStatus>
    </SupplementaryStatusWrapper>
  );
};

SupplementaryBenefitStatus.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired
};

SupplementaryBenefitStatus.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SupplementaryBenefitStatus;
