import React from 'react';
import PropTypes from 'prop-types';
import DateComponent from '../../../common/date/Date';

const WhoIsCoveredDate = (props, context) => {
  const { fieldData } = props;
  const {
    intl: { locale }
  } = context;

  if (!fieldData) {
    return '-';
  }
  return (
    <DateComponent
      date={fieldData}
      locale={locale}
      apiDateFormat="YYYY-MM-DD"
      id="standaradDateFormat"
    />
  );
};

WhoIsCoveredDate.propTypes = {
  fieldData: PropTypes.string.isRequired
};

WhoIsCoveredDate.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default WhoIsCoveredDate;
