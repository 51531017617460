import _ from 'lodash';

const DEATH_BENEFIT_SETTLEMENT_OPTION_LIST = ['A', 'B'];

const canSuppressBeneficiaries = currentPolicyDetail =>
  _.includes(
    DEATH_BENEFIT_SETTLEMENT_OPTION_LIST,
    currentPolicyDetail?.policyBenefitDetails?.deathBenefitDetails
      ?.deathBenefitSettlementOption.key
  );

export default canSuppressBeneficiaries;
