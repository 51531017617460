import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { addLanguageData } from '../app/appActions';
import { GET_CONTENT } from '../actionTypes';

export function* fetchContent() {
  const { REACT_APP_CONTENT_URL, PUBLIC_URL } = process.env;
  const url = `${PUBLIC_URL}${REACT_APP_CONTENT_URL}`;
  try {
    const config = {
      method: 'GET',
      url
    };
    const response = yield call(axios, config);
    const { ...content } = response.data;
    delete content.meta;
    yield put(addLanguageData(content));
  } catch (err) {
    /* eslint-disable no-console */
    console.log(err);
  }
}
export default function* contentSaga() {
  yield takeEvery(GET_CONTENT, fetchContent);
}
