/**
 * This is not a proper implementation of modal
 * Too many things are handled using props.
 * Please use common components modal for better accessbility
 * and lesser code
 */

import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  Title,
  DeleteIcon,
  DeleteIconStyle,
  CloseIconOverlay,
  ConditionalText,
  Description,
  ImgSpan
} from './Modal.styled';
import { IconDiv } from '../styles/commonStyle';

class ModalPopup extends Component {
  constructor(props) {
    super(props);
    const domIDBase = `modal_${Math.floor(Math.random() * Math.floor(30))}`;
    const { type } = this.props;
    const isTypeDialog = type === 'dialog';
    this.titleDOMId = `${domIDBase}_title`;
    this.state = {
      overflowY: 'hidden',
      contentTop: this.determineContentTop(isTypeDialog),
      contentLeft: '',
      contentRight: '',
      padding: '40px',
      paddingTop: '45px',
      contentWidth: isTypeDialog ? '500px' : '700px',
      contentBoxSizing: 'border-box',
      margin: '0 auto',
      marginBottom: this.determineMarginBottom(isTypeDialog),
      windowWidth: ''
    };
    this.contentRef = '';
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setContentRef = this.setContentRef.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    const { appElement } = this.props;
    if (appElement) {
      Modal.setAppElement(appElement); // eslint-disable-line no-unused-expressions
    }
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
    document.body.className = '';
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleDeleteKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.onCancel();
    }
  }

  onCancel() {
    const { onCancel } = this.props;
    document.body.style.overflowY = 'auto';
    onCancel();
  }

  setContentRef(node) {
    if (!node) {
      return;
    }
    this.contentRef = node;
    const { clientHeight } = this.contentRef;
    const { innerHeight } = window;
    this.setState({
      overflowY: clientHeight > innerHeight ? 'scroll' : 'hidden'
    });
  }

  setResponsiveCss() {
    const { type } = this.props;
    if (type === 'dialog') {
      if (window.innerWidth < 500) {
        this.setState({
          margin: '0 8px 20px 8px',
          contentTop: '20%'
        });
      } else {
        this.setState({
          margin: '0 auto 20px auto',
          contentTop: '20%'
        });
      }
    } else if (window.innerWidth < 700) {
      this.setState({
        margin: '0 8px auto',
        contentTop: '0%'
      });
    } else {
      this.setState({
        margin: '0 auto 20px auto',
        contentTop: '4%'
      });
    }
  }

  determineMarginBottom = isTypeDialog => {
    if (isTypeDialog) {
      return '20px';
    }
    return window.innerWidth > 700 ? '20px' : 0;
  };

  determineModalType = (width, type) => {
    return window.innerWidth > width ? `${type}Desktop` : `${type}Mobile`;
  };

  determineContentTop = isTypeDialog => {
    if (isTypeDialog) {
      return '20%';
    }
    return window.innerWidth > 700 ? '4%' : '0';
  };

  determineBgOpacity = backgroundopacity => {
    return backgroundopacity ? 'rgba(92,92,92,1)' : 'rgba(0,0,0,0.7)';
  };

  addRedClassfor500Resolution = () => {
    const { windowWidth } = this.state;
    return windowWidth > 500 ? `redBtn` : `redBtn fixWidth`;
  };

  addRedClassfor700Resolution = () => {
    const { windowWidth } = this.state;

    return windowWidth > 700 ? `redBtn` : `redBtn fixWidth`;
  };

  addWhiteClassfor500Resolution = () => {
    const { windowWidth } = this.state;

    return windowWidth > 500 ? `whiteBtn` : `whiteBtn fixWidth`;
  };

  addWhiteClassfor700Resolution = () => {
    const { windowWidth } = this.state;

    return windowWidth > 700 ? `whiteBtn` : `whiteBtn fixWidth`;
  };

  determineClassName = (showRedButtonFirst, type) => {
    if (showRedButtonFirst) {
      return type === 'dialog'
        ? this.addRedClassfor500Resolution()
        : this.addRedClassfor700Resolution();
    }
    return type === 'dialog'
      ? this.addWhiteClassfor500Resolution()
      : this.addWhiteClassfor700Resolution();
  };

  determineClassNames = (showRedButtonFirst, type) => {
    if (showRedButtonFirst) {
      return type === 'dialog'
        ? this.addWhiteClassfor500Resolution()
        : this.addWhiteClassfor700Resolution();
    }
    return type === 'dialog'
      ? this.addRedClassfor500Resolution()
      : this.addRedClassfor700Resolution();
  };

  updateWindowDimensions() {
    const { type, isModalOpen } = this.props;
    const { innerWidth } = window;
    let dynamicWidth;
    this.setResponsiveCss();
    this.setState({ windowWidth: innerWidth });
    if (type === 'dialog') {
      dynamicWidth = 500;
    } else {
      dynamicWidth = 700;
    }
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
    }
    if (innerWidth > dynamicWidth) {
      this.desktopSetting();
    } else {
      this.mobileSetting();
    }
  }

  mobileSetting() {
    const { contentBoxSizing, marginBottom } = this.state;
    const { contentStyle } = this.props;
    const { paddingTop, boxSizing } = contentStyle;
    const { clientHeight } = this.contentRef;
    const { innerHeight } = window;

    this.setState({
      contentTop: '44px',
      padding: '30px',
      paddingTop: paddingTop || '45px',
      overflowY: clientHeight > innerHeight ? 'hidden' : 'auto',
      contentWidth: 'auto',
      contentBoxSizing: boxSizing || contentBoxSizing,
      marginBottom
    });
  }

  desktopSetting() {
    const { contentBoxSizing, contentTop, marginBottom } = this.state;
    const { type, contentStyle } = this.props;
    const { paddingDesktop, paddingTop, boxSizing } = contentStyle;
    const { clientHeight } = this.contentRef;
    const { innerHeight } = window;
    this.setState({
      contentTop,
      padding: paddingDesktop || '40px',
      paddingTop: paddingTop || '45px',
      overflowY: clientHeight > innerHeight ? 'hidden' : 'auto',
      contentWidth: type === 'dialog' ? '500px' : '700px',
      contentBoxSizing: boxSizing || contentBoxSizing,
      marginBottom
    });
  }

  render() {
    const { aria = {}, titleIcon = '', showPopup, isMobile } = this.props;
    const { titleDOMId } = this;
    const windowHeight = window.innerHeight;
    const ariaAttrs = {
      ...aria,
      labelledby: titleDOMId
    };
    const { customZindex, backgroundopacity } = this.props;
    const {
      contentTop,
      contentLeft,
      contentBoxSizing,
      contentRight,
      margin,
      marginBottom,
      contentWidth,
      padding,
      paddingTop,
      overflowY
    } = this.state;
    const customStyles = {
      overlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: customZindex || 5,
        backgroundColor: this.determineBgOpacity(backgroundopacity),
        overflowY
      },
      content: {
        position: 'relative',
        top: contentTop,
        left: contentLeft,
        right: contentRight,
        margin,
        height: windowHeight,
        padding,
        paddingTop,
        borderRadius: '0px',
        bottom: 'none',
        marginBottom,
        width: contentWidth,
        boxSizing: contentBoxSizing
      }
    };
    const {
      isModalOpen,
      onCancel,
      showDeleteIcon,
      intl,
      overlayHeaderStyles,
      title,
      children,
      conditionalText,
      opacity
    } = this.props;

    return (
      <Modal
        contentRef={this.setContentRef}
        isOpen={isModalOpen}
        onRequestClose={onCancel}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        aria={ariaAttrs}
        showPopup={showPopup}
        isMobile={isMobile}
      >
        <>
          <div id="heading">
            <DeleteIcon showDeleteIcon={showDeleteIcon}>
              <IconDiv
                id="delete-icon-div"
                aria-label={intl.formatMessage({
                  id: 'closeOverlayReader',
                  defaultMessage: 'Close Overlay'
                })}
                cursor="pointer"
                onClick={() => this.onCancel()}
                role="button"
                onKeyDown={e => this.handleDeleteKeyDown(e)}
                tabIndex={0}
                style={{ position: 'absolute', right: '15px', top: '18px' }}
              >
                <CloseIconOverlay
                  type="icon-delete"
                  iconStyles={DeleteIconStyle}
                  aria-hidden="false"
                  role="button"
                />
              </IconDiv>
            </DeleteIcon>
            {title && (
              <Title
                overlayHeaderStyles={overlayHeaderStyles}
                id={titleDOMId}
                showPopup={showPopup}
                isMobile={isMobile}
              >
                {titleIcon && (
                  <ImgSpan>
                    <img alt="" src={titleIcon} width="30px" height="26px" />
                  </ImgSpan>
                )}
                {title}
              </Title>
            )}
          </div>
          <Description data-locator-id="full_description" opacity={opacity}>
            {children}
            <ConditionalText>{conditionalText}</ConditionalText>
          </Description>
        </>
      </Modal>
    );
  }
}

ModalPopup.propTypes = {
  type: PropTypes.string,
  isModalOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  contentStyle: PropTypes.shape({
    top: PropTypes.string,
    boxSizing: PropTypes.string,
    paddingDesktop: PropTypes.string,
    paddingTop: PropTypes.string,
    height: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.object
  }),

  titleIcon: PropTypes.string,
  aria: PropTypes.string,
  showPopup: PropTypes.bool,
  isMobile: PropTypes.bool,
  customZindex: PropTypes.string,
  backgroundopacity: PropTypes.string,
  opacity: PropTypes.string,
  overlayHeaderStyles: PropTypes.object,
  children: PropTypes.node,
  appElement: PropTypes.object,
  showDeleteIcon: PropTypes.bool,
  title: PropTypes.string,
  conditionalText: PropTypes.string
};

ModalPopup.defaultProps = {
  type: '',
  isModalOpen: true,
  onCancel: true,
  contentStyle: PropTypes.shape({
    top: '',
    boxSizing: '',
    paddingDesktop: '',
    paddingTop: '',
    height: ''
  }),
  intl: PropTypes.shape({
    formatMessage: ''
  }),

  titleIcon: '',
  aria: '',
  showPopup: true,
  isMobile: true,
  customZindex: '',
  backgroundopacity: '',
  opacity: '1',
  overlayHeaderStyles: {},
  children: '',
  appElement: '',
  showDeleteIcon: true,
  title: '',
  conditionalText: ''
};

export default injectIntl(ModalPopup);
