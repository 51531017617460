import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  RequestWrapper,
  RequestTitle,
  RequestList,
  RequestSectionWrapper,
  ToggleTitleSection,
  TitleLeftSection,
  TitleRightSection,
  TitleStyle,
  SubTitleStyle,
  RefNumberText,
  SubmitText,
  StatusCode,
  StatusText,
  NoRequestSection
} from '../instructionHistoryStyle';
import ToggleHandler from './ToggleHandler';
import CustomDate from './CustomDate';
import SubmitNotification from './SubmitNotification';
import ServicingOptionsDescription from './ServicingOptionsDescription';

const checkForSubmitStatus = (status, transCode) => {
  const submitStatusCode = ['R', '5', '7'];
  return (
    transCode !== 'ESTATMNT' && _.includes(submitStatusCode, status.trim())
  );
};

const accordionTitleSection = args => {
  const [
    referenceNumber,
    submissionTimestamp,
    colorCodes,
    transCode,
    formatMessage,
    dateFormatAsPerLocale,
    status,
    locale
  ] = args;
  return (
    <ToggleTitleSection>
      <TitleLeftSection>
        <TitleStyle>{formatMessage({ id: `title_${transCode}` })}</TitleStyle>
        <SubTitleStyle>
          <RefNumberText>
            {formatMessage(
              { id: 'referenceNumberText' },
              { referenceNo: referenceNumber }
            )}
          </RefNumberText>
          <SubmitText>
            <CustomDate
              date={submissionTimestamp}
              id="submissionText"
              dateFormatAsPerLocale={dateFormatAsPerLocale}
              locale={locale}
            />
          </SubmitText>
        </SubTitleStyle>
        {checkForSubmitStatus(status, transCode) && <SubmitNotification />}
      </TitleLeftSection>
      <TitleRightSection>
        <StatusText>{formatMessage({ id: `status_${status}` })}</StatusText>
        <StatusCode colorCode={colorCodes[status]} />
      </TitleRightSection>
    </ToggleTitleSection>
  );
};

const RequestType = (props, context) => {
  const {
    requestTitle,
    insDetails,
    colorCodes,
    fetchInstructionData,
    policyCurrency,
    dateFormatAsPerLocale,
    noRequestLabel,
    documentDownloadLoaderHandler,
    cancelRequestHandler,
    planName,
    fetchInstructionDetails,
    encodePolicyNumber,
    policyNumber,
    requestType,
    cancelRequestWarningsOrErrors,
    requestTitleToCheck = '',
    langObj,
    cancelRequestData,
    resetCancelData,
    customerSegment
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  return (
    <RequestWrapper>
      <RequestTitle>{requestTitle}</RequestTitle>
      {_.isEmpty(insDetails) ? (
        <NoRequestSection>
          {formatMessage({ id: noRequestLabel })}
        </NoRequestSection>
      ) : (
        _.map(insDetails, section => {
          const {
            referenceNo,
            submissionTimestamp,
            transCode,
            status,
            source
          } = section;

          return (
            <RequestList>
              <RequestSectionWrapper>
                <ToggleHandler
                  sectionTitleFormat={[
                    accordionTitleSection([
                      referenceNo,
                      submissionTimestamp,
                      colorCodes,
                      transCode,
                      formatMessage,
                      dateFormatAsPerLocale,
                      status,
                      locale
                    ])
                  ]}
                  expandedSectionId="options_policy_section"
                  titleId="options_policy_value"
                  transCode={transCode}
                  referenceNo={referenceNo}
                  fetchInstructionData={fetchInstructionData}
                  instructionDetail={section.details}
                  documentDownloadLoaderHandler={documentDownloadLoaderHandler}
                  langObj={langObj}
                  requestTitleToCheck={requestTitleToCheck}
                  source={source}
                  customerSegment={customerSegment}
                >
                  <ServicingOptionsDescription
                    policyCurrency={policyCurrency}
                    planName={planName}
                    fetchInstructionDetails={fetchInstructionDetails}
                    encodePolicyNumber={encodePolicyNumber}
                    policyNumber={policyNumber}
                    requestType={requestType}
                    cancelRequestWarningsOrErrors={
                      cancelRequestWarningsOrErrors
                    }
                    requestTitleToCheck={requestTitleToCheck}
                    section={section}
                    transCode={transCode}
                    referenceNo={referenceNo}
                    cancelRequestHandler={cancelRequestHandler}
                    langObj={langObj}
                    cancelRequestData={cancelRequestData}
                    resetCancelData={resetCancelData}
                    source={source}
                    status={status}
                    customerSegment={customerSegment}
                  />
                </ToggleHandler>
              </RequestSectionWrapper>
            </RequestList>
          );
        })
      )}
    </RequestWrapper>
  );
};

RequestType.propTypes = {
  requestTitle: PropTypes.string.isRequired,
  colorCodes: PropTypes.object.isRequired,
  insDetails: PropTypes.array.isRequired,
  fetchInstructionData: PropTypes.func.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  dateFormatAsPerLocale: PropTypes.object.isRequired,
  noRequestLabel: PropTypes.string.isRequired,
  documentDownloadLoaderHandler: PropTypes.func.isRequired,
  cancelRequestHandler: PropTypes.func.isRequired,
  planName: PropTypes.string.isRequired,
  fetchInstructionDetails: PropTypes.func.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  requestType: PropTypes.string.isRequired,
  cancelRequestWarningsOrErrors: PropTypes.object.isRequired,
  requestTitleToCheck: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  cancelRequestData: PropTypes.func.isRequired,
  resetCancelData: PropTypes.func.isRequired,
  customerSegment: PropTypes.string.isRequired
};

RequestType.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default RequestType;
