import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontIcon } from 'ui-component-library';
import { FormattedMessage } from 'react-intl';
import { setLanguageCode } from '../../../utils/domUtils';
import { AccessibleHidden } from '../../AppStyles';
import {
  LSButton,
  LSMenu,
  LSMenuItem,
  DropDownWrapper,
  SelectedLanguageIcon
} from './LanguageSelectorStyles';

const TextDropDown = ({
  options,
  selectedValue,
  onSelection,
  onMenuToggle,
  isOpen,
  id
}) => {
  const selected = _.find(options, { languageValue: selectedValue }) || {};

  return (
    <DropDownWrapper
      id={id}
      onSelection={onSelection}
      onMenuToggle={onMenuToggle}
    >
      <LSButton
        aria-label={selected.languageText}
        lang={setLanguageCode(selected.languageValue)}
        open={isOpen}
      >
        {selected.languageText}
        <FontIcon
          aria-hidden="true"
          type={!isOpen ? 'chevron-down' : 'chevron-up'}
          fontSize={[12, 12, 12]}
        />
      </LSButton>
      <LSMenu id="languageMenu">
        {_.map(options, ({ languageText, languageValue }, index) => {
          return (
            <LSMenuItem
              aria-label={languageText}
              role="menuitem"
              key={index}
              value={languageValue}
              text={languageText}
              id={languageValue}
              lang={setLanguageCode(languageValue)}
              aria-describedby={
                selectedValue === languageValue ? 'selected' : null
              }
            >
              <span
                aria-selected={selectedValue === languageValue}
                lang={setLanguageCode(languageValue)}
              >
                {languageText}
              </span>
              {selectedValue === languageValue ? (
                <>
                  <AccessibleHidden id="selected">
                    <FormattedMessage id="selected" defaultMessage="selected" />
                  </AccessibleHidden>
                  <SelectedLanguageIcon
                    aria-hidden="true"
                    type="agree"
                    fontSize={[20, 20, 20]}
                  />
                </>
              ) : null}
            </LSMenuItem>
          );
        })}
      </LSMenu>
    </DropDownWrapper>
  );
};

TextDropDown.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string
};

TextDropDown.defaultProps = {
  options: [],
  selectedValue: 'en',
  id: ''
};

export default TextDropDown;
