import { SET_SAAS_TOKEN, REFRESH_SAAS_TOKEN } from '../actionTypes';

export const INITIAL_STATE = null;

const saasTokenReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
  switch (type) {
    case SET_SAAS_TOKEN:
    case REFRESH_SAAS_TOKEN:
      return payload;
    default:
      return state;
  }
};

export default saasTokenReducer;
