import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import { RatingIcon, RatingLine, RatingWrapper } from '../../detailPageStyle';

export const ratingMap = [
  { contentKey: 'fundRating0', color: '#767676' },
  { contentKey: 'fundRating1', color: '#0d6bbd' },
  { contentKey: 'fundRating2', color: '#00a69d' },
  { contentKey: 'fundRating3', color: '#4e9c2d' },
  { contentKey: 'fundRating4', color: '#e09c14' },
  { contentKey: 'fundRating5', color: '#a8000b' }
];

function FundTableRating(props, context) {
  const { fieldData } = props;
  const {
    intl: { formatMessage }
  } = context;

  if (!fieldData || !ratingMap[fieldData.trim()]) {
    return '-';
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>
        {fieldData === '' ? (
          '-'
        ) : (
          <RatingWrapper>
            <RatingIcon iconColor={ratingMap[fieldData.trim()].color}>
              {fieldData}
            </RatingIcon>
            <RatingLine>
              {formatMessage({ id: ratingMap[fieldData.trim()].contentKey })}
            </RatingLine>
          </RatingWrapper>
        )}
      </ShowIf>
    </>
  );
}

FundTableRating.propTypes = {
  fieldData: PropTypes.array.isRequired
};

FundTableRating.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundTableRating;
