import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { StatusBulb } from '../../../policysummary/summaryPageStyles';

import { StatusRow, StatusWrapper, StatusText } from '../../detailPageStyle';
import ShowIf from '../../../utils/showIf';
import DateComponent from '../../../common/date/Date';
import Context from '../../../app/Context';

const PolicyStatusField = (props, context) => {
  const { fieldData, dependentFieldData } = props;
  const {
    intl: { locale }
  } = context;

  if (
    !fieldData ||
    !dependentFieldData ||
    typeof fieldData !== 'string' ||
    typeof dependentFieldData !== 'string'
  ) {
    return <></>;
  }

  const policyStatus = fieldData;
  const policyEffectiveDate = dependentFieldData;

  return (
    <>
      <StatusRow>
        <Context.Consumer>
          {globalContext => (
            <ShowIf condition={!!policyStatus}>
              <StatusBulb
                id="policy_inforce_status"
                colorCode={
                  globalContext.colorCodesForPolicyStatus[policyStatus]
                }
                aria-hidden="true"
              />
            </ShowIf>
          )}
        </Context.Consumer>
        <StatusWrapper>
          <ShowIf condition={!!policyStatus && !!policyEffectiveDate}>
            <StatusText id="policy_inforce_status_label">
              <FormattedMessage
                id={policyStatus}
                values={{
                  givenDate: (
                    <DateComponent
                      date={policyEffectiveDate}
                      locale={locale}
                      apiDateFormat="YYYY-MM-DD"
                      id="standaradDateFormat"
                    />
                  )
                }}
              />
            </StatusText>
          </ShowIf>
        </StatusWrapper>
      </StatusRow>
    </>
  );
};

PolicyStatusField.propTypes = {
  fieldData: PropTypes.string.isRequired,
  dependentFieldData: PropTypes.string.isRequired
};

PolicyStatusField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyStatusField;
