import React from 'react';

const ModalScrollHOC = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.content = undefined;
      this.handleTouchMove = this.handleTouchMove.bind(this);
      this.setContent = this.setContent.bind(this);
    }

    componentDidMount() {
      document.addEventListener('touchmove', this.handleTouchMove, {
        passive: false
      });
    }

    componentWillUnmount() {
      document.removeEventListener('touchmove', this.handleTouchMove);
    }

    setContent(node) {
      this.content = node;
    }

    handleTouchMove(e) {
      if (this.content && !this.node.content.contains(e.target)) {
        e.preventDefault();
      }
    }

    render() {
      return <WrappedComponent setContent={this.setContent} {...this.props} />;
    }
  };
};

export default ModalScrollHOC;
