import React from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';

import OverviewAmountField from '../PolicyOverview/OverviewAmountField';
import ProgressBar from '../../../charts/ProgressBar';
import { MedicalGraphWrapper, ProgressBarRow } from '../../sections/SectionStyle';
import { currentProgressBarWidth } from '../../../charts/chartUtils';



const MedicalTotalAmountChartField = function MedicalTotalAmountChartField(props) {
  const {
    labelContent,
    fieldData: { totalMedicalAmount, medicalClaimBalanceAmount },
  } = props;
  // medicalClaimAmt
  const sectionHeading = 'Medical Account Group';
  if (
    !labelContent || typeof totalMedicalAmount !== 'object'
  ) {
    return null;
  }

  return (
    <MedicalGraphWrapper id="medicalTotalSection">

      <OverviewAmountField
        labelContent="medicalBalanceLabel"
        fieldData={medicalClaimBalanceAmount}
        toolTipContent={null}
        sectionHeading={sectionHeading}
      />
      <ProgressBarRow>
        <ProgressBar
          currentProgress={
            currentProgressBarWidth(
              medicalClaimBalanceAmount.amount,
              totalMedicalAmount.amount)} />
      </ProgressBarRow>
    </MedicalGraphWrapper>
  );
};

MedicalTotalAmountChartField.propTypes = {
  labelContent: PropTypes.string,
  fieldData: PropTypes.number.isRequired,
};

MedicalTotalAmountChartField.defaultProps = {
  labelContent: null
};

export default MedicalTotalAmountChartField;
