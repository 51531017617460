import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as _ from 'lodash';
import { NextActionMessageWrapper } from '../summaryPageStyles';
import {
  NotificationMessageIcon,
  NotificationMessageType,
  NotificationMessage,
  NotificationIconSpan,
  ImagePath
} from '../../common/styles/commonStyle';
import * as icons from '../../assets/images/NotificationIcons';
import { getSummaryCardActionData } from '../../utils/policyUtils';
import DateComponent from '../../common/date/Date';
import ContactLink from '../../common/contactLink/contactLink';

const NextActionMessage = (
  {
    productFieldsObject,
    superCategory,
    productCategory,
    locale,
    notificationMessages,
    policyOnHold
  },
  context
) => {
  const {
    date,
    splitOneMessageKey,
    splitTwoMessageKey
  } = getSummaryCardActionData(
    productFieldsObject,
    superCategory,
    productCategory
  );
  const {
    intl: { formatMessage }
  } = context;
  let nextActionMessageComponent = '';
  let labelContent = '';
  const typeOfCall = 'general enquiry';
  const planCode = productFieldsObject.field2.value;
  let warningMessageComponent;
  const {
    field6: {
      conditionalFieldValueOne,
      conditionalFieldValueTwo,
      conditionalFieldLabelOne,
      conditionalFieldLabelTwo
    }
  } = productFieldsObject;
  const overdueAlertObj = {
    label: conditionalFieldLabelOne,
    value: conditionalFieldValueOne
  };
  const aplAlertObj = {
    label: conditionalFieldLabelTwo,
    value: conditionalFieldValueTwo
  };
  const alertsObj = [];
  if (conditionalFieldValueOne) {
    alertsObj.push(overdueAlertObj);
  }
  if (conditionalFieldValueTwo) {
    alertsObj.push(aplAlertObj);
  }
  const hasProductTagLine = _.startsWith(
    splitTwoMessageKey,
    'nextActionProductTagLine'
  );
  if (conditionalFieldValueOne || conditionalFieldValueTwo) {
    alertsObj.alertStyleObj = notificationMessages.find(
      message => message.type === 'warning'
    );
    const { alertStyleObj } = alertsObj;
    warningMessageComponent =
      alertsObj.length > 0 &&
      alertsObj.map(alert => {
        labelContent = `${alert.label}`;
        return (
          <NotificationMessageIcon role="alert" key={labelContent}>
            <NotificationIconSpan>
              <ImagePath
                aria-hidden="true"
                alt=""
                src={icons[alertStyleObj.style.icon]}
              />
            </NotificationIconSpan>
            <NotificationMessage
              aria-label={formatMessage(
                { id: labelContent },
                { contact0: formatMessage({ id: 'lifeContactUsNumber' }) }
              )}
            >
              <ContactLink
                sentenceWithContact={labelContent}
                contactsList={['lifeContactUsNumber']}
                typeOfCall={typeOfCall}
              />
            </NotificationMessage>
          </NotificationMessageIcon>
        );
      });
    nextActionMessageComponent = (
      <NotificationMessageType
        bgColor={alertStyleObj.style.bgColor}
        borderColor={alertStyleObj.style.borderColor}
      >
        {warningMessageComponent}
      </NotificationMessageType>
    );
  } else {
    nextActionMessageComponent = (
      <>
        {!!splitOneMessageKey && !!date && (
          <>
            <FormattedMessage
              id={splitOneMessageKey}
              values={{
                givenDate: (
                  <DateComponent
                    date={date}
                    locale={locale}
                    apiDateFormat="YYYY-MM-DD"
                    id="standaradDateFormat"
                  />
                )
              }}
            />
          </>
        )}
        {!splitOneMessageKey &&
          !!date &&
          !!splitTwoMessageKey &&
          !hasProductTagLine && (
            <>
              <FormattedMessage
                id={splitTwoMessageKey}
                values={{
                  givenDate: (
                    <DateComponent
                      date={date}
                      locale={locale}
                      apiDateFormat="YYYY-MM-DD"
                      id="standaradDateFormat"
                    />
                  )
                }}
              />
            </>
          )}
        {hasProductTagLine && !policyOnHold && (
          <FormattedHTMLMessage id={splitTwoMessageKey} />
        )}
      </>
    );
  }
  return (
    <NextActionMessageWrapper
      id={`nextActionMessage_${planCode}`}
      conditionalFieldValueOne={conditionalFieldValueOne}
      conditionalFieldValueTwo={conditionalFieldValueTwo}
    >
      {nextActionMessageComponent}
    </NextActionMessageWrapper>
  );
};

NextActionMessage.contextTypes = {
  intl: PropTypes.object.isRequired
};

NextActionMessage.propTypes = {
  superCategory: PropTypes.string.isRequired,
  productCategory: PropTypes.string.isRequired,
  productFieldsObject: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  policyOnHold: PropTypes.bool.isRequired
};

export default NextActionMessage;
