import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FundSelectionWrapper,
  FundNameWrapper,
  FundNameDiv,
  RiskTolerance,
  RiskTypeColor,
  RiskLevel,
  RiskIconSpan,
  FundListRow,
  FundListLabel,
  FundListValue,
  RatingIconMobile,
  GainIcon,
  FundHeaderWrapper
} from '../../../../common/styles/fundsRPQPopupStyle';

import gainIcon from '../../../../assets/images/gainChevrron.svg';
import lossIcon from '../../../../assets/images/lossIcon.svg';
import FundDetails from './FundDetails';
import { validatePerformanceValue } from '../../../sectionUtils/FundUtils';

const RpqPopupTableMobile = function RpqPopupTableMobile(props, context) {
  const {
    fundsTableData,
    matchedRiskLevel,
    fundPerformanceColumnData,
    rpqUTBConfig
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  const { fundCode, globalFundCode, fundName, riskLvlCde } = fundsTableData;
  const { matchedRisk, matchedRiskColor } = matchedRiskLevel[riskLvlCde];

  const getFundPerformanceIcon = performance =>
    performance > 0 ? gainIcon : lossIcon;

  return (
    <FundSelectionWrapper data-testid="fundslist-mobile">
      <>
        <FundHeaderWrapper>
          <FundNameWrapper type>
            <FundNameDiv id={fundCode}>
              <FundDetails
                fundName={fundName}
                fundCode={globalFundCode}
                rpqUTB={rpqUTBConfig}
              />
            </FundNameDiv>
          </FundNameWrapper>
        </FundHeaderWrapper>
        <FundListRow isPlanSummaryReview>
          <FundListLabel>{formatMessage({ id: 'riskLevel_TH' })}</FundListLabel>
          <FundListValue>
            <RatingIconMobile>
              <RiskLevel isFontBold>
                {formatMessage({ id: matchedRisk })}
              </RiskLevel>
              <RiskTypeColor riskTypeColor={matchedRiskColor}>
                {+riskLvlCde}
              </RiskTypeColor>
            </RatingIconMobile>
            <RiskTolerance>
              <span>{formatMessage({ id: 'matchedWithYouRiskLevel' })}</span>
            </RiskTolerance>
          </FundListValue>
        </FundListRow>
        {_.map(fundPerformanceColumnData, currentFundPerformance => {
          const {
            perFormanceKey,
            performance,
            isDisplayPerformance
          } = currentFundPerformance;
          return (
            <>
              {isDisplayPerformance && (
                <FundListRow key={perFormanceKey}>
                  <FundListLabel>
                    {formatMessage({ id: perFormanceKey })}
                  </FundListLabel>
                  <FundListValue>
                    {_.isNumber(performance) && performance !== 0 ? (
                      <RatingIconMobile>
                        <span>
                          <GainIcon
                            aria-hidden="true"
                            alt=""
                            src={getFundPerformanceIcon(performance)}
                          />
                        </span>
                        <RiskIconSpan>{Math.abs(performance)}%</RiskIconSpan>
                      </RatingIconMobile>
                    ) : (
                      <RatingIconMobile>
                        <RiskIconSpan>
                          {validatePerformanceValue(performance)}
                        </RiskIconSpan>
                      </RatingIconMobile>
                    )}
                  </FundListValue>
                </FundListRow>
              )}
            </>
          );
        })}
      </>
    </FundSelectionWrapper>
  );
};

RpqPopupTableMobile.contextTypes = {
  intl: PropTypes.object.isRequired
};

RpqPopupTableMobile.propTypes = {
  fundsTableData: PropTypes.array.isRequired,
  matchedRiskLevel: PropTypes.object.isRequired,
  fundPerformanceColumnData: PropTypes.array.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired
};

export default RpqPopupTableMobile;
