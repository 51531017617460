import React from 'react';
import PropTypes from 'prop-types';
import {
  RiderHeader,
  RiderIcon,
  ImagePath,
  RiderNameWrapper
} from '../../detailPageStyle';
import riderIconMapping from './data/RiderIconMapping';
import getDescription from '../../../utils/reducerUtils';

function RiderName(props, context) {
  const {
    intl: { locale }
  } = context;

  const { fieldData, dependentFieldData } = props;
  let imgSrc = riderIconMapping.generic.srcIcon;
  if (dependentFieldData) {
    const riderCategory = dependentFieldData.toLowerCase();
    if (riderCategory in riderIconMapping) {
      imgSrc = riderIconMapping[riderCategory].srcIcon;
    }
  }
  const contentDescription = fieldData && getDescription(locale, fieldData);

  if (!contentDescription) {
    return <></>;
  }

  return (
    <RiderNameWrapper>
      <RiderIcon>
        <ImagePath aria-hidden="true" alt="" src={imgSrc} />
      </RiderIcon>
      <RiderHeader>{contentDescription}</RiderHeader>
    </RiderNameWrapper>
  );
}

RiderName.propTypes = {
  dependentFieldData: PropTypes.string,
  fieldData: PropTypes.array.isRequired
};

RiderName.defaultProps = {
  dependentFieldData: ''
};
RiderName.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default RiderName;
