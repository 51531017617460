import styled from 'styled-components';
import { em, rem } from 'polished';
import theme from '../common/styles/theme';
import media from '../utils/media';
import {
  Section,
  NotificationIconSpan,
  NotificationMessage
} from '../common/styles/commonStyle';
import { SectionHeader } from './sections/SectionStyle';
import { FontFamily } from '../utils/font';

export const DetailsPageOverviewSection = styled.section`
  display: block;
  background: ${theme.whiteColor};
`;

export const DetailsPageOverviewSectionWithWarning = styled(
  DetailsPageOverviewSection
)`
  padding-top: 20px;
`;
export const PortfolioHeader = styled.div`
  margin-bottom: ${rem('20px')};
  margin-top: ${rem('30px')};
  font-size: ${rem('18px')};
  ${FontFamily.RegularFontW01};
  color: #575757;
  ${media.tablet`
  margin-bottom: ${rem('18.9px')};
  margin-top: ${rem('24.1px')};
  `};
  ${media.phone`
box-sizing: border-box;
padding: 0 ${rem('15px')} ${rem('20px')} ${rem('15px')};
margin-top: 0;
margin-bottom: 0;
line-height: ${rem('22px')}
`}
`;
export const PremAllocationHeader = styled.div`
  margin-top: ${rem('20px')};
  margin-bottom: ${rem('25px')};
  font-size: ${rem('18px')};
  ${FontFamily.RegularFontW01};
  color: #575757;
  ${media.tablet`
margin-top: ${rem('30px')};
margin-bottom: ${rem('25px')};
`}
  ${media.phone`
    line-height:22px;
    margin-top: 0;
    margin-bottom:0;
    padding: ${rem('20px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
  `}
`;
export const PremAllocationDesc = styled.div`
  margin-bottom: ${rem('10px')};
  line-height: ${rem('22px')};
  ${media.tablet`
  marging-bottom:${rem('15px')}
  `}
  ${media.phone`
    font-size:14px;
    line-height:20px;
    ${FontFamily.RegularFontW01};
    padding: 0 15px 15px 15px;
  `}
`;
export const RiskRatingDisclaimer = styled.div`
  line-height: 20px;
  padding-top: ${props => (props.topPadding ? '0' : '30px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.tablet`
  padding-top: ${props => (props.topPadding ? '0' : rem('25px'))};
  `}
  ${media.phone`
    line-height:18px;
    padding: ${rem('20px')} ${rem('15px')} ${rem('15px')} ${rem('15px')}
  `}
`;
export const RiskRatingText = styled.span`
  font-size: ${em('14px')};
  padding-bottom: 15px;
  display: block;
  max-width: 100%;
  flex-shrink: 1;
  ${media.phone`
  font-size:${em('12px')};
  `}
`;
export const ImgWrapper = styled.div`
  height: 20px;
  width: 20px;
  display: inline-block;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  position: relative;
  top: 4px;
  margin-right: 5px;

  img {
    margin: 0 auto;
    /* position: relative; */
    /* top: 3px; */
    /* left: 5px; */
    display: block;
    position: relative;
    top: 4px;
  }
`;

export const SummaryParentSection = styled.section`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: ${em(theme.maxWidth)};
  width: 100%;
  margin: 0 auto;
  clear: both;
  padding: 0 ${rem('50px')};
  ${media.tablet`
		padding: 0 ${rem('24px')};
    `};
  ${media.phone`
		padding: 0 ${rem('15px')};
	`};
`;

export const PageLevelWarningSection = styled(SummaryParentSection)`
  max-width: 1180px;
  margin-top: 16px;
  margin-bottom: -14px;
  padding: 0;
  ${media.tablet`
    margin-top: -6px;
    padding: ${rem('17px')} ${rem('24px')} ${rem('7px')}
  `};
  ${media.phone`
    margin-top: -6px;
  `};
`;

export const IntroHeaderSection = styled(Section)`
  display: block;
  margin-bottom: 0;
  padding-top: ${rem('30px')};
  padding-bottom: ${rem('10px')};
  ${media.tablet`
  padding:  ${rem('25px')}  ${rem('24px')} ${rem('10px')} ${rem('24px')};
  `};
  ${media.phone`
  padding:  ${rem('20px')}  ${rem('15px')} ${rem('10px')}  ${rem('15px')};
  `};
`;

export const LegendWrapper = styled.div`
  width: 70%;
  padding-right: 200px;
`;

export const ChartWrapper = styled.div`
  width: 30%;
  ${media.phone`
    width: 40%;
  `};
`;

export const DetailsPageMiddleSection = styled(Section)``;

export const FullWidthSection = styled.div`
  display: block;
`;
export const StatusRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem('10px')};
  ${media.phone`
  margin-bottom: ${rem('8px')};
  `};
`;
export const StatusWrapper = styled.span`
  font-size: ${em(`14px`)};
  color: ${theme.fontColor};
  line-height: ${rem('20px')};
`;
export const StatusText = styled.span`
  line-height: inherit;
  margin-right: ${rem('5px')};
`;

export const PolicyNameRow = styled.div`
  display: block;
  margin-bottom: ${rem('10px')};
  ${media.tablet`
  margin-bottom: ${rem('14px')};
  `};
  ${media.phone`
  margin-bottom: ${rem('11px')};
  `};
`;
export const PolicyNameHeader = styled.h1`
  font-size: ${em(`38px`)};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('46px')};

  ${FontFamily.LightFontW01};
  ${media.phone`
  font-size: ${em(`28px`)};
  line-height: ${rem('38px')};
  `};
`;

export const PolicyNumberRow = styled.div`
  display: block;
  margin-bottom: ${rem('20px')};
`;

export const PolicyTagsRow = styled.div`
  margin-bottom: ${rem('25px')};
  display: flex;
  flex-wrap: wrap;
`;

export const PolicyNumberText = styled.span`
  font-size: ${em('18px')};
  color: ${theme.greyNumberColor};
  letter-spacing: 0;
  line-height: ${rem('24px')};
  ${media.tablet`
  line-height:${rem('16.8px')};
  `};
  ${media.phone`
  font-size: ${em('16px')};
  `};
`;

export const DetailsNotification = styled.div`
  display: block;
  background-color: ${theme.whiteColor};
`;

export const NotificationRow = styled(Section)`
  display: block;
  margin-bottom: 0;
  display: flex;
  ${media.tablet`
  padding: 0 ${rem('24px')};
`};
  ${media.phone`
 padding: 0 0 0  ${rem('15px')};
`};
`;
export const LeftNotificationRow = styled.nav`
  padding: ${rem('20px')} 0 ${rem('15px')} 0;
  letter-spacing: 0;
  font-size: ${em('14px')};
  ${media.phone`
    font-size: ${em('12px')};
    padding: ${rem('20px')} 0 ${rem('15px')} 0;
`};
`;
export const NotificationBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.detailNotificationBorder};
`;
export const RightNotificationRow = styled.div`
  line-height: ${rem('20px')};
  font-size: ${em('14px')};
  position: relative;
`;

export const ButtonWrapperOL = styled.ol`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-wrap:wrap;
  padding:0;
  margin:0;
  span {
    ${FontFamily.BoldFontW01}
    color: ${theme.fontColor};
    ${media.phone`
      line-height : ${rem('18px')}
    `}
  }
  > li a {
    display:flex;
    cursor: pointer;
    border: none;
    text-decoration: none;
    &:focus {
      outline: 1px dotted black;
    }
    ${media.phone`
      padding-bottom:3px;
    `}
  }
`;
export const ListLI = styled.li`
padding:0;
margin:0;
cursor: ${props => (props.currentPage ? `default` : 'pointer')};
display:flex;
border: none;
text-decoration: none;
&:focus {
  outline: 1px dotted black;
}
${media.phone`
  padding-bottom:3px;
`}
}
`;

export const BackButton = styled.span`
  width: auto;
  -webkit-appearance: initial;
  background: transparent;
  border: none;
  span {
    ${FontFamily.RegularFontW01};
    color: ${theme.fontColor};
    &:hover {
      outline: 1px dotted black;
    }
  }
  letter-spacing: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  padding: 0;
`;

export const ImageSpan = styled.span`
  height: ${rem('10px')};
  width: ${rem('6px')};
  margin: 0 ${rem('10px')};
  display: block;
`;
export const ImagePath = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImagePaymentPath = styled.img`
  width: 320px;
  height: 202px;
  @media (max-width: 768px) {
    width: ${rem('260px')};
    height: ${rem('164px')};
  }
  ${media.miniPhone`
  width:260px;
  height:164px;
  margin: 0 auto 25px;
  `}
`;

export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('15px')} ${rem('20px')};
  border-left: 1px solid ${theme.greyLabels};
  border-right: 1px solid ${theme.greyLabels};
  ${media.phone`
  padding: ${rem('17.5px')} ${rem('15px')};
 `};
`;
export const ContactImgSpan = styled.span`
  height: ${rem('15px')};
  width: ${rem('15px')};
  margin-right: ${rem('10px')};
  display: block;
  ${media.phone`
    margin-right: 0;
  `}
`;

export const ContactNumberLink = styled.a`
  color: black;
  text-decoration: none;
  &:focus,
  &:hover {
    outline: 1px dotted white;
  }
`;

export const ContactNumberSpan = styled.span`
  display: block;
  line-height: 20px;
  font-size: ${rem('14px')};
  ${media.phone`
  display: none;
  `};
`;
export const ContactTextSpan = styled.span`
  display: block;
  line-height: 20px;
  font-size: ${rem('14px')};
  ${media.phone`
  display: none;
  `};
`;

export const ContactImagePath = styled.img`
  height: 100%;
  width: 100%;
`;

export const CriticalIllnessFieldWrapper = styled.dl`
  padding-left: ${rem('39px')};
  margin-bottom: ${rem('24px')};
  display: flex;
  flex-direction: column;
  dt span,
  dd span {
    line-height: ${rem('20px')};
  }
  ${media.phone`
    justify-content: space-between;
    flex-direction: row;
    padding-top: ${rem('10px')};
    padding-bottom: ${rem('10px')};
    margin-bottom: 0;
  `};
`;

export const CriticalIllnessLabelWrapper = styled.dt`
  margin-bottom: ${rem('5px')};
  font-size: ${rem('16px')};
  box-sizing: border-box;
  ${media.tablet`
    font-size: ${rem('14px')};
    padding-right: ${rem('12px')};
  `}
  ${media.phone`
    width: 50%;
    padding-right: ${rem('8px')};
  `}
`;

export const OverviewFieldWrapper = styled.dl`
  margin: 0;
  width: ${rem('204px')};
  padding: ${props =>
    props.id === 'sumInsuredLabel'
      ? `0 ${rem('16px')} ${rem('30px')} ${rem('24px')}`
      : `0 ${rem('24px')} ${rem('30px')} ${rem('24px')}`};
  box-sizing: border-box;
  position:relative;

  ${media.phone`
  position: unset;
  `};

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: ${rem('50px')};
    top: 0;
    right: 0;
    background: ${theme.bgGreyColor};
  }
  &:first-of-type {
    padding: 0 ${rem('24px')} ${rem('30px')} 0;
  }
  &:nth-of-type(1),
  &:nth-of-type(6),
  &:nth-of-type(11) {
    padding: 0 ${rem('24px')} ${rem('30px')} 0;
  }
  &:nth-of-type(5),
  &:nth-of-type(10) {
    &:after {
      visibility: hidden;
    }
  }
  &:last-of-type {
    &:after {
      visibility: hidden;
    }
  }
  ${props =>
    props.isMedical &&
    `
    &:first-of-type {
      &:after {
        visibility: hidden;
      }
    }
  `}

  @media (max-width: 1119px) {
   width: ${rem('204px')}
   &:nth-of-type(6),&:nth-of-type(10),
   &:nth-of-type(11) {
     padding: 0 ${rem('24px')} ${rem('30px')} ${rem('24px')};
   }
    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9),
    &:nth-of-type(15) {
      padding: 0 ${rem('24px')} ${rem('30px')} 0;
    }
    &:nth-of-type(5),
    &:nth-of-type(10) {
      &:after {
        visibility: visible;
      }
    }
    &:nth-of-type(4),
    &:nth-of-type(8),
    &:nth-of-type(12) {
      &:after {
        visibility: hidden;
      }
    }
    &:last-of-type {
      &:after {
        visibility: hidden;
      }
    }
  }
  ${media.mediumTablet`
  width: ${rem('190px')}
  &:nth-of-type(6),&:nth-of-type(10),
  &:nth-of-type(11) {
    padding: 0 ${rem('23px')} ${rem('30px')} ${rem('23px')};
  }
   &:nth-of-type(1),
   &:nth-of-type(5),
   &:nth-of-type(9),
   &:nth-of-type(15) {
     padding: 0 ${rem('23px')} ${rem('30px')} 0;
   }
   &:nth-of-type(5),
   &:nth-of-type(10) {
     &:after {
       visibility: visible;
     }
   }
   &:nth-of-type(4),
   &:nth-of-type(8),
   &:nth-of-type(12) {
     &:after {
       visibility: hidden;
     }
   }
   &:last-of-type {
     &:after {
       visibility: hidden;
     }
   }
  `};
  @media (max-width: 807px) {
    width: ${props =>
    props.id === 'monthlyGuaranteedAnnuity_label'
      ? `${rem('176px')}`
      : `${rem('180px')}`};
    padding: 0 ${rem('23px')} ${rem('30px')} ${rem('23px')};
    &:nth-of-type(6),
    &:nth-of-type(10),
    &:nth-of-type(11) {
      padding: 0 ${rem('23px')} ${rem('30px')} ${rem('23px')};
    }
    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9),
    &:nth-of-type(15) {
      padding: 0 ${rem('23px')} ${rem('30px')} 0;
    }
  }
  @media (max-width: 767px) {
    width: ${rem('169px')};
    padding: 0 ${rem('20px')} ${rem('30px')} ${rem('20px')};
    &:nth-of-type(6),
    &:nth-of-type(10),
    &:nth-of-type(11) {
      padding: 0 ${rem('20px')} ${rem('30px')} ${rem('20px')};
    }
    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9),
    &:nth-of-type(15) {
      padding: 0 ${rem('20px')} ${rem('30px')} 0;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    padding: ${rem('12px')} 0 !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.bgGreyColor};
    &:after {
     display:none;
    }
    &:first-of-type {
      padding: ${rem('12px')} 0;
    }
`;

export const PaymentInfoFieldWrapper = styled.dl`
  margin: 0;
  width: ${rem('260px')};
  padding: 0 ${rem('24px')} ${rem('30px')} 0;
  box-sizing: border-box;

  dt > span > span {
    font-size: ${rem('16px')};
  }

  > span {
    ${FontFamily.BoldFontW01}
  }
  dt > span > span {
    ${media.tablet`
      font-size : ${rem('14px')}
    `};
  }

  @media (max-width: 1225px) {
    width: ${rem('220px')};
  }

  ${media.miniDesktop`
  width: ${rem('180px')}
  `};

  ${media.mediumTablet`
  width: ${rem('165px')}
  `};

  @media (max-width: 760px) {
    width: ${rem('150px')};
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: ${rem('12px')} 0 !important;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.bgGreyColor};
  }
`;

export const OverviewInfoFieldLabelKey = styled.dt`
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
  padding-bottom: 11px;
  @media (max-width: 720px) {
    padding-bottom: 0;
  }
  ${media.phone`
    padding: 0;
    width: 50%
  `};
`;

export const LabelText = styled.span`
  font-size: ${rem('16px')};
  color: ${theme.greyNumberColor};
  line-height: ${rem('18px')};
  display: block;
  ${media.tablet`
    font-size: ${rem('14px')};
  `};
`;

export const LabelTextCriticalIllness = styled(LabelText)`
  margin-top: ${rem('5px')};
  ${media.phone`
    margin-top: 0;
  `}
  span {
    font-family: UniversNextforHSBC-Regular !important;
  }
`;

export const OverviewInfoFieldDataValue = styled.dd`
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;

  ${media.phone`
  width: 50%;
  justify-content: flex-end;
  text-align: right;
  `};
`;

export const OverviewInfoInsuredFieldDataValue = styled(
  OverviewInfoFieldDataValue
)`
  flex-direction: column;
  align-items: start;

  ${media.phone`
width: 50%;
align-items: flex-end;
text-align: right;
`};
`;

export const OverviewInfoFieldAmountCurrency = styled(
  OverviewInfoFieldDataValue
)`

  span  {
    width: 100%;
    ${FontFamily.RegularFontW01}
    font-size: ${rem('16px')};
    color: ${theme.fontColor};
    line-height:${rem('18px')};
  }
  span span  {
    ${FontFamily.BoldFontW01};
    font-size: ${rem('16px')};
    margin: 0 ${rem('5px')} 0 0;
  }

  ${media.tablet`
    span span{
      font-size: ${rem('14px')};
    }
    span  {
      font-size: ${rem('14px')};
    }
  `};
`;

export const SpcialCaseWrapper = styled.div`
  span span span {
    ${FontFamily.BoldFontW01}
  }
  span span {
    ${FontFamily.RegularFontW01};
  }
`;

export const DataTextBold = styled.span`
  width:100%;
  display:block;
  ${FontFamily.BoldFontW01}
  font-size: ${rem('16px')};
  color: ${theme.fontColor};
  line-height:${rem('18px')};
  padding-bottom: ${props => (props.name ? `${rem('6px')}` : '')};
    &:last-of-type {
      padding-bottom:0;
    }
  ${media.phone`
  font-size: ${rem('14px')};
  `};
`;
export const DataText = styled.span`
  display:block;
  ${FontFamily.RegularFontW01}
  font-size: ${rem('16px')};
  color: ${theme.fontColor};
  line-height:${rem('18px')};
  margin: 0 4px 0 0;
  ${media.phone`
  font-size: ${rem('14px')};
  `};
`;

export const ContactFieldWrapper = styled.div`
  width: 80%;
  height: 50px;
`;

export const ContactFieldLabel = styled.span`
  font-size: ${rem('16px')};
  color: #575757;
  line-height: ${rem('18px')};
`;

export const ContactFieldData = styled.span`
  font-family: UniversNextforHSBC-Bold;
  float: right;
  font-size: ${rem('16px')};
  color: #333333;
  line-height: ${rem('18px')};
`;

export const OverviewWrapper = styled.div`
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
`;

export const OverviewWrapperPayerSection = styled(OverviewWrapper)`
  ${media.phoneToMiniTablet`
      padding-bottom: ${rem('18px')};
  `}
`;

export const OverviewWrapperPayment = styled.div`
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  ${media.phone`
    width: 100%;
  `}
`;

export const UpdateClaimWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: solid 1px #d7d8d6;
  padding-top: ${rem('20px')};
  ${media.phone`
    display:block;
    border-top:none;
  `}
`;
export const SectionLink = styled.a`
margin-right:${rem('18px')};
margin-bottom: ${props => (props.noMarginBottom ? 0 : `${rem('20px')}`)};
display:flex;
padding:0
cursor: pointer;
border: none;
color: ${theme.fontColor};
line-height:${rem('18px')};
text-decoration:none;
&:focus, &:hover {
  outline: 1px dotted black;
}
${media.tablet`
margin-bottom: ${rem('20px')};
`}
${media.phone`
width:100%;
margin-bottom: ${rem('20px')};
`}
`;
export const SectionLabel = styled.span`
  display: block;
  padding: 0px ${rem('10px')} 0px ${rem('10px')};
  ${FontFamily.RegularFontW01};
  font-size: ${rem('14px')};
`;

export const SectionIconWrapper = styled.span`
  width: ${props => (props.setIconWidthHeight ? rem('18px') : rem('12px'))};
  height: ${props => (props.setIconWidthHeight ? rem('18px') : rem('12px'))};
`;
export const FundsLinkWrapper = styled.div`
  margin-top: ${rem('10px')};
`;
export const FundIconWrapper = styled.span`
  width: ${rem('12px')};
  height: ${rem('12px')};
  margin-right: ${rem('8px')};
`;
export const SectionIconRightWrapper = styled.span`
  width: ${rem('7px')};
  height: ${rem('11px')};
  display: none;
`;

export const LevyTextWrapper = styled.div`
  ${FontFamily.RegularFontW01};
  font-size: ${em('14px')};
  line-height: ${rem('18px')};
  margin-top: ${rem('10px')};
  ${media.tablet`
  margin-top: ${rem('6px')};
  `}
  ${media.phone`
  font-size: ${em('12px')};
  line-height: ${rem('16px')};
  margin-top: ${rem('14px')};
  `}
`;

export const RiderWrapper = styled.div`
  min-height: auto;
  padding: ${rem('25px')} ${rem('30px')};
  background-color: #f8f8f8;
  margin-bottom: ${rem('20px')};
  ${media.phone`
    padding : ${rem('15px')} ${rem('15px')} 0;
  `};
`;

export const RiderFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: ${rem('100px')};
  ${media.phone`
  flex-direction:column;
  `}
`;

export const RiderLeft = styled.div`
  width: 70%;
  flex-grow: 1;
  border-right: ${props =>
    props.rightSectionData ? '1px solid #d9d9d9' : 'none'};

  padding-right: ${rem('30px')};
  font-size: ${em('16px')};
  line-height: ${rem('22px')};

  ${media.tablet`
  font-size: ${em('14px')};
  line-height: ${rem('20px')};
  `};

  ${media.phone`
  font-size: ${em('14px')};
  width:100%;
  border-right:none;
  line-height: ${rem('20px')};
  `};
`;

export const RiderRight = styled.div`
  width: 30%;
  flex-grow: 1;
  padding-left: ${rem('30px')};

  dl {
    padding-left: ${rem('0px')};
  }

  ${media.phoneToMiniTablet`
    dl {
      border-bottom: none;
    }
  `};

  ${media.phone`
  dl {
    border-bottom: ${props =>
      props.rightSectionData ? '1px solid #D8D8D8' : 'none'};
  }
  dl:last-child{
    border-bottom: 1px solid transparent;
  }
  width:100%;
  border-top : ${props =>
      props.rightSectionData ? '1px solid #d9d9d9' : 'none'};
  padding-left:${rem('0px')};
  `};
`;
export const RiderNameWrapper = styled(SectionHeader)`
  margin-bottom: ${rem('12px')};
`;
export const RiderHeader = styled.h4`
  margin-block-start: 0;
  margin-block-end: 0;
  ${FontFamily.RegularFontW01};
  color: #333333;
  font-size: ${em('22px')};
  line-height: ${rem('32px')};
  ${media.tablet`
  font-size: ${em('20px')};
  `};
  ${media.phone`
  font-size: ${em('18px')};
  line-height: ${rem('28px')};
  `};
`;

export const RiderAddOns = styled.div`
  ${FontFamily.RegularFontW01};
  color: ${theme.fontColor};
  font-size: ${em('14px')};
  line-height: ${rem('22px')};
  margin-bottom: ${rem('12px')};

  ${media.tablet`
  margin-bottom: ${rem('18px')};
  `};
  ${media.phone`
  margin-bottom: ${rem('15px')};
  `};
`;

export const Table = styled.table`
  ${FontFamily.RegularFontW01};
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  ${media.phone`
    display: none;
`}
`;
export const Thead = styled.thead`
  color: #575757;
  border-bottom: 1px solid #d7d8d6;
  td {
    padding-left: 0;
    ${FontFamily.RegularFontW01};
    color: #575757;
    line-height: 18px;
  }
`;
export const Tbody = styled.tbody`
  tr {
    td {
      ${FontFamily.RegularFontW01};
      color: #252525;
      font-size: 14px;
    }

    td:first-child {
      ${FontFamily.BoldFontW01};
      padding-left: ${props =>
    props.type === 'whoIsCovered' ? rem('0px') : rem('15px')};
    }
  }
`;
export const Tr = styled.tr`
  padding: 10px 0;
  border-bottom: 1px solid #d7d8d6;
`;
export const Td = styled.td`
  vertical-align: top;
  line-height: ${rem('22px')};
  border-left: ${props => {
    if (props.toShow) {
      if (props.type === 'whoIsCovered') {
        return 'none';
      }
      return `5px solid ${props.borderColor}`;
    }
    return `5px solid ${props.otherBorderColor}`;
  }};
  text-align: ${props => props.textAlign};
  wordbreak: ${props => props.wordBreak};
  padding: ${rem('11px')} ${rem('5px')};
  :first-child {
    width: ${props => (props.type === 'whoIsCovered' ? '300px' : '562px')};
  }
  ${media.tablet`
  :first-child {
    width:${props => (props.type === 'whoIsCovered' ? '130px' : '178px')};
  }
`}
`;
export const BasicPlanDescWrapper = styled.div`
  line-height: 22px;
`;

export const BasicPlanOptionWrapper = styled.div`
  padding-bottom: 20px;
  display: flex;
`;
export const BasicPlanOptionHeader = styled.div`
  ${FontFamily.BoldFontW01}
  font-size:${em('16px')};
  line-height:${rem('22px')};
  ${media.tablet`
    font-size:${em('14px')};
    line-height:${rem('20px')};
  `}
`;
export const BasicPlanOptionDesc = styled.div`
  font-size: ${em('16px')};
  padding-top: 5px;
  line-height: 22px;
  ${media.tablet`
  font-size:${em('14px')};
  line-height:${rem('20px')};
  padding-top:7px;
  `}
`;
export const BasicPlanOptionImgSpan = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  top: 1px;
  margin-right: 15px;
  ${media.tablet`
    top:0
  `}
`;
export const BasicPlanOptionList = styled.div`
  max-width: 90%;
  ${media.miniPhone`
    max-width:83%;
  `}
`;

export const PlanDescription = styled.div`
  padding-bottom: ${rem('20px')};
  line-height: ${rem('22px')};
  ${media.tablet`
    font-size: ${rem('14px')};
  `}
  ${media.phone`
    font-size: ${rem('14px')};
  `}
`;

export const FullPaidContainer = styled.div`
  display: flex;
  margin: 0 auto;
  ${media.phone`
  flex-direction: column;
  `}
`;
export const ImageFullPaidWrapper = styled.div`
  margin-right: ${rem('58px')};
  ${media.phone`
    margin: 0 auto;
  `};
`;
export const ImageFullPaid = styled.img`
  width: ${rem('106px')};
  height: ${rem('101px')};
`;

export const FullPaidTextWrapper = styled.div`
  flex-wrap: wrap;
  margin: auto 0;
  ${media.phone`
    margin-top: ${rem('17px')};
    text-align: center;
  `};
`;
export const FullPaidTextSpan = styled.span`
  ${FontFamily.LightFontW01};
  font-size: ${em('22px')};
  color: ${theme.fontColor};
  line-height: ${rem('32px')};
`;
export const LevyLocalCurrency = styled.div`
  display: inline;
  padding-left: 5px;
  span {
    ${FontFamily.RegularFontW01}
  }
  span span {
    ${FontFamily.RegularFontW01}
  }
`;

export const LevyWrapper = styled.div``;

export const SupplementaryStatusWrapper = styled.div`
  padding-bottom: ${rem('12px')}
  display: flex;
  align-items: center;
  ${media.tablet`
    padding-bottom:${rem('18px')}
  `}
  ${media.phone`
    align-items: normal;
    padding-bottom: ${rem('15px')}
  `};
`;

export const SupplementaryStatusImgSpan = styled.div`
  width: ${rem('14px')};
  height: ${rem('14px')};
  margin-right: ${rem('8px')};
  ${media.tablet`
    top:0
  `}
`;

export const SupplementaryStatus = styled.div`
  span {
    font-size: ${rem('14px')};
    display: block;
  }
  line-height: ${rem('16px')};
`;

export const RiderIcon = styled.span`
  display: block;
  width: ${rem('18px')};
  height: ${rem('18px')};
  padding-right: ${rem('11px')};
  position: relative;
  top: -1px;
`;
export const RiderDesc = styled.div`
  margin-bottom: ${rem('12px')};
  line-height: ${rem('22px')};
  ${media.tablet`
    margin-bottom: ${rem('18px')};
    line-height: ${rem('20px')};
`};
  ${media.phone`
    margin-bottom: ${rem('15px')};
`};
`;

export const FundOverviewWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: ${rem('20px')};
  ${media.phone`
   padding-bottom: ${rem('10px')};
`};
`;
export const OverviewItemWrapper = styled.div``;
export const OverviewItem = styled.span`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('12px')};
  line-height: ${rem('22px')};
`;
export const FundTopSectionWrapper = styled.div`
  padding: 0 ${rem('15px')};
  @media (max-width: 742px) {
    padding: 0;
  }
  ${media.phone`
    padding: 0 ${rem('15px')};
  `}
`;
export const FundTopWrapper = styled.div`
  display: flex;
  padding-bottom: ${rem('15px')};
  ${media.tablet`
    flex-direction: unset;
    padding-bottom: ${rem('10px')};
  `};
  ${media.phoneToMiniTablet`
    flex-direction: column;
  `}

  ${media.phone`
    padding-bottom: ${rem('10px')};
    flex-direction: column;
`};
`;

// left section wrapper
export const FundsSubSection = styled.div`
  display: flex;
  width: calc(100% - 567px);
  align-items: center;
  box-sizing: border-box;
  ${media.tablet`
   width: calc(100% - 485px);
   flex-direction: column;
`};
  ${media.phoneToMiniTablet`
    width: 100%;
  `}
  ${media.phone`
   width: 100%;
   flex-direction: column;
   margin-bottom: ${rem('20px')};
`};
`;
export const FundsChartSection = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 280px);
  ${media.miniDesktop`
   width: 100%;
`};
`;
export const FundsTotalFundSection = styled.div`
  width: 280px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
  padding: 36.5px 40px;
  box-sizing: border-box;
  position: relative
  &:before {
    position: absolute;
    background: #d9d9d9;
    height: 130px;
    width: 1px;
    display: block;
    left: 0;
    top: 0;
    content: '';
  }
  &: has(div#IndicativeDiv) {
    &:before {
      position: absolute;
      background: #d9d9d9;
      height: 130px;
      width: 1px;
      display: block;
      left: 0;
      top: 23px;
      content: '';
    }
  }
  dl {
    padding: 10px 0px !important;
    @media (max-width: 720px) {
      border-top: 1px solid #d9d9d9;
    }
    ${media.phone`
      border-top: 1px solid #d9d9d9;
      `};
  }
  ${media.tablet`
      width : 100%;
      padding: 0;
      border-left: unset;
      &:before {
        display: none;
      }
      &: has(div#IndicativeDiv) {
        &:before {
          display: none;
        }
      }
`};
`;

export const FundsBifurcationSection = styled.div`
  width: ${rem('567px')};
  background: #f8f8f8;
  padding: ${rem('42px')} ${rem('30px')} ${rem('30px')} ${rem('30px')};
  box-sizing: border-box;
  ${media.tablet`
    width: ${rem('485px')};
    padding: ${rem('32px')} ${rem('24px')} ${rem('24px')} ${rem('24px')};
  `};

  ${media.phoneToMiniTablet`
    margin-top: ${rem('15px')};
    width: 100%;
  `}
  ${media.phone`
    width: ${rem('260px')};
    margin-top: 0;
    padding: ${rem('16px')} ${rem('15px')} ${rem('21px')} ${rem('15px')};
    width : 100%;
  `}
`;

export const FundsTotalSubSectionFieldWrapper = styled.dl`
  margin: 0;
  padding: ${rem('11px')} 0 ${rem('11px')} 0;
  border-bottom: 1px solid ${theme.fontColor};
  width: 100%;
  color: ${theme.greyNumberColor};
  display: flex;
  align-items: baseline;
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  &:first-child {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: 1px solid transparent;
    ${media.phone`
      padding: ${rem('10px')} 0 ${rem('10px')} 0;
    `}
  }
  @media (max-width: 742px) {
  }
  ${media.phone`
    padding: ${rem('12px')} 0 ${rem('12px')} 0;
  `}
`;

export const TotalSubSectionLabel = styled.dt`
  margin: 0;
  width: 35%;
  flex-grow: 1;
  text-align: left;
  ${media.tablet`
    width:40%;
  `}
  ${media.phone`
    width:30%;
  `}
`;
export const TotalSubSectionAmount = styled.dd`
  ${FontFamily.BoldFontW01};
  margin: 0;
  width: 65%;
  flex-grow: 1;
  text-align: right;
  word-break: break-word;
  color: ${theme.fontColor};
  ${media.tablet`
    width: 60%;
  `}
  ${media.phone`
    width:50%;
  `}
`;

export const TotalSubSectionStaticText = styled.span`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('14px')};
  color: ${theme.greyNumberColor};
`;

export const FundsTotalCalculationFieldWrapper = styled(
  FundsTotalSubSectionFieldWrapper
)`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('18px')};
  color: ${theme.fontColor};
  padding: ${rem('15px')} 0 ${rem('10px')} 0;
`;

export const TotalCalculationLabel = styled(TotalSubSectionLabel)`
  ${media.phone`
    width:35%;
  `}
`;

export const TotalCalculationAmount = styled(TotalSubSectionAmount)`
  ${media.phone`
    width:65%;
  `}
`;

// right section wrapper
export const FundsTotalPremiumPaidSectionWrapper = styled.div`
  display: flex;
  padding: ${props => (props.hasTotalSubSectionFields ? '0 15px 0 15px' : '0')};
  align-items: ${props =>
    props.hasTotalSubSectionFields ? 'baseline' : 'center'};
  @media (max-width: 742px) {
    padding: 0;
  }
  ${media.phone`
      padding: ${props =>
      props.hasTotalSubSectionFields ? '0 15px 0 15px' : '0'};
    `}
`;

export const FundsTotalPremiumPaidLeftSection = styled.div`
  display: flex;
  width: calc(100% - 567px);
  box-sizing: border-box;
  ${media.tablet`
    width: calc(100% - 485px);
  `}
  @media (max-width: 742px) {
    width: ${props =>
    props.hasTotalSubSectionFields ? `calc(100% - 485px)` : 'auto'};
  }
  ${media.phoneToMiniTablet`
    width: auto;
  `}
  ${media.phone`
    width:auto;
  `}
`;

export const FundsTotalPremiumPaidRightSection = styled.div`
  width: ${rem('567px')};
  background: #f8f8f8;
  box-sizing: border-box;
  padding: ${rem('20px')} ${rem('20px')} ${rem('20px')} ${rem('30px')};
  ${media.tablet`
    width: ${rem('485px')};
    padding: ${rem('20px')} ${rem('20px')} ${rem('20px')} ${rem('25px')};
  `};
  @media (max-width: 742px) {
    width: ${props => (props.hasTotalSubSectionFields ? '78%' : '100%')};
  }

  ${media.phoneToMiniTablet`
    width: 100%;
    margin-top: ${props =>
      props.hasTotalSubSectionFields ? '0' : `${rem('15px')}`};
  `}

  ${media.phone`
    width : 100%;
    padding: ${rem('15px')};
    margin-bottom: ${rem('20px')};
  `}
`;
export const FundsTotalPremiumPaidFieldWrapper = styled.dl`
  width: 100%;
  display: flex;
  align-items: baseline;
  ${FontFamily.RegularFontW01};
  font-size: ${rem('16px')};
  color: ${theme.fontColor};
  ${media.phone`
    font-size: ${rem('14px')};
  `}
`;

export const TotalPremiumPaidLabel = styled(TotalSubSectionLabel)`
  line-height: ${rem('24px')};
  ${media.phone`
    width:35%;
  `}
`;

export const TotalPremiumPaidAmount = styled(TotalSubSectionAmount)`
  line-height: ${rem('24px')};
  ${media.phone`
    width:65%;
  `}
`;

export const FundRatingDate = styled.div`
  ${FontFamily.RegularFontW01};
  display: block;
  color: #575757;
  font-size: 14px;
  margin-top: 10px;
`;

export const FundCodeStyle = styled.div`
  ${FontFamily.RegularFontW01};
  display: inline-block;
  color: #333333;
  font-size: 14px;
`;

export const FundNameStyle = styled.div`
  ${FontFamily.BoldFontW01};
  display: inline-block;
  color: #252525;
  font-size: 14px;
  margin-right: 10px;
  ${media.phone`
  color:#333300;
`};
`;

export const RatingIcon = styled.span`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0 ${rem('10px')} 0 0;
  height: ${rem('20px')};
  width: ${rem('20px')};
  min-height: ${rem('20px')};
  min-width: ${rem('20px')};
  background-color: ${props => props.iconColor};
  color: #ffffff;
  font-size: ${rem('12px')};
`;

export const RatingLine = styled.span`
  ${FontFamily.RegularFontW01};
  color: #252525;
  font-size: 14px;
  ${media.phone`
    font-size:12px;
  `}
`;
export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const RiskLevelLink = styled(SectionLink)`
  margin: 0;
  padding-top: ${rem('15px')};
  ${media.phone`
    margin:0;
    padding-top:15px;
  `}
`;
export const RiskRatingSectionLabel = styled(SectionLabel)`
  text-decoration: underline;
  ${media.phone`
    font-size:${em('12px')};
  `}
`;
export const TextLabel = styled.dd`
  ${FontFamily.BoldFontW01};
  margin: 0;
  margin-inline-start: 0;
  ${media.phone`
  span {
    font-size: ${em('14px')};
  }
  `}
`;
export const AlertWrapper = styled.div`
  margin-top: ${props =>
    props.type === 'pageLevel' ? rem('10px') : rem('30px')};
  ${media.tablet`
  margin-top: ${props => (props.type === 'pageLevel' ? '0px' : rem('30px'))};
  `}
  ${media.phone`
  margin-top: ${props => (props.type === 'pageLevel' ? '0px' : rem('20px'))};
  `}
`;

export const WarningText = styled.span`
  line-height: ${rem('22px')};
`;
export const FundDisclaimer = styled.div`
  font-size: ${em('12px')};
  padding-top: ${rem('30px')};
  > span {
    display: block;
    line-height: ${rem('18px')};
    padding-bottom: ${rem('10px')};
    & ol li {
      padding-bottom: ${rem('5px')};
      line-height: ${rem('18px')};
    }
  }
  ${media.phone`
  padding: 0 ${rem('15px')} ${rem('15px')} ${rem('15px')}
  `}
`;
const ReturnAmountGCVStyle = `
padding: ${rem('12px')} 0 ${rem('12px')} 0;
font-size: ${em('14px')};
line-height: ${rem('18px')};
border-bottom: 1px solid ${theme.lightGreyColor};
`;
export const ReturnsValueWrapper = styled.dl`
  margin: 0;
  box-sizing: border-box;
  font-size: ${em('18px')};
  color: ${theme.fontColor};
  display: flex;
  align-items: ${props => (props.dependentContentData ? 'center' : 'baseline')};
  width: 100%;
  dt > div {
    font-size: ${rem('14px')};
  }
  ${media.phone`
    align-items: baseline;
    font-size: ${props =>
      props.fieldValue === 'GCV' ? em('14px') : em('16px')}
  `}
  ${props => props.fieldValue === 'GCV' && ReturnAmountGCVStyle}
`;
export const ReturnAmountWrapper = styled(ReturnsValueWrapper)`
  ${ReturnAmountGCVStyle}
  ${media.phone`
  font-size:${em('14px')}
  `}
`;

const ReturnValueLeftGCV = `
line-height: ${rem('18px')};
width: 60%;
color: ${theme.greyNumberColor};
@media(max-width:330px){
  width:55%
}
`;
export const ReturnsValueLeft = styled.dt`
  width: 50%;
  text-align: left;
  line-height: ${rem('24px')};
  @media (max-width: 330px) {
    width: 45%;
  }
  div[role="tooltip"]{
    line-height:${rem('18px')}
  }
  }
  ${props => props.fieldValue === 'GCV' && ReturnValueLeftGCV}
`;
export const ReturnAmountLabelLeft = styled(ReturnsValueLeft)`
  ${ReturnValueLeftGCV}
`;
const ReturnValueRightGCV = `
width: 40%;
span {
  line-height: ${rem('18px')};
}
@media(max-width:330px){
  width:45%
}
`;
export const ReturnsValueRight = styled.dd`
  margin: 0;
  width: 50%;
  text-align: right;
  span {
    line-height: ${rem('24px')};
  }
  span span {
    ${FontFamily.BoldFontW01};
    margin: 0 ${rem('5px')} 0 0;
  }
  @media (max-width: 330px) {
    width: 55%;
  }
  ${props => props.fieldValue === 'GCV' && ReturnValueRightGCV}
`;
export const ReturnAmountValueRight = styled(ReturnsValueRight)`
  ${ReturnValueRightGCV}
`;
export const ReturnSurrenderWrapper = styled.div`
  font-size: ${em('14px')};
  line-height: ${rem('20px')};
  margin: ${rem('10px')} 0 ${rem('10px')} 0;
  color: ${theme.greyNumberColor};
  text-align: left;
`;

export const ReturnValue = styled.div`
  width: ${props =>
    props.fieldValue === 'GCV' || props.dependentContentData
      ? '100%'
      : 'calc(100% - 11px)'};
`;

export const LifeOrGiBanner = styled.div`
  margin-top: ${rem('20px')};

  section {
    margin-bottom: 0px;
    div:first-child {
      margin-bottom: 0px;
    }
  }
`;

export const DurationSpan = styled.span`
  padding: 0 ${rem('2px')};
`;

export const ReturnToolTip = styled.span`
  padding-top: 4px;
`;

export const FaqHeader = styled(SectionHeader)`
  padding-bottom: ${rem('20px')};
  border-bottom: 1px solid ${theme.lightGreyColor};
`;
export const FaqBody = styled.div`
 > div{
  border-bottom: 1px solid ${theme.lightGreyColor};
 }
button{
  ${FontFamily.BoldFontW01}
  font-size:${em('16px')};
  line-height:${rem('22px')};
  padding: ${rem('16px')} 0;
  box-sizing: border-box;
  align-items: inherit;
  text-align: left;
  border-bottom: none;
  span[data-attribute="img"] {
    height: inherit;
    position:inherit;
    padding-right:${rem('8px')}
    padding-left:0;
    ${media.tablet`padding-right:0`}
  }
  ${media.phone`
      font-size:${em('14px')};
      line-height:${rem('20px')};`}
 }`;
export const FaqQues = styled.div`
  box-sizing: border-box;
  display: block;
  width: calc(100% - 11px);
`;

export const FaqAns = styled.div`
    padding-top: ${rem('4px')};
    width: 83%;
    padding-bottom: ${rem('16px')};
 > span{
   font-size:${em('16px')};
   line-height:${rem('22px')};
   > ul{
    margin: 0;
    padding: 0;
    padding-top: ${rem('20px')};
    list-style-position: outside;
    padding-left: ${rem('20px')};
   }
   ul li {
    line-height:${rem('22px')}
    padding-bottom: ${rem('8px')};
    ${media.phone`
    line-height:${rem('20px')}
    `}
   }
   ${media.phone`
   font-size:${rem('14px')}
   line-height:${rem('20px')}
   `}
 }
 p{
  line-height:${rem('22px')};
  margin: 0;
  padding-top: ${rem('20px')};
 }
 p > span {
   display:block;
   &:first-child{
    padding-bottom:${rem('8px')};
   }
   line-height:${rem('22px')}
   ${media.phone`
   line-height:${rem('20px')}
   `}
 }
 ${media.tablet`
 width:100%;
 padding-top: 0;
`}
`;

export const SubHeaderReturns = styled.span`
  color: ${theme.greyNumberColor};
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  padding-top: ${rem('5px')};
`;

// Reprojection Entry Card CSS Styles
export const ReproWrapperDiv = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction : row
 padding : ${rem('66px')} ${rem('30px')} ${rem('30px')} ${rem('105px')};
 box-sizing: border-box;
 ${media.tablet`
    padding : ${rem('74px')} ${rem('30px')} ${rem('24px')} ${rem('18px')};
 `}
 ${media.phone`
  flex-direction: column;
  padding : ${rem('14px')} 0 ${rem('18px')} 0;
 `}
`;

export const ReproLeftDiv = styled.div`
  width: 30%;
  ${media.tablet`
    width : 43%;
  `} ${media.phone`
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;

export const ReproRightDiv = styled.div`
  width: 70%;
  ${media.tablet`
    width:57%;
  `} ${media.phone`
    width: 100%;
  `};
`;

export const ReproIntroSection = styled.div`
  font-size: ${em('22px')};
  line-height: ${rem('31px')};
  padding: ${rem('38px')} ${rem('80px')} ${rem('51px')} ${rem('71px')};
  ${FontFamily.LightFontW01};

  ${media.tablet`
    padding : ${rem('34px')} ${rem('30px')} ${rem('36px')} ${rem('38px')};
    font-size: ${em('19px')};
    line-height: ${rem('27px')};
  `}
  ${media.phone`
    padding : ${rem('20px')} 0 ${rem('13px')} 0;
  `}
`;
export const ReproImage = styled.div`
  width: ${rem('278px')};
  height: ${rem('151px')};
  ${media.phone`
    width: ${rem('258px')};
    height: ${rem('140px')};
  `}
`;

export const MedicalTotalSubSectionFieldWrapper = styled.div`
  margin: 0;
  padding: ${rem('11px')} 0 ${rem('11px')} 0;
  border-bottom: 1px solid ${theme.borderBottomColor};
  width: 100%;
  display: flex;
  align-items: baseline;
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  &:first-child {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: 1px solid transparent;
    ${media.phone`
    padding: ${rem('10px')} 0 ${rem('10px')} 0;
  `}
  }
  &:nth-child(1) {
    border-bottom: 1px solid ${theme.borderBottomTotalAmount};
  }
  ${media.tablet`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  padding: ${rem('10px')} 0 ${rem('10px')} 0;
  `}
  ${media.phone`
  padding: ${rem('12px')} 0 ${rem('12px')} 0;
`}
`;

export const MedicalSubSectionAmount = styled.div`
  ${TotalSubSectionAmount};
  ${props =>
    props.fontWeight ? FontFamily.BoldFontW01 : FontFamily.RegularFontW01};
  > span > span {
    margin-right: 5px;
  }
`;

export const MedicalLegendIcon = styled(RatingIcon)`
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  border-radius: 0;
  position: relative;
  top: 2px;
  ${media.tablet`
    width: 12px;
    height: 12px;
    `}
`;

export const MedicalDisclaimerWrapper = styled.span`
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  ${media.phone`
    margin-bottom: ${rem('24px')}
  `}
`;

export const ChildProtectionWrapper = styled.dl`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;

  ${media.phone`
  position: unset;
  dt{
    width: 100%;
    padding: 12px 0 12px 0;
  }
  `};
`;

export const ChildProtectionAmountClubWrapper = styled(
  OverviewInfoFieldAmountCurrency
)`
  display: flex;
  padding-bottom: 10px;
  > span {
    padding-right: 5px;
    line-height: 20px;
  }
  ${media.phone`
    text-align: left;
    width: 100%;
    > span{
      display:block;
      padding:0;
    }
  `}
`;

export const RiderSubCategoryWrapper = styled.span`
  line-height: 14px;
`;

export const InstructionHistoryWrapper = styled.div`
  > a{
    margin-right:${rem('18px')};
margin-bottom: ${props => (props.noMarginBottom ? 0 : `${rem('20px')}`)};
display:flex;
padding:0
cursor: pointer;
border: none;
color: ${theme.fontColor};
line-height:${rem('18px')};
text-decoration:none;
&:focus, &:hover {
  outline: 1px dotted black;
}
${media.tablet`
margin-bottom: ${rem('20px')};
`}
${media.phone`
width:100%;
margin-bottom: ${rem('20px')};
`}
  }
`;

export const InstructionLinkStyle = styled.span`
  display: inline !important;
  > a {
    ${FontFamily.RegularFontW01};
    color: ${theme.downIconColor};
    background: none;
    -webkit-appearance: none;
    display: inline;
    padding: 0;
    border: 0;
    font-size: ${rem('16px')};
    text-align: center;
    cursor: pointer;
    color: #333333;
    &:focus,
    &:hover {
      outline: 1px dotted #333;
    }
    ${media.phone`
    font-size: ${rem('12px')};
    text-align: left;
  `};
  }
`;

export const InstructionNotificationSpan = styled(NotificationIconSpan)`
  position: relative;
  top: 3px;
`;

/** surrender charge styles */
export const SurrenderChargeSectionWrapper = styled.div`
  margin-top: 30px;
  ${media.tablet`
    margin-top: 28px;
  `}
  ${media.phone`
    margin: 10px 0 25px 0;
    padding: 0px 15px 0 15px;
  `}
`;

export const SurrenderChargeWrapper = styled.div`
  border: solid 1px #d7d8d5;
  padding: 24px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  ${media.tablet`
    padding: 20px;
    align-items: flex-end;
  `}
  ${media.phone`
    padding: 16px;
    flex-direction: column;
  `}
`;

export const SurrenderChargeLeftSection = styled.div`
  width: ${props => (props.showLink ? '70%' : '100%')};
  display: flex;
  ${media.phone`
    width: 100%;
  `}
`;

export const SurrenderChargeRightSection = styled.div`
  width: 30%;
  ${FontFamily.RegularFontW01}
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  font-size: ${rem('16px')};
  padding-top: ${rem('30px')};
  display:flex;
  justify-content:flex-end;
   &:empty{
    padding-top:0;
  }
    ${media.phone`
    align-items: flex-end;
    flex-direction: column;
    padding:${props =>
      props.isPaymentSection
        ? `${rem('15px')} ${rem('15px')} ${rem('15px')} 0`
        : 0};
      text-align: right;
      padding-top: 16px;
      width: 100%;
`}
`;
export const SurrenderChargeIconWrapper = styled.span`
  width: auto;
  height: auto;
  margin-right: ${rem('12px')};
  display: block;
`;

export const SurrenderImage = styled.img`
  width: 24px;
  height: 24px;
`;
export const SurrenderChargeText = styled.span`
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
`;

export const SectionFooterButton = styled.button`
  padding:0
  display: flex;
  cursor: pointer;
  background: white;
  border: none;
  color: ${theme.fontColor};
  text-decoration:none;
  justify-content: flex-end
  align-items: center;
  margin: 0;
  &:focus,&:hover{
    outline: 1px dotted black;
  }
  ${media.phoneToMiniTablet`
  margin: 0;
  width:100%;
  align-items: center;
`}
${media.phone`
  width: 100%;
`}
`;
export const SurrenderLinkLabel = styled.span`
  display: block;
  margin-right: ${rem('9px')};
`;
export const IndicativeDiv = styled.div`
  ${media.phoneToMiniTablet`
    display: flex;
    width: 100%;
    padding:10px 0;
    border-bottom: 1px solid #D7D8D6;
  `}
`;

export const IndicativeText = styled.div`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};
  font-weight: 350;
  ${media.phoneToMiniTablet`
    font-size: ${rem('14px')};
    line-height: ${rem('20px')};
    width:50%;
  `}
`;

export const IndicativeAmtDiv = styled.div`
  ${FontFamily.RegularFontW01}
  font-size: ${rem('12px')};
  line-height: ${rem('16px')};
  font-weight: 400;
  color:${props => props.gainLossTextColor || '#333'};
  ${media.phoneToMiniTablet`
    font-size: ${rem('14px')};
    line-height: ${rem('20px')};
    width:50%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
`}


`;
export const IndicativeAmtImgDiv = styled.span`
  ${media.phoneToMiniTablet`
   line-height: ${rem('22px')};
  `}
`;
export const IndicativeGainLossAmt = styled.span`
  margin: 0 5px 0 0;
  ${media.phoneToMiniTablet`
   line-height: ${rem('22px')};
  `}
`;
export const IndicativePercentage = styled.span`
  margin: 0;
  ${media.phoneToMiniTablet`
   line-height: ${rem('22px')};
  `}
`;

export const ChevrronImg = styled.img`
  position: relative;
  object-fit: contain;
  margin-right: ${rem('5px')};
  width: ${rem('12px')};
  height: ${rem('7px')};
  bottom: 1px;
  transform: ${props =>
    props.iconType === 'isPositive' ? 'rotate(0deg)' : 'rotate(180deg)'};
`;

export const GainLossDisclaimerText = styled.div`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  padding: ${rem('20px')} 0 ${rem('20px')} 0;
  ${media.tablet`
  padding: ${rem('20px')} 0 ${rem('20px')} 0;
  `}
  ${media.phone`
  padding: 0 ${rem('15px')} ${rem('15px')} ${rem('15px')};
  `}
`;

export const OverviewNotificationDiv = styled.div`
    padding-bottom: : 1.875rem;
  ${media.tablet`
      padding-bottom: : 1.5625rem;  `}
  ${media.phone`
      padding-bottom: : 1.25rem;  `}
`;
export const ReprojectionLinkWrapper = styled.span`
  line-height: ${rem('24px')};
  > a {
    padding:0
    align-items: center;
    &:focus,
    &:hover{
      cursor: pointer;
    }
  }
  ${media.phone`
    display: block;
  `}
`;
export const ReprojectionLabel = styled.span`
  margin-right: ${rem('9px')};
  margin-left: 3px;
  color: ${theme.fontColor};
  text-decoration: underline;
  ${media.phone`
    width:100%;
    margin-left: 0px;
  `}
`;
export const NotificationMessageStyled = styled(NotificationMessage)`
  span {
    display: inline-block;
    line-height: 1.25rem;
  }
`;