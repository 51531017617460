import _ from 'lodash';
import { doOR } from '../common/util/commonUtil';

const prepareReprojectionStore = (reprojectionsResponse = {}) => {
  const { reprojections } = reprojectionsResponse;
  const {
    policyOverview: { annuityStartFlag, encodePolicyNumber },
    reprojectionDetails: { payoutBenefit, surrenderDetails, deathDetails }
  } = reprojections;
  const reprojection = {};

  /**
   * Need to have the pay year as the key for payoutBenefit,surrenderDetails and deathDetails
   */
  const filterPayoutDetails = (payoutDetails, payAgeKey) => {
    /**
     * Case 1: For Annuity and annuityStartFlag ===true
     * Case 2: For Non-Annuity products
     * We display as it is.
     *
     */
    if (doOR(annuityStartFlag, annuityStartFlag === undefined)) {
      return _.keyBy(payoutDetails, payAgeKey);
    }
    const len = payoutDetails.length;
    let i = 0;
    for (; i < len; i += 1) {
      const { totPayOutAmt } = payoutDetails[i];
      if (totPayOutAmt) {
        break;
      }
    }

    return _.keyBy(_.slice(payoutDetails, i, len), payAgeKey);
  };

  const payoutBenefitsObj = filterPayoutDetails(
    payoutBenefit.payoutDetails,
    'payAge'
  );
  const surrenderDetailsObj = _.keyBy(surrenderDetails, 'planAtAge');
  const deathDetailsObj = _.keyBy(deathDetails, 'planAtAge');

  reprojections.reprojectionDetails.payoutBenefit.payoutDetails = payoutBenefitsObj;
  reprojections.reprojectionDetails.surrenderDetails = surrenderDetailsObj;
  reprojections.reprojectionDetails.deathDetails = deathDetailsObj;

  reprojection[encodePolicyNumber] = reprojections;

  return reprojection;
};

export const isPolicyInException = function isPolicyInException(
  warningsOrErrors
) {
  const { errors = [] } = warningsOrErrors;
  if (errors.length > 0) {
    return true;
  }
  return false;
};

export const compareKeyValueToAPIValue = function compareKeyValueToAPIValue(
  expectedValue,
  apiValue,
  valueType
) {
  switch (valueType) {
    case 'boolean':
      return expectedValue === apiValue;
    case 'arrayMatch':
      if (expectedValue && apiValue) {
        if (expectedValue.length > apiValue.length) {
          return _.difference(expectedValue, apiValue).length === 0;
        }
        return _.difference(apiValue, expectedValue).length === 0;
      }
      return false;
    case 'arrayInclude':
      return _.includes(expectedValue, apiValue);
    case 'arrayNotInclude':
      return !_.includes(expectedValue, apiValue);
    case 'string':
      return expectedValue === apiValue;
    default:
      return false;
  }
};

export const getEveryKeyCheck = (fieldsArray, reprojectionsDetails) => {
  if (fieldsArray.length > 0) {
    return _.every(fieldsArray, field => {
      const { fieldParent, keyName, keyValue, valueType } = field;
      const fieldIdentifierKey = `${fieldParent}.${keyName}`;
      const getAPIValue = _.get(reprojectionsDetails, fieldIdentifierKey);
      return compareKeyValueToAPIValue(keyValue, getAPIValue, valueType);
    });
  }
  return true;
};

export const getConfiguratorIdentifier = (
  reprojection,
  identifierCriterias = []
) => {
  let configIdentifier;
  if (identifierCriterias.length > 0) {
    _.each(identifierCriterias, identifierCriteria => {
      const { commonChecks = [], conditions = [] } = identifierCriteria;
      const isMatchForCommonCheck = getEveryKeyCheck(
        commonChecks,
        reprojection
      );

      if (isMatchForCommonCheck && conditions.length > 0) {
        _.each(conditions, condition => {
          const { checksToMatch = [], identifier } = condition;
          const isMatchForConditions = getEveryKeyCheck(
            checksToMatch,
            reprojection
          );
          if (isMatchForConditions) {
            configIdentifier = identifier;
            return !configIdentifier;
          }
          return true;
        });
      }
      return !configIdentifier;
    });
  }
  return configIdentifier || 'DEFAULT';
};

export const getInitialCheckboxState = (tabLists, chartFields) => {
  const defaultStateKeyMappings = {
    PayoutBenefit: 'payoutBenefits',
    SurrenderValue: 'cashSurrender',
    DeathBenefit: 'deathBenefits'
  };
  const defaultValues = {};
  _.each(tabLists, tabList => {
    const { tabIdentifier, section } = tabList;
    const mappedKey = defaultStateKeyMappings[tabIdentifier];
    defaultValues[mappedKey] = {
      includeNonGuaranteedPayout:
        section && chartFields[section]
          ? chartFields[section].chartIncludeNGCheckbox.show
          : chartFields.default.chartIncludeNGCheckbox.show
    };
  });
  return { defaultTabValues: defaultValues };
};

export const getChartCheckboxInfo = (chartFields, sectionToRender) => {
  return chartFields[sectionToRender]
    ? chartFields[sectionToRender]
    : chartFields.default;
};

export default prepareReprojectionStore;
