import { select, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { SUBMIT_ESTATEMENT_PREFERENCE } from '../actionTypes';
import {
  getPayloadForEStatementSubmit,
  prepareEStatementPrefSubmitConfig
} from '../utils/sagaUtils';
import {
  endLoader,
  setEstatementPrefAckDetails,
  setEstatementPrefWarningsOrErrors,
  startLoader
} from '../app/appActions';

const getCustomerIdentifier = state => state.customer.customerIdentifier;
const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* submitEstatementPref(action) {
  const { payload: newStmtPref = null } = action;
  try {
    const customerIdentifier = yield select(getCustomerIdentifier);
    const configurationObj = yield select(getConfigurations);
    yield put(startLoader());
    if (customerIdentifier) {
      const {
        genericConfig: { commonHeaders },
        serviceConfig: { eStatementPreference }
      } = configurationObj;
      const saasAuth = yield select(getSaasToken);
      const headers = {
        ...commonHeaders,
        Authorization: `Bearer ${saasAuth}`,
        'X-HSBC-CustomerId': customerIdentifier
        // 'X-HSBC-Locale': localeToCookie[selectedLocale]
      };
      const eStatementPrefConfig = prepareEStatementPrefSubmitConfig(
        eStatementPreference,
        headers
      );
      eStatementPrefConfig.data = getPayloadForEStatementSubmit(newStmtPref);
      yield put(setEstatementPrefWarningsOrErrors(undefined));
      const eStatementPrefSubmitResponse = yield call(
        axios,
        eStatementPrefConfig
      );
      // need to save response
      const eStatementPrefResponseData = eStatementPrefSubmitResponse.data;
      yield put(
        setEstatementPrefAckDetails({
          eStatementPreferenceDetails: eStatementPrefResponseData
        })
      );
      yield put(endLoader());
    }
  } catch (err) {
    if (err.response && err.response.data.errors) {
      yield put(
        setEstatementPrefWarningsOrErrors({ errors: err.response.data.errors })
      );
    } else {
      yield put(
        setEstatementPrefWarningsOrErrors({
          errors: [{ errorCode: 'UI0001', causes: 'Invalid Request' }]
        })
      );
    }
    yield put(endLoader());
  }
}
export default function* eStatementPreferenceSaga() {
  yield takeEvery(SUBMIT_ESTATEMENT_PREFERENCE, submitEstatementPref);
}
