import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import singleUser from '../../../assets/images/User.svg';
import {
  CardContainer,
  CardDetailsWrapper,
  CardFundNameLabel,
  CardList,
  CardListKey,
  CardListValue,
  FundLabel
} from '../../templates/CardMobileViewStyle';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import BeneficaryFirstField from './BeneficiaryFirstField';
import {
  FundWrapper,
  ImgStyleProfileIcon,
  TableIconWrapper,
  TableIconWrapperContainer,
  WrapperContainer,
  FieldTooltip
} from './beneficiarySectionStyles';
import { relationFinder } from '../../../utils/BeneficiaryUtils';

const BeneficaryTableMobile = (props, context) => {
  const {
    fieldData,
    type,
    rawData,
    irrevocableAssigned,
    beneficiaryType
  } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (rawData.length > 0) {
      setData(rawData);
    }
  }, [rawData]);

  const {
    intl: { formatMessage }
  } = context;
  return (
    <FundWrapper type={type}>
      {fieldData &&
        fieldData.map((fieldDataInstance, parentIndex, key) => {
          return (
            <>
              <CardContainer
                key={key}
                id={`${type}_${parentIndex}`}
                firstCard={parentIndex === 0}
                type={type}
              >
                {Object.keys(fieldDataInstance).map((linkdetailsKey, index) => (
                  <>
                    {index === 0 && (
                      <CardFundNameLabel>
                        <WrapperContainer
                          isNameFieldEmpty={
                            _.isEmpty(fieldDataInstance[linkdetailsKey]) ||
                            rawData[parentIndex]?.missingInfo
                          }
                        >
                          <TableIconWrapperContainer>
                            <TableIconWrapper>
                              <ImgStyleProfileIcon
                                aria-hidden="true"
                                alt=""
                                src={singleUser}
                              />
                            </TableIconWrapper>
                          </TableIconWrapperContainer>
                          <FundLabel
                            aria-label={formatMessage({ id: linkdetailsKey })}
                          >
                            <BeneficaryFirstField
                              field={fieldDataInstance[linkdetailsKey]}
                              item={data[parentIndex]}
                              irrevocableAssigned={irrevocableAssigned}
                              isNameFieldEmpty={_.isEmpty(
                                fieldDataInstance[linkdetailsKey]
                              )}
                              beneficiaryType={beneficiaryType}
                              missingInfoFlag={
                                rawData[parentIndex]?.missingInfo
                              }
                            />
                          </FundLabel>
                        </WrapperContainer>
                      </CardFundNameLabel>
                    )}

                    {index !== 0 && (
                      <CardDetailsWrapper>
                        <CardList>
                          <CardListKey>
                            {formatMessage({ id: linkdetailsKey })}
                          </CardListKey>

                          {_.trim(
                            fieldDataInstance[linkdetailsKey],
                            ' '
                          ).toUpperCase() === 'SELF' ? (
                            <CardListValue>
                              {formatMessage({
                                id: 'manage_Beneficiaries_own_estate'
                              })}
                              <FieldTooltip>
                                <TooltipWrapper
                                  id="tooltip_id_viewBeneRole_Tooltip"
                                  contentKey="ownEstateBeneficiary"
                                  labelContent=" "
                                  sectionHeading=" "
                                />
                              </FieldTooltip>
                            </CardListValue>
                          ) : (
                            <CardListValue>
                              {fieldDataInstance[linkdetailsKey]
                                ? formatMessage({
                                    id: relationFinder(
                                      fieldDataInstance[linkdetailsKey]
                                    )
                                  })
                                : ''}
                            </CardListValue>
                          )}
                        </CardList>
                      </CardDetailsWrapper>
                    )}
                  </>
                ))}
              </CardContainer>
            </>
          );
        })}
    </FundWrapper>
  );
};
BeneficaryTableMobile.propTypes = {
  fieldData: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  irrevocableAssigned: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.any),
  beneficiaryType: PropTypes.string.isRequired
};

BeneficaryTableMobile.defaultProps = {
  rawData: []
};

BeneficaryTableMobile.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default BeneficaryTableMobile;
