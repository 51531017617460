import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import {
  ReturnsIndividualFieldLabel,
  ReturnsIndividualFieldValue,
  ReturnsIndividualFieldWrapper
} from '../../sections/SectionStyle';
import DateComponent from '../../../common/date/Date';

const ReturnsDateField = function ReturnsDateField(props, context) {
  const { fieldData, labelContent } = props;
  const {
    intl: { locale, formatMessage }
  } = context;

  if (!fieldData) {
    return <></>;
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>
        <ReturnsIndividualFieldWrapper>
          <ReturnsIndividualFieldLabel>
            {formatMessage({
              id: labelContent
            })}
          </ReturnsIndividualFieldLabel>
          <ReturnsIndividualFieldValue>
            <DateComponent
              date={fieldData}
              locale={locale}
              apiDateFormat="YYYY-MM-DD"
              id="standaradDateFormat"
            />
          </ReturnsIndividualFieldValue>
        </ReturnsIndividualFieldWrapper>
      </ShowIf>
    </>
  );
};

ReturnsDateField.propTypes = {
  fieldData: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired
};

ReturnsDateField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReturnsDateField;
