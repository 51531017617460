import { localMapping } from '../configure/localeDataConfig';

const getContentInSpecificLang = (contentKey, selectedLocale, langObj) => {
  const locale = localMapping[selectedLocale];
  const contentObj = locale && langObj && langObj[locale];
  return contentObj && contentObj.content && contentObj.content[contentKey];
};

export const checkContentInContext = (contentKey, messages) => {
  return messages && messages[contentKey];
};

export default getContentInSpecificLang;
