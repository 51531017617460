import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  CancelRequestWrapper,
  SeeDetail,
  CancelImgWrapper,
  IconRightImg,
  CancelRequestLink
} from '../instructionHistoryStyle';
import cancelIcon from '../../assets/images/cancel_icon.svg';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import { setLinkFocus } from '../../utils/domUtils';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import { trackViews, trackEvents } from '../../utils/tealiumUtils';
import {
  generateCommaSeparatedRefNumbers,
  getUniqueErrorCodes
} from '../instructionUtils';

const CancelRequest = (props, context) => {
  const {
    linkSrc,
    referenceNo,
    encodePolicyNumber,
    cancelRequestHandler,
    cancelRequestWarningsOrErrors,
    cancelRequestData,
    resetCancelData,
    toggleAccordion,
    customerSegment
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  const [isModalOpen, updateModalState] = useState(false);
  const [isErrorModalOpen, updateErrorState] = useState(false);

  const linkRef = React.createRef();

  const footerButtonList = [
    {
      className: 'whiteBtn',
      label: formatMessage({ id: 'cancel_instruction_no_btn' }),
      type: 'button',
      id: 'cancel_btn',
      align: 'left'
    },
    {
      className: 'redBtn',
      label: formatMessage({ id: 'cancel_instruction_yes_btn' }),
      type: 'button',
      id: 'continue_btn',
      align: 'right'
    }
  ];

  const footerButtonErrorPopupList = [
    {
      className: 'redBtn',
      label: formatMessage({ id: 'cancel_instruction_tryagain_btn' }),
      id: 'retry_btn',
      align: 'left'
    }
  ];

  const callTealiumEvent = btnName => {
    trackEvents({
      eventName: 'onCancelInstructionPopupButton',
      eventContent: btnName,
      customerSegment
    });
  };

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
    setLinkFocus(linkRef.current);
    callTealiumEvent('no');
  };

  const onErrorCancelClick = () => {
    updateErrorState(false);
    resetCancelData(referenceNo);
    setLinkFocus(linkRef.current);
  };

  const onRetryClick = () => {
    resetCancelData(referenceNo);
    cancelRequestHandler({ referenceNo, encodePolicyNumber });
  };

  const checkForErrors = () => {
    return (
      !_.isEmpty(cancelRequestWarningsOrErrors) &&
      _.has(cancelRequestWarningsOrErrors[referenceNo], 'errors') &&
      cancelRequestWarningsOrErrors[referenceNo].errors.length
    );
  };

  const onContinueClick = () => {
    updateModalState(!isModalOpen);
    updateErrorState(true);
    callTealiumEvent('yes, please cancel');
    cancelRequestHandler({ referenceNo, encodePolicyNumber });
  };

  const showErrorPopup = () => {
    return (
      <ModalPopUp
        isModalOpen={isErrorModalOpen}
        onClose={onErrorCancelClick}
        modalHeading={formatMessage({
          id: 'cancel_instruction_error_title'
        })}
        footerButtonList={footerButtonErrorPopupList}
        btnHandler={{
          retry_btn: onRetryClick
        }}
        id="cancelInstructionHistoryErrorPopup"
      >
        <ParagraphDiv>
          {formatMessage(
            { id: 'cancel_instruction_error_description' },
            {
              errorCodes: generateCommaSeparatedRefNumbers(
                getUniqueErrorCodes(
                  cancelRequestWarningsOrErrors[referenceNo].errors
                ),
                'commaLabel',
                formatMessage
              )
            }
          )}
        </ParagraphDiv>
      </ModalPopUp>
    );
  };
  const checkCancelAPIResponse = () => {
    if (!_.isEmpty(cancelRequestData) && cancelRequestData[referenceNo]) {
      toggleAccordion(false);
      return null;
    }
    if (checkForErrors()) {
      return showErrorPopup();
    }
    return null;
  };

  const callTealiumView = () => {
    trackViews({
      viewName: 'onCancelInstructionPopupLoad',
      customerSegment
    });
  };

  return (
    <>
      <CancelRequestWrapper>
        <CancelRequestLink
          href={linkSrc}
          rel="noopener noreferrer"
          target="_self"
          id="cancel_request"
          onClick={e => {
            e.preventDefault();
            updateModalState(!isModalOpen);
            callTealiumView();
          }}
          ref={linkRef}
        >
          <CancelImgWrapper>
            <IconRightImg aria-hidden="true" alt="" src={cancelIcon} />
          </CancelImgWrapper>
          <SeeDetail>{formatMessage({ id: 'cancel_instruction' })}</SeeDetail>
        </CancelRequestLink>
      </CancelRequestWrapper>
      <ModalPopUp
        isModalOpen={isModalOpen}
        onClose={onCancelClick}
        modalHeading={formatMessage({
          id: 'cancel_instruction_title'
        })}
        footerButtonList={footerButtonList}
        btnHandler={{
          continue_btn: onContinueClick,
          cancel_btn: onCancelClick
        }}
        id="cancelInstructionHistoryPopup"
      />
      {checkCancelAPIResponse()}
    </>
  );
};

CancelRequest.contextTypes = {
  intl: PropTypes.object.isRequired
};

CancelRequest.propTypes = {
  linkSrc: PropTypes.string,
  referenceNo: PropTypes.string.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  cancelRequestHandler: PropTypes.func.isRequired,
  cancelRequestWarningsOrErrors: PropTypes.object.isRequired,
  cancelRequestData: PropTypes.func.isRequired,
  resetCancelData: PropTypes.func.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  customerSegment: PropTypes.string.isRequired
};

CancelRequest.defaultProps = {
  linkSrc: '#'
};
export default CancelRequest;
