import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ExploreCollapsibleButton,
  Image,
  CollapsibleWrapper,
  ProductGroupImage,
  ProductTitle
} from '../summaryPageStyles';
import chevronUp from '../../assets/images/cevrron-up.svg';
import chevronDown from '../../assets/images/cevrron-down.svg';
import { trackEvents } from '../../utils/tealiumUtils';

function ExploreCollapsibleSection(props) {
  const { expandedSectionId, title, content, imagePath } = props;
  const sectionName = 'explore_';
  const [setActive, setActiveState] = useState(true);
  const [setDisplay, setDisplayState] = useState('none');
  const [setRotate, setRotateState] = useState(chevronDown);
  const accordianTitle = `${sectionName}${title}`;
  const contentRef = useRef(null);

  function toggleAccordion() {
    setActiveState(!setActive);
    setDisplayState(!setActive ? 'none' : `block`);
    setRotateState(!setActive ? chevronDown : chevronUp);
  }
  return (
    <CollapsibleWrapper>
      <ExploreCollapsibleButton
        setActive={setActive}
        aria-controls={expandedSectionId}
        onClick={() => {
          toggleAccordion();
          trackEvents({
            eventName: 'captureAccordian',
            eventContent: accordianTitle
          });
        }}
        id={title}
        role="region"
        aria-expanded={!setActive}
      >
        <ProductGroupImage aria-hidden="true" alt="" src={imagePath} />
        <ProductTitle>{title}</ProductTitle>
        <Image
          aria-hidden="true"
          alt=""
          src={setRotate}
          setRotate={setActive}
        />
      </ExploreCollapsibleButton>
      <div
        ref={contentRef}
        id={expandedSectionId}
        aria-labelledby={title}
        aria-hidden={setActive}
        style={{ display: `${setDisplay}` }}
      >
        <div>{content}</div>
      </div>
    </CollapsibleWrapper>
  );
}

ExploreCollapsibleSection.propTypes = {
  expandedSectionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  imagePath: PropTypes.string.isRequired
};

export default ExploreCollapsibleSection;
