import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import rightIcon from '../../assets/images/Icon_right.svg';
import {
  ProductWrapper,
  Title,
  SubHeadingSection,
  Tags,
  Divider,
  Content,
  Icon
} from '../summaryPageStyles';
import { getExploreProductLink } from '../summaryUtils/summaryWarningMessageUtils';

const ProductTemplate = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const {
    productTitle,
    productDescription,
    tags,
    link,
    isOpenInNewTab
  } = props;
  const productLink = getExploreProductLink(link, locale);

  return (
    <ProductWrapper>
      <Title
        href={productLink}
        rel="noopener noreferrer nofollow"
        target={isOpenInNewTab ? '_blank' : '_self'}
      >
        {productTitle}
        <Icon src={rightIcon} aria-hidden="true" alt="" />
      </Title>
      <SubHeadingSection>
        {tags.length > 0 && (
          <Tags>
            {_.map(tags, (tag, index) => {
              return (
                <span>
                  {index !== 0 && <Divider />}
                  {formatMessage({ id: tag })}
                </span>
              );
            })}
          </Tags>
        )}
      </SubHeadingSection>
      <Content>
        <FormattedHTMLMessage id={productDescription} />
      </Content>
    </ProductWrapper>
  );
};

ProductTemplate.contextTypes = {
  intl: PropTypes.object.isRequired
};

ProductTemplate.propTypes = {
  productTitle: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
  isOpenInNewTab: PropTypes.bool
};

ProductTemplate.defaultProps = {
  isOpenInNewTab: false
};

export default ProductTemplate;
