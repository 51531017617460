import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import {
  SectionFooterLink,
  FooterLinkLabel,
  FooterLinkImageWrapper,
  IconRightImg
} from './SectionStyle';
import iconRight from '../../assets/images/Icon_right.svg';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import { setLinkFocus } from '../../utils/domUtils';
import FundsPerformanceLink from '../templates/Funds/FundsPerformanceLink';
import { trackEvents } from '../../utils/tealiumUtils';
import getContentInSpecificLang from '../../utils/localeUtils';

const SectionFooterLinkComponent = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    footerButtonList,
    proceedToLink,
    openInNewTab,
    linkId,
    linkLabel,
    showComponent,
    langObj
  } = props;

  let returnLinkComponent;
  let modalID;
  let linkName;
  if (linkId) {
    modalID = `${linkId}-modal`;
  }
  const linkRef = React.createRef();
  const [isModalOpen, updateModalState] = useState(false);

  linkName = getContentInSpecificLang(linkLabel, 'en', langObj);
  linkName = _.toLower(linkName);

  const tealiumEvent = function tealiumEvent() {
    if (linkLabel) {
      trackEvents({
        eventName: 'redirectPopup',
        eventContent: linkName
      });
    }
  };

  const onContinueClick = () => {
    updateModalState(!isModalOpen);
    trackEvents({
      eventName: 'popupButtonEvent',
      eventContent: 'continue',
      linkName
    });
  };

  const onCancelClick = (isModalOpens, onPopupEvent = 'close') => {
    trackEvents({
      eventName: 'popupButtonEvent',
      eventContent: onPopupEvent,
      linkName
    });
    updateModalState(!isModalOpens);
    setLinkFocus(linkRef.current);
  };

  switch (showComponent) {
    case 'fundsPerformance':
      returnLinkComponent = (
        <FundsPerformanceLink
          updateModal={() => updateModalState(!isModalOpen)}
          tealiumEvent={() => tealiumEvent()}
        />
      );
      break;
    default:
      returnLinkComponent = (
        <SectionFooterLink
          id={linkId}
          href={openInNewTab ? '#' : proceedToLink}
          onClick={e => {
            if (openInNewTab) {
              updateModalState(!isModalOpen);
              tealiumEvent();
              e.preventDefault();
            }
          }}
          linkRef={linkRef}
          aria-label={formatMessage({
            id: linkLabel
          })}
          rel="noopener noreferrer"
        >
          <FooterLinkLabel>{formatMessage({ id: linkLabel })}</FooterLinkLabel>
          <FooterLinkImageWrapper>
            <IconRightImg src={iconRight} aria-hidden="true" alt="" />
          </FooterLinkImageWrapper>
        </SectionFooterLink>
      );
      break;
  }
  return (
    <>
      {returnLinkComponent}
      {openInNewTab && (
        <ModalPopUp
          id={modalID}
          isModalOpen={isModalOpen}
          onClose={() => onCancelClick(isModalOpen)}
          modalHeading=""
          footerButtonList={footerButtonList}
          proceedToLink={proceedToLink}
          btnHandler={{
            continue_btn: onContinueClick,
            cancel_btn: () => onCancelClick(isModalOpen, 'cancel')
          }}
        >
          <ParagraphDiv>
            {formatMessage({ id: 'makeaClaimPopUpText' })}
          </ParagraphDiv>
        </ModalPopUp>
      )}
    </>
  );
};

SectionFooterLinkComponent.propTypes = {
  footerButtonList: PropTypes.array.isRequired,
  showComponent: PropTypes.string.isRequired,
  proceedToLink: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool.isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkId: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired
};
SectionFooterLinkComponent.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SectionFooterLinkComponent;
