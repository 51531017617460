import React from 'react';
import PropTypes from 'prop-types';
import {
  ReturnsSectionWrapper,
  ReturnsSectionHeader,
  SectionHeaderText,
  SectionImgSpan,
  ReturnsSectionBody,
  ReturnsTotalSectionWrapper,
  ReturnsAnnuitySection,
  LastMonthlyPaymentWrapper,
  AnnuityInnerWrapper,
  StaticAnnuityBFStatement,
  ReturnsAnnuityFlexWrap,
  ReturnsAnnuityBeforeHeader,
  TotalPaymentsWrapper,
  ReturnsAfterAnnuityFieldsAndStatement,
  AnnuityAFStatement,
  ReturnsAfterAnnuityFields,
  LoanFieldWrapper,
  OptionSectionWrapper,
  OptionToggleLabel,
  OptionSection,
  ReturnsValuationDateWrapper,
  ReturnsValuation,
  ReturnsValuationLeft,
  BFWrapper,
  HeaderWrapper,
  HeaderSubText,
  SectionFooterLinkWrapper,
  SectionFooterLink,
  FooterLinkLabel,
  FooterLinkImageWrapper,
  IconRightImg
} from './SectionStyle';
import {
  DetailsPageMiddleSection,
  ImagePath,
  ReturnToolTip
} from '../detailPageStyle';
import * as templates from '../templates';
import { getFieldDataFromConfig } from '../../utils/policyUtils';
import { doAND, doOR } from '../../common/util/commonUtil';
import ReturnsImage from '../../assets/images/saving.svg'; // update svg
import ToggleSection from '../templates/ToggleSection';
import {
  returnsHeaderResolver,
  returnsHeaderTooltipResolver,
  checkToggleSectionToSuppress,
  checkAmountFieldEmpty,
  getTooltipContentReturns,
  getPolicyCurrency,
  getLabelOptionReturns,
  returnsAfterAnnuityText,
  getOptionArrayStack,
  checkForSuppression,
  getTotalPolicyComponent,
  getTableFields,
  checkFieldArrayNull,
  checkAnnuityPhaseIndicator,
  checkoptionsArrayStack
} from '../sectionUtils/ReturnsUtils';
import TooltipWrapper from '../../common/tooltipWrapper/TooltipWrapper';
import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';
import iconRight from '../../assets/images/Icon_right.svg';
import SectionFooterLinkComponent from './SectionFooterLinkComponent';
import getDocumentLink from '../sectionUtils/SectionsUtils';

export const getFulFillmentRatioLink = function getFulFillmentRatioLink(
  documentResponse,
  locale
) {
  return (
    documentResponse &&
    getDocumentLink('FulfillmentRatio', documentResponse, locale)
  );
};

export const getSubHeaderComponent = function getSubHeaderComponent(
  annuityPhaseIndicator,
  formatMessage
) {
  if (doAND(annuityPhaseIndicator, annuityPhaseIndicator !== '')) {
    return (
      <>
        {annuityPhaseIndicator === 'BF' && (
          <HeaderSubText>
            {formatMessage({ id: 'policyValue_BF_Title' })}
          </HeaderSubText>
        )}
        {annuityPhaseIndicator === 'ON' && (
          <HeaderSubText>
            {formatMessage({ id: 'policyValue_On_Title' })}
          </HeaderSubText>
        )}
        {annuityPhaseIndicator === 'AF' && (
          <HeaderSubText>
            {formatMessage({ id: 'policyValue_Af_Title' })}
          </HeaderSubText>
        )}
      </>
    );
  }
  return <></>;
};

export const renderComponent = function renderComponent(fieldsStack) {
  return (
    fieldsStack &&
    fieldsStack.map(component => {
      return component;
    })
  );
};

export const renderDividendOrCashBonusWithdrawalLink = function renderDividendOrCashBonusWithdrawalLink(
  DividendOrCashBonusWithdrawal,
  formatMessage
) {
  const eventAction = 'onsite';
  return (
    DividendOrCashBonusWithdrawal && (
      <SectionFooterLink
        id="DividendOrCashBonusWithdrawal_Link"
        href={DividendOrCashBonusWithdrawal.url}
        onClick={() => {
          trackEvents({
            eventName: 'sectionLinks',
            eventData: createEventActionContent(
              eventAction,
              'DividendOrCashBonusWithdrawal',
              'policy value'
            )
          });
        }}
        aria-label={formatMessage({
          id: 'DividendOrCashBonusWithdrawal'
        })}
        rel="noopener noreferrer"
        rightSpacing
      >
        <FooterLinkLabel>
          {formatMessage({ id: 'DividendOrCashBonusWithdrawal' })}
        </FooterLinkLabel>
        <FooterLinkImageWrapper>
          <IconRightImg src={iconRight} aria-hidden="true" alt="" />
        </FooterLinkImageWrapper>
      </SectionFooterLink>
    )
  );
};

export const renderFulFillmentRatioLink = function renderFulFillmentRatioLink(
  fulFillmentRatioLink,
  footerButtonList,
  langObj
) {
  return (
    fulFillmentRatioLink && (
      <SectionFooterLinkComponent
        footerButtonList={footerButtonList}
        proceedToLink={fulFillmentRatioLink}
        openInNewTab
        linkId="fulFillmentRatioLink"
        linkLabel="fulFillmentRatioLinkLabel"
        langObj={langObj}
      />
    )
  );
};

export const renderValuationDate = function renderValuationDate(fieldsStack) {
  return (
    fieldsStack && (
      <ReturnsValuation>
        <ReturnsValuationLeft>
          {fieldsStack.map(component => {
            return component;
          })}
        </ReturnsValuationLeft>
      </ReturnsValuation>
    )
  );
};

export const getOptionComponent = function getOptionComponent(args) {
  const [
    fieldConfigInstance,
    fieldDataInstance,
    apiArrayIndex,
    productCategory,
    locale,
    index,
    annuityPhaseIndicator,
    sectionHeading,
    productCategoryKey,
    FieldComponent
  ] = args;

  const arrayFieldData = fieldDataInstance[fieldConfigInstance.fieldName];

  if (!arrayFieldData) {
    return <React.Fragment key={fieldConfigInstance.fieldLabel} />;
  }

  let dependentFieldData;
  if (fieldConfigInstance.dependentField) {
    dependentFieldData = fieldDataInstance[fieldConfigInstance.dependentField];
  }

  let dependentFieldDataTwo;
  if (fieldConfigInstance.dependentFieldTwo) {
    dependentFieldDataTwo =
      fieldDataInstance[fieldConfigInstance.dependentFieldTwo];
  }

  let labelContent;
  if (fieldConfigInstance.fieldLabel) {
    labelContent = fieldDataInstance[fieldConfigInstance.fieldLabel];
  }

  return (
    <FieldComponent
      key={fieldConfigInstance.fieldLabel}
      id={`field_returns_${apiArrayIndex}_options`}
      productCategory={productCategory}
      productCategoryKey={productCategoryKey}
      labelContent={getLabelOptionReturns(
        dependentFieldDataTwo,
        labelContent,
        locale
      )}
      fieldData={arrayFieldData}
      toolTipContent={getTooltipContentReturns(
        fieldConfigInstance,
        labelContent
      )}
      idx={index}
      dependentFieldData={dependentFieldData}
      annuityPhaseIndicator={annuityPhaseIndicator}
      sectionHeading={sectionHeading}
      dependentFieldDataTwo={dependentFieldDataTwo}
    />
  );
};

export const getDependentFieldData = function getDependentFieldData(
  fieldConfigInstance,
  currentPolicyDetail
) {
  let dependentFieldData;
  if (fieldConfigInstance.dependentField) {
    dependentFieldData = getFieldDataFromConfig(
      currentPolicyDetail,
      fieldConfigInstance,
      true,
      'dependentField'
    );
  }
  return dependentFieldData;
};

export const renderTotalSection = function renderTotalSection(
  checkPolicyValue,
  checkTotalSection,
  fieldsStack
) {
  return (
    doAND(checkPolicyValue, checkTotalSection, fieldsStack) &&
    fieldsStack.map(component => {
      return component;
    })
  );
};
export const renderLoanWrapperComponent = function renderLoanWrapperComponent(
  fieldsStack
) {
  return (
    fieldsStack && (
      <LoanFieldWrapper>
        {fieldsStack.map(component => {
          return component;
        })}
      </LoanFieldWrapper>
    )
  );
};

export const renderLoanSection = function renderLoanSection(
  checkPolicyValue,
  checkTotalSection,
  fieldsStack
) {
  return (
    checkPolicyValue &&
    checkTotalSection &&
    renderLoanWrapperComponent(fieldsStack)
  );
};

export const checkSupressionReturnsOption = function checkSupressionReturnsOption(
  annuityPhaseIndicator,
  fieldsStack,
  optionsArrayStack
) {
  return (
    checkAnnuityPhaseIndicator(annuityPhaseIndicator) ||
    checkFieldArrayNull(fieldsStack) ||
    checkoptionsArrayStack(optionsArrayStack)
  );
};

const detailsPageMiddleSection = args => {
  const [
    formatMessage,
    returnsSectionHeader,
    returnsHeaderTooltip,
    sectionHeading,
    annuityPhaseIndicator,
    suppressTotalSection,
    hideTotalPolicyValue,
    fieldsStack,
    returnsTotalSubTitleKey,
    langObj,
    optionsArrayStack,
    displayAnnuityStatement,
    returnOptionHeaderTitle,
    fulFillmentRatioLink,
    footerButtonList,
    DividendOrCashBonusWithdrawal
  ] = args;
  return (
    <>
      <DetailsPageMiddleSection>
        <ReturnsSectionWrapper>
          <ReturnsSectionHeader>
            <HeaderWrapper>
              <SectionImgSpan>
                <ImagePath aria-hidden="true" alt="" src={ReturnsImage} />
              </SectionImgSpan>
              <SectionHeaderText>
                {formatMessage({ id: returnsSectionHeader })}
              </SectionHeaderText>
              <ReturnToolTip>
                <TooltipWrapper
                  id="returnsTitleTooltip"
                  contentKey={returnsHeaderTooltip}
                  labelContent={returnsHeaderTooltip}
                  sectionHeading={sectionHeading}
                  maxWidth={300}
                />
              </ReturnToolTip>
            </HeaderWrapper>
            {getSubHeaderComponent(annuityPhaseIndicator, formatMessage)}
          </ReturnsSectionHeader>
          <ReturnsSectionBody>
            <ReturnsTotalSectionWrapper>
              {doAND(!suppressTotalSection, !hideTotalPolicyValue) && (
                <ToggleSection
                  sectionTitleFormat={fieldsStack[0]}
                  expandedSectionId="total_policy_section"
                  titleId="total_policy_value"
                  tealiumEventContent={returnsTotalSubTitleKey}
                  langObj={langObj}
                >
                  {renderComponent(fieldsStack[1])}
                  {renderLoanWrapperComponent(fieldsStack[2])}
                </ToggleSection>
              )}
              {renderTotalSection(
                !hideTotalPolicyValue,
                suppressTotalSection,
                fieldsStack[0]
              )}
              {renderTotalSection(
                hideTotalPolicyValue,
                !suppressTotalSection,
                fieldsStack[1]
              )}
              {renderLoanSection(
                hideTotalPolicyValue,
                !suppressTotalSection,
                fieldsStack[2]
              )}
            </ReturnsTotalSectionWrapper>
            <ReturnsValuationDateWrapper>
              {renderValuationDate(fieldsStack[3])}
            </ReturnsValuationDateWrapper>
            {checkFieldArrayNull(fieldsStack[4]) && (
              <ReturnsAnnuitySection id="totalPremiumPaid">
                {renderComponent(fieldsStack[4])}
              </ReturnsAnnuitySection>
            )}
            {checkSupressionReturnsOption(
              annuityPhaseIndicator,
              fieldsStack[10],
              optionsArrayStack
            ) && (
              <ReturnsAnnuitySection>
                {checkAnnuityPhaseIndicator(annuityPhaseIndicator) && (
                  <>
                    {doAND(
                      annuityPhaseIndicator,
                      annuityPhaseIndicator === 'BF'
                    ) && (
                      <BFWrapper>
                        <ReturnsAnnuityBeforeHeader>
                          {formatMessage({ id: 'returnsBeforeAnnuityHeader' })}
                        </ReturnsAnnuityBeforeHeader>
                        <ReturnsAnnuityFlexWrap>
                          {renderComponent(fieldsStack[5])}
                        </ReturnsAnnuityFlexWrap>
                        {displayAnnuityStatement && (
                          <StaticAnnuityBFStatement>
                            {formatMessage({
                              id: 'returnsBeforeAnnuityStatement'
                            })}
                          </StaticAnnuityBFStatement>
                        )}
                      </BFWrapper>
                    )}
                    {doAND(
                      annuityPhaseIndicator,
                      annuityPhaseIndicator === 'ON'
                    ) && (
                      <>
                        <AnnuityInnerWrapper>
                          <ReturnsAnnuityFlexWrap>
                            <TotalPaymentsWrapper>
                              {renderComponent(fieldsStack[7])}
                            </TotalPaymentsWrapper>
                            <ReturnsAfterAnnuityFieldsAndStatement>
                              <ReturnsAfterAnnuityFields>
                                {renderComponent(fieldsStack[6])}
                              </ReturnsAfterAnnuityFields>
                              <AnnuityAFStatement>
                                {returnsAfterAnnuityText(
                                  displayAnnuityStatement,
                                  formatMessage
                                )}
                              </AnnuityAFStatement>
                            </ReturnsAfterAnnuityFieldsAndStatement>
                          </ReturnsAnnuityFlexWrap>
                        </AnnuityInnerWrapper>
                      </>
                    )}
                    {doAND(
                      annuityPhaseIndicator,
                      annuityPhaseIndicator === 'AF'
                    ) && (
                      <>
                        <AnnuityInnerWrapper>
                          <ReturnsAnnuityFlexWrap>
                            <TotalPaymentsWrapper>
                              {renderComponent(fieldsStack[7])}
                            </TotalPaymentsWrapper>
                            <ReturnsAfterAnnuityFieldsAndStatement>
                              <ReturnsAfterAnnuityFields>
                                {renderComponent(fieldsStack[8])}
                              </ReturnsAfterAnnuityFields>
                              <AnnuityAFStatement>
                                {returnsAfterAnnuityText(
                                  displayAnnuityStatement,
                                  formatMessage
                                )}
                                <LastMonthlyPaymentWrapper>
                                  {renderComponent(fieldsStack[9])}
                                </LastMonthlyPaymentWrapper>
                              </AnnuityAFStatement>
                            </ReturnsAfterAnnuityFieldsAndStatement>
                          </ReturnsAnnuityFlexWrap>
                        </AnnuityInnerWrapper>
                      </>
                    )}
                  </>
                )}
                <>
                  {checkFieldArrayNull(fieldsStack[10]) && (
                    <OptionSection>
                      <ToggleSection
                        sectionTitleFormat={[
                          <OptionToggleLabel key={fieldsStack[10]}>
                            {formatMessage({
                              id: returnOptionHeaderTitle
                            })}
                          </OptionToggleLabel>
                        ]}
                        expandedSectionId="options_policy_section"
                        titleId="options_policy_value"
                        tealiumEventContent={returnOptionHeaderTitle}
                        langObj={langObj}
                      >
                        <OptionSectionWrapper>
                          {renderComponent(fieldsStack[10])}
                          {renderComponent(optionsArrayStack)}
                        </OptionSectionWrapper>
                      </ToggleSection>
                    </OptionSection>
                  )}
                </>
              </ReturnsAnnuitySection>
            )}
          </ReturnsSectionBody>
          <SectionFooterLinkWrapper>
            {renderFulFillmentRatioLink(
              fulFillmentRatioLink,
              footerButtonList,
              langObj
            )}
            {renderDividendOrCashBonusWithdrawalLink(
              DividendOrCashBonusWithdrawal,
              formatMessage
            )}
          </SectionFooterLinkWrapper>
        </ReturnsSectionWrapper>
      </DetailsPageMiddleSection>
    </>
  );
};

const Returns = function Returns(props, context) {
  const {
    sectionHeading,
    sectionFieldConfig,
    currentPolicyDetail,
    dependentSectionField,
    dependentSectionFieldTwo,
    productCategory,
    productCategoryKey,
    fieldConfig,
    servicingOptionsURL: { DividendOrCashBonusWithdrawal },
    langObj
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  let displayAnnuityStatement = true;
  const footerButtonList = [
    {
      className: 'whiteBtn',
      label: formatMessage({ id: 'cancelPopup' }),
      id: 'cancel_btn',
      align: 'left'
    },
    {
      className: 'redBtn',
      label: formatMessage({ id: 'continuePopup' }),
      id: 'continue_btn',
      align: 'right'
    }
  ];
  /** Both current policy details and sectionFieldConfig would be required to display the returns section. */
  if (checkForSuppression(currentPolicyDetail, sectionFieldConfig)) {
    return null;
  }
  /** DependentSectionFieldConfig will be used to alter the section settings as per this field's value */
  const dependentSectionFieldConfig = fieldConfig[dependentSectionField];
  const dependentSectionFieldTwoConfig = fieldConfig[dependentSectionFieldTwo];

  /** annuityPhaseIndicator will be used to alter the section settings as per this field's value for annuity products */
  const annuityPhaseIndicator = getFieldDataFromConfig(
    currentPolicyDetail,
    dependentSectionFieldConfig,
    true
  );

  /** Suppressing the whole of Returns section when annuityStageIndicator is not present in the APIs response for Annuity products */
  if (
    doAND(
      productCategory,
      productCategory === 'Annuity',
      annuityPhaseIndicator === undefined
    )
  ) {
    return null;
  }

  /** paymentModeReferenceType will be used to alter the balance of payment field(field returned from calculation engine)
   *  settings as per the paymentModeReference value for all products
   */
  const paymentModeReferenceType = getFieldDataFromConfig(
    currentPolicyDetail,
    dependentSectionFieldTwoConfig,
    true
  );
  /** returnsSectionHeader will be used to alter the section header as per annuity phase value for all products
   */
  const returnsSectionHeader = returnsHeaderResolver(
    annuityPhaseIndicator,
    productCategory,
    'returnsHeader'
  );

  const returnsHeaderTooltip = returnsHeaderTooltipResolver(
    productCategory,
    'returnsHeaderTooltip'
  );

  const returnOptionHeaderTitle = returnsHeaderResolver(
    annuityPhaseIndicator,
    productCategory,
    'returnsOptions'
  );

  const returnsTotalSubTitleKey = returnsHeaderResolver(
    annuityPhaseIndicator,
    productCategory,
    'TotalSubTitle'
  );

  const optionsArrayStack = [];

  const fieldsStack = sectionFieldConfig.map(subSectionFieldConfig => {
    return subSectionFieldConfig.map((fieldConfigInstance, index) => {
      const FieldComponent = templates[fieldConfigInstance.uiFormat];
      const fieldDataParent = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfigInstance,
        false
      );

      const isArrayField = fieldDataParent && fieldDataParent.length;

      if (isArrayField) {
        fieldDataParent.forEach((fieldDataInstance, apiArrayIndex) => {
          const OptionComponent = getOptionComponent([
            fieldConfigInstance,
            fieldDataInstance,
            apiArrayIndex,
            productCategory,
            locale,
            index,
            annuityPhaseIndicator,
            sectionHeading,
            productCategoryKey,
            FieldComponent
          ]);

          optionsArrayStack[apiArrayIndex] = getOptionArrayStack(
            optionsArrayStack[apiArrayIndex]
          );
          optionsArrayStack[apiArrayIndex] = OptionComponent;
          return <></>;
        });
      }

      const fieldData = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfigInstance,
        true
      );
      /**
       * isMandatoryToShow check is done for Guaranteed Cash Value field as it always needs to appear on the UI unless needed to be suppressed.
       *isplayAnnuitySatement : Also checking if one of the field that have invalid data response is Monthly Guaranteed Annuity Payment.
       * Setting the boolean value displayAnnuityStatement to false if that field is not available,
       * This is done to hide the annuity statement when the field itself is suppressed.
       */
      if (
        doOR(
          !FieldComponent,
          doAND(!fieldData, !fieldConfigInstance.isMandatoryToShow)
        )
      ) {
        if (fieldConfigInstance.statementHideOnSuppression) {
          displayAnnuityStatement = false;
        }
        return null;
      }
      const dependentFieldData = getDependentFieldData(
        fieldConfigInstance,
        currentPolicyDetail
      );
      // check for policyCurrency Value for no value cases for Total and GCV
      const policyCurrency = getPolicyCurrency(currentPolicyDetail);
      const labelContent = fieldConfigInstance.fieldLabel;
      return (
        <FieldComponent
          key={fieldConfigInstance.fieldLabel}
          id={`field_returns_${index}`}
          productCategory={productCategory}
          productCategoryKey={productCategoryKey}
          labelContent={labelContent}
          fieldData={fieldData}
          toolTipContent={fieldConfigInstance.toolTip}
          idx={index}
          fontChange={typeof value === 'object'}
          dependentContentData={fieldConfigInstance.dependentContent || ''}
          dependentFieldData={dependentFieldData}
          policyCurrency={policyCurrency}
          annuityPhaseIndicator={annuityPhaseIndicator}
          isMandatoryToShow={doOR(fieldConfigInstance.isMandatoryToShow, false)}
          sectionHeading={sectionHeading}
          dependentFieldDataTwo={fieldConfigInstance.dependentFieldTwo}
          paymentModeReferenceType={paymentModeReferenceType}
          planCode={currentPolicyDetail.policyOverview.planCode}
          planCodesToCheck={fieldConfigInstance.planCodesToCheck}
        />
      );
    });
  });
  /**  checkAmountFieldEmpty : check if fieldData has amount/ currency  for totalPolicyValue field */
  const totalPolicyValueComponent = getTotalPolicyComponent(fieldsStack);
  const hideTotalPolicyValue = checkAmountFieldEmpty(
    totalPolicyValueComponent,
    true
  );
  /** checkToggleSectionToSuppress : check if all the fields inside returnsTotalTableSection has amount/ currency  for their respective field
   *  Returns true if every field has no value/ 0 value , to supress the toggle section
   */
  const totalTableFields = getTableFields(fieldsStack, 1);
  const loanSectionFields = getTableFields(fieldsStack, 2);

  const suppressTotalSection = checkToggleSectionToSuppress(
    totalTableFields,
    loanSectionFields
  );
  /**
   * fieldsStack - Contains temporary UI format for all the required Fields.
   * Particular index in this fields are for particular sub sections inside the Returns Sections
   *  Index - Data present in that index
   * 0 - Closing Balance/Remaining Balance/Total for the returns section. Used as a toggler for total table section.
   * 1 - Total Table sections and all the fields associated to it. Regardless of product category, whatever comes from the eAPIs response,
   *     it's available in it.
   * 2 - As of date
   * 3 - Fields associated to Annuity - Before phase are present in this index. Only to be used for BF phase(annuityStageIndicator) of Annuity products.
   * 4 - Fields associated to Annuity - On phase are present in this index. Only to be used for ON phase(annuityStageIndicator) of Annuity products.
   * 5 - Total Payments Paid Field - The one and only field present in this index
   * 6 - Fields associated to Annuity - On phase are present in this index. Only to be used for ON phases (annuityStageIndicator) of Annuity products.
   * 7 - Fields associated to Annuity - After phase are present in this index. Only to be used for AF phases (annuityStageIndicator) of Annuity products.
   * 8 - Last Monthly payout amount will be available in this index.
   * 9 - Narrative Fields are present in this index. Regardless of product category, whichever fields comes from the eAPIs response,
   *     it's available in it.
   */
  const { documentResponse } = currentPolicyDetail;
  const fulFillmentRatioLink = getFulFillmentRatioLink(
    documentResponse,
    locale
  );

  return detailsPageMiddleSection([
    formatMessage,
    returnsSectionHeader,
    returnsHeaderTooltip,
    sectionHeading,
    annuityPhaseIndicator,
    suppressTotalSection,
    hideTotalPolicyValue,
    fieldsStack,
    returnsTotalSubTitleKey,
    langObj,
    optionsArrayStack,
    displayAnnuityStatement,
    returnOptionHeaderTitle,
    fulFillmentRatioLink,
    footerButtonList,
    DividendOrCashBonusWithdrawal
  ]);
};

Returns.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  sectionFieldConfig: PropTypes.array.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  productCategory: PropTypes.string.isRequired,
  productCategoryKey: PropTypes.string.isRequired,
  dependentSectionField: PropTypes.string,
  fieldConfig: PropTypes.object.isRequired,
  dependentSectionFieldTwo: PropTypes.string,
  servicingOptionsURL: PropTypes.object.isRequired,
  langObj: PropTypes.object.isRequired
};

Returns.defaultProps = {
  dependentSectionField: '',
  dependentSectionFieldTwo: ''
};
Returns.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default Returns;
