import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SurrenderChargeTable from './SurrenderChargeTable';

const SurrenderChargeConfig = (props, context) => {
  const { fieldsReferred, reprojectionsDetails } = props;
  const {
    intl: { formatMessage }
  } = context;
  const { fieldParent: apiLevel, fieldName, decimalRange } = fieldsReferred[0];

  const surrChrgRates = _.get(_.get(reprojectionsDetails, apiLevel), fieldName);

  let maxPolicyYear = -1;

  const surrRateData = {};

  _.map(surrChrgRates, surrChrgRate => {
    if (surrChrgRate.policyYear && surrChrgRate.policyYear.trim().length) {
      const { policyYear } = surrChrgRate;
      maxPolicyYear = Math.max(maxPolicyYear, policyYear);
      surrRateData[policyYear] = surrChrgRate;
    }
    return null;
  });

  const getAppropriateValueForInvalidRate = (rate, totalYears, maxYears) => {
    let rateLabel = rate;

    if (_.isNaN(Number(rate))) {
      rateLabel = '-';
    } else if (rate <= 0 && totalYears < maxYears) {
      rateLabel = formatMessage({
        id: 'surrenderChargeInvalidPolicyRateLabel'
      });
    }

    return rateLabel;
  };

  const maxYear = Math.min(maxPolicyYear, 16);
  for (let i = 1; i <= maxYear; i += 1) {
    let rate = _.get(surrRateData[i], 'rate');
    rate = !_.isNil(rate) && rate.trim().length > 0 ? Number(rate) : 'invalid';
    surrRateData[i] = {
      policyYear: `${i}`,
      rate: getAppropriateValueForInvalidRate(rate, maxYear, 16)
    };
  }

  return (
    <SurrenderChargeTable
      surrRateData={surrRateData}
      toDisplayPlus={maxPolicyYear > 16}
      decimalRange={decimalRange}
      maxYear={maxYear}
    />
  );
};

SurrenderChargeConfig.contextTypes = {
  intl: PropTypes.object.isRequired
};

SurrenderChargeConfig.propTypes = {
  fieldsReferred: PropTypes.array.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired
};

export default SurrenderChargeConfig;
