import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ContactInfoWrapper,
  ContactInfoHeader,
  ContactImgWrapper,
  ImgStyle,
  ContactInfoLabel,
  ContactInfoBody,
  ContactInfoFooter,
  UpdateContactLink,
  UpdateContactLabel,
  UpdateIconWrapper,
  ContactInfoRow
} from './contactInfoStyles';
import { ParagraphDiv } from '../../common/styles/modalStyles';

import { DetailsPageMiddleSection } from '../detailPageStyle';
import Contactlivechat from '../../assets/images/Contactlivechat.svg';
import iconRight from '../../assets/images/Icon_right.svg';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';
import { setLinkFocus } from '../../utils/domUtils';
import getContentInSpecificLang from '../../utils/localeUtils';
import { getCurrentPolicyType } from '../sectionUtils/SectionsUtils';
import ModalPopupGI from './ModalPopupGI';

const ContactInfo = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;

  const [isModalOpen, updateModalState] = useState(false);

  const {
    genericConfig,
    fieldsStack,
    sectionHeading,
    langObj,
    superFamily
  } = props;
  const eventAction = 'onsite';
  const linkRef = React.createRef();
  const linkName = 'personal contact details';
  let eventData = {};
  const { isLifePolicy, isGIPolicy } = getCurrentPolicyType(superFamily);

  const contactKeyLangSpecific = getContentInSpecificLang(
    'update_contact_information',
    'en',
    langObj
  );

  eventData = createEventActionContent(
    eventAction,
    contactKeyLangSpecific,
    linkName
  );
  const onContinueClick = () => {
    trackEvents({
      eventName: 'popup',
      eventContent: contactKeyLangSpecific.toLowerCase()
    });
  };

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
    trackEvents({
      eventName: 'popup',
      eventContent: contactKeyLangSpecific.toLowerCase()
    });
    setLinkFocus(linkRef.current, true);
  };

  const footerButtonList = [
    {
      className: 'whiteBtn',
      label: formatMessage({ id: 'cancelPopup' }),
      type: 'button',
      id: 'cancel_btn',
      align: 'left'
    },
    {
      className: 'redBtn',
      label: formatMessage({ id: 'continuePopup' }),
      type: 'button',
      id: 'continue_btn',
      align: 'right'
    }
  ];
  return (
    <DetailsPageMiddleSection>
      <ContactInfoWrapper>
        <ContactInfoHeader>
          <ContactImgWrapper>
            <ImgStyle aria-hidden="true" alt="" src={Contactlivechat} />
          </ContactImgWrapper>
          <ContactInfoLabel id="contact_information_title">
            {formatMessage({ id: sectionHeading })}
          </ContactInfoLabel>
        </ContactInfoHeader>
        <ContactInfoBody>
          <ContactInfoRow id="row">{fieldsStack}</ContactInfoRow>
        </ContactInfoBody>
        <ContactInfoFooter>
          <UpdateContactLink
            href="#"
            onClick={e => {
              updateModalState(!isModalOpen);
              trackEvents({ eventName: 'updateContactInformation', eventData });
              e.preventDefault();
            }}
            id="update_contact_section_information"
            ref={linkRef}
          >
            <UpdateContactLabel>
              {formatMessage({ id: 'update_contact_information' })}
            </UpdateContactLabel>
            <UpdateIconWrapper>
              <ImgStyle aria-hidden="true" alt="" src={iconRight} />
            </UpdateIconWrapper>
          </UpdateContactLink>
          {isLifePolicy && (
            <>
              <ModalPopUp
                isModalOpen={isModalOpen}
                onClose={onCancelClick}
                modalHeading=""
                footerButtonList={footerButtonList}
                btnHandler={{
                  continue_btn: onContinueClick,
                  cancel_btn: onCancelClick
                }}
                proceedToLink={genericConfig.contactUpdateURL[locale]}
              >
                <ParagraphDiv>
                  {formatMessage({ id: 'makeaClaimPopUpText' })}
                </ParagraphDiv>
              </ModalPopUp>
            </>
          )}
          {isGIPolicy && (
            <ModalPopupGI
              linkLabel={formatMessage({
                id: 'updatePaymentInstructionPopupTitle'
              })}
              isModalOpen={isModalOpen}
              onClose={onCancelClick}
              footerButtonList={[
                {
                  className: 'redBtn',
                  label: formatMessage({
                    id: 'updatePaymentInstructionClosePopupButton'
                  }),
                  id: 'close_btn',
                  align: 'left'
                }
              ]}
              btnHandler={{
                close_btn: onCancelClick
              }}
            />
          )}
        </ContactInfoFooter>
      </ContactInfoWrapper>
    </DetailsPageMiddleSection>
  );
};

ContactInfo.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  genericConfig: PropTypes.object.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  superFamily: PropTypes.object.isRequired
};

ContactInfo.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ContactInfo;
