import _ from 'lodash';
import {
  setPolicySummary,
  setPolicyInfo,
  setWarningsOrErrors,
  setReprojectionsWarningsOrErrors,
  setInstructionHistory,
  setInstructionHistoryDetailErrors,
  setInstructionHistoryErrorOrWarnings
} from '../app/appActions';
import { preparePoliciesStore, preparePolicyDetailsStore } from './policyUtils';
import { getRoutesPagePath } from './routeUtils';
import { errorWarningHandler } from './errorWarningHandler';

const invalidRequest = 'Invalid Request';
const exceptionStack = 'exception-stack';
/**
 * @description: Prepares the  data for saving errors specific to dashboard(pages summary and details) to the redux store.
 * @param: object: error object(found in try catch block)
 * @param: string: encodePolicyNumber: Indicating if summary or details page.
 * @returns object eg{ summary: {errors: [{{ errorCode: 'defaultErrorOrWarningCode' }}]}
 *
 */
export const policySagaErrorHandler = function policySagaErrorHandler(
  error,
  encodePolicyNumber = null
) {
  if (!error || !error.response) {
    return {
      summary: { errors: [{ errorCode: 'defaultErrorOrWarningCode' }] }
    };
  }
  const { errors = null } = error.response.data;
  if (encodePolicyNumber) {
    if (errors) {
      return { details: { errors } };
    }
    return {
      details: { errors: [{ errorCode: 'defaultErrorOrWarningCode' }] }
    };
  }
  if (errors) {
    return { summary: { errors } };
  }
  return {
    summary: { errors: [{ errorCode: 'defaultErrorOrWarningCode' }] }
  };
};

/**
 * @description: Prepares the  data for saving errors specific to App bootstrap to the redux store.
 * @param: object: error object(found in try catch block)
 * @param: string: encodePolicyNumber: Indicating if summary or details page.
 * @returns object eg{ summary: {errors: [{{ errorCode: 'defaultErrorOrWarningCode' }}]}
 *
 */
export const appBootstrapSagaErrorHandler = function appBootstrapSagaErrorHandler(
  error,
  isReprojection = false,
  isDetailPage = false,
  customerIdentifier = null
) {
  const errorObjectsArray = [];
  const page = isDetailPage ? 'details' : 'summary';

  if (!error || !error.response) {
    errorObjectsArray.push(
      setWarningsOrErrors({
        [page]: { errors: [{ errorCode: 'defaultErrorOrWarningCode' }] }
      })
    );
  } else {
    const { errors = null } = error.response.data;
    if (errors) {
      if (!!isReprojection || !customerIdentifier) {
        errorObjectsArray.push(setReprojectionsWarningsOrErrors({ errors }));
      }
      errorObjectsArray.push(setWarningsOrErrors({ [page]: { errors } }));
    } else {
      if (!!isReprojection || !customerIdentifier) {
        errorObjectsArray.push(
          setReprojectionsWarningsOrErrors({
            errors: [{ errorCode: 'UI0001', causes: invalidRequest }]
          })
        );
      }
      errorObjectsArray.push(
        setWarningsOrErrors({
          [page]: { errors: [{ errorCode: 'defaultErrorOrWarningCode' }] }
        })
      );
    }
  }
  return errorObjectsArray;
};

export const papiErrorHandler = function papiErrorHandler(err) {
  const errorResponse = err.data;

  let errorObject = {};
  if (_.isEmpty(errorResponse) || !_.has(errorResponse, exceptionStack)) {
    errorObject = { 'exception-code': 'UI0001', message: invalidRequest };
  } else {
    _.forEach(errorResponse[exceptionStack], currentError => {
      errorObject = currentError;
    });
  }

  return errorObject;
};

/**
 * @description: Prepares the  service config, defining the headers, url and method that need to be passed to axios.
 * @param: object: serviceConfig
 * @param: string: api service url.
 * @param: object: predefined header object that 's required for api call.
 * @param: string: method default GET.
 * @param: string: customer segment.
 *
 */
export const prepareServiceConfig = function prepareServiceConfig(
  serviceConfig,
  serviceConfigUrl,
  headers,
  method = 'GET',
  customerSegment = null,
  data = null
) {
  const time = new Date().getTime();
  const stubUrl = `${serviceConfig.stubUrl}?t=${time}`;
  let queryParamsString = '';
  if (customerSegment) {
    queryParamsString = `?custSeg=${customerSegment}`;
  }
  return {
    method,
    url: serviceConfig.isStubbed
      ? stubUrl
      : `${serviceConfigUrl}${queryParamsString}`,
    headers: serviceConfig.isStubbed ? {} : headers,
    data: data || null
  };
};

export const prepareServiceConfigPAPI = function prepareServiceConfigPAPI(
  serviceConfig,
  serviceConfigUrl,
  headers,
  method = 'GET',
  data = ''
) {
  const time = new Date().getTime();
  const stubUrl = `${serviceConfig.stubUrl}?t=${time}`;
  const apiConfigurations = {
    method: serviceConfig.isStubbed ? 'GET' : method,
    url: serviceConfig.isStubbed ? stubUrl : `${serviceConfigUrl}`,
    headers: serviceConfig.isStubbed ? {} : headers
  };
  if (data !== '') {
    apiConfigurations.data = data;
  }
  return apiConfigurations;
};

export const isEligibleForLogging = function isEligibleForLogging() {
  return (
    process.env.REACT_APP_ENV !== 'prod' &&
    process.env.REACT_APP_ENV !== 'local'
  );
};

/**
 * @description : Returns if the application is stubbed when the we set the stubAPITest in the url and the env is not prod
 * @param: isStubbed: boolean
 * @returns:  boolean.
 */
export const determineIfStubApplicable = function determineIfStubApplicable(
  isStubbed = false
) {
  const { REACT_APP_ENV } = process.env;
  return isStubbed && REACT_APP_ENV !== 'prod';
};
/**
 * @description : Modifies the existing object passed with the attribute isStubbed  to that passed in the 2nd parameter.
 * @param: apiConfigs: Object, isStubbed: boolean
 *
 */
export const stubAllAPI = function stubAllAPI(apiConfigs, isStubbed = false) {
  const apiConfigsHolding = apiConfigs;
  if (determineIfStubApplicable(isStubbed)) {
    Object.keys(apiConfigsHolding).forEach(obj => {
      if ('isStubbed' in apiConfigs[obj]) {
        apiConfigsHolding[obj].isStubbed = true;
      }
    });
  }
  return apiConfigsHolding;
};

export const preparePolicyAPIConfig = function preparePolicyAPIConfig(
  encodedPolicyId,
  policies,
  policyDetails,
  policyHeaders,
  customerSegment
) {
  if (encodedPolicyId) {
    return prepareServiceConfig(
      policyDetails,
      policyDetails.url.replace('{encode-policynumber}', encodedPolicyId),
      policyHeaders,
      'GET',
      customerSegment
    );
  }
  return prepareServiceConfig(
    policies,
    policies.url,
    policyHeaders,
    'GET',
    customerSegment
  );
};

export const prepareDownloadDocumentAPIConfig = function prepareDownloadDocumentAPIConfig(
  docSysId,
  documentRetireve,
  documentRetireveHeaders
) {
  if (docSysId) {
    return prepareServiceConfig(
      documentRetireve,
      documentRetireve.url,
      documentRetireveHeaders,
      'GET'
    );
  }
  return false;
};

/**
 * FE logic of eSTATEMENT Preference:
 * if paper statement is checked -> eStatementOptInIndicator: false
 * if paper statement is unchecked -> eStatementOptInIndicator: true
 */
export const getPayloadForEStatementSubmit = function getPayloadForEStatementSubmit(
  payload
) {
  const { newStmtPref } = payload;
  const eStatementIndicatorValue = !_.isUndefined(newStmtPref)
    ? !newStmtPref
    : true; // set default value to true
  return {
    eStatementInfo: { eStatementOptInIndicator: eStatementIndicatorValue }
  };
};

export const prepareEStatementPrefSubmitConfig = function prepareEStatementPrefSubmitConfig(
  eStatementPref,
  eStatementHeaders
) {
  return prepareServiceConfig(
    eStatementPref,
    eStatementPref.url,
    eStatementHeaders,
    eStatementPref.isStubbed ? 'GET' : 'POST'
  );
};
export const prepareEWelcomeACKConfig = function prepareEWelcomeACKConfig(
  eWelcomePackAck,
  ackEWelcomeheaders
) {
  return prepareServiceConfig(
    eWelcomePackAck,
    eWelcomePackAck.url,
    ackEWelcomeheaders,
    'POST'
  );
};

export const prepareEWelcomeDownloadDocumentAPIConfig = function prepareEWelcomeDownloadDocumentAPIConfig(
  documentID,
  eWelcomeDocumentRetireve,
  documentRetireveHeaders
) {
  if (documentID) {
    return prepareServiceConfig(
      eWelcomeDocumentRetireve,
      eWelcomeDocumentRetireve.url.replace('{documentId}', documentID),
      documentRetireveHeaders,
      'GET'
    );
  }
  return false;
};

export const prepareEWelcomeGetDocumentAPIConfig = function prepareEWelcomeGetDocumentAPIConfig(
  policyNumber,
  eWelcomeDocuments,
  documentRetireveHeaders,
  payload
) {
  if (policyNumber) {
    return prepareServiceConfig(
      eWelcomeDocuments,
      eWelcomeDocuments.url,
      documentRetireveHeaders,
      eWelcomeDocuments.isStubbed ? 'GET' : 'POST',
      null,
      payload
    );
  }
  return false;
};

export const prepareEWelcomeDownloadAllAPIConfig = function prepareEWelcomeDownloadAllAPIConfig(
  policyNumber,
  eWelcomeDownloadAll,
  documentRetireveHeaders,
  payload
) {
  if (policyNumber) {
    return prepareServiceConfig(
      eWelcomeDownloadAll,
      eWelcomeDownloadAll.url,
      documentRetireveHeaders,
      eWelcomeDownloadAll.isStubbed ? 'GET' : 'POST',
      null,
      payload
    );
  }
  return false;
};

export const preparePolicyStorePayload = function preparePolicyStorePayload(
  encodedPolicyId,
  policyInfoResponseData,
  subProductFamilies,
  productFamilyOrder,
  isStubbed = false,
  byEncodeId = {},
  functionEligibility = null,
  customerIdentifier,
  customerSegment,
  customerPermId
) {
  if (encodedPolicyId) {
    const updatedByEncodeId = preparePolicyDetailsStore(
      encodedPolicyId,
      policyInfoResponseData,
      byEncodeId,
      functionEligibility,
      customerIdentifier,
      customerSegment,
      customerPermId,
      subProductFamilies
    );
    return setPolicyInfo(updatedByEncodeId);
  }
  const policySummaryPayload = preparePoliciesStore(
    subProductFamilies,
    productFamilyOrder,
    policyInfoResponseData
  );
  if (determineIfStubApplicable(isStubbed)) {
    policySummaryPayload.API_RESPONSE = policyInfoResponseData;
  }
  return setPolicySummary(policySummaryPayload);
};

export const handleDetailsApiFailure = function handleDetailsApiFailure(
  err,
  isDetailPage,
  isSummaryCalled
) {
  if (isDetailPage) {
    const { errors = null } = err.response.data;
    const errorArrayObj = errors || [
      { errorCode: 'defaultErrorOrWarningCode' }
    ];
    return errorWarningHandler(
      errorArrayObj,
      isSummaryCalled,
      getRoutesPagePath
    );
  }
  return true;
};

export const policyDocumentsSagaErrorHandler = function policyDocumentsSagaErrorHandler(
  documentIdentifier,
  error
) {
  if (!error || !error.response) {
    return {
      [documentIdentifier]: {
        errors: [{ errorCode: 'defaultErrorOrWarningCode' }]
      }
    };
  }
  const { errors = null } = error.response.data;
  if (errors) {
    return { [documentIdentifier]: { errors } };
  }
  return {
    [documentIdentifier]: {
      errors: [{ errorCode: 'defaultErrorOrWarningCode' }]
    }
  };
};

export const preparePolicyDocumentsStorePayload = function preparePolicyDocumentsStorePayload(
  documentIdentifer,
  data
) {
  return {
    currentIdentifier: documentIdentifer,
    documentData: {
      [documentIdentifer]: data
    }
  };
};

export const prepareEWelcomePolicyDocumentsStorePayload = function prepareEWelcomePolicyDocumentsStorePayload(
  documentIdentifer,
  data,
  encodePolicyNumber
) {
  return {
    currentIdentifier: documentIdentifer,
    documentData: data,
    encodePolicyNumber
  };
};

export const commonInstructionHistoryError = function commonInstructionHistoryError(
  instructionHistoryErrors
) {
  if (instructionHistoryErrors && instructionHistoryErrors.length > 0) {
    return setInstructionHistoryErrorOrWarnings({ instructionHistoryErrors });
  }
  return false;
};

export const prepareInstructionStorePayload = function prepareInstructionStorePayload(
  encodePolicyNumber,
  policyInfoResponseData
) {
  const instructionPayload = { instHistoryResponse: {} };
  let insErrors;
  if (encodePolicyNumber) {
    const {
      policyDetails: {
        instructionHistoryDetails = null,
        instructionHistoryErrors = null
      }
    } = policyInfoResponseData;
    if (instructionHistoryDetails && !_.isEmpty(instructionHistoryDetails)) {
      instructionPayload.instHistoryResponse[
        encodePolicyNumber
      ] = instructionHistoryDetails;
    }
    insErrors = commonInstructionHistoryError(instructionHistoryErrors);
  } else {
    _.map(policyInfoResponseData.policies, policy => {
      const {
        encodePolicyNumber: encodePolicyID,
        policyDetails = null
      } = policy;
      if (policyDetails) {
        const {
          policyDetails: {
            instructionHistoryDetails = null,
            instructionHistoryErrors = null
          }
        } = policy;
        if (
          instructionHistoryDetails &&
          !_.isEmpty(instructionHistoryDetails)
        ) {
          instructionPayload.instHistoryResponse[
            encodePolicyID
          ] = instructionHistoryDetails;
        }
        insErrors = commonInstructionHistoryError(instructionHistoryErrors);
      }
      return instructionPayload;
    });
  }
  if (!_.isEmpty(instructionPayload.instHistoryResponse)) {
    return setInstructionHistory(instructionPayload);
  }
  return insErrors;
};

export const addInstructionDetailsToStore = function addInstructionDetailsToStore(
  keysToMatch,
  insHistoryDetailResponseData,
  instructionList
) {
  const instructionPayload = { instHistoryResponse: {} };
  const instructionHistoryResponse = instructionList;
  const { encodedProductId, referenceNo } = keysToMatch;
  const { pendingRequestList, requestHistoryList } = instructionHistoryResponse[
    encodedProductId
  ];

  _.forEach(pendingRequestList, (currentPendingRequest, index) => {
    if (currentPendingRequest.referenceNo === referenceNo) {
      pendingRequestList[index].details = insHistoryDetailResponseData;
    }
  });
  _.forEach(requestHistoryList, (currentRequestHistory, index) => {
    if (currentRequestHistory.referenceNo === referenceNo) {
      requestHistoryList[index].details = insHistoryDetailResponseData;
    }
  });

  instructionPayload.instHistoryResponse = instructionHistoryResponse;
  return setInstructionHistory(instructionPayload);
};

export const changeRequestCategoryForInstruction = function changeRequestCategoryForInstruction(
  referenceNo,
  encodePolicyNumber,
  instructionList
) {
  const sampleInstructionList = instructionList;
  const { pendingRequestList, requestHistoryList = [] } = sampleInstructionList[
    encodePolicyNumber
  ];
  _.each(pendingRequestList, (customRequest, index) => {
    const tempObject = customRequest;
    if (tempObject && tempObject.referenceNo === referenceNo) {
      tempObject.category = 'RequestHistory';
      tempObject.status = '4';
      if (requestHistoryList.length === 0) {
        requestHistoryList[0] = tempObject;
      } else {
        requestHistoryList.push(tempObject);
      }
      pendingRequestList.splice(index, 1);
    }
  });
  sampleInstructionList[
    encodePolicyNumber
  ].pendingRequestList = pendingRequestList;
  sampleInstructionList[
    encodePolicyNumber
  ].requestHistoryList = requestHistoryList;
  const instructionPayload = { instHistoryResponse: {} };
  instructionPayload.instHistoryResponse = sampleInstructionList;
  return setInstructionHistory(instructionPayload);
};

export const instructionDetailErrorHandler = function instructionDetailErrorHandler(
  error
) {
  let errorList = [];
  if (error && error.response) {
    const errorResponse = error.response.data;
    if (_.isEmpty(errorResponse) || !_.has(errorResponse, exceptionStack)) {
      errorList = [
        ...errorList,
        { errorCode: 'UI0001', message: invalidRequest }
      ];
    } else {
      _.forEach(errorResponse[exceptionStack], currentError => {
        const { message } = currentError;
        errorList = [
          ...errorList,
          { errorCode: currentError['exception-code'], message }
        ];
      });
    }
  } else {
    errorList = [
      ...errorList,
      { errorCode: 'UI0001', message: invalidRequest }
    ];
  }
  return errorList;
};

export const addErrorListToInstructionStore = function addErrorListToInstructionStore(
  errorList,
  instructionList,
  payload
) {
  const instructionHistoryResponse = instructionList;
  const { encodedProductId, referenceNo } = payload;
  const { pendingRequestList, requestHistoryList } = instructionHistoryResponse[
    encodedProductId
  ];
  let errorFoundForPendingRequest = false;
  _.forEach(pendingRequestList, (currentPendingRequest, index) => {
    if (
      currentPendingRequest.referenceNo === referenceNo &&
      !errorFoundForPendingRequest
    ) {
      pendingRequestList[index].errors = errorList;
      errorFoundForPendingRequest = true;
    }
  });
  if (!errorFoundForPendingRequest) {
    _.forEach(requestHistoryList, (currentRequestHistory, index) => {
      if (currentRequestHistory.referenceNo === referenceNo) {
        requestHistoryList[index].errors = errorList;
      }
    });
  }

  return setInstructionHistoryDetailErrors(instructionHistoryResponse);
};
