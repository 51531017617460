import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import CurrencyWithSymbol from '../../../common/currency/CurrencyWithSymbol';
import Currency from '../../../common/currency/Currency';
import {
  MedicalTotalSubSectionFieldWrapper,
  TotalSubSectionLabel,
  MedicalSubSectionAmount,
  MedicalLegendIcon
} from '../../detailPageStyle';

const MedicalTotalAmountField = function MedicalTotalAmountField(props) {
  const {
    labelContent,
    fieldData,
    idx,
    id,
    toolTipContent,
    showLegendIconColor,
    showBoldFontAmount
  } = props;
  const sectionHeading = 'Medical Account Group';
  if (!labelContent || typeof fieldData !== 'object') {
    return null;
  }
  const { amount, currency } = fieldData;
  if (!currency) {
    return null;
  }

  return (
    <MedicalTotalSubSectionFieldWrapper id={`medicalTotalSection_${idx}`}>
      {showLegendIconColor && (
        <MedicalLegendIcon iconColor={showLegendIconColor} />
      )}
      <TotalSubSectionLabel id={`totalSubSectionLabel_${idx}`}>
        <FormattedMessage id={labelContent} />
        <TooltipWrapper
          id={`tooltip_medicalTotalSection_${id}`}
          contentKey={toolTipContent}
          labelContent={labelContent}
          sectionHeading={sectionHeading}
        />
      </TotalSubSectionLabel>
      <MedicalSubSectionAmount fontWeight={showBoldFontAmount}>
        <Currency currencyCode={currency} amount={amount || 0} />
      </MedicalSubSectionAmount>
    </MedicalTotalSubSectionFieldWrapper>
  );
};

MedicalTotalAmountField.propTypes = {
  id: PropTypes.string.isRequired,
  idx: PropTypes.number,
  labelContent: PropTypes.string,
  fieldData: PropTypes.number.isRequired,
  toolTipContent: PropTypes.string
};

MedicalTotalAmountField.defaultProps = {
  toolTipContent: null,
  labelContent: null,
  idx: 0
};

export default MedicalTotalAmountField;
