import React from 'react';
import PropTypes from 'prop-types';
import {
  FundsSubSection,
  FundsTotalFundSection,
  FundsTotalPremiumPaidLeftSection,
  FundTopSectionWrapper,
  FundTopWrapper,
  FundsChartSection,
  FundsTotalPremiumPaidSectionWrapper,
  FundsTotalPremiumPaidRightSection,
  PortfolioHeader
} from '../../detailPageStyle';

import DoughnutChart from '../../../charts/doughnutChart';
import FundRPQNotification from './FundRPQNotification';

import FundOverview from './FundOverview';

const FundsPorfolioSection = function FundsPorfolioSection(props, context) {
  const {
    overviewSectionComponents,
    doughnutChartData,
    totalFundBalanceComponents,
    returnTotalRightSubSection,
    checkPremiumPaidSectionFields,
    totalPremiumPaidComponents,
    showPortfolioTableData,
    hasTotalSubSectionFields,
    transferFundPendingIndicator,
    rpqNotification,
    matchedReferenceNumbers
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  if (transferFundPendingIndicator) {
    return (
      <>
        <FundOverview
          overviewSectionComponents={overviewSectionComponents}
          transferFundPendingIndicator={transferFundPendingIndicator}
        />
        <PortfolioHeader>
          {formatMessage({ id: 'portfolioHeader' })}
        </PortfolioHeader>
      </>
    );
  }

  return (
    <>
      <FundTopSectionWrapper id="FundsPorfolioSection">
        <FundOverview
          overviewSectionComponents={overviewSectionComponents}
          transferFundPendingIndicator={transferFundPendingIndicator}
        />
        <FundTopWrapper>
          <FundsSubSection>
            <FundsChartSection>
              {doughnutChartData && (
                <DoughnutChart
                  fieldData={doughnutChartData}
                  uniqueID="FundsChart"
                />
              )}
            </FundsChartSection>
            <FundsTotalFundSection>
              {totalFundBalanceComponents}
            </FundsTotalFundSection>
          </FundsSubSection>
          {returnTotalRightSubSection}
        </FundTopWrapper>
      </FundTopSectionWrapper>
      {checkPremiumPaidSectionFields && (
        <FundsTotalPremiumPaidSectionWrapper
          hasTotalSubSectionFields={hasTotalSubSectionFields}
        >
          <FundsTotalPremiumPaidLeftSection
            hasTotalSubSectionFields={hasTotalSubSectionFields}
          />
          <FundsTotalPremiumPaidRightSection
            hasTotalSubSectionFields={hasTotalSubSectionFields}
          >
            {totalPremiumPaidComponents}
          </FundsTotalPremiumPaidRightSection>
        </FundsTotalPremiumPaidSectionWrapper>
      )}
      <FundRPQNotification
        displayFor="UnchangeRPQ"
        rpqNotification={rpqNotification}
        matchedReferenceNumbers={matchedReferenceNumbers}
        transferFundPendingIndicator={transferFundPendingIndicator}
      />
      {showPortfolioTableData}
    </>
  );
};

FundsPorfolioSection.propTypes = {
  overviewSectionComponents: PropTypes.array.isRequired,
  doughnutChartData: PropTypes.array.isRequired,
  totalFundBalanceComponents: PropTypes.array.isRequired,
  returnTotalRightSubSection: PropTypes.array.isRequired,
  checkPremiumPaidSectionFields: PropTypes.func.isRequired,
  totalPremiumPaidComponents: PropTypes.array.isRequired,
  showPortfolioTableData: PropTypes.func.isRequired,
  hasTotalSubSectionFields: PropTypes.array.isRequired,
  transferFundPendingIndicator: PropTypes.bool.isRequired,
  rpqNotification: PropTypes.string.isRequired,
  matchedReferenceNumbers: PropTypes.array.isRequired
};

FundsPorfolioSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundsPorfolioSection;
