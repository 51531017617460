import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _some from 'lodash/some';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _ from 'lodash';
import * as Sections from './sections';
import * as templates from './templates';
import {
  getFieldDataFromConfig,
  overrideLableWhen
} from '../utils/policyUtils';
import { payerNameGenerator } from '../policysummary/productTemplates/templateUtils';

export const checkPayerFieldData = (fieldConfigInstance, fieldData) => {
  return (
    fieldConfigInstance &&
    fieldConfigInstance.fieldLabel === 'premiumPayerNameFieldLabel' &&
    fieldData &&
    fieldData.length
  );
};

export const checkEmptyString = fieldData =>
  typeof fieldData === 'string' && !_.trim(fieldData);

export const checkDependentContent = (
  fieldData,
  isCheckEmptyStringWithSpace,
  fieldConfigInstance
) => {
  return (
    (!fieldData || isCheckEmptyStringWithSpace) &&
    !!fieldConfigInstance.dependentContentData
  );
};

const generateDetailsFields = (
  sectionFieldConfigInstance,
  currentPolicyDetail,
  sectionName,
  headerContent,
  productCategory,
  formatMessage,
  superFamily,
  summaryResponse,
  isPolicyGeneralValue,
  fieldConfig,
  dependentSectionFieldThree,
  subsectionIndex,
  langObj,
  contactUsConfig,
  surrenderChargeConfig,
  customerWhiteListedForDigiServiceLinks,
  dependentSectionFieldFour
) =>
  sectionFieldConfigInstance.map((fieldConfigInstance, index) => {
    const FieldComponent = templates[fieldConfigInstance.uiFormat];
    let fieldData = currentPolicyDetail
      ? getFieldDataFromConfig(currentPolicyDetail, fieldConfigInstance)
      : _get(summaryResponse, fieldConfigInstance.fieldName);

    /* --------------------------------------------------------------------------------------------------------------------------------- */

    /**
     * This below block of code applies only and only for the case of Premium Payer name field. This is being perfomed here instead of being performed
     * inside the template, is only to have an ability to perform suppression check in Payment section for the bottom half.
     *
     * Since, the fieldData for Premium Payer template always has an array of involvedParties(with or without Payer),
     * the null check could not be performed in payment section as there are always some involvedParty in the fieldData (thus always returning not null via checkFieldData method).
     * Hence, to have the capability to apply null check if Payer is not present, moving the payer finding method over here.
     *
     * This enables the ability to have a check of just a string i.e. Payer name in the PaymentInfo.js via checkFieldData Method.
     * This also enables us to have better control over suppression of Payment Section through paymentInfo.js
     */

    if (checkPayerFieldData(fieldConfigInstance, fieldData)) {
      fieldData = payerNameGenerator(fieldData);

      /*
       * While iterating for Payer field, this fiedData now has Payer name in an array. And will send an empty
         array(unlike previously) to the template in case of no payer.
         Thus helping the method checkFieldData while checking for suppression of Payment section.
       *
       */
    }
    const isCheckEmptyStringWithSpace = checkEmptyString(fieldData);
    /* --------------------------------------------------------------------------------------------------------------------------------- */
    const isDependentContent = checkDependentContent(
      fieldData,
      isCheckEmptyStringWithSpace,
      fieldConfigInstance
    );
    if (
      !fieldData &&
      !fieldConfigInstance.dependentContentData &&
      !fieldConfigInstance.isMandatoryToShow
    ) {
      return <React.Fragment key={fieldConfigInstance.fieldLabel} />;
    }
    if (isDependentContent) {
      fieldData = formatMessage({
        id: fieldConfigInstance.dependentContentData
      });
    }
    let dependentFieldData;
    if (fieldConfigInstance.dependentField) {
      dependentFieldData = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfigInstance,
        true,
        'dependentField',
        summaryResponse
      );
    }
    /**
     * In case a field has dependency in more than one other fields,
     * use dependentFieldData2,dependentFieldData3
     */

    let secondDependentFieldData;
    if (fieldConfigInstance.dependentField2) {
      secondDependentFieldData = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfigInstance,
        true,
        'dependentField2',
        summaryResponse
      );
    }

    let thirdDependentFieldData;
    if (fieldConfigInstance.dependentField3) {
      thirdDependentFieldData = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfigInstance,
        true,
        'dependentField3',
        summaryResponse
      );
    }

    let dependentComponentData;
    if (fieldConfig[dependentSectionFieldThree]) {
      dependentComponentData = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfig[dependentSectionFieldThree],
        true
      );
    }

    let dependentComponentValue;
    if (fieldConfig[dependentSectionFieldFour]) {
      dependentComponentValue = getFieldDataFromConfig(
        currentPolicyDetail,
        fieldConfig[dependentSectionFieldFour],
        true
      );
    }

    let labelContent = fieldConfigInstance.fieldLabel;
    const fieldLabelSecondayKey = `fieldLabel_${productCategory}`;
    if (fieldConfigInstance[fieldLabelSecondayKey]) {
      labelContent = fieldConfigInstance[fieldLabelSecondayKey];
    }

    if (!FieldComponent) {
      return <React.Fragment key={fieldConfigInstance.fieldLabel} />;
    }

    return (
      <FieldComponent
        id={`field_${sectionName}_${subsectionIndex}_${index}`}
        idx={index}
        key={fieldConfigInstance.fieldLabel}
        labelContent={labelContent}
        fieldData={fieldData}
        fontChange={typeof value === 'object'}
        toolTipContent={fieldConfigInstance.toolTip}
        dependentFieldData={dependentFieldData}
        secondDependentFieldData={secondDependentFieldData}
        thirdDependentFieldData={thirdDependentFieldData}
        isDependentContent={isDependentContent}
        sectionHeading={headerContent}
        superFamily={superFamily}
        dependentContentData={fieldConfigInstance.dependentContent || ''}
        isMandatoryToShow={fieldConfigInstance.isMandatoryToShow || false}
        dependentComponentData={dependentComponentData}
        langObj={langObj}
        contactUsConfig={contactUsConfig}
        surrenderChargeConfig={surrenderChargeConfig}
        customerWhiteListedForDigiServiceLinks={
          customerWhiteListedForDigiServiceLinks
        }
        planCodesToCheck={fieldConfigInstance.planCodesToCheck}
        planCodesToolTip={fieldConfigInstance.planCodesToolTip}
        dependentComponentValue={dependentComponentValue}
      />
    );
  });

/**
 * Wrapper component for single policy section based on the section template in configuration
 * @param {Object} props
 */
const PolicyFieldsBuilder = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    policySectionTemplate,
    productCategory,
    productCategoryKey,
    sectionFieldList,
    fieldConfig,
    currentPolicyDetail,
    subProductCategories,
    selectedLocale,
    langObj,
    genericConfig,
    eStatementPreferenceConfig,
    superFamily,
    stackingFamily,
    servicingOptionsURL,
    headerContent,
    dependentSectionField,
    dependentSectionFieldTwo,
    dependentSectionFieldThree,
    isPolicyDetailsAvailableState,
    isPolicyExceptionalState,
    isPolicyLapsedState,
    summaryResponse,
    isPolicyGeneralValue,
    warningsOrErrors,
    commonToSections,
    sectionNotificationConfig,
    faqSection,
    contactUsConfig,
    setContactUsPopupHandler,
    showContactUsFooterPopup,
    functionEligibility,
    encodedProductId,
    dependentSectionFieldChild,
    dependentSectionFieldSurgical,
    fundSelectionData,
    fundSelectionWarningsOrErrors,
    rpqUTBConfig,
    surrenderChargeConfig,
    customerWhiteListedForDigiServiceLinks,
    policyTagsMedicalAndCIConfig,
    dependentSectionFieldFour,
    indicativeGainLossConfig,
    customer
  } = props;
  let sectionFieldConfig;
  let fieldsStack;
  const isNestedFields = _some(sectionFieldList, _isArray);
  if (isNestedFields) {
    sectionFieldConfig = _map(sectionFieldList, subSectionFieldList =>
      _map(subSectionFieldList, fieldName => {
        if (fieldConfig[fieldName]?.overideLabelWhen) {
          return overrideLableWhen(currentPolicyDetail, fieldConfig[fieldName]);
        }
        return fieldConfig[fieldName];
      })
    );
    fieldsStack = sectionFieldConfig.map(
      (sectionFieldConfigInstance, subsectionIndex) => {
        return generateDetailsFields(
          sectionFieldConfigInstance,
          currentPolicyDetail,
          policySectionTemplate,
          headerContent,
          productCategory,
          formatMessage,
          superFamily,
          summaryResponse,
          isPolicyGeneralValue,
          fieldConfig,
          dependentSectionFieldThree,
          subsectionIndex,
          langObj,
          contactUsConfig,
          surrenderChargeConfig,
          customerWhiteListedForDigiServiceLinks,
          dependentSectionFieldFour
        );
      }
    );
  } else {
    sectionFieldConfig = _map(
      sectionFieldList,
      fieldName => fieldConfig[fieldName]
    );
    /**
     * As not nested, all will placed at 0 th position.
     */
    const subsectionIndex = 0;
    fieldsStack = generateDetailsFields(
      sectionFieldConfig,
      currentPolicyDetail,
      policySectionTemplate,
      headerContent,
      productCategory,
      formatMessage,
      superFamily,
      summaryResponse,
      isPolicyGeneralValue,
      fieldConfig,
      dependentSectionFieldThree,
      subsectionIndex,
      langObj,
      contactUsConfig,
      surrenderChargeConfig,
      dependentSectionFieldFour
    );
  }
  const SpecificSection = Sections[policySectionTemplate];
  return (
    <SpecificSection
      productCategory={productCategory}
      productCategoryKey={productCategoryKey}
      sectionFieldConfig={sectionFieldConfig}
      currentPolicyDetail={currentPolicyDetail}
      subProductCategories={subProductCategories}
      selectedLocale={selectedLocale}
      langObj={langObj}
      genericConfig={genericConfig}
      eStatementPreferenceConfig={eStatementPreferenceConfig}
      superFamily={superFamily}
      fieldsStack={fieldsStack}
      stackingFamily={stackingFamily}
      servicingOptionsURL={servicingOptionsURL}
      sectionHeading={headerContent}
      dependentSectionField={dependentSectionField}
      dependentSectionFieldTwo={dependentSectionFieldTwo}
      dependentSectionFieldThree={dependentSectionFieldThree}
      fieldConfig={fieldConfig}
      isPolicyDetailsAvailable={isPolicyDetailsAvailableState}
      isPolicyExceptional={isPolicyExceptionalState}
      isPolicyLapsed={isPolicyLapsedState}
      isPolicyGeneralValue={isPolicyGeneralValue}
      summaryResponse={summaryResponse}
      warningsOrErrors={warningsOrErrors}
      commonToSections={commonToSections}
      sectionNotificationConfig={sectionNotificationConfig}
      faqSection={faqSection}
      showContactUsFooterPopup={showContactUsFooterPopup}
      setContactUsPopupHandler={setContactUsPopupHandler}
      functionEligibility={functionEligibility}
      encodedProductId={encodedProductId}
      dependentSectionFieldChild={dependentSectionFieldChild}
      dependentSectionFieldSurgical={dependentSectionFieldSurgical}
      fundSelectionData={fundSelectionData}
      fundSelectionWarningsOrErrors={fundSelectionWarningsOrErrors}
      rpqUTBConfig={rpqUTBConfig}
      surrenderChargeConfig={surrenderChargeConfig}
      customerWhiteListedForDigiServiceLinks={
        customerWhiteListedForDigiServiceLinks
      }
      policyTagsMedicalAndCIConfig={policyTagsMedicalAndCIConfig}
      dependentSectionFieldFour={dependentSectionFieldFour}
      indicativeGainLossConfig={indicativeGainLossConfig}
      customer={customer}
    />
  );
};

PolicyFieldsBuilder.propTypes = {
  policySectionTemplate: PropTypes.string.isRequired,
  productCategory: PropTypes.string.isRequired,
  productCategoryKey: PropTypes.string.isRequired,
  sectionFieldList: PropTypes.array.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired,
  eStatementPreferenceConfig: PropTypes.object.isRequired,
  superFamily: PropTypes.string.isRequired,
  stackingFamily: PropTypes.string.isRequired,
  servicingOptionsURL: PropTypes.object.isRequired,
  headerContent: PropTypes.string.isRequired,
  dependentSectionField: PropTypes.string,
  dependentSectionFieldTwo: PropTypes.string,
  dependentSectionFieldThree: PropTypes.string,
  isPolicyDetailsAvailableState: PropTypes.bool,
  isPolicyExceptionalState: PropTypes.bool.isRequired,
  isPolicyLapsedState: PropTypes.bool.isRequired,
  summaryResponse: PropTypes.object.isRequired,
  isPolicyGeneralValue: PropTypes.bool.isRequired,
  warningsOrErrors: PropTypes.array,
  commonToSections: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired,
  faqSection: PropTypes.object,
  contactUsConfig: PropTypes.object,
  setContactUsPopupHandler: PropTypes.func.isRequired,
  showContactUsFooterPopup: PropTypes.bool.isRequired,
  functionEligibility: PropTypes.array,
  encodedProductId: PropTypes.string.isRequired,
  dependentSectionFieldChild: PropTypes.string,
  dependentSectionFieldSurgical: PropTypes.string,
  fundSelectionData: PropTypes.object.isRequired,
  fundSelectionWarningsOrErrors: PropTypes.object.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired,
  surrenderChargeConfig: PropTypes.object,
  customerWhiteListedForDigiServiceLinks: PropTypes.object,
  dependentSectionFieldFour: PropTypes.object,
  indicativeGainLossConfig: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired
};

PolicyFieldsBuilder.defaultProps = {
  dependentSectionField: '',
  dependentSectionFieldTwo: '',
  isPolicyDetailsAvailableState: false,
  dependentSectionFieldThree: '',
  warningsOrErrors: [],
  faqSection: {},
  contactUsConfig: {},
  functionEligibility: [],
  dependentSectionFieldChild: '',
  dependentSectionFieldSurgical: '',
  surrenderChargeConfig: {},
  customerWhiteListedForDigiServiceLinks: {},
  dependentSectionFieldFour: ''
};

PolicyFieldsBuilder.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyFieldsBuilder;
