import React from 'react';
import PropTypes from 'prop-types';
import { FundDetailWrapper } from '../instructionHistoryStyle';
import ServicingLabelAmount from '../templates/ServicingLabelAmount';
import SettlementCurrency from '../templates/SettlementCurrency';
import SettlementInstruction from '../templates/SettlementInstruction';
import SlaNoteSection from '../templates/SlaNoteSection';

import { getFieldData } from '../instructionUtils';

const WithdrawalPolicyValue = props => {
  const { withdrawPolicy, status } = props;
  const { withdrawDividend, paymentInfo } = withdrawPolicy;

  const withdrawalCurrency = getFieldData(
    withdrawDividend,
    'withdrawalCurrency'
  );
  const cashDividendWithdrawalAmount = getFieldData(
    withdrawDividend,
    'cashDividendWithdrawalAmount'
  );
  const cashBonusWithdrawalAmount = getFieldData(
    withdrawDividend,
    'cashBonusWithdrawalAmount'
  );

  return (
    <FundDetailWrapper>
      {withdrawalCurrency && (
        <>
          <ServicingLabelAmount
            label="dividendLabel"
            amount={cashDividendWithdrawalAmount}
            currency={withdrawalCurrency}
            removeLeftSpacing
          />
          <ServicingLabelAmount
            label="cashBonusLabel"
            amount={cashBonusWithdrawalAmount}
            currency={withdrawalCurrency}
            removeLeftSpacing
          />
        </>
      )}
      <SettlementInstruction paymentInfo={paymentInfo} />
      <SettlementCurrency paymentInfo={paymentInfo} />
      <SlaNoteSection labelId="withdrawPolicySlaText" status={status} />
    </FundDetailWrapper>
  );
};

WithdrawalPolicyValue.propTypes = {
  withdrawPolicy: PropTypes.object,
  status: PropTypes.string.isRequired
};

WithdrawalPolicyValue.defaultProps = {
  withdrawPolicy: {}
};
export default WithdrawalPolicyValue;
