import React from 'react';
import PropTypes from 'prop-types';
import * as icons from '../../assets/images/NotificationIcons';
import { NotificationMessageIcon } from '../../common/styles/commonStyle';
import {
  SubmitNotificationSection,
  InstructionNotificationSpan,
  SubmitNotificationMessage,
  SubmitImagePath
} from '../instructionHistoryStyle';

const SubmitNotification = function SubmitNotification(props, context) {
  const {
    intl: { formatMessage }
  } = context;

  return (
    <SubmitNotificationSection>
      <NotificationMessageIcon role="alert">
        <InstructionNotificationSpan>
          <SubmitImagePath aria-hidden="true" alt="" src={icons.Information} />
        </InstructionNotificationSpan>
        <SubmitNotificationMessage>
          {formatMessage({ id: 'requestSubmitLabel' })}
        </SubmitNotificationMessage>
      </NotificationMessageIcon>
    </SubmitNotificationSection>
  );
};

SubmitNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SubmitNotification;
