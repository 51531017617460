import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';
import { SectionRow } from '../../../common/styles/commonStyle';
import {
  NotificationMessageType,
  NotificationMessageIcon,
  NotificationIconSpan,
  NotificationMessage,
  ImagePath,
  InstructionHistoryExceptionWrapper
} from './instructionHistoryExceptionStyles';
import theme from '../../../common/styles/theme';
import ErrorIcon from '../../../assets/images/ErrorIcon.svg';
import ExceptionPolicySection from './ExceptionPolicySection';
import ContactUsExceptionCard from './ContactUsExceptionCard';
import InstructionHistoryExceptionBreadCrumb from './InstructionHistoryExceptionBreadCrumb';

const InstructionHistoryException = function InstructionHistoryException(
  props
) {
  const { appData, apiErrors } = props;

  return (
    <InstructionHistoryExceptionWrapper>
      <SectionRow>
        <InstructionHistoryExceptionBreadCrumb />
        {_.map(apiErrors, currentError => {
          const { warningCode: errorCode } = currentError;
          return (
            <NotificationMessageType
              bgColor={theme.errorNotificationBG}
              borderColor={theme.primaryActive}
            >
              <NotificationMessageIcon>
                <NotificationIconSpan>
                  <ImagePath aria-hidden="true" alt="" src={ErrorIcon} />
                </NotificationIconSpan>
                <NotificationMessage>
                  <span>
                    <FormattedHTMLMessage
                      id="error_Instruction_PAPI_Fail_Message"
                      values={{
                        errorCode
                      }}
                    />
                  </span>
                </NotificationMessage>
              </NotificationMessageIcon>
            </NotificationMessageType>
          );
        })}
        <ExceptionPolicySection appData={appData} />
        <ContactUsExceptionCard />
      </SectionRow>
    </InstructionHistoryExceptionWrapper>
  );
};

InstructionHistoryException.propTypes = {
  appData: PropTypes.object.isRequired,
  apiErrors: PropTypes.array.isRequired
};

export default InstructionHistoryException;
