import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextDropDown from './TextDropDown';
import { trackEvents } from '../../../utils/tealiumUtils';

export default class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    const { onChange } = this.props;
    this.onChange = onChange || this.onChange;
    this.state = {
      isOpen: false
    };
  }

  onMenuToggle = ({ isOpen }) => this.setState({ isOpen });

  onSelection = value => {
    this.onChange(value);
    trackEvents({ eventName: 'dropdown', eventContent: value });
  };

  onChange = () => true;

  render() {
    const { options = [], value = 'en' } = this.props;
    const { isOpen } = this.state;
    switch (value) {
      case 'zh':
        document.documentElement.lang = 'zh-Hans';
        break;
      case 'zh-tc':
        document.documentElement.lang = 'zh-Hant';
        break;
      default:
        document.documentElement.lang = value;
        break;
    }
    return (
      <TextDropDown
        options={options}
        selectedValue={value}
        isOpen={isOpen}
        onSelection={this.onSelection}
        onMenuToggle={this.onMenuToggle}
        {...this.props}
      />
    );
  }
}

LanguageSelector.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

LanguageSelector.defaultProps = {
  options: [],
  value: 'en'
};
