import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {
  SectionWrapper,
  SectionHeader,
  SectionHeaderText,
  SectionImgSpan
} from './SectionStyle';
import {
  DetailsPageMiddleSection,
  ReproWrapperDiv,
  ReproLeftDiv,
  ReproRightDiv,
  ReproIntroSection,
  ReproImage,
  ImagePath
} from '../detailPageStyle';
import ReprojectionLink from '../templates/ReprojectionLink';
import { getImagePath } from '../../utils/routeUtils';
import InvestmentImage from '../../assets/images/investment.svg';
import isCustomerWhiteListed from '../../utils/customerUtils';

export const getReprojectionLink = function getReprojectionLink(
  currentPolicyDetail,
  functionEligibility
) {
  let reProjectionLink;
  let isReprojectionServicingOptionAvailable;

  const { policyServicingOptions } = currentPolicyDetail;
  if (policyServicingOptions && policyServicingOptions.length > 0) {
    isReprojectionServicingOptionAvailable = _.includes(
      policyServicingOptions,
      'Reprojection'
    );
  }

  if (
    isReprojectionServicingOptionAvailable &&
    isCustomerWhiteListed('Reprojection_Web', functionEligibility)
  ) {
    reProjectionLink = (
      <ReprojectionLink policyOverview={currentPolicyDetail.policyOverview} />
    );
  }
  return reProjectionLink;
};

const ReprojectionEntry = function ReprojectionEntry(props, context) {
  const {
    sectionHeading,
    currentPolicyDetail,
    functionEligibility,
    commonToSections: {
      ReprojectionEntrySection: { sectionImage }
    }
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const reprojectionImage = getImagePath(
    process.env.REACT_APP_IMAGES_URL,
    sectionImage
  );

  if (!currentPolicyDetail) {
    return null;
  }
  // 'Reprojection card is enable, if in policydetails response PolicyServicingOptions array has ‘Reprojection’.
  const returnReProjectionLink = getReprojectionLink(
    currentPolicyDetail,
    functionEligibility
  );

  if (!returnReProjectionLink) {
    return null;
  }
  return (
    <DetailsPageMiddleSection>
      <SectionWrapper>
        <SectionHeader>
          <SectionImgSpan>
            <ImagePath aria-hidden="true" alt="" src={InvestmentImage} />
          </SectionImgSpan>
          <SectionHeaderText>
            {formatMessage({ id: sectionHeading })}
          </SectionHeaderText>
        </SectionHeader>
        <ReproWrapperDiv>
          <ReproLeftDiv>
            <ReproImage>
              <ImagePath
                aria-hidden="true"
                alt={formatMessage({ id: 'decorativeImage' })}
                src={reprojectionImage}
              />
            </ReproImage>
          </ReproLeftDiv>
          <ReproRightDiv>
            <ReproIntroSection>
              {formatMessage({ id: 'reprojectionDesc' })}
            </ReproIntroSection>
          </ReproRightDiv>
        </ReproWrapperDiv>
        {returnReProjectionLink}
      </SectionWrapper>
    </DetailsPageMiddleSection>
  );
};

ReprojectionEntry.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  functionEligibility: PropTypes.array,
  commonToSections: PropTypes.object.isRequired
};

ReprojectionEntry.defaultProps = {
  functionEligibility: []
};

ReprojectionEntry.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReprojectionEntry;
