import React from 'react';
import PropTypes from 'prop-types';
import {
  ChartLegendsCheckboxWrapper,
  ChartLegendsCheckboxField
} from './ChartLegendsStyles';

const ChartLegendsCheckbox = (
  { includeNonGuranteedPayout, label, onCheckboxChange, checkboxId },
  { intl: { formatMessage } }
) => (
  <ChartLegendsCheckboxWrapper>
    <ChartLegendsCheckboxField
      items={[
        {
          id: `checkbox_item_${checkboxId}`,
          label: formatMessage({ id: label }),
          isChecked: includeNonGuranteedPayout
        }
      ]}
      size={32}
      tabletSize={32}
      mobileSize={26}
      arialabel="checkbox"
      className="custom-checkbox"
      disabled={false}
      onCheckboxClick={onCheckboxChange}
      id={`checkbox_field_${checkboxId}`}
    />
  </ChartLegendsCheckboxWrapper>
);

ChartLegendsCheckbox.propTypes = {
  includeNonGuranteedPayout: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  checkboxId: PropTypes.string.isRequired
};

ChartLegendsCheckbox.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ChartLegendsCheckbox;
