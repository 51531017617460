import styled from 'styled-components';
import { rem } from 'polished';
import { FontFamily, FontSize } from '../../../utils/font';
import theme from '../../../common/styles/theme';
import media from '../../../utils/media';

export const Table = styled.table`
  border: 1px solid ${theme.detailNotificationBorder};
  border-spacing: 0;
  width: 100%;
  margin-bottom: ${rem('10px')};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  height: ${rem('38px')};
  box-sizing: border-box;
  background-color: ${props =>
    props.policyYear ? `${theme.stepDefault}` : `${theme.whiteColor}`};
`;
export const TableData = styled.td`
  width: 50%;
  padding: ${rem('10px')} ${rem('18px')} ${rem('10px')} ${rem('20px')};
  font-size: ${rem('14px')};
  color: ${theme.fontColor};
  line-height: ${rem('18px')};
  ${FontFamily.RegularFontW01};
  border-left: ${props =>
    props.showBorderLeft ? `1px solid ${theme.detailNotificationBorder}` : 0};
  text-align: center;
  box-sizing: border-box;
  border-bottom: ${props =>
    props.showBorderBottom ? `1px solid ${theme.detailNotificationBorder}` : 0};
  ${props =>
    props.setDynamicWidth &&
    `
        width:${props.setDynamicWidth};
    `}
  ${media.phone`
      padding: 0 ${rem('18px')} 0 ${rem('15px')};
  `}
`;

export const TableHeader = styled.th`
  width: 50%;
  text-align: center;
  padding: ${rem('10px')} ${rem('30px')} ${rem('10px')} ${rem('20px')};
  border-left: 0;
  font-size: ${rem('14px')};
  color: ${theme.fontColor};
  line-height: ${rem('18px')};
  font-weight: normal;
  box-sizing: border-box;
  ${FontFamily.RegularFontW01};
  border-left: ${props =>
    props.showBorderLeft ? `1px solid ${theme.detailNotificationBorder}` : 0};
  border-bottom: ${props =>
    props.showBorderBottom ? `1px solid ${theme.detailNotificationBorder}` : 0};
  ${props =>
    props.setDynamicWidth &&
    `
      width:${props.setDynamicWidth};
  `}
  ${media.phone`
    padding: ${rem('10px')} ${rem('18px')} ${rem('10px')} ${rem('15px')};
`};
`;

export const TableHeaderText = styled.span`
  margin-right: 5px;
`;

export const TableCaption = styled.caption`
  text-align: left;
  margin: 5px 0;
`;

export const TableDiscriptionText = styled.p`
  ${FontFamily.LightFontW01};
  ${FontSize.size5};
`;
