import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  SectionLink,
  SectionLabel,
  SectionIconWrapper,
  ImagePath
} from '../policydetail/detailPageStyle';
import ReportIcon from '../assets/images/Modal/icon/Report.svg';
import WelcomeModal from './WelcomeModal';
import { trackEvents } from '../utils/tealiumUtils';

const OnboardingDocuments = props => {
  const [welcomeModelOverlay, setWelcomeModelOverlay] = useState(false);
  const { currentPolicyDetail } = props;
  return (
    <>
      <SectionLink
        id="onboardingDocumentLink"
        tabIndex="0"
        onClick={() => {
          setWelcomeModelOverlay(!welcomeModelOverlay);
          trackEvents({
            eventName: 'onboardingDocument'
          });
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setWelcomeModelOverlay(!welcomeModelOverlay);
            trackEvents({
              eventName: 'onboardingDocument'
            });
          }
        }}
        rel="noopener noreferrer"
      >
        <SectionIconWrapper>
          <ImagePath aria-hidden="true" alt="" src={ReportIcon} />
        </SectionIconWrapper>
        <SectionLabel>
          <FormattedMessage
            id="onboardingDocuments"
            defaultMessage="Onboarding Documents"
          />
        </SectionLabel>
      </SectionLink>
      {welcomeModelOverlay ? (
        <WelcomeModal
          isOpenModal={welcomeModelOverlay}
          onCancel={() => setWelcomeModelOverlay(!welcomeModelOverlay)}
          policyDetailPage
          customerPolicy={currentPolicyDetail}
        />
      ) : null}
    </>
  );
};

OnboardingDocuments.propTypes = {
  currentPolicyDetail: PropTypes.object.isRequired
};

export default OnboardingDocuments;
