import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import Context from '../../app/Context';

/**
 *
 * @param {*} props
 *          date : date fetched from response
 *          formatType : object contaning year month day format (i.e {yrType: 'YYYY',monType: 'MM',dayType:'DD'})
 *
 * getDateReplaceInCopyObject returns a dateObj in format {year:<yrType>,month :<monType>,day:<dayType>} which is passed to copy content
 * eg : getDateReplaceInCopyObject('2019-05-19',{yrType:'YYYY',monType:'MMM',dayType:'DD'}) returns {year:'2019',month:'May',day:19}
 * moment('13-05-2019','YYYY-MM-DD',true).isValid() returns false ( true flag is for strict checking)
 *
 */
export const getDateReplaceInCopyObject = (date, formatType, apiDateFormat) => {
  const { yrType, monType, dayType } = formatType;
  const dateObj = { year: '', month: '', day: '' };
  if (date) {
    if (moment(date, apiDateFormat, true).isValid()) {
      dateObj.year = moment(date, apiDateFormat).format(yrType);
      dateObj.month = moment(date, apiDateFormat).format(monType);
      dateObj.day = moment(date, apiDateFormat).format(dayType);
    }
  }
  return dateObj;
};

const DateComponent = ({
  date,
  locale = 'en',
  apiDateFormat,
  id = 'defaultDateFormat'
}) => {
  return (
    <Context.Consumer>
      {contextProvider => {
        const { dateFormatAsPerLocale } = contextProvider;
        const dateFormatType =
          dateFormatAsPerLocale && dateFormatAsPerLocale[locale]
            ? dateFormatAsPerLocale[locale]
            : { yrType: 'YYYY', monType: 'MMM', dayType: 'DD' };

        const dateReplaceInCopyObject = getDateReplaceInCopyObject(
          date,
          dateFormatType,
          apiDateFormat
        );
        /**
         *  check if  dateReplaceInCopyObject returns valid date obj containing values for years,months and day ,
         *  if yes then pass it to copy , if not then pass the date returned from api to copy.
         */
        const dateToReplace = _.values(dateReplaceInCopyObject).every(_.isEmpty)
          ? { dateFormat: date }
          : dateReplaceInCopyObject;
        return (
          <>
            <FormattedHTMLMessage
              defaultMessage={`${date}`}
              id={
                date && moment(date, apiDateFormat, true).isValid()
                  ? id
                  : 'defaultDateFormat'
              }
              values={dateToReplace}
            />
          </>
        );
      }}
    </Context.Consumer>
  );
};

DateComponent.contextTypes = {
  intl: PropTypes.object.isRequired
};
DateComponent.propTypes = {
  date: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  apiDateFormat: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default DateComponent;
