import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from './OverviewField';
import { OverviewFieldWrapper, TextLabel } from '../../detailPageStyle';
import DateComponent from '../../../common/date/Date';

const DateField = (props, context) => {
  const {
    intl: { locale }
  } = context;
  const { labelContent, fieldData } = props;
  if (!labelContent || !fieldData || typeof fieldData !== 'string') {
    return <></>;
  }
  return (
    <OverviewFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <TextLabel>
        <DateComponent
          date={fieldData}
          locale={locale}
          apiDateFormat="YYYY-MM-DD"
          id="standaradDateFormat"
        />
      </TextLabel>
    </OverviewFieldWrapper>
  );
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string
};

DateField.defaultProps = {
  toolTipContent: null
};

DateField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default DateField;
