import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableData,
  TableBody,
  TableHeader,
  TableCaption,
  TableDiscriptionText
} from './eecTableStyles';
import { EECTableheader } from './EECModalStyles';

const checkValidWithdrawalLimit = withdrawalLimit =>
  withdrawalLimit !== null && withdrawalLimit !== undefined;

const getWidth = isWithdrawalAvailable =>
  isWithdrawalAvailable ? '32%' : null;

const EECTable = (props, context) => {
  const { tableList } = props;
  const {
    intl: { formatMessage }
  } = context;

  const displayRate = function displayRate(rate) {
    return rate === 'key_na' ? formatMessage({ id: rate }) : `${rate}%`;
  };

  const showPlus = function showPlus(yr) {
    return yr === tableList.length ? '+' : '';
  };

  const table = [];
  const children = [];
  let isWithdrawalAvailable = false;
  for (let i = 0; i < tableList.length; i += 1) {
    const { policyYear, chargeRate, withdrawalLimit = null } = tableList[i];
    isWithdrawalAvailable = checkValidWithdrawalLimit(withdrawalLimit);

    children.push(
      <TableRow key={`key_${i}`} data-testid="surrenderCharge-tableRow">
        <TableHeader
          scope="row"
          id={`policyYear_${i}`}
          headers="endOfPolicyYear"
          showBorderBottom={i !== tableList.length - 1}
          setDynamicWidth={getWidth(isWithdrawalAvailable)}
        >
          {policyYear}
          {showPlus(policyYear)}
        </TableHeader>
        <TableData
          headers={`chargeRate  policyYear_${i}`}
          showBorderLeft
          showBorderBottom={i !== tableList.length - 1}
          setDynamicWidth={getWidth(isWithdrawalAvailable)}
        >
          {displayRate(chargeRate)}
        </TableData>
        {isWithdrawalAvailable && (
          <>
            <TableData
              headers={`withdrawalLimit  policyYear_${i}`}
              showBorderLeft
              showBorderBottom={i !== tableList.length - 1}
              setDynamicWidth={isWithdrawalAvailable ? '34%' : null}
            >
              {displayRate(withdrawalLimit)}
            </TableData>
          </>
        )}
      </TableRow>
    );
  }
  table.push(
    <thead>
      <TableRow policyYear>
        <TableHeader
          showBorderBottom
          scope="col"
          id="endOfPolicyYear"
          setDynamicWidth={isWithdrawalAvailable ? '34%' : null}
        >
          {formatMessage({ id: 'policy_year_th' })}
        </TableHeader>
        <TableHeader
          showBorderBottom
          scope="col"
          headers="endOfPolicyYear"
          id="surrenderChargeRate"
          showBorderLeft
          setDynamicWidth={isWithdrawalAvailable ? '32%' : null}
        >
          {formatMessage({ id: 'charge_rate_th' })}
        </TableHeader>
        {isWithdrawalAvailable && (
          <>
            <TableHeader
              showBorderBottom
              scope="col"
              headers="endOfPolicyYear"
              id="withdrawal"
              showBorderLeft
              setDynamicWidth={isWithdrawalAvailable ? '34%' : null}
            >
              {formatMessage({ id: 'withdrawal_limit_th' })}
            </TableHeader>
          </>
        )}
      </TableRow>
    </thead>
  );
  table.push(<TableBody>{children}</TableBody>);

  return (
    <>
      {tableList.length > 0 && (
        <>
          <Table>
            <TableCaption>
              <EECTableheader>
                {formatMessage({ id: 'tableCaption_EECTable' })}
              </EECTableheader>
            </TableCaption>
            <>{table}</>
          </Table>
          <TableDiscriptionText>
            {formatMessage({ id: 'tableDicriptionText' })}
          </TableDiscriptionText>
        </>
      )}
    </>
  );
};

EECTable.propTypes = {
  tableList: PropTypes.array.isRequired
};

EECTable.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default EECTable;
