import React from 'react';
import PropTypes from 'prop-types';
import { FundOverviewWrapper } from '../../detailPageStyle';

const FundOverview = function FundOverview(props) {
  const { overviewSectionComponents, transferFundPendingIndicator } = props;

  const renderComponent = function renderComponent(fieldsStack) {
    return (
      fieldsStack &&
      fieldsStack.map(component => {
        return React.cloneElement(component, { transferFundPendingIndicator });
      })
    );
  };

  return (
    <>
      <FundOverviewWrapper id="FundOverview">
        {renderComponent(overviewSectionComponents)}
      </FundOverviewWrapper>
    </>
  );
};

FundOverview.propTypes = {
  overviewSectionComponents: PropTypes.array.isRequired,
  transferFundPendingIndicator: PropTypes.bool.isRequired
};

export default FundOverview;
