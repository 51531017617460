import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../common/currency/Currency';
import { truncDigits } from '../../../utils/formatter';
import {
  FieldBoxContainer,
  FieldLabel,
  FieldValue
} from '../../reprojectionPageStyle';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';

const TotalPremiumPaid = function TotalPremiumPaid(props, context) {
  const {
    intl: { formatMessage }
  } = context;
  const { labelContent, fieldData, toolTipContent, allowDecimals } = props;
  const isReprojectionsTooltip = true;
  if (
    !labelContent ||
    !fieldData ||
    !fieldData.currency ||
    !fieldData.amount ||
    truncDigits(fieldData.amount, 0) <= 0
  ) {
    return <></>;
  }

  return (
    <FieldBoxContainer id={labelContent}>
      <FieldLabel id="total_premium_paid_label">
        {formatMessage({
          id: labelContent
        })}
        <TooltipWrapper
          id="tooltip_projected_total_premium_paid"
          contentKey={toolTipContent}
          sectionHeading=" "
          labelContent={labelContent}
          maxWidth={300}
          isReprojectionsTooltip={isReprojectionsTooltip}
        />
      </FieldLabel>
      <FieldValue>
        <Currency
          id="premium_paid_currency"
          currencyCode={fieldData.currency}
          amount={fieldData.amount}
          decimalRange={allowDecimals ? 2 : 0}
          /* 
          * decimalRange = 2 has been hard set to handle default behavior of
            javascript to allowed number to display 123.10 and dnt want to 
            trim it as 123.1 if number is 123 it will display as 123.00
        */
        />
      </FieldValue>
    </FieldBoxContainer>
  );
};

TotalPremiumPaid.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string.isRequired,
  allowDecimals: PropTypes.bool.isRequired
};

TotalPremiumPaid.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TotalPremiumPaid;
