import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';

const OverviewPolicyCurrencyField = props => {
  const { fieldData, labelContent } = props;
  if (!labelContent || !fieldData || typeof fieldData !== 'string') {
    return <React.Fragment />;
  }
  return (
    <OverviewFieldWrapper id={fieldData}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>
          <FormattedMessage id={`${fieldData}_CURRENCY`} />
        </DataTextBold>
      </OverviewInfoFieldDataValue>
    </OverviewFieldWrapper>
  );
};

OverviewPolicyCurrencyField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string
};

OverviewPolicyCurrencyField.defaultProps = {
  toolTipContent: null
};

export default OverviewPolicyCurrencyField;
