import { select, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';
import {
  setWarningsOrErrors,
  endLoader,
  getCoreFundDetails
} from '../app/appActions';
import { GET_POLICY } from '../actionTypes';
import {
  policySagaErrorHandler,
  preparePolicyAPIConfig,
  preparePolicyStorePayload,
  handleDetailsApiFailure,
  prepareInstructionStorePayload
} from '../utils/sagaUtils';
import {
  trackViews,
  getProductCategory,
  getProductSubCategory
} from '../utils/tealiumUtils';
import { doAND, doOR } from '../common/util/commonUtil';

const getCustomerIdentifier = state => state.customer.customerIdentifier;
const getCustomerPermId = state => state.customer.permId;
const getFunctionEligibility = state => state.customer.functionEligibility;
const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;
const getPolicyState = state => state.policy;
const getCustomerSegment = state => state.customer.customerSegment || null;
const getVIHLinkVisitedFromEstmt = state =>
  state.eStatementPreferenceData.isVIHLinkVisitedFromEstmt;
// const getAppBootstrapComplete = state => state.app.getAppBootstrapComplete;
let VIHLinkVisitedFromEstmt;
const getErrorsOnSummary = warningsOrErrors => {
  return warningsOrErrors.summary && warningsOrErrors.summary.errors;
};

const doWeHaveSummary = (
  encodePolicyNumber,
  isSummaryCalled,
  hasErrorsOnSummary
) => {
  return !encodePolicyNumber && !isSummaryCalled && !hasErrorsOnSummary;
};
const doWeHaveDetails = (
  encodePolicyNumber,
  byEncodeId,
  isVIHLinkVisitedFromEstmt = false
) => {
  if (encodePolicyNumber) {
    const policy = byEncodeId[encodePolicyNumber];
    if (isVIHLinkVisitedFromEstmt) {
      return true;
    }
    if (policy) {
      return !policy.policyDetails;
    }
    return true;
  }
  return false;
};

const actionsOnSummaryPage = (
  policy,
  subProductFamilies,
  productFamilyOrder,
  policyRowCount,
  hasErrorsOnSummary
) => {
  const { totalPolicyCount, summaryWarningsPageLevel } = policy;
  const productCategoryArray = [];
  let warninglabelContent = '';
  _.map(subProductFamilies, subProductFamily => {
    productCategoryArray.push(subProductFamily.productCategoryName);
  });
  getProductCategory(_.toString(productFamilyOrder));
  getProductSubCategory(productCategoryArray, totalPolicyCount);
  if (!hasErrorsOnSummary) {
    trackViews({ viewName: 'onSummaryPageLoad', policyRowCount });
  }

  summaryWarningsPageLevel.map(alert => {
    warninglabelContent = `${alert.label}`;
    trackViews({
      viewName: 'onSummaryPageLoadNotificationMsg',
      message: _.replace(warninglabelContent, 'Label', '').toLowerCase(),
      messageType: 'warning'
    });
    return true;
  });
};

const dispatchWarning = (encodePolicyNumber, warnings) => {
  return warnings.length > 0 && !encodePolicyNumber;
};

function* actionsForRpqOptInCustomer(
  rpqDetails,
  optOutIndicator,
  planCode,
  policyCurrency
) {
  if (doAND(rpqDetails, optOutIndicator === false)) {
    const {
      rpqResponse: { customerRpqLevel }
    } = rpqDetails;
    const fundSelectionRequestParams = {
      planCode,
      customerRpqLevel,
      policyCurrency
    };
    yield put(getCoreFundDetails(fundSelectionRequestParams));
  }
}

export function* fetchPolicy(action) {
  const { payload: encodePolicyNumber = null } = action;
  try {
    const customerIdentifier = yield select(getCustomerIdentifier);
    const functionEligibility = yield select(getFunctionEligibility);
    const customerSegment = yield select(getCustomerSegment);
    const configurationObj = yield select(getConfigurations);
    const customerPermId = yield select(getCustomerPermId);
    VIHLinkVisitedFromEstmt = yield select(getVIHLinkVisitedFromEstmt);

    // const appBootstrapComplete = yield select(getAppBootstrapComplete);
    let policy = yield select(getPolicyState);
    const { byEncodeId, isSummaryCalled, warningsOrErrors } = policy;
    const {
      genericConfig: { commonHeaders, policyRowCount },
      serviceConfig: { policies, policyDetails },
      productConfig: { subProductFamilies, productFamilyOrder }
    } = configurationObj;
    const saasAuth = yield select(getSaasToken);

    if (customerIdentifier) {
      /**
       * @description: If policy summary not called, make a call to get the summary information.
       */
      const hasErrorsOnSummary = getErrorsOnSummary(warningsOrErrors);

      if (
        doOR(
          doWeHaveSummary(
            encodePolicyNumber,
            isSummaryCalled,
            hasErrorsOnSummary
          ),
          doWeHaveDetails(
            encodePolicyNumber,
            byEncodeId,
            VIHLinkVisitedFromEstmt.isVIHLinkVisitedFromEstmt
          )
        )
      ) {
        const policyHeaders = {
          ...commonHeaders,
          Authorization: `Bearer ${saasAuth}`,
          'X-HSBC-CustomerId': customerIdentifier
        };

        const policyConfig = preparePolicyAPIConfig(
          encodePolicyNumber,
          policies,
          policyDetails,
          policyHeaders,
          customerSegment
        );
        const policyInfoResponse = yield call(axios, policyConfig);
        const policyInfoResponseData = policyInfoResponse.data;
        const policyStorePayload = preparePolicyStorePayload(
          encodePolicyNumber,
          policyInfoResponseData,
          subProductFamilies,
          productFamilyOrder,
          false,
          byEncodeId,
          functionEligibility,
          customerIdentifier,
          customerSegment,
          customerPermId
        );
        const instructionStorePayload = prepareInstructionStorePayload(
          encodePolicyNumber,
          policyInfoResponseData
        );
        if (instructionStorePayload) {
          yield put(instructionStorePayload);
        }
        yield put(policyStorePayload);
        policy = policyStorePayload.payload;
        /**
         * Checking if there is any warninfug available in the API's response, if there is any, then updating that warning code in the Redux store.
         */
        const { warnings = [] } = policyInfoResponseData;
        if (dispatchWarning(encodePolicyNumber, warnings)) {
          /**
           * Dispatching an action which would save the warning code in the Redux store.
           */
          yield put(
            setWarningsOrErrors({
              summary: { warnings }
            })
          );
        }
      }

      if (doAND(encodePolicyNumber, byEncodeId[encodePolicyNumber])) {
        const policyDetailsByEncodeID = byEncodeId[encodePolicyNumber];
        trackViews({
          viewName: 'onDetailsPageLoad',
          pageTealiumDetails: policyDetailsByEncodeID.policyDetails
        });
        const {
          policyDetails: {
            policyOverview: { planCode, policyCurrency, optOutIndicator },
            rpqDetails
          }
        } = policyDetailsByEncodeID;
        yield* actionsForRpqOptInCustomer(
          rpqDetails,
          optOutIndicator,
          planCode,
          policyCurrency
        );
      } else {
        actionsOnSummaryPage(
          policy,
          subProductFamilies,
          productFamilyOrder,
          policyRowCount,
          hasErrorsOnSummary
        );
      }
    }
    yield put(endLoader());
  } catch (err) {
    const errorDispatchObject = policySagaErrorHandler(err, encodePolicyNumber);
    yield put(setWarningsOrErrors(errorDispatchObject));
    // check if policy details  is fail then redirect to policy summary page
    const isSummaryCalled = yield select(state => state.policy.isSummaryCalled);
    const isDetailPage = !!encodePolicyNumber;
    // Special handling: check if policy details  is fail then redirect to policy summary page
    if (handleDetailsApiFailure(err, isDetailPage, isSummaryCalled)) {
      yield put(endLoader());
    }
  }
}

export default function* policySaga() {
  yield takeEvery(GET_POLICY, fetchPolicy);
}
