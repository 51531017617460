import styled from 'styled-components';
import { em, rem } from 'polished';
import theme from '../../common/styles/theme';
import { FontFamily } from '../../utils/font';

export const CardContainer = styled.div`
  display: block;
  width: 100%;
  border-left: 5px solid ${props => {
    if (props.type === 'whoIsCovered') {
      return 'none';
    }
    return props.backgroundColor;
  }};
  padding: ${props =>
    props.type === 'whoIsCovered' ? '11px 10px 3px 0px' : '11px 10px 3px 10px'}
  border-bottom: 1px solid ${theme.lightGreyColor};
  box-sizing: border-box;
`;
export const FundWrapper = styled.div`
  > div:first-child {
    border-top: ${props =>
      props.type === 'whoIsCovered'
        ? 'none'
        : `1px solid ${theme.lightGreyColor}`};
  }
  padding: 0px 15px 0px 15px;
`;
export const CardFundNameLabel = styled.div`
  display: block;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  font-size: ${em(`14px`)};
  color: ${theme.fundFont};
  padding-bottom: 12px;
`;

export const CardDetailsWrapper = styled.div`
  display: block;
`;

export const FundLabel = styled.span`
  ${FontFamily.BoldFontW01};
  div {
    line-height: ${em(`18px`)};
  }
`;

export const FundCode = styled.span`
  line-height: ${em(`18px`)};
  ${FontFamily.RegularFontW01};
`;
export const CardList = styled.dl`
  margin: 0;
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 0 8px 0;
  box-sizing: border-box;
`;
export const CardListKey = styled.dt`
  display: block;
  margin: 0;
  width: 40%;
  font-size: ${em(`12px`)};
  color: #575757;
  line-height: 18px;
`;
export const CardListValue = styled.dd`
  display: flex;
  margin: 0;
  width: 60%;
  text-align: right;
  font-size: ${em(`12px`)};
  color: #333333;
  letter-spacing: 0;
  justify-content: flex-end;
  span {
    font-size: ${rem(`12px`)};
  }
`;

export const CardListSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
