import React from 'react';
import PropTypes from 'prop-types';
import {
  RiderWrapper,
  RiderLeft,
  RiderRight,
  RiderFlexContainer
} from '../../detailPageStyle';
import {
  checkFieldData,
  childProtectionRightSectionData
} from '../../../utils/policyUtils';

// Todo: to add SupplementaryTopSection for riderName and supplementary Addon in future sprint
// RiderAddOns style to be used in future for embedded and exclusive riderCategory from detailPageStyle
const SupplementarySubSection = ({
  leftSection,
  rightSection,
  clubChildRider
}) => {
  const checkFieldValues = () => {
    return clubChildRider
      ? childProtectionRightSectionData(rightSection)
      : rightSection && checkFieldData(rightSection);
  };
  return (
    <RiderWrapper>
      {leftSection && leftSection[0]}
      <RiderFlexContainer>
        <RiderLeft rightSectionData={checkFieldValues()}>
          {leftSection &&
            leftSection.map((leftSectionInstance, index) =>
              index !== 0 ? (
                <React.Fragment key={leftSectionInstance.props.id}>
                  {leftSectionInstance}
                </React.Fragment>
              ) : (
                <React.Fragment key={leftSectionInstance.props.id} />
              )
            )}
        </RiderLeft>
        <RiderRight
          rightSectionData={rightSection && checkFieldData(rightSection)}
        >
          {rightSection}
        </RiderRight>
      </RiderFlexContainer>
    </RiderWrapper>
  );
};

SupplementarySubSection.propTypes = {
  leftSection: PropTypes.array.isRequired,
  rightSection: PropTypes.array.isRequired,
  clubChildRider: PropTypes.bool.isRequired
};

export default SupplementarySubSection;
