import React from 'react';
import PropTypes from 'prop-types';
import { BasicPlanHeader } from '../../sections/SectionStyle';
import ShowIf from '../../../utils/showIf';
import getDescription from '../../../utils/reducerUtils';

const BasicPlanName = function BasicPlanName(props, context) {
  const { fieldData } = props;
  const {
    intl: { locale }
  } = context;

  if (!fieldData || typeof fieldData !== 'object' || !fieldData.length) {
    return <></>;
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>
        <BasicPlanHeader id="policy_name">
          {getDescription(locale, fieldData)}
        </BasicPlanHeader>
      </ShowIf>
    </>
  );
};

BasicPlanName.propTypes = {
  fieldData: PropTypes.array.isRequired
};

BasicPlanName.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BasicPlanName;
