import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';

const OverviewFieldNumber = props => {
  const { labelContent, fieldData } = props;
  if (!labelContent || !fieldData || typeof fieldData !== 'string') {
    return <></>;
  }

  return (
    <OverviewFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>{fieldData}</DataTextBold>
      </OverviewInfoFieldDataValue>
    </OverviewFieldWrapper>
  );
};

OverviewFieldNumber.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired
};

export default OverviewFieldNumber;
