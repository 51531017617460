import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as icons from '../../../assets/images/NotificationIcons';
import Context from '../../../app/Context';
import {
  NotificationMessageType,
  NotificationMessageIcon,
  ImagePath,
  NotificationTextSpan
} from '../../../common/styles/commonStyle';
import {
  InstructionNotificationSpan,
  OverviewNotificationDiv,
  ReprojectionLinkWrapper,
  ReprojectionLabel,
  NotificationMessageStyled
} from '../../detailPageStyle';

const OverviewNotification = (props, context) => {
  const { hasNotification, encodePolicyNumber } = props;
  const {
    intl: { formatMessage }
  } = context;

  if (hasNotification) {
    const {
      content: { key }
    } = hasNotification;

    return (
      <Context.Consumer>
        {contextProvider => {
          const {
            applicationUrl: { forLink }
          } = contextProvider;
          const { reprojections } = forLink || {};
          return (
            <OverviewNotificationDiv>
              <NotificationMessageType bgColor="#e5f2f2" borderColor="#99cecc">
                <NotificationMessageIcon role="alert">
                  <InstructionNotificationSpan>
                    <ImagePath aria-hidden="true" alt="" src={icons.Success} />
                  </InstructionNotificationSpan>
                  <NotificationMessageStyled>
                    <NotificationTextSpan id="reprojAdjustmentMsg">
                      <span>
                        {formatMessage({ id: key })}
                        <ReprojectionLinkWrapper>
                          <Link to={`${reprojections}${encodePolicyNumber}`}>
                            <ReprojectionLabel>
                              {formatMessage({ id: 'learnMore' })}
                            </ReprojectionLabel>
                          </Link>
                        </ReprojectionLinkWrapper>
                      </span>
                    </NotificationTextSpan>
                  </NotificationMessageStyled>
                </NotificationMessageIcon>
              </NotificationMessageType>
            </OverviewNotificationDiv>
          );
        }}
      </Context.Consumer>
    );
  }
  return null;
};

OverviewNotification.propTypes = {
  hasNotification: PropTypes.object.isRequired,
  encodePolicyNumber: PropTypes.object.isRequired
};

OverviewNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default OverviewNotification;