import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  PaymentWrapper,
  LabelText,
  FieldWrapper,
  FieldValue
} from '../../sections/SectionStyle';

const ReturnsPaymentMade = function ReturnsPaymentMade(props, context) {
  const {
    fieldData,
    dependentFieldData,
    labelContent,
    annuityPhaseIndicator
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  if (annuityPhaseIndicator === 'AF' && (!fieldData || !dependentFieldData)) {
    return <></>;
  }
  if (annuityPhaseIndicator === 'ON' && !fieldData) {
    return <></>;
  }
  return (
    <PaymentWrapper id="returnPaymentPaid">
      <LabelText id="paymentMadeOutOf">
        {formatMessage({ id: labelContent })}
      </LabelText>
      <FieldWrapper aria-describedby="paymentMadeOutOf">
        <FieldValue>
          <FormattedMessage
            id="paymentMadeOutOf_Label_1"
            values={{
              dependentField: (
                <>{annuityPhaseIndicator === 'ON' ? 0 : dependentFieldData}</>
              )
            }}
          />
          <FormattedMessage
            id="paymentMadeOutOf_Label_2"
            values={{
              paid: <>{fieldData}</>
            }}
          />
        </FieldValue>
      </FieldWrapper>
    </PaymentWrapper>
  );
};

ReturnsPaymentMade.contextTypes = {
  intl: PropTypes.object.isRequired
};

ReturnsPaymentMade.propTypes = {
  fieldData: PropTypes.number,
  dependentFieldData: PropTypes.number,
  annuityPhaseIndicator: PropTypes.string,
  labelContent: PropTypes.string.isRequired
};
ReturnsPaymentMade.defaultProps = {
  fieldData: 0,
  annuityPhaseIndicator: '',
  dependentFieldData: 0
};

export default ReturnsPaymentMade;
