import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Tabs from '../../common/carouselTabs/Tabs';
import Tab from '../../common/carouselTabs/Tab';
import {
  Row,
  FutureBenefitsTabWrapper,
  FutureBenefitsTab,
  FutureSection,
  FullWidthFutureSection,
  FutureSectionRow,
  HeaderContentRow,
  ProjectedSummaryRow,
  FutureBenefitsMobileTabsWrapper,
  DisclaimersSection
} from '../reprojectionPageStyle';
import { AccessibleHidden } from '../../common/styles/commonStyle';
import ReprojectionTabBuilder from '../ReprojectionTabBuilder';
import FutureBenefitsMobileTabs from '../templates/FutureValueProjection/FutureBenefitsMobileTabs';
import { ShowIfDesktop, ShowIfMobile } from '../../utils/showIf';
import { isTab } from '../../utils/domUtils';
import DisclaimerBuilder from '../disclaimersection/DisclaimerBuilder';
import SingleAggrePremiumSection from '../disclaimersection/SingleAggrePremiumSection';
import { trackViews } from '../../utils/tealiumUtils';
import { getPlanCodeList } from '../utils/reprojectionDataUtils';

/**
 * Renders Future Value Projections along with the the tabs
 * sectionFieldMapper : list of all sections object
 * subSectionsMapper: list of all subSections object
 * fieldConfig: list of all fields object
 */

const FutureValueSection = function FutureValueSection(props, context) {
  const {
    intl: { formatMessage }
  } = context;
  const {
    reprojectionsDetails,
    fieldsStack,
    sectionFieldMapper,
    subSectionsMapper,
    fieldConfig,
    productCategory,
    dependentFieldConfig,
    sectionHeading,
    reprojectionGenericConfig,
    reprojectionChartConfig,
    customerAge,
    setCustomerAge,
    setCustomerMinPayAge,
    setCustomerMaxPayAge,
    customerMinPayAge,
    customerMaxPayAge,
    toggleIncludeNonGuaranteedPayout,
    tabList,
    tabValues,
    disclaimerConfig = {},
    explanatoryNotesConfigurations,
    setReprojectionsSelectTabHandler,
    selectedFutureBenefitsTab,
    sectionNotificationConfig
  } = props;

  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  }, [width]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const refMethod = React.createRef();

  // updated the selected Index for tab ( only when the screen comes under tablet resolution)
  const onWindowResize = () => {
    if (refMethod && refMethod.current && isTab()) {
      refMethod.current.updateSelectedTabValue(selectedIndex);
    }
  };

  // useEffects - used for cases when resizing the browser from dropdown to tabs
  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  const onFieldChange = selectedDropdownValue => {
    const { tabIdentifier: selectedTab } = tabList[selectedDropdownValue];
    setSelectedIndex(selectedDropdownValue);
    setReprojectionsSelectTabHandler(selectedTab);
    trackViews({
      viewName: 'onFutureBenefitsTabChanged',
      pageTealiumDetails: reprojectionsDetails,
      selectedTab
    });
  };

  if (_.isEmpty(reprojectionsDetails)) {
    return null;
  }

  return (
    <>
      <FullWidthFutureSection>
        <FutureSection>
          <FutureSectionRow>
            <HeaderContentRow>
              {formatMessage({
                id: sectionHeading
              })}
            </HeaderContentRow>
            <>{fieldsStack[0][0]}</>
          </FutureSectionRow>
          <Row>{fieldsStack[0][1]}</Row>
          <Row>{fieldsStack[1][0]}</Row>
          <ProjectedSummaryRow>
            {fieldsStack[1] &&
              fieldsStack[1].map((component, index) => {
                if (index === 0) {
                  return <React.Fragment key={`field_${component.key}`} />;
                }
                return (
                  <React.Fragment key={`fields_${component.key}`}>
                    {component}
                  </React.Fragment>
                );
              })}
          </ProjectedSummaryRow>
        </FutureSection>
        {tabList && tabList.length > 0 && (
          <FutureBenefitsTabWrapper>
            <ShowIfDesktop>
              <FutureSection>
                <FutureBenefitsTab>
                  <Tabs
                    id="reprojectionTab"
                    sectionStyle="reprojection"
                    ref={refMethod}
                    onFieldChange={onFieldChange}
                    selectedTab={selectedIndex}
                  >
                    {_.map(tabList, reprojectionInstance => {
                      return (
                        <Tab
                          label={formatMessage({
                            id: reprojectionInstance.title
                          })}
                          key={reprojectionInstance.title}
                        >
                          <ReprojectionTabBuilder
                            productCategory={productCategory}
                            reprojectionsDetails={reprojectionsDetails}
                            sectionToRender={reprojectionInstance.section}
                            sectionFieldMapper={sectionFieldMapper}
                            subSectionsMapper={subSectionsMapper}
                            fieldConfig={fieldConfig}
                            dependentFieldConfig={dependentFieldConfig}
                            reprojectionGenericConfig={
                              reprojectionGenericConfig
                            }
                            reprojectionChartConfig={reprojectionChartConfig}
                            setCustomerAge={setCustomerAge}
                            customerAge={customerAge}
                            customerMinPayAge={customerMinPayAge}
                            customerMaxPayAge={customerMaxPayAge}
                            setCustomerMinPayAge={setCustomerMinPayAge}
                            setCustomerMaxPayAge={setCustomerMaxPayAge}
                            toggleIncludeNonGuaranteedPayout={
                              toggleIncludeNonGuaranteedPayout
                            }
                            tabValues={tabValues}
                            selectedFutureBenefitsTab={
                              selectedFutureBenefitsTab
                            }
                            sectionNotificationConfig={
                              sectionNotificationConfig
                            }
                          />
                        </Tab>
                      );
                    })}
                  </Tabs>
                </FutureBenefitsTab>
              </FutureSection>
            </ShowIfDesktop>

            <ShowIfMobile>
              <FutureBenefitsMobileTabsWrapper id="futureBenefitsMobileTabs">
                <FutureBenefitsMobileTabs
                  productCategory={productCategory}
                  tabList={tabList}
                  formatMessage={formatMessage}
                  reprojectionsDetails={reprojectionsDetails}
                  sectionFieldMapper={sectionFieldMapper}
                  subSectionsMapper={subSectionsMapper}
                  fieldConfig={fieldConfig}
                  dependentFieldConfig={dependentFieldConfig}
                  onFieldChange={onFieldChange}
                  selectedIndex={selectedIndex}
                  reprojectionGenericConfig={reprojectionGenericConfig}
                  reprojectionChartConfig={reprojectionChartConfig}
                  setCustomerAge={setCustomerAge}
                  customerAge={customerAge}
                  customerMinPayAge={customerMinPayAge}
                  customerMaxPayAge={customerMaxPayAge}
                  setCustomerMinPayAge={setCustomerMinPayAge}
                  setCustomerMaxPayAge={setCustomerMaxPayAge}
                  toggleIncludeNonGuaranteedPayout={
                    toggleIncludeNonGuaranteedPayout
                  }
                  tabValues={tabValues}
                  selectedFutureBenefitsTab={selectedFutureBenefitsTab}
                  sectionNotificationConfig={sectionNotificationConfig}
                />
              </FutureBenefitsMobileTabsWrapper>
            </ShowIfMobile>
          </FutureBenefitsTabWrapper>
        )}
      </FullWidthFutureSection>
      <DisclaimersSection>
        <AccessibleHidden id="disclaimersSection_Hidden_AriaLabel">
          {formatMessage({ id: 'disclaimersSection_Hidden_AriaLabel' })}
        </AccessibleHidden>
        <SingleAggrePremiumSection
          reprojectionsDetails={reprojectionsDetails.reprojectionDetails}
          policyOverview={reprojectionsDetails.policyOverview}
          planCodeList={getPlanCodeList()}
        />

        <DisclaimerBuilder
          disclaimerConfig={disclaimerConfig}
          explanatoryNotesConfigurations={explanatoryNotesConfigurations}
          reprojectionsDetails={reprojectionsDetails}
          selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        />
      </DisclaimersSection>
    </>
  );
};
FutureValueSection.propTypes = {
  fieldsStack: PropTypes.array.isRequired
};

FutureValueSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

FutureValueSection.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  productCategory: PropTypes.string.isRequired,
  sectionFieldMapper: PropTypes.object.isRequired,
  subSectionsMapper: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  sectionHeading: PropTypes.object.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  customerAge: PropTypes.number.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  toggleIncludeNonGuaranteedPayout: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  tabValues: PropTypes.object.isRequired,
  disclaimerConfig: PropTypes.array.isRequired,
  explanatoryNotesConfigurations: PropTypes.object.isRequired,
  setReprojectionsSelectTabHandler: PropTypes.func.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired
};

FutureValueSection.propTypes = {
  fieldsStack: PropTypes.array.isRequired
};

export default FutureValueSection;
