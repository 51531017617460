import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import warningYellow from '../../../assets/images/warning-colour.svg';
import ModalPopUp from '../../../common/modal/PopUpTemplate';

import {
  ImgModal,
  ModalMainHeadContainer,
  ModalMainHeadImageContainer,
  ModalMainHeadImageTextContainer,
  ModalWrapperBeneHeader
} from './beneficiarySectionStyles';
import IrrevocableModalContent from './IrrevocableModalContent';
import AssignedModalContent from './AssignedModalContent';
import NonPersonalRelationModalContent from './NonPersonalRelationModalContent';

const BeneficiarySectionModal = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    isModalOpen,
    onCancelClick,
    irrevocableAssigned,
    assigned,
    encodedProductId,
    isNonPersonalRelation,
    changeBeneficiaryFormLink
  } = props;

  return (
    (irrevocableAssigned || assigned || isNonPersonalRelation) && (
      <ModalPopUp
        isModalOpen={isModalOpen}
        onClose={onCancelClick}
        hideHorizonatalLine
        id="beneficiary_modal_abc"
        modalHeading={
          <ModalMainHeadContainer>
            <ModalMainHeadImageContainer>
              <ImgModal src={warningYellow} />
            </ModalMainHeadImageContainer>
            <ModalMainHeadImageTextContainer>
              <ModalWrapperBeneHeader>
                {formatMessage({ id: 'beneficiary_exception_heading' })}
              </ModalWrapperBeneHeader>
            </ModalMainHeadImageTextContainer>
          </ModalMainHeadContainer>
        }
      >
        {irrevocableAssigned && (
          <IrrevocableModalContent
            encodedProductId={encodedProductId}
            changeBeneficiaryFormLink={changeBeneficiaryFormLink}
          />
        )}
        {assigned && (
          <AssignedModalContent
            encodedProductId={encodedProductId}
            changeBeneficiaryFormDownloadLink={changeBeneficiaryFormLink}
          />
        )}
        {isNonPersonalRelation && !irrevocableAssigned && !assigned && (
          <NonPersonalRelationModalContent
            encodedProductId={encodedProductId}
            changeBeneficiaryFormLink={changeBeneficiaryFormLink}
          />
        )}
      </ModalPopUp>
    )
  );
};

BeneficiarySectionModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  irrevocableAssigned: PropTypes.bool.isRequired,
  assigned: PropTypes.bool.isRequired,
  isNonPersonalRelation: PropTypes.bool.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  changeBeneficiaryFormLink: PropTypes.string.isRequired
};

BeneficiarySectionModal.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    configurations: {
      genericConfig: { changeBeneficiaryFormLink }
    }
  } = state;
  return { changeBeneficiaryFormLink };
};

export default connect(mapStateToProps, null)(BeneficiarySectionModal);
