import _ from 'lodash';
import _find from 'lodash/find';
import { sizes } from './media';
import theme from '../common/styles/theme';

export const isMobile = () => {
  return document.body.clientWidth <= sizes.tablet;
};

export const isTab = () => {
  return (
    document.body.clientWidth <= sizes.tablet &&
    document.body.clientWidth > sizes.phone
  );
};

export const isMiniMobile = () => {
  return document.body.clientWidth <= sizes.phone;
};

export const isDesktop = () => {
  return document.body.clientWidth > sizes.tablet;
};

export const isMiniDesktop = () => {
  return document.body.clientWidth > sizes.miniDesktop;
};

export const isCustomResolution = customWidth => {
  return document.body.clientWidth >= customWidth;
};

export const deriveLabelFromValue = (searchObject, arrayStore, requiredKey) => {
  const foundObj = _find(arrayStore, searchObject);
  if (foundObj) {
    return foundObj[requiredKey];
  }
  return '';
};

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1+
export const isChrome = !!window.chrome && !!window.chrome.webstore;

// Android device detection
export const isAndroid = navigator.userAgent.match(/Android/i);

/**
 * Method to check IOS devices but not IPAD or safari in windows
 * since they don't support applet wallet transaction
 */
export const isIOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = /iPhone|iPod|Macintosh/.test(ua);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !/(Chrome|CriOS|OPiOS)/.test(ua);
};

/**
 * Function to get browser name and version
 * @example: "Chrome 71"
 */
export const getBrowserInfo = ua => {
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) {
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera');
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return M.join(' ');
};

export const setLanguageCode = selectedLanguageCode => {
  let setLanguageValue = 'en';
  switch (selectedLanguageCode) {
    case 'zh':
      setLanguageValue = 'zh-Hans';
      break;
    case 'zh-tc':
      setLanguageValue = 'zh-Hant';
      break;
    default:
      break;
  }
  return setLanguageValue;
};

export const setFocus = function setFocus(indexNumber, superCategory) {
  if (!indexNumber || !superCategory) {
    return null;
  }
  const nextElementIndex = indexNumber + 1;
  setTimeout(() => {
    const productCard = document.getElementById(
      `${superCategory}_${nextElementIndex}`
    );
    const focuslink = productCard.getElementsByTagName('a');
    return focuslink[0].focus();
  }, 0);
  return true;
};

export const setScrollToFocus = (elem, setScroll) => {
  let isFocused = false;
  if (elem) {
    elem.focus();
    isFocused = true;
  }
  if (setScroll) {
    elem.scrollIntoView();
    isFocused = true;
  }
  return isFocused;
};

export const setLinkFocus = (elem, setScroll) => {
  setTimeout(() => {
    setScrollToFocus(elem, setScroll);
  }, 0);
};

export const setLinkFocusToElement = element => {
  if (!element) {
    return false;
  }
  setLinkFocus(element);
  return true;
};

export const getElement = elem => {
  const element = document.getElementById(`${elem}`);
  setLinkFocusToElement(element);
};

export const setBackGroundColorDetailSummaryInstPage = () => {
  const parentContainer = document.getElementById('parentContainer');
  if (parentContainer) {
    if (_.includes(window.location.href, 'instructionhistory')) {
      parentContainer.style.backgroundColor = theme.whiteColor;
    } else {
      parentContainer.style.backgroundColor = theme.bgParentColor;
    }
  }
};
