import styled from 'styled-components';
import { em, rem } from 'polished';
import { FontFamily } from '../../utils/font';
import media from '../../utils/media';

const btnStyle = (backgroundColor, border, color) => `
    background-color: ${backgroundColor};
    border: 1px solid ${border};
    color: ${color};
    cursor: pointer;
`;

const CustomButton = styled.button`
    ${FontFamily.RegularFontW01};
    font-size: ${em('14px')};
    text-align: center;
    text-decoration: none;
    padding: 15px 20px;
    min-width: 80px;
    &:focus{
        outline: none;
    }
    &.redBtn {
        ${btnStyle('#DB0011', '#DB0011', '#FFF')};
        &:hover,&:focus {
            ${btnStyle('#AF000D', '#AF000D', '#FFF')};
            outline:none !important;
        }
        &:active {
            background-color: #83000A;
            outline:none !important;
        }
        &:focus {
            outline:none !important;
            text-decoration:none;
        }
        &::-moz-focus-inner {
            border:0; outline:none;
        }
    }
    &.whiteBtn {
		${btnStyle('#fff', '#333', '#333')};
		&:hover,&:focus {
			${btnStyle('#d9d9d9', '#333', '#333')};
		}
		&:active {
			background-color: #b6b7b6;
        }
    }
    &.blackSlateBtn{
        ${btnStyle('#252525', '#252525', '#FFF')};
        &:hover{
			${btnStyle('#252525', '#252525', '#fff')};
			outline:none;
		}
		&:active {
            ${btnStyle('#000', '#000', '#fff')};
			outline:none;
		}
		&:focus {
			outline:1px dotted #333;
		}
    }
    &.slateBtn {
		${btnStyle('#4D6474', '#fff', '#fff')};
		&:hover,&:focus {
			${btnStyle('#3E505D', '#fff', '#fff')};
			outline:none !important;
		}
		&:active {
			background-color: #004F4B;
			outline:none !important;
		}
		&:focus {
			outline:none !important;
			text-decoration:none;
		}
    }
    &.blackBtn {
		${btnStyle('#000', '#000', '#fff')};
		&:hover,&:focus {
			${btnStyle('#000', '#000', '#fff')};
			outline:none;
		}
		&:active {
			background-color: #004F4B;
			outline:none;
		}
		&:focus {
			outline:1px dotted #333;
		}
    }
    &.log_OFF{
        font-size: ${em('16px')};
        font-weight: normal;
        padding: 12px 20px;
        border: none  !important;
        display: inline-block;
        cursor: pointer;
    }
    ${props =>
      props.disabled &&
      `
        background: none repeat scroll 0 0;
        box-shadow: none;
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
        color:#ccc;
        border: 1px solid #ccc;
    `}
}
`;
export default CustomButton;

export const ButtonLinkStyle = styled.button`
  ${FontFamily.RegularFontW01};
  background: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  font-size: ${rem('14px')};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  color: #333333;
  &:focus,
  &:hover {
    outline: 1px dotted #333;
  }
  ${media.phone`
    font-size: ${rem('12px')};
    text-align: left;
  `};
`;

export const AnchorLinkWrapper = styled.div`
  margin-top: 20px;
`;

export const AnchorLinkStyle = styled.a`
  ${FontFamily.RegularFontW01};
  font-size: ${em('14px')};
  text-align: center;
  text-decoration: none;
  padding: 15px 20px;
  min-width: 80px;
  &:focus {
    outline: none;
  }
  &.redBtn {
    ${btnStyle('#DB0011', '#DB0011', '#FFF')};
    &:hover,
    &:focus {
      ${btnStyle('#AF000D', '#AF000D', '#FFF')};
      outline: none !important;
    }
    &:active {
      background-color: #83000a;
      outline: none !important;
    }
    &:focus {
      outline: none !important;
      text-decoration: none;
    }
    &::-moz-focus-inner {
      border: 0;
      outline: none;
    }
  }
  &.whiteBtn {
    ${btnStyle('#fff', '#333', '#333')};
    &:hover,
    &:focus {
      ${btnStyle('#d9d9d9', '#333', '#333')};
    }
    &:active {
      background-color: #b6b7b6;
    }
  }
  &.blackSlateBtn {
    ${btnStyle('#252525', '#252525', '#FFF')};
    &:hover {
      ${btnStyle('#252525', '#252525', '#fff')};
      outline: none;
    }
    &:active {
      ${btnStyle('#000', '#000', '#fff')};
      outline: none;
    }
    &:focus {
      outline: 1px dotted #333;
    }
  }
  &.slateBtn {
    ${btnStyle('#4D6474', '#fff', '#fff')};
    &:hover,
    &:focus {
      ${btnStyle('#3E505D', '#fff', '#fff')};
      outline: none !important;
    }
    &:active {
      background-color: #004f4b;
      outline: none !important;
    }
    &:focus {
      outline: none !important;
      text-decoration: none;
    }
  }
  &.blackBtn {
    ${btnStyle('#000', '#000', '#fff')};
    &:hover,
    &:focus {
      ${btnStyle('#000', '#000', '#fff')};
      outline: none;
    }
    &:active {
      background-color: #004f4b;
      outline: none;
    }
    &:focus {
      outline: 1px dotted #333;
    }
  }
  &.log_OFF {
    font-size: ${em('16px')};
    font-weight: normal;
    padding: 12px 20px;
    border: none !important;
    display: inline-block;
    cursor: pointer;
  }
  ${props =>
    props.disabled &&
    `
    background: none repeat scroll 0 0;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    color:#ccc;
    border: 1px solid #ccc;
`}
`;
