import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';

const PaymentInfoField = (props, context) => {
  const { labelContent, fieldData, dependentContentData } = props;
  const {
    intl: { formatMessage }
  } = context;
  let value = '';
  if (
    !labelContent ||
    !fieldData ||
    (typeof fieldData !== 'string' && typeof fieldData !== 'number')
  ) {
    return <></>;
  }
  if (dependentContentData) {
    value = `${fieldData} ${formatMessage({ id: dependentContentData })}`;
  }
  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>{dependentContentData ? value : fieldData}</DataTextBold>
      </OverviewInfoFieldDataValue>
    </PaymentInfoFieldWrapper>
  );
};

PaymentInfoField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string,
  dependentContentData: PropTypes.string
};

PaymentInfoField.defaultProps = {
  toolTipContent: null,
  dependentContentData: ''
};

PaymentInfoField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PaymentInfoField;
