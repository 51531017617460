import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import {
  updateIdleFlag,
  startLoader,
  endLoader,
  updateSAASPageFail,
  getReprojectionsInfo,
  setReprojectionsAgeSelection,
  setReprojectionsMinAge,
  setReprojectionsMaxAge,
  setIncludeNonGuaranteedPayout,
  toggleIncludeNonGuaranteedPayout,
  setReprojectionsSelectedTab,
  setReprojectionsInfo,
  resetReprojectionsErrorsWarnings
} from '../app/appActions';
import ReprojectionNotification from './ReprojectionNotification';
import ErrorBoundary from '../app/ErrorBoundary';
import { FullWidthSectionContainer } from '../common/styles/commonStyle';
import ReprojectionSectionBuilder from './ReprojectionSectionBuilder';
import { isApplicationStubbed } from '../utils/routeUtils';
import { fetchSaasToken } from '../utils/saasAuthModuleUtil';
import prepareReprojectionStore, {
  getConfiguratorIdentifier,
  getInitialCheckboxState
} from '../utils/reprojectionsUtils';
import { filterTabList } from './utils/reprojectionDataUtils';

import ReprojectionsException from './ReprojectionsException';
import { trackViews } from '../utils/tealiumUtils';

class ReprojectionPage extends Component {
  componentDidMount() {
    const {
      updateIdleState,
      startLoaderHandler,
      endLoaderHandler,
      updateSAASPageFailHandler,
      isLoading
    } = this.props;

    !isLoading && startLoaderHandler(); // This would be called only incase of coming from details page.
    if (isApplicationStubbed()) {
      const {
        reprojectionsByEncodeId,
        match,
        setReprojectionsInfoHandler
      } = this.props;
      const {
        params: { encodedProductId }
      } = match;
      const hasReprojection = reprojectionsByEncodeId[encodedProductId];
      if (!hasReprojection) {
        const reprojectionsArray = require('../assets/reprojectionsInArray.json');
        _.forEach(reprojectionsArray, reprojection => {
          const updatedByEncodeId = prepareReprojectionStore(reprojection);
          setReprojectionsInfoHandler(updatedByEncodeId);
        });
      }
      this.onPageMount();
    } else {
      fetchSaasToken()
        .then(() => {
          this.onPageMount();
        })
        .catch(() => {
          updateIdleState(true);
          updateSAASPageFailHandler();
          endLoaderHandler();
        });
    }
  }

  componentWillUnmount() {
    const {
      setIncludeNonGuaranteedPayoutHandler,
      setCustomerAge,
      setCustomerMinPayAge,
      setCustomerMaxPayAge,
      setReprojectionsSelectTabHandler,
      resetReprojectionsError
    } = this.props;
    if (setCustomerAge) {
      setCustomerAge(null);
    }
    if (setCustomerMinPayAge) {
      setCustomerMinPayAge(null);
    }
    if (setCustomerMaxPayAge) {
      setCustomerMaxPayAge(null);
    }
    setReprojectionsSelectTabHandler(null);
    setIncludeNonGuaranteedPayoutHandler({ defaultTabValues: {} });
    resetReprojectionsError();
  }

  onPageMount() {
    window.scrollTo(0, 0);
    const {
      fetchReprojection,
      match,
      reprojectionsByEncodeId,
      endLoaderHandler,
      reprojectionTabList,
      setReprojectionsSelectTabHandler
    } = this.props;
    const {
      params: { encodedProductId }
    } = match;
    const reprojection = reprojectionsByEncodeId[encodedProductId];
    if (!reprojection) {
      fetchReprojection(encodedProductId);
    } else {
      /**
       *  Fetch the configuratorIndentifier
       */
      const configuratorIdentifier = getConfiguratorIdentifier(
        this.reprojectionObj
      );
      /**
       *  tabList: Contains the list of tabs to be displayed for reprojection.
       */
      const reprojectionTabListByCategory =
        reprojectionTabList[configuratorIdentifier] ||
        reprojectionTabList.DEFAULT;
      this.tabList = filterTabList(reprojectionTabListByCategory, reprojection);
      const { tabIdentifier: selectedTab } = this.tabList[0];
      setReprojectionsSelectTabHandler(selectedTab);
      endLoaderHandler();
      trackViews({
        viewName: 'onReprojectionsPageLoad',
        pageTealiumDetails: reprojectionsByEncodeId[encodedProductId],
        selectedTab
      });
    }
  }

  getReprojectionFromEncodeId(encodedProductId, byEncodeId) {
    this.reprojectionObj =
      !_.isEmpty(byEncodeId) && byEncodeId[encodedProductId]
        ? byEncodeId[encodedProductId]
        : {};
  }

  render() {
    const {
      reprojectionsByEncodeId,
      match,
      selectedLocale,
      langObj,
      reprojectionGenericConfig,
      reprojectionChartConfig,
      sectionNotificationConfig,
      customerAge,
      setCustomerAge,
      setCustomerMinPayAge,
      setCustomerMaxPayAge,
      customerMinPayAge,
      customerMaxPayAge,
      setIncludeNonGuaranteedPayoutHandler,
      toggleIncludeNonGuaranteedPayoutHandler,
      tabValues,
      disclaimerConfig,
      setReprojectionsSelectTabHandler,
      reprojectionTabList,
      selectedFutureBenefitsTab,
      warningsOrErrors,
      identifierCriteria,
      policyTagsMedicalAndCIConfig
    } = this.props;
    const {
      params: { encodedProductId }
    } = match;

    this.getReprojectionFromEncodeId(encodedProductId, reprojectionsByEncodeId);

    const { policyOverview = {} } = this.reprojectionObj;

    const {
      encodePolicyNumber = null,
      productCategory = null
    } = policyOverview;
    const hasNoErrors = _.isEmpty(warningsOrErrors);

    if (hasNoErrors && _.isEmpty(this.reprojectionObj)) {
      return null;
    }
    /**
     *  Fetch the configuratorIndentifier
     */
    const configuratorIdentifier = getConfiguratorIdentifier(
      this.reprojectionObj,
      identifierCriteria
    );

    /**
     *  tabList: Contains the list of tabs to be displayed for reprojection.
     */
    const reprojectionTabListByCategory =
      reprojectionTabList[configuratorIdentifier] ||
      reprojectionTabList.DEFAULT;
    this.tabList = filterTabList(
      reprojectionTabListByCategory,
      this.reprojectionObj
    );
    if (hasNoErrors) {
      if (this.tabList && _.isEmpty(tabValues)) {
        const { chartFields } = reprojectionChartConfig;
        const checboxStateForTabs = getInitialCheckboxState(
          this.tabList,
          chartFields
        );
        setIncludeNonGuaranteedPayoutHandler(checboxStateForTabs);
      }
      if (_.isEmpty(tabValues)) {
        return null;
      }
    }

    return (
      <>
        <ErrorBoundary>
          <FullWidthSectionContainer>
            <ReprojectionNotification
              encodePolicyNumber={encodePolicyNumber || encodedProductId}
              reprojectionTealium={{}}
            />
            <ReprojectionsException warningsOrErrors={warningsOrErrors} />
            <ReprojectionSectionBuilder
              productCategory={productCategory}
              configuratorIdentifier={configuratorIdentifier}
              reprojectionsDetails={this.reprojectionObj}
              selectedLocale={selectedLocale}
              langObj={langObj}
              reprojectionGenericConfig={reprojectionGenericConfig}
              reprojectionChartConfig={reprojectionChartConfig}
              sectionNotificationConfig={sectionNotificationConfig}
              setCustomerAge={setCustomerAge}
              customerAge={customerAge}
              customerMinPayAge={customerMinPayAge}
              customerMaxPayAge={customerMaxPayAge}
              setCustomerMinPayAge={setCustomerMinPayAge}
              setCustomerMaxPayAge={setCustomerMaxPayAge}
              toggleIncludeNonGuaranteedPayout={
                toggleIncludeNonGuaranteedPayoutHandler
              }
              tabList={this.tabList}
              tabValues={tabValues}
              disclaimerConfig={disclaimerConfig}
              selectedFutureBenefitsTab={selectedFutureBenefitsTab}
              setReprojectionsSelectTabHandler={
                setReprojectionsSelectTabHandler
              }
              warningsOrErrors={warningsOrErrors}
              isFor="reprojections"
              policyTagsMedicalAndCIConfig={policyTagsMedicalAndCIConfig}
            />
          </FullWidthSectionContainer>
        </ErrorBoundary>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    configurations: {
      genericConfig: { servicingOptions, reprojection },
      reprojectionConfig: { identifierCriteria },
      reprojectionChartConfig,
      sectionNotificationConfig,
      disclaimerConfig,
      reprojectionTabList,
      policyTagsMedicalAndCIConfig
    },
    app: { isLoading },
    reprojection: {
      byEncodeId,
      ageSelection,
      minAge,
      maxAge,
      tabValues,
      selectedTab,
      reprojectionsWarningsOrErrors
    },
    content: { selectedLocale, langObj }
  } = state;
  return {
    reprojectionsByEncodeId: byEncodeId,
    customerAge: ageSelection,
    selectedFutureBenefitsTab: selectedTab,
    selectedLocale,
    langObj,
    isLoading,
    servicingOptionsConfig: servicingOptions,
    reprojectionGenericConfig: reprojection,
    identifierCriteria,
    reprojectionChartConfig,
    sectionNotificationConfig,
    customerMinPayAge: minAge,
    customerMaxPayAge: maxAge,
    tabValues,
    disclaimerConfig,
    reprojectionTabList,
    warningsOrErrors: reprojectionsWarningsOrErrors,
    policyTagsMedicalAndCIConfig
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateIdleState: updateIdleFlag,
      fetchReprojection: policyEncodeID => getReprojectionsInfo(policyEncodeID),
      startLoaderHandler: startLoader,
      endLoaderHandler: endLoader,
      updateSAASPageFailHandler: updateSAASPageFail,
      setCustomerAge: value => setReprojectionsAgeSelection(value),
      setCustomerMinPayAge: value => setReprojectionsMinAge(value),
      setCustomerMaxPayAge: value => setReprojectionsMaxAge(value),
      setIncludeNonGuaranteedPayoutHandler: checboxStateForTabs =>
        setIncludeNonGuaranteedPayout(checboxStateForTabs),
      toggleIncludeNonGuaranteedPayoutHandler: value =>
        toggleIncludeNonGuaranteedPayout(value),
      setReprojectionsSelectTabHandler: selectedTab =>
        setReprojectionsSelectedTab(selectedTab),
      setReprojectionsInfoHandler: updatedByEncodeId =>
        setReprojectionsInfo(updatedByEncodeId),
      resetReprojectionsError: resetReprojectionsErrorsWarnings
    },
    dispatch
  );

ReprojectionPage.propTypes = {
  reprojectionsByEncodeId: PropTypes.object.isRequired,
  customerAge: PropTypes.number.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchReprojection: PropTypes.func.isRequired,
  updateIdleState: PropTypes.func.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  startLoaderHandler: PropTypes.func.isRequired,
  endLoaderHandler: PropTypes.func.isRequired,
  updateSAASPageFailHandler: PropTypes.func.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  toggleIncludeNonGuaranteedPayoutHandler: PropTypes.func.isRequired,
  setIncludeNonGuaranteedPayoutHandler: PropTypes.func.isRequired,
  tabValues: PropTypes.object.isRequired,
  disclaimerConfig: PropTypes.object.isRequired,
  setReprojectionsSelectTabHandler: PropTypes.func.isRequired,
  setReprojectionsInfoHandler: PropTypes.func.isRequired,
  reprojectionTabList: PropTypes.object.isRequired,
  warningsOrErrors: PropTypes.object.isRequired,
  resetReprojectionsError: PropTypes.func.isRequired,
  identifierCriteria: PropTypes.func.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired
};
export default connect(mapStateToProps, mapDispatchToProps)(ReprojectionPage);
