import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import ModalPopUp from '../common/modal/PopUpTemplate';
import {
  retrievePolicyDocument,
  eWelcomeResetDocumentsErrorsWarnings,
  startLoaderEWelcome
} from '../app/appActions';
import { ErrorDescription } from '../policydetail/templates/Benefits/IDC/idcSyles';
import getContentInSpecificLang from '../utils/localeUtils';
import { trackViews } from '../utils/tealiumUtils';

class DownloadErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  componentDidUpdate() {
    const { documentsWarningsOrErrors } = this.props;
    const { isModalOpen } = this.state;
    if (
      !_.isEmpty(documentsWarningsOrErrors) &&
      !isModalOpen &&
      this.checkModalToOpen()
    ) {
      this.setModal();
      trackViews({
        viewName: 'onClickDownloadDocumentWarningsOrErrors',
        event_content: 'sorry, please try again'
      });
    }
  }

  setModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  onCancelClick = () => {
    const { resetDocumentsErrorsWarnings, onCancelDownloadError } = this.props;
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen
    }));
    onCancelDownloadError && onCancelDownloadError();
    resetDocumentsErrorsWarnings();
  };

  isDocumentError = () => {
    const { documentsWarningsOrErrors = {}, selectedDocId } = this.props;
    if (selectedDocId in documentsWarningsOrErrors) {
      const { errors = null } = documentsWarningsOrErrors[selectedDocId];
      return !!errors;
    }
    return false;
  };

  checkModalToOpen = () => {
    const { selectedDocId } = this.props;
    return this.isDocumentError() && selectedDocId;
  };

  render() {
    const { selectedLocale, langObj, selectedDocId } = this.props;
    const { isModalOpen } = this.state;
    return (
      <>
        {this.isDocumentError() && selectedDocId && (
          <ModalPopUp
            isModalOpen={isModalOpen}
            onClose={() => this.onCancelClick('x')}
            modalHeading={getContentInSpecificLang(
              `modalErrorMessage`,
              selectedLocale,
              langObj
            )}
            // footerButtonList={footerButtonList}
            // btnHandler={{
            //   cancel_btn: this.onCancelClick
            // }}
            maxWidth="32em"
            hideHorizonatalLine
          >
            <ErrorDescription>
              {getContentInSpecificLang(
                `modalErrorDescription`,
                selectedLocale,
                langObj
              )}
            </ErrorDescription>
          </ModalPopUp>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      retrievePolicyDocument: (documentID, apiCallCount) =>
        retrievePolicyDocument(documentID, apiCallCount),
      resetDocumentsErrorsWarnings: () =>
        eWelcomeResetDocumentsErrorsWarnings(),
      startLoaderHandler: startLoaderEWelcome
    },
    dispatch
  );

const mapStateToProps = state => {
  const {
    eWelcomePolicyDocuments: { documentsWarningsOrErrors },
    content: { selectedLocale, langObj }
  } = state;
  return {
    documentsWarningsOrErrors,
    selectedLocale,
    langObj
  };
};

DownloadErrorModal.propTypes = {
  documentsWarningsOrErrors: PropTypes.object.isRequired,
  resetDocumentsErrorsWarnings: PropTypes.func.isRequired,
  onCancelDownloadError: PropTypes.func,
  selectedDocId: PropTypes.string.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired
};

DownloadErrorModal.defaultProps = {
  onCancelDownloadError: () => null
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadErrorModal);
