import styled from 'styled-components';
import { rem } from 'polished';
import theme from '../../../../common/styles/theme';
import media from '../../../../utils/media';
import { FontFamily, Fonts } from '../../../../utils/font';
import { ParagraphDiv } from '../../../../common/styles/modalStyles';

export const DocumentSection = styled.div`
  ${media.phone`
    padding-bottom: 15px;
  `}
`;
export const DownloadLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  ${Fonts.t14}
  ${FontFamily.RegularFontW01}
`;

export const DownloadLink = styled.a`
  color: ${theme.fontColor};
  margin-left: ${rem('10px')};
  line-height: ${rem('20px')}
  text-decoration: none;
  cursor: pointer;
  &:focus, &:hover {
    outline: 1px dotted black;
  }
`;

export const DownloadImgSpan = styled.span`
  display: block;
  width: ${rem('18px')};
  height: ${rem('18px')};
`;

export const DocumentDisclaimerWrapper = styled.div`
  margin-top: ${rem('12px')};
  ${Fonts.t14};
  color: ${theme.fontColor};
  line-height: ${rem('20px')};
`;

export const DisclaimerBody = styled.span``;

export const ErrorDescription = styled(ParagraphDiv)`
  font-size: 16px;
  line-height: 24px;
`;
