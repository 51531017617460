import _ from 'lodash';
import tealiumMapping from '../tealium/tealiumMapping';
import LocaleManager from './localeManager';
import {
  getPlanCodeBasedProductCategory,
  getReprojectionPageDetails,
  getReprojectionProductDetails,
  getReprojectionsTooltipContent
} from '../reprojection/utils/reprojectionTealiumHandlers';
import getDescription from './reducerUtils';

const tealiumTagLanguage = {
  en_HK: 'en',
  zh_CN: 'zh_cn',
  zh_HK: 'zh_hk'
};

let paymentModeFormField;
let productCategories;
let productSubCategories;
let totalPoliciesCount;
let productID;
let tealiumFormFieldMappingData;
let productDetails;
let pageDetails;
let captureEventObject;
let reprojectionPageDetails = {};
let reprojectionProductDetails = {};
let instructionHistoryProductDetails = '';
let pageLanguage =
  tealiumTagLanguage[LocaleManager.getLocaleForHSBCWebApps()] || 'en';
const productFamilyForReprojections = 'insurance';
let returnProductCategory = '';
let returnProductSubCategory = '';
let savePolicyTagsMedicalAndCI = {};
let tagForMedical = false;
let tagForCriticalIllness = false;

const formFieldsKeys = {
  productCategoryLabel: 'insurance',
  totalPoliciesCountLabel: 'total policies count',
  policyStatusLabel: 'policy status',
  paymentModeLabel: 'payment mode',
  linkNameLabel: 'link name'
};

export const prodIdToMessageMappings = [
  'message1',
  'message2',
  'message3',
  'message4'
];
/* function which will return payment mode */
export function getPaymentMode(paymentMode) {
  if (paymentMode) {
    paymentModeFormField = `${
      formFieldsKeys.paymentModeLabel
    }:${paymentMode.toLowerCase()}`;
  }
}

/* function which will return product category */
export function getProductCategory(productFamilyOrder) {
  if (productFamilyOrder) {
    productCategories = {
      product_category: productFamilyOrder
    };
  }
}

/* function which will return product subcategory for summary page */
export function getProductSubCategory(subProductCategories, totalPolicyCount) {
  totalPoliciesCount = {
    form_field_1: `${formFieldsKeys.totalPoliciesCountLabel}:${totalPolicyCount}`
  };
  if (subProductCategories) {
    productSubCategories = {
      product_subcategory: _.toString(subProductCategories)
    };
  }
}

/**
 * function getProductCategoryInLowerCase(productFamily)
 * @param {*} productFamily - Savings & Investment, Travel Insurance etc.
 * @description return product family in lower case if 'insurance' word is not present in product family -
 * ex. Travel Insurance returns travel insurance, else
 * ex. Savings & Investment returns savings & investment insurance
 */
export function getProductDetails(
  productFamily,
  productSubCategory,
  planName,
  planCode,
  policyTagsMedicalAndCIConfig
) {
  if (!_.isEmpty(policyTagsMedicalAndCIConfig)) {
    savePolicyTagsMedicalAndCI = policyTagsMedicalAndCIConfig;
    const {
      policyTagsMedicalAndCI: { planCodesUnderMedical, planCodesUnderCI }
    } = policyTagsMedicalAndCIConfig;
    tagForMedical = _.includes(planCodesUnderMedical, planCode);
    tagForCriticalIllness = _.includes(planCodesUnderCI, planCode);
  }
  if (
    _.includes(_.toLower(productFamily), formFieldsKeys.productCategoryLabel)
  ) {
    returnProductCategory = _.toLower(productFamily);
  } else {
    returnProductCategory = `${_.toLower(productFamily)} ${
      formFieldsKeys.productCategoryLabel
    }`;
  }
  if (productFamily === 'Travel Insurance') {
    returnProductSubCategory = '';
  } else {
    returnProductSubCategory = getPlanCodeBasedProductCategory(
      productSubCategory,
      tagForMedical,
      tagForCriticalIllness
    );
  }
  productDetails = {
    product_category: returnProductCategory,
    product_subcategory: returnProductSubCategory,
    product_id: _.toLower(planName)
  };
}

export function getToolTipContent(
  sectionName,
  eventContent,
  isReprojectionsTooltip
) {
  if (isReprojectionsTooltip) {
    return getReprojectionsTooltipContent(eventContent);
  }
  return `${sectionName.toLowerCase()}:${eventContent.toLowerCase()}`;
}

// This function will usefull for common tooltip and link events
export function createEventActionContent(
  action,
  eventContent,
  section,
  isReprojectionsTooltip
) {
  if (!eventContent || !section) {
    // console.log('err return null');
    return null;
  }
  return {
    eventAction: action,
    eventContent: getToolTipContent(
      section,
      eventContent,
      isReprojectionsTooltip
    ),
    eventTooltip: isReprojectionsTooltip
  };
}

/* function which will return planName which is used as productID */
export function getPlanName(planName) {
  if (planName) {
    productID = `${planName}`;
  }
}

/*
 *
 */
/**
 * function getFormField1(formFields)
 * @param {*} formFields -  Array: [ message1, message2 ]
 * @descriptionFunction to generate the form fields based on message params and the language selected used in highlights banner section. -
 * ex. {message1,message2} => PWS_HK_EN_ALL_BUS_INS_LIF_20829_0,PWS_HK_EN_ALL_BUS_INS_LCI_20830_0
 */
export const getFormField1 = function getFormField1(
  formFields,
  formField1MsgStack
) {
  const selectedLocale = pageLanguage || 'en';
  const mappedFormField1 = _.map(formFields, function getMappedFormFields(
    formField
  ) {
    return formField1MsgStack[formField][selectedLocale];
  });
  return _.join(mappedFormField1, ',');
};

const {
  commonTags,
  commonDashboardTags,
  summaryPage,
  summaryPageWithNotificationMsg,
  captureAccordian,
  pageLevelErrorMsg,
  logOff,
  dropDown,
  showAll,
  viewDetails,
  detailsPage,
  instructionPage,
  myInsurance,
  contactUs,
  detailsTooltip,
  updatePaymentInstruction,
  updateContactInformation,
  makeClaim,
  quickLinks,
  sectionLinks,
  popup,
  call,
  highlights,
  highlightsProductLink,
  redirectPopup,
  popupButtons,
  commonReprojectionPageTags,
  includeNonGuranteedCheckBox,
  commonButtonEvents,
  simplyBook,
  megaMenuLinks,
  downloadDocument,
  downloadDocumentPopup,
  downloadDocumentError,
  downloadDocumentCTA,
  eWelcomeModalLearnMore,
  eWelcomeDownloadDocument,
  downloadAll,
  gotIt,
  onboardingDocument,
  eWelcomeErrorMsg,
  eWelcomeModalView,
  eWelcomeViewDetails,
  eecModalPageDetails,
  fundRPQNotification,
  transactionModalEvent,
  assignee,
  vl1,
  pendingTransaction,
  cancelInstructionPopup,
  commonTagsBeneficiary,
  bendetPolicyDetails,
  bendetPolicyDetailsFunnel,
  bendetSorryCaseIrrevocableModalDetails,
  bendetSorryCaseIrrevocableModal,
  bendetSorryCaseIrrevocableModalFunnel,
  bendetSorryCaseAssigneeModalDetails,
  bendetSorryCaseAssigneeModalFunnel,
  bendetSorryCaseAssigneeModal,
  eStatementLinkClk,
  eStatemetView,
  eStatemetConfirmationView,
  eStatemetCheckbox,
  eStatemetBackBtn,
  eStatementTnCLinkClk,
  eStatementCommonLinkClk
} = tealiumMapping;

const tagsForBothJourneys = {
  commonPageTags: commonTags,
  tagsForDashboardJourney: commonDashboardTags,
  tagsForReprojectionJourney: commonReprojectionPageTags
};

const {
  commonPageTags,
  tagsForDashboardJourney,
  tagsForReprojectionJourney
} = tagsForBothJourneys;

export const preparePageFormFields = function preparePageFormFields(
  viewName,
  pageTealiumDetails,
  documentDownLoadFormField
) {
  if (
    (viewName === 'onDetailsPageLoad' ||
      viewName === 'eWelcomePolicyDocumentsErrorMsg') &&
    (pageTealiumDetails !== null || documentDownLoadFormField)
  ) {
    const {
      policyOverview: {
        productFamily,
        productCategory,
        policyStatus,
        sumInsured,
        planCode
      }
    } = pageTealiumDetails;
    getProductDetails(
      productFamily,
      productCategory,
      productID,
      planCode,
      savePolicyTagsMedicalAndCI
    );
    if (policyStatus && sumInsured) {
      tealiumFormFieldMappingData = {
        form_field_2: `${
          formFieldsKeys.policyStatusLabel
        }:${policyStatus.toLowerCase()}`,
        form_field_3: paymentModeFormField,
        amount: sumInsured.amount,
        currency_code: sumInsured.currency
      };
    } else if (policyStatus) {
      tealiumFormFieldMappingData = {
        form_field_2: `${
          formFieldsKeys.policyStatusLabel
        }:${policyStatus.toLowerCase()}`,
        form_field_3: paymentModeFormField
      };
    }
    if (documentDownLoadFormField) {
      tealiumFormFieldMappingData = {
        ...tealiumFormFieldMappingData,
        form_field_1: documentDownLoadFormField
      };
    }
  } else if (
    (viewName === 'onReprojectionsPageLoad' ||
      viewName === 'onFutureBenefitsTabChanged') &&
    pageTealiumDetails &&
    pageTealiumDetails.policyOverview
  ) {
    const {
      policyOverview: { productCategory, planName, planCode }
    } = pageTealiumDetails;
    reprojectionProductDetails = getReprojectionProductDetails(
      productFamilyForReprojections,
      productCategory,
      planName,
      planCode
    );
  }
};

export const getEventDetails = function getEventDetails(
  event,
  captureCommonTags,
  capturepageDetails,
  captureProductDetails,
  tealiumEventMapping,
  captureFormFieldMappingData,
  capturePageLanguage
) {
  const { eventAction, eventContent, eventTooltip } = event;
  const {
    captureCommonPageTags,
    captureTagsForDashboardJourney,
    captureTagsForReprojectionJourney
  } = captureCommonTags;

  if (eventTooltip) {
    captureEventObject = {
      ...captureCommonPageTags,
      ...captureTagsForReprojectionJourney,
      ...reprojectionPageDetails,
      ...reprojectionProductDetails,
      ...tealiumEventMapping,
      event_content: eventContent,
      event_action: eventAction,
      page_language: capturePageLanguage
    };
    return captureEventObject;
  }
  captureEventObject = {
    ...commonPageTags,
    ...captureTagsForDashboardJourney,
    ...capturepageDetails,
    ...captureProductDetails,
    ...tealiumEventMapping,
    ...captureFormFieldMappingData,
    event_content: eventContent,
    event_action: eventAction,
    page_language: capturePageLanguage
  };
  return captureEventObject;
};

const getPageDetails = isRPQModalOpen => {
  if (isRPQModalOpen) {
    return fundRPQNotification;
  }
  return detailsPage;
};

const getTransactionModalPageDetails = reasonServiceUnavailable => {
  const matchReason = {
    hasAssigneeRole: assignee,
    hasPlanCodeVL1: vl1,
    pendingTransactions: pendingTransaction
  };
  return matchReason[reasonServiceUnavailable];
};

const getTransactionModalEventContent = reasonServiceUnavailable => {
  const matchReason = {
    hasAssigneeRole:
      'according to our records, your policy is assigned to an assignee',
    hasPlanCodeVL1:
      'according to our records, your policy is assigned to an assignee',
    pendingTransactions:
      "you've already placed an instruction of switching investment choices, which is pending to be processed"
  };
  return {
    event_content: matchReason[reasonServiceUnavailable]
  };
};

const getInstructionFormFields = (requestType, formFieldSet) => {
  return `${requestType}:${_.toString(formFieldSet)}`;
};

const getBeneficiaryLange = langType => {
  if (langType === 'en' || langType === 'zh_cn' || langType === 'zh_hk') {
    const tealiumTagLanguageBene = {
      en: 'en_hk',
      zh_cn: 'zh_cn',
      zh_hk: 'zh_hk'
    };
    return tealiumTagLanguageBene[langType];
  }
  return langType;
};

export const trackViews = function trackViews(view) {
  if (window.TMS && window.TMS.trackView) {
    const {
      viewName,
      pageTealiumDetails = null,
      selectedTab = '',
      documentDownLoadFormField = '',
      reasonServiceUnavailable = null
    } = view;
    reprojectionPageDetails =
      selectedTab && getReprojectionPageDetails(selectedTab);
    pageLanguage =
      tealiumTagLanguage[LocaleManager.getLocaleForHSBCWebApps()] || 'en';
    switch (viewName) {
      case 'onSummaryPageLoad': {
        const onSummaryPageLoadObject = {
          ...summaryPage,
          ...productCategories,
          ...productSubCategories,
          ...totalPoliciesCount,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          page_language: pageLanguage
        };
        window.TMS.trackView(onSummaryPageLoadObject);
        break;
      }
      case 'onSummaryPageLoadNotificationMsg': {
        const notificationMsg = {
          event_content: view.message,
          event_subcategory: view.messageType
        };
        const onSummaryPageLoadNotificationMsgObject = {
          ...summaryPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productCategories,
          ...productSubCategories,
          ...summaryPageWithNotificationMsg,
          ...notificationMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(onSummaryPageLoadNotificationMsgObject);
        break;
      }
      case 'onSummaryPageLoadErrorMsg': {
        const summaryPageError = view.errorDetails[0];
        const summaryPageErrorMsg = {
          event_content: summaryPageError.warningMessage,
          event_subcategory: summaryPageError.warningCode
        };
        const onSummaryPageLoadErrorMsgObject = {
          ...summaryPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...pageLevelErrorMsg,
          ...summaryPageErrorMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(onSummaryPageLoadErrorMsgObject);
        break;
      }
      case 'onTransactionModalLoad': {
        const onTransactionModalLoadObject = {
          ...getTransactionModalPageDetails(reasonServiceUnavailable),
          ...getTransactionModalEventContent(reasonServiceUnavailable),
          ...transactionModalEvent,
          ...productDetails,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          page_language: pageLanguage
        };
        window.TMS.trackView(onTransactionModalLoadObject);
        break;
      }
      case 'onHighlightsBannersLoad': {
        const highlightBanners = {
          event_content: view.eventContent,
          form_field_1: view.formField1,
          product_category: view.productCategory,
          product_subcategory: view.productSubcategory
        };
        const onHighlightsBannersLoadObject = {
          ...summaryPage,
          ...totalPoliciesCount,
          ...highlightBanners,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...highlights,
          page_language: pageLanguage
        };
        window.TMS.trackView(onHighlightsBannersLoadObject);
        break;
      }
      case 'onInstructionPageLoad': {
        const formFieldData = {
          form_field_1: getInstructionFormFields(
            'pending request',
            view.formField1
          ),
          form_field_2: getInstructionFormFields(
            'request history',
            view.formField2
          )
        };

        instructionHistoryProductDetails = {
          product_category: returnProductCategory,
          product_subcategory: returnProductSubCategory,
          product_id: _.toLower(getDescription('en', view.planName))
        };
        const onInstructionPageLoadObject = {
          ...instructionPage,
          ...instructionHistoryProductDetails,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...formFieldData,
          page_language: pageLanguage,
          customer_group: view.customerGroup,
          raw_datalayer: '5777v14'
        };
        window.TMS.trackView(onInstructionPageLoadObject);
        break;
      }
      case 'simplyBook': {
        const captureSimplyBookView = {
          ...commonPageTags,
          ...commonDashboardTags,
          ...simplyBook,
          ...productCategories,
          ...productSubCategories,
          page_language: pageLanguage
        };
        window.TMS.trackView(captureSimplyBookView);
        break;
      }
      case 'onDetailsPageLoad': {
        pageTealiumDetails &&
          preparePageFormFields(
            viewName,
            pageTealiumDetails,
            documentDownLoadFormField
          );
        const onDetailsPageLoadObject = {
          ...detailsPage,
          ...productDetails,
          ...tealiumFormFieldMappingData,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          page_language: pageLanguage
        };
        window.TMS.trackView(onDetailsPageLoadObject);
        break;
      }
      case 'onReprojectionsPageLoad': {
        preparePageFormFields(viewName, pageTealiumDetails);
        const onReprojectionsPageLoadObj = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...reprojectionProductDetails,
          ...reprojectionPageDetails,
          page_language: pageLanguage
        };
        window.TMS.trackView(onReprojectionsPageLoadObj);
        break;
      }
      case 'onFutureBenefitsTabChanged': {
        preparePageFormFields(viewName, pageTealiumDetails);
        const onFutureBenefitsTabChangedObj = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...reprojectionProductDetails,
          ...reprojectionPageDetails,
          page_language: pageLanguage
        };
        window.TMS.trackView(onFutureBenefitsTabChangedObj);
        break;
      }
      case 'onReprojectionsPageWarningsOrErrors': {
        const { message, messageType, messageAction } = view;
        const reprojectionPageErrorMsg = {
          event_content: message,
          event_subcategory: messageType,
          event_action: messageAction
        };
        const onReprojectionsWarningsOrErrorsObject = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...reprojectionProductDetails,
          ...reprojectionPageDetails,
          ...pageLevelErrorMsg,
          ...reprojectionPageErrorMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(onReprojectionsWarningsOrErrorsObject);
        break;
      }
      case 'onDetailsPageLoadExceptionMsg': {
        const detailPageExceptionMsg = {
          event_content: view.message,
          event_subcategory: view.messageType
        };
        const onDetailsPageLoadExceptionMsgObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...summaryPageWithNotificationMsg,
          ...detailPageExceptionMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(onDetailsPageLoadExceptionMsgObject);
        break;
      }
      case 'onDetailsPageLoadErrorMsg': {
        const detailPageErrorMsg = {
          event_content: view.message,
          event_subcategory: view.messageType,
          event_action: view.messageAction
        };
        const onDetailsPageLoadErrorMsgObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...pageLevelErrorMsg,
          ...detailPageErrorMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(onDetailsPageLoadErrorMsgObject);
        break;
      }
      case 'downloadDocumentError': {
        const { errorCode } = view;
        let downloadDocumentErrorSubCategory = null;
        if (errorCode) {
          downloadDocumentErrorSubCategory = {
            event_subcategory: errorCode
          };
        }
        const downloadDocumentErrorObj = {
          ...commonTags,
          ...commonDashboardTags,
          ...downloadDocumentPopup,
          ...downloadDocumentError,
          ...downloadDocumentErrorSubCategory,
          ...productDetails,
          page_language: pageLanguage
        };
        window.TMS.trackView(downloadDocumentErrorObj);
        break;
      }
      case 'onClickDownloadDocumentWarningsOrErrors': {
        const documentDownloadPageErrorMsg = {
          event_content: view.event_content,
          event_category: view.event_category,
          event_subcategory: view.event_subcategory,
          event_action: view.event_action,
          raw_datalayer: '5996v10'
        };
        const onClickDownloadDocumentWarningsOrErrorsObj = {
          ...tagsForDashboardJourney,
          ...productDetails,
          ...detailsPage,
          ...documentDownloadPageErrorMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(onClickDownloadDocumentWarningsOrErrorsObj);
        break;
      }
      case 'eWelcomePolicyDocumentsErrorMsg': {
        pageTealiumDetails &&
          preparePageFormFields(
            viewName,
            pageTealiumDetails,
            documentDownLoadFormField
          );
        const eWelcomePolicyErrorContent = {
          raw_datalayer: '5996v12'
        };
        const eWelcomePolicyDocumentsErrorMsgObj = {
          ...tagsForDashboardJourney,
          ...productDetails,
          ...detailsPage,
          ...tealiumFormFieldMappingData,
          ...eWelcomeErrorMsg,
          ...eWelcomePolicyErrorContent,
          page_language: pageLanguage
        };
        window.TMS.trackView(eWelcomePolicyDocumentsErrorMsgObj);
        break;
      }
      case 'eWelcomeModalView': {
        const eWelcomeModalContent = {
          raw_datalayer: '5996v3'
        };
        const eWelcomeModalViewObj = {
          ...tagsForDashboardJourney,
          ...eWelcomeModalView,
          ...productDetails,
          ...eWelcomeModalContent,
          page_language: pageLanguage
        };
        window.TMS.trackView(eWelcomeModalViewObj);
        break;
      }
      case 'onEECModalLoad': {
        const modalHeaderMsg = {
          event_content: view.message
        };
        const OnEECModalLoad = {
          ...eecModalPageDetails,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...modalHeaderMsg,
          page_language: pageLanguage
        };
        window.TMS.trackView(OnEECModalLoad);
        break;
      }
      case 'earlyEncashmentDescription': {
        const eecCardText = {
          form_field_5: view.formField5
        };
        const checkEECDescription = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...eecCardText,
          page_language: pageLanguage
        };
        window.TMS.trackView(checkEECDescription);
        break;
      }
      case 'onRPQNotificationPopupLoad': {
        const onRPQNotificationPopupLoadObject = {
          ...fundRPQNotification,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          page_language: pageLanguage
        };
        window.TMS.trackView(onRPQNotificationPopupLoadObject);
        break;
      }
      case 'onCancelInstructionPopupLoad': {
        const onCancelInstructionPopupLoadObj = {
          customer_group: view.customerSegment
        };
        const onCancelInstructionPopupLoad = {
          ...cancelInstructionPopup,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...instructionHistoryProductDetails,
          ...onCancelInstructionPopupLoadObj,
          page_language: pageLanguage,
          raw_datalayer: '5777v16'
        };
        window.TMS.trackView(onCancelInstructionPopupLoad);
        break;
      }
      // --------------------------Change Beneficiary tagging-------------------------------//
      case 'policyDetailsOnLoad': {
        bendetPolicyDetailsFunnel.form_field_2 = `number of beneficiaries:${_.toString(
          view.formField2
        )}`;
        const policyDetailsOnLoad = {
          ...commonTagsBeneficiary,
          ...bendetPolicyDetails,
          ...bendetPolicyDetailsFunnel,
          page_language: getBeneficiaryLange(pageLanguage)
        };
        window.TMS.trackView(policyDetailsOnLoad);
        break;
      }
      case 'irrevocableModalOnLoad': {
        const irrevocableModalOnLoad = {
          ...commonTagsBeneficiary,
          ...bendetSorryCaseIrrevocableModal,
          ...bendetSorryCaseIrrevocableModalDetails,
          ...bendetSorryCaseIrrevocableModalFunnel,
          page_language: getBeneficiaryLange(pageLanguage)
        };
        window.TMS.trackView(irrevocableModalOnLoad);
        break;
      }
      case 'assigneeModalOnLoad': {
        const assigneeModalOnLoad = {
          ...commonTagsBeneficiary,
          ...bendetSorryCaseAssigneeModal,
          ...bendetSorryCaseAssigneeModalDetails,
          ...bendetSorryCaseAssigneeModalFunnel,
          page_language: getBeneficiaryLange(pageLanguage)
        };
        window.TMS.trackView(assigneeModalOnLoad);
        break;
      }
      case 'onEstmtPreferenceLoad': {
        const { customerGroup } = view;
        const onEstmtPreferenceLoad = {
          ...commonTags,
          ...eStatemetView,
          ...productDetails,
          ...commonDashboardTags,
          ...customerGroup,
          page_language: pageLanguage
        };
        window.TMS.trackView(onEstmtPreferenceLoad);
        break;
      }
      case 'eStatemetConfirmationView': {
        const { customerGroup } = view;
        const eStatemetConfirmationViewObj = {
          ...commonTags,
          ...eStatemetConfirmationView,
          ...productDetails,
          ...commonDashboardTags,
          ...customerGroup,
          page_language: pageLanguage
        };
        window.TMS.trackView(eStatemetConfirmationViewObj);
        break;
      }
      // --------------------------Change Beneficiary tagging-------------------------------//
      default:
        break;
    }
  }
};

export const trackEvents = function trackEvents(event) {
  if (window.TMS && window.TMS.trackEvent) {
    pageLanguage =
      tealiumTagLanguage[LocaleManager.getLocaleForHSBCWebApps()] || 'en';
    switch (event.eventName) {
      case 'captureAccordian': {
        const captureAccordianMappingObj = {
          event_content: event.eventContent
        };
        const captureAccordianObject = {
          ...summaryPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productCategories,
          ...productSubCategories,
          ...captureAccordian,
          ...captureAccordianMappingObj,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(captureAccordianObject);
        break;
      }
      case 'logOff': {
        const logOffObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...logOff,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(logOffObject);
        break;
      }
      case 'dropdown': {
        const dropdownData = {
          event_content: pageLanguage
        };
        const dropdownObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...dropDown,
          ...dropdownData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(dropdownObject);
        break;
      }
      case 'showAll': {
        const showAllObject = {
          ...summaryPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productCategories,
          ...productSubCategories,
          ...showAll,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(showAllObject);
        break;
      }
      case 'viewDetails': {
        const viewDetailsObject = {
          ...summaryPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productCategories,
          ...productSubCategories,
          ...viewDetails,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(viewDetailsObject);
        break;
      }
      case 'myInsurance': {
        const myInsuranceObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...myInsurance,
          ...tealiumFormFieldMappingData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(myInsuranceObject);
        break;
      }
      case 'contactUs': {
        const contactUsObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...contactUs,
          ...productDetails,
          ...tealiumFormFieldMappingData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(contactUsObject);
        break;
      }
      case 'contactUsFooter': {
        getEventDetails(
          event.eventData,
          tagsForBothJourneys,
          pageDetails,
          productDetails,
          contactUs,
          tealiumFormFieldMappingData,
          pageLanguage
        );
        window.TMS.trackEvent(captureEventObject);
        break;
      }
      case 'detailsTooltip': {
        getEventDetails(
          event.tooltipData,
          tagsForBothJourneys,
          detailsPage,
          productDetails,
          detailsTooltip,
          tealiumFormFieldMappingData,
          pageLanguage
        );
        window.TMS.trackEvent(captureEventObject);
        break;
      }
      case 'updatePaymentInstruction': {
        getEventDetails(
          event.eventData,
          tagsForBothJourneys,
          detailsPage,
          productDetails,
          updatePaymentInstruction,
          tealiumFormFieldMappingData,
          pageLanguage
        );
        window.TMS.trackEvent(captureEventObject);
        break;
      }
      case 'updateContactInformation': {
        getEventDetails(
          event.eventData,
          tagsForBothJourneys,
          detailsPage,
          productDetails,
          updateContactInformation,
          tealiumFormFieldMappingData,
          pageLanguage
        );
        window.TMS.trackEvent(captureEventObject);
        break;
      }
      case 'makeClaim': {
        const makeClaimObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...makeClaim,
          ...tealiumFormFieldMappingData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(makeClaimObject);
        break;
      }
      case 'quickLinks': {
        const quickLinksData = {
          event_content: event.eventContent
        };
        const quickLinksObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...quickLinks,
          ...productDetails,
          ...quickLinksData,
          ...tealiumFormFieldMappingData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(quickLinksObject);
        break;
      }
      case 'sectionLinks': {
        getEventDetails(
          event.eventData,
          tagsForBothJourneys,
          detailsPage,
          productDetails,
          sectionLinks,
          tealiumFormFieldMappingData,
          pageLanguage
        );
        window.TMS.trackEvent(captureEventObject);
        break;
      }
      case 'digiServiceLinks': {
        const digiServiceLinksData = {
          event_content: event.eventContent
        };
        const digiServiceLinksObject = {
          ...getPageDetails(event.isRPQModalOpen),
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...commonButtonEvents,
          ...productDetails,
          ...digiServiceLinksData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(digiServiceLinksObject);
        break;
      }
      case 'popup': {
        const { eventContent } = event;
        const popupMappingObj = {
          event_content: eventContent
        };
        const popupMappingObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...popup,
          ...popupMappingObj,
          ...tealiumFormFieldMappingData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(popupMappingObject);
        break;
      }
      case 'call': {
        getEventDetails(
          event.callData,
          tagsForBothJourneys,
          detailsPage,
          productDetails,
          call,
          tealiumFormFieldMappingData,
          pageLanguage
        );
        window.TMS.trackEvent(captureEventObject);
        break;
      }
      case 'onHighlightsProductLink': {
        const highlightProductLinkContent = {
          event_content: event.eventContent,
          form_field_1: event.formField1
        };
        if (event.productCategory && event.productSubcategory) {
          highlightProductLinkContent.product_category = event.productCategory;
          highlightProductLinkContent.product_subcategory =
            event.productSubcategory;
        }
        if (event.eventAction) {
          highlightProductLinkContent.event_action = event.eventAction;
        }
        const onHighlightsProductLinkObject = {
          ...summaryPage,
          ...totalPoliciesCount,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...highlightsProductLink,
          ...highlightProductLinkContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(onHighlightsProductLinkObject);
        break;
      }
      case 'redirectPopup': {
        const { eventContent } = event;
        const popupContentFormField = {
          form_field_1: `${formFieldsKeys.linkNameLabel}:${eventContent}`
        };
        const redirectPopupObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...popupContentFormField,
          ...productDetails,
          ...redirectPopup,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(redirectPopupObject);
        break;
      }
      case 'popupButtonEvent': {
        const { eventContent, linkName } = event;
        const popupButtonEventObj = {
          event_content: eventContent
        };
        const popupContentFormField = {
          form_field_1: `${
            formFieldsKeys.linkNameLabel
          }:${linkName.toLowerCase()}`
        };
        const popupButtonEventObject = {
          ...redirectPopup,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...popupContentFormField,
          ...productDetails,
          ...popupButtons,
          ...popupButtonEventObj,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(popupButtonEventObject);
        break;
      }
      case 'includeNonGuranteedCheckBox': {
        const { eventContent } = event;
        const checkBoxContent = {
          event_content: eventContent
        };
        const includeNonGuranteedCheckBoxObj = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...checkBoxContent,
          ...reprojectionPageDetails,
          ...reprojectionProductDetails,
          ...includeNonGuranteedCheckBox,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(includeNonGuranteedCheckBoxObj);
        break;
      }
      case 'commonButtonEvents': {
        const { eventContent } = event;
        const commonBtnContent = {
          event_content: eventContent
        };
        const commonButtonObj = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...commonBtnContent,
          ...reprojectionPageDetails,
          ...reprojectionProductDetails,
          ...commonButtonEvents,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(commonButtonObj);
        break;
      }
      case 'eecButtonClick': {
        const { eventContent } = event;
        const eecBtnContent = {
          event_content: eventContent
        };
        const eecButtonObj = {
          ...detailsPage,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...commonPageTags,
          ...eecBtnContent,
          ...commonButtonEvents,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(eecButtonObj);
        break;
      }
      case 'captureBenefitsTableAccordion': {
        const { eventContent } = event;
        const captureTableAccordion = {
          event_content: eventContent
        };
        const captureTableAccordionObj = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...captureTableAccordion,
          ...reprojectionPageDetails,
          ...reprojectionProductDetails,
          ...captureAccordian,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(captureTableAccordionObj);
        break;
      }
      case 'captureReprojectionChartsInteraction': {
        const { eventContent, eventSubcategory } = event;
        const captureChartsInteraction = {
          event_content: eventContent,
          event_subcategory: eventSubcategory
        };
        const captureChartsInteractionObj = {
          ...commonPageTags,
          ...tagsForReprojectionJourney,
          ...captureChartsInteraction,
          ...reprojectionPageDetails,
          ...reprojectionProductDetails,
          ...commonButtonEvents,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(captureChartsInteractionObj);
        break;
      }
      case 'megaMenuLinks': {
        const { eventContent } = event;
        const megaMenuLinkContent = {
          event_content: eventContent
        };
        const megaMenuLinkObj = {
          ...commonTags,
          ...commonDashboardTags,
          ...megaMenuLinks,
          ...megaMenuLinkContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(megaMenuLinkObj);
        break;
      }
      case 'downloadDocument': {
        const { eventContent } = event;
        const downloadDocumentContent = {
          event_content: eventContent
        };
        const downloadDocumentObj = {
          ...commonTags,
          ...commonDashboardTags,
          ...downloadDocument,
          ...downloadDocumentContent,
          ...productDetails,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(downloadDocumentObj);
        break;
      }
      case 'downloadDocumentPopupCTA': {
        const { eventContent } = event;
        const downloadDocumentPopupCTAContent = {
          event_content: eventContent
        };
        const downloadDocumentPopupCTA = {
          ...downloadDocumentPopup,
          ...commonPageTags,
          ...commonDashboardTags,
          ...productDetails,
          ...downloadDocumentCTA,
          ...downloadDocumentPopupCTAContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(downloadDocumentPopupCTA);
        break;
      }
      case 'eWelcomeModellearnMore': {
        const { eventAction, eventContent, customerPolicy } = event.eventData;
        const { productFamily, productCategory } = customerPolicy;
        getProductDetails(productFamily, productCategory, productID);
        const learnMoreContent = {
          event_content: eventContent,
          event_action: eventAction,
          raw_datalayer: '5996v2'
        };
        const learnMoreObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...eWelcomeModalLearnMore,
          ...learnMoreContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(learnMoreObject);
        break;
      }
      case 'eWelcomeModelDownloadDocument': {
        const { eventAction, eventContent } = event.eventData;
        const eWelcomeModelDownloadDocumentContent = {
          event_content: eventContent,
          event_action: eventAction,
          raw_datalayer: '5996v4'
        };
        const downloadDocumentObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...eWelcomeModelDownloadDocumentContent,
          ...eWelcomeDownloadDocument,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(downloadDocumentObject);
        break;
      }
      case 'eWelcomeDownloadAll': {
        const { eventContent } = event;
        const eWelcomeDownloadAllContent = {
          event_content: eventContent,
          raw_datalayer: '5996v5'
        };
        const downloadAllObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...eWelcomeDownloadAllContent,
          ...eWelcomeDownloadDocument,
          ...downloadAll,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(downloadAllObject);
        break;
      }
      case 'gotIt': {
        const eWelcomeGotItContent = {
          raw_datalayer: '5996v6'
        };
        const gotItObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...gotIt,
          ...eWelcomeGotItContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(gotItObject);
        break;
      }
      case 'onboardingDocument': {
        const eWelcomeOnboardingContent = {
          raw_datalayer: '5996v9'
        };
        const onboardingDocumentObject = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...onboardingDocument,
          ...detailsPage,
          ...eWelcomeOnboardingContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(onboardingDocumentObject);
        break;
      }
      case 'eWelcomeViewDetails': {
        const { eventAction, eventContent, customerPolicy } = event.eventData;
        const { productFamily, productCategory } = customerPolicy;
        getProductDetails(productFamily, productCategory, productID);
        const eWelcomeViewDetailsContent = {
          event_content: eventContent,
          event_action: eventAction,
          raw_datalayer: '5996v1'
        };
        const eWelcomeViewDetailsObj = {
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...eWelcomeViewDetails,
          ...eWelcomeViewDetailsContent,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(eWelcomeViewDetailsObj);
        break;
      }
      case 'onViewInstructionHistoryLink': {
        const onViewInstructionHistoryLinkObj = {
          event_content: event.eventContent,
          form_field_2: event.formField2,
          customer_group: event.customerGroup
        };
        const onViewInstructionHistoryLinkObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...commonButtonEvents,
          ...onViewInstructionHistoryLinkObj,
          page_language: pageLanguage,
          raw_datalayer: '5777v4'
        };
        window.TMS.trackEvent(onViewInstructionHistoryLinkObject);
        break;
      }
      case 'onViewInstructionInfoNotification': {
        const onViewInstructionInfoNotificationObj = {
          event_content: event.eventContent,
          event_action: event.eventAction,
          customer_group: event.customerGroup
        };
        const onViewInstructionInfoNotificationObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...commonButtonEvents,
          ...onViewInstructionInfoNotificationObj,
          page_language: pageLanguage,
          raw_datalayer: '5777v3'
        };
        window.TMS.trackEvent(onViewInstructionInfoNotificationObject);
        break;
      }
      case 'onInstructionHistoryAccordian': {
        const onInstructionHistoryAccordianObj = {
          event_content: event.eventContent,
          customer_group: event.customerSegment
        };
        const onInstructionHistoryAccordianObjObject = {
          ...instructionPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...instructionHistoryProductDetails,
          ...captureAccordian,
          ...onInstructionHistoryAccordianObj,
          ...productDetails,
          page_language: pageLanguage,
          raw_datalayer: '5777v15'
        };
        window.TMS.trackEvent(onInstructionHistoryAccordianObjObject);
        break;
      }
      case 'onCancelInstructionPopupButton': {
        const onCancelInstructionPopupButtonObj = {
          event_content: event.eventContent,
          customer_group: event.customerSegment,
          raw_datalayer: event.eventContent === 'no' ? '5777v17' : '5777v18'
        };
        const onCancelInstructionPopupButton = {
          ...cancelInstructionPopup,
          ...commonPageTags,
          ...commonButtonEvents,
          ...onCancelInstructionPopupButtonObj,
          ...tagsForDashboardJourney,
          ...instructionHistoryProductDetails,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(onCancelInstructionPopupButton);
        break;
      }
      case 'onEStmtLinkClk': {
        const {
          currentPolicyDetail: {
            policyOverview: { policyCurrency, policyStatus }
          },
          customerGroup
        } = event;
        const onEStmtLinkClk = {
          ...commonPageTags,
          ...eStatementLinkClk,
          ...productDetails,
          ...tagsForDashboardJourney,
          ...customerGroup,
          form_field_3: paymentModeFormField,
          form_field_2: `${
            formFieldsKeys.policyStatusLabel
          }:${policyStatus.toLowerCase()}`,
          currency_code: policyCurrency,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(onEStmtLinkClk);
        break;
      }
      case 'onEStmtCheckboxClk': {
        const { formFieldData, customerGroup } = event;
        const onEStmtCheckboxClk = {
          ...commonPageTags,
          ...eStatemetCheckbox,
          ...productDetails,
          ...tagsForDashboardJourney,
          ...customerGroup,
          form_field_1: formFieldData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(onEStmtCheckboxClk);
        break;
      }
      case 'estmtBackClk': {
        const { formFieldData, customerGroup } = event;
        const onEstmtBackClk = {
          ...commonPageTags,
          ...eStatemetBackBtn,
          ...productDetails,
          ...tagsForDashboardJourney,
          ...customerGroup,
          form_field_1: formFieldData,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(onEstmtBackClk);
        break;
      }
      case 'eStatementTnCLinkClk': {
        const { customerGroup } = event;
        const eStatementTnCLinkClkObj = {
          ...commonPageTags,
          ...eStatementTnCLinkClk,
          ...productDetails,
          ...tagsForDashboardJourney,
          ...customerGroup,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(eStatementTnCLinkClkObj);
        break;
      }
      case 'eStatementCommonLinkClk': {
        const { eventContent, rawDataLayer, customerGroup } = event;
        const eStatementCommonLinkClkObj = {
          ...commonPageTags,
          ...eStatementCommonLinkClk,
          ...productDetails,
          ...customerGroup,
          ...tagsForDashboardJourney,
          event_content: eventContent,
          raw_datalayer: rawDataLayer,
          page_language: pageLanguage
        };
        window.TMS.trackEvent(eStatementCommonLinkClkObj);
        break;
      }
      case 'estatementAndAdvices': {
        const { eventContent, rawDataLayer, customerGroup } = event;
        const estatementAndAdvicesObject = {
          ...detailsPage,
          ...commonPageTags,
          ...tagsForDashboardJourney,
          ...productDetails,
          ...commonButtonEvents,
          event_content: eventContent,
          raw_datalayer: rawDataLayer,
          page_language: pageLanguage,
          ...customerGroup
        };
        window.TMS.trackEvent(estatementAndAdvicesObject);
        break;
      }
      default:
        break;
    }
  }
};
