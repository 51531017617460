import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TooltipWrapper from '../../common/tooltipWrapper/TooltipWrapper';
import {
  SectionWrapper,
  SectionHeader,
  SectionHeaderText,
  SectionImgSpan,
  BenefitSectionFooter,
  BenefitsSubSectionWrapper,
  RightSection,
  PolicyName,
  LeftSection,
  CentreSection,
  CentralImage,
  SectionSubTitle,
  BenefitSectionBody,
  LinkWrapper,
  RowDiv,
  RowLeftDiv,
  RowRightDiv
} from './SectionStyle';
import * as templates from '../templates';
import { getFieldDataFromConfig } from '../../utils/policyUtils';
import { doAND, doOR } from '../../common/util/commonUtil';
import { getImagePath } from '../../utils/routeUtils';
import { DetailsPageMiddleSection, ImagePath } from '../detailPageStyle';
import BenefitsAndCoverageHeader from '../../assets/images/insurance.svg';
import SupplementarySubSection from './subSections/SupplementarySubSection';
import policySectionUtils from '../sectionBuilderUtils/policySectionUtils';
import ServicingOption from './ServicingOption';
import MedicalDisclaimer from '../templates/Benefits/MedicalDisclaimer';

export function hasArrayAllNulls(arrary) {
  return arrary.every(value => value === null);
}

export const checkFieldDataParent = fieldDataParent => {
  return fieldDataParent && fieldDataParent.length;
};

export const checkFieldSuppression = (arrayFieldData, hasCoverageName) => {
  return (arrayFieldData && hasCoverageName) || <></>;
};

export const checkDependentFieldContent = content => {
  return content || '';
};

export const checkRiderFieldContent = content => {
  return content || [];
};

export const checkFieldComponent = FieldComponent => {
  return FieldComponent || <></>;
};

export const checkFieldStack = fieldsStack => {
  return (fieldsStack && fieldsStack.length && fieldsStack.length > 3) || <></>;
};

export const getLabelContent = (
  fieldConfigInstance,
  fieldLabelSecondayKey,
  fieldLabel
) => {
  let fieldLabelContent = '';
  if (fieldConfigInstance[fieldLabelSecondayKey]) {
    fieldLabelContent = fieldConfigInstance[fieldLabelSecondayKey];
  } else {
    fieldLabelContent = fieldLabel;
  }
  return fieldLabelContent;
};

const checkRiderConditions = (
  dependentSectionField,
  fieldName,
  arrayFieldData
) => {
  return (
    dependentSectionField === fieldName &&
    !!arrayFieldData &&
    arrayFieldData.length > 0
  );
};

const checkSectionToRender = function checkSectionToRender(
  basicPlanTopSection,
  basicPlanLeftSection,
  basicPlanRightSection
) {
  return (
    !hasArrayAllNulls(basicPlanTopSection) &&
    !hasArrayAllNulls(basicPlanLeftSection) &&
    !hasArrayAllNulls(basicPlanRightSection)
  );
};

const checkRiderSectionToRender = function checkRiderSectionToRender(
  hasCoverageName,
  riderFieldsStack
) {
  return hasCoverageName && riderFieldsStack.length > 0;
};

const getDependentFieldData = (condition, value) => {
  if (condition) {
    return value;
  }
  return undefined;
};

const checkAllFieldZeroNull = medicalRightSection => {
  return _.every(medicalRightSection, fieldObj => {
    const { props } = fieldObj;
    return (
      !props.fieldData ||
      props.fieldData.amount <= 0 ||
      !props.fieldData.currency
    );
  });
};

const checkFieldDataCurrency = fieldData => {
  return !fieldData || !fieldData.currency || _.isEmpty(fieldData);
};

const checkNegativeZeroValue = medicalRightSection => {
  let showMedicalSection = true;
  _.forEach(medicalRightSection, fieldObj => {
    const { props } = fieldObj;
    const { fieldData, supressZeroNegative } = props;
    if (checkFieldDataCurrency(fieldData)) {
      showMedicalSection = false;
    } else {
      const { amount } = fieldData;
      if (doOR(amount < 0, supressZeroNegative && amount === 0)) {
        showMedicalSection = false;
      }
    }
  });
  return showMedicalSection;
};

const checkMedicalFieldDataNull = medicalRightSection => {
  if (checkAllFieldZeroNull(medicalRightSection)) {
    return false;
  }
  return checkNegativeZeroValue(medicalRightSection);
};

const appendDownloadDocumentComponent = (
  stackCurrentInstance,
  downloadDocumentComponent
) => {
  if (downloadDocumentComponent) {
    stackCurrentInstance.push(downloadDocumentComponent);
  }
  return stackCurrentInstance;
};
const detailsPageMiddleSection = args => {
  const [
    basicPlanLeftSection,
    basicPlanTopSection,
    basicPlanRightSection,
    benefitServiceOption,
    centreImageForProduct,
    formatMessage,
    sectionHeading,
    riderFieldsStack,
    medicalFieldsStack,
    criticalIllnessFieldsStack,
    hasCoverageName,
    riderHasCoverageNameStack,
    dependentSectionField,
    medicalRightSection,
    medicalChartSection,
    medicalHasCoverageNameStack,
    childProtectionFieldStack,
    criticalIllnessHasCoverageNameStack,
    surgicalBenefitFieldStack,
    downloadDocComponent
  ] = args;
  const showMedicalSection = checkMedicalFieldDataNull(medicalRightSection);
  return (
    <>
      <DetailsPageMiddleSection>
        <SectionWrapper>
          <SectionHeader>
            <SectionImgSpan>
              <ImagePath
                aria-hidden="true"
                alt=""
                src={BenefitsAndCoverageHeader}
              />
            </SectionImgSpan>
            <SectionHeaderText>
              {formatMessage({ id: sectionHeading })}
            </SectionHeaderText>
          </SectionHeader>

          {checkSectionToRender(
            basicPlanTopSection,
            basicPlanLeftSection,
            basicPlanRightSection
          ) && (
            <BenefitSectionBody
              hasRiderData={
                riderFieldsStack.length > 0 ||
                medicalFieldsStack.length > 0 ||
                criticalIllnessFieldsStack.length > 0
              }
              hasCoverageName={hasCoverageName}
            >
              <SectionSubTitle>
                {formatMessage({ id: `basicPlanSubTitle` })}
              </SectionSubTitle>
              <PolicyName>{basicPlanTopSection}</PolicyName>
              <BenefitsSubSectionWrapper>
                <LeftSection>
                  {basicPlanLeftSection}
                  {benefitServiceOption && (
                    <LinkWrapper>
                      <ServicingOption
                        servicingOptionsURL={benefitServiceOption}
                        sectionHeading={sectionHeading}
                        noMarginBottom
                        setIconWidthHeight
                        calledFrom="Benefits"
                      />
                    </LinkWrapper>
                  )}
                </LeftSection>
                <CentreSection>
                  <CentralImage>
                    <ImagePath
                      aria-hidden="true"
                      alt={formatMessage({ id: 'decorativeImage' })}
                      src={centreImageForProduct}
                    />
                  </CentralImage>
                </CentreSection>
                <RightSection hasCoverageName={hasCoverageName}>
                  {basicPlanRightSection}
                </RightSection>
              </BenefitsSubSectionWrapper>
            </BenefitSectionBody>
          )}
          {/* medical */}
          {showMedicalSection && medicalFieldsStack.length > 0 && (
            <BenefitSectionFooter>
              <SectionSubTitle>
                {formatMessage({ id: 'medicalAccountHeaderLabel' })}
                <TooltipWrapper
                  id="tooltip_id_medicalAccountHeader_Tooltip"
                  contentKey="medicalAmountLabelTooltip"
                  sectionHeading={sectionHeading}
                  labelContent="medicalAccountHeaderLabel"
                  maxWidth={300}
                />
              </SectionSubTitle>

              <RowDiv>
                <RowLeftDiv>{medicalChartSection}</RowLeftDiv>
                <RowRightDiv>{medicalRightSection}</RowRightDiv>
              </RowDiv>
            </BenefitSectionFooter>
          )}
          {checkRiderSectionToRender(hasCoverageName, medicalFieldsStack) && (
            <BenefitSectionFooter
              hasRiderData={
                criticalIllnessFieldsStack.length > 0 || riderFieldsStack.length
              }
            >
              <SectionSubTitle>
                {formatMessage({ id: 'medicalAccountCoverageSection' })}
              </SectionSubTitle>
              {medicalFieldsStack.map(
                (medicalInstance, index) =>
                  medicalHasCoverageNameStack[index] && (
                    <>
                      <SupplementarySubSection
                        key={medicalInstance}
                        leftSection={medicalInstance[5]}
                        rightSection={medicalInstance[6]}
                        dependentSectionField={dependentSectionField}
                      />
                    </>
                  )
              )}
              {surgicalBenefitFieldStack.map(riderInstance => (
                <>
                  <SupplementarySubSection
                    key={riderInstance}
                    leftSection={riderInstance[7]}
                    rightSection={riderInstance[8]}
                    dependentSectionField={dependentSectionField}
                    clubChildRider
                  />
                </>
              ))}
              {medicalFieldsStack.length > 0 && (
                <MedicalDisclaimer labelContent="medDisclaimerLabel" />
              )}
            </BenefitSectionFooter>
          )}
          {checkRiderSectionToRender(
            hasCoverageName,
            criticalIllnessFieldsStack
          ) && (
            <BenefitSectionFooter
              supressPadding={medicalFieldsStack.length <= 0}
              hasRiderData={riderFieldsStack.length > 0}
            >
              <SectionSubTitle>
                {formatMessage({ id: 'criticalIllnessHeaderLabel' })}
              </SectionSubTitle>
              {criticalIllnessFieldsStack.map(
                (criticalIllnessInstance, index) =>
                  criticalIllnessHasCoverageNameStack[index] && (
                    <>
                      <SupplementarySubSection
                        key={criticalIllnessInstance}
                        leftSection={criticalIllnessInstance[9]}
                        rightSection={criticalIllnessInstance[10]}
                        dependentSectionField={dependentSectionField}
                      />
                    </>
                  )
              )}
              {criticalIllnessFieldsStack.length > 0 && (
                <MedicalDisclaimer labelContent="medDisclaimerLabel" />
              )}
            </BenefitSectionFooter>
          )}
          {/*  */}
          {checkRiderSectionToRender(hasCoverageName, riderFieldsStack) && (
            <BenefitSectionFooter
              supressPadding={
                medicalFieldsStack.length <= 0 &&
                criticalIllnessFieldsStack.length <= 0
              }
              suppressBorder
              hasRiderData={
                medicalFieldsStack.length > 0 ||
                criticalIllnessFieldsStack.length > 0
              }
            >
              <SectionSubTitle>
                {formatMessage({ id: 'supplementarySection' })}
              </SectionSubTitle>
              {riderFieldsStack.map(
                (riderInstance, index) =>
                  riderHasCoverageNameStack[index] && (
                    <>
                      <SupplementarySubSection
                        key={riderInstance}
                        leftSection={riderInstance[11]}
                        rightSection={riderInstance[12]}
                        dependentSectionField={dependentSectionField}
                      />
                    </>
                  )
              )}
              {childProtectionFieldStack.map(riderInstance => (
                <>
                  <SupplementarySubSection
                    key={riderInstance}
                    leftSection={appendDownloadDocumentComponent(
                      riderInstance[13],
                      downloadDocComponent
                    )}
                    rightSection={riderInstance[14]}
                    dependentSectionField={dependentSectionField}
                    clubChildRider
                  />
                </>
              ))}
            </BenefitSectionFooter>
          )}
        </SectionWrapper>
      </DetailsPageMiddleSection>
    </>
  );
};

const getFilteredListChildGroup = (
  currentPolicyDetail,
  childProtectionSubCategoryField,
  riderSubCategory
) => {
  const { fieldParent, fieldName } = childProtectionSubCategoryField;
  const childProtectionArr = _.get(currentPolicyDetail, fieldParent);
  return _.filter(childProtectionArr, [`${fieldName}`, riderSubCategory]);
};

const getRiderClubComponent = args => {
  const [
    fieldConfigInstance,
    arrayData,
    sectionHeading,
    FieldComponent,
    fieldId,
    clubSurgicalOrChildInfo
  ] = args;
  const {
    fieldLabel,
    fieldName,
    toolTip,
    dependentField,
    dependentContent,
    dependentFieldTwo,
    dependentFieldThree,
    riderIcon,
    documentOrder
  } = fieldConfigInstance;
  return (
    <FieldComponent
      key={fieldLabel}
      id={fieldId}
      fieldLabel={fieldLabel}
      fieldData={arrayData}
      fieldName={fieldName}
      toolTipContent={toolTip}
      sectionHeading={sectionHeading}
      dependentField={dependentField}
      dependentContent={dependentContent}
      dependentFieldTwo={dependentFieldTwo}
      dependentFieldDataThree={dependentFieldThree}
      riderIcon={riderIcon}
      clubSurgicalOrChildInfo={clubSurgicalOrChildInfo}
      documentOrder={documentOrder}
      labelContent={fieldLabel}
    />
  );
};

const checkIsMedicalAccountGrp = medicalAccount => {
  return medicalAccount && medicalAccount === 'Medical Account Balance (Group)';
};

const checkIsCriticalIllness = criticalIllnessObj =>
  criticalIllnessObj === 'Critical Illness Benefit (Group)';

const getdependentComponentData = (
  dependentField,
  currentPolicyDetail,
  fieldConfigInstance
) => {
  let dependentComponentData;
  if (dependentField) {
    dependentComponentData = getFieldDataFromConfig(
      currentPolicyDetail,
      fieldConfigInstance,
      true,
      'dependentField'
    );
  }
  return dependentComponentData;
};
const Benefits = function Benefits(props, context) {
  const {
    sectionHeading,
    sectionFieldConfig,
    currentPolicyDetail,
    dependentSectionField,
    productCategory,
    productCategoryKey,
    dependentSectionFieldTwo,
    dependentSectionFieldThree,
    dependentSectionFieldChild,
    dependentSectionFieldSurgical,
    fieldConfig,
    commonToSections: {
      Benefits: { centreImage }
    },
    servicingOptionsURL: { ViewHospitalNetworkProviders }
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  let fieldsStack = [];
  const riderFieldsStack = [];
  const medicalFieldsStack = [];
  const childProtectionFieldStack = [];
  let childProtectionGrpArray = [];
  const surgicalBenefitFieldStack = [];
  let surgicalBenefitGrpArray = [];
  const criticalIllnessFieldsStack = [];
  const riderHasCoverageNameStack = [];
  const medicalHasCoverageNameStack = [];
  const criticalIllnessHasCoverageNameStack = [];
  let hasCoverageName = false;
  let labelContent;
  const downloadDocComponent = '';
  let arrayField = '';
  const { getPolicySectionOrder } = policySectionUtils;
  /**
   * DependentSectionFieldConfig will be used to alter the section settings as per this field's value
   */
  const dependentSectionFieldThreeConfig =
    fieldConfig[dependentSectionFieldThree];
  /**
   * planCodeName will be used to alter the label of sum insured field
   */
  const planCodeName = getFieldDataFromConfig(
    currentPolicyDetail,
    dependentSectionFieldThreeConfig,
    true
  );

  /** dependentSectionFieldChild will be used to club the child protection group fields into a single card */

  const childProtectionSubCategoryField =
    fieldConfig[dependentSectionFieldChild];
  childProtectionGrpArray = getFilteredListChildGroup(
    currentPolicyDetail,
    childProtectionSubCategoryField,
    'Child protection (Group)'
  );

  /** dependentSectionFieldSurgical will be used to club the surgical benefit group fields into a single card */

  const surgicalBenefitSubCategoryField =
    fieldConfig[dependentSectionFieldSurgical];
  surgicalBenefitGrpArray = getFilteredListChildGroup(
    currentPolicyDetail,
    surgicalBenefitSubCategoryField,
    'Surgical (Group)'
  );

  const updateRiderHasCoverageNameStack = apiArrayIndex => {
    riderHasCoverageNameStack[apiArrayIndex] =
      riderHasCoverageNameStack[apiArrayIndex] || true;
    medicalHasCoverageNameStack[apiArrayIndex] =
      medicalHasCoverageNameStack[apiArrayIndex] || true;
    criticalIllnessHasCoverageNameStack[apiArrayIndex] =
      criticalIllnessHasCoverageNameStack[apiArrayIndex] || true;
  };

  const updateRideContentRiderFieldStack = (
    riderFieldStack,
    apiArrayIndex,
    subSectionIndex,
    currentComponent
  ) => {
    const tempFieldsStack = riderFieldStack;
    tempFieldsStack[apiArrayIndex] = checkRiderFieldContent(
      tempFieldsStack[apiArrayIndex]
    );
    tempFieldsStack[apiArrayIndex][subSectionIndex] = checkRiderFieldContent(
      tempFieldsStack[apiArrayIndex][subSectionIndex]
    );
    tempFieldsStack[apiArrayIndex][subSectionIndex].push(currentComponent);
  };

  const updateAccordingToFieldStack = (
    { isMedical, isCriticalIllness, mandatoryField },
    apiArrayIndex,
    subSectionIndex,
    currentComponent
  ) => {
    if (isMedical) {
      updateRideContentRiderFieldStack(
        medicalFieldsStack,
        apiArrayIndex,
        subSectionIndex,
        currentComponent
      );
    } else if (isCriticalIllness) {
      updateRideContentRiderFieldStack(
        criticalIllnessFieldsStack,
        apiArrayIndex,
        subSectionIndex,
        currentComponent
      );
    } else if (!mandatoryField) {
      updateRideContentRiderFieldStack(
        riderFieldsStack,
        apiArrayIndex,
        subSectionIndex,
        currentComponent
      );
    }
  };

  const displayClubbedRidersInfo = args => {
    const [
      clubRidersInfo,
      clubSurgicalInfo,
      fieldConfigInstance,
      FieldComponent,
      subSectionIndex,
      index
    ] = args;
    if (clubRidersInfo) {
      if (doAND(childProtectionGrpArray, childProtectionGrpArray.length)) {
        const riderComponent = getRiderClubComponent([
          fieldConfigInstance,
          childProtectionGrpArray,
          sectionHeading,
          FieldComponent,
          `field_child_${subSectionIndex}_${index}`,
          clubRidersInfo
        ]);
        updateRideContentRiderFieldStack(
          childProtectionFieldStack,
          0,
          subSectionIndex,
          riderComponent
        );
      }
      return true;
    }
    if (clubSurgicalInfo) {
      if (doAND(surgicalBenefitGrpArray, surgicalBenefitGrpArray.length)) {
        const riderComponent = getRiderClubComponent([
          fieldConfigInstance,
          surgicalBenefitGrpArray,
          sectionHeading,
          FieldComponent,
          `field_surgical_${subSectionIndex}_${index}`,
          clubSurgicalInfo
        ]);
        updateRideContentRiderFieldStack(
          surgicalBenefitFieldStack,
          0,
          subSectionIndex,
          riderComponent
        );
      }
      return true;
    }
    return false;
  };

  fieldsStack = sectionFieldConfig.map(
    (subSectionFieldConfig, subSectionIndex) => {
      return subSectionFieldConfig.map((fieldConfigInstance, index) => {
        const {
          uiFormat,
          fieldName,
          fieldLabel,
          dependentField,
          dependentFieldTwo,
          dependentFieldThree,
          dependentContent,
          toolTip,
          showLegendIconColor,
          showBoldFontAmount,
          mandatoryField,
          clubRidersInfo,
          clubSurgicalInfo,
          fieldType,
          supressZeroNegative,
          documentOrder
        } = fieldConfigInstance;
        const FieldComponent = templates[uiFormat];
        const fieldDataParent = getFieldDataFromConfig(
          currentPolicyDetail,
          fieldConfigInstance,
          false
        );
        if (
          displayClubbedRidersInfo([
            clubRidersInfo,
            clubSurgicalInfo,
            fieldConfigInstance,
            FieldComponent,
            subSectionIndex,
            index
          ])
        ) {
          return <React.Fragment key={fieldLabel} />;
        }

        const isArrayField = checkFieldDataParent(fieldDataParent);

        if (isArrayField) {
          fieldDataParent.forEach((fieldDataInstance, apiArrayIndex) => {
            const arrayFieldData = _.get(fieldDataInstance, fieldName);
            checkFieldSuppression(arrayFieldData, hasCoverageName);
            const dependentFieldData = getDependentFieldData(
              dependentField,
              _.get(fieldDataInstance, dependentField)
            );
            const dependentFieldDataTwo = getDependentFieldData(
              dependentFieldTwo,
              _.get(fieldDataInstance, dependentFieldTwo)
            );
            const dependentFieldDataThree = getDependentFieldData(
              dependentFieldThree,
              _.get(fieldDataInstance, dependentFieldThree)
            );
            const mandatoryFieldData = getDependentFieldData(
              mandatoryField,
              fieldDataInstance[mandatoryField]
            );
            let dependentComponentData;
            if (fieldConfigInstance[dependentSectionFieldTwo]) {
              dependentComponentData = getFieldDataFromConfig(
                currentPolicyDetail,
                fieldDataInstance[dependentField],
                true
              );
            }
            if (
              checkRiderConditions(
                dependentSectionField,
                fieldName,
                arrayFieldData
              )
            ) {
              hasCoverageName = true;
              updateRiderHasCoverageNameStack(apiArrayIndex);
            }
            const fieldLabelSecondayKey = `fieldLabel_${productCategory}`;
            labelContent = getLabelContent(
              fieldConfigInstance,
              fieldLabelSecondayKey,
              fieldLabel
            );
            const isMedical = checkIsMedicalAccountGrp(mandatoryFieldData);
            const isCriticalIllness = checkIsCriticalIllness(
              mandatoryFieldData
            );
            arrayField = (
              <FieldComponent
                key={fieldLabel}
                id={`field_benefit_${apiArrayIndex}_${index}`}
                labelContent={labelContent}
                fieldData={arrayFieldData}
                toolTipContent={toolTip}
                idx={apiArrayIndex}
                fontChange={typeof value === 'object'}
                dependentFieldData={dependentFieldData}
                dependentContentData={checkDependentFieldContent(
                  dependentContent
                )}
                dependentComponentData={dependentComponentData}
                sectionHeading={sectionHeading}
                mandatoryFieldData={mandatoryFieldData}
                isMedical={isMedical}
                fieldType={fieldType}
                documentOrder={documentOrder}
                dependentField={dependentField}
                dependentContent={dependentContent}
                dependentFieldDataTwo={dependentFieldDataTwo}
                dependentFieldDataThree={dependentFieldDataThree}
                currentPolicyDetail={currentPolicyDetail}
              />
            );
            updateAccordingToFieldStack(
              {
                isMedical,
                isCriticalIllness,
                mandatoryField
              },
              apiArrayIndex,
              subSectionIndex,
              arrayField
            );

            return <React.Fragment key={fieldLabel} />;
          });
          return <React.Fragment key={fieldLabel} />;
        }
        const fieldData = getFieldDataFromConfig(
          currentPolicyDetail,
          fieldConfigInstance,
          true
        );
        checkFieldComponent(FieldComponent);
        let dependentFieldData;
        if (dependentField) {
          dependentFieldData = getFieldDataFromConfig(
            currentPolicyDetail,
            fieldConfigInstance,
            true,
            'dependentField'
          );
        }

        const dependentComponentData = getdependentComponentData(
          dependentField,
          currentPolicyDetail,
          fieldConfigInstance
        );

        const fieldLabelSecondayKey = `fieldLabel_${productCategory}`;
        labelContent = getLabelContent(
          fieldConfigInstance,
          fieldLabelSecondayKey,
          fieldLabel
        );

        return (
          <FieldComponent
            key={fieldLabel}
            id={`field_benefit_${index}`}
            productCategory={productCategory}
            productCategoryKey={productCategoryKey}
            labelContent={labelContent}
            fieldData={fieldData}
            toolTipContent={toolTip}
            idx={index}
            fontChange={typeof value === 'object'}
            dependentContentData={checkDependentFieldContent(dependentContent)}
            dependentFieldData={dependentFieldData}
            sectionHeading={sectionHeading}
            dependentComponentData={dependentComponentData}
            secondDependentFieldData={planCodeName}
            showLegendIconColor={showLegendIconColor}
            showBoldFontAmount={showBoldFontAmount}
            supressZeroNegative={supressZeroNegative}
            dependentField={dependentField}
            documentOrder={documentOrder}
            dependentContent={dependentContent}
            currentPolicyDetail={currentPolicyDetail}
          />
        );
      });
    }
  );

  checkFieldStack(fieldsStack);
  const basicPlanTopSection = fieldsStack[0];
  const basicPlanLeftSection = fieldsStack[1];
  const basicPlanRightSection = fieldsStack[2];

  /* medical group account top sections - charts - data */
  const medicalChartSection = fieldsStack[3];
  const medicalRightSection = fieldsStack[4];

  const centreImageRelativePath = getPolicySectionOrder(
    productCategoryKey,
    centreImage
  );
  const centreImageForProduct = getImagePath(
    process.env.REACT_APP_IMAGES_URL,
    centreImageRelativePath
  );
  const benefitServiceOption = ViewHospitalNetworkProviders && {
    ViewHospitalNetworkProviders
  };

  return detailsPageMiddleSection([
    basicPlanLeftSection,
    basicPlanTopSection,
    basicPlanRightSection,
    benefitServiceOption,
    centreImageForProduct,
    formatMessage,
    sectionHeading,
    riderFieldsStack,
    medicalFieldsStack,
    criticalIllnessFieldsStack,
    hasCoverageName,
    riderHasCoverageNameStack,
    dependentSectionField,
    medicalRightSection,
    medicalChartSection,
    medicalHasCoverageNameStack,
    childProtectionFieldStack,
    criticalIllnessHasCoverageNameStack,
    surgicalBenefitFieldStack,
    downloadDocComponent
  ]);
};

Benefits.propTypes = {
  sectionFieldConfig: PropTypes.array.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  productCategory: PropTypes.string.isRequired,
  productCategoryKey: PropTypes.string.isRequired,
  dependentSectionField: PropTypes.string,
  dependentSectionFieldTwo: PropTypes.string,
  dependentSectionFieldThree: PropTypes.string,
  fieldConfig: PropTypes.object.isRequired,
  commonToSections: PropTypes.object.isRequired,
  servicingOptionsURL: PropTypes.object.isRequired
};

Benefits.defaultProps = {
  dependentSectionField: '',
  dependentSectionFieldTwo: '',
  dependentSectionFieldThree: ''
};

Benefits.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default Benefits;
