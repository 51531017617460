import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import CurrencyWithSymbol from '../common/currency/CurrencyWithSymbol';
import Currency from '../common/currency/Currency';
import getDescription from '../utils/reducerUtils';
import { truncDigits, formatLocaleNumber } from '../utils/formatter';
import {
  BubbleDiv,
  BubbleImgSpan,
  BubbleDataSpan,
  BubbleLabelSpan
} from '../reprojection/reprojectionPageStyle';
import { displayIfNull, doAND } from '../common/util/commonUtil';
import { isMiniMobile } from '../utils/domUtils';

export const chartColors = [
  '#9451e0',
  '#e66b00',
  '#5851e0',
  '#4e9c2d',
  '#1087ef',
  '#00a69d',
  '#563594',
  '#bf5900',
  '#3a3594',
  '#3b7522',
  '#9451e0',
  '#e66b00',
  '#5851e0',
  '#4e9c2d',
  '#1087ef',
  '#00a69d',
  '#563594',
  '#bf5900',
  '#3a3594',
  '#3b7522'
];

const numberFormatConfig = {
  en: [
    { gte: 1000000000, notation: 'billion_subscript' },
    { gte: 1000000, notation: 'million_subscript' },
    { gte: 1000, notation: 'thousand_subscript' }
  ],
  'zh-tc': [
    { gte: 100000000, notation: 'billion_subscript' },
    { gte: 10000, notation: 'million_subscript' },
    { gte: 1000, notation: 'thousand_subscript' }
  ],
  zh: [
    { gte: 100000000, notation: 'billion_subscript' },
    { gte: 10000, notation: 'million_subscript' },
    { gte: 1000, notation: 'thousand_subscript' }
  ]
};

export function getCorrectPayoutDetails(
  selectedAge,
  maxAge,
  payoutDetails,
  policyMaturityFieldsMapping
) {
  if (!payoutDetails || (_.isNumber(maxAge) && selectedAge !== maxAge)) {
    return payoutDetails;
  }
  const newPayoutDetails = { ...payoutDetails };
  _.forEach(policyMaturityFieldsMapping, (fieldValue, fieldKey) => {
    if (newPayoutDetails[fieldValue] !== undefined) {
      newPayoutDetails[fieldKey] = newPayoutDetails[fieldValue];
    }
  });

  return newPayoutDetails;
}

export function isValidMaturityAmount(maturityAmount) {
  return _.isNumber(maturityAmount) && maturityAmount > 0;
}

export function cellDataGenerator(locale, fieldData = {}) {
  switch (fieldData.constructor.name) {
    case 'Object':
      if (fieldData.amount && fieldData.currency) {
        return (
          <CurrencyWithSymbol
            currencyCode={fieldData.currency}
            amount={fieldData.amount}
          />
        );
      }
      return '';

    case 'Array':
      return getDescription(locale, fieldData);

    case 'String':
      return fieldData;

    case 'Number':
      return fieldData;

    default:
      return '';
  }
}

/** Function that returns the content of the bubble that pops up on hovering the data points */
export function getTooltipContents(
  data,
  rawData,
  fields,
  row,
  imagePath,
  headerLabel,
  policyCurrency,
  tooltipId,
  selectedTabName
) {
  let content = '';
  let byIdentifier;

  content = _.map(fields, (field, index) => {
    const {
      label: rawDataKey,
      showBubble,
      imagePosition,
      decimalRange = 0
    } = field;
    // The age definition in the configuration needs to be in 1st position, used as identifier
    if (index === 0) {
      byIdentifier = data[row + 1][index];
    }

    return (
      showBubble && (
        <BubbleDiv>
          {!imagePosition && (
            <>
              <BubbleLabelSpan
                id={`${selectedTabName}_${tooltipId}_labelValue`}
              >
                <FormattedMessage
                  id={headerLabel}
                  values={{ age: byIdentifier }}
                />
              </BubbleLabelSpan>
            </>
          )}
          {imagePosition && (
            <>
              <BubbleImgSpan
                id={`${selectedTabName}_imagePath_${index}`}
                imagePath={imagePath}
                imagePosition={imagePosition}
              />
              <BubbleDataSpan
                dataCurrencySpan
                id={`${selectedTabName}_dataCurrencySpan_${index}`}
              >
                <Currency
                  id="dataPoint_currency"
                  currencyCode={policyCurrency}
                  amount={truncDigits(
                    displayIfNull(
                      rawData[byIdentifier][rawDataKey],
                      0,
                      rawData[byIdentifier][rawDataKey],
                      true
                    ),
                    2
                  )}
                  decimalRange={decimalRange}
                />
              </BubbleDataSpan>
            </>
          )}
        </BubbleDiv>
      )
    );
  });
  return content;
}

const genrateLabelFrequencyArray = function genrateLabelFrequencyArray(
  stepSize,
  data
) {
  const ticks = [];
  for (let i = 1; i < data.length; i += stepSize) {
    if (i + stepSize <= data.length - 1) {
      ticks.push(data[i][0]);
    } else {
      ticks.push(data[data.length - 1][0]);
    }
  }
  return ticks;
};
export const xAxisLabelFrequncyHandler = function xAxisLabelFrequncyHandler(
  size,
  data
) {
  let stepSize = 0;
  if (size <= 20) {
    stepSize = isMiniMobile() ? 5 : 3;
  } else if (size <= 40) {
    stepSize = isMiniMobile() ? 9 : 7;
  } else if (size <= 60) {
    stepSize = isMiniMobile() ? 13 : 10;
  } else if (size <= 80) {
    stepSize = isMiniMobile() ? 17 : 13;
  } else {
    stepSize = isMiniMobile() ? 23 : 18;
  }
  return genrateLabelFrequencyArray(stepSize, data);
};

/*
  Function to manually calculate chart axis points to be displayed on x-axis and y-axis
*/
export const calculateChartTicks = (maxAxisValue, locale, formatMessage) => {
  let newMaxAxisValue = maxAxisValue;
  const localConfig = numberFormatConfig[locale];
  let multiplyingFactor = 1;
  for (
    let i = 0;
    doAND(i < localConfig.length, multiplyingFactor !== 1);
    i += 1
  ) {
    const { gte } = localConfig[i];
    if (newMaxAxisValue > gte) {
      newMaxAxisValue /= gte;
      multiplyingFactor = gte;
    }
  }
  newMaxAxisValue = +truncDigits(Math.ceil(newMaxAxisValue));

  let numAxisPoints = 5;
  while (newMaxAxisValue % numAxisPoints !== 0) {
    let tempNumAxisPoints = numAxisPoints;
    while (tempNumAxisPoints > 1 && newMaxAxisValue % tempNumAxisPoints !== 0) {
      tempNumAxisPoints -= 1;
    }
    if (tempNumAxisPoints > 1 && newMaxAxisValue % tempNumAxisPoints === 0) {
      numAxisPoints = tempNumAxisPoints;
    } else {
      newMaxAxisValue += 1;
    }
  }

  const axisInterval = newMaxAxisValue / numAxisPoints;
  const axisTicks = [];
  if (axisInterval > 0) {
    for (let i = 0; i <= newMaxAxisValue; i += axisInterval) {
      const axisLabel = formatLocaleNumber(
        numberFormatConfig,
        locale,
        i * multiplyingFactor,
        formatMessage
      ).toString();
      axisTicks.push({ v: i * multiplyingFactor, f: axisLabel });
    }
  }
  return axisTicks;
};

// function to determine if an empty space is required in the progress bar.

export const progressBarWidthUtil = currentProgress => {
  return currentProgress !== 0 || currentProgress !== 100;
};

// function to determine progress bar width
// TODO need to finalize below calculations
export const currentProgressBarWidth = (
  medicalClaimBalanceAmount,
  totalMedicalAmount
) => {
  return (medicalClaimBalanceAmount / totalMedicalAmount) * 100;
};
