import Cookies from 'universal-cookie';

const defaultOptions = (
  path = process.env.REACT_APP_COOKIE_PATH,
  domain = process.env.REACT_APP_COOKIE_DOMAIN,
  sameSite = 'lax'
) => {
  return { path, domain, sameSite };
};

export const getCookie = cookieName => {
  return new Cookies().get(cookieName);
};

export const setCookie = (cookieName, cookieValue, options = {}) => {
  const cookieOptions = { ...defaultOptions(), ...options };
  new Cookies().set(cookieName, cookieValue, cookieOptions);
};

export const checkCookie = cookieName => {
  const cookie = getCookie(cookieName);
  return cookie !== '';
};

/**
 * @description - This function will stringify the object and save to the localStorage.
 * @param {string} storageIdentifier - id for that localstorage.
 * @param {object} storageData - Object that needs to be saved in the localStorage
 */
export const setItemInLocalStorage = function setItemInLocalStorage(
  storageIdentifier,
  storageData
) {
  localStorage.setItem(storageIdentifier, JSON.stringify(storageData));
};
/**
 * @description - Function will return the parse and return the object for the particular localStorage.
 * @param {string} storageIdentifier - id for that localstorage.
 */
export const getItemInLocalStorage = function getItemInLocalStorage(
  storageIdentifier
) {
  return JSON.parse(localStorage.getItem(storageIdentifier));
};
