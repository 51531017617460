import React from 'react';
import PropTypes from 'prop-types';
import {
  RiderHeader,
  RiderIcon,
  ImagePath,
  RiderNameWrapper
} from '../../detailPageStyle';
import riderIconMapping from './data/RiderIconMapping';

function ChildRiderName(props, context) {
  const {
    intl: { formatMessage }
  } = context;

  const { fieldLabel, riderIcon } = props;
  const imgSrc = riderIconMapping[`${riderIcon}`].srcIcon;

  if (!fieldLabel) {
    return <></>;
  }

  return (
    <RiderNameWrapper>
      <RiderIcon>
        <ImagePath aria-hidden="true" alt="" src={imgSrc} />
      </RiderIcon>
      <RiderHeader>{formatMessage({ id: fieldLabel })}</RiderHeader>
    </RiderNameWrapper>
  );
}

ChildRiderName.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  riderIcon: PropTypes.string.isRequired
};

ChildRiderName.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ChildRiderName;
