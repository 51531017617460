import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PageLevelWarningSection } from './detailPageStyle';
import {
  NotificationMessageType,
  NotificationMessageIcon,
  NotificationMessage,
  NotificationIconSpan,
  ImagePath
} from '../common/styles/commonStyle';
import * as icons from '../assets/images/NotificationIcons';

const ErrorNotification = () => {
  return (
    <PageLevelWarningSection id="pageLevelWarningSection">
      <NotificationMessageType
        isDetailsPage
        bgColor="#fbf8f3"
        borderColor="#e9a115"
      >
        <NotificationMessageIcon role="alert">
          <NotificationIconSpan>
            <ImagePath aria-hidden="true" alt="" src={icons.Warning} />
          </NotificationIconSpan>
          <NotificationMessage>
            <FormattedMessage
              id="errorNotification"
              defaultMessage="Sorry, the documents are not available at the moment due to technical issues. Please try again later."
            />
          </NotificationMessage>
        </NotificationMessageIcon>
      </NotificationMessageType>
    </PageLevelWarningSection>
  );
};

ErrorNotification.propTypes = {};

export default ErrorNotification;
