import React from 'react';
import PropTypes from 'prop-types';
import {
  FundName,
  FundAllocation,
  FundNameSection
} from '../instructionHistoryStyle';
import Currency from '../../common/currency/Currency';
import { checkAmountZero } from '../instructionUtils';

const ServicingLabelAmount = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { label, amount, currency, removeLeftSpacing } = props;

  if (checkAmountZero(amount)) {
    return null;
  }

  return (
    <FundNameSection>
      <FundName removeLeftSpacing={removeLeftSpacing}>
        {formatMessage({ id: label })}
      </FundName>
      <FundAllocation removeLeftSpacing={removeLeftSpacing}>
        <Currency currencyCode={`${currency}`} amount={amount} />
      </FundAllocation>
    </FundNameSection>
  );
};

ServicingLabelAmount.propTypes = {
  label: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number,
  removeLeftSpacing: PropTypes.bool
};

ServicingLabelAmount.defaultProps = {
  amount: '',
  removeLeftSpacing: false
};
ServicingLabelAmount.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ServicingLabelAmount;
