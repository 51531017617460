import React from 'react';
import PropTypes from 'prop-types';
import { DivComponent } from '../styles/commonStyle';

const TabPanel = props => {
  const { tabpanelreference, children } = props;

  return (
    <DivComponent ref={tabpanelreference} {...props}>
      {children}
    </DivComponent>
  );
};
TabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  'aria-hidden': PropTypes.bool.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
  toDisplay: PropTypes.bool.isRequired,
  tabpanelreference: PropTypes.func.isRequired,
  tabIndex: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default TabPanel;
