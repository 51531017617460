import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FundsTableWrapper,
  FundTable,
  FundTableHeader,
  FundTableRow,
  FundTableTH,
  FundTableBody,
  FundTableCell,
  LinkImage,
  LinkImgSpan,
  ColumnWrapper,
  ColumnNameSpan,
  RiskLevelWrapper,
  RatingIcon,
  RiskTypeColor,
  RiskLevel,
  RiskTolerance,
  RiskIconSpan,
  FundTableCaption
} from '../../../../common/styles/fundsRPQPopupStyle';

import FundDetails from './FundDetails';

import gainIcon from '../../../../assets/images/gainChevrron.svg';
import lossIcon from '../../../../assets/images/lossIcon.svg';
import { validatePerformanceValue } from '../../../sectionUtils/FundUtils';

const RpqPopupTableDesktop = function RpqPopupTableDesktop(props, context) {
  const {
    fundsColumnData,
    fundsTableData,
    matchedRiskLevel,
    fundPerformanceColumnData,
    rpqUTBConfig
  } = props;

  const { globalFundCode, fundName, riskLvlCde } = fundsTableData;
  const { matchedRisk, matchedRiskColor } = matchedRiskLevel[riskLvlCde];

  const {
    intl: { formatMessage }
  } = context;

  const getFundPerformanceIcon = performance =>
    performance > 0 ? gainIcon : lossIcon;

  return (
    <FundsTableWrapper id="OptInOutModalDesktopTableWrapper">
      <FundTable id="OptInOutModalDesktopTable">
        <FundTableCaption>
          {' '}
          {formatMessage({ id: 'rpqTableCaption' })}
        </FundTableCaption>
        <FundTableHeader id="OptInOutModalDesktopTableHeader">
          <FundTableRow id="OptInOutModalDesktopTableHeaderRow">
            {fundsColumnData.map(headerGroup => {
              const { columnName, colWidthResponsive } = headerGroup;
              return (
                <FundTableTH
                  key={`${columnName}_column`}
                  colWidth={colWidthResponsive}
                  id={`${columnName}_column`}
                  hasWidthForMediumTab
                >
                  <ColumnWrapper>
                    <ColumnNameSpan>
                      {columnName && formatMessage({ id: columnName })}
                    </ColumnNameSpan>
                  </ColumnWrapper>
                </FundTableTH>
              );
            })}
          </FundTableRow>
        </FundTableHeader>
        <FundTableBody id="OptInOutModalDesktopTableBody">
          <FundTableRow key={`${fundName}_row`}>
            <>
              <FundTableCell
                key={`${fundName}_fundNameCell`}
                aria-describedby={`${fundName}_column`}
                isTopAlign
              >
                <FundDetails
                  fundName={fundName}
                  fundCode={globalFundCode}
                  rpqUTB={rpqUTBConfig}
                />
              </FundTableCell>
              <FundTableCell
                key={matchedRisk}
                aria-describedby={`${matchedRisk}_column`}
              >
                <RiskLevelWrapper>
                  <RatingIcon>
                    <RiskTypeColor riskTypeColor={matchedRiskColor}>
                      {+riskLvlCde}
                    </RiskTypeColor>
                    <RiskLevel>{formatMessage({ id: matchedRisk })}</RiskLevel>
                  </RatingIcon>
                  <RiskTolerance>
                    <span>
                      {formatMessage({ id: 'matchedWithYouRiskLevel' })}
                    </span>
                  </RiskTolerance>
                </RiskLevelWrapper>
              </FundTableCell>
              {_.map(fundPerformanceColumnData, currentColumn => {
                const { performance, isDisplayPerformance } = currentColumn;
                return (
                  isDisplayPerformance && (
                    <FundTableCell
                      key={performance}
                      aria-describedby={`${performance}_column`}
                    >
                      {_.isNumber(performance) && performance !== 0 ? (
                        <RatingIcon>
                          <LinkImgSpan
                            imageWidth={14}
                            imageHeight={7}
                            marginRight={5}
                          >
                            <LinkImage
                              src={getFundPerformanceIcon(performance)}
                              alt=""
                              aria-hidden="true"
                            />
                          </LinkImgSpan>
                          <RiskIconSpan>{Math.abs(performance)}%</RiskIconSpan>
                        </RatingIcon>
                      ) : (
                        <>{validatePerformanceValue(performance)}</>
                      )}
                    </FundTableCell>
                  )
                );
              })}
            </>
          </FundTableRow>
        </FundTableBody>
      </FundTable>
    </FundsTableWrapper>
  );
};

RpqPopupTableDesktop.contextTypes = {
  intl: PropTypes.object.isRequired
};

RpqPopupTableDesktop.propTypes = {
  fundsColumnData: PropTypes.array.isRequired,
  fundsTableData: PropTypes.object.isRequired,
  matchedRiskLevel: PropTypes.object.isRequired,
  fundPerformanceColumnData: PropTypes.array.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired
};
export default RpqPopupTableDesktop;
