import _ from 'lodash';

const tabList = {
  PayoutBenefit: 'PayoutBenefit',
  SurrenderValue: 'SurrenderValue',
  DeathBenefit: 'DeathBenefit'
};

const { SurrenderValue, DeathBenefit } = tabList;
const tabSubHeader = 'SubHeader';

export const checkFlagCondition = function checkFlagCondition(selectedTAB) {
  return selectedTAB === SurrenderValue || selectedTAB === DeathBenefit;
};

export const getTabSubHeaderKey = function getTabSubHeaderKey(selectedTAB) {
  return _.lowerFirst(`${selectedTAB}${tabSubHeader}`);
};

export const getTabSubHeaderKeyForType = function getTabSubHeaderKeyForType(
  selectedTAB,
  subHeaderType = ''
) {
  return _.lowerFirst(`${selectedTAB}${tabSubHeader}_${subHeaderType}`);
};

export const getTabSubHeader = function getTabSubHeader(selectedTAB) {
  if (checkFlagCondition(selectedTAB)) {
    return getTabSubHeaderKey(selectedTAB);
  }
  return getTabSubHeaderKey(selectedTAB);
};
