import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import { findPolicyCheckValue } from '../utils/policyUtils';
import { doAND } from '../common/util/commonUtil';
import {
  setPolicyCheckValue,
  setPolicyState,
  setContactUsPopup
} from '../app/appActions';
import policySectionUtils from './sectionBuilderUtils/policySectionUtils';
import PolicyFieldsBuilder from './PolicyFieldsBuilder';

/**
 * Wrapper component to build all policy section based on the product family
 * And if product family doesn't exist in configuration then using DEFAULT config sections
 * @param {Object} props
 */

const checkForFundsSection = (
  suppressionFlag,
  policySection,
  policyDetails
) => {
  return (
    !suppressionFlag &&
    policySection === 'Funds' &&
    policyDetails &&
    !policyDetails.funds
  );
};

const checkForSectionFieldWrapper = (
  suppressionFlag,
  sectionFieldMapper,
  policySection
) => {
  return !suppressionFlag && !sectionFieldMapper[policySection];
};

const checkForSuppression = (
  suppressionFlag,
  policySectionTemplate,
  sectionFieldList,
  subSections
) => {
  return (
    (!suppressionFlag && !policySectionTemplate) ||
    (!suppressionFlag &&
      sectionFieldList &&
      sectionFieldList.length === 0 &&
      !subSections)
  );
};

const suppressBasedOnPolicyStatus = (suppressionFlag, showInPhase) => {
  return !suppressionFlag && !showInPhase;
};

const checkPolicyInGeneral = (isPolicyGeneral, showInGeneral) => {
  return (
    (isPolicyGeneral && showInGeneral) || (!isPolicyGeneral && !showInGeneral)
  );
};

const PolicySectionBuilder = props => {
  const {
    policyDetails,
    productCategory,
    productCategoryKey,
    sectionConfig,
    sectionFieldMapper,
    subSectionsMapper,
    subProductCategories,
    fieldConfig,
    selectedLocale,
    langObj,
    genericConfig,
    eStatementPreferenceConfig,
    policyTagsMedicalAndCIConfig,
    superFamily,
    stackingFamily,
    servicingOptionsURL,
    policyByEncodeId,
    policyChecks,
    commonToSections,
    sectionNotificationConfig,
    setPolicyCheckValues,
    isPolicyDetailsAvailableState,
    isPolicyExceptionalState,
    isPolicyLapsedState,
    dashboardCategory,
    summaryResponse,
    setPolicyStateValue,
    isPolicyGeneralValue,
    warningsOrErrors,
    faqSection,
    setContactUsPopupHandler,
    showContactUsFooterPopup,
    functionEligibility,
    encodedProductId,
    contactUsConfig,
    fundSelectionData,
    fundSelectionWarningsOrErrors,
    rpqUTBConfig,
    surrenderChargeConfig,
    customerWhiteListedForDigiServiceLinks,
    indicativeGainLossConfig,
    customer
  } = props;
  let isPolicyExceptional = false;
  let isPolicyLapsed = false;
  let isDetailsAvailable = false;
  let isPolicyGeneral = true;
  let suppressionFlag;
  const { getPolicySectionOrder, checkPolicyStatus } = policySectionUtils;
  const policySectionOrder = getPolicySectionOrder(
    dashboardCategory,
    sectionConfig
  );

  const openedPolicy = Object.keys(policyByEncodeId).filter(policy => {
    return _includes(window.location.href, policy);
  });

  /**
   * Fetching policy criterias from configuration i.e. from policyChecks
   */

  const {
    exceptionPolicyCheck,
    lapsedPolicyCheck,
    detailsCheck
  } = policyChecks;

  /**
   * Checking for Exceptional Policy via onHoldIndicator configured in the configurator.
   * Available in policyChecks object. Exception policy is when onHoldIndicator is true
   */

  isPolicyExceptional = findPolicyCheckValue(
    exceptionPolicyCheck,
    openedPolicy,
    policyByEncodeId
  );

  /**
   * Checking for Lapsed Policy via policyStatusCode in the configurator.
   * Available in policyChecks object. Lapsed is when policyStatusCode is LA
   */

  let policyStatus = null;
  policyStatus = findPolicyCheckValue(
    lapsedPolicyCheck,
    openedPolicy,
    policyByEncodeId
  );

  isPolicyLapsed = checkPolicyStatus(policyStatus);

  /**
   * Checking for Details Response availability by checking policyDetailsIndicator if available in summary response
   *
   */

  isDetailsAvailable = findPolicyCheckValue(
    detailsCheck,
    openedPolicy,
    policyByEncodeId,
    true
  );

  setPolicyCheckValues({
    isPolicyExceptional,
    isPolicyLapsed,
    isPolicyDetailsAvailable: isDetailsAvailable
  });

  if (isPolicyDetailsAvailableState === false) {
    isDetailsAvailable = false;
  }

  const sectionsStack = _map(policySectionOrder, policySection => {
    /** TODO: Move to configurator, As the async call for funds can fail */
    suppressionFlag = checkForFundsSection(
      suppressionFlag,
      policySection,
      policyDetails
    );
    suppressionFlag = checkForSectionFieldWrapper(
      suppressionFlag,
      sectionFieldMapper,
      policySection
    );

    const {
      [policySection]: {
        subSections,
        fields,
        template,
        headerContent,
        dependentSectionField,
        dependentSectionFieldTwo,
        dependentSectionFieldThree,
        dependentSectionFieldChild,
        dependentSectionFieldSurgical,
        showInException,
        showWhenLapsed,
        showInGeneral,
        dependentSectionFieldFour
      }
    } = sectionFieldMapper;

    let sectionFieldList = fields;

    if (subSections) {
      sectionFieldList = _map(
        subSections,
        subSectionInstance => subSectionsMapper[subSectionInstance].fields
      );
    }

    const policySectionTemplate = template;
    suppressionFlag = checkForSuppression(
      suppressionFlag,
      policySectionTemplate,
      sectionFieldList,
      subSections
    );

    /**
     * If policy is lapsed, omits the sections here which are not supposed to be shown on Lapsed state
     */
    if (doAND(!suppressionFlag, isPolicyLapsed)) {
      isPolicyGeneral = false;
      setPolicyStateValue({ isPolicyGeneral: false });
      suppressionFlag = suppressBasedOnPolicyStatus(
        suppressionFlag,
        showWhenLapsed
      );
    }

    /**
     * If policy doesn't have policy details in it, omits the sections here which are not supposed to be shown on Lapsed state
     */

    if (doAND(!suppressionFlag, isDetailsAvailable === false)) {
      isPolicyGeneral = false;
      setPolicyStateValue({ isPolicyGeneral: false });
      suppressionFlag = suppressBasedOnPolicyStatus(
        suppressionFlag,
        showInException
      );
    }

    /**
     * If policy is exceptional, omits the sections here which are not supposed to be shown on exceptional state
     */

    if (doAND(!suppressionFlag, isPolicyExceptional)) {
      isPolicyGeneral = false;
      setPolicyStateValue({ isPolicyGeneral: false });
      suppressionFlag = suppressBasedOnPolicyStatus(
        suppressionFlag,
        showInException
      );
    }

    if (suppressionFlag) {
      return null;
    }

    if (checkPolicyInGeneral(isPolicyGeneral, showInGeneral)) {
      return (
        <PolicyFieldsBuilder
          key={policySection}
          productCategory={productCategory}
          productCategoryKey={productCategoryKey}
          policySectionTemplate={policySectionTemplate}
          sectionFieldList={sectionFieldList}
          subProductCategories={subProductCategories}
          fieldConfig={fieldConfig}
          currentPolicyDetail={policyDetails}
          selectedLocale={selectedLocale}
          langObj={langObj}
          genericConfig={genericConfig}
          eStatementPreferenceConfig={eStatementPreferenceConfig}
          policyTagsMedicalAndCIConfig={policyTagsMedicalAndCIConfig}
          indicativeGainLossConfig={indicativeGainLossConfig}
          superFamily={superFamily}
          stackingFamily={stackingFamily}
          servicingOptionsURL={servicingOptionsURL}
          headerContent={headerContent}
          dependentSectionField={dependentSectionField}
          dependentSectionFieldTwo={dependentSectionFieldTwo}
          dependentSectionFieldThree={dependentSectionFieldThree}
          dependentSectionFieldChild={dependentSectionFieldChild}
          dependentSectionFieldSurgical={dependentSectionFieldSurgical}
          isPolicyDetailsAvailableState={isPolicyDetailsAvailableState}
          isPolicyExceptionalState={isPolicyExceptionalState}
          isPolicyLapsedState={isPolicyLapsedState}
          summaryResponse={summaryResponse}
          isPolicyGeneralValue={isPolicyGeneralValue}
          warningsOrErrors={warningsOrErrors}
          commonToSections={commonToSections}
          sectionNotificationConfig={sectionNotificationConfig}
          fundSelectionData={fundSelectionData}
          fundSelectionWarningsOrErrors={fundSelectionWarningsOrErrors}
          rpqUTBConfig={rpqUTBConfig}
          faqSection={faqSection}
          contactUsConfig={contactUsConfig}
          showContactUsFooterPopup={showContactUsFooterPopup}
          setContactUsPopupHandler={setContactUsPopupHandler}
          functionEligibility={functionEligibility}
          encodedProductId={encodedProductId}
          surrenderChargeConfig={surrenderChargeConfig}
          customerWhiteListedForDigiServiceLinks={
            customerWhiteListedForDigiServiceLinks
          }
          dependentSectionFieldFour={dependentSectionFieldFour}
          customer={customer}
        />
      );
    }
    return <React.Fragment key={policySection} />;
  });

  return <>{sectionsStack}</>;
};

PolicySectionBuilder.propTypes = {
  policyDetails: PropTypes.object,
  productCategory: PropTypes.string.isRequired,
  sectionConfig: PropTypes.object.isRequired,
  sectionFieldMapper: PropTypes.object.isRequired,
  subSectionsMapper: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired,
  eStatementPreferenceConfig: PropTypes.object.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired,
  indicativeGainLossConfig: PropTypes.object.isRequired,
  superFamily: PropTypes.string.isRequired,
  stackingFamily: PropTypes.string.isRequired,
  productCategoryKey: PropTypes.string.isRequired,
  servicingOptionsURL: PropTypes.object.isRequired,
  policyByEncodeId: PropTypes.object.isRequired,
  policyChecks: PropTypes.object.isRequired,
  commonToSections: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  fundSelectionData: PropTypes.object.isRequired,
  fundSelectionWarningsOrErrors: PropTypes.object.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired,
  isPolicyDetailsAvailableState: PropTypes.bool,
  isPolicyExceptionalState: PropTypes.bool.isRequired,
  isPolicyLapsedState: PropTypes.bool.isRequired,
  setPolicyCheckValues: PropTypes.func.isRequired,
  dashboardCategory: PropTypes.string.isRequired,
  summaryResponse: PropTypes.object.isRequired,
  setPolicyStateValue: PropTypes.func.isRequired,
  isPolicyGeneralValue: PropTypes.bool.isRequired,
  warningsOrErrors: PropTypes.array,
  faqSection: PropTypes.object,
  contactUsConfig: PropTypes.object,
  setContactUsPopupHandler: PropTypes.func.isRequired,
  showContactUsFooterPopup: PropTypes.bool.isRequired,
  functionEligibility: PropTypes.array,
  encodedProductId: PropTypes.string.isRequired,
  surrenderChargeConfig: PropTypes.object,
  customerWhiteListedForDigiServiceLinks: PropTypes.object,
  customer: PropTypes.object.isRequired
};

PolicySectionBuilder.defaultProps = {
  isPolicyDetailsAvailableState: false,
  policyDetails: null,
  warningsOrErrors: [],
  faqSection: {},
  contactUsConfig: {},
  functionEligibility: [],
  surrenderChargeConfig: {},
  customerWhiteListedForDigiServiceLinks: {}
};

const mapStateToProps = state => {
  const {
    configurations: {
      detailsConfig: {
        products,
        sections,
        fields,
        subSections,
        policyChecks,
        commonToSections
      },
      sectionNotificationConfig,
      genericConfig,
      eStatementPreferenceConfig
    },
    app: { showContactUsFooterPopup },
    customer,
    policy: { byFamily, byEncodeId, isSummaryCalled, isPolicyGeneral }
  } = state;
  return {
    policyByFamily: byFamily,
    policyByEncodeId: byEncodeId,
    sectionConfig: products,
    sectionFieldMapper: sections,
    subSectionsMapper: subSections,
    fieldConfig: fields,
    genericConfig,
    eStatementPreferenceConfig,
    policyChecks,
    commonToSections,
    sectionNotificationConfig,
    isSummaryCalled,
    isPolicyGeneralValue: isPolicyGeneral,
    showContactUsFooterPopup,
    customer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPolicyCheckValues: setPolicyCheckValue,
      setPolicyStateValue: setPolicyState,
      setContactUsPopupHandler: setContactUsPopup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicySectionBuilder);
