import * as images from '../../../../assets/images/Riders';

const riderIconMapping = {
  accident: { label: 'accident', srcIcon: images.accident },
  cancer: { label: 'cancer', srcIcon: images.cancer },
  female: { label: 'female', srcIcon: images.female },
  child: { label: 'child', srcIcon: images.child },
  'hospital & medical': {
    label: 'hospitalMedical',
    srcIcon: images.hospitalMedical
  },
  'critical illness': {
    label: 'criticalillness',
    srcIcon: images.criticalillness
  },
  'additional or advance death benefits': {
    label: 'deathBenefit',
    srcIcon: images.deathBenefit
  },
  'payment deferral or waiver': {
    label: 'paymentWaiver',
    srcIcon: images.paymentWaiver
  },
  'terminal illness': {
    label: 'terminalillness',
    srcIcon: images.terminalillness
  },
  'critical illness benefit (group)': {
    label: 'criticalillness',
    srcIcon: images.criticalillness
  },
  generic: { label: 'generic', srcIcon: images.generic }
};

export default riderIconMapping;
