export const errorWarningHandler = function errorWarningHandler(
  response,
  isSummaryCalled,
  RouteUrl
) {
  const { errorCode } = response[0];
  const { summary } = RouteUrl().forLink;
  switch (errorCode) {
    default:
      if (!isSummaryCalled) {
        window.location = summary;
        return false;
      }
      return true;
  }
};

export default errorWarningHandler;
