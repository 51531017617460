import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'core-js/stable';
import _ from 'lodash';
import { saasAuthGetInstance } from './utils/saasAuthModuleUtil';
import onContentData from './utils/contentManager';
import App from './app/App';
import Loader from './common/loader';
import {
  setSAASToken,
  bootstrapApplication,
  mergePreviewContent,
  setPolicySummary,
  setExternalConfiguration,
  setIsStubbed,
  setPolicyAnchoring
} from './app/appActions';
import configureStore from './store';
import { preparePoliciesStore } from './utils/policyUtils';
import { isApplicationStubbed } from './utils/routeUtils';

export const store = configureStore();

const DashboardApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

/**
 * CONTENT/CONFIGURATOR MANAGER
 *
 * @param: updatedJSON: content or configuration
 * @param: isConfig: boolean to identify if content or configuration type
 * */

// eslint-disable-next-line
onContentData(function(updatedJSON, isConfig = false) {
  if (!isConfig) {
    store.dispatch(mergePreviewContent(updatedJSON));
    return;
  }
  const state = store.getState();
  const {
    policy: { API_RESPONSE }
  } = state;
  store.dispatch(setExternalConfiguration(updatedJSON.configurator));
  const policySummaryPayload = preparePoliciesStore(
    updatedJSON.configurator.productConfig.subProductFamilies,
    updatedJSON.configurator.productConfig.productFamilyOrder,
    API_RESPONSE
  );
  policySummaryPayload.API_RESPONSE = API_RESPONSE;
  store.dispatch(setPolicySummary(policySummaryPayload));
});

let isSaasRequired = true;
if (isApplicationStubbed()) {
  isSaasRequired = false;
  store.dispatch(setIsStubbed());
}

if (isSaasRequired) {
  /**
   * Added the loader for better customer experience
   */
  ReactDOM.render(
    <Loader opacity={1} id="loadingText" />,
    document.getElementById('root')
  );
  const saasAuthModule = saasAuthGetInstance();
  saasAuthModule
    .authenticateOnPageLoad(window.location.href)
    .then(SECURED_AUTH_TOKEN => {
      const payload = SECURED_AUTH_TOKEN;
      const { href } = window.location;
      let url = href;
      url = `${url.split('?')[0]}`;
      window.history.replaceState({}, document.title, url);
      /**
       * SAAS authentication of the user is successful
       */
      if (SECURED_AUTH_TOKEN !== undefined) {
        store.dispatch(setSAASToken(payload));

        /**
         * Bootstrapping application with multiple necessary configuration APIs i.e. Content and Configurations(Product and Service).
         */

        store.dispatch(bootstrapApplication());

        /**
         * ANCHORING FEATURE FOR LANDING PAGE.
         * If the user comes through HSBC customer portal and has clicked on "My Insurance" link, the user must be
         * navigated to Dashboard Summary Page and anchored towards Policy Section under the Explore section.
         *
         * Checking here if the url has "policies" parameter. If it does,
         * maintaining a boolean flag @needToAnchorToPolicies
         * And then, anchoring by checking this flag on summary page load.
         * ----------------------------------------------------------------------------------------------------------
         */

        if (_.includes(window.location.href, 'policies')) {
          store.dispatch(setPolicyAnchoring());
        }
        ReactDOM.render(<DashboardApp />, document.getElementById('root'));
      }
    });
} else {
  store.dispatch(bootstrapApplication());
  ReactDOM.render(<DashboardApp />, document.getElementById('root'));
}

export default DashboardApp;
