import React from 'react';
import PropTypes from 'prop-types';

import { PolicyNameRow, PolicyNameHeader } from '../../detailPageStyle';
import ShowIf from '../../../utils/showIf';
import getDescription from '../../../utils/reducerUtils';

import { getPlanName } from '../../../utils/tealiumUtils';

const PolicyNameField = (props, context) => {
  const { fieldData } = props;
  const {
    intl: { locale }
  } = context;

  if (!fieldData || typeof fieldData !== 'object' || !fieldData.length) {
    return <></>;
  }

  const planName = fieldData;
  getPlanName(getDescription('en', planName)); // to get plan name only in English for tealium tagging
  return (
    <>
      <ShowIf condition={!!planName}>
        <PolicyNameRow>
          <PolicyNameHeader id="policy_name">
            {getDescription(locale, planName)}
          </PolicyNameHeader>
        </PolicyNameRow>
      </ShowIf>
    </>
  );
};

PolicyNameField.propTypes = {
  fieldData: PropTypes.array.isRequired
};

PolicyNameField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyNameField;
