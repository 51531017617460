import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  FundName,
  FundAllocation,
  FundNameSection
} from '../instructionHistoryStyle';
import getDescription from '../../utils/reducerUtils';

const FundNameAllocation = props => {
  const { locale, fundName, percent, labelId } = props;

  if (_.isEmpty(fundName)) {
    return null;
  }

  return (
    <FundNameSection>
      <FundName>{getDescription(locale, fundName)}</FundName>
      <FundAllocation>
        <FormattedMessage
          id={labelId}
          values={{
            percentage: percent
          }}
        />
      </FundAllocation>
    </FundNameSection>
  );
};

FundNameAllocation.propTypes = {
  locale: PropTypes.string.isRequired,
  fundName: PropTypes.array,
  percent: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired
};

FundNameAllocation.defaultProps = {
  fundName: []
};
export default FundNameAllocation;
