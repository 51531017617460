import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  FooterWrapper,
  FooterContainer,
  FooterText,
  FooterSubText,
  FooterSubLinkText
} from './FooterStyles';
import ContactUsFooter from './ContactUsFooter';
import theme from '../../../common/styles/theme';

const getCrossBorderLink = function getCrossBorderLink(productLink, locale) {
  return productLink[locale];
};

const Footer = ({
  showContactUsFooterPopup,
  setContactUsPopupHandler,
  footerCrossBorderURL,
  selectedLanguage
}) => {
  const crossBorderUrl = getCrossBorderLink(
    footerCrossBorderURL.url,
    selectedLanguage
  );
  return (
    <>
      <FooterWrapper bgColor={theme.contactBg}>
        <ContactUsFooter
          showContactUsFooterPopup={showContactUsFooterPopup}
          setContactUsPopupHandler={setContactUsPopupHandler}
        />
      </FooterWrapper>
      <FooterWrapper bgColor={theme.blackColor}>
        <FooterContainer>
          <FooterText id="footerText">
            <FormattedMessage
              id="footer"
              values={{
                year: new Date().getFullYear()
              }}
              defaultMessage="defaultMsg"
            />
          </FooterText>
          <FooterSubText>
            <FormattedMessage id="footerSubText" />
            <FooterSubLinkText
              id="crossBorderLink"
              data-testid="crossBorderLink"
              href={crossBorderUrl}
              rel={footerCrossBorderURL.rel}
              target={footerCrossBorderURL.target}
            >
              <FormattedMessage id="footerSubTextLink" />
            </FooterSubLinkText>
          </FooterSubText>
        </FooterContainer>
      </FooterWrapper>
    </>
  );
};

export default Footer;

Footer.propTypes = {
  showContactUsFooterPopup: PropTypes.bool.isRequired,
  setContactUsPopupHandler: PropTypes.func.isRequired,
  footerCrossBorderURL: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired
};
