import _ from 'lodash';
import {
  truncDecimalIfGreaterThanBuffer,
  formatCurrency
} from '../../utils/formatter';

const keyValueAPIValueConditions = [
  {
    // If keyValue is number, and API Value is number then check if APIValue is greater than keyValue
    condition: keyValue => _.isNumber(keyValue),
    action: (keyValue, APIValue) => _.isNumber(APIValue) && APIValue > keyValue
  },
  {
    // If keyValue is array then check APIValue exists in keyValue
    condition: keyValue => _.isArray(keyValue),
    action: (keyValue, APIValue) => _.includes(keyValue, APIValue)
  }
];

export const compareKeyValueToAPIValue = function compareKeyValueToAPIValue(
  keyValue,
  APIValue
) {
  let validationItem;
  // Validate key value and API value in order
  for (let i = 0; i < keyValueAPIValueConditions.length; i += 1) {
    validationItem = keyValueAPIValueConditions[i];
    if (validationItem.condition(keyValue, APIValue)) {
      return validationItem.action(keyValue, APIValue);
    }
  }
  // If the above conditions are not true, then default check to exact match keyValue with APIValue
  return _.camelCase(keyValue) === _.camelCase(APIValue);
};

export const getEveryKeyCheck = (fieldsArray, reprojectionsDetails) => {
  if (fieldsArray) {
    const { checksToMatch = [] } = fieldsArray;
    if (checksToMatch.length > 0) {
      return checksToMatch.every(check => {
        const { fieldParent, keyName, keyValue } = check;
        const fieldIdentifierKey = `${fieldParent}.${keyName}`;
        const getAPIValue = _.get(reprojectionsDetails, fieldIdentifierKey);
        return compareKeyValueToAPIValue(keyValue, getAPIValue);
      });
    }
  }
  return true;
};

export const conditionalFieldAPIValue = (fieldName, fieldAPIValue) =>
  fieldName === 'customerAge'
    ? Math.min(99 - fieldAPIValue, 25)
    : fieldAPIValue;

export const getFieldsReferredValues = (
  reprojectionDetails,
  fieldsReferredArr,
  context
) => {
  let fieldsArray = [];
  let allValuesExist = true;
  _.map(fieldsReferredArr, (field, index) => {
    const {
      fieldParent,
      fieldName,
      fieldLabel,
      decimalRange,
      toFormat = false,
      conversion
    } = field;
    let fieldAPIValue = _.get(
      reprojectionDetails,
      `${fieldParent}.${fieldName}`
    );
    if (conversion === 'locale') {
      const {
        intl: { messages, formatMessage }
      } = context;
      if (!!messages[fieldAPIValue] && messages[fieldAPIValue] !== '') {
        fieldAPIValue = formatMessage({ id: fieldAPIValue });
      } else {
        fieldAPIValue = null;
      }
    }
    if (fieldAPIValue === undefined || fieldAPIValue === null) {
      allValuesExist = false;
    }
    if (allValuesExist) {
      if (typeof fieldAPIValue === 'string' && fieldAPIValue.trim() === '') {
        fieldAPIValue = '0';
      }
      const fieldLabelValue = {
        fieldLabel,
        fieldValue: conditionalFieldAPIValue(fieldName, fieldAPIValue),
        toFormat
      };

      if (decimalRange) {
        fieldLabelValue.decimalRange = decimalRange;
      }
      fieldsArray[index] = fieldLabelValue;
    } else {
      fieldsArray = [];
    }
  });
  return fieldsArray;
};

export const generateDynamicKey = (
  hasMultipleKeys,
  configContentKey,
  selectedTab,
  planCodeTypeCheck,
  reprojectionsDetails
) => {
  if (!_.isEmpty(planCodeTypeCheck)) {
    const {
      planCodeList,
      contentPlanCodeKey,
      apiKeyToCheck
    } = planCodeTypeCheck;
    const apiValue = _.get(reprojectionsDetails, apiKeyToCheck);
    if (_.includes(planCodeList, apiValue)) {
      if (hasMultipleKeys && _.isObject(contentPlanCodeKey)) {
        return contentPlanCodeKey[selectedTab];
      }
      return contentPlanCodeKey;
    }
    if (hasMultipleKeys && _.isObject(configContentKey)) {
      return configContentKey[selectedTab];
    }
    return configContentKey;
  }

  if (hasMultipleKeys && _.isObject(configContentKey)) {
    return configContentKey[selectedTab];
  }
  return configContentKey;
};
export const getPlanCodeDefaultKey = (
  defaultContentKey,
  planCodeTypeCheck,
  reprojectionsDetails
) => {
  if (_.isEmpty(planCodeTypeCheck)) {
    return defaultContentKey;
  }
  const { planCodeList, defaultPlanCodeKey, apiKeyToCheck } = planCodeTypeCheck;
  const apiValue = _.get(reprojectionsDetails, apiKeyToCheck);
  if (_.includes(planCodeList, apiValue)) {
    return defaultPlanCodeKey;
  }
  return defaultContentKey;
};
export const getOptimisticNotesObj = (
  list,
  reprojectionsDetails,
  selectedFutureBenefitsTab
) => {
  const { optimisticNotesConditions = [] } = list;

  const optimisticObj = { contentId: '', fieldsReferredValues: [] };

  for (
    let optimisticNote = 0;
    optimisticNote < optimisticNotesConditions.length;
    optimisticNote += 1
  ) {
    if (
      getEveryKeyCheck(
        optimisticNotesConditions[optimisticNote],
        reprojectionsDetails
      )
    ) {
      const {
        ifTabSelected,
        fieldsReferred,
        contentKey,
        hasMultipleKeys = false,
        defaultContentKey,
        planCodeTypeCheck = {}
      } = optimisticNotesConditions[optimisticNote];
      if (_.includes(ifTabSelected, selectedFutureBenefitsTab)) {
        optimisticObj.fieldsReferredValues = getFieldsReferredValues(
          reprojectionsDetails,
          fieldsReferred
        );
        optimisticObj.contentId =
          optimisticObj.fieldsReferredValues &&
          optimisticObj.fieldsReferredValues.length > 0
            ? generateDynamicKey(
                hasMultipleKeys,
                contentKey,
                selectedFutureBenefitsTab,
                planCodeTypeCheck,
                reprojectionsDetails
              )
            : getPlanCodeDefaultKey(
                defaultContentKey,
                planCodeTypeCheck,
                reprojectionsDetails
              );
      }
      break;
    }
  }
  return optimisticObj;
};

export const getKeyBasedFormattedValues = (toFormat, fieldValue) => {
  if (toFormat) {
    return formatCurrency(fieldValue, 0);
  }
  return fieldValue;
};

export const getInterestRateObj = fieldsReferredValues => {
  const fieldsObj = {};
  _.map(fieldsReferredValues, field => {
    const { fieldLabel, fieldValue, decimalRange, toFormat } = field;
    fieldsObj[`${fieldLabel}`] = decimalRange
      ? truncDecimalIfGreaterThanBuffer(fieldValue, decimalRange)
      : getKeyBasedFormattedValues(toFormat, fieldValue);
  });
  return fieldsObj;
};

export const getListOfExplanationNotes = (
  explanatoryNoteConfigAPI,
  explanatoryDefinitions
) => {
  let listOfExplanationNotes = [];
  _.each(explanatoryNoteConfigAPI, explanatoryNoteConfig => {
    if (explanatoryDefinitions[explanatoryNoteConfig]) {
      listOfExplanationNotes = _.union(
        listOfExplanationNotes,
        explanatoryDefinitions[explanatoryNoteConfig]
      );
    }
  });
  return listOfExplanationNotes;
};

export const orderExplanationNotes = (explanationNotesList, order) => {
  const orderedList = [];
  _.each(order, textDefinition => {
    if (_.includes(explanationNotesList, textDefinition)) {
      orderedList.push(textDefinition);
    }
  });
  return orderedList;
};

export const getAmountLabel = (
  fieldsReferred,
  reprojectionsDetails,
  context
) => {
  const {
    intl: { messages, formatMessage }
  } = context;
  const referredValueObj = {};
  _.each(fieldsReferred, fieldReferred => {
    const { fieldParent, fieldName, fieldLabel, defaultLabel } = fieldReferred;
    referredValueObj[fieldLabel] = _.toLower(
      formatMessage({ id: defaultLabel })
    );
    const fieldIdentifierKey = `${fieldParent}.${fieldName}`;
    const getAPIValue = _.get(reprojectionsDetails, fieldIdentifierKey);
    if (getAPIValue !== undefined) {
      const labelContent = `${defaultLabel}_${getAPIValue}`;
      if (!!messages[labelContent] && messages[labelContent] !== '') {
        referredValueObj[fieldLabel] = _.toLower(
          formatMessage({ id: labelContent })
        );
      }
      return false;
    }
    return true;
  });
  return referredValueObj;
};

export const checkPlanCodes = (fieldsReferred, reprojectionsDetails) => {
  let ifPlanCodeMatch = false;
  _.each(fieldsReferred, fieldReferred => {
    const { fieldParent, fieldName, planCodesToCheck } = fieldReferred;
    const fieldIdentifierKey = `${fieldParent}.${fieldName}`;
    const getAPIValue = _.get(reprojectionsDetails, fieldIdentifierKey);
    ifPlanCodeMatch = _.includes(planCodesToCheck, getAPIValue);
  });
  return ifPlanCodeMatch;
};

export const checkIfPlanCodeMatch = (
  planCodesToCheck,
  reprojectionsDetails,
  fieldValue
) => {
  const apiValue = _.get(reprojectionsDetails, fieldValue);
  return _.includes(planCodesToCheck, apiValue);
};
