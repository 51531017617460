import styled, { css } from 'styled-components';
import { rem, em } from 'polished';
import theme from './theme';
import media from '../../utils/media';
import { FontFamily } from '../../utils/font';

export const ReprojectionHorizontalLine = styled.div`
  margin: 0;
  ${media.phone`
    margin: 0 ${rem('15px')};
  `}
  border-top: 1px solid ${theme.lightGreyColor};
`;

export const ReprojectionTableWrapper = styled.div`
  box-sizing: border-box;
  padding-bottom: ${rem('40px')};
  ${media.tablet`
    margin: ${rem('24px')} 0 0 0;
    padding-bottom: ${rem('30px')};
  `};

  ${media.mediumTablet`
    margin: ${rem('24px')} 0 0 0;
    padding-bottom: ${rem('30px')};
  `}
  ${media.phone`
    margin: ${rem('15px')} 0 0 0;
    padding-bottom: ${rem('20px')};
  `}
`;
export const ReprojectionTable = styled.table`
  width: 100%;
  border-spacing: inherit;
  border: 1px solid ${theme.detailNotificationBorder};
  ${media.tablet`
    height: auto;
`}
`;
export const TableHead = styled.thead`
  background-color: ${theme.stepDefault};
`;
export const TableHeader = styled.th`
  margin: 0;
  padding: ${rem('12px')} ${rem('15px')};
  border-right: 1px solid ${theme.detailNotificationBorder};
  border-top: ${props =>
    props.nosubcolumns
      ? 'none'
      : `1px solid ${theme.detailNotificationBorder}`};
  font-size: ${em('16px')};
  line-height: ${rem('24px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: ${props => props.align};
  color: ${theme.fontColor};
  width: ${rem('147px')};
  &:last-child {
    border-right: 0;
  }
  ${media.tablet`
    display: flex;
    padding: ${rem('14px')} ${rem('20px')} ${rem('14px')} ${rem('12px')};
    width: 100%;
    box-sizing: border-box;
    border-top: none;
  `};
  ${media.phone`
    padding: 2%;
    line-height: 16px;
  `}
`;
export const TableBody = styled.tbody``;
export const TableRow = styled.tr`
  vertical-align: baseline;
  &#headerRow_0 {
    th {
      border-top: 0;
    }
  }
  ${media.tablet`
      height: auto;
      width: 100%;
      border-bottom: 1px solid ${theme.detailNotificationBorder};
      background: ${props =>
        props.selectedRow || props.id === 'headerId'
          ? `${theme.stepDefault}`
          : `${theme.whiteColor}`}
`};
`;

export const TableData = styled.td`
  margin: 0;
  padding: ${rem('12px')} ${rem('15px')};
  font-size: ${em('16px')};
  line-height: ${rem('24px')};
  border-right: 1px solid ${theme.detailNotificationBorder};
  border-top: 1px solid ${theme.detailNotificationBorder};
  text-align: ${props => (props.type === 'Number' ? 'right' : 'left')};
  &:last-child {
    border-right: 0;
  }
  &:focus {
    outline: 1px dotted black;
  }
  ${media.tablet`
    width: 100%;
    border-right: none;
    padding: ${rem('14px')} ${rem('20px')} ${rem('14px')} ${rem('12px')};
    height: auto;
    font-size: ${rem('12px')};
    color: ${theme.fontColor};
    border-top:none;
  `};
  ${media.phone`
    padding: 2%;
  `}
`;
const TableBodyTDStyle = css`
  margin: 0;
  padding: ${rem('12px')} ${rem('15px')};
  font-size: ${em('16px')};
  line-height: ${rem('24px')};
  border-right: 1px solid ${theme.detailNotificationBorder};
  border-top: 1px solid ${theme.detailNotificationBorder};
  &:last-child {
    border-right: 0;
  }
  &:focus {
    outline: 1px dotted black;
  }
`;

export const TableDynamicHeader = props => {
  if (props.currentCellIndex === 0) {
    return styled.th`
${TableBodyTDStyle}
  text-align: ${props.type === 'Number' ? 'right' : 'left'};
  font-weight: normal;
`;
  }
  return styled.td`
  ${TableBodyTDStyle}
  text-align: ${props.type === 'Number' ? 'right' : 'left'};
  `;
};
export const TableFooter = styled.tfoot`
  cursor: pointer;
  tr {
    width: 100%;
    height: auto;
    td {
      text-align: center;
    }
  }
`;

export const TableDataParent = styled.div`
  ${media.tablet`
    display: flex;
  `};
`;
// table body div
export const TableDataContainer = styled.span`
  ${media.tablet`
    height: auto;
    width:auto;
    display: ${props => (props.index === 0 ? 'flex' : 'inline')};
    align-items: ${props => (props.index === 0 ? 'center' : '')};
    text-align: ${props => (props.type === 'Number' ? 'right' : 'left')};
    margin: ${props => (props.index !== 0 ? 'auto' : 0)};
    margin-right: 0;
  `};
  ${media.phone`
    display: ${props => (props.index === 0 ? 'flex' : 'inline')};
    align-items: ${props => (props.index === 0 ? 'center' : '')};
  `}
`;
export const TableDataTd = styled.td`
  ${media.tablet`
    height: auto;
    width: ${props =>
      props.index === 0 ? `18%` : `${82 / props.widthModulator}%`};
    text-align: ${props => (props.type === 'Number' ? 'right' : 'left')};
    margin: ${props => (props.index !== 0 ? 'auto' : 0)};
    padding: ${props =>
      props.index !== 0
        ? `12px ${rem('20px')}`
        : `12px ${rem('20px')} 12px ${rem('15px')}`};
    margin-right: 0;
    border-bottom:${props =>
      props.expanded ? '' : `1px solid ${theme.detailNotificationBorder}`};
  `};
  ${media.phone`
    width: ${props =>
      props.index === 0 ? `19%` : `${70 / props.widthModulator}%`};
      padding: ${props => (props.index === 0 ? `10px 0 10px 8px` : `10px`)};
      @media (max-width: 375px) {
        padding: ${props => (props.index === 0 ? `10px 0 10px 6px` : `10px`)};
        width: ${props =>
          props.index === 0 ? `16%` : `${84 / props.widthModulator}%`};
      }
      @media (max-width: 374px) {
        padding: ${props =>
          props.index === 0 ? `10px 0 10px 6px` : `10px 7px 10px 2px`};
      }
  `}
`;
export const HeaderDiv = styled.div`
  display: flex;
  text-align: ${props => (props.fieldType === 'Number' ? 'right' : 'left')};
  justify-content: ${props =>
    props.fieldType === 'Number' ? 'flex-end' : 'flex-start'};
  word-break: initial;
`;
export const HeaderTh = styled.th`
  width: ${props =>
    props.index === 0 ? `18%` : `${82 / props.widthModulator}%`};
  word-break: initial;
  padding: ${rem('14px')} ${rem('20px')} ${rem('14px')} ${rem('12px')};
  box-sizing: border-box;
  border-top: none;
  font-weight: inherit;
  border-bottom: 1px solid ${theme.detailNotificationBorder};
  ${media.phone`
    width: ${props =>
      props.index === 0 ? `16%` : `${84 / props.widthModulator}%`};
      padding: ${props => (props.index === 0 ? `10px 0 10px 25px` : `10px`)};
      @media (max-width: 375px) {
        padding: ${props => (props.index === 0 ? `10px 0 10px 15px` : `10px`)};
      }
      @media (max-width: 374px) {
        padding: ${props =>
          props.index === 0 ? `10px 0 10px 15px` : `10px 7px 10px 2px`};
      }
  `}
`;

export const HeaderSpan = styled.span`
  ${media.tablet`
    ${FontFamily.RegularFontW01};
    color:${theme.fontColor};
    font-size: ${rem('14px')};
    color: ${theme.fontColor};
    padding-left: ${props => (props.index === 0 ? `${rem('17px')}` : '')};
    line-height:${rem('16px')};
  `};
  ${media.phone`
      padding-left:0;
      font-size: ${rem('12px')};
    `}
`;

export const DataSpan = styled.span`
  ${media.tablet`
    max-width: ${rem('110px')};
    height: auto;
    font-size: ${rem('14px')};
    line-height: 1.5;
    ${FontFamily.RegularFontW01};
  `};
  ${media.phone`
    max-width: ${rem('49px')};
    font-size: ${rem('12px')};
  `}
`;

export const TableDataCollapsable = styled.dl`
  ${media.tablet`
     padding: 0 ${rem('20px')} ${rem('20px')} ${rem('20px')};
     background-color: ${theme.stepDefault};
     div:nth-of-type(2){
      margin-top: 9px;
     }
  `};
  ${media.phone`
  padding: 0 ${rem('10px')} ${rem('10px')} ${rem('10px')};
  `}
`;

export const DataCollapsableParent = styled.div`
  background: ${theme.whiteColor};
  display: flex;
  flex-wrap: wrap;
  ${media.tablet`
    padding: ${rem('11px')} ${rem('20px')};
  `}
  ${media.phone`
      padding: 10px;
  `}
`;

export const DataCollapsableHeader = styled.h3`
  ${FontFamily.BoldFontW01};
  width: 100%;
  height: auto;
  font-size: ${rem('14px')};
  color: ${theme.fontColor};
  margin-bottom: 9px;
  ${media.phone`
  font-size: ${rem('12px')};
  `}
`;

export const DataCollapsableBody = styled.div`
  margin-top: ${rem('9px')};
  display: flex;
  color: ${theme.fontColor};
`;

export const CollapsableSpanHeader = styled.dt`
  width: 50%;
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  margin-bottom: 9px;
  ${media.phone`
  font-size: ${rem('12px')};
`}
`;

export const CollapsableDivData = styled.dd`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 9px;
`;
export const CollapsableSpanData = styled.span`
  text-align: right;
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  ${media.phone`
    font-size: ${rem('12px')};
  `}
`;

export const ChevronImage = styled.img`
  ${media.tablet`
    width: ${rem('6px')};
    height: ${rem('4px')};
    ${FontFamily.RegularFontW01};
  `};
  ${media.phone`
    margin-right: 12%;
  `}
`;
export const AccordionButton = styled.button`
  ${FontFamily.RegularFontW01};
  background: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  font-size: ${em('14px')};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  color: #333333;
  margin-right: 10px;
  @media (max-width: 375px) {
    margin-right: 6px;
  }
`;
export const TableShowMoreLessWrapper = styled.span`
  display: flex;
  justify-content: center;
  font-size: ${rem('16px')};
  ${media.tablet`
    font-size: ${rem('14px')};
`};
  ${media.phone`
    font-size: ${rem('12px')};
`};
`;

export const TableShowMoreLessIcon = styled.img`
  max-width: 100%;
  height: auto;
  width: ${rem('15px')};
  height: ${rem('15px')};
  margin-right: ${rem('8px')};
  ${media.tablet`
    width: ${rem('12px')};
    height: ${rem('12px')};
`};
`;

export const TableHeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${rem('38px')} 0 ${rem('22px')} 0;
  ${media.tablet`
  margin: ${rem('28px')} 0 ${rem('22px')} 0;
  `};
  ${media.phone`
  margin: ${rem('20px')} 0;
  padding: 0 ${rem('15px')};
  `};
`;
export const TableHeaderText = styled.h3`
  ${FontFamily.LightFontW01};
  font-size: ${em('28px')};
  line-height: ${rem('32px')};
  ${media.phone`
  font-size: ${rem('19px')};
  line-height: ${rem('28px')};
  margin-bottom: 7px;
  `};
`;
export const CurrencyLabel = styled.span`
  ${FontFamily.RegularFontW01};
  font-size: ${em('16px')};
  text-align: right;
  ${media.phone`
font-size: ${rem('14px')};
  `};
`;
export const CurrencyFormat = styled.span`
  margin-left: 5px;
`;
export const CurrencySymbol = styled.span`
  margin-left: 5px;
`;

export const TableDataFieldSpan = styled.span`
  display: block;
  margin-bottom: 3px;
`;
export const TableDataValueSpan = styled.span`
  display: block;
  ${FontFamily.RegularFontW01};
  font-size: ${em('14px')};
  line-height: ${rem('20px')};
  letter-spacing: 0;
  ${media.tablet`
    font-size: ${rem('14px')};
    line-height: ${rem('18px')};
  `}
  ${media.phone`
    font-size: ${rem('12px')};

  `}
`;
export const TableCaption = styled.caption`
  text-align: left;
  margin: 5px 0;
`;
export const TableCaptionMobile = styled.caption`
  text-align: left;
  margin: 5px 0;
  ${media.phone`
  margin: 0 0 20px 0;
  `}
`;
export const TableHeaderMobile = styled(TableHeaderTextWrapper)`
  ${media.phone`
  margin: 0;
  padding: 0 ${rem('15px')};
  flex-direction: column;
    align-items: baseline;
  `};
`;
export const TrAccordion = styled.tr``;
export const TdAccordion = styled.td`
  background-color: ${theme.stepDefault};
`;
