import PropTypes from 'prop-types';

const WhoIsCoveredString = function WhoIsCoveredString(props) {
  const { fieldData } = props;

  if (!fieldData) {
    return '-';
  }
  return fieldData;
};

WhoIsCoveredString.propTypes = {
  fieldData: PropTypes.string.isRequired
};

export default WhoIsCoveredString;
