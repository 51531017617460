import * as _ from 'lodash';
import getDescription from '../../utils/reducerUtils';

export const returnsHeaderResolver = function returnsHeaderResolver(
  annuityPhaseIndicator,
  product,
  title
) {
  const annuityPhaseIndicatorValue = annuityPhaseIndicator || '';

  /**
   * Annuity Products would have annuityPhaseIndicators as well appended at the end, whereas for other products,
   * it will remain blank and only "returnsHeader{ProductName}" will be taken up
   */

  return _.camelCase(`${title}${product}${annuityPhaseIndicatorValue}`);
};

export const returnsHeaderTooltipResolver = function returnsHeaderTooltipResolver(
  product,
  title
) {
  /**
   * function will return key "returnsHeaderTooltip{ProductName}" will be taken up for tooltip content
   */

  return _.camelCase(`${title}${product}`);
};

export const checkAmountFieldEmpty = (field, checkTotal = false) => {
  if (field) {
    const { props } = field;
    const { fieldData } = props;
    if (
      !checkTotal &&
      (typeof fieldData !== 'object' ||
        !fieldData.amount ||
        !fieldData.currency)
    ) {
      return true;
    }
  } else {
    return true;
  }
  return false;
};

export function checkToggleSectionToSuppress(
  calculatedFields = [],
  loanAmountFields = []
) {
  let ifNoGCV = false;

  const suppressFieldsList = [];
  calculatedFields.forEach(field => {
    if (field) {
      const { props } = field;
      if (props.isMandatoryToShow && !props.fieldData) {
        ifNoGCV = true;
        suppressFieldsList.push(null);
      } else if (ifNoGCV) {
        const fieldData = checkAmountFieldEmpty(field);
        if (fieldData) {
          suppressFieldsList.push(fieldData);
        }
      }
    } else {
      suppressFieldsList.push(null);
    }
  });
  loanAmountFields.forEach(field => {
    if (field) {
      if (ifNoGCV) {
        const fieldData = checkAmountFieldEmpty(field);
        if (fieldData) {
          suppressFieldsList.push(fieldData);
        }
      }
    } else {
      suppressFieldsList.push(null);
    }
  });
  const concatList = calculatedFields.concat(loanAmountFields);
  return concatList.length === suppressFieldsList.length;
}

export function hasArrayAllNulls(arrary) {
  return arrary.every(value => value === null);
}

export const getTooltipContentReturns = function getTooltipContentReturns(
  fieldConfigInstance,
  labelContent
) {
  return fieldConfigInstance.toolTip ? `${labelContent}_OptionTooltip` : null;
};

export const getPolicyCurrency = function getPolicyCurrency(
  currentPolicyDetail
) {
  return (
    currentPolicyDetail &&
    currentPolicyDetail.policyOverview &&
    currentPolicyDetail.policyOverview.policyCurrency
  );
};

export const getLabelOptionReturns = function getLabelOptionReturns(
  dependentFieldDataTwo,
  labelContent,
  locale
) {
  return dependentFieldDataTwo
    ? getDescription(locale, dependentFieldDataTwo)
    : `${labelContent}_OptionLabel`;
};

export const returnsAfterAnnuityText = function returnsAfterAnnuityText(
  displayAnnuityStatement,
  formatMessage
) {
  return (
    displayAnnuityStatement &&
    formatMessage({ id: 'returnsAfterAnnuityStatement' })
  );
};

export const getOptionArrayStack = function getOptionArrayStack(
  optionsArrayStack
) {
  return optionsArrayStack || [];
};

export const checkForSuppression = function checkForSuppression(
  currentPolicyDetail,
  sectionFieldConfig
) {
  return (
    !currentPolicyDetail || !sectionFieldConfig || sectionFieldConfig.length < 1
  );
};

export const getTotalPolicyComponent = function getTotalPolicyComponent(
  fieldsStack
) {
  return fieldsStack && fieldsStack[0] && fieldsStack[0][0];
};

export const getTableFields = function getTableFields(fieldsStack, index) {
  return fieldsStack && fieldsStack[index];
};

export const checkFieldArrayNull = function checkFieldArrayNull(fieldsStack) {
  return fieldsStack && !hasArrayAllNulls(fieldsStack);
};

export const checkAnnuityPhaseIndicator = function checkAnnuityPhaseIndicator(
  annuityPhaseIndicator
) {
  return annuityPhaseIndicator && annuityPhaseIndicator !== '';
};

export const checkoptionsArrayStack = function checkoptionsArrayStack(
  optionsArrayStack
) {
  return optionsArrayStack && optionsArrayStack.length > 0;
};

export const generateLabelWithTooltipForJW1 = function generateLabelWithTooltipForJW1(
  planCode,
  labelContent,
  toolTipContent,
  planCodesToCheck
) {
  const isPlanCodeJW1 = _.includes(planCodesToCheck, planCode);
  return {
    label:
      isPlanCodeJW1 && labelContent && labelContent === 'specialBonusLabel'
        ? `specialBonusLabel_${planCode}`
        : labelContent,
    tooltip:
      isPlanCodeJW1 &&
      toolTipContent &&
      toolTipContent === 'specialBonusTooltip'
        ? `specialBonusTooltip_${planCode}`
        : toolTipContent
  };
};
