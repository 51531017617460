import styled from 'styled-components';
import { em, rem } from 'polished';
import { FontFamily } from '../../../utils/font';
import media from '../../../utils/media';
import theme from '../../../common/styles/theme';

export const ServiceDownHeading = styled.h1`
  ${FontFamily.LightFontW01};
  color: ${theme.fontColor};
  font-size: ${em('38px')};
  margin: ${rem('40px')} 0 ${rem('20px')} 0;
  ${media.tablet`
    font-size: ${em('32px')};
    margin: ${rem('36px')} 0 ${rem('20px')} 0;
    `};
  ${media.phone`
    font-size: ${em('32px')};
    margin: ${rem('25px')} 0;
    `};
`;
export const BackLinkDiv = styled.div`
  margin: ${rem('40px')} 0 30% 0;
  ${media.tablet`
margin: ${rem('30px')} 0 30% 0;
    `};
  ${media.phone`
margin: ${rem('20px')} 0 30% 0;
`};
`;
export const IconSpan = styled.span`
  height: 12px;
  width: 8px;
  display: block;
  margin-right: 5px;
`;
export const BackLink = styled.a`
  ${FontFamily.RegularFontW01};
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  margin: ${rem('10px')} 0;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: baseline;
  span {
    cursor: pointer;
  }
  position: relative;
  img {
    transform: rotate(180deg);
  }
  &:hover,
  &:focus {
    text-decoration: ${props => (props.iconText ? `none` : `underline`)};
  }
  &:focus {
    text-decoration: underline;
    outline: 0;
  }
`;
