import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { personInsuredGenerator } from './templateUtils';
import {
  LeftColumn,
  ProjectStatusField,
  ProductCodeField,
  PolicyNumberWrapper,
  PolicyNumberLabel,
  PolicyNumberField,
  CardFlexContainer,
  StatusBulb,
  StatusLabel,
  CardStatementsWrapper,
  RedHR,
  PersonInsuredField,
  PersonInsuredLabel,
  PersonInsuredValues,
  PolicyCardTag
} from '../summaryPageStyles';
import { ShowIfDesktop, ShowIfMobile } from '../../utils/showIf';
import NextActionMessage from '../components/NextActionMessage';
import getDescription from '../../utils/reducerUtils';
import SeeDetailsLink from '../components/SeeDetailsLink';
import { getPolicyStatusCopyLabel } from '../../utils/policyUtils';

const GITemplate = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;

  const {
    productFieldsObject,
    superCategory,
    productCategory,
    encodePolicyNumber,
    colorCodesForPolicyStatus,
    notificationMessages,
    policyOnHold,
    eWelcomePack
  } = props;

  const {
    field6: { conditionalFieldValueOne, conditionalFieldValueTwo }
  } = productFieldsObject;

  const policyStatusLabelId = getPolicyStatusCopyLabel(
    productFieldsObject.field1.value
  );

  let showWelcomePack = false;
  let currentCustomerPolicy = null;
  eWelcomePack.map(eWelcome => {
    if (
      eWelcome.showWelcomePack &&
      eWelcome.encodePolicyNumber === encodePolicyNumber
    ) {
      showWelcomePack = true;
      const { customerPolicy } = eWelcome;
      currentCustomerPolicy = customerPolicy;
    }
    return false;
  });

  return (
    <>
      {productFieldsObject.field1 &&
        productFieldsObject.field1.showOnUI &&
        productFieldsObject.field1.value !== undefined && (
          <ProjectStatusField>
            <StatusBulb
              colorCode={
                colorCodesForPolicyStatus[productFieldsObject.field1.value]
              }
              aria-hidden="true"
            />
            <StatusLabel
              id={`policy_status_${productFieldsObject.field1.value}`}
            >
              {policyStatusLabelId &&
                formatMessage({
                  id: policyStatusLabelId
                })}
            </StatusLabel>
          </ProjectStatusField>
        )}
      <CardFlexContainer>
        <LeftColumn>
          {productFieldsObject.field2 &&
            productFieldsObject.field2.showOnUI &&
            productFieldsObject.field2.value && (
              <>
                <ProductCodeField
                  id={`plan_code_${productFieldsObject.field3.value}`}
                >
                  {getDescription(locale, productFieldsObject.field2.value)}
                  {(process.env.REACT_APP_ENV === 'local' ||
                    process.env.REACT_APP_ENV === 'dev') &&
                    productCategory &&
                    ` (${productCategory})`}
                  {showWelcomePack ? (
                    <PolicyCardTag>
                      <FormattedMessage
                        id="newPolicy"
                        defaultMessage="New Policy"
                      />
                    </PolicyCardTag>
                  ) : null}
                </ProductCodeField>
              </>
            )}

          {productFieldsObject.field3 &&
            productFieldsObject.field3.showOnUI &&
            productFieldsObject.field3.value && (
              <PolicyNumberWrapper>
                <PolicyNumberLabel id="policyNumber">
                  {formatMessage({ id: 'policyNumberFieldLabel' })}
                </PolicyNumberLabel>
                <PolicyNumberField
                  id={`policy_number_${productFieldsObject.field3.value}`}
                >
                  {productFieldsObject.field3.value}
                </PolicyNumberField>
              </PolicyNumberWrapper>
            )}

          <ShowIfDesktop>
            {productFieldsObject.field12 &&
              productFieldsObject.field12.value &&
              productFieldsObject.field12.showOnUI &&
              personInsuredGenerator(
                productFieldsObject.field12,
                formatMessage,
                'summary'
              ) !== '' && (
                <PersonInsuredField>
                  {`${formatMessage({
                    id: productFieldsObject.field12.fieldLabel
                  })}: ${personInsuredGenerator(
                    productFieldsObject.field12,
                    formatMessage,
                    'summary'
                  )}`}
                </PersonInsuredField>
              )}
          </ShowIfDesktop>
        </LeftColumn>
      </CardFlexContainer>
      <ShowIfMobile>
        {productFieldsObject.field12 &&
          productFieldsObject.field12.value &&
          productFieldsObject.field12.showOnUI &&
          personInsuredGenerator(
            productFieldsObject.field12,
            formatMessage,
            'summary'
          ) !== '' && (
            <PersonInsuredField>
              <PersonInsuredLabel>
                {formatMessage({
                  id: productFieldsObject.field12.fieldLabel
                })}
              </PersonInsuredLabel>{' '}
              <PersonInsuredValues>
                {personInsuredGenerator(
                  productFieldsObject.field12,
                  formatMessage,
                  'summary'
                )}
              </PersonInsuredValues>
            </PersonInsuredField>
          )}
      </ShowIfMobile>
      <>
        <RedHR />
        <CardStatementsWrapper
          conditionalFieldValueOne={conditionalFieldValueOne}
          conditionalFieldValueTwo={conditionalFieldValueTwo}
        >
          <NextActionMessage
            productFieldsObject={productFieldsObject}
            productCategory={productCategory}
            superCategory={superCategory}
            locale={locale}
            notificationMessages={notificationMessages}
            policyOnHold={policyOnHold}
          />
          <SeeDetailsLink
            showWelcomePack={showWelcomePack}
            customerPolicy={currentCustomerPolicy}
            policynumber={productFieldsObject.field3.value}
            encodePolicyNumber={encodePolicyNumber}
            productFieldsObject={productFieldsObject}
          />
        </CardStatementsWrapper>
      </>
    </>
  );
};

GITemplate.propTypes = {
  productFieldsObject: PropTypes.object.isRequired,
  productCategory: PropTypes.string.isRequired,
  superCategory: PropTypes.string.isRequired,
  colorCodesForPolicyStatus: PropTypes.object.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyOnHold: PropTypes.bool.isRequired,
  eWelcomePack: PropTypes.object.isRequired
};

GITemplate.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default GITemplate;
