import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import { endLoader } from '../app/appActions';
import { E_WELCOME_PACK_ACK } from '../actionTypes';
import { prepareEWelcomeACKConfig } from '../utils/sagaUtils';

const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* eWelcomePackACK(action) {
  const {
    payload: { policyNumber }
  } = action;
  try {
    const configurationObj = yield select(getConfigurations);
    const saasAuth = yield select(getSaasToken);
    const {
      genericConfig: { commonHeaders, addnCustomersHeader },
      serviceConfig: { eWelcomePackAck }
    } = configurationObj;

    const idcHeaders = {
      Authorization: `Bearer ${saasAuth}`,
      ...commonHeaders,
      ...addnCustomersHeader,
      'content-type': 'application/json',
      'X-HSBC-Global-Channel-Id': 'STAFFWEB',
      'Accept-Language': 'en-US,en;q=0.9'
    };

    const ackPayload = {
      policyDetails: {
        entityCode: '2',
        policyId: policyNumber,
        ackFlag: 'Y'
      }
    };

    const eWelcomePackAckConfig = prepareEWelcomeACKConfig(
      eWelcomePackAck,
      idcHeaders
    );

    eWelcomePackAckConfig.data = ackPayload;

    yield call(axios, eWelcomePackAckConfig);
    yield put(endLoader());
  } catch (err) {
    yield put(endLoader());
  }
}

export default function* eWelcomePackAckSaga() {
  yield takeEvery(E_WELCOME_PACK_ACK, eWelcomePackACK);
}
