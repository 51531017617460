import React from 'react';
import PropTypes from 'prop-types';
import {
  IconInfoStyle,
  PageInfoDiv,
  InfoMsg,
  InfoStyleIcon
} from './welcomeNotificationStyles';
import icon from '../../assets/images/NotificationIcons/Icon.svg';

const InformationSnackbar = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { children } = props;

  return (
    <InfoMsg>
      <IconInfoStyle>
        <InfoStyleIcon
          src={icon}
          alt={formatMessage({
            id: `success_Alt_Text`
          })}
        />
      </IconInfoStyle>
      <PageInfoDiv>{children}</PageInfoDiv>
    </InfoMsg>
  );
};

InformationSnackbar.displayName = 'InformationSnackbar';

InformationSnackbar.propTypes = {
  children: PropTypes.node.isRequired
};

InformationSnackbar.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default InformationSnackbar;
