import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';

export const localMapping = {
  en: 'en_HK', // English
  zh: 'zh_CN', // Simplified Chinese
  'zh-tc': 'zh_HK' // Treditional Chinese
};

export const htmlLangAttr = {
  en: 'en', // English
  zh: 'zh-Hans', // Simplified Chinese
  'zh-tc': 'zh-Hant' // Treditional Chinese
};

export const localeToCookie = {
  en: 'en_HK', // English
  zh: 'zh_CN', // Simplified Chinese
  'zh-tc': 'zh_HK' // Treditional Chinese
};

export const cookieToLocale = {
  en_HK: 'en',
  zh_CN: 'zh',
  zh_HK: 'zh-tc'
};

export const localeMappingForDescription = {
  en: 'EN', // English
  zh: 'SC', // Simplified Chinese
  'zh-tc': 'TC' // Traditional Chinese
};

export const fallbackLocalePriority = {
  EN: ['TC', 'SC'], // English
  SC: ['TC', 'EN'], // Simplified Chinese
  TC: ['SC', 'EN'] // Traditional Chinese
};

export const localeData = [
  ...en,
  ...zh,
  {
    locale: 'zh-tc',
    parentLocale: 'zh'
  }
];

export const langConfig = [
  { languageText: 'English', languageValue: 'en' },
  { languageText: '繁體', languageValue: 'zh-tc' },
  { languageText: '简体', languageValue: 'zh' }
];

export const idcFallBackLocaleMapping = {
  EN: ['BL', 'TC', 'SC'], // English
  SC: ['TC', 'BL', 'EN'], // Simplified Chinese
  TC: ['BL', 'SC', 'EN'] // Traditional Chinese
};
