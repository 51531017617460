import styled from 'styled-components';
import media from '../../utils/media';

const LinkContainer = styled.span`
  ${media.phone`
  display:inline;
  > a {
  text-decoration: none;
  color: black;
  &:hover{
    cursor: pointer;
  }
}
`}
`;

export default LinkContainer;
