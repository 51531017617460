import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ProjectedValueSummaryAgeText } from '../../reprojectionPageStyle';

const ProjectedValueSummaryAge = function ProjectedValueSummaryAge(
  props,
  context
) {
  const {
    intl: { formatMessage }
  } = context;
  const {
    fieldData,
    planCodesCheckList,
    planCodeFieldLabel,
    dependentFieldData
  } = props;
  let { labelContent } = props;
  if (!labelContent || !fieldData) {
    return <></>;
  }

  if (_.includes(planCodesCheckList, dependentFieldData)) {
    labelContent = planCodeFieldLabel;
  }

  return (
    <ProjectedValueSummaryAgeText id={labelContent}>
      <FormattedMessage
        id={labelContent}
        aria-label={formatMessage(
          {
            id: labelContent
          },
          { customerAge: fieldData }
        )}
        values={{
          customerAge: fieldData
        }}
      />
    </ProjectedValueSummaryAgeText>
  );
};

ProjectedValueSummaryAge.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.number.isRequired,
  planCodesCheckList: PropTypes.array.isRequired,
  planCodeFieldLabel: PropTypes.string.isRequired,
  dependentFieldData: PropTypes.string.isRequired
};

ProjectedValueSummaryAge.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ProjectedValueSummaryAge;
