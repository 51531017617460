import _ from 'lodash';
import {
  SET_POLICY_SUMMARY,
  SET_POLICY,
  GET_POLICY,
  SET_ERRORS_WARNINGS,
  SET_POLICY_CHECK_VALUE,
  SET_POLICY_STATE,
  REMOVE_POLICY_DETAIL
} from '../actionTypes';

export const INITIAL_STATE = {
  isSummaryCalled: false,
  totalPolicyCount: 0,
  byFamily: {},
  byEncodeId: {},
  warningsOrErrors: { summary: null, details: null },
  summaryWarningsPageLevel: null,
  productFamilyOrder: [],
  isPolicyExceptional: false,
  isPolicyLapsed: false,
  isPolicyDetailsAvailable: true,
  isPolicyGeneral: true
};

const policyReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
  switch (type) {
    case SET_POLICY_SUMMARY:
      return {
        ...state,
        isSummaryCalled: true,
        ...payload
      };
    case GET_POLICY:
    case SET_POLICY:
    case SET_POLICY_CHECK_VALUE:
    case SET_POLICY_STATE:
      return {
        ...state,
        ...payload
      };
    case SET_ERRORS_WARNINGS: {
      const warningsOrErrors = { ...state.warningsOrErrors, ...payload };
      return {
        ...state,
        warningsOrErrors
      };
    }
    case REMOVE_POLICY_DETAIL: {
      const policyList = state.byEncodeId;
      if (!_.isEmpty(policyList) && policyList[payload].policyDetails) {
        delete policyList[payload].policyDetails;
      }
      return {
        ...state,
        byEncodeId: policyList
      };
    }

    default:
      return state;
  }
};

export default policyReducer;
