import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import multiuser from '../../../assets/images/multiuser.svg';
import { DetailsPageMiddleSection } from '../../detailPageStyle';
import BeneficiaryPrimarySection from './BeneficiaryPrimarySection';
import BeneficiarySecondarySection from './BeneficiarySecondarySection';
import { trackViews } from '../../../utils/tealiumUtils';
import {
  BeneficiariesInfoLabel,
  ImgStyle,
  ManageBeneficiariesHeader,
  ManageBeneficiariesImgWrapper,
  ManageBeneficiariesWrapper
} from './beneficiarySectionStyles';
/**
 *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
 * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
 */
// import DisplayPercentageAmount from './DisplayPercentageAmount';
import {
  getMissingInfo,
  AllocationPercentConverter,
  conditionForentityCode
} from '../../../utils/BeneficiaryUtils';
import { setLinkFocus } from '../../../utils/domUtils';
import canSuppressBeneficiaries from '../../../utils/DBSOUtils';
import BeneFooterTextSection from './BeneFooterTextSection';

const BeneficiarySection = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { currentPolicyDetail, encodedProductId, displayBeneCard } = props;
  const suppressBeneficiaries = canSuppressBeneficiaries(currentPolicyDetail);
  const linkRefPrimary = React.createRef();
  const linkRefSecondary = React.createRef();
  const [primaryBeneficiariesList, setPrimaryBeneficiariesList] = useState([]);
  const [
    rawPrimaryBeneficiariesList,
    setRawPrimaryBeneficiariesList
  ] = useState([]);

  const [secondaryBeneficiariesList, setSecondaryBeneficiariesList] = useState(
    []
  );
  const [
    rawSecondaryBeneficiariesList,
    setRawSecondaryBeneficiariesList
  ] = useState([]);

  const [isModalOpenPrimary, updateModalStatePrimary] = useState(false);
  const [isModalOpenSecondary, updateModalStateSecondary] = useState(false);
  const [isDisplay, setDisplay] = useState(false);

  const [irrevocableAssigned, setIrrevocableAssigned] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [nothingDataStore, setNothingDataStore] = useState(false);

  /**
   *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
   * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
   */
  // const totalPolicyInfo = {
  //   amount:
  //     currentPolicyDetail?.policyAmountDetails?.totalPolicyValue?.amount ?? 0,
  //   currency:
  //     currentPolicyDetail?.policyAmountDetails?.totalPolicyValue?.currency
  // };

  const onCancelClickPrimary = () => {
    updateModalStatePrimary(!isModalOpenPrimary);
    setLinkFocus(linkRefPrimary.current, true);
  };
  const onCancelClickSecondary = () => {
    updateModalStateSecondary(!isModalOpenSecondary);
    setLinkFocus(linkRefSecondary.current, true);
  };

  const handleDataSet = (
    tempData,
    tempDataSecondary,
    primaryBeneficiariesData,
    secondaryBeneficiariesData
  ) => {
    setPrimaryBeneficiariesList(tempData);

    setRawPrimaryBeneficiariesList(() => {
      return primaryBeneficiariesData.map(node => {
        return {
          ...node,
          missingInfo: getMissingInfo(currentPolicyDetail, node)
        };
      });
    });
    setSecondaryBeneficiariesList(tempDataSecondary);

    setRawSecondaryBeneficiariesList(() => {
      return secondaryBeneficiariesData.map(node => {
        return {
          ...node,
          missingInfo: getMissingInfo(currentPolicyDetail, node)
        };
      });
    });
    const nodataBool =
      primaryBeneficiariesData.length === 0 &&
      secondaryBeneficiariesData.length === 0;
    setNothingDataStore(nodataBool);
  };

  useEffect(() => {
    const primaryBeneficiariesData =
      currentPolicyDetail?.beneficiaryDetails?.primaryBeneficiaries ?? [];
    const secondaryBeneficiariesData =
      currentPolicyDetail?.beneficiaryDetails?.secondaryBeneficiaries ?? [];
    const checkForEntityCode = conditionForentityCode(
      currentPolicyDetail?.policyOverview?.entityCode ?? ''
    );
    if (
      _.has(
        currentPolicyDetail?.policyOverview,
        'irrevocableBeneficiaryIndicator'
      )
    ) {
      setIrrevocableAssigned(
        currentPolicyDetail?.policyOverview?.irrevocableBeneficiaryIndicator
      );
    }

    if (_.has(currentPolicyDetail?.policyOverview, 'assignedPolicyIndicator')) {
      setAssigned(currentPolicyDetail?.policyOverview?.assignedPolicyIndicator);
    }
    const tempData = primaryBeneficiariesData.map(node => {
      return {
        manage_Beneficiaries_List_field1: node?.fullName,
        manage_Beneficiaries_List_field2: node?.beneficiaryRole,
        manage_Beneficiaries_List_field3: AllocationPercentConverter(
          node?.allocationPercentage
        )

        /**
         *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
         * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
         */
        // manage_Beneficiaries_List_field4: (
        //   <DisplayPercentageAmount
        //     totalPolicyValue={totalPolicyInfo.amount}
        //     allocationPercentage={node?.allocationPercentage}
        //     totalPolicyCurrency={totalPolicyInfo.currency}
        //   />
        // )
      };
    });
    const tempDataSecondary = secondaryBeneficiariesData.map(node => {
      return {
        manage_Beneficiaries_List_field1: node?.fullName,
        manage_Beneficiaries_List_field2: node?.beneficiaryRole,
        manage_Beneficiaries_List_field3: AllocationPercentConverter(
          node?.allocationPercentage
        )
      };
    });

    handleDataSet(
      tempData,
      tempDataSecondary,
      primaryBeneficiariesData,
      secondaryBeneficiariesData
    );
    if (displayBeneCard && checkForEntityCode) {
      setDisplay(true);
      const totalNumberCount =
        Number(primaryBeneficiariesData.length) +
        Number(secondaryBeneficiariesData.length);
      trackViews({
        viewName: 'policyDetailsOnLoad',
        formField2: totalNumberCount
      });
    }
  }, [currentPolicyDetail]);

  return isDisplay ? (
    <DetailsPageMiddleSection>
      <ManageBeneficiariesWrapper>
        <ManageBeneficiariesHeader>
          <ManageBeneficiariesImgWrapper>
            <ImgStyle aria-hidden="true" alt="" src={multiuser} />
          </ManageBeneficiariesImgWrapper>
          <BeneficiariesInfoLabel id="Beneficiaries_information_title">
            {formatMessage({ id: 'manage_Beneficiaries_List_title' })}
          </BeneficiariesInfoLabel>
        </ManageBeneficiariesHeader>
        <BeneficiaryPrimarySection
          nothingDataStore={nothingDataStore}
          primaryBeneficiariesList={primaryBeneficiariesList}
          rawPrimaryBeneficiariesList={rawPrimaryBeneficiariesList}
          isModalOpen={isModalOpenPrimary}
          linkRefPrimary={linkRefPrimary}
          onCancelClick={onCancelClickPrimary}
          updateModal={updateModalStatePrimary}
          /**
           *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
           * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
           */
          // currentPolicyDetail={currentPolicyDetail}
          irrevocableAssigned={irrevocableAssigned}
          assigned={assigned}
          encodedProductId={encodedProductId}
          canSuppressBeneficiaryLink={suppressBeneficiaries}
        />
        <BeneficiarySecondarySection
          nothingDataStore={nothingDataStore}
          secondaryBeneficiariesList={secondaryBeneficiariesList}
          rawSecondaryBeneficiariesList={rawSecondaryBeneficiariesList}
          linkRefSecondary={linkRefSecondary}
          isModalOpen={isModalOpenSecondary}
          onCancelClick={onCancelClickSecondary}
          updateModal={updateModalStateSecondary}
          irrevocableAssigned={irrevocableAssigned}
          assigned={assigned}
          encodedProductId={encodedProductId}
          canSuppressBeneficiaryLink={suppressBeneficiaries}
        />

        <BeneFooterTextSection
          nothingDataStore={nothingDataStore}
          suppressBeneficiaries={suppressBeneficiaries}
        />
      </ManageBeneficiariesWrapper>
    </DetailsPageMiddleSection>
  ) : null;
};

BeneficiarySection.propTypes = {
  currentPolicyDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  encodedProductId: PropTypes.string.isRequired,

  displayBeneCard: PropTypes.bool
};

BeneficiarySection.defaultProps = {
  displayBeneCard: false
};

BeneficiarySection.contextTypes = {
  intl: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    displayBeneCard:
      state?.configurations?.detailsConfig?.BeneficiariesListInfo?.display ??
      false
  };
};
export default connect(mapStateToProps, null)(BeneficiarySection);
