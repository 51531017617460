import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { SingleAggreStyle } from './DisclaimerStyle';

const SingleAggrePremiumSection = props => {
  const {
    reprojectionsDetails: {
      indicators: { singPmtPgInd }
    },
    policyOverview: { planCode },
    planCodeList
  } = props;

  if (!singPmtPgInd) {
    return null;
  }

  const getMessage = () => {
    if (_.includes(planCodeList, planCode)) {
      return (
        <FormattedHTMLMessage id="disclaimerSingleAggrePremiumLabelJWIP" />
      );
    }
    return <FormattedHTMLMessage id="disclaimerSingleAggrePremiumLabel" />;
  };

  return (
    <SingleAggreStyle id="disclaimerSingleAggregatePremium">
      {singPmtPgInd && getMessage()}
    </SingleAggreStyle>
  );
};

SingleAggrePremiumSection.propTypes = {
  reprojectionsDetails: PropTypes.object.isRequired,
  policyOverview: PropTypes.object.isRequired,
  planCodeList: PropTypes.array.isRequired
};

export default SingleAggrePremiumSection;
