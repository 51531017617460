import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import Currency from '../../../common/currency/Currency';
import { displayIfNull } from '../../../common/util/commonUtil';

import {
  PayoutBenefitAnnuityPayoutLegends,
  AnnualAnnuityPayoutLegendWrapper,
  PayouBenefitLegendIconWrapper,
  AnnualAnnuityPayoutLegend,
  AnnualAnnuityPayoutKey,
  PayouBenefitLegendIcon,
  AnnualAnnuityPayoutValue,
  TotalGuranteedPayoutKey,
  TotalGuaranteedPayoutLabel,
  SelectedAge,
  TotalGuranteedPayoutLegendIcon,
  CumulativeGuranteedPayoutKey,
  CumulativeGuranteedPayoutValue
} from './payoutLegendStyle';
import { getToggleBasedLegendTotalValue } from '../../utils/reprojectionDataUtils';
import PayoutAnnualTotalNonGuranteed from './PayoutAnnualTotalNonGuranteed';

const PayoutAnnualAnnuityLegends = function PayoutAnnualAnnuityLegends(
  props,
  context
) {
  const {
    productCategory,
    isNonGuaranteedChecked,
    policyCurrency,
    payoutDetails,
    ageSelection,
    legends: { header, guaranteed, nonGuaranteed }
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const {
    color,
    decimalRange,
    keyName,
    keyNameSecondary,
    keyValueGuaranteed,
    keyValueNonGuaranteed
  } = header;
  const {
    color: legendColor,
    decimalRange: trimUpto,
    keyName: labelName,
    keyValue: labelValue
  } = guaranteed;

  const payoutBenefitLegendsData = payoutDetails[ageSelection];

  if (!payoutBenefitLegendsData) {
    return <></>;
  }

  const legendSectionID = 'annual_annuity_payout';

  let annualAnnuityPayoutValue = getToggleBasedLegendTotalValue(
    keyValueNonGuaranteed,
    keyValueGuaranteed,
    isNonGuaranteedChecked
  );

  annualAnnuityPayoutValue = payoutBenefitLegendsData[annualAnnuityPayoutValue];

  return (
    <PayoutBenefitAnnuityPayoutLegends>
      <AnnualAnnuityPayoutLegendWrapper>
        <AnnualAnnuityPayoutLegend>
          <>
            <>
              <PayouBenefitLegendIconWrapper>
                {_.map(color, legendCol => {
                  return (
                    <PayouBenefitLegendIcon
                      legendBgColor={legendCol}
                      isNonGuaranteedChecked={isNonGuaranteedChecked}
                    />
                  );
                })}
              </PayouBenefitLegendIconWrapper>
              <AnnualAnnuityPayoutKey id="annualPayoutLegendsKey">
                {formatMessage({ id: keyName })}
                <SelectedAge id="annualAnnualAnnuityPayoutYear">
                  <FormattedMessage
                    id={keyNameSecondary}
                    values={{
                      selectedAge: ageSelection
                    }}
                  />
                </SelectedAge>
              </AnnualAnnuityPayoutKey>
              <AnnualAnnuityPayoutValue id="annualAnnuityPayoutValue">
                <Currency
                  id={legendSectionID}
                  currencyCode={policyCurrency}
                  amount={displayIfNull(annualAnnuityPayoutValue, 0)}
                  decimalRange={decimalRange}
                />
              </AnnualAnnuityPayoutValue>
            </>
            <TotalGuranteedPayoutKey aria-hidden="true" styleUpdate />
            <>
              <CumulativeGuranteedPayoutKey styleUpdate id="guranteedPayoutKey">
                <TotalGuranteedPayoutLegendIcon legendBgColor={legendColor} />
                <TotalGuaranteedPayoutLabel id="totalGuaranteedPayoutLabel">
                  {formatMessage({ id: labelName })}
                </TotalGuaranteedPayoutLabel>
              </CumulativeGuranteedPayoutKey>
              <CumulativeGuranteedPayoutValue id="guranteedPayoutValue">
                <Currency
                  id={legendSectionID}
                  currencyCode={policyCurrency}
                  amount={displayIfNull(
                    payoutBenefitLegendsData[labelValue],
                    0
                  )}
                  decimalRange={trimUpto}
                />
              </CumulativeGuranteedPayoutValue>
            </>
            <TotalGuranteedPayoutKey aria-hidden="true" styleUpdate />
            {/*  total guranteed and non-guranteed amount list goes here */}
            {isNonGuaranteedChecked && (
              <>
                <PayoutAnnualTotalNonGuranteed
                  productCategory={productCategory}
                  legendSectionID={legendSectionID}
                  policyCurrency={policyCurrency}
                  legends={nonGuaranteed}
                  payoutBenefitLegendsData={payoutBenefitLegendsData}
                />
              </>
            )}
          </>
        </AnnualAnnuityPayoutLegend>
      </AnnualAnnuityPayoutLegendWrapper>
    </PayoutBenefitAnnuityPayoutLegends>
  );
};

PayoutAnnualAnnuityLegends.propTypes = {
  productCategory: PropTypes.string.isRequired,
  ageSelection: PropTypes.number.isRequired,
  payoutDetails: PropTypes.object.isRequired,
  isNonGuaranteedChecked: PropTypes.bool.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  legends: PropTypes.object.isRequired
};

PayoutAnnualAnnuityLegends.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PayoutAnnualAnnuityLegends;
