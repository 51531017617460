import React from 'react';
import PropTypes from 'prop-types';
import {
  ContactCardDetailsGeneral,
  ContactCardNumberGeneral,
  ContactCardDescGeneral,
  ContactCardTitle
} from '../sections/contactInfoStyles';
import ContactLink from '../../common/contactLink/contactLink';
import { checkContentInContext } from '../../utils/localeUtils';

const ContactInfoDetails = (props, context) => {
  const {
    intl: { formatMessage, messages }
  } = context;
  const { superFamily = 'default', labelContent, isMandatoryToShow } = props;
  if (!labelContent) {
    return null;
  }
  let contactCardTitle = '';
  let contactCardDescFirst = '';
  let contactCardDescSecond = '';
  let contactCardNumber = '';
  let contactCardDescThird = '';

  if (!isMandatoryToShow && superFamily === 'gi') {
    return null;
  }

  if (!isMandatoryToShow) {
    contactCardTitle = `${labelContent}Title_Jade`;
    contactCardDescFirst = `${labelContent}Time_Jade`;
    contactCardNumber = 'JadeContactUsNumber';
    contactCardDescSecond = `${labelContent}Time_WE_Jade`;
    contactCardDescThird = `${labelContent}Time_PB_Jade`;
  } else {
    contactCardTitle = `${labelContent}Title_${superFamily}`;
    contactCardDescFirst = `${labelContent}Time_WD_${superFamily}`;
    contactCardNumber =
      superFamily !== 'default'
        ? `${superFamily}ContactUsNumber`
        : `${labelContent}ContactUsNumber`;
  }
  if (superFamily !== 'gi' && isMandatoryToShow) {
    contactCardDescSecond = `${labelContent}Time_WE_${superFamily}`;
    contactCardDescThird = `${labelContent}Time_PB_${superFamily}`;
  }

  return (
    <ContactCardDetailsGeneral id={contactCardTitle}>
      <ContactCardTitle>
        {formatMessage({ id: contactCardTitle })}
      </ContactCardTitle>
      <ContactCardNumberGeneral>
        <ContactLink
          sentenceWithContact="emergencyHotLineNumber"
          contactsList={[contactCardNumber]}
          typeOfCall="emergency hotline"
        />
      </ContactCardNumberGeneral>
      <ContactCardDescGeneral>
        <span>{formatMessage({ id: contactCardDescFirst })}</span>
        {checkContentInContext(contactCardDescSecond, messages) && (
          <span>{formatMessage({ id: contactCardDescSecond })}</span>
        )}
        {checkContentInContext(contactCardDescThird, messages) && (
          <span>{formatMessage({ id: contactCardDescThird })}</span>
        )}
      </ContactCardDescGeneral>
    </ContactCardDetailsGeneral>
  );
};

ContactInfoDetails.propTypes = {
  superFamily: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  isMandatoryToShow: PropTypes.bool
};

ContactInfoDetails.defaultProps = {
  isMandatoryToShow: false
};

ContactInfoDetails.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ContactInfoDetails;
