import React from 'react';
import Carousel from 'nuka-carousel';
import 'core-js/stable';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BackIcon from '../../assets/images/Highlights_images/Pagination/Left/LeftIcon.svg';
import FrontIcon from '../../assets/images/Highlights_images/Pagination/Right/RightIcon.svg';
import { isMiniMobile, isMiniDesktop, isTab } from '../../utils/domUtils';
import {
  CarouselBodyWrapper,
  CarouselTitleWrapper,
  CarouselImage,
  CarouselContentWrapper,
  CarouselDescriptionWrapper,
  CarouselDisclaimerWrapper,
  CarouselLinkTitle,
  ImgWrapper,
  CarouselLinkWrapper,
  IconRightImg,
  CarouselWrapper,
  SliderBackImage,
  SliderForwardImage
} from '../summaryPageStyles';
import { getImagePath } from '../../utils/routeUtils';
import PagingDots from './PagingDots';
import iconRight from '../../assets/images/Icon_right.svg';
import {
  trackViews,
  trackEvents,
  prodIdToMessageMappings,
  getFormField1
} from '../../utils/tealiumUtils';
import getContentInSpecificLang from '../../utils/localeUtils';
import { doAND } from '../../common/util/commonUtil';

// to be used in tealium
let bannerImpressions = '';
let messages = '';
let formField1 = '';
let isDisabledNextBtn;
let returnHighlights = '';
let setProductCategory = null;
let setProductSubCategory = null;

const bannerDetails = (highlightSection, eligibilityDetails) => {
  const { showDynamicBanner = {} } = highlightSection;
  if (_.isEmpty(showDynamicBanner)) {
    return highlightSection;
  }
  const { apiKeyName, expectedValue, fallBack } = showDynamicBanner;
  if (eligibilityDetails && eligibilityDetails[apiKeyName]) {
    const apiResponse = eligibilityDetails[apiKeyName];
    if (_.includes(_.keys(expectedValue), apiResponse)) {
      return expectedValue[apiResponse];
    }
  }
  return fallBack;
};

const checkGABannerImpression = (
  eligibilityDetails,
  eventContent,
  gaEventContent
) => {
  const { lowRiskIndicator = null } = eligibilityDetails;
  if (lowRiskIndicator === 'Y') {
    return gaEventContent[0];
  }
  return eventContent[0];
};

const desktopView = function desktopView(args) {
  const [
    viewNameBannerLoad,
    tealiumMappings,
    highlightsSection,
    formatMessage,
    locale,
    journeyLinkTealiumEvent,
    eligibilityDetails
  ] = args;
  const {
    Desktop: {
      productCategory,
      subProductCategory,
      eventContent,
      gaEventContent
    },
    formField1MsgStack
  } = tealiumMappings;
  bannerImpressions = checkGABannerImpression(
    eligibilityDetails,
    eventContent,
    gaEventContent
  );
  messages = ['message1', 'message2', 'message3', 'message4'];
  formField1 = getFormField1(messages, formField1MsgStack);
  trackViews({
    viewName: viewNameBannerLoad,
    eventContent: bannerImpressions,
    eventMessages: messages,
    productCategory,
    productSubcategory: subProductCategory,
    formField1
  });
  returnHighlights = (
    <>
      {_.map(highlightsSection, highlightSectionObj => {
        const highlightSection = bannerDetails(
          highlightSectionObj,
          eligibilityDetails
        );
        const {
          productID,
          productName,
          productImage,
          productContent: { description, disclaimer },
          productJourneyLink: {
            journeyLinkTitle,
            journeyLinkPath,
            isOpenInNewTab
          }
        } = highlightSection;
        return (
          doAND(
            highlightSection,
            _.size(highlightSection) !== 0,
            !_.isNull(highlightSection)
          ) && (
            <CarouselBodyWrapper key={productID}>
              <CarouselImage
                aria-hidden="true"
                alt=""
                src={getImagePath(process.env.PUBLIC_URL, productImage)}
              />
              <CarouselContentWrapper>
                <CarouselTitleWrapper>
                  {formatMessage({ id: productName })}
                </CarouselTitleWrapper>
                <CarouselDescriptionWrapper>
                  {formatMessage({ id: description })}
                </CarouselDescriptionWrapper>
                {disclaimer && (
                  <CarouselDisclaimerWrapper>
                    {formatMessage({ id: disclaimer })}
                  </CarouselDisclaimerWrapper>
                )}
                <CarouselLinkWrapper>
                  <CarouselLinkTitle
                    href={journeyLinkPath[locale]}
                    target={isOpenInNewTab ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                    onClick={() =>
                      journeyLinkTealiumEvent(
                        _.toLower(formatMessage({ id: productName })),
                        [prodIdToMessageMappings[productID]],
                        productID
                      )
                    }
                  >
                    {formatMessage({ id: journeyLinkTitle })}
                  </CarouselLinkTitle>
                  <ImgWrapper>
                    <IconRightImg aria-hidden="true" alt="" src={iconRight} />
                  </ImgWrapper>
                </CarouselLinkWrapper>
              </CarouselContentWrapper>
            </CarouselBodyWrapper>
          )
        );
      })}
    </>
  );
};

const mobileView = function mobileViewmobileView(args) {
  const [
    captureBannerEvents,
    tealiumMappings,
    highlightsSection,
    slidesToShow,
    keyIndex,
    setKeyIndex,
    count,
    setCount,
    formatMessage,
    locale,
    journeyLinkTealiumEvent,
    eligibilityDetails
  ] = args;
  returnHighlights = (
    <Carousel
      afterSlide={slideIndex =>
        captureBannerEvents(
          Math.ceil(slideIndex),
          Math.ceil(highlightsSection.length - slidesToShow),
          highlightsSection,
          tealiumMappings
        )
      }
      slidesToShow={slidesToShow}
      cellAlign="left"
      renderAnnounceSlideMessage={({ currentSlide, slideCount }) => {
        if (isTab()) {
          return `Slide ${Math.ceil(currentSlide) + 1} and ${Math.ceil(
            currentSlide
          ) + 2} of ${slideCount}`;
        }
        return `Slide ${Math.ceil(currentSlide) + 1} of ${slideCount}`;
      }}
      renderBottomCenterControls={prop => (
        <PagingDots {...prop} slidesToShow={slidesToShow} />
      )}
      renderBottomLeftControls={prop => {
        return (
          <SliderBackImage
            aria-hidden="true"
            alt=""
            src={BackIcon}
            disabled={prop.currentSlide === 0}
            onClick={() => {
              prop.previousSlide();
            }}
          />
        );
      }}
      renderBottomRightControls={prop => {
        isDisabledNextBtn =
          Math.ceil(prop.currentSlide) ===
          Math.ceil(highlightsSection.length - slidesToShow);
        if (keyIndex === 9) {
          setKeyIndex(0);
          if (count === 0) {
            prop.goToSlide(count);
          } else if (
            count >= Math.floor(slidesToShow) &&
            count < highlightsSection.length
          ) {
            prop.nextSlide();
          }
        }
        return (
          <SliderForwardImage
            aria-hidden="true"
            alt=""
            src={FrontIcon}
            disabled={isDisabledNextBtn}
            onClick={() => {
              prop.nextSlide();
            }}
          />
        );
      }}
    >
      {_.map(highlightsSection, highlightSectionObj => {
        const highlightSection = bannerDetails(
          highlightSectionObj,
          eligibilityDetails
        );
        const {
          productID,
          productName,
          productImage,
          productContent: { description, disclaimer },
          productJourneyLink: {
            journeyLinkTitle,
            journeyLinkPath,
            isOpenInNewTab
          }
        } = highlightSection;

        return (
          doAND(
            highlightSection,
            _.size(highlightSection) !== 0,
            !_.isNull(highlightSection)
          ) && (
            <CarouselBodyWrapper key={productID}>
              <CarouselImage
                aria-hidden="true"
                alt=""
                src={getImagePath(process.env.PUBLIC_URL, productImage)}
              />
              <CarouselContentWrapper
                onKeyUp={e => {
                  setKeyIndex(e.keyCode);
                  setCount(productID);
                }}
              >
                <CarouselTitleWrapper>
                  {formatMessage({ id: productName })}
                </CarouselTitleWrapper>
                <CarouselDescriptionWrapper>
                  {formatMessage({ id: description })}
                </CarouselDescriptionWrapper>
                {disclaimer && (
                  <CarouselDisclaimerWrapper>
                    {formatMessage({ id: disclaimer })}
                  </CarouselDisclaimerWrapper>
                )}
                <CarouselLinkWrapper>
                  <CarouselLinkTitle
                    href={journeyLinkPath[locale]}
                    target={isOpenInNewTab ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                    onClick={() =>
                      journeyLinkTealiumEvent(
                        _.toLower(formatMessage({ id: productName })),
                        [prodIdToMessageMappings[productID]],
                        productID
                      )
                    }
                  >
                    {formatMessage({ id: journeyLinkTitle })}{' '}
                  </CarouselLinkTitle>
                  <ImgWrapper>
                    <IconRightImg aria-hidden="true" alt="" src={iconRight} />
                  </ImgWrapper>
                </CarouselLinkWrapper>
              </CarouselContentWrapper>
            </CarouselBodyWrapper>
          )
        );
      })}
    </Carousel>
  );
};
const individualCategory = (category, id) => {
  return category.split(',')[id] ? category.split(',')[id] : '';
};
const HighlightsSection = (props, context) => {
  const {
    highlightsSection,
    tealiumMappings,
    langObj,
    eligibilityDetails
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  const [width, setWidth] = React.useState(window.innerWidth);
  const [keyIndex, setKeyIndex] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const updateWidth = () => {
    setWidth(document.body.clientWidth);
  };
  const {
    Desktop: {
      productCategory: desktopProductCategory,
      subProductCategory: desktopSubProductCategory
    },
    Tablet: {
      productCategory: tabProductCategory,
      subProductCategory: tabSubProductCategory,
      eventContent: tabEventContent,
      gaEventContent: tabGAEventContent
    },
    Mobile: {
      productCategory: mobProductCategory,
      subProductCategory: mobSubProductCategory,
      eventContent: mobEventContent,
      gaEventContent: mobGAEventContent
    },
    formField1MsgStack
  } = tealiumMappings;
  const viewNameBannerLoad = 'onHighlightsBannersLoad';
  const eventNameHighLights = 'onHighlightsProductLink';

  React.useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  });
  const carouselWidth = 0.9 * width;
  let slidesToShow = carouselWidth / 260;

  if (isTab()) {
    slidesToShow = carouselWidth / 340;
    bannerImpressions = checkGABannerImpression(
      eligibilityDetails,
      tabEventContent,
      tabGAEventContent
    );
    messages = ['message1', 'message2'];
    formField1 = getFormField1(messages, formField1MsgStack);

    trackViews({
      viewName: viewNameBannerLoad,
      eventContent: bannerImpressions,
      eventMessages: messages,
      productCategory: tabProductCategory[0],
      productSubcategory: tabSubProductCategory[0],
      formField1
    });
  }

  if (isMiniMobile()) {
    slidesToShow = carouselWidth / 275;
    bannerImpressions = checkGABannerImpression(
      eligibilityDetails,
      mobEventContent,
      mobGAEventContent
    );
    messages = ['message1'];
    formField1 = getFormField1(messages, formField1MsgStack);
    trackViews({
      viewName: viewNameBannerLoad,
      eventContent: bannerImpressions,
      eventMessages: messages,
      productCategory: mobProductCategory,
      productSubcategory: mobSubProductCategory,
      formField1
    });
  }

  const journeyLinkTealiumEvent = (productName, msgArray, productID) => {
    setProductCategory = individualCategory(desktopProductCategory, productID);
    setProductSubCategory = individualCategory(
      desktopSubProductCategory,
      productID
    );
    trackEvents({
      eventName: eventNameHighLights,
      eventContent: productName,
      eventMessages: msgArray,
      productCategory: setProductCategory,
      productSubcategory: setProductSubCategory,
      formField1: getFormField1(msgArray, formField1MsgStack)
    });
  };
  const captureBannerEvents = (
    currentSlide,
    totalSlides,
    highlightsSectionSlides
  ) => {
    if (doAND(currentSlide >= 0, currentSlide <= totalSlides)) {
      const highlightsCurrentSlide = _.find(highlightsSectionSlides, {
        productID: currentSlide
      });
      const { productID, productName } = highlightsCurrentSlide;
      if (doAND(isTab(), currentSlide === totalSlides)) {
        bannerImpressions = tabEventContent[1];
        messages = ['message3', 'message4'];
        formField1 = getFormField1(messages, formField1MsgStack);
        trackEvents({
          eventName: eventNameHighLights,
          eventContent: bannerImpressions,
          eventMessages: messages,
          eventAction: 'impression',
          productCategory: tabProductCategory[1],
          productSubcategory: tabSubProductCategory[1],
          formField1
        });
      }
      if (
        doAND(
          isMiniMobile(),
          highlightsCurrentSlide,
          productName,
          productID !== 0
        )
      ) {
        setProductCategory = individualCategory(
          desktopProductCategory,
          productID
        );
        setProductSubCategory = individualCategory(
          desktopSubProductCategory,
          productID
        );
        const content = getContentInSpecificLang(
          highlightsCurrentSlide.productName,
          'en',
          langObj
        );
        messages = [prodIdToMessageMappings[productID]];
        formField1 = getFormField1(messages, formField1MsgStack);
        trackEvents({
          eventName: eventNameHighLights,
          eventContent: _.toLower(content),
          eventMessages: messages,
          eventAction: 'impression',
          productCategory: setProductCategory,
          productSubcategory: setProductSubCategory,
          formField1
        });
      }
    }
  };

  if (isMiniDesktop()) {
    desktopView([
      viewNameBannerLoad,
      tealiumMappings,
      highlightsSection,
      formatMessage,
      locale,
      journeyLinkTealiumEvent,
      eligibilityDetails
    ]);
  } else {
    mobileView([
      captureBannerEvents,
      tealiumMappings,
      highlightsSection,
      slidesToShow,
      keyIndex,
      setKeyIndex,
      count,
      setCount,
      formatMessage,
      locale,
      journeyLinkTealiumEvent,
      eligibilityDetails
    ]);
  }
  return <CarouselWrapper>{returnHighlights}</CarouselWrapper>;
};

HighlightsSection.propTypes = {
  highlightsSection: PropTypes.array.isRequired,
  tealiumMappings: PropTypes.object.isRequired,
  langObj: PropTypes.object.isRequired,
  eligibilityDetails: PropTypes.object
};

HighlightsSection.defaultProps = {
  eligibilityDetails: {}
};

HighlightsSection.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default HighlightsSection;
