import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Currency from '../../../common/currency/Currency';
import {
  TotalGuranteedPayoutKey,
  TotalGuaranteedPayoutLabel,
  GuranteedPayoutKey,
  GuranteedPayoutValue,
  TotalNonGuranteedPayoutLegendIcon,
  CumulativeGuranteedPayoutKey
} from './payoutLegendStyle';
import { getPayoutAnnualLegendLabel } from '../../utils/reprojectionDataUtils';

const isCheckedTotalNonGuarAmtHelper = (
  negationCheckFields,
  payoutBenefitLegendsData
) => {
  let isCheckedTotalNonGuarAmt = true;
  for (let i = 0; i < negationCheckFields.length; i += 1) {
    isCheckedTotalNonGuarAmt =
      isCheckedTotalNonGuarAmt &&
      !payoutBenefitLegendsData[negationCheckFields[i]];
  }
  return isCheckedTotalNonGuarAmt;
};

const NonGuranteedPayout = function NonGuranteedPayout(props) {
  const {
    labelKey,
    labelValue,
    id,
    currencyCode,
    amount,
    decimalRange,
    formatMessage
  } = props;

  return (
    <>
      <GuranteedPayoutKey id={labelKey}>
        {formatMessage({ id: labelKey })}
      </GuranteedPayoutKey>
      <GuranteedPayoutValue id={labelValue}>
        <Currency
          id={id}
          currencyCode={currencyCode}
          amount={amount}
          decimalRange={decimalRange}
        />
      </GuranteedPayoutValue>
    </>
  );
};

const PayoutAnnualTotalNonGuranteed = function PayoutAnnualTotalNonGuranteed(
  props,
  context
) {
  const {
    productCategory,
    legendSectionID,
    policyCurrency,
    legends,
    payoutBenefitLegendsData
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  const { color, keyName, subFields, negationCheckFields } = legends;
  const isCheckedTotalNonGuarAmt = isCheckedTotalNonGuarAmtHelper(
    negationCheckFields,
    payoutBenefitLegendsData
  );
  if (!isCheckedTotalNonGuarAmt) {
    return (
      <>
        <CumulativeGuranteedPayoutKey id="nonGuarPayOutAmt_Key" styleUpdate>
          <TotalNonGuranteedPayoutLegendIcon legendBgColor={color} />
          <TotalGuaranteedPayoutLabel>
            {formatMessage({
              id: keyName
            })}
          </TotalGuaranteedPayoutLabel>
        </CumulativeGuranteedPayoutKey>
        {_.map(subFields, subField => {
          const {
            keyName: fieldKey,
            keyValue: fieldValue,
            generateLabelKey = false,
            decimalRange,
            labelValue
          } = subField;
          const val = payoutBenefitLegendsData[fieldValue];
          return (
            !!val && (
              <NonGuranteedPayout
                labelValue={labelValue}
                id={legendSectionID}
                currencyCode={policyCurrency}
                amount={val}
                decimalRange={decimalRange}
                formatMessage={formatMessage}
                labelKey={getPayoutAnnualLegendLabel(
                  productCategory,
                  fieldKey,
                  generateLabelKey
                )}
              />
            )
          );
        })}
        <TotalGuranteedPayoutKey aria-hidden="true" styleUpdate />
      </>
    );
  }
  return <></>;
};

PayoutAnnualTotalNonGuranteed.propTypes = {
  productCategory: PropTypes.string.isRequired,
  legendSectionID: PropTypes.string.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  legends: PropTypes.array.isRequired,
  payoutBenefitLegendsData: PropTypes.object.isRequired
};

NonGuranteedPayout.propTypes = {
  labelKey: PropTypes.string.isRequired,
  labelValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  decimalRange: PropTypes.number.isRequired,
  formatMessage: PropTypes.func.isRequired
};

PayoutAnnualTotalNonGuranteed.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PayoutAnnualTotalNonGuranteed;
