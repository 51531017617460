import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldAmountCurrency,
  LevyTextWrapper
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';

const PremiumAmountFieldGI = (props, context) => {
  const {
    id,
    fieldData,
    toolTipContent,
    labelContent,
    dependentComponentData
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  if (!labelContent || !fieldData || typeof fieldData !== 'object') {
    return <></>;
  }

  if (!fieldData.currency || (!fieldData.amount && fieldData.amount !== 0)) {
    return <></>;
  }

  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper
        {...props}
        id={id}
        labelContent={labelContent}
        toolTipContent={toolTipContent}
      />
      <OverviewInfoFieldAmountCurrency>
        <Currency currencyCode={fieldData.currency} amount={fieldData.amount} />
      </OverviewInfoFieldAmountCurrency>
      {dependentComponentData && (
        <LevyTextWrapper>
          {formatMessage({ id: 'premium_Levy_Gi_Text' })}
        </LevyTextWrapper>
      )}
    </PaymentInfoFieldWrapper>
  );
};

PremiumAmountFieldGI.propTypes = {
  id: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentComponentData: PropTypes.object,
  labelContent: PropTypes.string.isRequired
};

PremiumAmountFieldGI.defaultProps = {
  toolTipContent: null,
  dependentComponentData: null
};

PremiumAmountFieldGI.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PremiumAmountFieldGI;
