import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold,
  DurationSpan
} from '../../detailPageStyle';
import DateComponent from '../../../common/date/Date';

const OverviewTravelDurationField = (props, context) => {
  const {
    intl: { locale, formatMessage }
  } = context;
  const {
    labelContent,
    fieldData,
    dependentFieldData,
    dependentContentData
  } = props;
  if (
    !labelContent ||
    !fieldData ||
    typeof fieldData !== 'string' ||
    !dependentFieldData
  ) {
    return <></>;
  }
  return (
    <OverviewFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>
          <DateComponent
            date={fieldData}
            locale={locale}
            apiDateFormat="YYYY-MM-DD"
            id="standaradDateFormat"
          />
          <DurationSpan>
            {formatMessage({ id: dependentContentData })}
          </DurationSpan>
          <DateComponent
            date={dependentFieldData}
            locale={locale}
            apiDateFormat="YYYY-MM-DD"
            id="standaradDateFormat"
          />
        </DataTextBold>
      </OverviewInfoFieldDataValue>
    </OverviewFieldWrapper>
  );
};

OverviewTravelDurationField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.string.isRequired,
  dependentContentData: PropTypes.string
};

OverviewTravelDurationField.defaultProps = {
  toolTipContent: null,
  dependentContentData: ''
};

OverviewTravelDurationField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default OverviewTravelDurationField;
