import styled from 'styled-components';
import { rem, em } from 'polished';
import { FontFamily } from '../utils/font';
import { Section } from '../common/styles/commonStyle';
import media from '../utils/media';
import theme from '../common/styles/theme';

export const ReprojectionLinkWrapper = styled.dt`
  line-height: ${rem('24px')};
  display:flex;
  justify-content: flex-end;
  > a {
    padding:0
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${theme.whiteColor};
    border: none;
    color: ${theme.fontColor};
    text-decoration:none;
    &:focus, &:hover {
      outline: 1px dotted black;
    }
  }
  ${media.phone`
    display: block;
  `}
`;

export const ReprojectionLabel = styled.span`
  display: block;
  margin-right: ${rem('9px')};
  ${media.phone`
    width:100%;
  `}
`;

export const ReprojectionImgWrapper = styled.span`
  width: ${rem('8px')};
  height: ${rem('12px')};
  display: block;
  position: relative;
  top: -1px;
  ${media.phone`
  width:${rem('9.7px')};
  height:${rem('16px')};
  top:0;
`}
`;

export const ReprojectionHeader = styled.span`
  font-size: ${rem('35px')};
  ${FontFamily.RegularFontW01};
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  color: #333;
`;

export const ReprojectionFieldRow = styled.div`
  display: flex;
  align-items: baseline;
  font-size: ${rem('14px')};
  ${FontFamily.RegularFontW01};
  line-height: ${rem('22px')};
  color: ${theme.greyNumberColor};
`;

export const Row = styled.div`
  display: flex;
`;
export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ProjectedSummaryRow = styled.div`
  padding-bottom: ${rem('40px')};
  display: flex;
  ${media.tablet`
  flex-direction: column;
  padding-bottom:${rem('30px')}
 `}
`;

export const FutureSection = styled(Section)`
  margin: 0 auto;
  ${media.tablet`
 padding: 0 24px;
`}
  ${media.phone`
 padding: 0 15px;
`}
`;
export const FullWidthFutureSection = styled.section`
  display: block;
  width: 100%;
  background: white;
`;
export const DisclaimersSection = styled(Section)`
  display: block;
  width: 100%;
  background: #f3f3f3;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  ${media.tablet`
  padding: 20px 24px 0 24px;
 `}
  ${media.phone`
 padding: 20px 15px 0 15px;
`}
`;
export const FutureSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem('40px')} 0 ${rem('33px')} 0;
  box-sizing: border-box;
  border-top: 1px solid ${theme.lightGreyColor};
  color: ${theme.fontColor};
  ${media.tablet`
  padding: ${rem('30px')} 0 ${rem('33px')} 0;
`}
  ${media.phone`
  padding: ${rem('20px')} 0 ${rem('15px')} 0;
  flex-direction: column;
  align-items: flex-start;
`}
`;
export const HeaderContentRow = styled.h2`
  font-size: ${rem('35px')};
  ${FontFamily.LightFontW01};
  line-height: ${rem('32px')};
  ${media.phone`
font-size: ${rem('23px')};
margin-bottom: 10px;
`}
`;

export const ValuationText = styled.span`
  margin-right: ${rem('5px')};
`;
export const ValuationDate = styled.span``;
export const HeaderDependentSpan = styled.span`
  font-size: ${rem('16px')};
  ${FontFamily.RegularFontW01};
  line-height: ${rem('22px')};
  letter-spacing: normal;
  margin-bottom: ${rem('38px')};
  ${media.tablet`
margin-bottom: ${rem('48px')};
`}
  ${media.phone`
font-size: ${rem('14px')};
margin-bottom: ${rem('30px')};
`}
`;

export const OverviewStatusNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.phone`
    flex-direction: column;
  `}
`;

export const OverviewNumber = styled.span`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  ${media.phone`
    margin-bottom: ${rem('9px')};
  `}
`;

export const ProjectedValueSummaryAgeText = styled.h2`
  padding-bottom: ${rem('26px')};
  font-size: ${em('28px')};
  ${FontFamily.LightFontW01};
  line-height: ${rem('32px')};
  ${media.phone`
  font-size: ${em('19px')};
  line-height:${rem('28px')};
  padding-bottom: ${rem('19px')};
  `}
`;

// ProjectedGain.js, wraps the entire Projected gain section
export const ProjectedGainWrapper = styled.div`
  height: ${rem('89px')};
  width: ${rem('250px')};
  background: ${theme.summaryBoxGrey};
  padding: ${rem('19px')} 0 ${rem('17px')} ${rem('30px')};
  ${media.tablet`
    padding: ${rem('20px')} ${rem('15px')} ${rem('15px')} ${rem('15px')};
    width: 100%;
    height: ${rem('81px')};
    box-sizing: border-box;
  `}
  ${media.phone`
    padding: ${rem('15px')} ${rem('15px')} ${rem('11px')} ${rem('15px')};
    height: ${rem('67px')};
    width: 100%;
    box-sizing: border-box;
  `}
`;

// ProjectedGain.js, wraps the header of the projected gain section
export const LabelContentWrapper = styled.div`
  font-size: ${rem('16px')};
  width: ${rem('176px')};
  height: ${rem('20px')};
  line-height: 1.25;
  color: ${theme.greyNumberColor};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal
  letter-spacing: normal;
  ${FontFamily.RegularFontW01};
  ${media.phone`
    width: ${rem('104px')};
    height: ${rem('18px')};
    line-height: 1.29;
    font-size: ${rem('14px')};
  `}
`;
// ProjectedGain.js, wraps everything but the header
export const ProjectedGainBodyWrapper = styled.dd`
  ${media.tablet`
    bottom: ${rem('33px')}
    text-align: right;
  `}
  ${media.phone`
    bottom: ${rem('25px')}
  `}
`;
// ProjectedGain.js, wraps the image(chevrron currently) and numeric value
export const FieldDataWrapper = styled.div`
  margin: ${rem('8px')} 0;
  ${media.phone`
    margin: ${rem('7px')} 0;
  `}
`;

// ProjectedGain.js, wraps the image (chevrron)
export const Chevrron = styled.img`
  position: relative;
  object-fit: contain;
  margin-right: ${rem('9px')};
  width: ${rem('17px')};
  height: ${rem('7px')};
  bottom: ${rem('3px')};
  object-fit: contain;
  ${media.phone`
    margin-right: ${rem('6px')};
    bottom: ${rem('1.5px')};
  `}
`;

// ProjectedGain.js, wraps the numeric value(projected gain value)
export const FieldDataBold = styled.span`
  width: ${rem('57px')};
  height: ${rem('28px')};
  ${FontFamily.BoldFontW01};
  font-size: ${rem('20px')};
  line-height: 1.4;
  color: ${theme.fontColor};
  ${media.phone`
    width: ${rem('40px')};
    height: ${rem('18px')};
    font-size: ${rem('14px')};
    line-height: 1.29;
  `}
`;

// ProjectedGain.js, wraps the footer at the bottom
export const ConstantFieldData = styled.span`
  width: ${rem('141px')};
  height: ${rem('18px')};
  ${FontFamily.RegularFontW01};
  font-size: ${rem('14px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.fontColor};
  ${media.tablet`
    height: ${rem('15px')};
  `}
  ${media.phone`
    width: ${rem('121px')};
    height: ${rem('16px')};
    font-size: ${rem('12px')};
  `}
`;

export const FieldBoxContainer = styled.dl`
  margin: 0 20px 0 0;
  padding: 20px 30px;
  min-height: 125px;
  width: 280px;
  box-sizing: border-box;
  background-color: ${theme.summaryBoxGrey};
  ${media.tablet`
   width: 100%;
   min-height: 58px;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: space-between;
    margin: 0 0 7px 0;
    padding:20px 15px;
    align-items: baseline;
 `}
  ${media.phone`
   width: 100%;
    margin: 0;
    margin: 0 0 7px 0;
    padding:15px 15px;
    min-height: 48px;
 `}
`;
export const FieldLabel = styled.dt`
  margin: 0 0 12px 0;
  padding: 0;
  ${FontFamily.RegularFontW01};
  font-size: ${rem('16px')};
  color: ${theme.greyNumberColor};
  line-height: ${rem('18px')};
  box-sizing: border-box;
  ${media.tablet`
  margin:0
  width:50%;
  padding-right: 3px;
 `}
  ${media.phone`
 font-size: ${rem('14px')};
 width:47%;
 padding-right: 3px;
`}
`;
export const FieldValue = styled.dd`
margin:0
padding:0;
span {
  font-size: ${rem('16px')};
  color:${theme.fontColor}
  line-height:${rem('18px')};
  span {
    ${FontFamily.BoldFontW01};
    font-size: ${rem('20px')};
    line-height:${rem('28px')};
    margin-right: 6px;
  }
}
${media.tablet`
width:50%;
text-align: right;
`}
${media.phone`
 width:53%;
  span{
    font-size: ${rem('14px')};
    span{
      font-size: ${rem('14px')};
    }
  }
`}

`;

// culmulative payout box style
export const PayoutFieldWrapper = styled.div`
  display: flex;
  margin: 0 20px 0 0;
  padding: 20px 27px;
  min-height: 125px;
  width: ${props =>
    props.isPayoutFieldEmpty === false ? `${rem('280px')}` : `${rem('580px')}`};
  box-sizing: border-box;
  background-color: ${theme.summaryBoxGrey};
  flex-direction: column;
  ${media.tablet`
  width:100%;
  height: auto;
  min-height:58px;
  margin: 0 0 7px 0;
  padding: ${props =>
    props.isPayoutFieldEmpty === false ? `20px` : `15px 15px 0 15px`};
  flex-direction: column;
`}
  ${media.phone`
  padding: ${props =>
    props.isPayoutFieldEmpty === false ? `15px` : `15px 15px 0 15px`};
`}
`;
export const AnnuityField = styled.dl`
  width: ${props => (props.isPayoutFieldEmpty === false ? `100%` : `40%`)};
  box-sizing: border-box;
  ${media.tablet`
  width:100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-right: none;
  padding-bottom:${props => (props.isPayoutFieldEmpty === false ? `` : `18px`)};
`}
  ${media.phone`
padding-bottom: ${rem('15px')};
`}
`;
export const AnnuityLabel = styled.dt`
  ${FontFamily.RegularFontW01};
  font-size: ${rem('16px')};
  color: ${theme.fontColor};
  line-height: ${rem('18px')};
  margin-bottom: 10px;
  padding-right: ${rem('3px')};
  box-sizing: border-box;
  ${media.tablet`
margin-bottom: 0;
padding-right: 0;

font-size: ${rem('16px')};
`}
  ${media.phone`
font-size: ${rem('14px')};
width: 47%;
`}
`;
export const AnnuityValue = styled.dd`
span {
  font-size: ${rem('16px')};
  color:${theme.fontColor}
  line-height:${rem('18px')};
  span {
    ${FontFamily.BoldFontW01};
    font-size: ${rem('20px')};
    line-height:${rem('28px')};
    margin-right: 6px;
  }
}

${media.phone`
  span{
    font-size: ${rem('14px')};
    span{
      font-size: ${rem('14px')};
    }
  }
  width: 53%;
  text-align: right;
`}
`;
export const GuaranteedWrapper = styled.dl`
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 8px 0 0 14px;
  position: relative;
  justify-content: center;
  min-height: 85px;
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: ${theme.bgGreyColor};
    left: 0;
    top: 0;
  }
  ${media.tablet`
  width:100%;
  padding: 15px 0 0 0;
  min-height:auto;
  &:after{
    height:1px;
    width:100%;
    background:${theme.bgGreyColor};
    top:0;
  }
`}
`;

export const GuaranteedLabel = styled.dt`
  font-size: ${rem('14px')};
  margin-bottom: 3px;
  width: 53%;
  min-height: 28px;
  ${media.tablet`
  min-height:auto;
  padding-bottom: 0;
`}
  ${media.phone`
  width: 47%;
`}
`;
export const GuaranteedValue = styled.dd`
  width: 47%;
  text-align: right;
  span {
    font-size: ${rem('14px')};
    color: ${theme.fontColor};
    span {
      ${FontFamily.BoldFontW01};
      font-size: ${rem('14px')};
      margin-right: 6px;
    }
  }
  ${media.phone`
  width: 53%;
`}
`;
export const CashValueBox = styled.div`
  display: flex;
  ${media.tablet`
flex-direction: column;
`}
`;
export const GuaranteedRow = styled.div`
  display: flex;
`;

export const FutureBenefitsTabWrapper = styled.div``;

export const FutureBenefitsMobileTabsWrapper = styled.div`
  div[role='combobox'] {
    box-shadow: inset ${rem('5px')} 0 0 0 ${theme.stepSelected};
  }
`;

export const FutureBenefitsTab = styled.div`
  ul {
    padding: 0;
    box-shadow: inset 0 ${rem('-1px')} 0 0 ${theme.lightGreyColor};
    border-bottom: none;
    li {
      padding: ${rem('11px')} ${rem('20px')} ${rem('11px')} ${rem('20px')};
      margin-right: ${rem('1px')};
      span {
        font-weight: normal;
        line-height: ${rem('24px')};
        padding: 0;
        border-bottom: none;
      }
    }
  }
`;

export const CustomTooltip = styled.div`
  background-color: ${theme.whiteColor};
  position: fixed;
  min-height: ${props =>
    props.id === 'payoutBenefitsChart' ? `${rem('21px')}` : `${rem('75px')}`};
  height: auto;
  min-width: ${props =>
    props.id === 'payoutBenefitsChart' ? `${rem('51px')}` : `${rem('160px')}`};
  max-width: ${rem('217px')};
  color: ${theme.fontColor};
  padding: ${rem('12px')} ${rem('15px')};
  border: 1px solid ${theme.subLinksText};
  box-sizing: border-box;
  box-shadow: ${props =>
    props.id === 'payoutBenefitsChart'
      ? `0 0 ${rem('3px')} 0 rgba(189, 189, 189, 0.5)`
      : 'none'};
  > div:last-child {
    margin-bottom: 0;
  }
  ${media.phone`
  min-height: ${props =>
    props.id === 'payoutBenefitsChart' ? `${rem('29px')}` : `${rem('60px')}`};
  height:auto;
  min-width: ${props =>
    props.id === 'payoutBenefitsChart' ? `${rem('65px')}` : `${rem('100px')}`};
  max-width:${rem('156px')} ;
  padding: ${rem('8px')} ${rem('8px')};
`}
`;

export const ChartDiv = styled.div`
  position: relative;
`;

export const ChartTitle = styled.h3`
  margin: ${rem('38px')} 0 ${rem('22px')} 0;
  height: auto;
  width: 95%;
  font-size: ${rem('28px')};
  color: ${theme.fontColor};
  line-height: ${rem('32px')};
  ${FontFamily.LightFontW01};
  ${media.tablet`
    margin: ${rem('28px')} 0 ${rem('22px')} 0;
  `}
  ${media.phone`
    padding: 0 0 0 ${rem('15px')};
    font-size: ${rem('19px')};
    line-height: ${rem('28px')};
    margin: ${rem('20px')} 0 ${rem('22px')} 0;
  `}
`;

export const BubbleDiv = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: flex-start;
  ${media.phone`
    margin-bottom:6px;
  `}
`;

export const BubbleImgSpan = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  background: url(${props => props.imagePath}) ${props => props.imagePosition};
  background-repeat: no-repeat;
  height: 18px;
  width: 25px;
  display: block;
  margin-right: 12px;
  ${media.phone`
    margin-right:6px;
`}
`;

export const BubbleDataSpan = styled.span`
  word-break: break-all;
  white-space: pre-wrap;
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  ${props => (props.styleUpdate ? `${FontFamily.BoldFontW01}` : '')}
  ${props =>
    props.dataCurrencySpan
      ? `
  span span {
    margin-right: 4px;
  }
  `
      : ''}
  ${media.phone`
font-size:${rem('10px')};
`}
`;

export const BubbleLabelSpan = styled.span`
  word-break: break-all;
  white-space: pre-wrap;
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  ${FontFamily.BoldFontW01};
  margin-right: 6px;
  ${media.phone`
font-size:${rem('10px')};
`}
`;

export const ReprojectionHorizontalLine = styled.div`
  margin-top: ${rem('20px')};
  border-top: 1px solid ${theme.lightGreyColor};
`;

export const AnnualAnnuityPayoutWrapper = styled.div``;

export const CashSurrenderSubHeaderDiv = styled.div`
  display: flex;
  padding: ${rem('10px')} 0 0 0;
  ${media.phone`
    padding: 0 ${rem('15px')};
  `}
`;
export const GraphInfoSubHeaderDiv = styled.div`
  ${media.phone`
    padding: 0 ${rem('15px')} ${rem('10px')};
  `}
`;

export const InfoIconSpan = styled.span`
  display: block;
  margin-right: ${rem('12px')};
  position: relative;
  top: 1px;
  ${media.miniPhone`
    top:2px
  `}
`;
export const InfoIconImg = styled.img`
  height: ${rem('18px')};
  width: ${rem('18px')};
`;
export const InfoMessageSpan = styled.span`
  font-size: ${em('14px')};
  line-height: ${rem('22px')};
  display: block;
`;

export const ReprojectionsPageMiddleSection = styled(Section)`
  margin-top: ${rem('20px')};
`;

export const ColumnChartDiv = styled.div`
  rect {
    cursor: pointer;
  }
  rect[stroke-opacity] {
    stroke-width: 1 !important;
  }
`;

export const LoaderDiv = styled.div`
  text-align: center;
`;

export const ReprojectionImageSectionWrapper = styled.div`
  margin-top: ${rem('24px')};
  ${media.phone`
      margin-top: ${rem('20px')};
      padding: 0 ${rem('15px')};
    `}
`;

export const ImageSectionDiv = styled.div`
  width: 100%;
  box-sizing: border-box;
  min-height: ${rem('179px')};
  border: 1px solid #d7d8d5;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  padding: ${rem('14px')} ${rem('201px')} ${rem('13px')} ${rem('100px')};
  ${media.tablet`
    padding: ${rem('10px')} ${rem('24px')} ${rem('10px')} ${rem('10px')};
  `};
  ${media.phone`
    flex-direction: column;
    min-height: ${rem('296px')};
    padding: ${rem('7px')} ${rem('16px')} ${rem('15px')} ${rem('16px')};
  `}
`;

export const ReprojectionImage = styled.img`
  width: ${rem('278px')};
  height: ${rem('151px')};
  margin: auto ${rem('20px')} auto 0;
  ${media.tablet`
    margin: auto ${rem('12px')} auto 0;
  `}
  ${media.phone`
    margin: 0 auto ${rem('12px')} auto;
  `}
`;

export const ReprojectionImageContent = styled.div`
  color: ${theme.fontColor};
  ${FontFamily.RegularFontW01};
  margin: auto 0 auto 0;
  ${media.phone`
    margin: 0;
  `}
`;
export const ReprojectionImageContentHeader = styled.div`
  font-size: ${rem('22px')};
  line-height: ${rem('32px')};
  margin: 0 0 ${rem('8px')} 0;
  ${media.tablet`
    margin: 0 0 ${rem('8px')} 0;
  `}
  ${media.phone`
    margin: 0 0 ${rem('10px')} 0;
    font-size: ${rem('18px')};
  `}
`;
export const ReprojectionImageContentBody = styled.div`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  box-sizing: border-box;
`;

export const BonusNotificationWrapper = styled.div`
  margin: 40px 0;
  padding: 40px;
  box-sizing: border-box;
  border: 1px solid ${theme.lightGreyColor};
  ${media.tablet`
  margin: 30px 0;
  `}
  ${media.phone`
    margin: 20px;
    padding:20px;
  `}
`;
export const BonusNotificationHeader = styled.p`
  display: flex;
  margin-bottom: 6px;
`;
export const BonusNotificationHeaderText = styled.span`
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  ${FontFamily.MediumFontW01};
  display: flex;
`;
export const BonusNotificationIcon = styled.span``;
export const BonusNotificationBody = styled.div`
  font-size: ${rem('16px')};
  line-height: ${rem('24px')};
  ${FontFamily.RegularFontW01};
  padding-left: 30px;
`;

export const ChartImage = styled.img`
  height: ${rem('18px')};
  width: ${rem('18px')};
  margin-right: ${rem('10px')};
  position: relative;
  top: 2px;
`;
