import styled, { css } from 'styled-components';
import { FontFamily } from '../../utils/font';
import Icon from '../icon/Icon';
import media from '../../utils/media';

export const Title = styled.h2`
  font-weight: normal;
  margin-top: 0;
  ${FontFamily.LightFont};

  margin-bottom: 0;
  color: #333333;
  ${props =>
    props.showPopup
      ? css`
          font-size: 33px;
        `
      : css`
          font-size: 38px;
        `};
  ${media.phone`
	${props =>
    props.showPopup
      ? css`
          font-size: 28px;
        `
      : css`
          font-size: 38px;
        `};
		`};
`;
export const ImgSpan = styled.div`
  display: inline-block;
  margin: 6px;
`;

export const ConditionalText = styled.div`
  display: inline-block;
  margin-top: 14px;
  width: 80%;
`;

export const DeleteIcon = styled.div`
  display: ${props => (props.showDeleteIcon ? 'block' : 'none')};
`;

export const DeleteIconStyle = css`
  font-size: 20px;
`;

export const Description = styled.div`
  opacity: ${props => props.opacity};
  margin-bottom: ${props => (props.isFixFooter ? '50px' : '0')}
    ${props =>
      props.isFixFooter
        ? `overflow-y:auto;
          max-height:calc(100% - 130px);
         `
        : `overflow-y:initial;
          max-height:auto;
         `};
`;

export const FooterDivider = styled.hr`
  border-style: solid;
  border-top: none;
  border-color: #d7d8d6;
`;

export const FooterTopBorder = styled(FooterDivider)`
  margin-bottom: 20px;
`;

const ModalFooterCss = css`
  clear: both;
`;

export const ModalFooter = styled.div`
  ${props =>
    props.isFixFooter
      ? `
            z-index: 1;
            position: fixed;
            bottom: 0;
            background-color: white;
            width: 100%;
            margin: 0;
            left: 0;
            right: 0;
            padding: 15px;
            box-sizing: border-box;
            border-top:${props.showFooterTopBorder ? 'none' : '1px solid'};
            border-top-color: #333333;
        `
      : ModalFooterCss};
`;

export const ModalCancel = styled.a`
  cursor: pointer;
  position: relative;
  top: 17px;
  float: left;
`;
const getModalButtonWidth = props => {
  const { role, dynamicWidth } = props;
  if (role === 'dialog') {
    return dynamicWidth > 500 ? '' : '100%';
  }
  return dynamicWidth > 700 ? '45%' : '100%';
};
const getModalContinueWidth = props => {
  const { role, dynamicWidth } = props;
  if (role === 'dialog') {
    return dynamicWidth > 500 ? '45%' : '100% !important';
  }
  return dynamicWidth > 700 ? '45%' : '100% !important';
};
const getButtonMarginBotton = props => {
  const { role, dynamicWidth } = props;
  if (role === 'dialog') {
    return dynamicWidth > 500 ? '' : '10px';
  }
  return dynamicWidth > 700 ? '45%' : '10px';
};

export const ModalButton = styled.div`
  float: left;
  padding-right: 10px;
  margin-top: "0"
  width: ${props => getModalButtonWidth(props)};
  margin-bottom: ${props => getButtonMarginBotton(props)};
`;

export const ModalContinue = styled.div`
  float: 'left;
  padding-left: '10px';
  width: ${props => getModalContinueWidth(props)};
  margin-bottom: ${props => getButtonMarginBotton(props)};
  padding-left: 0;
`;

export const backIcon = css`
  color: #db0011;
  vertical-align: initial;
`;

export const CloseIconOverlay = styled(Icon)`
  cursor: pointer;
  &:focus {
    outline: 1px dotted #333;
    outline: -webkit-focus-ring-color auto 5px;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    &:focus {
      outline: 1px dotted #333;
    }
  }
  ${props => (props.closeIconStyles ? props.closeIconStyles : null)};
`;
