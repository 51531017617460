import reduce from 'lodash/reduce';
import { localeToCookie, cookieToLocale } from '../configure/localeDataConfig';
import LocaleManager from '../utils/localeManager';

const locale = LocaleManager.getLocaleForHSBCWebApps() || 'en_HK';
LocaleManager.setLocaleForHSBCWebApps(locale);

export const INITIAL_STATE = {
  selectedLocale: cookieToLocale[locale],
  langObj: {}
};

export const setPreviewContent = (state, payload) => {
  const { content: data = {} } = payload;
  const { langObj } = state;

  if (Object.keys(langObj).length > 0) {
    const previewContent = reduce(
      langObj,
      (preview, langCon, key) => {
        const newPreviewContent = { ...preview };
        newPreviewContent[key] = {
          content: {
            ...langCon.content,
            ...data[key].content
          }
        };
        return newPreviewContent;
      },
      {}
    );

    return { ...state, langObj: previewContent };
  }
  return state;
};

const contentReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
  switch (type) {
    case 'CHANGE_LOCALE':
      LocaleManager.setLocaleForHSBCWebApps(localeToCookie[payload]);
      return { ...state, selectedLocale: payload };
    case 'ADD_LANG_DATA':
      return { ...state, langObj: payload };
    case 'LOGGER_READY':
      return { ...state, loggerConnected: true };
    case 'MERGE_PREVIEW_CONTENT':
      return setPreviewContent(state, payload);
    default:
      return state;
  }
};

export default contentReducer;
