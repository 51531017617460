import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import iconRight from '../../../assets/images/Icon_right.svg';
import redDot from '../../../assets/images/red-dot.svg';
import { ReprojectionLabel } from '../../../reprojection/reprojectionPageStyle';
import {
  getModifyUpdateJourneyUrl,
  getNonPersonalRelationshipStatus
} from '../../../utils/BeneficiaryUtils';
import BeneficiarySectionModal from './BeneficiarySectionModal';
import {
  BeneficiaryPrimaryWrapper,
  ChevronDotContainer,
  IconRightImg,
  MissingBeneDot,
  ProjectedDeathBenefitsAmountCard,
  ProjectedDeathBenefitsSecondaryFooter,
  BeneficiaryLinkWrapper,
  RightIconImgWrapper
} from './beneficiarySectionStyles';
import BeneficiarySectionTableContainer from './BeneficiarySectionTableContainer';

const BeneficiarySecondarySection = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    nothingDataStore,
    secondaryBeneficiariesList,
    rawSecondaryBeneficiariesList,
    isModalOpen,
    linkRefSecondary,
    onCancelClick,
    updateModal,
    irrevocableAssigned,
    assigned,
    encodedProductId,
    changeBeneficiaryService,
    canSuppressBeneficiaryLink
  } = props;

  const [showSection, setshowSection] = useState(false);
  const [isMissing, setIsMissing] = useState(false);
  const [isNonPersonalRelation, setPersonalRelation] = useState(false);

  useEffect(() => {
    setshowSection(!nothingDataStore);
  }, [nothingDataStore]);

  const hetBtnTitle = formatMessage({
    id: 'update_Secondary_Beneficiaries'
  });
  const onClickUpdateAction = e => {
    e.preventDefault();
    const url = getModifyUpdateJourneyUrl(
      changeBeneficiaryService,
      encodedProductId
    );

    if (
      !irrevocableAssigned &&
      !assigned &&
      url !== '' &&
      !isNonPersonalRelation
    ) {
      window.localStorage.setItem('selectedBeneficiaryType', 'secondary');
      window.location.href = encodeURI(url);
      return false;
    }
    updateModal(!isModalOpen);
    return true;
  };

  useEffect(() => {
    if (rawSecondaryBeneficiariesList.length > 0) {
      setIsMissing(_.some(rawSecondaryBeneficiariesList, 'missingInfo'));
      setPersonalRelation(
        getNonPersonalRelationshipStatus(rawSecondaryBeneficiariesList)
      );
    }
  }, [rawSecondaryBeneficiariesList]);

  return showSection ? (
    <>
      <BeneficiarySectionTableContainer
        primaryBeneficiariesList={secondaryBeneficiariesList}
        rawPrimaryBeneficiariesList={rawSecondaryBeneficiariesList}
        linkRefPrimary={null}
        linkRefSecondary={linkRefSecondary}
        type="secondary"
        nothingDataStore={nothingDataStore}
      />
      <ProjectedDeathBenefitsAmountCard>
        <ProjectedDeathBenefitsSecondaryFooter>
          {!canSuppressBeneficiaryLink && (
            <BeneficiaryLinkWrapper>
              <Link
                to="/"
                id="update_secondary"
                aria-label={`${hetBtnTitle}`}
                onClick={e => onClickUpdateAction(e)}
              >
                <BeneficiaryPrimaryWrapper>
                  <ReprojectionLabel>{hetBtnTitle}</ReprojectionLabel>
                </BeneficiaryPrimaryWrapper>
                <RightIconImgWrapper>
                  <ChevronDotContainer>
                    <IconRightImg aria-hidden="true" alt="" src={iconRight} />
                    {isMissing && (
                      <MissingBeneDot
                        aria-hidden="true"
                        alt={formatMessage({
                          id: 'missing_secondary_beneficiary_alt_txt'
                        })}
                        src={redDot}
                      />
                    )}
                  </ChevronDotContainer>
                </RightIconImgWrapper>
              </Link>
            </BeneficiaryLinkWrapper>
          )}
          <BeneficiarySectionModal
            isModalOpen={isModalOpen}
            onCancelClick={onCancelClick}
            irrevocableAssigned={irrevocableAssigned}
            assigned={assigned}
            encodedProductId={encodedProductId}
            isNonPersonalRelation={isNonPersonalRelation}
          />
        </ProjectedDeathBenefitsSecondaryFooter>
      </ProjectedDeathBenefitsAmountCard>
    </>
  ) : null;
};

BeneficiarySecondarySection.propTypes = {
  nothingDataStore: PropTypes.bool.isRequired,
  secondaryBeneficiariesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  rawSecondaryBeneficiariesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  irrevocableAssigned: PropTypes.bool.isRequired,
  assigned: PropTypes.bool.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  updateModal: PropTypes.func.isRequired,
  linkRefSecondary: PropTypes.objectOf(PropTypes.any).isRequired,
  changeBeneficiaryService: PropTypes.objectOf(PropTypes.any),
  canSuppressBeneficiaryLink: PropTypes.bool.isRequired
};

BeneficiarySecondarySection.contextTypes = {
  intl: PropTypes.object.isRequired
};

BeneficiarySecondarySection.defaultProps = {
  changeBeneficiaryService: null
};

const mapStateToProps = state => {
  const {
    configurations: {
      genericConfig: {
        servicingOptions: {
          digiService: { ChangeBeneficiaryPIB }
        }
      }
    }
  } = state;
  return {
    changeBeneficiaryService: ChangeBeneficiaryPIB
  };
};

export default connect(mapStateToProps, null)(BeneficiarySecondarySection);
