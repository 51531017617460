import { select, takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { CANCEL_REQUEST } from '../actionTypes';
import {
  setCancelRequestSuccess,
  setCancelRequestErrorsorWarnings,
  updateLoaderOpacity,
  startLoader,
  endLoader
} from '../app/appActions';
import {
  prepareServiceConfigPAPI,
  changeRequestCategoryForInstruction,
  papiErrorHandler
} from '../utils/sagaUtils';

const getCustomerIdentifier = state => state.customer.customerIdentifier;
const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;
const getInstructionList = state => state.instHistory.instHistoryResponse;

export function* cancelInstructionDetails(cancelInstructionParams) {
  try {
    yield put(updateLoaderOpacity(true));
    yield put(startLoader());
    const customerIdentifier = yield select(getCustomerIdentifier);
    const configurationObj = yield select(getConfigurations);
    if (customerIdentifier) {
      const {
        genericConfig: { commonHeaders },
        serviceConfig: { cancelInstruction }
      } = configurationObj;
      const saasAuth = yield select(getSaasToken);
      const headers = {
        ...commonHeaders,
        Authorization: `Bearer ${saasAuth}`,
        'X-HSBC-CustomerId': customerIdentifier
      };
      const {
        referenceNo,
        encodePolicyNumber
      } = cancelInstructionParams.payload;
      const serviceConfigUrl = cancelInstruction.url.replace(
        '{referenceNo}',
        referenceNo
      );
      const requestMethod = 'PATCH';

      const cancelRequestConfig = prepareServiceConfigPAPI(
        cancelInstruction,
        serviceConfigUrl,
        headers,
        requestMethod,
        {}
      );

      const cancelRequestResponse = yield call(axios, cancelRequestConfig);
      const cancelRequestResponseData = cancelRequestResponse.data;
      const cancelPayloadData = { [referenceNo]: cancelRequestResponseData };
      yield put(setCancelRequestSuccess(cancelPayloadData));
      const instructionList = yield select(getInstructionList);
      const updateInstructionHistoryList = changeRequestCategoryForInstruction(
        referenceNo,
        encodePolicyNumber,
        instructionList
      );
      yield put(updateInstructionHistoryList);
    }
    yield put(updateLoaderOpacity(false));
    yield put(endLoader());
  } catch (err) {
    const { referenceNo } = cancelInstructionParams.payload;
    if (err && err.response) {
      const errorDispatchObject = papiErrorHandler(err.response);
      const { message } = errorDispatchObject;
      yield put(
        setCancelRequestErrorsorWarnings({
          [referenceNo]: {
            errors: [
              {
                errorCode: errorDispatchObject['exception-code'],
                causes: message
              }
            ]
          }
        })
      );
    } else {
      yield put(
        setCancelRequestErrorsorWarnings({
          [referenceNo]: {
            errors: [
              {
                errorCode: 'UI0001',
                causes: 'Invalid Request'
              }
            ]
          }
        })
      );
    }
    yield put(updateLoaderOpacity(false));
    yield put(endLoader());
  }
}

export default function* cancelRequestSaga() {
  yield takeEvery(CANCEL_REQUEST, cancelInstructionDetails);
}
