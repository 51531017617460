import React from 'react';
import PropTypes from 'prop-types';
import FundsRiskLevelDetails from './FundsRiskLevelDetails';
import {
  BenefitsSubSectionWrapper,
  SectionFooterLinkWrapper
} from '../../sections/SectionStyle';

import { FundDisclaimer, RiskRatingDisclaimer } from '../../detailPageStyle';

const FundsAllocationWithSectionLinks = function FundsAllocationWithSectionLinks(
  props,
  context
) {
  const {
    getAllocationTableDataComponent,
    topPadding,
    getFundPerformanceLink,
    renderSurrenderSubSection,
    getServicingTFFLink,
    getServicingWithdrawalLink,
    getServicingCPALink
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  return (
    <>
      {getAllocationTableDataComponent}
      <RiskRatingDisclaimer topPadding={topPadding}>
        <FundsRiskLevelDetails />
        {getFundPerformanceLink}
      </RiskRatingDisclaimer>
      {renderSurrenderSubSection}
      <BenefitsSubSectionWrapper />
      <SectionFooterLinkWrapper isFundSection>
        {getServicingTFFLink}
        {getServicingWithdrawalLink}
        {getServicingCPALink}
      </SectionFooterLinkWrapper>
      <FundDisclaimer id="fund_section_disclaimer">
        <span>{formatMessage({ id: 'fundSectionDisclaimer_line1' })}</span>
      </FundDisclaimer>
    </>
  );
};

FundsAllocationWithSectionLinks.propTypes = {
  getAllocationTableDataComponent: PropTypes.bool.isRequired,
  topPadding: PropTypes.string.isRequired,
  getFundPerformanceLink: PropTypes.func.isRequired,
  renderSurrenderSubSection: PropTypes.func.isRequired,
  getServicingTFFLink: PropTypes.func.isRequired,
  getServicingWithdrawalLink: PropTypes.func.isRequired,
  getServicingCPALink: PropTypes.func.isRequired
};

FundsAllocationWithSectionLinks.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundsAllocationWithSectionLinks;
