import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import TableNameField from './TableNameField';
import TableNameWithImage from './TableNameWithImage';
import { Table, Tbody, Thead, Tr, Th } from './beneficiarySectionStyles';

function BeneficiaryTabelField(props, context) {
  const {
    fieldData,
    type,
    rawData,
    irrevocableAssigned,
    beneficiaryType
  } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (rawData.length > 0) {
      setData(rawData);
    }
  }, [rawData]);

  const {
    intl: { formatMessage }
  } = context;
  if (!fieldData || typeof fieldData !== 'object' || fieldData.length < 1) {
    return <></>;
  }
  const fieldNames = Object.keys(fieldData[0]);

  return (
    <Table x-ms-format-detection="none">
      <Thead style={{ borderBottom: '1px solid {theme.lightGreyColor}' }}>
        <Tr>
          {_.map(fieldNames, fieldName => (
            <Th id={`${type}_${fieldName}`}>
              {formatMessage({ id: fieldName })}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {_.map(fieldData, (fieldDataInstance, rowIndex) => (
          <>
            <Tr boderColor="#d7d8d6">
              {_.map(fieldNames, (fieldName, index) => (
                <>
                  {index === 0 && (
                    <TableNameWithImage
                      id={index}
                      field={fieldDataInstance[fieldName]}
                      item={data[rowIndex]}
                      irrevocableAssigned={irrevocableAssigned}
                      rawData={rawData}
                      rowIndex={rowIndex}
                      isNameFieldEmpty={_.isEmpty(fieldDataInstance[fieldName])}
                      beneficiaryType={beneficiaryType}
                      missingInfoFlag={rawData[rowIndex]?.missingInfo}
                    />
                  )}
                  {index !== 0 && (
                    <TableNameField
                      id={fieldName + index}
                      item={fieldDataInstance[fieldName]}
                    />
                  )}
                </>
              ))}
            </Tr>
          </>
        ))}
      </Tbody>
    </Table>
  );
}

BeneficiaryTabelField.propTypes = {
  fieldData: PropTypes.array.isRequired,
  type: PropTypes.string,
  rawData: PropTypes.arrayOf(PropTypes.any),
  irrevocableAssigned: PropTypes.bool.isRequired,
  beneficiaryType: PropTypes.string.isRequired
};

BeneficiaryTabelField.defaultProps = {
  type: '',
  rawData: []
};

BeneficiaryTabelField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BeneficiaryTabelField;
