import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  canDisplayIndicativeGainLoss,
  getGainLossValues
} from '../../sectionUtils/FundUtils';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import { formatCurrency } from '../../../utils/formatter';
import projectedLossChevrron from '../../../assets/images/reprojections/projectedLossChevrron.svg';
import projectedGainChevrron from '../../../assets/images/reprojections/projectedGainChevrron.svg';
import {
  IndicativeDiv,
  IndicativeAmtImgDiv,
  IndicativeGainLossAmt,
  IndicativePercentage,
  IndicativeText,
  IndicativeAmtDiv,
  ChevrronImg
} from '../../detailPageStyle';

const IndicativeGainLoss = function IndicativeGainLoss(props, context) {
  const {
    fieldData,
    indicativeGainLossConfig: { planCodesIndicativeGainLossWPLP },
    dependentFieldData,
    planCode,
    labelContent,
    toolTipContent
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  if (_.isEmpty(fieldData)) {
    return null;
  }
  const { amount } = fieldData;
  const {
    gainLossLabel,
    gainLossAmount,
    gainLossPercentage
  } = getGainLossValues(amount, dependentFieldData, formatCurrency);

  const iconObj = {
    isNegative: projectedLossChevrron,
    isPositive: projectedGainChevrron
  };

  const gainLossColors = {
    isNegative: '#A8000B',
    isPositive: '#00847F'
  };

  return (
    <>
      {canDisplayIndicativeGainLoss(
        planCodesIndicativeGainLossWPLP,
        planCode
      ) && (
        <IndicativeDiv id="IndicativeDiv">
          <IndicativeText>
            {formatMessage({ id: `${labelContent}` })}
            <TooltipWrapper
              id="tooltip_id_indicativeGainLossTooltip"
              contentKey={toolTipContent}
              labelContent={toolTipContent}
              sectionHeading=" "
            />
          </IndicativeText>

          <IndicativeAmtDiv gainLossTextColor={gainLossColors[gainLossLabel]}>
            {iconObj[gainLossLabel] && (
              <IndicativeAmtImgDiv>
                <ChevrronImg
                  alt=""
                  iconType={gainLossLabel}
                  src={iconObj[gainLossLabel]}
                />
              </IndicativeAmtImgDiv>
            )}

            <IndicativeGainLossAmt>{gainLossAmount}</IndicativeGainLossAmt>
            <IndicativePercentage>{gainLossPercentage}</IndicativePercentage>
          </IndicativeAmtDiv>
        </IndicativeDiv>
      )}
    </>
  );
};

IndicativeGainLoss.propTypes = {
  fieldData: PropTypes.string.isRequired,
  indicativeGainLossConfig: PropTypes.object.isRequired,
  dependentFieldData: PropTypes.number.isRequired,
  planCode: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string.isRequired
};

IndicativeGainLoss.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default IndicativeGainLoss;
