import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import { formatCurrency } from '../../utils/formatter';
import Context from '../../app/Context';

/**
 *
 * @param {*} props
 *              currencyCode: The currency code(E.g HKD,EUR)
 *              amount: Number String.
 */
/**
 * Determine the currency code from the list of currency codes mapped to rendering formats.
 * If not present then we take the default.
 */
export const deriveDefinedCurrencyCode = (currencyCodes, inputCurrencyCde) => {
  return _.includes(currencyCodes, inputCurrencyCde)
    ? inputCurrencyCde
    : 'default';
};
/**
 * @param: formattedAmount is the amount value determined from formatCurrency function
 * @param: currencyCode is the currency code that is provided to the Currency Component
 * @param: definedCurrencyCodeMapping has default value if the currency code is not defined.
 *
 * replaceInCopyObj: Object that  contains values to be replaced in the currency format eg. "{amount}HKD" copy.
 */
export const getReplaceInCopyObject = (
  formattedAmount,
  currencyCode,
  definedCurrencyCodeMapping
) => {
  let replaceInCopyObj = { currencyValue: formattedAmount };
  if (definedCurrencyCodeMapping === 'default') {
    replaceInCopyObj = {
      ...replaceInCopyObj,
      currencyCode
    };
  }
  return replaceInCopyObj;
};

const Currency = ({ currencyCode, amount, decimalRange }) => {
  /**
   *  Some values for which we do not show the Currency Component.
   */
  const suppressedForValues = [undefined, null, ''];
  if (
    _.includes(suppressedForValues, currencyCode) ||
    _.includes(suppressedForValues, amount)
  ) {
    return null;
  }

  /**
   * Format the currency amount as defined in US INSDS-221
   */
  const formattedAmount = formatCurrency(amount, decimalRange);

  return (
    <Context.Consumer>
      {contextProvider => {
        const { currencyCodes } = contextProvider;
        const definedCurrencyCodeMapping = deriveDefinedCurrencyCode(
          currencyCodes,
          currencyCode
        );

        return (
          <>
            <FormattedHTMLMessage
              id={definedCurrencyCodeMapping}
              values={getReplaceInCopyObject(
                formattedAmount,
                currencyCode,
                definedCurrencyCodeMapping
              )}
            />
          </>
        );
      }}
    </Context.Consumer>
  );
};

Currency.contextTypes = {
  intl: PropTypes.object.isRequired
};
Currency.propTypes = {
  currencyCode: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  decimalRange: PropTypes.number
};

Currency.defaultProps = {
  decimalRange: 2
};
export default Currency;
