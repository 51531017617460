import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import chevronUp from '../../assets/images/cevrron-up.svg';
import chevronDown from '../../assets/images/cevrron-down.svg';
import {
  ToggleSectionWrapper,
  ToggleTitle,
  ToggleImgWrapper,
  ToggleDescription,
  ImagePath
} from './toggleHandlerStyle';
import { AccessibleHidden } from '../../common/styles/commonStyle';
import getContentInSpecificLang from '../../utils/localeUtils';
import { trackEvents } from '../../utils/tealiumUtils';

const ToggleHandler = function ToggleHandler(props, context) {
  const {
    children,
    sectionTitleFormat,
    expandedSectionId,
    titleId,
    transCode,
    referenceNo,
    fetchInstructionData,
    instructionDetail,
    documentDownloadLoaderHandler,
    langObj,
    requestTitleToCheck,
    source,
    customerSegment
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  const [isOpen, toggleAccordion] = useState(false);

  const callTealiumEvent = () => {
    const accordianEventContent = `${_.toLower(
      getContentInSpecificLang(`title_${transCode}`, 'en', langObj)
    )} ${referenceNo}`;

    trackEvents({
      eventName: 'onInstructionHistoryAccordian',
      eventContent: `${_.lowerCase(
        requestTitleToCheck
      )}:${accordianEventContent}:${isOpen ? 'expand' : 'collapse'}`,
      customerSegment
    });
  };

  const fetchInstructionList = () => {
    if (isOpen) {
      toggleAccordion(!isOpen);
    } else {
      documentDownloadLoaderHandler(true);
      fetchInstructionData(transCode, referenceNo, 1, source).then(res => {
        /* eslint-disable no-console */
        console.log(res);
        toggleAccordion(!isOpen);
      });
    }
    callTealiumEvent();
  };
  return (
    <ToggleSectionWrapper>
      <ToggleTitle
        onClick={() => {
          _.isEmpty(instructionDetail)
            ? fetchInstructionList()
            : toggleAccordion(!isOpen);
        }}
        aria-controls={expandedSectionId}
        aria-expanded={isOpen}
        id={titleId}
        hideBorderBottom={!isOpen}
      >
        {sectionTitleFormat && sectionTitleFormat.map(component => component)}
        <AccessibleHidden aria-hidden={isOpen} toHide={isOpen}>
          {formatMessage({ id: 'SelectToExpand' })}
        </AccessibleHidden>
        <AccessibleHidden aria-hidden={!isOpen}>
          {formatMessage({ id: 'SelectToCollapse' })}
        </AccessibleHidden>
        <ToggleImgWrapper data-attribute="img">
          {isOpen ? (
            <ImagePath aria-hidden="true" alt="" src={chevronUp} />
          ) : (
            <ImagePath aria-hidden="true" alt="" src={chevronDown} />
          )}
        </ToggleImgWrapper>
      </ToggleTitle>
      {isOpen && (
        <ToggleDescription
          id={expandedSectionId}
          aria-labelledby={titleId}
          aria-hidden={!isOpen}
        >
          {React.cloneElement(children, { toggleAccordion })}
        </ToggleDescription>
      )}
    </ToggleSectionWrapper>
  );
};

ToggleHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  sectionTitleFormat: PropTypes.array.isRequired,
  expandedSectionId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  transCode: PropTypes.string.isRequired,
  referenceNo: PropTypes.string.isRequired,
  fetchInstructionData: PropTypes.func.isRequired,
  instructionDetail: PropTypes.object,
  documentDownloadLoaderHandler: PropTypes.func.isRequired,
  langObj: PropTypes.object.isRequired,
  requestTitleToCheck: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  customerSegment: PropTypes.string.isRequired
};

ToggleHandler.defaultProps = {
  instructionDetail: {}
};

ToggleHandler.contextTypes = {
  intl: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};
export default ToggleHandler;
