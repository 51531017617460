const theme = {
  maxWidth: 1280,
  basefontSize: 16,
  primary: '#DB0011',
  primaryHover: '#AF000D',
  primaryActive: '#AF000D',
  greyBorder: '#DEDEDE',
  fontColor: '#333333',
  greyColor: '#D2D2D2',
  lightGreyColor: '#D7D8D6',
  bgGreyColor: '#D8D8D8',
  summaryBoxGrey: '#f8f8f8',
  borderGreyColor: 'rgba(37, 48, 56, 0.5)',
  stepSelected: '#DB0011',
  stepDefault: '#EDEDED',
  stepTextDefault: '#929292',
  greenSuccess: '#008580',
  greenSuccessBg: 'rgba(0,133,128,0.05)',
  errorText: '#83000a',
  errorBg: 'rgba(146, 23, 34, 0.1)',
  errorBorder: '#8F1A22',
  errorBadge: '#DB0011',
  subLinksText: '#767676',
  warningText: '#e9a115',
  warningBg: '#FBF8F3',
  disableText: '#D7D8D6',
  infoText: '#305a85',
  greyText: '#a7a7a7',
  lightGrey: '#f9f8f8',
  successBg: '#00807B',
  successIconBg: '#005E59',
  whiteColor: '#ffffff',
  pendingBg: '#376798',
  pendingIconBg: '#1A4875',
  declinedBg: '#E8A215',
  declinedIconBg: '#BA8111',
  offerSectionBg: '#637483',
  tableBasicBg: '#637483',
  tableSaverBg: '#566775',
  tableStandardBg: '#485D6C',
  tableEnhancedBg: '#425665',
  tableTopBg: '#3E505D',
  radioAccessibleBorder: '#333',
  informationMessageBg: '#EBEFF4',
  darkRed: '#A8000B',
  blackColor: '#000000',
  footerTextColor: '#959595',
  borderColor: '#979797',
  greyLabels: '#666666',
  bgHeaderColor: '#262626',
  bgParentColor: '#F4f4f4',
  buttonBorder: '#dadada',
  downIconColor: '#252525',
  greyNumberColor: '#575757',
  topHeaderColor: '#262626;',
  fundFont: '#333300',
  errorNotificationBG: '#f9f2f3',
  greyBG: '#f7f7f7',
  fundRating0: '#767676',
  fundRating1: '#0d6bbd',
  fundRating2: '#00a69d',
  fundRating3: '#4e9c2d',
  fundRating4: '#e09c14',
  fundRating5: '#a8000b',
  greyValue: '#717171',
  contactBg: '#1f1f1f',
  detailNotificationBorder: '#d7d8d6',
  borderBottomColor: '#d9d9d9',
  borderBottomTotalAmount: '#686868',
  greyButton: '#7c7c7c',
  languageButtonBG: '#404040'
};

export default theme;
