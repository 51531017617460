import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';
import styled from 'styled-components';
import { FontIcon } from 'ui-component-library';
import { em, rem } from 'polished';
import media from '../../../utils/media';
import { FontFamily } from '../../../utils/font';
import theme from '../../../common/styles/theme';

export const DropDownWrapper = styled(Wrapper)`
  position: relative;
`;

export const LSMenuItem = styled(MenuItem)`
  padding: ${rem('11px')} ${rem('12px')} ${rem('11px')} ${rem('15px')};
  line-height: ${rem('20px')};
  ${FontFamily.RegularFontW01}
  &:focus, &:hover {
    background-color: ${theme.lightGreyColor};
  }
  span[type='agree'] {
    font-size: ${em('20px')};
  }
  ${media.tablet`
    padding-left: ${rem('20px')};
    padding-right: ${rem('20px')};
    `}
`;

export const LSButton = styled(Button)`
  display: inline-block;
  min-width: ${rem('65px')};
  padding: ${rem('12px')} ${rem('16px')} ${rem('13px')} ${rem('15px')};
  font-size: ${em('14px')};
  color: ${theme.fontColor};
  ${FontFamily.RegularFontW01};
  margin-right: ${rem('23px')};
  cursor: pointer;
  border: ${props =>
    props.open ? `1px solid ${theme.borderColor}` : `1px solid transparent`};
  border-bottom: ${props => (props.open ? `1px solid transparent` : ``)};
  span {
    padding-left: ${rem('5px')};
    cursor: pointer;
  }
  ${media.tablet`
    display: inline-block;
    margin: 0 ${rem('20px')};
    font-size: ${em('14px')};
    padding: ${rem('13px')} ${rem('15px')};
    margin: 0 ${rem('20px')} 0 0;
    ${FontFamily.RegularFontW01}
    `}
  &:hover {
    outline: ${props => (props.open ? `` : `1px dotted ${theme.borderColor}`)};
  }
  &:focus {
    outline: ${props => (props.open ? `` : `1px solid ${theme.borderColor}`)};
  }
`;

export const LSMenu = styled(Menu)`
  left: 0;
  top: auto;
  background-color: white;
  max-width: ${rem('120px')};
  width: 100%;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  border: 1px solid ${theme.borderColor};
  border-top: 0px solid transparent;
  height: inherit;
  line-height: ${rem('20px')};
  margin: 0;
  margin-top: -1px;
  font-size: ${em('14px')};
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% - 96px);
    background: ${theme.borderColor};
    height: 1px;
    top: -1px;
    right: 0;
    left: auto;
    bottom: auto;
  }
  ${media.tablet`
    bottom: auto;
    width: 100%;
    right: auto;
    height: auto;
    padding: 0;
    `}
`;

export const SelectedLanguageIcon = FontIcon.extend`
  font-size: 1.125em;
  float: right;
`;
