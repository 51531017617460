import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Fonts, FontFamily, fontSize5 } from '../../../../utils/font';
import media from '../../../../utils/media';
import theme from '../../../../common/styles/theme';

export const MegaMenuWrapper = styled.div`
  background-color: ${theme.whiteColor}
  padding: 38px 0 54px;
  box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.08);
  width: 1180px;
  min-height: 250px;  
  position: absolute;
  z-index: 1;
  display: none;
  top: 77px;  
  border-top: 1px solid #D7D8D6;  
  left: ${props => (props.locale === 'en' ? '-297px' : '-227px')}  
  box-sizing: border-box;  
  ${media.tablet`
    width: 1024px;
    top: 55px;
    padding: 40px 0 52px 25px;
  `};
  ${media.mediumTablet`
    width: 800px;
    top: 55px;
  `};
`;

export const MenuItemParent = styled.ul`
  list-style-type: none;
`;

export const MenuTitle = styled.h2`
  margin-bottom: ${rem('10px')};
  ${FontFamily.MediumFontW01}
`;

export const SubMenuTitle = styled.span`
  ${FontFamily.RegularFontW01};
  ${fontSize5}
`;

export const SubMenuItem = styled.li`
  ${Fonts.t14};
  list-style: none;
  padding-left: 0;
`;

const commonToLink = css`
  ${FontFamily.RegularFontW01};
  color: ${theme.fontColor};
  display: -moz-inline-stack;
  display: inline-block;
  margin: ${rem('10px')} 0;
  text-decoration: none;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  position: relative;
  &:hover,
  &:focus {
    text-decoration: ${props => (props.iconText ? `none` : `underline`)};
  }
  &:focus {
    text-decoration: underline;
    outline: 0;
  }
`;

export const LinkWrapper = styled.span`
  > a {
    ${commonToLink}
  }
`;

export const LinkText = styled.a`
  ${commonToLink}
`;
