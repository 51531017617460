import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import hyphenToCamelCase from '../util/hyphen-to-camel-case';
import media from '../../utils/media';

const fontContentMap = {
  iconAgree: '"\\f00a"',
  iconCircleDeleteSolid: '"\\e007"',
  iconCircleInfo: '"\f053"',
  iconCircleHelp: '"\f060";',
  iconBin: '"\\f065"',
  iconChevronUpSmall: '"\\e006"',
  iconChevronDownSmall: '"\\e003"',
  iconDownChevron: '"\\f055"',
  iconUpChevron: '"\\f056"',
  iconChevronLeft: '"\f058"',
  iconChevronRight: '"\f057"',
  iconDelete: '"\f002"',
  iconCircleErrorSolid: '"\\e009"',
  iconEdit: '"\f001"',
  iconCircleConfirmationSolid: '"\\e008"',
  iconChevronLeftSmall: '"\\e004"',
  iconDownload: '"\f000"',
  iconExternalLink: '"\\e039"',
  iconMenu: '"\\f00b"',
  iconPopUpWindow: '"\\e041"',
  iconPlay: '"\\e040"',
  iconPrint: '"\f004"',
  iconTriangleError: '"\\f069"',
  iconUpload: '"\f066"',
  iconArticles: '"\f01d"',
  iconAdd: '"\f031"',
  iconCircleHelpSolid: '"\\e010"'
};

const getFontIcon = ({ type = 'down-chevron' }) => {
  return `content: ${fontContentMap[hyphenToCamelCase(type)]}`;
};
const getStyles = ({ iconStyles }) => {
  if (iconStyles !== undefined) {
    return iconStyles;
  }
  return null;
};
const getFontSize = ({ fontSize = 20 }) => {
  return Array.isArray(fontSize)
    ? css`
        font-size: ${fontSize[2]}px;
        ${media.tablet`font-size: ${fontSize[1]}px`};
        ${media.phone`font-size: ${fontSize[0]}px`};
      `
    : `font-size: ${fontSize}px;`;
};

const Icon = styled.em.attrs({
  id: props => props.id,
  tabIndex: props => props.tabIndex,
  'aria-describedby': props => props['aria-describedby'],
  'aria-label': props => props['aria-label']
})`
  font-family: 'HSBCIcon-Font';
  display: inline-block;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  vertical-align: middle;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::before {
    ${getFontIcon};
  }
  ${getStyles};
  ${getFontSize};
  color: ${props => props.color};
  cursor: ${({ cursor }) => cursor};
`;
Icon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  cursor: PropTypes.string
};

Icon.defaultProps = {
  fontSize: 20,
  cursor: 'default'
};
Icon.displayName = 'Icon';
export default Icon;
