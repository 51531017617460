import React from 'react';
import PropTypes from 'prop-types';
import { chartColors } from '../../charts/chartUtils';
import {
  CardContainer,
  CardFundNameLabel,
  CardList,
  CardDetailsWrapper,
  CardListKey,
  CardListValue,
  FundLabel,
  FundWrapper
} from './CardMobileViewStyle';

const FundsCardMobileView = (props, context) => {
  const { fieldData, borderColorIndex, type } = props;
  const {
    intl: { formatMessage }
  } = context;
  return (
    <FundWrapper type={type}>
      {fieldData &&
        fieldData.map((fieldDataInstance, parentIndex, key) => {
          return (
            <>
              <CardContainer
                key={key}
                id={`${type}_${parentIndex}`}
                firstCard={parentIndex === 0}
                type={type}
                backgroundColor={
                  borderColorIndex
                    ? chartColors[borderColorIndex]
                    : chartColors[parentIndex]
                }
              >
                {Object.keys(fieldDataInstance).map((linkdetailsKey, index) => (
                  <>
                    {index === 0 && (
                      <CardFundNameLabel>
                        <FundLabel
                          aria-label={formatMessage({ id: linkdetailsKey })}
                        >
                          {fieldDataInstance[linkdetailsKey]}
                        </FundLabel>
                      </CardFundNameLabel>
                    )}
                    {index !== 0 && (
                      <CardDetailsWrapper>
                        <CardList>
                          <CardListKey>
                            {formatMessage({ id: linkdetailsKey })}
                          </CardListKey>
                          <CardListValue>
                            {fieldDataInstance[linkdetailsKey]}
                          </CardListValue>
                        </CardList>
                      </CardDetailsWrapper>
                    )}
                  </>
                ))}
              </CardContainer>
            </>
          );
        })}
    </FundWrapper>
  );
};
FundsCardMobileView.propTypes = {
  fieldData: PropTypes.array.isRequired,
  borderColorIndex: PropTypes.number,
  type: PropTypes.string.isRequired
};
FundsCardMobileView.defaultProps = {
  borderColorIndex: 0
};
FundsCardMobileView.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default FundsCardMobileView;
