const tempFundDetailURL = 'https://www.hsbc.com/';
const linkRel = 'noopener noreferrer nofollow';
const fundDetails = 'Fund details';
export const matchedRiskLevel = {
  1: {
    matchedRisk: 'risk_level_low',
    matchedRiskColor: '#0d6bbd'
  },
  2: {
    matchedRisk: 'risk_level_low_to_medium',
    matchedRiskColor: '#00a69d'
  },
  3: {
    matchedRisk: 'risk_level_medium',
    matchedRiskColor: '#4e9c2d'
  },
  4: {
    matchedRisk: 'risk_level_medium_to_high',
    matchedRiskColor: '#e09c14'
  },
  5: {
    matchedRisk: 'risk_level_high',
    matchedRiskColor: '#a8000b'
  }
};
export const columnData = [
  {
    id: 1,
    columnName: 'fundName_TH',
    colWidthResponsive: {
      colWidthForDeskTop: 32,
      colWidthForTablet: 32
    }
  },
  {
    id: 2,
    columnName: 'riskLevel_TH',
    colWidthResponsive: {
      colWidthForDeskTop: 24,
      colWidthForTablet: 24
    }
  },
  {
    id: 3,
    columnName: 'performance3Month_TH',
    colWidthResponsive: {
      colWidthForDeskTop: 22,
      colWidthForTablet: 22
    }
  },
  {
    id: 4,
    columnName: 'performance1Year_TH',
    colWidthResponsive: {
      colWidthForDeskTop: 22,
      colWidthForTablet: 22
    }
  }
];
export const fundsObject = [
  {
    id: 'coreFund_0',
    fundName: 'HSBC World Selection 5',
    riskLevel: 'Low-medium risk',
    returnPastYear: 2.2,
    return3Year: 1.2,
    fundRiskRating: 1,
    fundDetails: {
      linkName: fundDetails,
      url: tempFundDetailURL,
      target: '_blank',
      rel: linkRel
    },
    isDefaultFund: true,
    isSelected: true
  }
];
