import SAASAuthModule from 'saas-authenticator-hk';
import LocaleManager from './localeManager';

export const saasAuthGetInstance = () => {
  const locale =
    LocaleManager.getLocaleForHSBCWebApps() ||
    process.env.REACT_APP_DEFAULT_LOCALE;
  return SAASAuthModule.getInstance({
    locale,
    environment: process.env.REACT_APP_SAAS_ENVIRONMENT
  });
};

export const loginHandler = () => {
  const saasAuthModule = saasAuthGetInstance();
  saasAuthModule.authenticate();
};

export const isSessionActive = () => {
  return saasAuthGetInstance().isSessionActive();
};

export const invalidateSession = () => {
  return saasAuthGetInstance().invalidateSession();
};
export const authenticateSession = () => {
  return saasAuthGetInstance().authenticate();
};
export async function fetchSaasToken() {
  return await isSessionActive();
}
