import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import {
  ModalContentFormatted,
  NilDetails,
  NilDetailsImagePath,
  NilDetailsTab,
  NilDetailsWarningDiv,
  NilDetailsWrapper,
  NilDetailsWrapperDesktop,
  NilDetailsWrapperTab,
  NilText,
  NilTextTab
} from './beneficiarySectionStyles';
import warningIcon from '../../../assets/images/warningBlue.svg';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import { ShowIfMobile } from '../../../utils/showIf';

const BeneficiaryNilNameField = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { beneficiaryName, beneficiaryType, missingInfoFlag } = props;
  const isNameFieldEmpty = _.isEmpty(beneficiaryName);
  const linkRef = React.createRef();
  const [isModalOpen, updateModalState] = useState(false);
  const showMissingInfoPopup = isNameFieldEmpty || missingInfoFlag;
  const renderNilTextDiv = () => {
    return (
      <NilDetailsWarningDiv
        role="alert"
        aria-live="assertive"
        href={linkRef}
        onClick={e => {
          updateModalState(!isModalOpen);
          e.preventDefault();
        }}
      >
        <NilDetailsImagePath src={warningIcon} alt="warning-icon" />
        <NilDetails>
          {formatMessage({ id: 'modalDetailsMissingTitle' })}
        </NilDetails>
      </NilDetailsWarningDiv>
    );
  };
  return (
    <NilDetailsWrapper>
      <>
        <NilDetailsWrapperDesktop>
          <NilText>
            {isNameFieldEmpty
              ? formatMessage({ id: 'nilText' })
              : beneficiaryName}
          </NilText>
        </NilDetailsWrapperDesktop>
        <ShowIfMobile>
          <NilDetailsWrapperTab>
            <NilTextTab isNameFieldEmpty={showMissingInfoPopup}>
              {isNameFieldEmpty
                ? formatMessage({ id: 'nilText' })
                : beneficiaryName}
            </NilTextTab>

            <NilDetailsTab>
              {showMissingInfoPopup && renderNilTextDiv()}
            </NilDetailsTab>
          </NilDetailsWrapperTab>
        </ShowIfMobile>
      </>

      <ModalPopUp
        isModalOpen={isModalOpen}
        onClose={() => {
          updateModalState(!isModalOpen);
        }}
        modalHeading={formatMessage({ id: 'modalDetailsMissingTitle' })}
        id="nilDetailsModal"
      >
        <ModalContentFormatted>
          <FormattedHTMLMessage
            id={
              beneficiaryType === 'primary'
                ? 'modalDetailsMissingDescription1'
                : 'modalDetailsMissingDescription2'
            }
          />
        </ModalContentFormatted>
      </ModalPopUp>
    </NilDetailsWrapper>
  );
};

BeneficiaryNilNameField.propTypes = {
  beneficiaryName: PropTypes.string.isRequired,
  beneficiaryType: PropTypes.string.isRequired,
  missingInfoFlag: PropTypes.bool.isRequired
};

BeneficiaryNilNameField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BeneficiaryNilNameField;
