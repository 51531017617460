import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReprojectionCharts from '../ReprojectionCharts';
import BelowGraphNotification from './templates/BelowGraphNotification';
import ReprojectionTableDataFormatter from '../ReprojectionTableDataFormatter';
import ChartLegendsCheckbox from '../../charts/ChartLegendsCheckbox';
import {
  ChartTitle,
  GraphInfoSubHeaderDiv,
  InfoMessageSpan
} from '../reprojectionPageStyle';
import { trackEvents } from '../../utils/tealiumUtils';
import { getChartCheckboxInfo } from '../../utils/reprojectionsUtils';
import { FutureValueTabSubHeader } from '../templates';

/**
 * @param {Object} props
 * @description : contains sectionFieldConfig which can be iterated to render field component inside this template
 *  sectionFieldConfig[0] : array of fields object for chart
    sectionFieldConfig[1] : array of table header object for table
    sectionFieldConfig[2] : array of fields object of table main subsection
 */

const FutureValuePayoutBenefit = (props, context) => {
  const {
    productCategory,
    sectionFieldConfig,
    tableSectionAPIFieldParentKey,
    reprojectionsDetails,
    dependentFieldConfig,
    policyCurrencyFormat,
    reprojectionGenericConfig,
    sectionNotificationConfig,
    sectionToRender,
    reprojectionChartConfig: { payoutBenefitChart, chartFields },
    customerAge,
    setCustomerAge,
    setCustomerMinPayAge,
    setCustomerMaxPayAge,
    customerMinPayAge,
    customerMaxPayAge,
    toggleIncludeNonGuaranteedPayout,
    tabValues: {
      payoutBenefits: { includeNonGuaranteedPayout }
    },
    selectedFutureBenefitsTab,
    policyPhaseFlag,
    reprojectionTealiumHandlers
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const {
    reprojectionDetails: {
      payoutBenefit: { payoutDetails }
    }
  } = reprojectionsDetails;

  const { captureCheckBoxEvents } = reprojectionTealiumHandlers;
  const {
    chartIncludeNGCheckbox: { show: showCheckboxAsPerConfiguration }
  } = getChartCheckboxInfo(chartFields, sectionToRender);
  const [isDisplayCheckBox, displayChkBoxSetter] = useState(
    showCheckboxAsPerConfiguration
  );

  return (
    <>
      <ChartTitle>
        {formatMessage({ id: 'payoutBenefitChartLabel' })}
      </ChartTitle>
      {Object.keys(payoutDetails).length > 1 && (
        <GraphInfoSubHeaderDiv>
          <InfoMessageSpan>
            {formatMessage({ id: 'graphInfoSubHeaderLabel' })}
          </InfoMessageSpan>
        </GraphInfoSubHeaderDiv>
      )}
      <FutureValueTabSubHeader
        reprojectionsDetails={reprojectionsDetails}
        selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        customerMaxPayAge={customerMaxPayAge}
        id="payoutBenefitsChart"
        selectedTabName="PayoutBenefit"
      />
      {isDisplayCheckBox && (
        <ChartLegendsCheckbox
          includeNonGuranteedPayout={includeNonGuaranteedPayout}
          onCheckboxChange={() => {
            captureCheckBoxEvents(
              toggleIncludeNonGuaranteedPayout,
              'payoutBenefits',
              includeNonGuaranteedPayout,
              'non-guaranteed payout',
              trackEvents
            );
          }}
          label="legendCheckboxIncludeNonGuranteedPayout"
          checkboxId="future-value-payout-benefit"
        />
      )}
      <ReprojectionCharts
        productCategory={productCategory}
        reprojectionsData={reprojectionsDetails}
        chartConfig={payoutBenefitChart}
        sectionToRender={sectionToRender}
        tooltipContent="tooltipTotalAmountAllocate"
        isNonGuaranteedChecked={includeNonGuaranteedPayout}
        policyCurrencyFormat={policyCurrencyFormat}
        id="payoutBenefitsChart"
        setValue={setCustomerAge}
        value={customerAge}
        customerMinPayAge={customerMinPayAge}
        customerMaxPayAge={customerMaxPayAge}
        setCustomerMinPayAge={setCustomerMinPayAge}
        setCustomerMaxPayAge={setCustomerMaxPayAge}
        displayChkBoxSetter={displayChkBoxSetter}
        showCheckboxAsPerConfiguration={showCheckboxAsPerConfiguration}
        selectedTabName="PayoutBenefit"
      />
      <BelowGraphNotification
        selectedTabName="PayoutBenefit"
        reprojectionsDetails={reprojectionsDetails}
        sectionNotificationConfig={sectionNotificationConfig}
      />
      <ReprojectionTableDataFormatter
        tableSectionFieldConfig={sectionFieldConfig && sectionFieldConfig[2]}
        apiFieldParentKey={tableSectionAPIFieldParentKey}
        reprojectionsDetails={reprojectionsDetails}
        dependentFieldConfig={dependentFieldConfig}
        policyCurrencyFormat={policyCurrencyFormat}
        reprojectionGenericConfig={reprojectionGenericConfig}
        selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        tableHeaderText="payoutBenefitTableHeader"
        selectedTabName="PayoutBenefit"
        policyPhaseFlag={policyPhaseFlag}
      />
    </>
  );
};

FutureValuePayoutBenefit.propTypes = {
  productCategory: PropTypes.string.isRequired,
  sectionFieldConfig: PropTypes.array.isRequired,
  tableSectionAPIFieldParentKey: PropTypes.string.isRequired,
  policyCurrencyFormat: PropTypes.string.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  sectionToRender: PropTypes.string.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired,
  customerAge: PropTypes.number.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  toggleIncludeNonGuaranteedPayout: PropTypes.func.isRequired,
  tabValues: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  policyPhaseFlag: PropTypes.bool.isRequired,
  reprojectionTealiumHandlers: PropTypes.object.isRequired
};

FutureValuePayoutBenefit.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FutureValuePayoutBenefit;
