import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import { formatCurrency } from '../../../utils/formatter';

const FundTablePercentage = function FundTablePercentage(props) {
  const { fieldData } = props;

  if (!fieldData) {
    return '-';
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>{`${formatCurrency(fieldData)}%`}</ShowIf>
    </>
  );
};

FundTablePercentage.propTypes = {
  fieldData: PropTypes.array.isRequired
};

export default FundTablePercentage;
