import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from './CustomButtonStyledComp';

const Button = ({ label, className, onClick, id }) => {
  return (
    <CustomButton className={className} onClick={onClick} id={id}>
      <span>{label}</span>
    </CustomButton>
  );
};

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default Button;
