import { all, fork } from 'redux-saga/effects';
import contentSaga from './contentSaga';
import appBootstrapSaga from './appBootstrapSaga';
import loggerSaga from './loggerSaga';
import policySaga from './policySaga';
import reprojectionsSaga from './reprojectionSaga';
import policyDocumentsSaga from './policyDocumentsSaga';
import eWelcomePackAckSaga from './eWelcomeAckSaga';
import eWelcomeDocumentsSaga from './eWelcomeDocumentsSaga';
import eWelcomeDocumentRetrieveSaga from './eWelcomeDocumentRetrieveSaga';
import eWelcomeDownloadAllSaga from './eWelcomeDownloadAllSaga';
import instructionHistorySaga from './instructionHistorySaga';
import fundSelectionSaga from './fundSelectionSaga';
import cancelRequestSaga from './cancelRequestSaga';
import eStatementPreferenceSaga from './eStatementPreferenceSaga';

export default function* rootSaga() {
  yield all([
    fork(contentSaga),
    fork(appBootstrapSaga),
    fork(loggerSaga),
    fork(policySaga),
    fork(reprojectionsSaga),
    fork(policyDocumentsSaga),
    fork(eWelcomePackAckSaga),
    fork(eWelcomeDocumentsSaga),
    fork(eWelcomeDocumentRetrieveSaga),
    fork(eWelcomeDownloadAllSaga),
    fork(instructionHistorySaga),
    fork(fundSelectionSaga),
    fork(cancelRequestSaga),
    fork(eStatementPreferenceSaga)
  ]);
}
