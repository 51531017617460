import React from 'react';
import PropTypes from 'prop-types';
import * as icons from '../../assets/images/NotificationIcons';
import theme from '../../common/styles/theme';
import {
  NotificationMessageIcon,
  NotificationMessageType,
  NotificationIconSpan,
  ImagePath,
  ExceptionLapsedNotificationMessage
} from '../../common/styles/commonStyle';
import { DetailsPageMiddleSection } from '../detailPageStyle';
import { trackViews } from '../../utils/tealiumUtils';
import ContactLink from '../../common/contactLink/contactLink';

export const getPrefixForKey = function getPrefixForKey(
  superFamily,
  productCategory
) {
  let prefix = 'gi';
  if (productCategory === 'Jade') {
    prefix = 'Jade';
  } else if (superFamily === 'life') {
    prefix = 'life';
  }
  return prefix;
};

export const getExceptionContentKeys = function getExceptionContentKeys(
  isPolicyExceptional,
  isPolicyLapsed,
  summaryResponse,
  prefixForKey
) {
  let bannerMessage = '';
  let tealiumEventContent = '';
  const apiErrorMessage = `${prefixForKey}NoDetailsBannerMessage`;
  const contactNumberLabel = `${prefixForKey}ContactUsNumber`;
  if (isPolicyExceptional) {
    bannerMessage = `${prefixForKey}OnholdBannerMessage`;
    tealiumEventContent = 'policy on hold';
  }
  if (isPolicyLapsed) {
    bannerMessage = `${prefixForKey}LapsedBannerMessage`;
    tealiumEventContent = 'policy lapsed';
  }
  if (summaryResponse && summaryResponse.policyDetailsIndicator === false) {
    bannerMessage = `${prefixForKey}NoDetailsBannerMessage`;
    tealiumEventContent = 'policy details not available';
  }
  return {
    bannerMessage,
    apiErrorMessage,
    tealiumEventContent,
    contactNumberLabel
  };
};

const exceptionOrLapsedBanner = (props, context) => {
  const {
    productCategory,
    isPolicyExceptional,
    isPolicyLapsed,
    superFamily,
    warningsOrErrors,
    summaryResponse
  } = props;

  const {
    intl: { formatMessage }
  } = context;
  const errorCodeList = [];
  const prefixForKey = getPrefixForKey(superFamily, productCategory);
  const exceptionKeys = getExceptionContentKeys(
    isPolicyExceptional,
    isPolicyLapsed,
    summaryResponse,
    prefixForKey
  );
  const {
    bannerMessage,
    apiErrorMessage,
    tealiumEventContent,
    contactNumberLabel
  } = exceptionKeys;

  /* show only warning banner if lapsed/on hold/ detailsIndicator is false */
  if (bannerMessage) {
    trackViews({
      viewName: 'onDetailsPageLoadExceptionMsg',
      message: tealiumEventContent,
      messageType: 'warning'
    });
    return (
      <DetailsPageMiddleSection>
        <NotificationMessageType bgColor="#fbf8f3" borderColor="#e9a115">
          <NotificationMessageIcon role="alert">
            <NotificationIconSpan>
              <ImagePath aria-hidden="true" alt="" src={icons.Warning} />
            </NotificationIconSpan>
            <ExceptionLapsedNotificationMessage
              aria-label={formatMessage(
                { id: bannerMessage },
                { contact0: formatMessage({ id: contactNumberLabel }) }
              )}
            >
              <ContactLink
                sentenceWithContact={bannerMessage}
                contactsList={[contactNumberLabel]}
                typeOfCall="general enquiry"
              />
            </ExceptionLapsedNotificationMessage>
          </NotificationMessageIcon>
        </NotificationMessageType>
      </DetailsPageMiddleSection>
    );
  }

  /* show only error banner if api fails  */
  if (warningsOrErrors.length) {
    return (
      <DetailsPageMiddleSection>
        <NotificationMessageType
          bgColor={theme.errorNotificationBG}
          borderColor={theme.primaryActive}
        >
          {warningsOrErrors.map((alert) => {
            trackViews({
              viewName: 'onDetailsPageLoadErrorMsg',
              message: 'error details, api falied',
              messageType: alert.errorCode,
              messageAction: 'error type,system'
            });
            if (alert.errorCode !== 'defaultErrorOrWarningCode') {
              errorCodeList.push(alert.errorCode);
            }
          })}
          <NotificationMessageIcon role="alert">
            <NotificationIconSpan>
              <ImagePath aria-hidden="true" alt="" src={icons.ErrorIcon} />
            </NotificationIconSpan>
            <ExceptionLapsedNotificationMessage
              id={`policyDetails_error_${prefixForKey}`}
              aria-label={formatMessage(
                { id: apiErrorMessage },
                { contact0: formatMessage({ id: contactNumberLabel }) }
              )}
            >
              {errorCodeList.length > 0 ? (
                <ContactLink
                  sentenceWithContact={`policyDetailFailMessage_${prefixForKey}`}
                  contactsList={[contactNumberLabel]}
                  typeOfCall="general enquiry"
                  includeErrorCode
                  errorCode={errorCodeList.toString()}
                />
              ) : (
                <ContactLink
                  sentenceWithContact={apiErrorMessage}
                  contactsList={[contactNumberLabel]}
                  typeOfCall="general enquiry"
                />
              )}
            </ExceptionLapsedNotificationMessage>
          </NotificationMessageIcon>
        </NotificationMessageType>
      </DetailsPageMiddleSection>
    );
  }
  return <></>;
};

exceptionOrLapsedBanner.propTypes = {
  productCategory: PropTypes.string.isRequired,
  isPolicyExceptional: PropTypes.bool.isRequired,
  isPolicyLapsed: PropTypes.bool.isRequired,
  superFamily: PropTypes.string.isRequired,
  warningsOrErrors: PropTypes.array,
  summaryResponse: PropTypes.object.isRequired
};

exceptionOrLapsedBanner.defaultProps = {
  warningsOrErrors: []
};

exceptionOrLapsedBanner.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default exceptionOrLapsedBanner;
