import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import {
  CriticalIllnessFieldWrapper,
  OverviewInfoFieldAmountCurrency,
  LabelTextCriticalIllness,
  CriticalIllnessLabelWrapper
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';

const invalidateFieldData = fieldData => {
  const amount = _.get(fieldData, 'amount');
  const currency = _.get(fieldData, 'currency');

  return amount === undefined || currency === undefined;
};
const CriticalIllnessAmountField = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { fieldData, dependentFieldData } = props;
  const { labelContent, toolTipContent, sectionHeading, id } = props;
  if (!labelContent || invalidateFieldData(fieldData)) {
    return null;
  }

  const amountCurrencyComponent = (
    <Currency
      id="CurrencyLabel"
      currencyCode={fieldData.currency}
      amount={fieldData.amount}
    />
  );

  const hideDependentField = invalidateFieldData(dependentFieldData);

  return (
    <CriticalIllnessFieldWrapper id={labelContent}>
      <CriticalIllnessLabelWrapper>
        {formatMessage({ id: labelContent })}
        <TooltipWrapper
          id={`critical_illness_tooltip_${id}`}
          contentKey={toolTipContent}
          sectionHeading={sectionHeading}
          labelContent={labelContent}
          isFormatHtml={false}
        />
      </CriticalIllnessLabelWrapper>
      <OverviewInfoFieldAmountCurrency id="amountLabelCriticalIllness">
        {amountCurrencyComponent}
        {!hideDependentField && (
          <>
            <br />
            <LabelTextCriticalIllness>
              {formatMessage({ id: 'criticalIllnessTotalClaimAmount' })}
              <Currency
                currencyCode={dependentFieldData.currency}
                amount={dependentFieldData.amount}
              />
            </LabelTextCriticalIllness>
          </>
        )}
      </OverviewInfoFieldAmountCurrency>
    </CriticalIllnessFieldWrapper>
  );
};

CriticalIllnessAmountField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object,
  dependentFieldData: PropTypes.object,
  toolTipContent: PropTypes.string,
  sectionHeading: PropTypes.string.isRequired
};

CriticalIllnessAmountField.defaultProps = {
  toolTipContent: null,
  fieldData: null,
  dependentFieldData: null
};

CriticalIllnessAmountField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default CriticalIllnessAmountField;
