import styled from 'styled-components';
import { rem, em } from 'polished';
import { FontFamily } from '../../utils/font';
import { Section } from '../../common/styles/commonStyle';
import media from '../../utils/media';
import theme from '../../common/styles/theme';

export const DetailsNotification = styled.div`
  display: block;
  background-color: ${theme.whiteColor};
`;

export const NotificationRow = styled(Section)`
  display: block;
  margin-bottom: 0;
  display: flex;
  ${media.tablet`
  padding: 0 ${rem('24px')};
`};
  ${media.phone`
 padding: 0 0 0  ${rem('15px')};
`};
`;

export const LeftNotificationRow = styled.nav`
  padding: ${rem('20px')} 0 ${rem('15px')} 0;
  letter-spacing: 0;
  font-size: ${em('14px')};
  ${media.phone`
    font-size: ${em('12px')};
    padding: ${rem('20px')} 0 ${rem('15px')} 0;
`};
`;

export const BackButton = styled.span`
  width: auto;
  -webkit-appearance: initial;
  background: transparent;
  border: none;
  ${FontFamily.RegularFontW01};
  color: ${theme.fontColor};
  &:hover {
    outline: 1px dotted black;
  }
  letter-spacing: 0;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  padding: 0;
`;

export const ButtonWrapperOL = styled.ol`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  > li a {
    display: flex;
    cursor: pointer;
    border: none;
    text-decoration: none;
    color: ${theme.fontColor};
    &:focus {
      outline: 1px dotted black;
    }
    ${media.phone`
      padding-bottom:3px;
    `}
  }
`;

export const ListLI = styled.li`
padding:0;
margin:0;
cursor: ${props => (props.currentPage ? `default` : 'pointer')};
display:flex;
border: none;
text-decoration: none;
>span{
  ${FontFamily.BoldFontW01}
  color: ${theme.fontColor};
  ${media.phone`
    line-height : ${rem('18px')}
  `}
}
&:focus {
  outline: 1px dotted black;
}
${media.phone`
  padding-bottom:3px;
`}
}
`;

export const ImageSpan = styled.span`
  height: ${rem('10px')};
  width: ${rem('6px')};
  margin: 0 ${rem('10px')};
  display: block;
`;
export const ImagePath = styled.img`
  height: 100%;
  width: 100%;
`;
