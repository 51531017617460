import styled, { css } from 'styled-components';
import { rem, em } from 'polished';
import media from '../../../utils/media';
import { Section } from '../../../common/styles/commonStyle';
import theme from '../../../common/styles/theme';
import { FontFamily } from '../../../utils/font';

export const PayouBenefitRangeLegendsWrapper = styled(Section)`
  background-color: ${theme.stepDefault};
  padding: ${rem('20px')} ${rem('30px')};
  position: relative;
  ${media.tablet`
    padding: ${rem('20px')} ${rem('30px')};
  `}
  ${media.phone`
    padding: ${rem('10px')} ${rem('15px')};
  `}
  &:before {
    content: '';
    position: absolute;
    top: ${rem('-16px')};
    visibility: ${props => (props.left < 0 ? 'hidden' : 'visible')}
    left: ${props => `${props.left}px`};
    width: ${rem('34px')};
    height: ${rem('34px')};
    background: linear-gradient(
      to bottom right,
      ${theme.stepDefault} 50%,
      transparent 50%
    );
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const AgeRangeWrapper = styled.div``;
const commonInputStyle = css`
  padding-top: ${rem('10px')};
  padding-left: ${props =>
    props.id === 'policyYear' ? `${rem('20px')}` : `0`};
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  line-height: ${rem('22px')};
  ${FontFamily.RegularFontW01};
  ${media.phone`
    font-size: ${rem('12px')};
    padding-left: ${props =>
      props.id === 'policyYear' ? `${rem('10px')}` : `0`};
  `}
`;
export const InputSectionDiv = styled.div`
  ${commonInputStyle}
`;
export const InputSectionLabel = styled.label`
  ${commonInputStyle}
`;
export const PartitionDiv = styled.div`
  border-right: 1px solid ${theme.bgGreyColor};
  margin: ${rem('6px')} 0 ${rem('6px')} 0;
`;

export const InputSectionWrapper = styled.div`
  display: flex;
`;

export const WarningDiv = styled.div`
  display: flex;
  ${FontFamily.RegularFontW01};
  font-size: ${rem('16px')};
  line-height: ${rem('22px')};
  color: ${theme.fontColor};
  margin-top: ${rem('17px')};
`;
export const ImagePath = styled.img`
  display: block;
  margin: auto ${rem('9px')} auto 0;
  width: ${rem('18px')};
  height: ${rem('18px')};
`;
// cash surrender subheader styles
export const PayoutBenefitLegendsWrapper = styled.div`
  display flex;
  box-sizing: border-box;
  padding-top: ${rem('20px')};
  width:100%;
  ${media.tablet`
    flex-direction: column;
  `}
`;
export const commonStyleToPayoutBenefitLegends = styled.div`
  background-color: #fff;
  width: 49%;
  height: auto;
  min-height: ${rem('170px')};
  box-sizing: border-box;
  padding: ${rem('20px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};

  ${media.tablet`
    width: 100%;
  `}
  ${media.phone`
    width: 100%;
    padding: ${rem('15px')} ${rem('15px')} 0 ${rem('15px')};
  `}
`;
export const PayoutBenefitAnnuityPayoutLegends = styled(
  commonStyleToPayoutBenefitLegends
)`
  margin-left: ${props => (props.styleUpdate ? `2%` : '')};
  ${media.tablet`
  margin: 10px 0 0 0;
  padding: ${rem('15px')} ${rem('15px')};
  min-height:auto;
  `};
  ${media.phone`
  min-height:auto;
  `}
`;
export const PayoutBenefitCumulativeAnnuityPayoutLegends = styled(
  commonStyleToPayoutBenefitLegends
)`
  margin-left: ${rem('20px')};
  ${media.tablet`
    margin-left: 0;
    margin-top: ${rem('20px')};
  `}
  ${media.phone`
    margin-top: ${rem('10px')};
  `}
`;
export const AnnualAnnuityPayoutLegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const PayouBenefitLegendIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AnnualAnnuityPayoutLegend = styled.dl`
  width: 100%;
  display: flex;
  align-items: ${props => (props.styleUpdate ? `baseline` : 'center')};
  flex-wrap: wrap;
  font-size: ${em('16px')};
  line-height: ${rem('22px')};
  ${media.phone`
    font-size: ${rem('12px')};
  `}
`;
export const AnnualAnnuityPayoutKey = styled.dt`
  width: ${props => (props.styleUpdate ? `63%` : 'calc(60% - 24px)')};
  box-sizing: border-box;
  text-align: left;
  padding: ${props =>
    props.styleUpdate
      ? `${rem('1px')} 0 0 0}`
      : `${rem('1px')} 0 0 ${rem('8px')}`};
  ${FontFamily.BoldFontW01};
  line-height: ${rem('14px')};
  ${media.phone`
  width:${props => (props.styleUpdate ? `62%` : 'calc(60% - 15px)')};
`}
`;
export const PayouBenefitLegendIcon = styled.span`
  width: ${rem('16px')};
  height: ${rem('16px')};
  background-color: ${props => props.legendBgColor}
  &:last-child {
    margin-top: ${props => (props.isNonGuaranteedChecked ? `${rem('2px')}` : 0)}
  }
  ${media.phone`
    width: ${rem('10px')};
    height: ${rem('10px')};
  `}
`;

export const AnnualAnnuityPayoutValue = styled.dd`
  width: ${props => (props.styleUpdate ? `37%` : '40%')};
  text-align: right;
  padding-top: ${rem('1px')};
  line-height: ${rem('14px')};
  span {
    color: ${theme.fontColor};
    span {
      ${FontFamily.BoldFontW01};
      margin-right: 2px;
    }
  }
`;
export const SelectedAge = styled.span`
  margin-left: ${rem('3px')};
`;

export const TotalGuranteedPayoutKey = styled.dt`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  font-size: ${rem('14px')};
  line-height: ${rem('14px')};
  color: ${theme.fontColor};
  margin-top: ${props =>
    props.styleUpdate ? `${rem('15px')}` : `${rem('20px')}`};
  padding: ${props =>
    props.styleUpdate ? `0` : `${rem('15px')} 0 0 ${rem('15px')}`};
  border-top: 1px solid ${theme.lightGreyColor};
  ${media.phone`
    font-size: ${rem('12px')};
    align-items: baseline;
  `}
`;
export const TotalGuaranteedPayoutLabel = styled.span`
  margin-left: ${rem('8px')};
`;
export const GuranteedPayoutKey = styled.dt`
  display: flex;
  align-items: center;
  width: 65%;
  text-align: left;
  font-size: ${rem('14px')};
  line-height: ${rem('14px')};
  color: ${props =>
    props.styleUpdate ? `${theme.greyNumberColor}` : `${theme.fontColor}`};
  padding: ${props =>
    props.styleUpdate
      ? `${rem('15px')} 0 0 ${rem('15px')}`
      : `${rem('15px')} 0 0 ${rem('55px')}`};
  box-sizing: border-box;
  ${media.phone`
  align-items: baseline;
    font-size: ${rem('12px')};
    padding:${props =>
      props.styleUpdate
        ? `${rem('15px')} 0 0 ${rem('10px')}`
        : `${rem('15px')} 0 0 ${rem('50px')}`};
  `}
`;
export const CumulativeGuranteedPayoutKey = styled(GuranteedPayoutKey)`
  padding: ${props =>
    props.styleUpdate
      ? `${rem('15px')} 0 0 ${rem('15px')}`
      : `${rem('15px')} 0 0 ${rem('30px')}`};
  color: ${theme.fontColor};
  ${media.phone`
  padding-left:20px;
`}
`;
export const GuranteedPayoutValue = styled.dd`
  width: 35%;
  text-align: right;
  font-size: ${rem('14px')};
  line-height: ${rem('14px')};
  padding-top: ${rem('15px')};
  box-sizing: border-box;
  span {
    color: ${theme.fontColor};
    span {
      margin-right: 2px;
    }
  }
  ${media.phone`
    font-size: ${rem('12px')};
  `}
`;
export const CumulativeGuranteedPayoutValue = styled(GuranteedPayoutValue)``;

export const TotalBenefitPayoutLegend = styled.span`
  width: ${rem('16px')};
  height: ${rem('16px')};
  ${media.phone`
    width: ${rem('10px')};
    height: ${rem('10px')};
    padding: 5px;
    box-sizing: border-box;
  `}
`;
export const TotalGuranteedPayoutLegendIcon = styled(TotalBenefitPayoutLegend)`
  background-color: ${props => props.legendBgColor || '#4fa62f'};
`;

export const TotalNonGuranteedPayoutLegendIcon = styled(
  TotalBenefitPayoutLegend
)`
  background-color: ${props => props.legendBgColor || '#e67310'};
`;

export const RemainingBalanceWrapper = styled.div``;
