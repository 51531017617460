import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import {
  EECWrapper,
  EECSubheader,
  EECCardText,
  EECCardHeader,
  EECCardSubHeader,
  EECCardSubHeaderParagraph,
  EECCardHeaderText,
  ImgStyle
} from './EECModalStyles';
import securityKey from '../../../assets/images/SurrenderCharge/security_secure_key.svg';
import EECTable from './EECTable';
import { trackViews } from '../../../utils/tealiumUtils';
import getContentInSpecificLang from '../../../utils/localeUtils';

function EECModal(props, context) {
  const {
    intl: { formatMessage }
  } = context;

  const { isModalOpen, onClose, tableList, currentPlan, langObj } = props;
  trackViews({
    viewName: 'onEECModalLoad',
    message: _.toLower(
      getContentInSpecificLang('eecModalHeader', 'en', langObj)
    )
  });

  return (
    <ModalPopUp
      hideButton
      isModalOpen={isModalOpen}
      onClose={onClose}
      modalHeading={formatMessage({ id: 'eecModalHeader' })}
      id="EECScroll"
    >
      <EECWrapper id="EECInfoGeneralCard">
        <EECSubheader>
          {formatMessage({ id: 'eecModalSubHeader' })}
        </EECSubheader>
        <EECCardText>
          <EECCardHeader>
            <ImgStyle aria-hidden="true" alt="" src={securityKey} />
            <EECCardHeaderText>
              {formatMessage({ id: 'eecCardSurrenderText' })}
            </EECCardHeaderText>
          </EECCardHeader>
          <EECCardSubHeader>
            <EECCardSubHeaderParagraph>
              {formatMessage({ id: 'eecSurrenderText' })}
            </EECCardSubHeaderParagraph>
            <EECCardSubHeaderParagraph>
              {formatMessage({ id: 'eecSurrenderExample' })}
            </EECCardSubHeaderParagraph>
          </EECCardSubHeader>
        </EECCardText>
        <EECCardText>
          <EECCardHeader>
            <ImgStyle aria-hidden="true" alt="" src={securityKey} />
            <EECCardHeaderText>
              {currentPlan === 'WI0' || currentPlan === 'WI1'
                ? formatMessage({ id: 'eecCardWithrawalTextWI01' })
                : formatMessage({ id: 'eecCardWithrawalText' })}
            </EECCardHeaderText>
          </EECCardHeader>
          <EECCardSubHeader>
            <EECCardSubHeaderParagraph>
              {currentPlan === 'WI0' || currentPlan === 'WI1'
                ? formatMessage({ id: 'eecWithdrawalTextWI01' })
                : formatMessage({ id: 'eecWithdrawalText' })}
            </EECCardSubHeaderParagraph>
            <EECCardSubHeaderParagraph>
              {currentPlan === 'WI0' || currentPlan === 'WI1'
                ? formatMessage({ id: 'eecWithdrawalExampleWI01' })
                : formatMessage({ id: 'eecWithdrawalExample' })}
            </EECCardSubHeaderParagraph>
          </EECCardSubHeader>
        </EECCardText>
        <EECTable tableList={tableList} />
      </EECWrapper>
    </ModalPopUp>
  );
}

EECModal.contextTypes = {
  intl: PropTypes.object.isRequired
};

EECModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  currentPlan: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  tableList: PropTypes.array,
  langObj: PropTypes.object.isRequired
};

EECModal.defaultProps = {
  tableList: []
};

export default EECModal;
