import { select, call, put, takeEvery, all } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';
import {
  setExternalConfiguration,
  getContent,
  setCustomerInfo,
  stubServiceConfig,
  startLoader,
  setAppBootstapAsComplete,
  initLogger,
  setEligibilityInfo
} from '../app/appActions';
import { APP_BOOTSTRAP } from '../actionTypes';
import { isDashboardAccessed, determineEncodeID } from '../utils/routeUtils';
import isCustomerWhiteListed from '../utils/customerUtils';
import URLsConfiguration from '../utils/HSBCdataCenterDetector';
import { doAND } from '../common/util/commonUtil';

import {
  isEligibleForLogging,
  appBootstrapSagaErrorHandler,
  prepareServiceConfig,
  stubAllAPI,
  handleDetailsApiFailure
} from '../utils/sagaUtils';

/**
 * @description Change the Service Config response to a format that is used to merge in the Configuration Object to be put in the store.
 * @param {*} apiConfigurationObj eg.{
   "baseUrl"     :"https://devcluster.api.p2g.netd2.hsbc.com.hk/",
   "serviceUrl" :{
  "policies"  : "dw-ins-policies-hk-ea-cert-proxy/v1/customers/{encodedoc-id}/policies",
  "customers" : "dwi-customers-hk-ea-cert-proxy/v2/customers"
  }
 * @returns eg.{policies: {url : "https://devcluster.api.p2g.netd2.hsbc.com.hk/dw-ins-policies-hk-ea-cert-proxy/v1/customers/{encodedoc-id}/policies" }}
 */
export const generateServiceUrls = apiConfigurationObj => {
  const { baseUrl, serviceUrl, papiServiceUrl } = apiConfigurationObj;
  const serviceAPIUrlsObject = {};
  _.forOwn(serviceUrl, (value, key) => {
    serviceAPIUrlsObject[key] = { url: `${baseUrl}${value}` };
  });
  _.forOwn(papiServiceUrl, (value, key) => {
    serviceAPIUrlsObject[key] = { url: `${baseUrl}${value}` };
  });
  return serviceAPIUrlsObject;
};

export function* bootstrapApplication() {
  let encodedPolicyId = '';
  let customerIdentifier = null;
  const isLoggingEnabled = isEligibleForLogging();
  try {
    /**
     * Fetching Copy content from the external git Content Repository - "dashboard-content"
     */
    yield put(startLoader());
    yield put(getContent());

    /**
     * Fetching Product and application configuration content from the external git repository - "dashboard-configuration"
     */
    const {
      REACT_APP_CONFIGURATION_URL,
      REACT_APP_API_CONFIGURATION_URL,
      PUBLIC_URL
    } = process.env;
    const config = {
      method: 'GET',
      url: `${PUBLIC_URL}${REACT_APP_CONFIGURATION_URL}`
    };
    const response = yield call(axios, config);

    const configurationObj = response.data;

    /**
     * Fetching API configuration content from the external git repository - "dwi-api-endpoints"
     */
    const externaliseApiConfig = {
      method: 'GET',
      url: `${PUBLIC_URL}${REACT_APP_API_CONFIGURATION_URL}`
    };
    const apiConfigurationsResponse = yield call(axios, externaliseApiConfig);

    const serviceAPIUrlsObject = generateServiceUrls(
      apiConfigurationsResponse.data
    );
    /**
     * Updated the configurationObj(containing the service[stub]  and generic with the service API urls)
     */
    _.merge(configurationObj.serviceConfig, serviceAPIUrlsObject);

    yield put(setExternalConfiguration(configurationObj));

    /**
     * Stubbing in the configuration.
     */
    let apiConfigs = yield select(state => state.configurations.serviceConfig);
    const isStubbed = yield select(state => state.app.isStubbed);
    /**
     * All the api's would be stubbed based on env!=prod and the isStubbed=true
     */
    apiConfigs = stubAllAPI(apiConfigs, isStubbed);

    yield put(stubServiceConfig({ ...apiConfigs }));
    const saasAuth = yield select(state => state.saasToken);

    /**
     * Get the encoded id from the URL, that would be passed to appBootStrapSaga, for calling policy appropriately.
     */
    encodedPolicyId = determineEncodeID(window.location.href);

    const {
      genericConfig: {
        commonHeaders,
        addnCustomersHeader,
        addnEligibilityHeader,
        functionID = 'Dashboard_Web',
        mastHeadURL
      },
      serviceConfig: { customers, discountEligibility }
    } = configurationObj;

    const headers = {
      ...commonHeaders,
      Authorization: `Bearer ${saasAuth}`
    };

    const customerHeaders = {
      ...headers,
      ...addnCustomersHeader
    };

    try {
      /**
       * Fetching eligibility information to check for GA customers"
       */
      const eligibilityHeaders = {
        ...headers,
        ...addnEligibilityHeader
      };
      const eligibilityConfig = prepareServiceConfig(
        discountEligibility,
        discountEligibility.url,
        eligibilityHeaders
      );
      const eligibilityInfoResponse = yield call(axios, eligibilityConfig);
      yield put(setEligibilityInfo(eligibilityInfoResponse.data));
    } catch (e) {
      console.log('#err', e);
    }

    /**
     * Fetching customer information"
     */
    const customerConfig = prepareServiceConfig(
      customers,
      customers.url,
      customerHeaders
    );
    const customerInfoResponse = yield call(axios, customerConfig);
    yield put(setCustomerInfo(customerInfoResponse.data));

    const {
      data: {
        customerIdentifier: customerIdentifierAPI,
        functionEligibility = null
      }
    } = customerInfoResponse;
    customerIdentifier = customerIdentifierAPI;
    /**
     * Whitelisting for customers
     */
    if (!isCustomerWhiteListed(functionID, functionEligibility)) {
      const mastHeaderLinks = URLsConfiguration(mastHeadURL);
      const myInsuranceLinkObject = _.find(
        mastHeaderLinks,
        function getSpecificLink(mastHeaderLink) {
          return mastHeaderLink.text === 'myInsurance';
        }
      );
      window.location.href = myInsuranceLinkObject.url;
    }

    /**
     * Fetching policy information, only when the customer identifier is present.
     */
    if (customerIdentifier) {
      // To make policy or policy details call only when dashboard is accessed.
      if (isDashboardAccessed() && isLoggingEnabled) {
        yield put(initLogger(console));
      }
    } else {
      throw new Error('UI0001');
    }
    yield put(setAppBootstapAsComplete());
  } catch (err) {
    /** *
     * initLogger incase the api fails And if there is any issue when rendering the error page.
     */
    if (isLoggingEnabled) {
      yield put(initLogger(console));
    }
    /**
     *
     * Checking if there is an error caused due to an API failure. If not, still dispatching an action to store the error codes to the Redux store.
     * Handling of the warning or error messages is done via content repo.
     *
     */
    /**
     * Need to have a check if there was an error when accessing repro
     */
    let error = err;
    if (err.message === 'UI0001') {
      error = {
        response: {
          data: {
            errors: [
              {
                errorCode: 'UI0001'
              }
            ]
          }
        }
      };
    }
    const isReprojection = !isDashboardAccessed();
    const isDetailPage = doAND(isDashboardAccessed(), !!encodedPolicyId);
    const isSummaryCalled = yield select(state => state.policy.isSummaryCalled);
    const errorDispatchObjectArray = appBootstrapSagaErrorHandler(
      error,
      isReprojection,
      isDetailPage,
      customerIdentifier
    );
    yield all(
      errorDispatchObjectArray.map(errorDispatchObject =>
        put(errorDispatchObject)
      )
    );

    handleDetailsApiFailure(error, isDetailPage, isSummaryCalled);
  }
  yield put(setAppBootstapAsComplete());
}
export default function* appBootstrapSaga() {
  yield takeEvery(APP_BOOTSTRAP, bootstrapApplication);
}
