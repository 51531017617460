import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { UL, AccessibleHidden } from '../styles/commonStyle';
import determineNextTabIndex from '../../utils/tabUtils';
import LiComponent from './LiComponent';
import TabPanel from './TabPanel';

export default class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minTabIndex: 0,
      maxTabIndex: null
    };
    this.prefixId = props.id;
  }

  componentDidMount() {
    const { children = [] } = this.props;
    const maxTabIndex = children.length - 1;
    this.setState({ maxTabIndex });
  }

  onKeyDownHandler = (e, tabIndex, onFieldChange) => {
    const { minTabIndex, maxTabIndex } = this.state;
    const nextTabIndex = determineNextTabIndex(
      e.keyCode,
      minTabIndex,
      maxTabIndex,
      tabIndex
    );
    if (nextTabIndex !== undefined && tabIndex !== nextTabIndex) {
      e.preventDefault();
      this.onClickHandler(nextTabIndex, onFieldChange);
    }
  };

  onClickHandler = (tabId, onFieldChange) => {
    this.tabRef[tabId].focus();
    onFieldChange && onFieldChange(tabId);
  };

  getTabItems = (childProps, sectionStyle, onFieldChange, selectedTab) => {
    this.tabRef = [];
    return childProps.map(({ label }, tabIndex) => {
      const isSelected = selectedTab === tabIndex;
      const tabId = `${this.prefixId}_Tab_${tabIndex}`;
      const panelId = `${this.prefixId}_Panel_${tabIndex}`;
      const tabRefFocus = li => {
        this.tabRef[tabIndex] = li;
      };
      return (
        <LiComponent
          key={tabId}
          id={tabId}
          tabreference={li => tabRefFocus(li)}
          sectionStyle={sectionStyle}
          role="tab"
          tabIndex={isSelected ? '0' : '-1'}
          aria-controls={panelId}
          aria-selected={isSelected}
          selectedTab={isSelected}
          selectedIndex={selectedTab}
          currentIndex={tabIndex}
          onKeyDown={e => this.onKeyDownHandler(e, tabIndex, onFieldChange)}
          onClick={() => this.onClickHandler(tabIndex, onFieldChange)}
        >
          {label}
          <AccessibleHidden>
            <FormattedMessage
              id="tabListSelectedAriaLabel"
              values={{
                tabIndex: tabIndex + 1,
                tabListCount: childProps.length
              }}
            />
            {isSelected && <FormattedMessage id="selected" />}
          </AccessibleHidden>
        </LiComponent>
      );
    });
  };

  getTabPanelItems = (childProps, selectedTab) => {
    this.tabPanelRef = [];
    return childProps.map(({ children, noFocusableItem }, tabIndex) => {
      const isSelected = selectedTab === tabIndex;
      const tabId = `${this.prefixId}_Tab_${tabIndex}`;
      const panelId = `${this.prefixId}_Panel_${tabIndex}`;
      const tabPanelFocus = div => {
        this.tabPanelRef[tabIndex] = div;
      };
      return (
        <TabPanel
          key={panelId}
          id={panelId}
          aria-hidden={!isSelected}
          aria-labelledby={tabId}
          toDisplay={isSelected}
          tabpanelreference={div => tabPanelFocus(div)}
          tabIndex={noFocusableItem ? '0' : ''}
        >
          {children}
        </TabPanel>
      );
    });
  };

  render() {
    const {
      sectionStyle,
      children = [],
      onFieldChange,
      selectedTab
    } = this.props;
    const childProps = children.map(child => child.props);
    return (
      <>
        <UL role="tablist">
          {this.getTabItems(
            childProps,
            sectionStyle,
            onFieldChange,
            selectedTab
          )}
        </UL>
        {this.getTabPanelItems(childProps, selectedTab)}
      </>
    );
  }
}

Tabs.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  selectedTab: PropTypes.number.isRequired,
  sectionStyle: PropTypes.string,
  onFieldChange: PropTypes.func
};

Tabs.defaultProps = {
  sectionStyle: '',
  onFieldChange: null
};
