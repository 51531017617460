import React from 'react';
import PropTypes from 'prop-types';
import {
  BasicPlanOptionWrapper,
  BasicPlanOptionHeader,
  BasicPlanOptionDesc,
  BasicPlanOptionImgSpan,
  BasicPlanOptionList,
  ImagePath
} from '../../detailPageStyle';

const BasicPlanOptionLayout = ({
  id,
  srcIcon,
  dependentContentData,
  contentDescription,
  formatMessage
}) => {
  return (
    <BasicPlanOptionWrapper>
      <BasicPlanOptionImgSpan>
        <ImagePath aria-hidden="true" alt="" src={srcIcon} />
      </BasicPlanOptionImgSpan>
      <BasicPlanOptionList id={id}>
        <BasicPlanOptionHeader role="heading">
          {formatMessage({ id: dependentContentData })}
        </BasicPlanOptionHeader>
        <BasicPlanOptionDesc>{contentDescription}</BasicPlanOptionDesc>
      </BasicPlanOptionList>
    </BasicPlanOptionWrapper>
  );
};

BasicPlanOptionLayout.propTypes = {
  id: PropTypes.string.isRequired,
  srcIcon: PropTypes.string.isRequired,
  contentDescription: PropTypes.string.isRequired,
  dependentContentData: PropTypes.string.isRequired,
  formatMessage: PropTypes.func.isRequired
};

export default BasicPlanOptionLayout;
