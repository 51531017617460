import React from 'react';
import PropTypes from 'prop-types';
import * as icons from '../../assets/images/NotificationIcons';
import { NotificationMessageIcon } from '../../common/styles/commonStyle';
import {
  SubmitNotificationSection,
  InstructionNotificationSpan,
  SubmitNotificationMessage,
  SubmitImagePath,
  ErrorButtonStyle
} from '../instructionHistoryStyle';
import {
  getUniqueErrorCodes,
  generateCommaSeparatedRefNumbers
} from '../instructionUtils';

const ErrorSection = function ErrorSection(props, context) {
  const {
    intl: { formatMessage }
  } = context;
  const {
    errors,
    fetchInstructionDetails,
    encodePolicyNumber,
    transCode,
    referenceNo,
    source
  } = props;
  const errorCodesList = getUniqueErrorCodes(errors);

  return (
    <SubmitNotificationSection>
      <NotificationMessageIcon role="alert">
        <InstructionNotificationSpan>
          <SubmitImagePath aria-hidden="true" alt="" src={icons.Warning} />
        </InstructionNotificationSpan>
        <SubmitNotificationMessage>
          <span>
            {formatMessage(
              { id: 'instruction_detail_error_label' },
              {
                errorCodes: generateCommaSeparatedRefNumbers(
                  errorCodesList,
                  'commaLabel',
                  formatMessage
                )
              }
            )}
          </span>
          <ErrorButtonStyle
            id="instruction_detail_try_again_btn"
            onClick={() =>
              fetchInstructionDetails({
                transCode,
                referenceNo,
                encodedProductId: encodePolicyNumber,
                apiCallCount: 1,
                source
              })
            }
            aria-label={formatMessage({
              id: 'instruction_detail_try_again_btn'
            })}
          >
            {formatMessage({ id: 'instruction_detail_try_again_btn' })}
          </ErrorButtonStyle>
        </SubmitNotificationMessage>
      </NotificationMessageIcon>
    </SubmitNotificationSection>
  );
};

ErrorSection.propTypes = {
  errors: PropTypes.array.isRequired,
  fetchInstructionDetails: PropTypes.func.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  transCode: PropTypes.string.isRequired,
  referenceNo: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
};

ErrorSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ErrorSection;
