import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SummaryPage from '../policysummary/SummaryPage';
import DetailPage from '../policydetail/DetailPage';
import ReprojectionPage from '../reprojection/ReprojectionPage';
import InstructionHistory from '../instructionhistory/InstructionHistory';
import { MainContainer } from '../common/styles/commonStyle';
import Context from './Context';
import { hideMegaMenu } from './components/masthead/MegaMenu/megaMenuUtils';
import { megaMenuPathToMatch, checkLocation } from '../utils/routeUtils';
import Loader from '../common/loader';

const StatementPreference = lazy(() =>
  import('../estatement/StatementPreference')
);
const StatementPreferenceConfirmation = lazy(() =>
  import('../estatement/StatementPreferenceConfirmation')
);

const EstatementPendingInstruction = lazy(() =>
  import('../estatement/EstatementPendingInstruction')
);

/**
 * Routes config
 */

class Routes extends Component {
  componentDidMount() {
    const {
      history,
      setContactUsPopupHandler,
      encryptedCIN,
      location,
      setSelectedTabHandler
    } = this.props;
    const { policies, browsebycategories } = megaMenuPathToMatch();
    if (checkLocation(location.pathname, browsebycategories)) {
      setSelectedTabHandler(1);
    }

    if (checkLocation(location.pathname, policies)) {
      const elmnt = document.getElementById('myPolicies');
      if (elmnt) {
        elmnt.scrollIntoView();
        hideMegaMenu();
      }
    }

    history.listen(() => {
      setContactUsPopupHandler(false);
    });
    if (encryptedCIN) {
      const encryptedCINDiv = document.querySelector('div#customer_id');
      if (encryptedCINDiv) {
        document.querySelector('div#customer_id').innerHTML = encryptedCIN;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { location, setSelectedTabHandler } = this.props;
    const { policies, browsebycategories } = megaMenuPathToMatch();
    if (
      location !== prevProps.location &&
      checkLocation(location.pathname, browsebycategories)
    ) {
      setSelectedTabHandler(1);
      hideMegaMenu();
    }
    if (
      location !== prevProps.location &&
      checkLocation(location.pathname, policies)
    ) {
      const elmnt = document.getElementById('myPolicies');
      if (elmnt) {
        elmnt.scrollIntoView();
        hideMegaMenu();
      }
    }
  }

  render() {
    return (
      <Context.Consumer>
        {contextProvider => {
          const {
            applicationUrl: { forRoute }
          } = contextProvider;

          return (
            <>
              {/*
            <MainContainer> Added main tag for accessibility point of view,
             which mean your main UI structure composed within this tag
        */}
              <MainContainer role="main" id="mainContainer">
                <Switch>
                  <Route
                    exact
                    path={forRoute.summary}
                    component={SummaryPage}
                  />
                  <Route path={forRoute.policies} component={SummaryPage} />
                  <Route
                    path={forRoute.browsebycategories}
                    component={SummaryPage}
                  />
                  <Route path={forRoute.details} component={DetailPage} />
                  <Route
                    path={forRoute.reprojections}
                    component={ReprojectionPage}
                  />
                  <Route
                    path={forRoute.instructionhistory}
                    component={InstructionHistory}
                  />
                  <Suspense fallback={<Loader opacity={1} id="loadingText" />}>
                    <Route
                      path={forRoute.estatement}
                      component={StatementPreference}
                    />
                    <Route
                      path={forRoute.estatementpendinginstruction}
                      component={EstatementPendingInstruction}
                    />
                    <Route
                      path={forRoute.estatementConfirmation}
                      component={StatementPreferenceConfirmation}
                    />
                  </Suspense>
                  <Redirect to={forRoute.summary} />
                </Switch>
              </MainContainer>
            </>
          );
        }}
      </Context.Consumer>
    );
  }
}

Routes.contextTypes = {
  applicationUrl: PropTypes.object
};

Routes.propTypes = {
  history: PropTypes.object.isRequired,
  setContactUsPopupHandler: PropTypes.func.isRequired,
  encryptedCIN: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  setSelectedTabHandler: PropTypes.func.isRequired
};
export default withRouter(Routes);
