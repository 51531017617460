import styled from 'styled-components';
import { em, rem } from 'polished';
import { CheckBox } from 'ui-component-library';
import { FontFamily } from '../utils/font';
import media from '../utils/media';
import theme from '../common/styles/theme';

export const ChartLegendsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
  padding-left: ${rem('30px')};
  padding-top: ${rem('20px')};
  margin-top: ${rem('40px')};
  align-items: center;
  height:56px;
  box-sizing: border-box
  padding-bottom: ${rem('15px')};
  ${media.tablet`
    padding-left: ${rem('21px')};
    padding-top: ${rem('15px')};
    align-items: flex-start;
    height:auto;
  `};
  ${media.phone`
    padding-left: ${rem('16px')};
    padding-top: ${rem('15px')};
    padding-bottom: ${rem('0px')};
    flex-direction: column;
    margin-left: ${rem('15px')};
    margin-right: ${rem('15px')};
	`};
`;

export const ChartLegend = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: ${rem('30px')};
  visibility: ${props => (props.showLegend ? 'visible' : 'hidden')};
  ${media.tablet`
    margin-right: ${rem('25px')};
  `};
  ${media.phone`
    padding-bottom: ${rem('12px')};
    margin-right: 0;
    display: ${props => (props.showLegend ? 'flex' : 'none')};
  `}
`;

export const ChartLegendIcon = styled.div`
  width: ${rem('26px')};
  height: ${rem('26px')};
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${rem('12px')};
  background: url(${props => props.imagePath}) ${props => props.imagePosition};
  background-repeat: no-repeat;
`;

export const ChartLegendCaption = styled.span`
  font-size: ${em('14px')};
  line-height: ${rem('18px')};

  ${FontFamily.RegularFontW01};
`;

export const ChartLegendCaptionWrapper = styled.div``;

export const ChartLegendCheckboxTooltipWrapper = styled.span`
  padding-bottom: 3px;
`;

export const ChartLegendsCheckboxWrapper = styled.div`
  padding: ${rem('6px')} ${rem('6px')} ${rem('6px')} 0;
  display: flex;
  margin-left: ${rem('6px')};
  margin-top: ${rem('40px')};
  align-items: center;
  font-size: ${em('16px')};
  ${media.tablet`
    margin-top: ${rem('36px')}
  `}
  ${media.phone`
    padding: 0;
    font-size: ${rem('14px')};
    margin-left: ${rem('15px')};
    margin-top: ${rem('20px')}
	`};
`;

export const ChartLegendsCheckboxField = styled(CheckBox)`
  ${FontFamily.RegularFontW01};
  &.custom-checkbox {
    display: flex;
    align-items: center;

    & > div[id^='checkbox_field'] {
      line-height: ${rem('24px')};
      ${media.phone`
        line-height: ${rem('14px')};
    `};
    }
  }

  input.default:focus + label {
    border: 1px dotted ${theme.stepTextDefault};
  }
`;

export const ChartLegendsCheckboxLabel = styled.label`
  font-size: ${em('16px')};
  ${FontFamily.RegularFontW01};
  padding-top: ${rem('9px')};
  padding-bottom: ${rem('9px')};
  ${media.phone`
  font-size: ${rem('14px')};
  line-height: ${rem('19px')};
  padding-top: ${rem('3px')};
  padding-bottom: ${rem('4px')};
`};
`;

export const ChartLegendsSectionWrapper = styled.div`
  margin-top: ${rem('40px')};
  ${media.tablet`
    margin-top: ${rem('30px')};
  `};
  ${media.phone`
    margin-top: ${rem('23px')};
  `};
`;
