import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { DropDown } from 'ui-component-library';
import ReprojectionTabBuilder from '../../ReprojectionTabBuilder';
import { FutureSection } from '../../reprojectionPageStyle';

const FutureBenefitsMobileTabs = function FutureBenefitsMobileTabs(props) {
  const {
    tabList,
    formatMessage,
    reprojectionsDetails,
    sectionFieldMapper,
    subSectionsMapper,
    fieldConfig,
    productCategory,
    dependentFieldConfig,
    onFieldChange,
    selectedIndex,
    reprojectionGenericConfig,
    reprojectionChartConfig,
    customerAge,
    setCustomerAge,
    setCustomerMinPayAge,
    setCustomerMaxPayAge,
    customerMinPayAge,
    customerMaxPayAge,
    toggleIncludeNonGuaranteedPayout,
    tabValues,
    selectedFutureBenefitsTab,
    sectionNotificationConfig
  } = props;

  const benefitsTabsList = [];

  _.map(tabList, (listItem, index) => {
    benefitsTabsList.push({
      displayText: formatMessage({ id: listItem.title }),
      value: index
    });
  });

  return (
    <>
      <FutureSection>
        <DropDown
          options={benefitsTabsList}
          id="futureBenefitsTabs"
          value={selectedIndex}
          onChange={value => onFieldChange(value)}
        />
      </FutureSection>
      {selectedIndex > -1 && (
        <ReprojectionTabBuilder
          productCategory={productCategory}
          reprojectionsDetails={reprojectionsDetails}
          sectionToRender={tabList[selectedIndex].section}
          sectionFieldMapper={sectionFieldMapper}
          subSectionsMapper={subSectionsMapper}
          fieldConfig={fieldConfig}
          dependentFieldConfig={dependentFieldConfig}
          reprojectionGenericConfig={reprojectionGenericConfig}
          reprojectionChartConfig={reprojectionChartConfig}
          setCustomerAge={setCustomerAge}
          customerAge={customerAge}
          customerMinPayAge={customerMinPayAge}
          customerMaxPayAge={customerMaxPayAge}
          setCustomerMinPayAge={setCustomerMinPayAge}
          setCustomerMaxPayAge={setCustomerMaxPayAge}
          toggleIncludeNonGuaranteedPayout={toggleIncludeNonGuaranteedPayout}
          tabValues={tabValues}
          selectedFutureBenefitsTab={selectedFutureBenefitsTab}
          sectionNotificationConfig={sectionNotificationConfig}
        />
      )}
    </>
  );
};
FutureBenefitsMobileTabs.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  productCategory: PropTypes.string.isRequired,
  sectionFieldMapper: PropTypes.object.isRequired,
  subSectionsMapper: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  customerAge: PropTypes.number.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  toggleIncludeNonGuaranteedPayout: PropTypes.func.isRequired,
  tabValues: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired
};

export default FutureBenefitsMobileTabs;
