import React from 'react';
import PropTypes from 'prop-types';
import { AccessibleHidden } from '../../../app/AppStyles';
import { PolicyNumberRow, PolicyNumberText } from '../../detailPageStyle';
import ShowIf from '../../../utils/showIf';

const PolicyNumberField = (props, context) => {
  const { fieldData } = props;
  const {
    intl: { formatMessage }
  } = context;
  if (!fieldData || typeof fieldData !== 'string') {
    return <React.Fragment />;
  }
  return (
    <>
      <ShowIf condition={!!fieldData}>
        <PolicyNumberRow>
          <AccessibleHidden>
            {formatMessage({ id: 'policyNumber' })}
          </AccessibleHidden>
          <PolicyNumberText
            id="policy_number"
            aria-label={formatMessage({ id: 'policyNumber' })}
          >
            {fieldData}
          </PolicyNumberText>
        </PolicyNumberRow>
      </ShowIf>
    </>
  );
};

PolicyNumberField.propTypes = {
  fieldData: PropTypes.string.isRequired
};

PolicyNumberField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyNumberField;
