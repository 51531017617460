import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FundDetailWrapper,
  FundTypeHeading,
  FundNameSection,
  FundName,
  FundAllocation
} from '../instructionHistoryStyle';
import FundNameAllocation from '../templates/FundNameAllocation';
import Currency from '../../common/currency/Currency';
import getDescription from '../../utils/reducerUtils';
import SlaNoteSection from '../templates/SlaNoteSection';

const ChangePremiumAllocation = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const { funds, currency, status } = props;
  const { premiumAllocationFundList, premiumAllocationMethod } = funds;

  const fundAllocationMethod = _.get(
    premiumAllocationMethod,
    'fundAllocationMethod'
  );

  if (!fundAllocationMethod) {
    return null;
  }

  const showFundAllocationPercentage = (fundName, newAllocationFundAmount) => {
    return (
      <FundNameAllocation
        locale={locale}
        fundName={fundName}
        percent={_.toNumber(newAllocationFundAmount)}
        labelId="switchInValue"
      />
    );
  };
  const showFundAllocationAmount = (fundName, newAllocationFundAmount) => {
    return (
      <FundNameSection>
        <FundName>{getDescription(locale, fundName)}</FundName>
        <FundAllocation>
          <Currency
            currencyCode={`${currency}`}
            amount={newAllocationFundAmount}
          />
        </FundAllocation>
      </FundNameSection>
    );
  };

  const checkFundAllocationMethod = (fundName, newAllocationFundAmount) => {
    if (fundAllocationMethod === 'P') {
      return showFundAllocationPercentage(fundName, newAllocationFundAmount);
    }
    if (fundAllocationMethod === 'A') {
      return showFundAllocationAmount(fundName, newAllocationFundAmount);
    }
    return null;
  };

  return (
    <FundDetailWrapper>
      <FundTypeHeading>{formatMessage({ id: 'premiumLabel' })}</FundTypeHeading>
      {_.map(premiumAllocationFundList, fund => {
        const { fundName, newAllocationFundAmount } = fund;
        return checkFundAllocationMethod(fundName, newAllocationFundAmount);
      })}
      <SlaNoteSection labelId="slaText_changePremium" status={status} />
    </FundDetailWrapper>
  );
};

ChangePremiumAllocation.propTypes = {
  funds: PropTypes.object,
  currency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

ChangePremiumAllocation.defaultProps = {
  funds: {}
};
ChangePremiumAllocation.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ChangePremiumAllocation;
