import styled from 'styled-components';
import { em, rem } from 'polished';
import { Section } from '../styles/commonStyle';
import theme from '../styles/theme';
import media from '../../utils/media';
import { FontFamily } from '../../utils/font';

export const Wrapper = styled(Section)`
  padding: ${rem('20px')} ${rem('30px')};
  background-color: #f9f2f3;
  border: 1px solid #af000d;
  margin-bottom: ${rem('30px')};
  display: flex;

  ${media.tablet`
padding: ${rem('20px')} ${rem('30px')};
`};

  ${media.phone`
padding: ${rem('20px')} ${rem('10px')} ${rem('20px')} ${rem('30px')};
margin-bottom: ${rem('20px')};
`};
`;

export const IconWrapper = styled.div`
  max-height: 20px;
  max-width: 22px;
  margin-right: ${rem('15px')};
  height: 100%;
  width: 100%;
`;

export const Icon = styled.img`
  height: 100%;
  width: 100%;
`;

export const WarningOrErrorMessage = styled.div`
  font-family: ${FontFamily.RegularFontW01};
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  line-height: 20px;
`;
/**
 * NoPolicyAvailable : To be displayed when there no policies for the ci
 */
export const NoPolicyAvailable = styled.div`
  ${FontFamily.RegularFontW01}
  font-size: ${em('16px')}
  margin-top:${rem('30px')};
  margin-bottom: ${rem('15px')};`;
