/*
TODO use this instead of index.html script
let webSocketInstance;
let socketURL = "wss://dia4pzbbo9.execute-api.us-east-1.amazonaws.com/dev";//TODO CHANGE URL AS PER ENV

function setupWebSocket(url) {
  webSocketInstance = new WebSocket(url);
  webSocketInstance.onclose = function () {
    console.info
    setTimeout(setupWebSocket, 1000);
  };
}

setupWebSocket(socketURL);
 */
export const contentDataHandler = (cb, event) => {
  let isConfig;
  const previewCms = event.data && event.data.previewCms;
  if (previewCms) {
    if (previewCms === 'cms') {
      /* eslint-disable no-console */
      console.log(event, `Content Received ${event.data} from ${event.origin}`);
      isConfig = false;
    } else if (previewCms === 'configuration') {
      /* eslint-disable no-console */
      console.log(
        event,
        `Configuration Received ${event.data} from ${event.origin}`
      );
      isConfig = true;
    }
    return cb(event.data, isConfig);
  }
  return false;
};
export default function onContentData(cb) {
  window.addEventListener('message', function contentCallback(event) {
    contentDataHandler(cb, event);
  });
}
