import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import { authenticateSession } from '../../../utils/saasAuthModuleUtil';
import { ParagraphDiv } from '../../../common/styles/modalStyles';

export const SessionTimeoutPopUp = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const [isModalOpen, setIsModalOpen] = useState(true);

  const onCloseHandler = () => {
    setIsModalOpen(false);
    authenticateSession();
  };
  const footerButtonList = [
    {
      className: 'redBtn',
      label: formatMessage({ id: 'Ok' }),
      id: 'ok_btn',
      align: 'right'
    }
  ];

  return (
    <ModalPopUp
      isModalOpen={isModalOpen}
      onClose={onCloseHandler}
      modalHeading={formatMessage({ id: 'timeoutModalTitle' })}
      footerButtonList={footerButtonList}
      btnHandler={{
        ok_btn: onCloseHandler
      }}
    >
      <ParagraphDiv>
        {formatMessage({ id: 'timeoutModalContent' })}
      </ParagraphDiv>
    </ModalPopUp>
  );
};

SessionTimeoutPopUp.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SessionTimeoutPopUp;
