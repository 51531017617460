import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  FullPaidContainer,
  ImageFullPaidWrapper,
  ImageFullPaid,
  FullPaidTextWrapper,
  FullPaidTextSpan
} from '../../detailPageStyle';
import { paidFull } from '../../../assets/images/Payment';
import { getPaymentMode } from '../../../utils/tealiumUtils';
import getContentInSpecificLang from '../../../utils/localeUtils';

const PaymentInfoFullPay = props => {
  const { labelContent, langObj } = props;

  if (!labelContent) {
    return null;
  }

  const paymentModeKeyLangSpecific = getContentInSpecificLang(
    labelContent,
    'en',
    langObj
  );
  getPaymentMode(paymentModeKeyLangSpecific);

  return (
    <FullPaidContainer>
      <ImageFullPaidWrapper>
        <ImageFullPaid aria-hidden="true" alt="" src={paidFull} />
      </ImageFullPaidWrapper>
      <FullPaidTextWrapper>
        <FullPaidTextSpan>
          <FormattedMessage id={labelContent} />
        </FullPaidTextSpan>
      </FullPaidTextWrapper>
    </FullPaidContainer>
  );
};

PaymentInfoFullPay.propTypes = {
  labelContent: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired
};

export default PaymentInfoFullPay;
