import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  setDocumentsWarningsOrErrors,
  endLoader,
  startLoader,
  setPolicyDocumentsDetails
} from '../app/appActions';
import { GET_POLICY_DOCUMENTS_DETAILS } from '../actionTypes';
import {
  policyDocumentsSagaErrorHandler,
  prepareDownloadDocumentAPIConfig,
  preparePolicyDocumentsStorePayload
} from '../utils/sagaUtils';

const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* fetchPolicyDocuments(action) {
  const {
    payload: { documentSysID, apiCallCount }
  } = action;
  try {
    const configurationObj = yield select(getConfigurations);
    const saasAuth = yield select(getSaasToken);
    const {
      genericConfig: { commonHeaders, addnCustomersHeader },
      serviceConfig: { documentRetrieve }
    } = configurationObj;
    const idcHeaders = {
      Authorization: `Bearer ${saasAuth}`,
      ...commonHeaders,
      ...addnCustomersHeader,
      'content-type': 'application/json',
      'X-HSBC-Global-Channel-Id': 'OHI',
      'X-HSBC-DocumentSysId': documentSysID
    };

    const documentRetrieveConfig = prepareDownloadDocumentAPIConfig(
      documentSysID,
      documentRetrieve,
      idcHeaders
    );

    yield put(startLoader());
    const documentResponse = yield call(axios, documentRetrieveConfig);
    const { data } = documentResponse;
    const policyDocumentsData = preparePolicyDocumentsStorePayload(
      documentSysID,
      data.result
    );
    yield put(setPolicyDocumentsDetails(policyDocumentsData));
    yield put(endLoader());
  } catch (err) {
    let errorCount = apiCallCount;
    if (errorCount < 2) {
      errorCount += 1;
      yield fetchPolicyDocuments({
        payload: { documentSysID, apiCallCount: errorCount }
      });
    } else {
      const errorDispatchObject = policyDocumentsSagaErrorHandler(
        documentSysID,
        err
      );
      yield put(setDocumentsWarningsOrErrors(errorDispatchObject));
      yield put(endLoader());
    }
  }
}

export default function* policyDocumentsSaga() {
  yield takeEvery(GET_POLICY_DOCUMENTS_DETAILS, fetchPolicyDocuments);
}
