import {
  SET_CORE_FUND_DETAILS,
  SET_FUND_SELECTION_ERRORS_WARNINGS
} from '../actionTypes';

export const INITIAL_STATE = {
  fundSelectionData: {},
  fundSelectionWarningsOrErrors: {}
};

const fundSelectionReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case SET_CORE_FUND_DETAILS: {
      return {
        ...state,
        fundSelectionData: payload
      };
    }
    case SET_FUND_SELECTION_ERRORS_WARNINGS: {
      const fundSelectionWarningsOrErrors = {
        ...state.fundSelectionWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        fundSelectionWarningsOrErrors
      };
    }
    default:
      return state;
  }
};

export default fundSelectionReducer;
