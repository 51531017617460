import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SectionWrapper,
  SectionHeader,
  SectionHeaderText,
  SectionBody,
  FullPaidSectionBody,
  SectionImgSpan,
  SectionImgPayment,
  SectionFooter,
  SectionFooterLinkWrapper
} from './SectionStyle';
import {
  OverviewWrapperPayerSection,
  OverviewWrapperPayment,
  DetailsPageMiddleSection,
  ImagePath,
  ImagePaymentPath
} from '../detailPageStyle';
import {
  ImgStyle,
  UpdateContactLink,
  UpdateContactLabel,
  UpdateIconWrapper
} from './contactInfoStyles';
import * as images from '../../assets/images/Payment';
import iconRight from '../../assets/images/Icon_right.svg';
import PaymentImg from '../../assets/images/payment.svg';
import {
  getFieldDataFromConfig,
  checkFieldData
} from '../../utils/policyUtils';
import getPaymentAlertList from '../sectionUtils/PaymentUtils';
import AlertIndicators from './AlertIndicators';
import getTealiumSectionName from '../../tealium/teliumSectionName';
import getContentInSpecificLang from '../../utils/localeUtils';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import ModalPopupGI from './ModalPopupGI';
import { getCurrentPolicyType } from '../sectionUtils/SectionsUtils';
import { setLinkFocus } from '../../utils/domUtils';

import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';
import { ParagraphDiv } from '../../common/styles/modalStyles';

const PaymentInfo = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const [isModalOpen, updateModalState] = useState(false);
  const {
    fieldsStack,
    stackingFamily,
    sectionHeading,
    dependentSectionField,
    dependentSectionFieldTwo,
    fieldConfig,
    currentPolicyDetail,
    superFamily,
    genericConfig: {
      notificationMessages,
      p2gDemiseList: { ChangeInPremiumPaymentInstruction }
    },
    langObj
  } = props;
  const linkRef = React.createRef();
  const { isGIPolicy, isLifePolicy } = getCurrentPolicyType(superFamily);
  const onCloseClick = () => {
    updateModalState(!isModalOpen);
    setLinkFocus(linkRef.current, true);
  };
  if (!fieldsStack || (fieldsStack.length && fieldsStack.length < 1)) {
    return <></>;
  }
  let isPremiumFullPaid = false;
  let paymentAlerts = [];
  let eventData = {};
  const eventAction = 'onsite';
  const headerContent = getTealiumSectionName(sectionHeading);
  const linkName = getContentInSpecificLang(
    'update_payment_information',
    'en',
    langObj
  );
  eventData = createEventActionContent(eventAction, linkName, headerContent);
  const onContinueClick = () => {
    trackEvents({
      eventName: 'popup',
      eventContent: getContentInSpecificLang(
        'update_payment_information',
        'en',
        langObj
      )
    });
  };
  if (fieldsStack[0] && fieldsStack[0][0]) {
    const {
      props: { fieldData }
    } = fieldsStack[0][0];
    isPremiumFullPaid = fieldData === true;
  }
  const overDueAlertIndicator = getFieldDataFromConfig(
    currentPolicyDetail,
    fieldConfig[dependentSectionField],
    true
  );
  // using notificationmessages object for styling the alert/ adding an extra fields array for iterating the alerts based on errorType
  if (overDueAlertIndicator) {
    paymentAlerts = getPaymentAlertList(
      paymentAlerts,
      fieldConfig[dependentSectionField],
      notificationMessages
    );
  }
  const aplAlertIndicator = getFieldDataFromConfig(
    currentPolicyDetail,
    fieldConfig[dependentSectionFieldTwo],
    true
  );
  if (aplAlertIndicator) {
    paymentAlerts = getPaymentAlertList(
      paymentAlerts,
      fieldConfig[dependentSectionFieldTwo],
      notificationMessages
    );
  }
  /**
   * Adding a check for the fieldstacks 1 and 2 sub section i.e. right and bottom half of Payment section.
   * If both of them don't have fields appropriate enough to display on UI, we suppress the entire Payment Section.
   */
  if (
    fieldsStack[1] &&
    !checkFieldData(fieldsStack[1]) &&
    fieldsStack[2] &&
    !checkFieldData(fieldsStack[2])
  ) {
    return null;
  }
  return (
    <>
      <DetailsPageMiddleSection>
        <SectionWrapper>
          <SectionHeader>
            <SectionImgSpan>
              <ImagePath aria-hidden="true" alt="" src={PaymentImg} />
            </SectionImgSpan>
            <SectionHeaderText>
              {formatMessage({ id: sectionHeading })}
            </SectionHeaderText>
          </SectionHeader>
          {isPremiumFullPaid && (
            <FullPaidSectionBody>{fieldsStack[0]}</FullPaidSectionBody>
          )}
          {!isPremiumFullPaid && (
            <>
              {paymentAlerts && paymentAlerts.length > 0 && (
                <AlertIndicators
                  alerts={paymentAlerts}
                  superFamily={superFamily}
                  position="cardLevel"
                />
              )}
              <SectionBody>
                <SectionImgPayment>
                  <ImagePaymentPath
                    aria-hidden="true"
                    alt={formatMessage({ id: 'decorativeImage' })}
                    src={images[stackingFamily]}
                  />
                </SectionImgPayment>
                <OverviewWrapperPayment>
                  {fieldsStack[1]}
                </OverviewWrapperPayment>
              </SectionBody>
              <SectionFooter>
                <OverviewWrapperPayerSection>
                  {fieldsStack[2]}
                </OverviewWrapperPayerSection>
                <SectionFooterLinkWrapper isPaymentSection>
                  <UpdateContactLink
                    href="#"
                    id="update_payment_information"
                    aria-label={formatMessage({
                      id: 'update_payment_information'
                    })}
                    rel="noopener noreferrer"
                    onClick={() => {
                      updateModalState(!isModalOpen);
                      trackEvents({
                        eventName: 'updatePaymentInstruction',
                        eventData
                      });
                    }}
                    ref={linkRef}
                  >
                    <UpdateContactLabel>
                      {formatMessage({ id: 'update_payment_information' })}
                    </UpdateContactLabel>
                    <UpdateIconWrapper>
                      <ImgStyle aria-hidden="true" alt="" src={iconRight} />
                    </UpdateIconWrapper>
                  </UpdateContactLink>
                  {isLifePolicy && (
                    <>
                      <ModalPopUp
                        isModalOpen={isModalOpen}
                        onClose={() => onCloseClick()}
                        modalHeading=""
                        footerButtonList={[
                          {
                            className: 'whiteBtn',
                            label: formatMessage({ id: 'cancelPopup' }),
                            id: 'cancel_btn',
                            align: 'left'
                          },
                          {
                            className: 'redBtn',
                            label: formatMessage({ id: 'continuePopup' }),
                            id: 'continue_btn',
                            align: 'right'
                          }
                        ]}
                        btnHandler={{
                          continue_btn: onContinueClick,
                          cancel_btn: () =>
                            onCloseClick()
                          }}
                        proceedToLink={
                          ChangeInPremiumPaymentInstruction[locale]
                        }
                      >
                        <ParagraphDiv>
                          {formatMessage({ id: 'makeaClaimPopUpText' })}
                        </ParagraphDiv>
                      </ModalPopUp>
                    </>
                  )}
                  {isGIPolicy && (
                    <ModalPopupGI
                      linkLabel={formatMessage({
                        id: 'updatePaymentInstructionPopupTitle'
                      })}
                      isModalOpen={isModalOpen}
                      onClose={onCloseClick}
                      const
                      footerButtonList={[
                        {
                          className: 'redBtn',
                          label: formatMessage({
                            id: 'updatePaymentInstructionClosePopupButton'
                          }),
                          id: 'close_btn',
                          align: 'left'
                        }
                      ]}
                      btnHandler={{
                        close_btn: onCloseClick
                      }}
                    />
                  )}
                </SectionFooterLinkWrapper>
              </SectionFooter>
            </>
          )}
        </SectionWrapper>
      </DetailsPageMiddleSection>
    </>
  );
};

PaymentInfo.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  stackingFamily: PropTypes.string.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  dependentSectionField: PropTypes.string,
  dependentSectionFieldTwo: PropTypes.string,
  currentPolicyDetail: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired,
  superFamily: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired
};

PaymentInfo.defaultProps = {
  dependentSectionField: '',
  dependentSectionFieldTwo: ''
};

PaymentInfo.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default PaymentInfo;
