import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../../../common/currency/Currency';
import {
  ReturnsIndividualFieldLabel,
  ReturnsIndividualFieldValue,
  ReturnsIndividualFieldWrapper
} from '../../sections/SectionStyle';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';

const ReturnsAnnuityPaymentAmount = (props, context) => {
  const { labelContent, fieldData, toolTipContent, sectionHeading } = props;
  const {
    intl: { locale, formatMessage }
  } = context;
  if (
    !labelContent ||
    typeof fieldData !== 'object' ||
    !fieldData.currency ||
    (!fieldData.amount && fieldData.amount !== 0)
  ) {
    return <></>;
  }
  return (
    <>
      <ReturnsIndividualFieldWrapper>
        <ReturnsIndividualFieldLabel>
          {formatMessage({ id: labelContent })}
          <TooltipWrapper
            id={`tooltip_policy_value_${toolTipContent}`}
            contentKey={toolTipContent}
            labelContent={toolTipContent}
            sectionHeading={sectionHeading}
            maxWidth={300}
          />
        </ReturnsIndividualFieldLabel>
        <ReturnsIndividualFieldValue amountField>
          {locale !== 'en' ? formatMessage({ id: 'perMonth' }) : null}
          <Currency
            currencyCode={fieldData.currency}
            amount={fieldData.amount}
          />
          {locale === 'en' ? formatMessage({ id: 'perMonth' }) : null}
        </ReturnsIndividualFieldValue>
      </ReturnsIndividualFieldWrapper>
    </>
  );
};

ReturnsAnnuityPaymentAmount.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  sectionHeading: PropTypes.string.isRequired
};

ReturnsAnnuityPaymentAmount.defaultProps = {
  toolTipContent: null
};
ReturnsAnnuityPaymentAmount.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReturnsAnnuityPaymentAmount;
