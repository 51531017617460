/**
 * @description : This function returns the currency symbol.
 * @param {*} currencyCode : The currency code for which the symbol is required.
 */
import React from 'react';

export const currencyMap = {
  HKD: <span>&#36;</span>,
  CNY: <span>&#165;</span>,
  GBP: <span>&#163;</span>,
  USD: <span>&#36;</span>,
  AUD: <span>&#36;</span>,
  EUR: <span>&#8364;</span>
};

export const currencyMapper = function currencyMapper(currencyCode = '') {
  if (currencyMap[currencyCode]) {
    return currencyMap[currencyCode];
  }
  return '';
};
/**
 * @description : This function when truncating does not apply rounding logic.
 * @param {*} inputNumber : The number that needs to be truncated
 * @param {*} digits : Truncated to decimal places.
 */
export const truncDigits = (inputNumber, decimals) => {
  const explodedInputNumber = inputNumber.toString().split('.');
  if (explodedInputNumber[1]) {
    return parseFloat(
      `${explodedInputNumber[0]}.${explodedInputNumber[1].slice(0, decimals)}`
    ).toFixed(decimals);
  }

  return Number(`${explodedInputNumber[0]}`).toFixed(decimals);
};

/**
 * check for negative amount value especially for values like -0.06
 */
const checkForNegativeAmount = amount => {
  if (amount < 0) {
    return true;
  }
  return false;
};

export const formatCurrency = (value, decimalRange) => {
  const decimalplaces = Number.isInteger(decimalRange) ? decimalRange : 2;
  const decimalcharacter = '.';
  const thousandseparater = ',';
  let amount = parseFloat(value);

  amount = truncDigits(amount, decimalplaces);

  const isNegativeValue = checkForNegativeAmount(amount);

  let formatted = String(amount);

  /**
   * Replaces the . with the decimal representation mentioned above in decimalcharacter
   */
  if (decimalcharacter.length && decimalcharacter !== '.') {
    formatted = formatted.replace(/\./, decimalcharacter);
  }
  let fraction = '';
  let negativeRepresentation = '';
  const strnumber = String(formatted);
  let integer = strnumber;
  const dotpos = decimalcharacter.length
    ? strnumber.indexOf(decimalcharacter)
    : -1;
  if (dotpos > -1) {
    if (dotpos) {
      integer = strnumber.substr(0, dotpos);
    }
    fraction = strnumber.substr(dotpos + 1);
  }
  // when the integer is negative, we need to add when returning
  if (integer < 0 || isNegativeValue) {
    negativeRepresentation = '-';
  }
  if (integer) {
    integer = String(Math.abs(Number(integer)));
  }
  let decimalWithFraction = '';
  if (fraction !== '') {
    while (fraction.length < decimalplaces) {
      fraction += '0';
    }
    decimalWithFraction = `${decimalcharacter}${fraction}`;
  }

  const temparray = [];
  while (integer.length > 3) {
    temparray.unshift(integer.substr(-3));
    integer = integer.substr(0, integer.length - 3);
  }
  temparray.unshift(integer);
  integer = temparray.join(thousandseparater);
  return `${negativeRepresentation}${integer}${decimalWithFraction}`;
};

/** Function that adds zeros to the beginning of a number depending on a maxValue
 * Suppose the maxValue of Age(maxValue in the func) avaiable is 102 and the current age is 2(num in the func), this function would insert two zeros at the start of 2 making it 002
 *
 */
export const zeroFormatter = function zeroFormatter(num, maxValue) {
  const fieldData = num.toString();
  const diff = maxValue.toString().length - fieldData.length;
  const zeros = '0'.repeat(diff);
  return fieldData.length > 1 ? fieldData : zeros.concat(fieldData);
};

/**
 * Function to trim decimal values of a num, if no. of decimal digits is greater than a buffer
 * If buffer is 5 and the no. of decimal digits in the num is more than 5, say 7, then the function will trim the num to 5 decimal digits
 * If no. of decimal digits in num is less than or equal to buffer, than the num is returned as it is.
 * If the digits to trimUpto is not specified, it gets trimmed to the buffer
 */
export const truncDecimalIfGreaterThanBuffer = function truncDecimalIfGreaterThanBuffer(
  num,
  buffer,
  trimTo = buffer
) {
  const data = `${num}`.split('.');
  if (!data[1]) {
    return num;
  }
  return data[1].length > buffer ? truncDigits(num, trimTo) : num;
};

/*
  Function used to custom format the number based on locale like 1K, 1M, 1B, 1T.
*/
export const formatLocaleNumber = (
  conversionConfig,
  locale,
  num,
  formatMessage
) => {
  const localConfig = conversionConfig[locale];
  if (!localConfig) {
    return 0;
  }
  let formattedNum = num;
  for (let i = 0; i < localConfig.length; i += 1) {
    const { gte, notation } = localConfig[i];
    let absNum = Math.abs(num);
    if (absNum >= gte) {
      absNum /= gte;
      formattedNum = `${Number(
        truncDigits(absNum, 1)
      ).toString()}${formatMessage({ id: notation })}`;
      return formattedNum;
    }
  }
  return formattedNum;
};
