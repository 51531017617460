import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  OverviewInfoInsuredFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';
import { personInsuredGenerator } from '../../../policysummary/productTemplates/templateUtils';

const OverviewField = props => {
  const { labelContent, fieldData } = props;

  if (
    !labelContent ||
    !fieldData ||
    typeof fieldData !== 'object' ||
    !fieldData.length
  ) {
    return <></>;
  }
  const fieldDataObject = { value: fieldData };
  const nameField = personInsuredGenerator(fieldDataObject);

  const insuredPerson = !nameField ? (
    <></>
  ) : (
    <OverviewFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoInsuredFieldDataValue>
        {nameField.split(', ').map(name => {
          return (
            <DataTextBold key={name} name={name}>
              {name}
            </DataTextBold>
          );
        })}
      </OverviewInfoInsuredFieldDataValue>
    </OverviewFieldWrapper>
  );

  return insuredPerson;
};

OverviewField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.array.isRequired,
  toolTipContent: PropTypes.string
};

OverviewField.defaultProps = {
  toolTipContent: null
};

export default OverviewField;
