import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

const ChartLoader = props => {
  const { height } = props;
  return <Loader type="Oval" color="#000000" width={50} height={height} />;
};

ChartLoader.propTypes = {
  height: PropTypes.number.isRequired
};

export default ChartLoader;
