import styled from 'styled-components';
import { FontSize, FontFamily } from '../../../utils/font';

export const EECWrapper = styled.div`
  display: block;
  max-height: 100%;
`;

export const EECSubheader = styled.span`
  ${FontFamily.RegularFontW01};
  ${FontSize.size5};
`;

export const EECTableheader = styled.span`
  ${FontFamily.LightFontW01};
  ${FontSize.size5};
  margin-top: 28px;
  margin-bottom: 16px;
  display: block;
`;

export const EECCardText = styled.div`
  background-color: #f8f8f8;
  margin-top: 25px;
`;

export const EECCardHeader = styled.div`
  padding: 20px;
  margin-top: 25px;
  display: flex;
`;

export const EECCardHeaderText = styled.div`
  ${FontFamily.MediumFontW01};
  ${FontSize.size5};
`;

export const EECCardSubHeader = styled.div`
  padding: 0 20px 20px 20px;
  margin-left: 35px;
`;

export const EECCardSubHeaderParagraph = styled.div`
  ${FontFamily.RegularFontW01};
  ${FontSize.size6};
  line-height: 20px;
  margin-bottom: 10px;
`;

export const ImgStyle = styled.img`
  margin-right: 12px;
  margin-top: 2px;
  width: 21px;
  height: 24px;
`;
