import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import downloadIcon from '../../../assets/images/QuickLinks/download.svg';
import uploadIcon from '../../../assets/images/upload.svg';
import { IconRightImg } from '../../../policysummary/summaryPageStyles';
import { ShowIfDesktop } from '../../../utils/showIf';
import { trackViews } from '../../../utils/tealiumUtils';
import {
  ButtonAction,
  Dot,
  DotContainer,
  FirstStep,
  GapMaker,
  GapMakerLastItem,
  ItemRowItem,
  ModalMainContainer,
  ParagraphDiv,
  ParagraphDivQues,
  ParagraphItemDiv,
  ParagraphStep2Div,
  UploadButtonTitle,
  UploadIconContainer
} from './beneficiarySectionStyles';

const IrrevocableModalContent = (props, context) => {
  const { encodedProductId, changeBeneficiaryFormLink } = props;
  const {
    intl: { formatMessage }
  } = context;

  const urltoRedirect = `${window.location.origin}/documents-service/${encodedProductId}`;

  useEffect(() => {
    trackViews({
      viewName: 'irrevocableModalOnLoad'
    });
  }, []);

  return (
    <ModalMainContainer>
      <FirstStep>
        <ParagraphDivQues>
          <h1>
            {formatMessage({
              id: 'beneficiary_exception_question1'
            })}
          </h1>
        </ParagraphDivQues>

        <ParagraphDiv>
          {formatMessage({
            id: 'beneficiary_exception_irrevocable_answer1'
          })}
        </ParagraphDiv>
      </FirstStep>
      <ShowIfDesktop>
        <ParagraphDivQues>
          <h1>
            {formatMessage({
              id: 'beneficiary_exception_irrevocable_question2'
            })}
          </h1>
        </ParagraphDivQues>
      </ShowIfDesktop>
      <ParagraphDiv>
        {formatMessage({
          id: 'beneficiary_exception_irrevocable_step1'
        })}
      </ParagraphDiv>
      <ItemRowItem>
        <DotContainer>
          <Dot />
        </DotContainer>
        <ParagraphItemDiv>
          {formatMessage({
            id: 'beneficiary_exception_irrevocable_bullet1'
          })}
        </ParagraphItemDiv>
      </ItemRowItem>
      <ItemRowItem>
        <ButtonAction target="_blank" href={changeBeneficiaryFormLink}>
          <UploadIconContainer>
            <IconRightImg aria-hidden="true" alt="" src={downloadIcon} />
          </UploadIconContainer>
          <UploadButtonTitle>
            {formatMessage({
              id: 'beneficiary_exception_irrevocable_download_form'
            })}
          </UploadButtonTitle>
        </ButtonAction>
      </ItemRowItem>
      <GapMaker />
      <ItemRowItem>
        <DotContainer>
          <Dot />
        </DotContainer>

        <ParagraphItemDiv>
          {formatMessage({
            id: 'beneficiary_exception_irrevocable_bullet2'
          })}
        </ParagraphItemDiv>
      </ItemRowItem>
      <GapMaker />
      <ParagraphStep2Div>
        {formatMessage({
          id: 'beneficiary_exception_irrevocable_step2'
        })}
      </ParagraphStep2Div>
      <ItemRowItem>
        <DotContainer>
          <Dot />
        </DotContainer>
        <ParagraphItemDiv>
          {formatMessage({
            id: 'beneficiary_exception_irrevocable_bullet3'
          })}
        </ParagraphItemDiv>
      </ItemRowItem>
      <ItemRowItem>
        <DotContainer>
          <Dot />
        </DotContainer>
        <ParagraphItemDiv>
          {formatMessage({
            id: 'beneficiary_exception_irrevocable_bullet4'
          })}
        </ParagraphItemDiv>
      </ItemRowItem>
      <GapMakerLastItem />
      <ItemRowItem>
        <ButtonAction id="btnAction" href={urltoRedirect}>
          <UploadIconContainer>
            <IconRightImg aria-hidden="true" alt="" src={uploadIcon} />
          </UploadIconContainer>
          <UploadButtonTitle>
            {formatMessage({
              id: 'beneficiary_exception_irrevocable_upload_form'
            })}
          </UploadButtonTitle>
        </ButtonAction>
      </ItemRowItem>
    </ModalMainContainer>
  );
};

IrrevocableModalContent.propTypes = {
  encodedProductId: PropTypes.string.isRequired,
  changeBeneficiaryFormLink: PropTypes.string.isRequired
};

IrrevocableModalContent.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default IrrevocableModalContent;
