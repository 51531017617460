import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  DownloadLinkWrapper,
  DownloadLink,
  DownloadImgSpan,
  ErrorDescription,
  DocumentSection
} from './idcSyles';
import { ImagePath } from '../../../detailPageStyle';
import {
  getPolicyDocumentsDetails,
  resetDocumentsErrorsWarnings,
  updateLoaderOpacity,
  resetDocumentDetails
} from '../../../../app/appActions';
import pdfIcon from '../../../../assets/images/PDF.svg';
import { isIE, setLinkFocus } from '../../../../utils/domUtils';
import {
  getDocDetails,
  base64toBlob,
  orderObjectByDocumentType,
  getSurgicalChildDocuments
} from './utils/idcUtils';
import ModalPopUp from '../../../../common/modal/PopUpTemplate';
import { trackEvents, trackViews } from '../../../../utils/tealiumUtils';
import getDescription from '../../../../utils/reducerUtils';
import getContentInSpecificLang from '../../../../utils/localeUtils';

class DownloadDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
      isModalOpen: true
    };
    this.linkRef = React.createRef();
    this.selectedDocId = '';
    this.selectedDocElement = '';
    this.selectedDocName = '';
  }

  componentDidUpdate() {
    const { documentDetails, documentsWarningsOrErrors } = this.props;
    const { isDownload, isModalOpen } = this.state;
    if (_.isEmpty(documentsWarningsOrErrors) && !isModalOpen) {
      this.setModal();
    }

    if (_.has(documentDetails, this.selectedDocId) && isDownload) {
      const { content } = documentDetails[this.selectedDocId];
      this.openBase64NewTab(this.selectedDocName, content);
      this.downloadPDF(this.selectedDocName, content);
    }
  }

  setModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  openBase64NewTab = (fileName, pdfString) => {
    const blob = base64toBlob(pdfString);
    const url = URL.createObjectURL(blob);
    if (isIE) {
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    } else {
      window.nativeOpen(url, '_blank');
    }
  };

  downloadPDF = (fileName, pdfString) => {
    const { resetDocumentsData } = this.props;
    const linkSource = `data:application/pdf;base64,${pdfString}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    this.setState({
      isDownload: false
    });
    resetDocumentsData();
  };

  getBaseString = (
    documentSysID,
    element,
    apiCallCount,
    documentTitle,
    currentDocumentDetails,
    dependentFieldDataThree
  ) => {
    const { fetchEncodedUrl, updateLoaderOpacityHandler } = this.props;
    this.selectedDocId = documentSysID;
    this.selectedDocName = documentTitle;
    this.selectedDocElement = element;
    this.setState({
      isDownload: true
    });
    this.callTealiumEvent(currentDocumentDetails, dependentFieldDataThree);
    fetchEncodedUrl({ documentSysID, apiCallCount });
    updateLoaderOpacityHandler(true);
  };

  onCancelClick = (btnName = 'close') => {
    const { resetDocumentsError } = this.props;
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen
    }));
    resetDocumentsError();
    const elem = document.getElementById(this.selectedDocElement);
    setLinkFocus(elem, true);
    trackEvents({
      eventName: 'downloadDocumentPopupCTA',
      eventContent: btnName ? 'x' : 'close'
    });
  };

  getErrorCode = errors => {
    let errorCodeForTealium = '';
    _.each(errors, currentError => {
      const { errorCode } = currentError;
      errorCodeForTealium = errorCode;
    });
    return errorCodeForTealium;
  };

  getRiderCategory = riderField => {
    const riderCategory = {
      basic: 'basic plan',
      medical: 'medical account balance',
      surgicalRider: 'medical account balance',
      criticalIllness: 'critical illness',
      supplementaryRider: 'supplementary benefit',
      childRider: 'supplementary benefit'
    };
    return riderCategory[riderField];
  };

  isDocumentError = () => {
    const { documentsWarningsOrErrors = {} } = this.props;
    if (this.selectedDocId in documentsWarningsOrErrors) {
      const { errors = null } = documentsWarningsOrErrors[this.selectedDocId];
      trackViews({
        viewName: 'downloadDocumentError',
        errorCode: this.getErrorCode(errors)
      });
      return !!errors;
    }
    return false;
  };

  getRiderName = (dependentFieldDataThree, clubSurgicalOrChildInfo) => {
    const { langObj } = this.props;
    if (clubSurgicalOrChildInfo) {
      const riderLangSpecific = getContentInSpecificLang(
        dependentFieldDataThree,
        'en',
        langObj
      );
      return _.toLower(riderLangSpecific);
    }
    return _.toLower(getDescription('en', dependentFieldDataThree));
  };

  callTealiumEvent = (currentDocumentDetails, dependentFieldDataThree) => {
    const { dependentContent, clubSurgicalOrChildInfo, langObj } = this.props;
    const { subcategory, documentTypeCode } = currentDocumentDetails;
    const getRiderNameForTealium = `${this.getRiderCategory(
      dependentContent
    )}:${this.getRiderName(dependentFieldDataThree, clubSurgicalOrChildInfo)}`;
    const captureEventContent = `${
      subcategory === 'Basic Plan'
        ? _.toLower(subcategory)
        : getRiderNameForTealium
    }: ${_.toLower(
      getContentInSpecificLang(`document_${documentTypeCode}`, 'en', langObj)
    )}`;
    trackEvents({
      eventName: 'downloadDocument',
      eventContent: captureEventContent
    });
  };

  callTealiumView = docList => {
    const {
      currentPolicyDetail,
      documentOrder,
      selectedLocale,
      dependentContent
    } = this.props;
    let formFieldData = null;
    let docTypes = '';
    _.map(documentOrder, currentError => {
      const docDetailsonLoad = getDocDetails(
        selectedLocale,
        docList[`${currentError}`]
      );
      if (_.isEmpty(docDetailsonLoad)) {
        return null;
      }
      const { documentType } = docDetailsonLoad;
      docTypes = `${docTypes},${documentType}`;
      const sectionName = this.getRiderCategory(dependentContent);
      formFieldData = `${sectionName}:${_.keys(docList).length}:${_.trimStart(
        _.toLower(docTypes),
        ','
      )}`;

      return formFieldData;
    });

    if (!_.isEmpty(docList)) {
      trackViews({
        viewName: 'onDetailsPageLoad',
        pageTealiumDetails: currentPolicyDetail,
        documentDownLoadFormField: formFieldData
      });
    }
  };

  checkModalToOpen = () => this.isDocumentError() && this.selectedDocId;

  render() {
    const {
      labelContent,
      fieldData,
      dependentField,
      documentOrder,
      dependentContent,
      id,
      clubSurgicalOrChildInfo,
      fieldName,
      selectedLocale,
      dependentFieldDataThree,
      dependentFieldTwo
    } = this.props;
    let { dependentFieldDataTwo } = this.props;
    const {
      intl: { formatMessage }
    } = this.context;

    let apiResponse = fieldData;
    const { isModalOpen } = this.state;
    const footerButtonList = [
      {
        className: 'redBtn',
        label: formatMessage({ id: 'document_error_close' }),
        id: 'cancel_btn',
        align: 'left'
      }
    ];

    if (clubSurgicalOrChildInfo) {
      const surgicalChildArr = getSurgicalChildDocuments(
        apiResponse,
        fieldName
      );
      apiResponse = surgicalChildArr ? surgicalChildArr[fieldName] : [];
      dependentFieldDataTwo = _.get(surgicalChildArr, dependentFieldTwo);
    }
    if (
      (!labelContent && !apiResponse) ||
      (dependentFieldDataTwo && dependentFieldDataTwo !== 'IF')
    ) {
      return <></>;
    }
    const documentsList = orderObjectByDocumentType(
      apiResponse,
      dependentField,
      documentOrder
    );
    !_.isEmpty(documentsList) && this.callTealiumView(documentsList);
    if (_.isEmpty(documentsList)) {
      return null;
    }
    return (
      <DocumentSection>
        {_.map(documentOrder, (orderKey, idx) => {
          if (`${orderKey}` in documentsList) {
            const docDetails = getDocDetails(
              selectedLocale,
              documentsList[`${orderKey}`]
            );
            if (_.isEmpty(docDetails)) {
              return null;
            }
            const {
              language,
              documentSize,
              documentType,
              documentSysID,
              documentTypeCode,
              documentTitle
            } = docDetails;
            return (
              <>
                <DownloadLinkWrapper
                  key={`${labelContent}${documentType}`}
                  id={`${labelContent}${documentType}`}
                >
                  <DownloadImgSpan>
                    <ImagePath aria-hidden="true" alt="" src={pdfIcon} />
                  </DownloadImgSpan>
                  <DownloadLink
                    href="#"
                    id={`${id}_${dependentContent}_${documentSysID}_${idx}`}
                    onClick={e => {
                      this.getBaseString(
                        documentSysID,
                        `${id}_${dependentContent}_${documentSysID}_${idx}`,
                        1,
                        documentTitle,
                        docDetails,
                        dependentFieldDataThree
                      );
                      e.preventDefault();
                    }}
                    ref={this.linkRef}
                  >
                    <FormattedMessage
                      id={labelContent}
                      values={{
                        documentName: formatMessage({
                          id: `document_${documentTypeCode}`
                        }),
                        language: formatMessage({
                          id: `documentLang_${_.upperCase(language)}`
                        }),
                        documentSize
                      }}
                    />
                  </DownloadLink>
                </DownloadLinkWrapper>
                {this.isDocumentError() && this.selectedDocId && (
                  <ModalPopUp
                    isModalOpen={isModalOpen}
                    onClose={() => this.onCancelClick('x')}
                    modalHeading={formatMessage({
                      id: 'document_error_heading'
                    })}
                    footerButtonList={footerButtonList}
                    btnHandler={{
                      cancel_btn: this.onCancelClick
                    }}
                    maxWidth="32em"
                    hideHorizonatalLine
                  >
                    <ErrorDescription>
                      {formatMessage({
                        id: 'document_error_description'
                      })}
                    </ErrorDescription>
                  </ModalPopUp>
                )}
              </>
            );
          }
          return <></>;
        })}
      </DocumentSection>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEncodedUrl: (documentSysID, apiCallCount) =>
        getPolicyDocumentsDetails(documentSysID, apiCallCount),
      resetDocumentsError: resetDocumentsErrorsWarnings,
      resetDocumentsData: resetDocumentDetails,
      updateLoaderOpacityHandler: updateLoaderOpacity
    },
    dispatch
  );

const mapStateToProps = state => {
  const {
    policyDocuments: { documentDetails, documentsWarningsOrErrors },
    content: { selectedLocale, langObj }
  } = state;
  return {
    documentDetails,
    documentsWarningsOrErrors,
    selectedLocale,
    langObj
  };
};

DownloadDocument.contextTypes = {
  intl: PropTypes.object.isRequired
};

DownloadDocument.propTypes = {
  fieldData: PropTypes.array.isRequired,
  labelContent: PropTypes.string.isRequired,
  documentDetails: PropTypes.object,
  fetchEncodedUrl: PropTypes.func.isRequired,
  dependentField: PropTypes.string,
  documentOrder: PropTypes.array,
  resetDocumentsError: PropTypes.func.isRequired,
  updateLoaderOpacityHandler: PropTypes.func.isRequired,
  documentsWarningsOrErrors: PropTypes.object,
  dependentContent: PropTypes.string,
  id: PropTypes.string,
  clubSurgicalOrChildInfo: PropTypes.bool,
  fieldName: PropTypes.string,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  dependentFieldDataTwo: PropTypes.string,
  dependentFieldDataThree: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  resetDocumentsData: PropTypes.func.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  dependentFieldTwo: PropTypes.string.isRequired
};

DownloadDocument.defaultProps = {
  documentDetails: {},
  dependentField: '',
  documentOrder: [],
  documentsWarningsOrErrors: {},
  dependentContent: '',
  id: '',
  clubSurgicalOrChildInfo: false,
  fieldName: '',
  dependentFieldDataTwo: '',
  dependentFieldDataThree: []
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocument);
