import React from 'react';
import PropTypes from 'prop-types';
import _some from 'lodash/some';
import _map from 'lodash/map';
import _isArray from 'lodash/isArray';
import * as tabsections from './tabsections';
import reprojectionTealiumHandlers from './utils/reprojectionTealiumHandlers';

/**
 * iterate through each section template listed inside reprojectionTab list and
    generates sectionFieldConfig Array ( list of all subsections and their respective fields)
 * Example:
    sectionFieldConfig[0] : array of fields object for chart
    sectionFieldConfig[1] : array of table header object for table
    sectionFieldConfig[2] : array of fields object of table main subsection
 *
 */

const ReprojectionTabBuilder = function ReprojectionTabBuilder(props) {
  const {
    productCategory,
    reprojectionsDetails,
    sectionToRender,
    sectionFieldMapper,
    subSectionsMapper,
    fieldConfig,
    dependentFieldConfig,
    reprojectionGenericConfig,
    reprojectionChartConfig,
    setCustomerAge,
    customerAge,
    setCustomerMinPayAge,
    setCustomerMaxPayAge,
    customerMinPayAge,
    customerMaxPayAge,
    toggleIncludeNonGuaranteedPayout,
    tabValues,
    selectedFutureBenefitsTab,
    sectionNotificationConfig
  } = props;

  const {
    [sectionToRender]: {
      subSections,
      fields,
      template,
      headerContent,
      tableSectionAPIFieldParentKey,
      policyCurrencyFormat,
      policyPhaseFlag
    }
  } = sectionFieldMapper;
  let sectionFieldList = fields;
  if (subSections) {
    sectionFieldList = _map(
      subSections,
      subSectionInstance => subSectionsMapper[subSectionInstance].fields
    );
  }
  const policySectionTemplate = template;
  // checking null check fir template and section/subsection
  if (
    !policySectionTemplate ||
    (sectionFieldList && sectionFieldList.length === 0 && !subSections)
  ) {
    return <React.Fragment key={sectionToRender} />;
  }
  let fieldsStack;
  let sectionFieldConfig;
  const isNestedFields = _some(sectionFieldList, _isArray);

  if (isNestedFields) {
    sectionFieldConfig = _map(sectionFieldList, subSectionFieldList =>
      _map(subSectionFieldList, fieldName => fieldConfig[fieldName])
    );
  } else {
    sectionFieldConfig = _map(
      sectionFieldList,
      fieldName => fieldConfig[fieldName]
    );
  }

  const SpecificSection = tabsections[policySectionTemplate];
  return (
    <SpecificSection
      productCategory={productCategory}
      reprojectionsDetails={reprojectionsDetails}
      key={fieldsStack}
      sectionHeading={headerContent}
      sectionFieldConfig={sectionFieldConfig}
      dependentFieldConfig={dependentFieldConfig}
      tableSectionAPIFieldParentKey={tableSectionAPIFieldParentKey}
      policyCurrencyFormat={policyCurrencyFormat}
      policyPhaseFlag={policyPhaseFlag}
      reprojectionGenericConfig={reprojectionGenericConfig}
      sectionToRender={sectionToRender}
      reprojectionChartConfig={reprojectionChartConfig}
      setCustomerAge={setCustomerAge}
      customerAge={customerAge}
      customerMinPayAge={customerMinPayAge}
      customerMaxPayAge={customerMaxPayAge}
      setCustomerMinPayAge={setCustomerMinPayAge}
      setCustomerMaxPayAge={setCustomerMaxPayAge}
      toggleIncludeNonGuaranteedPayout={toggleIncludeNonGuaranteedPayout}
      tabValues={tabValues}
      selectedFutureBenefitsTab={selectedFutureBenefitsTab}
      reprojectionTealiumHandlers={reprojectionTealiumHandlers}
      sectionNotificationConfig={sectionNotificationConfig}
    />
  );
};

ReprojectionTabBuilder.propTypes = {
  productCategory: PropTypes.string.isRequired,
  sectionFieldMapper: PropTypes.object.isRequired,
  subSectionsMapper: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  sectionToRender: PropTypes.string.isRequired,
  dependentFieldConfig: PropTypes.object.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  reprojectionChartConfig: PropTypes.object.isRequired,
  setCustomerAge: PropTypes.func.isRequired,
  customerAge: PropTypes.number.isRequired,
  setCustomerMinPayAge: PropTypes.func.isRequired,
  setCustomerMaxPayAge: PropTypes.func.isRequired,
  customerMinPayAge: PropTypes.number.isRequired,
  customerMaxPayAge: PropTypes.number.isRequired,
  toggleIncludeNonGuaranteedPayout: PropTypes.func.isRequired,
  tabValues: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  policyPhaseFlag: PropTypes.bool.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired
};

ReprojectionTabBuilder.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReprojectionTabBuilder;
