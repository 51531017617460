import React from 'react';
import PropTypes from 'prop-types';
import {
  FundSectionWrapper,
  SectionHeaderText,
  SectionImgSpan,
  WhoIsCoveredHeader
} from './SectionStyle';
import { DetailsPageMiddleSection, ImagePath } from '../detailPageStyle';
import * as templates from '../templates';
import { getFieldDataFromConfig } from '../../utils/policyUtils';
import { ShowIfDesktop, ShowIfMobile } from '../../utils/showIf';
import TabelField from '../templates/TableField';
import FundsCardMobileView from '../templates/FundsCardMobileView';
import {
  sanitizingMemberList,
  checkMemberData,
  getInstanceComponentProps
} from '../sectionUtils/whoIsCoveredUtils';
import WhoIsCoveredIcon from '../../assets/images/multiuser.svg';

const WhoIsCovered = function WhoIsCovered(props, context) {
  const {
    sectionFieldConfig,
    sectionHeading,
    currentPolicyDetail,
    productCategory,
    productCategoryKey
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  if (!sectionFieldConfig.length) {
    return null;
  }

  const arrayData = []; // Being used for Table generation. Array of objects containing individual column UI rendering.

  sectionFieldConfig.forEach((sectionField, subSectionIndex) => {
    const FieldComponent = templates[sectionField.uiFormat];
    const fieldDataParent = getFieldDataFromConfig(
      currentPolicyDetail,
      sectionField,
      false
    );

    /**
     * Since, memberInformation is an array type, all the field iteration will be done from the following block.
     */
    if (fieldDataParent) {
      fieldDataParent.forEach((fieldDataInstance, apiArrayIndex) => {
        const fieldComponentProps = getInstanceComponentProps(
          sectionField,
          fieldDataInstance
        );
        const whoIsCoveredFieldComponent = (
          <FieldComponent
            key={sectionField.fieldLabel}
            id={`field_whoIsCovered_${subSectionIndex}`}
            decimalRange={sectionField.decimalRange}
            productCategory={productCategory}
            productCategoryKey={productCategoryKey}
            idx={subSectionIndex}
            sectionHeading={sectionHeading}
            isMandatoryToShow={sectionField.isMandatoryToShow}
            {...fieldComponentProps}
          />
        );
        if (!arrayData[apiArrayIndex]) {
          arrayData[apiArrayIndex] = [];
        }
        const labelContent = sectionField.fieldLabel;
        arrayData[apiArrayIndex][labelContent] = whoIsCoveredFieldComponent;
      });
    }
  });

  /**
   * Processing members list and filtering out members with no member names and additional information like relationship, membership number etc
   */

  const finalMemberArray = checkMemberData(sanitizingMemberList(arrayData));
  return (
    finalMemberArray.length > 0 && (
      <DetailsPageMiddleSection data-testid="whoIsCoveredSection">
        <FundSectionWrapper>
          <WhoIsCoveredHeader>
            <SectionImgSpan>
              <ImagePath aria-hidden="true" alt="" src={WhoIsCoveredIcon} />
            </SectionImgSpan>
            <SectionHeaderText id="whoIsCoveredSectionHeading">
              {formatMessage({ id: sectionHeading })}
            </SectionHeaderText>
          </WhoIsCoveredHeader>
          <>
            <ShowIfDesktop>
              <TabelField fieldData={finalMemberArray} type="whoIsCovered" />
            </ShowIfDesktop>
            <ShowIfMobile>
              <FundsCardMobileView
                fieldData={finalMemberArray}
                type="whoIsCovered"
              />
            </ShowIfMobile>
          </>
        </FundSectionWrapper>
      </DetailsPageMiddleSection>
    )
  );
};

WhoIsCovered.propTypes = {
  sectionFieldConfig: PropTypes.array,
  sectionHeading: PropTypes.string.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  productCategory: PropTypes.string.isRequired,
  productCategoryKey: PropTypes.string.isRequired
};

WhoIsCovered.defaultProps = {
  sectionFieldConfig: []
};
WhoIsCovered.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default WhoIsCovered;
