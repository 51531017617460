import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import getDescription from '../../../utils/reducerUtils';
import {
  FundCodeStyle,
  FundNameStyle,
  FundRatingDate
} from '../../detailPageStyle';
import DateComponent from '../../../common/date/Date';

function FundName(props, context) {
  const { fieldData, dependentField, dependentFieldTwo } = props;
  const {
    intl: { formatMessage, locale }
  } = context;
  if ((!fieldData || !fieldData.length) && !dependentField) {
    return <></>;
  }
  return (
    <>
      <ShowIf condition={!!fieldData || !!dependentField}>
        {!!fieldData && fieldData.length > 0 && (
          <FundNameStyle>{getDescription(locale, fieldData)}</FundNameStyle>
        )}
        {!!dependentField && (
          <FundCodeStyle>{`(${dependentField})`}</FundCodeStyle>
        )}
        {!!dependentFieldTwo && (
          <FundRatingDate>
            {formatMessage({ id: 'riskRatingDate' })}
            <DateComponent
              date={dependentFieldTwo}
              locale={locale}
              apiDateFormat="YYYY-MM-DD"
              id="standaradDateFormat"
            />
          </FundRatingDate>
        )}
      </ShowIf>
    </>
  );
}

FundName.propTypes = {
  fieldData: PropTypes.array.isRequired,
  dependentField: PropTypes.object.isRequired,
  dependentFieldTwo: PropTypes.object.isRequired
};

FundName.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundName;
