// @flow
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import MenuBar from './MenuBar';
import BurgerMenu from '../burgermenu/BurgerMenu';
import { invalidateSession } from '../../../utils/saasAuthModuleUtil';

const MastHead = props => {
  return (
    <>
      <MenuBar {...props} onLogOff={invalidateSession} />
      <BurgerMenu {...props} onLogOff={invalidateSession} />
    </>
  );
};

MastHead.propTypes = {
  languageConfig: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  mastHeaderLinks: PropTypes.array.isRequired,
  customerSegmentValues: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired
};

export default injectIntl(MastHead);
