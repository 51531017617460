import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import iconRight from '../../../assets/images/Icon_right.svg';
import redDot from '../../../assets/images/red-dot.svg';
/**
 *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
 * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
 */
// import Currency from '../../../common/currency/Currency';
import { ReprojectionLabel } from '../../../reprojection/reprojectionPageStyle';
import {
  getModifyUpdateJourneyUrl,
  getNonPersonalRelationshipStatus
} from '../../../utils/BeneficiaryUtils';
/**
 *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
 * [Manage Beneficiary - PIB]  Display Beneficiary % allocation and not Amount
 */
// import { LabelWrapper } from './BeneficiaryOverviewField';
import BeneficiarySectionModal from './BeneficiarySectionModal';
import {
  BeneficiaryPrimaryWrapper,
  ChevronDotContainer,
  /**
   *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
   * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
   */
  // CurrencyWrapper,
  IconRightImg,
  ManageBeneficiariesFooter,
  MissingBeneDot,
  BeneficiaryLinkWrapper,
  RightIconImgWrapper
  /**
   * This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
   * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
   */
  // OverviewFieldWrapper,
  // OverviewInfoFieldDataValue
} from './beneficiarySectionStyles';
import BeneficiarySectionTableContainer from './BeneficiarySectionTableContainer';

const BeneficiaryPrimarySection = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    nothingDataStore,
    primaryBeneficiariesList,
    rawPrimaryBeneficiariesList,
    /**
     *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
     * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
     */
    // currentPolicyDetail,
    isModalOpen,
    linkRefPrimary,
    updateModal,
    onCancelClick,
    irrevocableAssigned,
    assigned,
    encodedProductId,
    changeBeneficiaryService,
    canSuppressBeneficiaryLink
  } = props;
  const [isMissing, setIsMissing] = useState(false);
  const [isNonPersonalRelation, setPersonalRelation] = useState(false);

  /**
   *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
   * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
   */
  // const getValueData = () => {
  //   const returnVal = {
  //     currency: '',
  //     amount: 0
  //   };
  //   if (currentPolicyDetail?.policyAmountDetails?.totalPolicyValue) {
  //     const {
  //       currency,
  //       amount
  //     } = currentPolicyDetail?.policyAmountDetails?.totalPolicyValue;
  //     returnVal.currency = currency;
  //     returnVal.amount = amount;
  //     return returnVal;
  //   }
  //   return returnVal;
  // };

  const hetBtnTitle = formatMessage({
    id: 'manage_Beneficiaries_Primary_Beneficiaries'
  });
  const onClickUpdateAction = e => {
    e.preventDefault();
    const url = getModifyUpdateJourneyUrl(
      changeBeneficiaryService,
      encodedProductId
    );

    if (
      !irrevocableAssigned &&
      !assigned &&
      url !== '' &&
      !isNonPersonalRelation
    ) {
      window.localStorage.setItem('selectedBeneficiaryType', 'primary');
      window.location.href = url;
      return false;
    }

    updateModal(!isModalOpen);
    return true;
  };
  useEffect(() => {
    if (rawPrimaryBeneficiariesList.length > 0) {
      setIsMissing(_.some(rawPrimaryBeneficiariesList, 'missingInfo'));
      setPersonalRelation(
        getNonPersonalRelationshipStatus(rawPrimaryBeneficiariesList)
      );
    }
  }, [rawPrimaryBeneficiariesList]);

  return (
    <>
      <BeneficiarySectionTableContainer
        primaryBeneficiariesList={primaryBeneficiariesList}
        rawPrimaryBeneficiariesList={rawPrimaryBeneficiariesList}
        nothingDataStore={nothingDataStore}
        irrevocableAssigned={irrevocableAssigned}
        linkRefPrimary={linkRefPrimary}
        linkRefSecondary={null}
        type="primary"
      />

      {/**
       *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
       * [Manage Beneficiary - PIB]  Display Beneficiary % allocation and not Amount
       */
      /* <OverviewFieldWrapper
        id="monthlyGuaranteedAnnuity_label"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: 0
        }}
      >
        <LabelWrapper
          labelContent="manage_Beneficiaries_List_Projected_title"
          toolTipContent="beneficiaryTooltip"
          sectionHeading=""
        />
        <OverviewInfoFieldDataValue>
          <CurrencyWrapper>
            <Currency
              id="CurrencyLabel"
              currencyCode={getValueData().currency}
              amount={getValueData().amount}
            />
          </CurrencyWrapper>
        </OverviewInfoFieldDataValue>
      </OverviewFieldWrapper> */}

      <ManageBeneficiariesFooter>
        {!canSuppressBeneficiaryLink && (
          <BeneficiaryLinkWrapper>
            <Link
              to="/"
              id="update_primary"
              aria-label={`${hetBtnTitle}`}
              onClick={e => onClickUpdateAction(e)}
            >
              <BeneficiaryPrimaryWrapper>
                <ReprojectionLabel>{hetBtnTitle}</ReprojectionLabel>
              </BeneficiaryPrimaryWrapper>
              <RightIconImgWrapper>
                <ChevronDotContainer>
                  <IconRightImg aria-hidden="true" alt="" src={iconRight} />
                  {isMissing && (
                    <MissingBeneDot
                      aria-hidden="true"
                      alt={formatMessage({
                        id: 'missing_primary_beneficiary_alt_txt'
                      })}
                      src={redDot}
                    />
                  )}
                </ChevronDotContainer>
              </RightIconImgWrapper>
            </Link>
          </BeneficiaryLinkWrapper>
        )}
        <BeneficiarySectionModal
          isModalOpen={isModalOpen}
          onCancelClick={onCancelClick}
          irrevocableAssigned={irrevocableAssigned}
          assigned={assigned}
          encodedProductId={encodedProductId}
          isNonPersonalRelation={isNonPersonalRelation}
        />
      </ManageBeneficiariesFooter>
    </>
  );
};

BeneficiaryPrimarySection.propTypes = {
  nothingDataStore: PropTypes.bool.isRequired,
  primaryBeneficiariesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  rawPrimaryBeneficiariesList: PropTypes.arrayOf(PropTypes.any).isRequired,
  /**
   *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
   * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
   */
  // currentPolicyDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  irrevocableAssigned: PropTypes.bool.isRequired,
  assigned: PropTypes.bool.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  linkRefPrimary: PropTypes.objectOf(PropTypes.any).isRequired,
  updateModal: PropTypes.func.isRequired,
  changeBeneficiaryService: PropTypes.objectOf(PropTypes.any),
  canSuppressBeneficiaryLink: PropTypes.bool.isRequired
};

BeneficiaryPrimarySection.defaultProps = {
  changeBeneficiaryService: null
};

BeneficiaryPrimarySection.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    configurations: {
      genericConfig: {
        servicingOptions: {
          digiService: { ChangeBeneficiaryPIB }
        }
      }
    }
  } = state;
  return {
    changeBeneficiaryService: ChangeBeneficiaryPIB
  };
};

export default connect(mapStateToProps, null)(BeneficiaryPrimarySection);
