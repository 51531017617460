import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TableDesktop from '../common/table/TableDesktop';
import TableMobile from '../common/table/TableMobile';
import { isMiniMobile, isDesktop, isTab } from '../utils/domUtils';
import { currencyMapper } from '../utils/formatter';
import {
  ReprojectionHorizontalLine,
  ReprojectionTableWrapper
} from '../common/styles/reprojectionTableStyle';
import { trackEvents } from '../utils/tealiumUtils';

const ReprojectionTable = function ReprojectionTable(props) {
  const {
    reprojectionTableData: { columns, data },
    policyCurrency,
    reprojectionGenericConfig,
    selectedFutureBenefitsTab,
    tableHeaderText,
    selectedTabName,
    policyPhaseIndicator
  } = props;
  // Convert object to array as is simply required here
  const tableDataArray = _.compact(_.toArray(data));
  const tableRowsCount = reprojectionGenericConfig
    ? reprojectionGenericConfig.tableRowsCount
    : 0;

  const [width, setWidth] = useState(window.innerWidth);
  const [pageSize, setPageSize] = useState(tableRowsCount);

  const updateWidth = () => {
    setWidth(document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    if (isMiniMobile() || isTab()) {
      updateWidth();
    }
    return () => window.removeEventListener('resize', updateWidth);
  }, [width]);

  const totalSize = tableDataArray.length;
  /**
   * A function that handles the number of rows to be displayed in the table.
   */
  const displayRowHandler = showMoreLessTitle => {
    trackEvents({
      eventName: 'commonButtonEvents',
      eventContent: _.lowerCase(showMoreLessTitle)
    });
    if (pageSize >= tableDataArray.length) {
      setPageSize(tableRowsCount);
    } else {
      setPageSize(totalSize);
    }
  };
  const tableData = tableDataArray.slice(0, pageSize);
  const symbol = currencyMapper(policyCurrency);

  return (
    <>
      <ReprojectionHorizontalLine />
      <ReprojectionTableWrapper>
        {(isMiniMobile() || isTab()) && (
          <TableMobile
            columns={columns}
            data={tableData}
            pageSize={pageSize}
            displayRowHandler={displayRowHandler}
            totalSize={totalSize}
            maxRowCount={tableRowsCount}
            selectedTableName={selectedFutureBenefitsTab}
            policyPhaseIndicator={policyPhaseIndicator}
            tableHeaderText={tableHeaderText}
            selectedTabName={selectedTabName}
            policyCurrency={policyCurrency}
            symbol={symbol}
          />
        )}
        {isDesktop() && (
          <TableDesktop
            columns={columns}
            data={tableData}
            pageSize={pageSize}
            displayRowHandler={displayRowHandler}
            totalSize={totalSize}
            maxRowCount={tableRowsCount}
            selectedTableName={selectedFutureBenefitsTab}
            policyPhaseIndicator={policyPhaseIndicator}
            tableHeaderText={tableHeaderText}
            selectedTabName={selectedTabName}
            policyCurrency={policyCurrency}
            symbol={symbol}
          />
        )}
      </ReprojectionTableWrapper>
    </>
  );
};

ReprojectionTable.propTypes = {
  reprojectionTableData: PropTypes.object.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  reprojectionGenericConfig: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired,
  tableHeaderText: PropTypes.string.isRequired,
  selectedTabName: PropTypes.string.isRequired,
  policyPhaseIndicator: PropTypes.bool.isRequired
};

export default ReprojectionTable;
