import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FundSectionWrapper,
  FundSectionHeader,
  SectionHeaderText,
  SectionImgSpan,
  FundAllocation,
  SectionFooterLink,
  FooterLinkLabel,
  FooterLinkImageWrapper,
  IconRightImg,
  PortFolioWrapper
} from './SectionStyle';
import {
  DetailsPageMiddleSection,
  ImagePath,
  FundsBifurcationSection,
  TotalSubSectionStaticText,
  FundsTotalPremiumPaidSectionWrapper,
  FundsTotalPremiumPaidLeftSection,
  FundsTotalPremiumPaidRightSection,
  PortfolioHeader,
  PremAllocationHeader,
  PremAllocationDesc,
  GainLossDisclaimerText
} from '../detailPageStyle';
import * as templates from '../templates';
import { getFieldDataFromConfig } from '../../utils/policyUtils';
import { ShowIfDesktop, ShowIfMobile } from '../../utils/showIf';
import TabelField from '../templates/TableField';
import FundsCardMobileView from '../templates/FundsCardMobileView';

import FundsImage from '../../assets/images/funds.svg'; // update svg
import iconRight from '../../assets/images/Icon_right.svg';
import fundsSubscriptionInProgressIcon from '../../assets/images/fundsSubscriptionInProgress.svg';
import portFolioTransactionIcon from '../../assets/images/portFolioTransaction.svg';
import ContactLink from '../../common/contactLink/contactLink';
import { isServicngOptionApplicableToDemise } from '../sectionUtils/SectionsUtils';
import { hasArrayAllNulls } from './Benefits';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import {
  arrayStackGenerator,
  doughnutChartDataGenerator,
  checkTotalSubSectionFields,
  checkTotalPremiumPaidFields,
  getDocumentLinkForFundsPerformance,
  getFieldStackValue,
  getTableFieldComponentProps,
  getPortfolioTableData,
  getAllocationTableData,
  getLabelContent,
  isfieldsStackInValid,
  hasInvalidMandatoryComponents,
  isSectionFieldConfigInvalid,
  isArrayField,
  isFieldComponentDataInvalid,
  getRPQStatus,
  servicingLinkAvailable,
  checkForSurrenderPlanCodes,
  getSurrenderChargeTableData,
  entryPointCheckForInstructionHistory
} from '../sectionUtils/FundUtils';
import SectionFooterLinkComponent from './SectionFooterLinkComponent';

import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';
import FundRPQNotification from '../templates/Funds/FundRPQNotification';
import TransactionModal from '../templates/Funds/TransactionModal';

import InstructionInfoNotification from '../templates/InstructionInfoNotification';
import SurrenderChargeSubSection from './subSections/SurrenderChargeSubSection';
import { doAND } from '../../common/util/commonUtil';
import FundsPorfolioSection from '../templates/Funds/FundsPorfolioSection';
import PendingDealingNotification from '../templates/Funds/PendingDealingNotification';
import FundsAllocationWithSectionLinks from '../templates/Funds/FundsAllocationWithSectionLinks';

const canShowGainLossDisclaimer = function canShowGainLossDisclaimer(
  policyDetail
) {
  const { gainLossDetails = {} } = policyDetail;
  if (_.isEmpty(gainLossDetails)) {
    return false;
  }
  const { gainLossAmount = {} } = gainLossDetails;
  if (_.isEmpty(gainLossAmount)) {
    return false;
  }
  return true;
};

const getTotalRightSubSection = function getTotalRightSubSection(
  hasTotalSubSectionFields,
  totalSubSectionComponents,
  hasTotalPremiumPaidSectionFields,
  totalPremiumPaidComponents,
  formatMessage
) {
  let returnTotalRightSubSection;
  if (hasTotalSubSectionFields) {
    returnTotalRightSubSection = (
      <FundsBifurcationSection>
        {totalSubSectionComponents}
        <TotalSubSectionStaticText>
          {formatMessage({
            id: 'totalSubSectionStaticTextLabel'
          })}
        </TotalSubSectionStaticText>
      </FundsBifurcationSection>
    );
  } else if (hasTotalPremiumPaidSectionFields) {
    returnTotalRightSubSection = (
      <FundsTotalPremiumPaidSectionWrapper
        hasTotalSubSectionFields={hasTotalSubSectionFields}
      >
        <FundsTotalPremiumPaidLeftSection
          hasTotalSubSectionFields={hasTotalSubSectionFields}
        />
        <FundsTotalPremiumPaidRightSection
          hasTotalSubSectionFields={hasTotalSubSectionFields}
        >
          {totalPremiumPaidComponents}
        </FundsTotalPremiumPaidRightSection>
      </FundsTotalPremiumPaidSectionWrapper>
    );
  }
  return returnTotalRightSubSection;
};

const showPortfolioTableData = function showPortfolioTableData(
  portfolioTableData,
  dependentSectionFieldTwo,
  formatMessage
) {
  return (
    portfolioTableData.length > 0 && (
      <PortFolioWrapper>
        <PortfolioHeader id="portfolioHeader">
          {formatMessage({ id: 'portfolioHeader' })}
        </PortfolioHeader>
        <ShowIfDesktop>
          <TabelField fieldData={portfolioTableData} type="portfolio" />
        </ShowIfDesktop>
        <ShowIfMobile>
          <FundsCardMobileView
            fieldData={portfolioTableData}
            type="portfolio"
            dependentSectionFieldTwo={dependentSectionFieldTwo}
          />
        </ShowIfMobile>
      </PortFolioWrapper>
    )
  );
};

const getAllocationTableDataComponent = function getAllocationTableDataComponent(
  allocationTableData,
  totalFundBalanceComponents,
  dependentSectionField,
  dependentSectionFieldTwo,
  formatMessage
) {
  return (
    allocationTableData.length > 0 && (
      <FundAllocation id="premiumAllocation">
        <PremAllocationHeader id="premiumAllocationHeader">
          {formatMessage({ id: 'premiumAllocationHeader' })}
        </PremAllocationHeader>
        <PremAllocationDesc>
          {formatMessage({ id: 'premiumAllocationDesc' })}
        </PremAllocationDesc>
        <ShowIfDesktop>
          <TabelField
            fieldData={allocationTableData}
            dependentSectionField={dependentSectionField}
            type="allocation"
          />
        </ShowIfDesktop>
        <ShowIfMobile>
          <FundsCardMobileView
            fieldData={allocationTableData}
            dependentSectionField={dependentSectionField}
            borderColorIndex={10}
            type="allocation"
            dependentSectionFieldTwo={dependentSectionFieldTwo}
          />
        </ShowIfMobile>
      </FundAllocation>
    )
  );
};

const getFundPerformanceLink = function getFundPerformanceLink(
  fundsPerformance,
  footerButtonList,
  langObj
) {
  return (
    fundsPerformance && (
      <SectionFooterLinkComponent
        showComponent="fundsPerformance"
        footerButtonList={footerButtonList}
        proceedToLink={fundsPerformance}
        openInNewTab
        linkId="fundsPerformance"
        linkLabel="fundsPerformanceLink"
        langObj={langObj}
      />
    )
  );
};

const onCancelClick = (updateModalState, isModalOpen) => {
  updateModalState(!isModalOpen);
};

const updateChangeInPremiumAllocationModal = (
  updateP2GModalState,
  isP2GModalPopupOpen
) => {
  updateP2GModalState(!isP2GModalPopupOpen);
};

const getTransferFundsTypeObj = function getTransferFundsTypeObj(
  servicingOptionsURL
) {
  if (_.has(servicingOptionsURL, 'TransferOfFundsP2G')) {
    return servicingOptionsURL.TransferOfFundsP2G;
  }
  if (_.has(servicingOptionsURL, 'TransferOfFundsPIB')) {
    return servicingOptionsURL.TransferOfFundsPIB;
  }
  return null;
};

const getTransferFundsLabel = function getTransferFundsLabel(
  servicingOptionsURL
) {
  if (_.has(servicingOptionsURL, 'TransferOfFundsP2G')) {
    return 'TransferOfFundsP2G';
  }
  if (_.has(servicingOptionsURL, 'TransferOfFundsPIB')) {
    return 'TransferOfFundsPIB';
  }
  return '';
};

const callTealiumEventForServicing = function callTealiumEventForServicing(
  labelId,
  linkId,
  isRPQModalOpen
) {
  const linkBasedSectionName = {
    TransferOfFundsPIB: `total cash value: transfer of funds`,
    ChangeInPremium_Link: `change your upcoming premium allocation`,
    TransferOfFunds_Link: `switch investment choices`
  };
  const tealiumEventContent = isRPQModalOpen
    ? linkBasedSectionName[linkId]
    : linkBasedSectionName[labelId];

  trackEvents({
    eventName: 'digiServiceLinks',
    eventContent: tealiumEventContent,
    isRPQModalOpen
  });
};

const getServicingLink = function getServicingLink(
  linkUrl,
  linkId,
  labelId,
  fundSectionHeading,
  formatMessage,
  currentPolicyDetail,
  updateModalState,
  isModalOpen,
  customerWhiteListedForDigiServiceLinks,
  updateRPQModalState,
  isRPQModalOpen,
  setLinkVisited,
  updateP2GModalState,
  isP2GModalPopupOpen
) {
  const isServiceUnavailable = servicingLinkAvailable(
    linkId,
    currentPolicyDetail,
    customerWhiteListedForDigiServiceLinks
  );
  const pendingTransactions =
    isServiceUnavailable && isServiceUnavailable === 'pendingTransactions';
  const isDDNotConfirmed =
    isServiceUnavailable && isServiceUnavailable === 'ddNotconfirmed';
  const digiServicingOptions = [
    'TransferOfFundsP2G',
    'TransferOfFundsPIB',
    'rpqModalListLink'
  ];
  return (
    linkUrl && (
      <>
        <SectionFooterLink
          id={linkId}
          href={isServiceUnavailable ? '#' : linkUrl.url}
          onClick={e => {
            _.includes(digiServicingOptions, labelId)
              ? callTealiumEventForServicing(labelId, linkId, isRPQModalOpen)
              : trackEvents({
                  eventName: 'sectionLinks',
                  eventData: createEventActionContent(
                    'onsite',
                    labelId,
                    fundSectionHeading
                  )
                });
            if (isServicngOptionApplicableToDemise(true, labelId)) {
              e.preventDefault();
              updateChangeInPremiumAllocationModal(
                updateP2GModalState,
                isP2GModalPopupOpen
              );
              setLinkVisited(labelId);
            }
            if (updateRPQModalState) {
              updateRPQModalState();
            } else {
              isServiceUnavailable && updateModalState(!isModalOpen);
            }
          }}
          aria-label={formatMessage({
            id: labelId
          })}
          rel="noopener noreferrer"
          rightSpacing
        >
          <FooterLinkLabel>{formatMessage({ id: labelId })}</FooterLinkLabel>
          <FooterLinkImageWrapper>
            <IconRightImg src={iconRight} aria-hidden="true" alt="" />
          </FooterLinkImageWrapper>
        </SectionFooterLink>
        {isServiceUnavailable && (
          <TransactionModal
            isModalOpen={isModalOpen}
            onClose={() => onCancelClick(updateModalState, isModalOpen)}
            reasonServiceUnavailable={isServiceUnavailable}
            pendingTransactions={pendingTransactions}
            isDDNotConfirmed={isDDNotConfirmed}
          />
        )}
      </>
    )
  );
};

const checkPremiumPaidSectionFields = (
  hasTotalPremiumPaidSectionFields,
  hasTotalSubSectionFields
) => hasTotalPremiumPaidSectionFields && hasTotalSubSectionFields;

const renderSurrenderSubSection = (
  surrenderSectionTemplate,
  surrenderChargeTableData,
  planCode,
  langObj
) => {
  return surrenderSectionTemplate ? (
    <SurrenderChargeSubSection
      template={surrenderSectionTemplate}
      tableData={surrenderChargeTableData}
      currentPlan={planCode}
      langObj={langObj}
    />
  ) : null;
};

const renderInstructionInfoNotification = (
  matchedReferenceNumbers,
  encodePolicyNumber,
  servicingOptionsURL,
  isCustomerWhitelistedForVIH
) => {
  const preConditionForVIHNotification =
    isCustomerWhitelistedForVIH &&
    matchedReferenceNumbers &&
    matchedReferenceNumbers.length > 0;
  return preConditionForVIHNotification ? (
    <InstructionInfoNotification
      messageId="instruction_switch_investment"
      encodePolicyNumber={encodePolicyNumber}
      matchedReferenceNumbers={matchedReferenceNumbers}
      servicingOptionsURL={servicingOptionsURL}
    />
  ) : null;
};

const getPlanCode = currentPolicyDetail => {
  if (currentPolicyDetail) {
    return currentPolicyDetail.policyOverview.planCode;
  }
  return null;
};

const canShowGainLossComponent = (
  currentPolicyDetail,
  transferFundPendingIndicator
) => {
  if (!transferFundPendingIndicator) {
    return (
      canShowGainLossDisclaimer(currentPolicyDetail) && (
        <GainLossDisclaimerText>
          <ContactLink
            sentenceWithContact="gainLossDisclaimer"
            contactsList={['lifeContactUsNumber']}
            typeOfCall="general enquiry"
          />
        </GainLossDisclaimerText>
      )
    );
  }
  return null;
};
const Funds = function Funds(props, context) {
  const {
    sectionFieldConfig,
    sectionHeading,
    servicingOptionsURL,
    currentPolicyDetail,
    dependentSectionField,
    dependentSectionFieldTwo,
    dependentSectionFieldThree,
    productCategory,
    productCategoryKey,
    langObj,
    fundSelectionData,
    fundSelectionWarningsOrErrors,
    rpqUTBConfig,
    surrenderChargeConfig,
    indicativeGainLossConfig,
    genericConfig
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  const { ChangeInPremiumAllocation } = servicingOptionsURL;

  const TransferOfFunds = getTransferFundsTypeObj(servicingOptionsURL);
  const transferTypeLabel = getTransferFundsLabel(servicingOptionsURL);

  const [isModalOpen, updateModalState] = useState(false);
  const [isP2GModalPopupOpen, updateP2GModalState] = useState(false);
  const [currentLinkVisited, setLinkVisited] = useState('');

  if (isSectionFieldConfigInvalid(sectionFieldConfig)) {
    return null;
  }
  let arrayData = []; // Being used for Table generation. Array of objects containing individual column UI rendering.
  let chartArrayData = [];
  const planCode = getPlanCode(currentPolicyDetail);
  const fieldsStack = sectionFieldConfig.map(
    (subSectionFieldConfig, subSectionIndex) => {
      return subSectionFieldConfig.map((fieldConfigInstance, index) => {
        const FieldComponent = templates[fieldConfigInstance.uiFormat];
        const fieldDataParent = getFieldDataFromConfig(
          currentPolicyDetail,
          fieldConfigInstance,
          false
        );

        /**
         * Following block is for table and chart
         * */
        if (isArrayField(fieldDataParent)) {
          fieldDataParent.forEach((fieldDataInstance, apiArrayIndex) => {
            const fieldComponentProps = getTableFieldComponentProps(
              fieldDataInstance,
              fieldConfigInstance
            );
            const { fieldData: arrayFieldData } = fieldComponentProps;
            const fundTableFieldComponent = (
              <FieldComponent
                key={fieldConfigInstance.fieldLabel}
                id={`field_funds_${index}`}
                decimalRange={fieldConfigInstance.decimalRange}
                productCategory={productCategory}
                productCategoryKey={productCategoryKey}
                idx={index}
                isNegative={!!fieldConfigInstance.isNegative}
                sectionHeading={sectionHeading}
                indicativeGainLossConfig={indicativeGainLossConfig}
                planCode={planCode}
                {...fieldComponentProps}
              />
            );

            chartArrayData = arrayStackGenerator(
              chartArrayData,
              subSectionIndex,
              apiArrayIndex,
              fieldConfigInstance,
              arrayFieldData
            );

            arrayData = arrayStackGenerator(
              arrayData,
              subSectionIndex,
              apiArrayIndex,
              fieldConfigInstance,
              fundTableFieldComponent
            );
          });
          return <React.Fragment key={fieldConfigInstance.fieldLabel} />;
        }
        const fieldData = getFieldDataFromConfig(
          currentPolicyDetail,
          fieldConfigInstance,
          true
        );
        if (isFieldComponentDataInvalid(FieldComponent, fieldData)) {
          return null;
        }
        let dependentFieldData;
        if (fieldConfigInstance.dependentField) {
          dependentFieldData = getFieldDataFromConfig(
            currentPolicyDetail,
            fieldConfigInstance,
            true,
            'dependentField'
          );
        }

        const labelContent = getLabelContent(
          fieldConfigInstance,
          productCategory
        );
        const {
          fieldLabel,
          toolTip,
          dependentContent = '',
          isNegative
        } = fieldConfigInstance;
        return (
          <FieldComponent
            key={fieldLabel}
            id={`field_funds_${index}`}
            productCategory={productCategory}
            productCategoryKey={productCategoryKey}
            labelContent={labelContent}
            fieldData={fieldData}
            toolTipContent={toolTip}
            idx={index}
            fontChange={typeof value === 'object'}
            dependentContentData={dependentContent}
            dependentFieldData={dependentFieldData}
            isNegative={!!isNegative}
            sectionHeading={sectionHeading}
            indicativeGainLossConfig={indicativeGainLossConfig}
            planCode={planCode}
          />
        );
      });
    }
  );
  if (isfieldsStackInValid(fieldsStack)) {
    return null;
  }
  /**
   * arrayData - Contains chart and table related array data
   *
   * Index - Data present in that index
   *  1 - Chart data (Fund Name and Fund Balance)
   *  5 - Portfolio Table data (Fund Name, Fund Code, Risk Rating, Risk Rating updated date, Fund Price/Unit, Fund Units, Fund Amount balance )
   *  6 - Allocation Table data (Fund Name, Fund Code, Risk Rating, Fund Allocation Percentage)
   */

  /**
   * fieldsStack - Contains straight forward fields which are not of Array types in API response
   *
   * Index - Data present in that index
   * 0 - (Valuation Date, Total Fund Balance) for extracting CURRENCY for the top section
   * 2 - Total Fund Balance to be displayed beside the holdings chart
   * 3 - Data present for the total section (Total Fund Balance, Policy Loan Interest, Total Sum)
   * 4 - Data present for the total premium paid
   *
   */

  /**
   *
   * portfolioTableData is used to populate the portfolio table data
   *
   */

  const portfolioTableData = getPortfolioTableData(
    arrayData,
    dependentSectionFieldTwo,
    dependentSectionFieldThree
  );
  /**
   *
   * allocationTableData is used to populate allocation table
   *
   */
  const allocationTableData = getAllocationTableData(
    arrayData,
    dependentSectionField,
    dependentSectionFieldTwo
  );

  const overviewSectionComponents = getFieldStackValue(fieldsStack[0]);
  const totalFundBalanceComponents = getFieldStackValue(fieldsStack[2]);
  const totalSubSectionComponents = getFieldStackValue(fieldsStack[3]);
  const totalPremiumPaidComponents = getFieldStackValue(fieldsStack[4]);
  const fundSectionHeading = 'fund details';

  const hasTotalSubSectionFields = checkTotalSubSectionFields(
    totalSubSectionComponents
  );

  const hasTotalPremiumPaidSectionFields = checkTotalPremiumPaidFields(
    totalPremiumPaidComponents
  );

  const doughnutChartData = doughnutChartDataGenerator(
    chartArrayData[1],
    portfolioTableData
  );

  if (
    hasInvalidMandatoryComponents(
      arrayData,
      overviewSectionComponents,
      totalFundBalanceComponents,
      totalSubSectionComponents,
      totalPremiumPaidComponents,
      hasArrayAllNulls
    )
  ) {
    return null;
  }
  const returnTotalRightSubSection = getTotalRightSubSection(
    hasTotalSubSectionFields,
    totalSubSectionComponents,
    hasTotalPremiumPaidSectionFields,
    totalPremiumPaidComponents,
    formatMessage
  );

  const footerButtonList = [
    {
      className: 'whiteBtn',
      label: formatMessage({ id: 'cancelPopup' }),
      id: 'cancel_btn',
      align: 'left'
    },
    {
      className: 'redBtn',
      label: formatMessage({ id: 'continuePopup' }),
      id: 'continue_btn',
      align: 'right'
    }
  ];
  const fundsPerformance = getDocumentLinkForFundsPerformance(
    currentPolicyDetail,
    locale
  );

  const rpqNotification = getRPQStatus(currentPolicyDetail);
  const matchedReferenceNumbers = entryPointCheckForInstructionHistory(
    currentPolicyDetail
  );
  const encodePolicyNumber = _.get(
    currentPolicyDetail,
    'policyOverview.encodePolicyNumber'
  );
  /**
   * Above FundTopSectionWrapper, adding a check for total fund balance amount in the policy. If there is no such field present
   * in the APIs response, then we are suppressing all of sub sections in Funds except Allocation Table.
   */
  /** logic to check for the applicable plan codes to show surrender charge section */
  const surrenderSectionTemplate = checkForSurrenderPlanCodes(
    surrenderChargeConfig,
    currentPolicyDetail
  );

  /** logic to fetch the table data on the basis of surrender charge applicable plan codes */
  const surrenderChargeTableData = getSurrenderChargeTableData(
    surrenderChargeConfig,
    currentPolicyDetail
  );

  const { customerWhiteListedForDigiServiceLinks } = props;
  const {
    policyOverview: {
      newlyIssuedPendingIndicator = false,
      transferFundPendingIndicator = false
    }
  } = currentPolicyDetail;

  let modalPopuplinkURL = '';
  if (!_.isUndefined(genericConfig)) {
    const { p2gDemiseList } = genericConfig;
    const linkBasedURL = {
      TransferOfFundsP2G: p2gDemiseList.TransferOfFundsP2G[locale],
      ChangeInPremiumAllocation:
        p2gDemiseList.ChangeInPremiumAllocation[locale],
      DividendOrCashBonusWithdrawal:
        p2gDemiseList.DividendOrCashBonusWithdrawal[locale]
    };
    modalPopuplinkURL = linkBasedURL[currentLinkVisited];
  }
  return (
    <>
      <DetailsPageMiddleSection>
        <FundSectionWrapper>
          <FundSectionHeader>
            <SectionImgSpan>
              <ImagePath aria-hidden="true" alt="" src={FundsImage} />
            </SectionImgSpan>
            <SectionHeaderText id="fundsSectionHeading">
              {formatMessage({ id: sectionHeading })}
            </SectionHeaderText>
          </FundSectionHeader>
          <>
            {newlyIssuedPendingIndicator ? (
              <PendingDealingNotification
                pendingDealingFieldData={newlyIssuedPendingIndicator}
                contentKey="fundSubscriptionOrTransactionInProgress"
                pendingDealinIcon={fundsSubscriptionInProgressIcon}
              />
            ) : (
              <>
                {doAND(
                  totalFundBalanceComponents,
                  !hasArrayAllNulls(totalFundBalanceComponents),
                  !newlyIssuedPendingIndicator
                ) && (
                  <>
                    {renderInstructionInfoNotification(
                      matchedReferenceNumbers,
                      encodePolicyNumber,
                      servicingOptionsURL,
                      customerWhiteListedForDigiServiceLinks.InstructionHistory
                    )}
                    <FundRPQNotification
                      displayFor="ZeroRPQ"
                      getServicingLink={getServicingLink}
                      rpqNotification={rpqNotification}
                      TransferOfFunds={TransferOfFunds}
                      ChangeInPremiumAllocation={ChangeInPremiumAllocation}
                      fundSectionHeading={fundSectionHeading}
                      currentPolicyDetail={currentPolicyDetail}
                      updateModalState={updateModalState}
                      isModalOpen={isModalOpen}
                      fundSelectionData={fundSelectionData}
                      transferTypeLabel={transferTypeLabel}
                      isCustomerWhiteListedForTFF={
                        customerWhiteListedForDigiServiceLinks.TransferOfFundsPIB
                      }
                      matchedReferenceNumbers={matchedReferenceNumbers}
                      transferFundPendingIndicator={
                        transferFundPendingIndicator
                      }
                    />
                    <FundRPQNotification
                      displayFor="ChangeRPQ"
                      getServicingLink={getServicingLink}
                      rpqNotification={rpqNotification}
                      TransferOfFunds={TransferOfFunds}
                      ChangeInPremiumAllocation={ChangeInPremiumAllocation}
                      fundSectionHeading={fundSectionHeading}
                      currentPolicyDetail={currentPolicyDetail}
                      updateModalState={updateModalState}
                      isModalOpen={isModalOpen}
                      fundSelectionData={fundSelectionData}
                      fundSelectionWarningsOrErrors={
                        fundSelectionWarningsOrErrors
                      }
                      rpqUTBConfig={rpqUTBConfig}
                      transferTypeLabel={transferTypeLabel}
                      isCustomerWhiteListedForTFF={
                        customerWhiteListedForDigiServiceLinks.TransferOfFundsPIB
                      }
                      matchedReferenceNumbers={matchedReferenceNumbers}
                      transferFundPendingIndicator={
                        transferFundPendingIndicator
                      }
                    />
                    {canShowGainLossComponent(
                      currentPolicyDetail,
                      transferFundPendingIndicator
                    )}
                    <FundsPorfolioSection
                      overviewSectionComponents={overviewSectionComponents}
                      doughnutChartData={doughnutChartData}
                      totalFundBalanceComponents={totalFundBalanceComponents}
                      returnTotalRightSubSection={returnTotalRightSubSection}
                      checkPremiumPaidSectionFields={checkPremiumPaidSectionFields(
                        hasTotalPremiumPaidSectionFields,
                        hasTotalSubSectionFields
                      )}
                      totalPremiumPaidComponents={totalPremiumPaidComponents}
                      showPortfolioTableData={showPortfolioTableData(
                        portfolioTableData,
                        dependentSectionFieldTwo,
                        formatMessage
                      )}
                      transferFundPendingIndicator={
                        transferFundPendingIndicator
                      }
                      rpqNotification={rpqNotification}
                      hasTotalSubSectionFields={hasTotalSubSectionFields}
                      matchedReferenceNumbers={matchedReferenceNumbers}
                      indicativeGainLossConfig={indicativeGainLossConfig}
                    />
                  </>
                )}
                <PendingDealingNotification
                  pendingDealingFieldData={
                    transferFundPendingIndicator && !newlyIssuedPendingIndicator
                  }
                  contentKey="fundSubscriptionOrTransactionInProgress"
                  pendingDealinIcon={portFolioTransactionIcon}
                />
                <FundsAllocationWithSectionLinks
                  getAllocationTableDataComponent={getAllocationTableDataComponent(
                    allocationTableData,
                    totalFundBalanceComponents,
                    dependentSectionField,
                    dependentSectionFieldTwo,
                    formatMessage
                  )}
                  topPadding={allocationTableData.length === 0}
                  getFundPerformanceLink={getFundPerformanceLink(
                    fundsPerformance,
                    footerButtonList,
                    langObj
                  )}
                  renderSurrenderSubSection={renderSurrenderSubSection(
                    surrenderSectionTemplate,
                    surrenderChargeTableData,
                    planCode,
                    langObj
                  )}
                  getServicingTFFLink={getServicingLink(
                    TransferOfFunds,
                    'TransferOfFunds_Link',
                    transferTypeLabel,
                    fundSectionHeading,
                    formatMessage,
                    currentPolicyDetail,
                    updateModalState,
                    isModalOpen,
                    customerWhiteListedForDigiServiceLinks,
                    null,
                    false,
                    setLinkVisited,
                    updateP2GModalState,
                    isP2GModalPopupOpen
                  )}
                  getServicingWithdrawalLink={getServicingLink(
                    '#',
                    'Withdrawal_Link',
                    'DividendOrCashBonusWithdrawal',
                    fundSectionHeading,
                    formatMessage,
                    currentPolicyDetail,
                    updateModalState,
                    isModalOpen,
                    null,
                    null,
                    false,
                    setLinkVisited,
                    updateP2GModalState,
                    isP2GModalPopupOpen
                  )}
                  getServicingCPALink={getServicingLink(
                    '#',
                    'ChangeInPremium_Link',
                    'ChangeInPremiumAllocation',
                    fundSectionHeading,
                    formatMessage,
                    currentPolicyDetail,
                    updateModalState,
                    isModalOpen,
                    null,
                    null,
                    false,
                    setLinkVisited,
                    updateP2GModalState,
                    isP2GModalPopupOpen
                  )}
                  pendingDealingFieldData={transferFundPendingIndicator}
                />
              </>
            )}
          </>
          {isServicngOptionApplicableToDemise(true, currentLinkVisited) && (
            <ModalPopUp
              isModalOpen={isP2GModalPopupOpen}
              onClose={() =>
                updateChangeInPremiumAllocationModal(
                  updateP2GModalState,
                  isP2GModalPopupOpen
                )
              }
              modalHeading=""
              footerButtonList={footerButtonList}
              btnHandler={{
                // eslint-disable-next-line
                continue_btn: () => {},
                cancel_btn: () =>
                  updateChangeInPremiumAllocationModal(
                    updateP2GModalState,
                    isP2GModalPopupOpen
                  )
              }}
              proceedToLink={modalPopuplinkURL}
            >
              <ParagraphDiv>
                {formatMessage({ id: 'makeaClaimPopUpText' })}
              </ParagraphDiv>
            </ModalPopUp>
          )}
        </FundSectionWrapper>
      </DetailsPageMiddleSection>
    </>
  );
};

Funds.propTypes = {
  sectionFieldConfig: PropTypes.array.isRequired,
  servicingOptionsURL: PropTypes.object.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  productCategory: PropTypes.string.isRequired,
  productCategoryKey: PropTypes.string.isRequired,
  dependentSectionField: PropTypes.string,
  dependentSectionFieldTwo: PropTypes.string,
  dependentSectionFieldThree: PropTypes.object,
  langObj: PropTypes.object.isRequired,
  surrenderChargeConfig: PropTypes.object,
  fundSelectionData: PropTypes.object.isRequired,
  fundSelectionWarningsOrErrors: PropTypes.object.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired,
  customerWhiteListedForDigiServiceLinks: PropTypes.object.isRequired,
  indicativeGainLossConfig: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired
};

Funds.defaultProps = {
  dependentSectionField: '',
  dependentSectionFieldTwo: '',
  dependentSectionFieldThree: {},
  surrenderChargeConfig: {}
};
Funds.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default Funds;
