import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { TagsWrapper } from '../../reprojectionPageStyle';
import { TagValue } from '../../../common/styles/commonStyle';

const PolicyTags = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    fieldData,
    dependentFieldData,
    secondDependentFieldData,
    policyTagsMedicalAndCIConfig: {
      policyTagsMedicalAndCI: { planCodesUnderCI, planCodesUnderMedical }
    }
  } = props;
  if (!fieldData || !dependentFieldData) {
    return <></>;
  }
  let tagForMedical = false;
  let tagForCriticalIllness = false;
  let dynamicProductCategoryTag = '';
  const getPolicyTagsForMedicalAndCI = () => {
    tagForMedical = _.includes(planCodesUnderMedical, secondDependentFieldData);
    tagForCriticalIllness = _.includes(
      planCodesUnderCI,
      secondDependentFieldData
    );
    if (tagForMedical) {
      dynamicProductCategoryTag = 'tag_overview_MedicalandCriticalIllnessEVHIS';
    }
    if (tagForCriticalIllness) {
      dynamicProductCategoryTag = 'tag_overview_MedicalandCriticalIllnessCI';
    }
    return dynamicProductCategoryTag;
  };

  return (
    <TagsWrapper id="policyTags">
      <TagValue styleUpdate id="policyTags_Currency">
        {formatMessage({ id: `${fieldData}_CURRENCY` })}
      </TagValue>
      <TagValue id="policyTags_Category">
        {dependentFieldData === 'Medical & Critical Illness'
          ? formatMessage({ id: getPolicyTagsForMedicalAndCI() })
          : formatMessage({
              id: `tag_productCategory_${_.camelCase(dependentFieldData)}`
            })}
      </TagValue>
    </TagsWrapper>
  );
};
PolicyTags.propTypes = {
  dependentFieldData: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  secondDependentFieldData: PropTypes.string.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired
};

PolicyTags.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyTags;
