import * as _ from 'lodash';
import getDocumentLink from './SectionsUtils';

import { truncDigits } from '../../utils/formatter';

/**
 *
 * arrayStackGenerator is used to form array stacks which will be used for all the subsections of Funds Section
 *
 */

export function arrayStackGenerator(
  desiredArray,
  subsectionIndex,
  apiArrayIndex,
  fieldConfigInstance,
  arrayFieldData
) {
  const outcomeStack = [...desiredArray];
  outcomeStack[subsectionIndex] = outcomeStack[subsectionIndex]
    ? outcomeStack[subsectionIndex]
    : [];
  outcomeStack[subsectionIndex][apiArrayIndex] = outcomeStack[subsectionIndex][
    apiArrayIndex
  ]
    ? outcomeStack[subsectionIndex][apiArrayIndex]
    : {};
  outcomeStack[subsectionIndex][apiArrayIndex][
    fieldConfigInstance.fieldLabel
  ] = arrayFieldData;

  return outcomeStack;
}

export const getDocumentLinkForFundsPerformance = (
  currentPolicyDetail,
  locale
) => {
  if (currentPolicyDetail) {
    const { documentResponse } = currentPolicyDetail;
    return getDocumentLink('FundPerformance', documentResponse, locale);
  }

  return '';
};

/**
 *
 * checkFundNameCode is used to check if the fund contains fund name information in the APIs response
 *
 */

export function checkFundNameCode(dependantSectionData) {
  let hasFundNameCode = false;
  const {
    props: { fieldData, dependentField }
  } = dependantSectionData;
  if ((fieldData && fieldData.length) || dependentField) {
    hasFundNameCode = true;
  }

  return hasFundNameCode;
}

/**
 *
 * checkFundAmount is used to check if the fund contains fund amount balance information in the APIs response
 *
 */

export function checkFundAmount(fundAmountBalance, decimals = 2) {
  const {
    props: { fieldData, isNegative }
  } = fundAmountBalance;
  if (isNegative) {
    return fieldData && fieldData.amount !== 0 && fieldData.amount < 0;
  }
  return fieldData && truncDigits(fieldData.amount, decimals) > 0;
}

/**
 *
 * checkPremiumPaidAmount is used to check if the fund contains total premium paid information in the APIs response
 *
 */

export function checkPremiumPaidAmount(fundAmountBalance, decimals = 2) {
  const {
    props: { fieldData }
  } = fundAmountBalance;
  return fieldData && truncDigits(fieldData.amount, decimals) >= 0;
}

/**
 *
 * checkFundCurrency is used to check if the fund contains fund currency balance information in the APIs response
 *
 */

export function checkFundCurrency(fundCurrency) {
  return fundCurrency.props.fieldData && fundCurrency.props.fieldData.currency;
}

/**
 *
 * checkTotalSubSectionFields is used to check if the fields in Funds Total Sub Section contains
 * toal fund balance, policy loan and interest and total information in the APIs response
 *
 */

export function checkTotalSubSectionFields(totalSubSectionComponents) {
  return totalSubSectionComponents.every(function totalSubSection(
    totalSubSectionInstance
  ) {
    return (
      totalSubSectionInstance &&
      checkFundAmount(totalSubSectionInstance) &&
      checkFundCurrency(totalSubSectionInstance)
    );
  });
}

/**
 *
 * checkTotalPremiumPaidFields is used to check if the fields in Funds Total Premium Paid Section contains
 * total premium paid information in the APIs response
 *
 */

export function checkTotalPremiumPaidFields(totalPremiumPaidComponents) {
  return totalPremiumPaidComponents.every(function totalPremiumPaid(
    totalPremiumPaidInstance
  ) {
    return (
      totalPremiumPaidInstance &&
      checkPremiumPaidAmount(totalPremiumPaidInstance) &&
      checkFundCurrency(totalPremiumPaidInstance)
    );
  });
}

/**
 *
 * doughnutChartDataGenerator is used to populate the holdings chart
 *
 */

export function doughnutChartDataGenerator(
  chartArrayData = [],
  portfolioTableData = []
) {
  let refinedChartData = [];
  if (chartArrayData.length > 0 && portfolioTableData.length > 0) {
    const amountReferenceKey = Object.keys(chartArrayData[0])[0];
    refinedChartData = _.map(portfolioTableData, fund => {
      return fund[amountReferenceKey].props.fieldData;
    });
  }
  return refinedChartData;
}

export const getFieldStackValue = fieldStackArray => fieldStackArray || [];

export const getTableFieldComponentProps = function getTableFieldComponentProps(
  fieldDataInstance,
  fieldConfigInstance
) {
  const {
    fieldName,
    dependentField,
    dependentFieldTwo,
    fieldType
  } = fieldConfigInstance;
  const arrayFieldData = fieldDataInstance[fieldName];

  let dependentFieldData;
  let dependentFieldDataTwo;
  let tableFieldType;
  if (dependentField) {
    dependentFieldData = fieldDataInstance[dependentField];
  }
  if (dependentFieldTwo) {
    dependentFieldDataTwo = fieldDataInstance[dependentFieldTwo];
  }
  if (fieldType) {
    tableFieldType = fieldType;
  }
  return {
    fieldData: arrayFieldData,
    dependentField: dependentFieldData,
    dependentFieldTwo: dependentFieldDataTwo,
    fieldType: tableFieldType
  };
};

export const getPortfolioTableData = function getPortfolioTableData(
  arrayData,
  dependentSectionFieldTwo,
  dependentSectionFieldThree
) {
  const portfolioTableData = [];
  if (arrayData[5]) {
    arrayData[5].forEach(fieldDataInstance => {
      const dependantSectionData =
        fieldDataInstance && fieldDataInstance[dependentSectionFieldTwo];
      const fundAmountBalance =
        fieldDataInstance && fieldDataInstance[dependentSectionFieldThree];
      if (
        dependantSectionData &&
        fundAmountBalance &&
        checkFundNameCode(dependantSectionData) &&
        checkFundAmount(fundAmountBalance)
      ) {
        portfolioTableData.push(fieldDataInstance);
      }
    });
  }
  return portfolioTableData;
};

export const getAllocationTableData = function getAllocationTableData(
  arrayData,
  dependentSectionField,
  dependentSectionFieldTwo
) {
  const allocationTableData = [];
  if (arrayData[6]) {
    arrayData[6].forEach(fieldDataInstance => {
      if (
        fieldDataInstance[dependentSectionField] &&
        fieldDataInstance[dependentSectionField].props.fieldData
      ) {
        const dependantSectionData =
          fieldDataInstance && fieldDataInstance[dependentSectionFieldTwo];
        if (dependantSectionData && checkFundNameCode(dependantSectionData)) {
          allocationTableData.push(fieldDataInstance);
        }
      }
    });
  }
  return allocationTableData;
};

export const getLabelContent = function getLabelContent(
  fieldConfigInstance,
  productCategory
) {
  let labelContent;
  const fieldLabelSecondayKey = `fieldLabel_${productCategory}`;
  if (fieldConfigInstance[fieldLabelSecondayKey]) {
    labelContent = fieldConfigInstance[fieldLabelSecondayKey];
  } else {
    labelContent = fieldConfigInstance.fieldLabel;
  }
  return labelContent;
};

export const isfieldsStackInValid = function isfieldsStackInValid(fieldsStack) {
  return !fieldsStack || (fieldsStack.length && fieldsStack.length < 3);
};

export const hasInvalidMandatoryComponents = function hasInvalidMandatoryComponents(
  arrayData,
  overviewSectionComponents,
  totalFundBalanceComponents,
  totalSubSectionComponents,
  totalPremiumPaidComponents,
  hasArrayAllNulls
) {
  return (
    !arrayData[4] &&
    !arrayData[5] &&
    hasArrayAllNulls(overviewSectionComponents) &&
    hasArrayAllNulls(totalFundBalanceComponents) &&
    hasArrayAllNulls(totalSubSectionComponents) &&
    hasArrayAllNulls(totalPremiumPaidComponents)
  );
};

export const isSectionFieldConfigInvalid = function isSectionFieldConfigInvalid(
  sectionFieldConfig
) {
  return !sectionFieldConfig || sectionFieldConfig.length < 1;
};

export const isArrayField = function isArrayField(fieldDataParent) {
  return fieldDataParent && fieldDataParent.length;
};

export const isFieldComponentDataInvalid = function isFieldComponentDataInvalid(
  FieldComponent,
  fieldData
) {
  return !FieldComponent || !fieldData;
};

export const checkOptOutIndicator = optOutIndicator =>
  optOutIndicator === false;

export const checkRiskLevel = (coreFundRiskLevel, customerRpqLevel) => {
  if (customerRpqLevel === 0) {
    return 'ZeroRPQ';
  }
  return coreFundRiskLevel === customerRpqLevel ? 'UnchangeRPQ' : 'ChangeRPQ';
};

export const getRPQStatus = currentPolicyDetail => {
  const {
    policyOverview: { optOutIndicator },
    funds,
    rpqDetails = null
  } = currentPolicyDetail;
  if (funds && checkOptOutIndicator(optOutIndicator) && rpqDetails) {
    const { rpqResponse } = rpqDetails;
    if (rpqResponse) {
      const coreFund = _.head(funds.fundDetails);
      const { fundRiskRating } = coreFund;
      return checkRiskLevel(
        _.toNumber(fundRiskRating),
        rpqResponse.customerRpqLevel
      );
    }
  }
  return false;
};

export const getFundBalanceWithPremiumAllocation = currentPolicyDetail => {
  const {
    policyOverview: { policyCurrency },
    funds: { fundDetails },
    premiumPaymentDetails: {
      premiumAmount: { amount }
    }
  } = currentPolicyDetail;
  return {
    fundBalance: _.head(fundDetails).fundAmountBalance.amount,
    premiumAmount: amount,
    policyCurrency
  };
};

export const getMatchedCoreFund = (currentPolicyDetail, fundSelectionData) => {
  const { rpqDetails = null } = currentPolicyDetail;
  let coreFund = null;
  if (rpqDetails && fundSelectionData.eligibleFundDetails) {
    const {
      rpqResponse: { customerRpqLevel }
    } = rpqDetails;
    coreFund = _.find(fundSelectionData.eligibleFundDetails, function(o) {
      return (
        _.toNumber(o.riskLvlCde) === customerRpqLevel && o.coreFundIndicator
      );
    });
  }
  return coreFund;
};

export const getFundPerformanceColumnData = fundPerformanceDetails => {
  const fundPerformanceData = [];
  if (!_.isEmpty(fundPerformanceDetails)) {
    const { fundPerformanceList } = fundPerformanceDetails;
    if (!_.isEmpty(fundPerformanceList)) {
      const threeMonths = _.find(fundPerformanceList, {
        performanceDuration: '3M'
      });
      fundPerformanceData.push({
        isDisplayPerformance: true,
        perFormanceKey: 'performance3Month_TH',
        performance:
          threeMonths && threeMonths.performance !== undefined
            ? threeMonths.performance
            : '-'
      });
      const oneYear = _.find(fundPerformanceList, {
        performanceDuration: '1Y'
      });
      fundPerformanceData.push({
        isDisplayPerformance: true,
        perFormanceKey: 'performance1Year_TH',
        performance:
          oneYear && oneYear.performance !== undefined
            ? oneYear.performance
            : '-'
      });
    }
    return fundPerformanceData;
  }
  return null;
};

export const validatePerformanceValue = performanceValue => {
  if (
    performanceValue === undefined ||
    performanceValue === null ||
    performanceValue === '-' ||
    performanceValue === ''
  ) {
    return performanceValue;
  }
  return '0.0%';
};

/**
 * @description - function returns an Boolean of pending transaction if there is for a particular servicing request.
 * @param {String} serviceName - Its the service name definition in the api response. And one parameter that we plan to filter by.
 * @param {String} detailsResponse - The details object.
 * @param {String} serviceStatus - The status that we plan to filter by. Default is 'P'
 */
export const transactionsForService = (
  serviceName,
  detailsResponse,
  serviceStatus = 'P'
) => {
  const { instructionEnquiryDetails } = detailsResponse;
  if (instructionEnquiryDetails && instructionEnquiryDetails.instructionList) {
    return (
      _.filter(instructionEnquiryDetails.instructionList, {
        transCode: serviceName,
        status: serviceStatus
      }).length > 0
    );
  }
  return false;
};

/**
 *  @description - Function that checks for the availability of the servicing option and returns a string specifying the reason, and false if no issue.
 *  @param {String} linkId  - Identifier for  the servicing option
 *  @param {String} detailsResponse  - Array of members
 */
export const servicingLinkAvailable = (
  linkId,
  detailsResponse,
  customerWhiteListedForDigiServiceLinks
) => {
  if (
    linkId === 'TransferOfFunds_Link' &&
    customerWhiteListedForDigiServiceLinks &&
    customerWhiteListedForDigiServiceLinks.TransferOfFundsPIB
  ) {
    if (detailsResponse.policyOverview.hasAssigneeRole) {
      return 'hasAssigneeRole';
    }
    if (detailsResponse.policyOverview.hasPlanCodeVL1CSA) {
      return 'hasPlanCodeVL1';
    }
    if (detailsResponse.policyOverview.fundDealingStatus) {
      return 'pendingTransactions';
    }
    if (
      !_.isUndefined(
        detailsResponse.policyOverview.directDebitConfirmIndicator
      ) &&
      detailsResponse.policyOverview.directDebitConfirmIndicator === false
    ) {
      return 'ddNotconfirmed';
    }
  }
  return false;
};

export const checkForSurrenderPlanCodes = (
  surrenderConfig,
  detailsResponse
) => {
  let templateKey = null;
  const { surrenderSectionEntryPoint } = surrenderConfig;
  if (!_.isEmpty(surrenderSectionEntryPoint)) {
    _.map(surrenderSectionEntryPoint, list => {
      const { fieldParent, keyName, keyValue, uiTemplateKey } = list;
      const planCodeFromApi = _.get(
        detailsResponse,
        `${fieldParent}.${keyName}`
      );
      if (_.includes(keyValue, planCodeFromApi)) {
        templateKey = uiTemplateKey;
      }
    });
  }
  return templateKey;
};

export const getSurrenderChargeTableData = (
  surrenderConfig,
  detailsResponse
) => {
  let tableData = [];
  const { surrenderChargeTableData } = surrenderConfig;
  if (!_.isEmpty(surrenderChargeTableData)) {
    _.map(surrenderChargeTableData, list => {
      const { fieldParent, keyName, keyValue, tableDataList } = list;
      const planCodeFromApi = _.get(
        detailsResponse,
        `${fieldParent}.${keyName}`
      );
      if (_.includes(keyValue, planCodeFromApi)) {
        tableData = tableDataList;
      }
    });
  }
  return tableData;
};

export const entryPointCheckForInstructionHistory = currentPolicyDetail => {
  const matchedReferenceNumbers = [];
  if (currentPolicyDetail.instructionHistoryDetails) {
    const {
      pendingRequestList
    } = currentPolicyDetail.instructionHistoryDetails;
    const checkForStatusCodes = ['P', 'S', '1', '3'];
    _.forEach(pendingRequestList, currentPendingRequest => {
      const { transCode, status, referenceNo } = currentPendingRequest;
      if (
        transCode === 'TRANSFUND' &&
        _.includes(checkForStatusCodes, _.trim(status))
      ) {
        matchedReferenceNumbers.push(referenceNo);
      }
    });
    return _.uniq(matchedReferenceNumbers);
  }
  return matchedReferenceNumbers;
};

export const canDisplayRPQMismatchNotification = matchReferenceNumbers =>
  matchReferenceNumbers && matchReferenceNumbers.length === 0;

export const canDisplayIndicativeGainLoss = (
  planCodesIndicativeGainLossWPLP,
  planCode
) => _.includes(planCodesIndicativeGainLossWPLP, planCode);

export const getGainLossValues = (amount, percentage, formatCurrency) => {
  if (amount < 0) {
    return {
      gainLossLabel: 'isNegative',
      gainLossAmount: `${formatCurrency(amount)}`,
      gainLossPercentage:
        percentage < 0
          ? `${formatCurrency(percentage)}%`
          : `-${formatCurrency(percentage)}%`
    };
  }
  if (amount > 0) {
    return {
      gainLossLabel: 'isPositive',
      gainLossAmount: `+${formatCurrency(amount)}`,
      gainLossPercentage: `+${formatCurrency(percentage)}%`
    };
  }
  return {
    gainLossLabel: 'isNeutral',
    gainLossAmount: `${formatCurrency(amount)}`,
    gainLossPercentage: `${formatCurrency(percentage)}%`
  };
};

export const getPopupContent = pendingTransactions => {
  const contentObj = {};
  contentObj.infoText = pendingTransactions
    ? 'what_canyou_do_infotext'
    : 'what_canyou_do_infotext_ddNotConfirmed';
  contentObj.contactLabel = pendingTransactions
    ? 'policyGeneralInfo_Life_Contact_label'
    : 'policyGeneralInfo_Life_Contact_label_ddNotConfirmed';
  contentObj.whatCanYouHeader = pendingTransactions
    ? 'what_canyou_do_header'
    : 'what_canyou_do_header_ddNotConfirmed';
  return contentObj;
};
