import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { Td, FieldTooltip } from './beneficiarySectionStyles';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import { relationFinder } from '../../../utils/BeneficiaryUtils';

const TableNameField = (props, context) => {
  const { id, item } = props;
  const {
    intl: { formatMessage }
  } = context;

  return _.trim(item, ' ').toUpperCase() === 'SELF' ? (
    <Td id={id}>
      {formatMessage({ id: 'manage_Beneficiaries_own_estate' })}
      <FieldTooltip>
        <TooltipWrapper
          id="tooltip_id_viewBeneRole_Tooltip"
          contentKey="ownEstateBeneficiary"
          labelContent=" "
          maxWidth={300}
          sectionHeading=" "
        />
      </FieldTooltip>
    </Td>
  ) : (
    <Td id={id}>{item ? formatMessage({ id: relationFinder(item) }) : ''}</Td>
  );
};

TableNameField.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.any)
};

TableNameField.defaultProps = {
  item: null
};

TableNameField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TableNameField;
