import _ from 'lodash';
import { doAND, doOR } from '../../common/util/commonUtil';
import UmbrellaIcon from '../../assets/images/Modal/icon/umbrella.svg';
import InsuredIcon from '../../assets/images/Modal/icon/insured.svg';
import WardIcon from '../../assets/images/Modal/icon/ward.svg';
import GeographicIcon from '../../assets/images/Modal/icon/geographic.svg';
import PremiumIcon from '../../assets/images/Modal/icon/premium.svg';

const getSortedRiderData = function getSortedRiderData(array) {
  return array.sort(
    (a, b) =>
      a.documentCode === 'RP' &&
      b.documentCode === 'RP' &&
      Number(a.documentType.slice(-2)) - Number(b.documentType.slice(-2))
  );
};

const checkDocumentCode = (document, docType) => {
  return doOR(
    document.documentCode === docType,
    document.documentCodeRider === docType
  );
};

const getLangFilteredDocs = function getLangFilteredDocs(
  tempDocumentsArray,
  docTypesArray,
  langPrefrence
) {
  const finalCategoryArray = [];
  docTypesArray.forEach(docType => {
    let alreadyAdded = false;
    langPrefrence.forEach(lang => {
      tempDocumentsArray.forEach(doc => {
        if (doAND(checkDocumentCode(doc, docType), doc.languageCode === lang)) {
          finalCategoryArray.forEach(finalDoc => {
            if (checkDocumentCode(finalDoc, docType)) {
              alreadyAdded = true;
            }
          });
          if (
            checkDocumentCode(doc, docType) &&
            doc.languageCode === lang &&
            !alreadyAdded
          ) {
            finalCategoryArray.push(doc);
          }
        }
      });
    });
  });
  return finalCategoryArray || [];
};

const filterDocObject = function filterDocObject(responseDoc, documentObject) {
  const docObject = documentObject;
  let riderFound = false;
  responseDoc.docProperty.forEach(docProper => {
    if (docProper?.propertyName === 'LANGUAGE_CODE') {
      docObject.languageCode = docProper.stringValue;
    }
    if (docProper?.propertyName === 'PRINT_DATE') {
      docObject.printDate = docProper.stringValue;
    }
    if (
      docProper?.propertyName === 'DOCUMENT_CODE' &&
      docProper.stringValue.includes(`${docObject.documentCode}_`)
    ) {
      docObject.documentCodeRider = docProper.stringValue;
      riderFound = true;
    }
    if (docProper?.propertyName === 'DOCUMENT_TYPE' && riderFound) {
      docObject.documentType = docProper.stringValue;
    }
  });
  return docObject;
};

const documentObjectConfig = function documentObjectConfig(
  responseDoc,
  configDoc,
  tempDocument,
  documentsArr,
  docTypesArray
) {
  let documentObject = tempDocument;
  if (
    doAND(responseDoc, responseDoc.docProperty, responseDoc.docProperty.length)
  ) {
    responseDoc.docProperty.forEach(docProp => {
      if (
        docProp?.propertyName === 'DOCUMENT_CODE' &&
        doOR(
          configDoc.documentCode === docProp.stringValue,
          docProp.stringValue.includes(`${configDoc.documentCode}_`)
        )
      ) {
        documentObject = filterDocObject(responseDoc, documentObject);
        documentObject.documentID = responseDoc.docIdentification.documentID;
        if (
          !doOR(
            docTypesArray.includes(configDoc.documentCode),
            docTypesArray.includes(documentObject.documentCodeRider)
          )
        ) {
          let docObject;
          if (docProp.stringValue.includes(`${configDoc.documentCode}_`)) {
            docObject = docProp.stringValue;
          } else {
            docObject = configDoc.documentCode;
          }
          docTypesArray.push(docObject);
        }
        documentsArr.push({ ...tempDocument });
      }
    });
  }
  return { documentsArr, docTypesArray };
};

const getPrintDateFilteredDocs = function getPrintDateFilteredDocs(
  documentTypesArray,
  tempDocumentsArray
) {
  const filteredPrintDateArray = [];
  documentTypesArray.forEach(docType => {
    const documentsWithSameTypes = [];
    const datesOfDocsArray = [];
    tempDocumentsArray.forEach(doc => {
      if (doc.documentCode === docType || doc.documentCodeRider === docType) {
        documentsWithSameTypes.push(doc);
        datesOfDocsArray.push(new Date(doc.printDate));
      }
    });
    const latestDateInArray = new Date(Math.max.apply(null, datesOfDocsArray));
    documentsWithSameTypes.forEach(doc => {
      if (new Date(doc.printDate) >= latestDateInArray) {
        filteredPrintDateArray.push(doc);
      }
    });
  });
  return filteredPrintDateArray;
};

export const hasDocuments = function hasDocuments(configCategory) {
  return (
    configCategory &&
    configCategory.documents &&
    configCategory.documents.length
  );
};

export const isEmptyArray = function isEmptyArray(arr) {
  return arr && arr.length;
};

export const getPolicyInfo = function getPolicyInfo(policy) {
  let planCode;
  let productCategory;
  const { policyDetails = {} } = policy;
  if (!_.isEmpty(policyDetails)) {
    ({ policyOverview: { planCode, productCategory } = {} } = policyDetails);
  } else if (policy.policyNumber) {
    ({ planCode, productCategory } = policy);
  }
  return { planCode, productCategory };
};

export const documentsArray = function documentsArray(
  eWelcomeDocumentConfig,
  docRecordList,
  selectedLocale,
  policy
) {
  const { planCode, productCategory } = getPolicyInfo(policy);
  const {
    documentsConfig: { documentLangConfig = {} }
  } = eWelcomeDocumentConfig;
  const documentConfigProductCategory =
    eWelcomeDocumentConfig.documentsConfig[productCategory] || [];
  const langPrefrence = documentLangConfig[selectedLocale];
  const tempDocumentConfig = [];
  let totalDocuments = 0;
  const documentIDList = [];
  documentConfigProductCategory.forEach(configCategory => {
    let tempDocumentsArray = [];
    let documentObjectConf = {};
    let documentTypesArray = [];
    if (hasDocuments(configCategory)) {
      configCategory.documents.forEach(configDoc => {
        const { planCodesEligible = [] } = configDoc;
        if (planCodesEligible.includes(planCode)) {
          const tempDocument = {
            ...configDoc
          };
          docRecordList.forEach(responseDoc => {
            documentObjectConf = documentObjectConfig(
              responseDoc,
              configDoc,
              tempDocument,
              tempDocumentsArray,
              documentTypesArray
            );
            const { documentsArr, docTypesArray } = documentObjectConf;
            tempDocumentsArray = documentsArr;
            documentTypesArray = docTypesArray;
          });
        }
      });
    }

    const filteredPrintDateArray = getPrintDateFilteredDocs(
      documentTypesArray,
      tempDocumentsArray
    );

    const finalCategoryArray = getLangFilteredDocs(
      filteredPrintDateArray,
      documentTypesArray,
      langPrefrence
    );

    const sortedFinalArray = getSortedRiderData(finalCategoryArray);
    if (sortedFinalArray.length) {
      sortedFinalArray.forEach(doc => {
        if (doAND(doc, doc.documentID)) {
          documentIDList.push({ documentID: doc.documentID });
        }
      });
      totalDocuments += sortedFinalArray.length;
      const categoryObj = {
        ...configCategory
      };
      categoryObj.documents = sortedFinalArray;
      tempDocumentConfig.push(categoryObj);
    }
    return null;
  });

  return { totalDocuments, documentIDList, categoryConfig: tempDocumentConfig };
};

export const keyFeaturesArray = function keyFeaturesArray(
  eWelcomeKeyFeatureConfig,
  planCode,
  planName,
  insuredPerson,
  productCategory
) {
  const keyConfigProductCategory =
    eWelcomeKeyFeatureConfig.featuresConfig[productCategory];
  const tempFeaturesArray = [];
  if (isEmptyArray(keyConfigProductCategory)) {
    keyConfigProductCategory.forEach(feature => {
      const { planCodesEligible = [] } = feature;
      if (planCodesEligible.includes(planCode)) {
        const tempFeature = {
          ...feature
        };
        if (tempFeature.featureType === 'planName') {
          tempFeature.value = planName;
          tempFeature.icon = UmbrellaIcon;
        } else if (tempFeature.featureType === 'insuredPerson') {
          tempFeature.value = insuredPerson;
          tempFeature.icon = InsuredIcon;
        } else if (tempFeature.featureType === 'geographic') {
          tempFeature.icon = GeographicIcon;
        } else if (tempFeature.featureType === 'ward') {
          tempFeature.icon = WardIcon;
        } else if (tempFeature.featureType === 'paymentInfo') {
          tempFeature.value = '';
          tempFeature.icon = PremiumIcon;
        }
        tempFeaturesArray.push(tempFeature);
      }
    });
  }
  return tempFeaturesArray;
};
