import _ from 'lodash';

const getInstructionListAsPerRequest = instructionData => {
  return {
    pending: (instructionData && instructionData.pendingRequestList) || [],
    history: (instructionData && instructionData.requestHistoryList) || []
  };
};

const sortInstructionListByDate = instructionList => {
  return instructionList.sort((a, b) => {
    const submissionTimestamp1 = new Date(a.submissionTimestamp).getTime();
    const submissionTimestamp2 = new Date(b.submissionTimestamp).getTime();
    if (submissionTimestamp1 > submissionTimestamp2) {
      return -1;
    }
    if (submissionTimestamp1 < submissionTimestamp2) {
      return 1;
    }
    return 0;
  });
};

const generateCommaSeparatedRefNumbers = (
  matchedReferenceNumbers,
  commaLabel,
  formatMessage
) => {
  if (matchedReferenceNumbers.length === 1) {
    return matchedReferenceNumbers;
  }
  return matchedReferenceNumbers.join(formatMessage({ id: commaLabel }));
};

const getUniqueErrorCodes = errorList => {
  const errorCodes = _.map(errorList, 'errorCode');
  return _.uniq(errorCodes);
};

const getPolicyDataForExceptionPage = (
  planName,
  encodedProductId,
  policyNumber
) => {
  return {
    planName,
    encodePolicyNumber: encodedProductId,
    policyNumber
  };
};

const getFieldData = (fieldParent, fieldValue) => {
  return _.get(fieldParent, fieldValue);
};

const isWithdrawalTotalFund = details => {
  if (details.fundWithdrawPolicy) {
    const { withdrawFundList } = details.fundWithdrawPolicy;
    return !withdrawFundList.length > 0;
  }
  return true;
};

const canShowSLATextForStatus = status =>
  _.includes(['P', 'S', '1', '2', '3'], status);

const checkAmountZero = amount => !amount || Number(amount) <= 0;
export {
  sortInstructionListByDate,
  getInstructionListAsPerRequest,
  generateCommaSeparatedRefNumbers,
  getUniqueErrorCodes,
  getPolicyDataForExceptionPage,
  getFieldData,
  isWithdrawalTotalFund,
  canShowSLATextForStatus,
  checkAmountZero
};
