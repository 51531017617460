import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import CurrencyWithSymbol from '../../../common/currency/CurrencyWithSymbol';
import {
  FundsTotalSubSectionFieldWrapper,
  TotalSubSectionLabel,
  TotalSubSectionAmount
} from '../../detailPageStyle';

const FundsTotalSubSectionField = function FundsTotalSubSectionField(props) {
  const {
    labelContent,
    fieldData,
    fieldData: { currency, amount },
    idx,
    id,
    toolTipContent
  } = props;
  const sectionHeading = 'fund details';
  if (
    !labelContent ||
    typeof fieldData !== 'object' ||
    !currency ||
    (!amount && amount === 0)
  ) {
    return null;
  }

  return (
    <FundsTotalSubSectionFieldWrapper id="fundsTotalSubSection">
      <TotalSubSectionLabel id={`totalSubSectionLabel_${idx}`}>
        <FormattedMessage id={labelContent} />
        <TooltipWrapper
          id={`tooltip_fundsSubSection_${id}`}
          contentKey={toolTipContent}
          labelContent={labelContent}
          sectionHeading={sectionHeading}
        />
      </TotalSubSectionLabel>
      <TotalSubSectionAmount>
        <CurrencyWithSymbol currencyCode={currency} amount={amount} />
      </TotalSubSectionAmount>
    </FundsTotalSubSectionFieldWrapper>
  );
};

FundsTotalSubSectionField.propTypes = {
  id: PropTypes.string.isRequired,
  idx: PropTypes.number,
  labelContent: PropTypes.string,
  fieldData: PropTypes.number.isRequired,
  toolTipContent: PropTypes.string
};

FundsTotalSubSectionField.defaultProps = {
  toolTipContent: null,
  labelContent: null,
  idx: 0
};

export default FundsTotalSubSectionField;
