import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { Fonts, FontFamily, FontSize } from '../../../utils/font';
import media from '../../../utils/media';
import theme from '../../../common/styles/theme';

/* eslint no-nested-ternary: 0 */
export const HeaderContainer = styled.div`
  max-width: ${em(theme.maxWidth)};
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
`;
const LogoStyle = `
    box-sizing: inherit;
    display: flex;
    align-items: center;
    float: left;
    color: ${theme.fontColor};
    height: ${em('27px')};
    img {
        max-width: 100%;
        display: inline-block;
        height: -webkit-fill-available;
        height: 100%;
        width:100%;
    }
`;

export const HsbcLogo = styled.span`
  ${LogoStyle}
  width: ${rem('100px')};
  ${media.tablet`
    position: relative;
    top: -2px;
  `};
  @media only screen and (max-width: 768px) and (min-width: 721px) {
    margin-right: ${rem('0px')};
  }
`;

export const HsbcFlag = styled.span`
  ${LogoStyle}
  height: ${em('14px')};
  padding: 0 ${rem('15px')};
  white-space: nowrap;
  span {
    cursor: default;
  }
  img {
    margin-right: ${rem('10px')};
    width: ${rem('21px')};
  }
`;

export const NavSection = styled.nav`
  display: block;
  box-sizing: border-box;
  max-width: ${em(theme.maxWidth)};
  height: auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem('50px')};
  font: inherit;
  ${media.tablet`
  padding: ${rem('17px')} ${rem('24px')};
  `};
  ${media.phoneToMiniTablet`
      display:none;
  `};
`;

const commonUlStyle = css`
  padding: 0;
  width: ${props => {
    if (props.topLeft) {
      return `50%`;
    }
    return props.topRight ? `45%` : 'auto';
  }};
`;

const ButtonActiveCss = css`
  box-sizing: border-box;
  border-bottom: 4px solid ${theme.stepSelected};
  span {
    ${FontFamily.BoldFontW01};
  }
`;

export const ULSection = styled.ul`
  float: ${props => (props.rightAlign ? `right` : `left`)};
  padding: ${props => (props.topLeft ? `0 0 0 ${rem('37px')}` : '')};
  position: relative;
  list-style: none;
  margin: 0;
  border: 0;
  font: inherit;
  width: ${`calc(50% - 43px)`};
  display: ${props => (props.topRight ? `flex` : `unset`)};
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 1275px) and (min-width: 1025px) {
  /*   padding: ${props => (props.topRight ? '0' : `0 0 0 4%`)};
    width: ${props => {
      if (props.topLeft) {
        return `calc(60% - 84px)`;
      }
      return props.topRight ? `40%` : 'auto';
    }}
 */
    ${commonUlStyle}
    }
  ${media.tablet`
      ${commonUlStyle}
    `};
`;
export const LISection = styled.li`
  margin: 0;
  word-break: ${props => (props.isCopyright ? 'keep-all' : '')};
  font: inherit;
  float: left;
  position: relative;
  border-bottom: ${props => (props.isActive ? '5px solid #DB0011' : '')};
  &:after {
    position: absolute;
    height: ${rem('20px')};
    left: 0;
    width: ${rem('1px')};
    background: #979797;
    top: 50%;
    margin-top: ${rem('-10px')};
    display: ${props => (props.displayline ? `block` : `none`)};
  }
  &#myInsurance:hover,
  &#myInsurance:focus {
    #megaMenu {
      display: flex;
    }
  }
  &button#myInsurance_link[aria-expanded='true'] {
    ${ButtonActiveCss}
  }
`;
export const ButtonText = styled.button`
  ${FontFamily.RegularFontW01};
  background: none;
  -webkit-appearance: none;
  border: 0;
  color: ${theme.fontColor};
  display: -moz-inline-stack;
  display: inline-block;
  padding: ${rem('17px')} 0;
  margin: ${rem('10px')} ${rem('17px')};
  text-decoration: none;
  margin-bottom: 0;
  padding-bottom: 22px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  box-sizing: border-box;
  span {
    cursor: pointer;
  }
  position: relative;
  &:focus {
    text-decoration: underline;
  }
  &:hover {
    ${ButtonActiveCss}
  }
  ${media.tablet`
  padding: 0 ${rem('10px')} ${rem('10px')};  
  `};
  ${props =>
    props.isSubMenuOpen &&
    `
   ${ButtonActiveCss}
   
  `}
`;

export const LinkText = styled.a`
  ${FontFamily.RegularFontW01};
  color: ${theme.fontColor};
  display: -moz-inline-stack;
  display: inline-block;
  padding: ${rem('17px')} ${rem('17px')};
  margin: ${rem('10px')} 0;
  text-decoration: none;
  cursor: pointer;
  span {
    cursor: pointer;
  }
  position: relative;
  &:hover,
  &:focus {
    text-decoration: ${props => (props.iconText ? `none` : `underline`)};
  }
  &:focus {
    text-decoration: underline;
    outline: 0;
  }
  ${media.tablet`
  padding: 0 ${rem('10px')};
  `};
`;

export const LinkTextPib = styled.span`
  ${FontSize.size5};
  color: ${theme.fontColor};
  ${FontFamily.RegularFontW01};
`;

export const LogoffWrapper = styled.span`
  button {
    line-height: ${rem('10px')};
    padding: ${rem('9px')} ${rem('18px')};
    ${Fonts.t12};
  }
`;

export const FlagText = styled.span`
  ${Fonts.t14} ${FontFamily.RegularFontW01};
  span {
    ${FontFamily.RegularFontW01};
  }
`;
