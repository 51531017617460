import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { truncDigits } from '../../../utils/formatter';
import projectedGainChevrron from '../../../assets/images/reprojections/projectedGainChevrron.svg';
import {
  FieldDataWrapper,
  Chevrron,
  FieldDataBold,
  ConstantFieldData,
  ProjectedGainBodyWrapper,
  FieldBoxContainer,
  FieldLabel
} from '../../reprojectionPageStyle';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';

const ProjectedGain = function ProjectedGain(props, context) {
  const {
    labelContent,
    fieldData,
    planCodesCheckList,
    dependantValue,
    planCodeFieldTooltip
  } = props;
  let { toolTipContent } = props;
  const {
    intl: { formatMessage }
  } = context;
  const isReprojectionsTooltip = true;
  if (!labelContent || (typeof fieldData === 'number' && fieldData < 1)) {
    return <></>;
  }
  if (_.includes(planCodesCheckList, dependantValue)) {
    toolTipContent = planCodeFieldTooltip;
  }

  return (
    <FieldBoxContainer id={labelContent}>
      <FieldLabel
        data-test="projectgain-labelcontent"
        id="projected_gain_label"
      >
        {formatMessage({
          id: labelContent
        })}
        <TooltipWrapper
          id="tooltip_projected_gain"
          contentKey={toolTipContent}
          sectionHeading=" "
          labelContent={labelContent}
          maxWidth={300}
          isReprojectionsTooltip={isReprojectionsTooltip}
        />
      </FieldLabel>
      <ProjectedGainBodyWrapper>
        <FieldDataWrapper>
          <Chevrron src={projectedGainChevrron} alt="" aria-hidden="true" />
          <FieldDataBold>{truncDigits(fieldData, 0)}%</FieldDataBold>
        </FieldDataWrapper>
        <ConstantFieldData>
          {formatMessage({ id: 'ProjectedGainDependentLabel' })}
        </ConstantFieldData>
      </ProjectedGainBodyWrapper>
    </FieldBoxContainer>
  );
};
ProjectedGain.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.number,
  toolTipContent: PropTypes.string.isRequired,
  planCodesCheckList: PropTypes.array.isRequired,
  dependantValue: PropTypes.string.isRequired,
  planCodeFieldTooltip: PropTypes.string.isRequired
};
ProjectedGain.defaultProps = {
  fieldData: 0
};
ProjectedGain.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ProjectedGain;
