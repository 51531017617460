import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { AccessibleHidden } from '../../app/AppStyles';
import {
  PolicyDetailWrapper,
  PlanNameRow,
  PlanNameHeader,
  PolicyTagsWrapper,
  PolicyNumber
} from '../instructionHistoryStyle';
import getDescription from '../../utils/reducerUtils';
import { tagGenerator } from '../../utils/policyUtils';
import getContentInSpecificLang from '../../utils/localeUtils';

const PolicyDetails = (props, context) => {
  const {
    intl: { locale, formatMessage }
  } = context;
  const {
    currentPolicyDetail,
    subProductCategories,
    productCategory,
    selectedLocale,
    langObj,
    summaryResponse,
    policyTagsMedicalAndCIConfig
  } = props;
  const {
    policyOverview: { planName, policyNumber }
  } = currentPolicyDetail;

  const matchedConfiguredProduct = _find(subProductCategories, [
    'productCategoryName',
    productCategory
  ]);

  const policyTags = () => {
    return (
      matchedConfiguredProduct &&
      matchedConfiguredProduct.tags && (
        <PolicyTagsWrapper>
          <AccessibleHidden>
            {formatMessage({ id: 'policyTags' })}
          </AccessibleHidden>
          {tagGenerator(
            matchedConfiguredProduct,
            currentPolicyDetail,
            selectedLocale,
            langObj,
            getContentInSpecificLang,
            formatMessage,
            summaryResponse,
            policyTagsMedicalAndCIConfig
          )}
        </PolicyTagsWrapper>
      )
    );
  };

  return (
    <PolicyDetailWrapper>
      <PlanNameRow>
        <PlanNameHeader id="policy_name">
          {getDescription(locale, planName)}
        </PlanNameHeader>
      </PlanNameRow>
      {policyTags()}
      <PolicyNumber>
        {formatMessage({ id: 'policyNumber' })} {policyNumber}
      </PolicyNumber>
    </PolicyDetailWrapper>
  );
};

PolicyDetails.propTypes = {
  currentPolicyDetail: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  productCategory: PropTypes.string.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  summaryResponse: PropTypes.object.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired
};

PolicyDetails.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyDetails;
