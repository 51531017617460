import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ReturnAmountWrapper,
  ReturnAmountLabelLeft,
  ReturnAmountValueRight
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import getDescription from '../../../utils/reducerUtils';
import { generateLabelWithTooltipForJW1 } from '../../sectionUtils/ReturnsUtils';

const ReturnsAmount = (props, context) => {
  const {
    labelContent,
    fieldData,
    toolTipContent,
    sectionHeading,
    dependentFieldData,
    dependentFieldDataTwo,
    paymentModeReferenceType,
    planCode,
    planCodesToCheck
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;
  let contentDescription = '';
  let toolTipLabelToAdd = toolTipContent;
  let labelToChange = labelContent;

  if (dependentFieldData && dependentFieldData.length) {
    contentDescription = getDescription(locale, dependentFieldData);
    toolTipLabelToAdd = _.camelCase(getDescription('en', dependentFieldData));
  }

  if (dependentFieldDataTwo && paymentModeReferenceType) {
    const trimValue = paymentModeReferenceType.trim();
    if (trimValue === 'S01' || trimValue === 'AGG') {
      labelToChange = `${labelContent}_${trimValue}`;
    }
  }
  const { label, tooltip } = generateLabelWithTooltipForJW1(
    planCode,
    labelContent,
    toolTipContent,
    planCodesToCheck
  );
  labelToChange = label;
  toolTipLabelToAdd = tooltip;
  if (
    (!labelContent && !contentDescription) ||
    typeof fieldData !== 'object' ||
    !fieldData.currency ||
    !parseFloat(fieldData.amount)
  ) {
    return null;
  }
  return (
    <ReturnAmountWrapper id={labelToChange || contentDescription}>
      <ReturnAmountLabelLeft>
        {contentDescription || formatMessage({ id: labelToChange })}
        <TooltipWrapper
          id={`tooltip_${labelToChange || toolTipLabelToAdd}`}
          contentKey={toolTipLabelToAdd}
          labelContent={labelToChange || toolTipLabelToAdd}
          sectionHeading={sectionHeading}
          maxWidth={300}
          isFormatHtml
        />
      </ReturnAmountLabelLeft>
      <ReturnAmountValueRight>
        <Currency currencyCode={fieldData.currency} amount={fieldData.amount} />
      </ReturnAmountValueRight>
    </ReturnAmountWrapper>
  );
};

ReturnsAmount.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.array,
  dependentFieldDataTwo: PropTypes.string,
  paymentModeReferenceType: PropTypes.string,
  planCode: PropTypes.string.isRequired,
  planCodesToCheck: PropTypes.array
};

ReturnsAmount.defaultProps = {
  toolTipContent: null,
  dependentFieldData: [],
  dependentFieldDataTwo: '',
  paymentModeReferenceType: '',
  planCodesToCheck: []
};

ReturnsAmount.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ReturnsAmount;
