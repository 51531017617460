import styled from 'styled-components';

export const ErrorMessageSectHeader = styled.div`
  margin-bottom: 15px;
  line-height: 1.5em;
`;

export const InfoMsg = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: #e5f2f2;
  border: solid 1px #99cecc;
  padding: 15px 13px 1px 13px;
  display: inline-flex;
`;

export const PageInfoDiv = styled.div`
  font-family: UniversNextforHSBC-Regular;
  font-size: 16px;
  color: #333333;
`;

export const IconInfoStyle = styled.div`
  color: #305a85;
  margin-right: 8px;
  ${props => (props.circleIconStyles ? props.circleIconStyles : null)};
`;
export const InfoStyleIcon = styled.img`
  padding: 0 0 7px 7px !important;
`;
