import { SET_CUSTOMER } from '../actionTypes';

export const INITIAL_STATE = {
  customerIdentifier: '',
  hkId: '',
  encryptedCIN: '',
  docId: ''
};

const customerReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
  if (type === SET_CUSTOMER) {
    return { ...payload };
  }
  return state;
};

export default customerReducer;
