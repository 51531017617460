import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import {
  IntroHeaderSection,
  OverviewWrapper,
  PolicyTagsRow,
  DetailsPageOverviewSectionWithWarning,
  LifeOrGiBanner
} from '../detailPageStyle';
import { AccessibleHidden } from '../../app/AppStyles';
import getContentInSpecificLang from '../../utils/localeUtils';
import { FullWidthSectionContainer } from '../../common/styles/commonStyle';
import ExceptionalLapsedBanner from '../templates/ExceptionalLapsedBanner';
import { tagGenerator } from '../../utils/policyUtils';
import { getProductDetails } from '../../utils/tealiumUtils';
import getDescription from '../../utils/reducerUtils';

const PolicyOverview = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const {
    currentPolicyDetail,
    subProductCategories,
    productCategory,
    selectedLocale,
    langObj,
    fieldsStack,
    isPolicyDetailsAvailable,
    isPolicyExceptional,
    isPolicyLapsed,
    superFamily,
    summaryResponse,
    warningsOrErrors,
    policyTagsMedicalAndCIConfig
  } = props;

  if (currentPolicyDetail) {
    getProductDetails(
      currentPolicyDetail.policyOverview.productFamily,
      currentPolicyDetail.policyOverview.productCategory,
      getDescription('en', currentPolicyDetail.policyOverview.planName),
      currentPolicyDetail.policyOverview.planCode,
      policyTagsMedicalAndCIConfig
    );
  }
  if (!fieldsStack || (fieldsStack.length && fieldsStack.length < 1)) {
    return <></>;
  }

  const matchedConfiguredProduct = _find(subProductCategories, [
    'productCategoryName',
    productCategory
  ]);
  /**
   * Utility method tagGenerator creates the UI of tags by consuming all the parameters passed to it. In case policy is not a general policy,
   * the tag values of 1st and 2nd tags will be taken from summary response instead of details response.
   */

  const policyTags = () => {
    return (
      matchedConfiguredProduct &&
      matchedConfiguredProduct.tags && (
        <PolicyTagsRow>
          <AccessibleHidden>
            {formatMessage({ id: 'policyTags' })}
          </AccessibleHidden>
          {tagGenerator(
            matchedConfiguredProduct,
            currentPolicyDetail,
            selectedLocale,
            langObj,
            getContentInSpecificLang,
            formatMessage,
            summaryResponse,
            policyTagsMedicalAndCIConfig
          )}
        </PolicyTagsRow>
      )
    );
  };

  return (
    <FullWidthSectionContainer>
      <DetailsPageOverviewSectionWithWarning>
        <LifeOrGiBanner>
          <ExceptionalLapsedBanner
            productCategory={productCategory}
            isPolicyDetailsAvailable={isPolicyDetailsAvailable}
            isPolicyExceptional={isPolicyExceptional}
            isPolicyLapsed={isPolicyLapsed}
            superFamily={superFamily}
            warningsOrErrors={warningsOrErrors}
            summaryResponse={summaryResponse}
          />
        </LifeOrGiBanner>
        <IntroHeaderSection>
          {fieldsStack[0]}
          {policyTags()}
          <OverviewWrapper>
            {fieldsStack[1].map(fieldInstance => (
              <React.Fragment key={fieldInstance}>
                {fieldInstance}
              </React.Fragment>
            ))}
          </OverviewWrapper>
        </IntroHeaderSection>
      </DetailsPageOverviewSectionWithWarning>
    </FullWidthSectionContainer>
  );
};

export default PolicyOverview;

PolicyOverview.contextTypes = {
  intl: PropTypes.object.isRequired
};

PolicyOverview.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  currentPolicyDetail: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  productCategory: PropTypes.string.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  isPolicyDetailsAvailable: PropTypes.bool.isRequired,
  isPolicyExceptional: PropTypes.bool.isRequired,
  isPolicyLapsed: PropTypes.bool.isRequired,
  superFamily: PropTypes.string.isRequired,
  summaryResponse: PropTypes.object.isRequired,
  warningsOrErrors: PropTypes.array,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired
};

PolicyOverview.defaultProps = {
  warningsOrErrors: []
};
