import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, Overlay } from 'ui-component-library';

const OverlayDiv = Overlay.extend`
  top: 0;
  background-color: rgba(
    255,
    255,
    255,
    ${props => (props.opacity ? props.opacity : 0.8)}
  );
`;

// "Loading" text can be shown by passing text as a prop to Spinner component
const Loader = props => {
  const { opacity, messages, id } = props;
  return (
    <OverlayDiv opacity={opacity}>
      <Spinner
        color="black"
        center
        accessibilityLabel={(messages && messages[id]) || 'Loading'}
      />
    </OverlayDiv>
  );
};
export default Loader;

Loader.propTypes = {
  opacity: PropTypes.number.isRequired,
  messages: PropTypes.object,
  id: PropTypes.string.isRequired
};

Loader.defaultProps = {
  messages: {}
};
