import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  FundDetailWrapper,
  FundTypeHeading,
  FundName,
  FundAllocation,
  FundNameSection,
  SwitchOutName,
  SwitchOutAllocation,
  SettlementWrapper
} from '../instructionHistoryStyle';
import Currency from '../../common/currency/Currency';
import getDescription from '../../utils/reducerUtils';
import FundNameAllocation from '../templates/FundNameAllocation';
import SettlementInstruction from '../templates/SettlementInstruction';
import SettlementCurrency from '../templates/SettlementCurrency';
import SlaNoteSection from '../templates/SlaNoteSection';
import ServicingLabelAmount from '../templates/ServicingLabelAmount';

const FundWithdrawal = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const { withdrawPolicy, status } = props;
  const { paymentInfo, withdrawFund, withdrawFundList = [] } = withdrawPolicy;
  const {
    withdrawalMethod,
    withdrawalOption,
    transactionFee,
    totalWithdrawalCurrencyCode,
    totalWithdrawalPercentOrAmount
  } = withdrawFund;

  const getTotalAmount = () => {
    return withdrawFundList.reduce((acc, value) => {
      const amount = _.get(value, 'fundWithdrawalPercentOrAmount');
      return acc + _.toNumber(amount);
    }, 0);
  };

  const totalWithdrawalAmount = getTotalAmount();

  const amountPercentLayout = fund => {
    const {
      fundName,
      fundWithdrawalPercentOrAmount,
      fundWithdrawalCurrencyCode
    } = fund;
    if (withdrawalMethod === 'P') {
      return (
        <FundNameAllocation
          locale={locale}
          fundName={fundName}
          percent={_.toNumber(fundWithdrawalPercentOrAmount)}
          labelId="switchOutValueWithoutAmt"
        />
      );
    }
    if (withdrawalMethod === 'A') {
      return (
        <FundNameSection>
          <FundName>{getDescription(locale, fundName)}</FundName>
          <FundAllocation>
            <Currency
              currencyCode={`${fundWithdrawalCurrencyCode}`}
              amount={fundWithdrawalPercentOrAmount}
            />
          </FundAllocation>
        </FundNameSection>
      );
    }
    return null;
  };

  const individualFunds = () => {
    const { fundWithdrawalCurrencyCode } = _.head(withdrawFundList);
    return (
      <>
        {withdrawFundList.length > 0 && (
          <FundDetailWrapper>
            <FundTypeHeading>
              {formatMessage({ id: 'withdrawFundsLabel' })}
            </FundTypeHeading>
            {_.map(withdrawFundList, fund => {
              return amountPercentLayout(fund);
            })}
            {!Number.isNaN(totalWithdrawalAmount) &&
              withdrawalMethod === 'A' &&
              fundWithdrawalCurrencyCode && (
                <FundNameSection>
                  <SwitchOutName>
                    {formatMessage({ id: 'totalWithdrawalAmountLabel' })}
                  </SwitchOutName>
                  <SwitchOutAllocation>
                    <Currency
                      currencyCode={`${fundWithdrawalCurrencyCode}`}
                      amount={totalWithdrawalAmount}
                    />
                  </SwitchOutAllocation>
                </FundNameSection>
              )}
            <SettlementWrapper>
              <SettlementInstruction paymentInfo={paymentInfo} individualFund />
              <SettlementCurrency paymentInfo={paymentInfo} individualFund />
            </SettlementWrapper>
            <SlaNoteSection labelId="withdrawPolicySlaText" status={status} />
          </FundDetailWrapper>
        )}
      </>
    );
  };

  const fundWithDrawalTotalFund = () => {
    if (withdrawalMethod === 'P') {
      return (
        <FundAllocation removeLeftSpacing>
          <FormattedMessage
            id="switchOutValueWithoutAmt"
            values={{
              percentage: _.toNumber(totalWithdrawalPercentOrAmount)
            }}
          />
        </FundAllocation>
      );
    }
    if (withdrawalMethod === 'A') {
      return (
        <FundAllocation removeLeftSpacing>
          <Currency
            currencyCode={`${totalWithdrawalCurrencyCode}`}
            amount={totalWithdrawalPercentOrAmount}
          />
        </FundAllocation>
      );
    }
    return null;
  };

  const totalFunds = () => {
    return (
      <FundDetailWrapper>
        {totalWithdrawalPercentOrAmount && (
          <FundNameSection>
            <FundName removeLeftSpacing>
              {formatMessage({ id: 'totalFundBalanceWithdrawLabel' })}
            </FundName>
            {fundWithDrawalTotalFund()}
          </FundNameSection>
        )}
        <SettlementInstruction paymentInfo={paymentInfo} />
        <SettlementCurrency paymentInfo={paymentInfo} />
        {totalWithdrawalCurrencyCode && (
          <ServicingLabelAmount
            label="transactionFeeLabel"
            amount={transactionFee}
            currency={totalWithdrawalCurrencyCode}
            removeLeftSpacing
          />
        )}
        <SlaNoteSection labelId="withdrawPolicySlaText" status={status} />
      </FundDetailWrapper>
    );
  };

  return (
    <>
      {withdrawalOption === 'B' && individualFunds()}
      {withdrawalOption === 'A' && totalFunds()}
    </>
  );
};

FundWithdrawal.propTypes = {
  withdrawPolicy: PropTypes.object,
  status: PropTypes.string.isRequired
};

FundWithdrawal.defaultProps = {
  withdrawPolicy: {}
};
FundWithdrawal.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default FundWithdrawal;
