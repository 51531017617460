import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BackIcon from '../assets/images/cevrron-right.svg';
import titleHandler from '../utils/titleUtils';
import {
  DetailsNotification,
  NotificationRow,
  LeftNotificationRow,
  NotificationBorder,
  BackButton,
  ButtonWrapperOL,
  ListLI,
  ImageSpan,
  ImagePath
} from './detailPageStyle';
import Context from '../app/Context';
import { trackEvents } from '../utils/tealiumUtils';

const DetailNotification = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const title = formatMessage({ id: 'detail_page_title' });
  titleHandler(title);

  const { policyDetailsTealium } = props;
  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;

        return (
          <>
            <DetailsNotification>
              <NotificationRow>
                <NotificationBorder>
                  <LeftNotificationRow>
                    <ButtonWrapperOL>
                      <ListLI>
                        <Link
                          to={forLink.summary}
                          id="my_insurance_back_link"
                          onClick={() =>
                            trackEvents({
                              eventName: 'myInsurance',
                              policyDetailsTealium
                            })
                          }
                        >
                          <BackButton
                            aria-label={formatMessage({
                              id: 'BackMyInsuranceAriaText'
                            })}
                          >
                            <span>
                              {formatMessage({ id: 'BackMyInsurance' })}
                            </span>
                            <ImageSpan>
                              <ImagePath src={BackIcon} alt="" />
                            </ImageSpan>
                          </BackButton>
                        </Link>
                      </ListLI>
                      <ListLI currentPage>
                        <span aria-current="page">
                          {formatMessage({ id: 'policyDetailsLabel' })}
                        </span>
                      </ListLI>
                    </ButtonWrapperOL>
                  </LeftNotificationRow>
                </NotificationBorder>
              </NotificationRow>
            </DetailsNotification>
          </>
        );
      }}
    </Context.Consumer>
  );
};

DetailNotification.contextTypes = {
  intl: PropTypes.object.isRequired,
  applicationUrl: PropTypes.object
};

DetailNotification.propTypes = {
  policyDetailsTealium: PropTypes.object.isRequired
};

export default DetailNotification;
