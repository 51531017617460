import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListElement, ListHeaderLI } from '../../burgermenu/BurgerMenuStyles';
import MegaMenuLinks from './MegaMenuLinks';
import { canDisplayMegaMenuLink } from './megaMenuUtils';

const MegaMenuMobile = function MegaMenuMobile(props, context) {
  const {
    megaMenuItems,
    totalPolicyCount,
    handleClose,
    langObj,
    customer,
    customerSegmentValues
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  return (
    <>
      {_.map(megaMenuItems, megaMenuItem => {
        const { menuHeading, isDisplayMenu = false, menuItems } = megaMenuItem;
        return (
          isDisplayMenu && (
            <>
              <ListElement
                key={`${menuHeading}_list`}
                id={`${menuHeading}_list`}
                aria-label={formatMessage({ id: menuHeading })}
                className="megaMenuUL"
              >
                <ListHeaderLI>
                  {formatMessage({ id: menuHeading })}
                </ListHeaderLI>
              </ListElement>
              {_.map(menuItems, subMenu => {
                const {
                  menuHeading: subMenuHeading,
                  isDisplayMenu: isDisplaySubMenu = false
                } = subMenu;

                const displayConditionParams = {
                  customer,
                  customerSegmentValuesArray: customerSegmentValues
                };

                return (
                  isDisplaySubMenu &&
                  canDisplayMegaMenuLink(subMenu, displayConditionParams) && (
                    <ListElement
                      key={`${menuHeading}_${subMenuHeading}_list_item`}
                      id={`${menuHeading}_${subMenuHeading}_list_item`}
                      aria-label={formatMessage({ id: subMenuHeading })}
                      noBorder
                    >
                      <MegaMenuLinks
                        subMenuList={subMenu}
                        totalPolicyCount={totalPolicyCount}
                        handleClose={handleClose}
                        langObj={langObj}
                      />
                    </ListElement>
                  )
                );
              })}
            </>
          )
        );
      })}
    </>
  );
};

MegaMenuMobile.propTypes = {
  megaMenuItems: PropTypes.array.isRequired,
  totalPolicyCount: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  langObj: PropTypes.object.isRequired,
  customerSegmentValues: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired
};

MegaMenuMobile.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default MegaMenuMobile;
