import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FullWidthSectionContainer } from '../../common/styles/commonStyle';
import {
  IntroHeaderSection,
  DetailsPageOverviewSection
} from '../../policydetail/detailPageStyle';
import { OverviewStatusNumberWrapper } from '../reprojectionPageStyle';

const PolicyOverviewSection = props => {
  const { fieldsStack, reprojectionsDetails } = props;
  const PolicyStatusField = fieldsStack[0];
  const PolicyNumberField = fieldsStack[2];
  const PolicyNameField = fieldsStack[1];
  /**
   * Policy Tags is been added through Configuration field name policyTags
   * policyTags template is use to display tags
   * in future if any tag need to add it should be
   * added though configuration fields max 4 tags can be added in one field
   * "policyTags": {
      "dependentField": "productCategory"
    }
   * tag will be api driven
   */
  const PolicyTags = fieldsStack[3];
  if (_.isEmpty(reprojectionsDetails)) {
    return null;
  }
  return (
    <FullWidthSectionContainer>
      <DetailsPageOverviewSection>
        <IntroHeaderSection>
          <OverviewStatusNumberWrapper>
            {PolicyStatusField}
            {PolicyNumberField}
          </OverviewStatusNumberWrapper>
          {PolicyNameField}
          {PolicyTags}
        </IntroHeaderSection>
      </DetailsPageOverviewSection>
    </FullWidthSectionContainer>
  );
};

export default PolicyOverviewSection;

PolicyOverviewSection.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  reprojectionsDetails: PropTypes.object
};

PolicyOverviewSection.defaultProps = {
  reprojectionsDetails: {}
};
PolicyOverviewSection.contextTypes = {
  intl: PropTypes.object.isRequired
};
