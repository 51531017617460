import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import {
  SectionWrapper,
  SectionHeaderText,
  SectionImgSpan
} from './SectionStyle';
import {
  DetailsPageMiddleSection,
  FaqHeader,
  FaqBody,
  FaqQues,
  FaqAns,
  ImagePath
} from '../detailPageStyle';
import MessageIcon from '../../assets/images/MessageIcon.svg';
import ToggleSection from '../templates/ToggleSection';

const FaqForLapsed = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { sectionHeading, faqSection, superFamily } = props;
  const faqSectionList = faqSection[superFamily];
  return (
    <DetailsPageMiddleSection>
      {faqSectionList.length > 0 && (
        <SectionWrapper>
          <FaqHeader>
            <SectionImgSpan>
              <ImagePath aria-hidden="true" alt="" src={MessageIcon} />
            </SectionImgSpan>
            <SectionHeaderText id="faq_section">
              {formatMessage({ id: sectionHeading })}
            </SectionHeaderText>
          </FaqHeader>
          <FaqBody>
            {_.map(faqSectionList, (section, i) => {
              return (
                <ToggleSection
                  sectionTitleFormat={[
                    <FaqQues key={`question${i}`}>
                      {formatMessage({
                        id: section.faqQues
                      })}
                    </FaqQues>
                  ]}
                  expandedSectionId={`question${i}`}
                  titleId={`answer${i}`}
                  tealiumEventContent={`question${i + 1}`}
                  staticTealiumContent
                >
                  <FaqAns>
                    <FormattedHTMLMessage id={section.faqAns} />
                  </FaqAns>
                </ToggleSection>
              );
            })}
          </FaqBody>
        </SectionWrapper>
      )}
    </DetailsPageMiddleSection>
  );
};

FaqForLapsed.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  faqSection: PropTypes.object.isRequired,
  superFamily: PropTypes.string.isRequired
};

FaqForLapsed.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FaqForLapsed;
