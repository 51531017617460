import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  DescriptionWrapper,
  PolicyNameHeader,
  PolicyNameValue
} from '../instructionHistoryStyle';
import CancelRequest from './CancelRequest';
import SwitchInvestmentChoices from './SwitchInvestmentChoices';
import ChangePremiumAllocation from './ChangePremiumAllocation';
import WithdrawalPolicyValue from './WithdrawalPolicyValue';
import ChangePaymentInstruction from './ChangePaymentInstruction';
import getDescription from '../../utils/reducerUtils';
import ErrorSection from './ErrorSection';
import FundWithdrawal from './FundWithdrawal';
import UpdateStatementPreference from './UpdateStatementPreference';
import { isWithdrawalTotalFund } from '../instructionUtils';

const checkForDetails = section => {
  return section && section.details;
};

const checkForErrors = section => {
  return section && section.errors;
};

const canShowCancelRequest = (insTitle, insTransCode, insStatus) => {
  return (
    insTitle === 'pending_Request' &&
    insTransCode !== 'TRANSFUND' &&
    _.includes(['1', '2'], insStatus)
  );
};

const detailSection = args => {
  const [
    transCode,
    planName,
    locale,
    policyCurrency,
    policyNumber,
    details,
    formatMessage,
    requestType,
    cancelRequestHandler,
    referenceNo,
    encodePolicyNumber,
    cancelRequestWarningsOrErrors,
    requestTitleToCheck,
    langObj,
    cancelRequestData,
    resetCancelData,
    toggleAccordion,
    status,
    source,
    customerSegment
  ] = args;

  const withdrawalTotalFund = isWithdrawalTotalFund(details);
  const suppressPolicyDetailsFor = ['ESTATMNT'];
  return (
    <>
      <DescriptionWrapper type={requestType}>
        {!_.includes(suppressPolicyDetailsFor, transCode) && (
          <>
            <PolicyNameHeader
              transCode={transCode}
              withdrawalTotalFund={withdrawalTotalFund}
            >
              {formatMessage({ id: 'yourPolicy' })}
            </PolicyNameHeader>
            <PolicyNameValue
              transCode={transCode}
              withdrawalTotalFund={withdrawalTotalFund}
            >
              <FormattedMessage
                id="policyNameLabel"
                values={{
                  planName: getDescription(locale, planName),
                  policyNumber
                }}
              />
            </PolicyNameValue>
          </>
        )}
        {transCode === 'CHANGEPAYMENTINFO' && (
          <ChangePaymentInstruction
            funds={details.paymentInstruction}
            status={status}
          />
        )}
        {transCode === 'CHANGEPREMIUMALLOC' && (
          <ChangePremiumAllocation
            funds={details.premiumAllocation}
            currency={policyCurrency}
            status={status}
          />
        )}
        {transCode === 'TRANSFUND' && (
          <SwitchInvestmentChoices
            funds={details}
            currency={policyCurrency}
            status={status}
            source={source}
          />
        )}
        {transCode === 'WITHDRAWPOLICYVAL' && (
          <WithdrawalPolicyValue
            withdrawPolicy={details.withdrawPolicy}
            status={status}
          />
        )}
        {transCode === 'FUND_WITHDRAW' && (
          <FundWithdrawal
            withdrawPolicy={details.fundWithdrawPolicy}
            status={status}
          />
        )}
        {transCode === 'ESTATMNT' && (
          <UpdateStatementPreference
            changeStatementPreference={details.changeStatementPreference}
          />
        )}
      </DescriptionWrapper>
      {canShowCancelRequest(requestTitleToCheck, transCode, status) && (
        <CancelRequest
          referenceNo={referenceNo}
          encodePolicyNumber={encodePolicyNumber}
          cancelRequestHandler={cancelRequestHandler}
          cancelRequestWarningsOrErrors={cancelRequestWarningsOrErrors}
          cancelRequestData={cancelRequestData}
          resetCancelData={resetCancelData}
          toggleAccordion={toggleAccordion}
          langObj={langObj}
          customerSegment={customerSegment}
        />
      )}
    </>
  );
};

const ServicingOptionsDescription = (props, context) => {
  const {
    policyCurrency,
    planName,
    fetchInstructionDetails,
    encodePolicyNumber,
    policyNumber,
    requestType,
    cancelRequestWarningsOrErrors,
    requestTitleToCheck = '',
    section,
    transCode,
    referenceNo,
    cancelRequestHandler,
    langObj,
    cancelRequestData,
    resetCancelData,
    source,
    toggleAccordion,
    status,
    customerSegment
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;

  if (checkForDetails(section)) {
    const { details } = section;
    return detailSection([
      transCode,
      planName,
      locale,
      policyCurrency,
      policyNumber,
      details,
      formatMessage,
      requestType,
      cancelRequestHandler,
      referenceNo,
      encodePolicyNumber,
      cancelRequestWarningsOrErrors,
      requestTitleToCheck,
      langObj,
      cancelRequestData,
      resetCancelData,
      toggleAccordion,
      status,
      source,
      customerSegment
    ]);
  }
  if (checkForErrors(section)) {
    const { errors } = section;
    return (
      <ErrorSection
        errors={errors}
        fetchInstructionDetails={fetchInstructionDetails}
        encodePolicyNumber={encodePolicyNumber}
        transCode={transCode}
        referenceNo={referenceNo}
        source={source}
      />
    );
  }
  return <></>;
};

ServicingOptionsDescription.propTypes = {
  policyCurrency: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  fetchInstructionDetails: PropTypes.func.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  requestType: PropTypes.string.isRequired,
  cancelRequestWarningsOrErrors: PropTypes.object.isRequired,
  requestTitleToCheck: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  transCode: PropTypes.string.isRequired,
  referenceNo: PropTypes.string.isRequired,
  cancelRequestHandler: PropTypes.func.isRequired,
  langObj: PropTypes.object.isRequired,
  cancelRequestData: PropTypes.func.isRequired,
  resetCancelData: PropTypes.func.isRequired,
  toggleAccordion: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  customerSegment: PropTypes.string.isRequired
};

ServicingOptionsDescription.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ServicingOptionsDescription;
