import React, { useState } from 'react';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import ChartLoader from '../common/loader/ChartLoader';
import { isMiniMobile } from '../utils/domUtils';
import {
  ColumnChartDiv,
  CustomTooltip,
  LoaderDiv
} from '../reprojection/reprojectionPageStyle';

import { trackEvents } from '../utils/tealiumUtils';
import { getTooltipContents, xAxisLabelFrequncyHandler } from './chartUtils';
import {
  captureChartEvents,
  chartDataForTealium
} from '../reprojection/utils/reprojectionTealiumHandlers';

const canShowTooltip = (row, pos) => {
  return typeof row === 'number' && pos.x !== 0 && pos.y !== 0;
};

const getLeftTooltipPos = (pos, tooltipPosition) =>
  pos.x + tooltipPosition.clientWidth + 10 < document.body.clientWidth
    ? `${pos.x}px`
    : `${document.body.clientWidth - 40 - tooltipPosition.clientWidth}px`;

const getTopTooltipPos = (pos, tooltipPosition) =>
  pos.y + tooltipPosition.clientHeight + 10 < document.body.clientHeight
    ? `${pos.y + 20}px`
    : `${document.body.clientHeight + 5 - tooltipPosition.clientHeight}px`;

const {
  columnChart: { chartEventType }
} = chartDataForTealium;

const ColumnCharts = props => {
  const {
    data,
    setValue,
    setInputValue,
    vAxisTitle,
    hAxisTitle,
    hAxisPrefix,
    showTooltip,
    showHorizontalGridLines,
    showVerticalGridLines,
    showStacked,
    value,
    setLeft,
    vAxisTicks,
    tooltipId,
    chartFields,
    rawData,
    policyCurrency,
    xAxisField,
    maxAllowedVal
  } = props;

  const chartDimension = {
    width: '100%',
    paddingLeft: isMiniMobile() ? '63' : '75',
    paddingRight: isMiniMobile() ? '28' : '45',
    height: isMiniMobile() ? '250px' : '404px'
  };
  const getGridLines = showGridLines =>
    showGridLines ? { count: 6 } : { color: 'transparent' };

  const ticks = xAxisLabelFrequncyHandler(data.length - 1, data);

  const [age, setAge] = useState(0);

  const pos = {
    x: 0,
    y: 0
  };

  const setPosition = e => {
    pos.x = e.clientX;
    pos.y = e.clientY;
  };

  const content = getTooltipContents(
    data,
    rawData,
    chartFields,
    age,
    '',
    xAxisField,
    policyCurrency,
    tooltipId
  );

  return (
    <>
      <ColumnChartDiv
        id="container"
        onMouseOver={e => setPosition(e)}
        onFocus={e => setPosition(e)}
      >
        <Chart
          height={chartDimension.height}
          chartType="ColumnChart"
          data={data}
          loader={
            <LoaderDiv>
              <ChartLoader height={chartDimension.height} />
            </LoaderDiv>
          }
          options={{
            hAxis: {
              title: hAxisTitle,
              format: hAxisPrefix,
              ticks,
              gridlines: getGridLines(showVerticalGridLines)
            },
            vAxis: {
              title: vAxisTitle,
              gridlines: getGridLines(showHorizontalGridLines),
              ticks: vAxisTicks
            },
            chartArea: {
              backgroundColor: {
                fill: '#fff'
              },
              width: chartDimension.width,
              left: chartDimension.paddingLeft,
              right: chartDimension.paddingRight
            },
            tooltip: {
              trigger: showTooltip ? true : 'none'
            },
            isStacked: showStacked,
            focusTarget: 'category',
            legend: { position: 'none' }
          }}
          rootProps={{ 'data-testid': '1' }}
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                const tooltipPosition = document.getElementById(tooltipId);
                const layoutInterface = chartWrapper
                  .getChart()
                  .getChartLayoutInterface();
                const x = layoutInterface.getXLocation(value);
                setLeft(x);
                const selectionHandler = function selectionHandler() {
                  if (chart.getSelection()[0]) {
                    const { row } = chart.getSelection()[0];
                    captureChartEvents(
                      data[row + 1][0],
                      maxAllowedVal,
                      trackEvents,
                      tooltipId,
                      chartEventType
                    );

                    setValue(data[row + 1][0]);
                    setInputValue(data[row + 1][0]);
                    chart.setSelection([]);
                  }
                };
                google.visualization.events.addListener(
                  chart,
                  'select',
                  selectionHandler
                );

                const mouseOverHandler = function mouseOverHandler(e) {
                  const { row } = e;
                  if (canShowTooltip(row, pos)) {
                    setAge(row);
                    tooltipPosition.style.display = 'block';
                    tooltipPosition.style.left = getLeftTooltipPos(
                      pos,
                      tooltipPosition
                    );
                    tooltipPosition.style.top = getTopTooltipPos(
                      pos,
                      tooltipPosition
                    );
                  }
                };
                google.visualization.events.addListener(
                  chart,
                  'onmouseover',
                  mouseOverHandler
                );

                const chartEventListener = function chartEventListener() {
                  tooltipPosition.style.display = 'none';
                  tooltipPosition.style.left = 'none';
                  tooltipPosition.style.top = 'none';
                  chart.setSelection([]);
                };
                google.visualization.events.addListener(
                  chart,
                  'onmouseout',
                  chartEventListener
                );
                google.visualization.events.addListener(
                  chart,
                  'scroll',
                  chartEventListener
                );

                google.visualization.events.addListener(
                  chart,
                  'touchmove',
                  chartEventListener
                );

                // handle scroll event for full page
                const scrollListener = function scrollListener() {
                  tooltipPosition.style.display = 'none';
                  tooltipPosition.style.left = 'none';
                  tooltipPosition.style.top = 'none';
                  chart.setSelection([]);

                  document.removeEventListener('scroll', scrollListener, true);
                };
                document.addEventListener('scroll', scrollListener, true);
                document.addEventListener('touchmove', scrollListener, true);
              }
            }
          ]}
        />
      </ColumnChartDiv>
      <CustomTooltip id={tooltipId}>{content}</CustomTooltip>
    </>
  );
};

ColumnCharts.propTypes = {
  data: PropTypes.array.isRequired,
  rawData: PropTypes.array.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  maxAllowedVal: PropTypes.number.isRequired,
  setLeft: PropTypes.func.isRequired,
  tooltipId: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  showVerticalGridLines: PropTypes.bool,
  showHorizontalGridLines: PropTypes.bool,
  vAxisTitle: PropTypes.string,
  hAxisTitle: PropTypes.string,
  hAxisPrefix: PropTypes.string,
  showStacked: PropTypes.bool,
  vAxisTicks: PropTypes.array,
  chartFields: PropTypes.array,
  xAxisField: PropTypes.string
};
ColumnCharts.defaultProps = {
  vAxisTitle: '',
  hAxisTitle: '',
  hAxisPrefix: '',
  showTooltip: false,
  showVerticalGridLines: false,
  showHorizontalGridLines: true,
  showStacked: true,
  vAxisTicks: [],
  chartFields: null,
  xAxisField: ''
};
export default ColumnCharts;
