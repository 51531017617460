import React from 'react';
import PropTypes from 'prop-types';
import {
  ReprojectionImageSectionWrapper,
  ImageSectionDiv,
  ReprojectionImage,
  ReprojectionImageContent,
  ReprojectionImageContentHeader,
  ReprojectionImageContentBody
} from './reprojectionPageStyle';
import ReprojectionSectionImage from '../assets/images/ReprojectionImage.jpg';

const ReprojectionImageSection = (props, context) => {
  const { tabId } = props;
  const imageSectionBodyKey = `reprojectionImageBody_${tabId}`;
  const {
    intl: { formatMessage }
  } = context;
  return (
    <ReprojectionImageSectionWrapper>
      <ImageSectionDiv>
        <ReprojectionImage
          src={ReprojectionSectionImage}
          alt="ReprojectionSectionImage"
        />
        <ReprojectionImageContent>
          <ReprojectionImageContentHeader>
            {formatMessage({ id: 'reprojectionImageHeader' })}
          </ReprojectionImageContentHeader>
          <ReprojectionImageContentBody>
            {formatMessage({ id: imageSectionBodyKey })}
          </ReprojectionImageContentBody>
        </ReprojectionImageContent>
      </ImageSectionDiv>
    </ReprojectionImageSectionWrapper>
  );
};
ReprojectionImageSection.propTypes = {
  tabId: PropTypes.string.isRequired
};

ReprojectionImageSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ReprojectionImageSection;
