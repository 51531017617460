import React from 'react';
import PropTypes from 'prop-types';
import {
  ContactInfoWrapper,
  ContactInfoHeader,
  ContactImgWrapper,
  ImgStyle,
  ContactInfoLabel,
  ContactCardBody,
  ContactCardLeft,
  ContactCardRight,
  ContactCardImageGeneral,
  ContactCardDetailsGeneralWrapper,
  ContactCardImageGeneralWrapper,
  ContactCardText
} from './contactInfoStyles';
import { DetailsPageMiddleSection, ImagePath } from '../detailPageStyle';
import Contactphone from '../../assets/images/Contactphone.svg';
import ContactFallBack from '../../assets/images/contact_fallback.svg';

const ExceptionalContactCard = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { fieldsStack, sectionHeading, isFor } = props;

  return (
    <DetailsPageMiddleSection>
      <ContactInfoWrapper isFor={isFor}>
        <ContactInfoHeader>
          <ContactImgWrapper>
            <ImgStyle aria-hidden="true" alt="" src={Contactphone} />
          </ContactImgWrapper>
          <ContactInfoLabel id="contact_card_title">
            {formatMessage({ id: sectionHeading })}
          </ContactInfoLabel>
        </ContactInfoHeader>
        <ContactCardText>
          {formatMessage({ id: 'contactCardText' })}
        </ContactCardText>
        <ContactCardBody>
          <ContactCardLeft>
            <ContactCardImageGeneralWrapper>
              <ContactCardImageGeneral>
                <ImagePath
                  aria-hidden="true"
                  alt={formatMessage({ id: 'decorativeImage' })}
                  src={ContactFallBack}
                />
              </ContactCardImageGeneral>
            </ContactCardImageGeneralWrapper>
          </ContactCardLeft>
          <ContactCardRight>
            <ContactCardDetailsGeneralWrapper>
              {fieldsStack}
            </ContactCardDetailsGeneralWrapper>
          </ContactCardRight>
        </ContactCardBody>
      </ContactInfoWrapper>
    </DetailsPageMiddleSection>
  );
};

ExceptionalContactCard.propTypes = {
  fieldsStack: PropTypes.array.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  isFor: PropTypes.string
};

ExceptionalContactCard.defaultProps = {
  isFor: ''
};

ExceptionalContactCard.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ExceptionalContactCard;
