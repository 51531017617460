import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import {
  TransactionWrapper,
  TransactionSubheader,
  TransactionContactText,
  TransactionModalBody,
  TransactionModalLeft,
  TransactionModalRight,
  TransactionModalImage,
  ContactCardDetailsWrapper,
  ContactNumber,
  ContactDay,
  HeaderText,
  ImagePath,
  ImgStyle
} from './transactionModalStyles';
import warningIcon from '../../../assets/images/warning-colour.svg';
import Contactphone from '../../../assets/images/Contactphone.svg';
import ContactFallBack from '../../../assets/images/illustraion-generic-help.svg';
import { trackViews } from '../../../utils/tealiumUtils';
import { getPopupContent } from '../../sectionUtils/FundUtils';
import { doOR } from '../../../common/util/commonUtil';

function TransactionModal(props, context) {
  const {
    intl: { formatMessage }
  } = context;

  const {
    isModalOpen,
    onClose,
    reasonServiceUnavailable,
    pendingTransactions,
    isDDNotConfirmed
  } = props;

  trackViews({
    viewName: 'onTransactionModalLoad',
    reasonServiceUnavailable
  });

  const popupContent = getPopupContent(pendingTransactions);
  const isTransaction = doOR(pendingTransactions, isDDNotConfirmed);

  return (
    <ModalPopUp
      hideButton
      isModalOpen={isModalOpen}
      onClose={onClose}
      modalHeading={formatMessage({ id: `${reasonServiceUnavailable}Heading` })}
      id={isTransaction ? 'PendingModalStyle' : 'ModalWithoutScroll'}
    >
      <TransactionWrapper id="TransactionInfoGeneralCard">
        {!isTransaction && (
          <>
            <TransactionSubheader>
              <ImgStyle
                alt={formatMessage({ id: 'aria_infotext' })}
                src={warningIcon}
              />
              <FormattedHTMLMessage
                id={`${reasonServiceUnavailable}_sub_heading`}
              />
            </TransactionSubheader>
          </>
        )}
        {isTransaction && (
          <>
            <TransactionSubheader>
              <ImgStyle
                alt={formatMessage({ id: 'aria_infotext' })}
                src={warningIcon}
              />
              <FormattedHTMLMessage
                id={`${reasonServiceUnavailable}_sub_heading`}
              />
            </TransactionSubheader>
            <TransactionContactText pendingTransactions={isTransaction}>
              {formatMessage({ id: popupContent.whatCanYouHeader })}
            </TransactionContactText>
            <TransactionSubheader>
              <FormattedHTMLMessage id={popupContent.infoText} />
            </TransactionSubheader>
          </>
        )}

        <TransactionContactText>
          <ImgStyle aria-hidden="true" alt="" src={Contactphone} />
          {formatMessage({ id: 'contact_us_header' })}
        </TransactionContactText>
        <TransactionModalBody>
          <TransactionModalLeft isGeneral>
            <TransactionModalImage>
              <ImagePath
                aria-hidden="true"
                alt={formatMessage({ id: 'decorativeImage' })}
                src={ContactFallBack}
              />
            </TransactionModalImage>
          </TransactionModalLeft>
          <TransactionModalRight isGeneral>
            <ContactCardDetailsWrapper>
              <HeaderText>
                {formatMessage({ id: popupContent.contactLabel })}
              </HeaderText>
              <ContactNumber>
                {formatMessage({ id: 'policyGeneralInfo_Life_Contact_Number' })}
              </ContactNumber>
              <ContactDay>
                {formatMessage({
                  id: 'policyGeneralInfo_Life_Contact_Details'
                })}
              </ContactDay>
            </ContactCardDetailsWrapper>
          </TransactionModalRight>
        </TransactionModalBody>
      </TransactionWrapper>
    </ModalPopUp>
  );
}

TransactionModal.contextTypes = {
  intl: PropTypes.object.isRequired
};

TransactionModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reasonServiceUnavailable: PropTypes.any.isRequired,
  pendingTransactions: PropTypes.bool.isRequired,
  isDDNotConfirmed: PropTypes.bool.isRequired
};

export default TransactionModal;
