import React from 'react';
import PropTypes from 'prop-types';
import {
  ReturnValue,
  ReturnsValueWrapper,
  ReturnsValueLeft,
  ReturnsValueRight,
  SubHeaderReturns
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import { returnsHeaderResolver } from '../../sectionUtils/ReturnsUtils';

const ReturnsTotalPolicyValue = (props, context) => {
  const {
    sectionHeading,
    labelContent,
    fieldData,
    dependentFieldData,
    policyCurrency,
    toolTipContent,
    productCategory,
    annuityPhaseIndicator,
    dependentContentData
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  let fieldValue = '';
  if (!labelContent || typeof fieldData !== 'object') {
    return <></>;
  }

  if (dependentFieldData) {
    fieldValue = 'GCV';
  }

  const returnsTotalSubTitleKey = returnsHeaderResolver(
    annuityPhaseIndicator,
    productCategory,
    'TotalSubTitle'
  );

  const titleId =
    fieldValue === 'GCV' || dependentContentData
      ? labelContent
      : returnsTotalSubTitleKey;

  return (
    <ReturnValue
      fieldValue={fieldValue}
      dependentContentData={dependentContentData}
    >
      <ReturnsValueWrapper
        id={labelContent}
        fieldValue={fieldValue}
        dependentContentData={dependentContentData}
      >
        <ReturnsValueLeft fieldValue={fieldValue}>
          {formatMessage({ id: titleId })}
          <TooltipWrapper
            id={`tooltip_${labelContent}`}
            contentKey={toolTipContent}
            labelContent={labelContent}
            sectionHeading={sectionHeading}
            maxWidth={300}
          />
        </ReturnsValueLeft>
        <ReturnsValueRight fieldValue={fieldValue}>
          <Currency
            currencyCode={(fieldData && fieldData.currency) || policyCurrency}
            amount={(fieldData && fieldData.amount) || 0}
          />
        </ReturnsValueRight>
      </ReturnsValueWrapper>
      {dependentContentData && (
        <SubHeaderReturns>
          {formatMessage({ id: dependentContentData })}
        </SubHeaderReturns>
      )}
    </ReturnValue>
  );
};

ReturnsTotalPolicyValue.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  policyCurrency: PropTypes.string,
  dependentFieldData: PropTypes.object,
  toolTipContent: PropTypes.string,
  annuityPhaseIndicator: PropTypes.string,
  productCategory: PropTypes.string,
  dependentContentData: PropTypes.string
};

ReturnsTotalPolicyValue.contextTypes = {
  intl: PropTypes.object.isRequired
};
ReturnsTotalPolicyValue.defaultProps = {
  policyCurrency: '',
  dependentFieldData: null,
  toolTipContent: null,
  annuityPhaseIndicator: '',
  productCategory: '',
  dependentContentData: ''
};
export default ReturnsTotalPolicyValue;
