import React from 'react';
import PropTypes from 'prop-types';
import yesIcon from '../../../assets/images/yes.svg';
import getDescription from '../../../utils/reducerUtils';
import BasicPlanOptionLayout from './BasicPlanOptionLayout';

const BasicPlanOption = (props, context) => {
  const {
    intl,
    intl: { formatMessage, locale }
  } = context;

  const {
    fieldData,
    dependentContentData,
    labelContent,
    dependentFieldData
  } = props;
  let contentDescription = '';
  if (
    labelContent &&
    !!intl.messages[labelContent] &&
    intl.messages[labelContent] !== ''
  ) {
    contentDescription = formatMessage({ id: labelContent });
  } else if (
    fieldData &&
    fieldData.narrative &&
    fieldData.narrative.length > 0
  ) {
    contentDescription = getDescription(locale, fieldData.narrative);
  } else {
    contentDescription = getDescription(locale, fieldData);
  }
  if (!dependentFieldData || !contentDescription) {
    return <></>;
  }
  return (
    <BasicPlanOptionLayout
      id={labelContent}
      srcIcon={yesIcon}
      altText="yes-icon"
      dependentContentData={dependentContentData}
      contentDescription={contentDescription}
      formatMessage={formatMessage}
    />
  );
};

BasicPlanOption.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object,
  dependentContentData: PropTypes.string,
  dependentFieldData: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

BasicPlanOption.defaultProps = {
  dependentContentData: '',
  dependentFieldData: false,
  fieldData: null
};
BasicPlanOption.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BasicPlanOption;
