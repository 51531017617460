import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';

/**
 *
 * @param {*} props
 *          date : date fetched from response
 *          formatType : object contaning year month day format (i.e {yrType: 'YYYY',monType: 'MM',dayType:'DD'})
 *
 * getDateReplaceInCopyObject returns a dateObj in format {year:<yrType>,month :<monType>,day:<dayType>} which is passed to copy content
 * eg : getDateReplaceInCopyObject('2019-05-19',{yrType:'YYYY',monType:'MMM',dayType:'DD'}) returns {year:'2019',month:'May',day:19}
 * moment('13-05-2019','YYYY-MM-DD',true).isValid() returns false ( true flag is for strict checking)
 *
 */
export const getDateReplaceInCopyObject = (date, formatType) => {
  const { yrType, monType, dayType } = formatType;
  const dateObj = { year: '', month: '', day: '', hour: '', min: '' };
  // expected timezone format is 'YYYY-MM-DDTHH:mm:ss.sssZ'
  const [yrMonDay, time] = date.split('T');
  const [hrMinSec] = time.split('.');
  const dateToParse = `${yrMonDay} ${hrMinSec}`;
  if (moment(dateToParse).isValid()) {
    dateObj.year = moment(dateToParse).format(yrType);
    dateObj.month = moment(dateToParse).format(monType);
    dateObj.day = moment(dateToParse).format(dayType);
    dateObj.hour = moment(dateToParse).format('HH');
    dateObj.min = moment(dateToParse).format('mm');
  }
  return dateObj;
};

const CustomDate = ({
  date,
  id = 'defaultDateFormat',
  dateFormatAsPerLocale,
  locale = 'en'
}) => {
  const dateFormatType =
    dateFormatAsPerLocale && dateFormatAsPerLocale[locale]
      ? dateFormatAsPerLocale[locale]
      : { yrType: 'YYYY', monType: 'MMM', dayType: 'DD' };

  const dateReplaceInCopyObject = getDateReplaceInCopyObject(
    date,
    dateFormatType
  );
  /**
   *  check if  dateReplaceInCopyObject returns valid date obj containing values for years,months and day ,
   *  if yes then pass it to copy , if not then pass the date returned from api to copy.
   */
  const dateToReplace = _.values(dateReplaceInCopyObject).every(_.isEmpty)
    ? { dateFormat: date }
    : dateReplaceInCopyObject;
  return (
    <>
      <FormattedHTMLMessage
        defaultMessage={`${date}`}
        id={date ? id : 'defaultDateFormat'}
        values={dateToReplace}
      />
    </>
  );
};

CustomDate.contextTypes = {
  intl: PropTypes.object.isRequired
};
CustomDate.propTypes = {
  date: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dateFormatAsPerLocale: PropTypes.object.isRequired
};

export default CustomDate;
