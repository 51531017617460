import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import BeneIcon from '../../assets/images/multiuser.svg';
import {
  handleClickChangeBeneficiary,
  getNonPersonalRelationshipStatus,
  conditionForChangeBeneficiary,
  conditionForentityCode
} from '../../utils/BeneficiaryUtils';
import BeneficiarySectionModal from './beneficiarySection/BeneficiarySectionModal';
import ClaimWrapper from './ClaimWrapper';

const ChangeBeneficiary = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    currentPolicyDetail,
    encodedProductId,
    changeBeneficiaryService,
    displayQuickLink,
    productConfig,
    summaryResponse
  } = props;

  const [isModalOpen, updateModalState] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [irrevocableAssigned, setIrrevocableAssigned] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [isNonPersonalRelation, setPersonalRelation] = useState(false);
  const linkBene = React.createRef();
  const link = ``;

  useEffect(() => {
    if (currentPolicyDetail) {
      setPersonalRelation(
        getNonPersonalRelationshipStatus(
          currentPolicyDetail?.beneficiaryDetails?.primaryBeneficiaries
        )
      );
      if (
        _.has(
          currentPolicyDetail?.policyOverview,
          'irrevocableBeneficiaryIndicator'
        )
      ) {
        setIrrevocableAssigned(
          currentPolicyDetail?.policyOverview?.irrevocableBeneficiaryIndicator
        );
      }
      if (
        _.has(currentPolicyDetail?.policyOverview, 'assignedPolicyIndicator')
      ) {
        setAssigned(
          currentPolicyDetail?.policyOverview?.assignedPolicyIndicator
        );
      }
    }
  }, [currentPolicyDetail]);

  useEffect(() => {
    const { productCategory } =
      currentPolicyDetail?.policyOverview ?? summaryResponse?.productCategory;
    if (productCategory) {
      const checkForEntityCode = conditionForentityCode(
        currentPolicyDetail?.policyOverview?.entityCode ?? ''
      );
      setIsDisplay(
        conditionForChangeBeneficiary(productCategory, productConfig) !== -1 &&
          checkForEntityCode
      );
    }
  }, [currentPolicyDetail, productConfig]);

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
  };

  const handleClick = e => {
    handleClickChangeBeneficiary(
      e,
      changeBeneficiaryService,
      encodedProductId,
      irrevocableAssigned,
      assigned,
      isNonPersonalRelation
    );
    updateModalState(!isModalOpen);
  };

  const returnLink =
    displayQuickLink && isDisplay ? (
      <>
        <ClaimWrapper
          id="ChangeBeneficiary"
          ariaLabel={formatMessage({ id: 'ChangeBeneficiary' })}
          onClick={e => handleClick(e)}
          altText="changeBene-icon"
          iconSrc={BeneIcon}
          href={link}
          linkLabel={formatMessage({ id: 'ChangeBeneficiary' })}
          linkRef={linkBene}
        />

        <BeneficiarySectionModal
          id="beneModal"
          isModalOpen={isModalOpen}
          onCancelClick={onCancelClick}
          irrevocableAssigned={irrevocableAssigned}
          assigned={assigned}
          encodedProductId={encodedProductId}
          isNonPersonalRelation={isNonPersonalRelation}
        />
      </>
    ) : null;
  return <>{returnLink}</>;
};

ChangeBeneficiary.contextTypes = {
  intl: PropTypes.object.isRequired
};

ChangeBeneficiary.defaultProps = {
  currentPolicyDetail: null,
  productConfig: null,
  changeBeneficiaryService: null,
  displayQuickLink: false,
  summaryResponse: null
};

ChangeBeneficiary.propTypes = {
  currentPolicyDetail: PropTypes.objectOf(PropTypes.any),
  changeBeneficiaryService: PropTypes.objectOf(PropTypes.any),
  productConfig: PropTypes.objectOf(PropTypes.any),
  encodedProductId: PropTypes.string.isRequired,
  summaryResponse: PropTypes.objectOf(PropTypes.any),
  displayQuickLink: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    configurations: {
      genericConfig: {
        servicingOptions: {
          digiService: { ChangeBeneficiaryPIB }
        }
      }
    }
  } = state;
  return {
    changeBeneficiaryService: ChangeBeneficiaryPIB,
    displayQuickLink:
      state?.configurations?.detailsConfig?.BeneficiariesListInfo?.display ??
      false,
    productConfig:
      state?.configurations?.productConfig?.subProductFamilies ?? null
  };
};

export default connect(mapStateToProps, null)(ChangeBeneficiary);
