import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ParagraphDiv } from '../../../common/styles/modalStyles';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import { ButtonLinkStyle } from '../../../common/customButton/CustomButtonStyledComp';
import { FundIconWrapper, ImagePath } from '../../detailPageStyle';
import {
  RiskLevelImgSpan,
  ModalDescWrapper,
  ModalNoteWrapper,
  ListDescLevel,
  ListLevelDesc,
  ModalListSection,
  NoteText,
  ModalListHeaderSection,
  ModalListDescWrapper,
  RiskLableWrapper,
  RiskTypeColor,
  RiskListType,
  RiskListTypeText
} from '../../sections/SectionStyle';
import newModal from '../../../assets/images/Newmodal.svg';
import { FundsRiskLevelDataList } from './FundsRiskLevelDataList';
import {
  trackEvents,
  createEventActionContent
} from '../../../utils/tealiumUtils';
import getContentInSpecificLang from '../../../utils/localeUtils';

const FundsRiskLevelDetails = (props, context) => {
  const { langObj } = props;

  const {
    intl: { formatMessage }
  } = context;
  const [isModalOpen, updateModalState] = useState(false);
  const footerButtonList = [
    {
      className: 'redBtn',
      label: formatMessage({ id: 'cancelPopup' }),
      id: 'cancel_btn',
      align: 'right'
    }
  ];

  const fundRiskLevelKeyLangSpecific = getContentInSpecificLang(
    'riskLevelsLink',
    'en',
    langObj
  );

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
    trackEvents({
      eventName: 'popup',
      eventContent: fundRiskLevelKeyLangSpecific.toLowerCase()
    });
  };
  const eventAction = 'onsite';

  const sectionName = 'fund details';
  let eventData = {};
  eventData = createEventActionContent(
    eventAction,
    fundRiskLevelKeyLangSpecific,
    sectionName
  );
  return (
    <>
      <ButtonLinkStyle
        type="button"
        id="riskLevelsLink"
        ariaLabel={formatMessage({ id: 'riskLevelsLink' })}
        onClick={() => {
          updateModalState(!isModalOpen);
          trackEvents({
            eventName: 'updateContactInformation', // reusing same eventName
            eventData
          });
        }}
      >
        <FundIconWrapper>
          <RiskLevelImgSpan>
            <ImagePath aria-hidden="true" alt="" src={newModal} />
          </RiskLevelImgSpan>
        </FundIconWrapper>

        {formatMessage({ id: 'riskLevelsLink' })}
      </ButtonLinkStyle>
      <ModalPopUp
        isModalOpen={isModalOpen}
        onClose={onCancelClick}
        modalHeading={formatMessage({
          id: 'riskLevelModalHeader'
        })}
        footerButtonList={footerButtonList}
        btnHandler={{
          cancel_btn: onCancelClick
        }}
      >
        <ParagraphDiv>
          <ModalDescWrapper>
            <ModalNoteWrapper>
              <NoteText>
                {formatMessage({
                  id: 'riskLevelModal_HeaderNote'
                })}
              </NoteText>
            </ModalNoteWrapper>
            <ModalListHeaderSection>
              <ModalListDescWrapper>
                <ListDescLevel>
                  {formatMessage({
                    id: 'riskLevels'
                  })}
                </ListDescLevel>
                <ListLevelDesc>
                  {formatMessage({
                    id: 'riskLevels_Descriptions'
                  })}
                </ListLevelDesc>
              </ModalListDescWrapper>

              {FundsRiskLevelDataList.map(field => (
                <ModalListSection key={field}>
                  <RiskListType>
                    <RiskLableWrapper>
                      <RiskTypeColor riskTypeColor={field.riskLevelColor}>
                        {field.riskLevelRating}
                      </RiskTypeColor>
                      {formatMessage({
                        id: field.riskLevel
                      })}
                    </RiskLableWrapper>
                  </RiskListType>
                  <RiskListTypeText>
                    {formatMessage({
                      id: field.riskDescription
                    })}
                  </RiskListTypeText>
                </ModalListSection>
              ))}
            </ModalListHeaderSection>
          </ModalDescWrapper>
        </ParagraphDiv>
      </ModalPopUp>
    </>
  );
};

export default FundsRiskLevelDetails;

FundsRiskLevelDetails.propTypes = {
  langObj: PropTypes.object.isRequired
};

FundsRiskLevelDetails.contextTypes = {
  intl: PropTypes.object.isRequired
};
