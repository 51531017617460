import * as _ from 'lodash';

export const checkCustomerSegment = function checkCustomerSegment(
  customerObj,
  customerSegmentValuesArray
) {
  const { customerSegment = '' } = customerObj;
  return (
    customerSegment && _.includes(customerSegmentValuesArray, customerSegment)
  );
};

const validateCustomerSegment = {
  checkCustomerSegment
};
export default validateCustomerSegment;
