import {
  SET_DOCUMENTS_ERRORS_WARNINGS,
  SET_POLICY_DOCUMENTS_DETAILS,
  GET_POLICY_DOCUMENTS_DETAILS,
  RESET_DOCUMENTS_ERRORS_WARNINGS,
  RESET_DOCUMENTS_DETAILS
} from '../actionTypes';

export const INITIAL_STATE = {
  documentDetails: {},
  documentsWarningsOrErrors: {}
};

const policyDocumentsReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case GET_POLICY_DOCUMENTS_DETAILS: {
      return {
        ...state,
        payload
      };
    }
    case SET_POLICY_DOCUMENTS_DETAILS: {
      const { currentIdentifier, documentData } = payload;
      const { documentDetails } = state;
      return {
        ...state,
        currentIdentifier,
        documentDetails: {
          ...documentDetails,
          ...documentData
        }
      };
    }
    case SET_DOCUMENTS_ERRORS_WARNINGS: {
      const warnings = {
        ...state.documentsWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        documentsWarningsOrErrors: warnings
      };
    }
    case RESET_DOCUMENTS_ERRORS_WARNINGS: {
      return {
        ...state,
        documentsWarningsOrErrors: {}
      };
    }
    case RESET_DOCUMENTS_DETAILS: {
      return {
        ...state,
        currentIdentifier: '',
        documentDetails: {}
      };
    }

    default:
      return state;
  }
};

export default policyDocumentsReducer;
