import styled from 'styled-components';
import { rem } from 'polished';
import theme from '../common/styles/theme';

export const ProgressBarDiv = styled.div`
  width: 100%;
  background-color: ${theme.lightGreyColor};
  height: ${rem('15px')};
  display: flex;
  flex-direction: row;
`

export const ColouredProgressDiv = styled.div`
  background-color: ${theme.greenSuccess};
  width : ${props => `${props.width}%`};
  height: 100%;
`

export const EmptyProgressDiv = styled.div`
  width: ${props => props.showWidth ? `${rem('2px')}` : 0};
  background-color: ${props => props.showWidth ? 'white' : 'none'}
`

export const TransparentDiv = styled.div`
  width : ${props => `${props.width}%`};
`