import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLinkStyle } from '../../../common/customButton/CustomButtonStyledComp';
import {
  FundIconWrapper,
  ImagePath,
  FundsLinkWrapper
} from '../../detailPageStyle';
import { RiskLevelImgSpan } from '../../sections/SectionStyle';
import fundsPerformanceLinkIcon from '../../../assets/images/NewWindow.svg';

const FundsPerformanceLink = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const { updateModal, tealiumEvent } = props;

  return (
    <>
      <FundsLinkWrapper>
        <ButtonLinkStyle
          type="button"
          id="fundsPerformanceLink"
          ariaLabel={formatMessage({ id: 'fundsPerformanceLink' })}
          onClick={() => {
            updateModal();
            tealiumEvent();
          }}
        >
          <FundIconWrapper>
            <RiskLevelImgSpan>
              <ImagePath
                aria-hidden="true"
                alt=""
                src={fundsPerformanceLinkIcon}
              />
            </RiskLevelImgSpan>
          </FundIconWrapper>
          {formatMessage({ id: 'fundsPerformanceLink' })}
        </ButtonLinkStyle>
      </FundsLinkWrapper>
    </>
  );
};

export default FundsPerformanceLink;

FundsPerformanceLink.contextTypes = {
  intl: PropTypes.object.isRequired
};

FundsPerformanceLink.propTypes = {
  updateModal: PropTypes.func.isRequired,
  tealiumEvent: PropTypes.func.isRequired
};
