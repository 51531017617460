import ChangeInPremiumPaymentInstruction from './changePayment.svg';
import ChangeInPremiumAllocation from './changePremium.svg';
import TransferOfFunds from './transferFunds.svg';
import DividendOrCashBonusWithdrawal from './withdraw.svg';
import amendPlan from './amend.svg';
import upgradePlan from './upgrade.svg';
import ClaimHistory from './claimHistory.svg';
import InstructionHistory from './instructionHistory.svg';
import ViewHospitalNetworkProviders from './network_hospital.svg';

const TransferOfFundsPIB = TransferOfFunds;
const TransferOfFundsP2G = TransferOfFunds;

export {
  ChangeInPremiumPaymentInstruction,
  ChangeInPremiumAllocation,
  TransferOfFundsPIB,
  TransferOfFundsP2G,
  DividendOrCashBonusWithdrawal,
  amendPlan,
  upgradePlan,
  ClaimHistory,
  InstructionHistory,
  ViewHospitalNetworkProviders
};
