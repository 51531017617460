import {
  SET_INSTRUCTION_HISTORY,
  SET_INSTRUCTION_HISTORY_ERRORS_WARNINGS,
  SET_INSTRUCTION_HISTORY_DETAIL_ERROR
} from '../actionTypes';

export const INITIAL_STATE = {
  instHistoryResponse: {},
  instHistoryWarningsOrErrors: []
};

const instructionHistoryReducer = (
  state = INITIAL_STATE,
  { type, payload } = {}
) => {
  switch (type) {
    case SET_INSTRUCTION_HISTORY: {
      return {
        ...state,
        ...payload
      };
    }
    case SET_INSTRUCTION_HISTORY_DETAIL_ERROR: {
      return {
        ...state,
        instHistoryResponse: payload
      };
    }
    case SET_INSTRUCTION_HISTORY_ERRORS_WARNINGS: {
      const instHistoryWarningsOrErrors = {
        ...state.instHistoryWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        instHistoryWarningsOrErrors
      };
    }
    default:
      return state;
  }
};

export default instructionHistoryReducer;
