import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMiniMobile } from '../domUtils';
import ShowIf from './ShowIfUtil';

export default class ShowIfMobile extends Component {
  state = {
    windowHeight: undefined,
    windowWidth: undefined
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    });

  render() {
    const { condition } = this.props;
    const { windowHeight, windowWidth } = this.state;
    const mergedCondition = condition && isMiniMobile();
    return (
      <ShowIf
        {...this.props}
        condition={mergedCondition}
        windowHeight={windowHeight}
        windowWidth={windowWidth}
      />
    );
  }
}

ShowIfMobile.propTypes = {
  condition: PropTypes.bool
};

ShowIfMobile.defaultProps = {
  condition: true
};
