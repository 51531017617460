import React from 'react';
import PropTypes from 'prop-types';
import {
  NotificationMessageIcon,
  NotificationMessageType,
  NotificationMessage,
  NotificationIconSpan,
  ImagePath
} from '../../common/styles/commonStyle';
import { AlertWrapper } from '../detailPageStyle';
import { trackViews } from '../../utils/tealiumUtils';
import ContactLink from '../../common/contactLink/contactLink';

import * as icons from '../../assets/images/NotificationIcons';

const AlertIndicator = function AlertIndicator(props, context) {
  const { alerts, superFamily, position } = props;
  const {
    intl: { formatMessage }
  } = context;

  const listAllAlerts = alert => {
    const { fields } = alert;
    return fields.map(field => {
      const labelContent = `${field.fieldLabel}_${superFamily}`;
      if (position === 'pageLevel') {
        trackViews({
          viewName: 'onDetailsPageLoadExceptionMsg',
          message: field.fieldName.toLowerCase(),
          messageType: 'warning'
        });
      }
      return (
        <NotificationMessageIcon role="alert">
          <NotificationIconSpan>
            <ImagePath
              aria-hidden="true"
              alt=""
              src={icons[alert.style.icon]}
            />
          </NotificationIconSpan>
          <NotificationMessage
            paymentAlert
            aria-label={formatMessage(
              { id: labelContent },
              {
                contact0: formatMessage({ id: `${superFamily}ContactUsNumber` })
              }
            )}
          >
            <ContactLink
              sentenceWithContact={labelContent}
              contactsList={[`${superFamily}ContactUsNumber`]}
              typeOfCall="general enquiry"
            />
          </NotificationMessage>
        </NotificationMessageIcon>
      );
    });
  };

  return (
    <AlertWrapper type={position}>
      {alerts.map(alert => {
        return (
          <NotificationMessageType
            bgColor={alert.style.bgColor}
            borderColor={alert.style.borderColor}
            paymentAlert
            type={position}
          >
            {listAllAlerts(alert)}
          </NotificationMessageType>
        );
      })}
    </AlertWrapper>
  );
};

AlertIndicator.propTypes = {
  alerts: PropTypes.array.isRequired,
  superFamily: PropTypes.string.isRequired,
  position: PropTypes.string
};

AlertIndicator.defaultProps = {
  position: ''
};
AlertIndicator.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default AlertIndicator;
