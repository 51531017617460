import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import _find from 'lodash/find';
import {
  getPolicyInfo,
  setWarningsOrErrors,
  setPolicyCheckValue,
  updateIdleFlag,
  startLoader,
  endLoader,
  updateSAASPageFail
} from '../app/appActions';
import DetailNotification from './DetailNotification';
import DisclaimerSectionNote from './DisclaimerSection';
import {
  FullWidthSectionContainer,
  WhiteSpaceContainer
} from '../common/styles/commonStyle';
import PolicySectionBuilder from './PolicySectionBuilder';
import ErrorBoundary from '../app/ErrorBoundary';
import fireCelebrus from '../utils/fireCelebrusUtils';
import { trackViews } from '../utils/tealiumUtils';
import {
  getDigiServiceLink,
  checkIfInstructionLinkWithSuperFamily
} from '../utils/policyUtils';
import { fetchSaasToken } from '../utils/saasAuthModuleUtil';
import { isApplicationStubbed } from '../utils/routeUtils';
import {
  getElement,
  isMiniMobile,
  setBackGroundColorDetailSummaryInstPage
} from '../utils/domUtils';
import isCustomerWhiteListed from '../utils/customerUtils';
import ErrorNotification from './ErrorNotification';

class DetailPage extends Component {
  constructor(props) {
    super(props);
    this.policyObj = {};
    this.policySuperFamily = '';
    this.stackingFamily = '';
    this.productCategoryKey = '';
    this.serviceOptions = {};
    this.policyDetailsTealium = {};
    this.isDetailsHasError = false;
    this.isCustomerWhiteListedForDigiService = {};
  }

  componentDidMount() {
    const {
      updateIdleState,
      startLoaderHandler,
      endLoaderHandler,
      updateSAASPageFailHandler
    } = this.props;
    startLoaderHandler();

    if (isApplicationStubbed()) {
      this.onPageMount();
    } else {
      fetchSaasToken()
        .then(() => {
          this.onPageMount();
        })
        .catch(() => {
          updateIdleState(true);
          updateSAASPageFailHandler();
          endLoaderHandler();
        });
    }
  }

  componentDidCatch(error, info) {
    /* eslint-disable no-console */
    // TODO: add UI-logger debug
    console.log('Error', error, info);
  }

  componentWillUnmount() {
    const { resetPolicyCheckValues, initialiseDetailsError } = this.props;
    resetPolicyCheckValues({
      isPolicyExceptional: false,
      isPolicyLapsed: false,
      isPolicyDetailsAvailable: null
    });
    initialiseDetailsError({ details: null });
  }

  onPageMount() {
    window.scrollTo(0, 0);
    if (isMiniMobile()) {
      getElement('menuBtn');
    }
    const {
      fetchPolicyInfo,
      match,
      policyByEncodeId,
      location: { pathname },
      documentsWarningsOrErrors
    } = this.props;
    const {
      params: { encodedProductId }
    } = match;
    /**
     * Calling the policy saga which has the following logic
     *  1) Firstly do no api call if the policy information or policy details for a particular policy exists.
     *  2) Would be responsible to call the respective API, basis the encodedID or Not.
     *  3) Added the trackview for details page(When the encodedPolicyNumber present).
     *  4) Adding any new API call like rpq etc
     */
    fetchPolicyInfo(encodedProductId);
    fireCelebrus(pathname);
    const documentsError = documentsWarningsOrErrors[encodedProductId] || {};
    const { errors } = documentsError;
    if (errors) {
      trackViews({
        viewName: 'eWelcomePolicyDocumentsErrorMsg',
        pageTealiumDetails: policyByEncodeId[encodedProductId].policyDetails,
        event_content:
          'Sorry, the documents are not available at the moment due to technical issues. Please try again later.'
      });
    }
  }

  getPolicyFromEncodeId(encodedProductId, byEncodeId) {
    this.policyObj = byEncodeId[encodedProductId];
  }

  getPolicySuperFamily(subProductCategories, productCategory) {
    if (subProductCategories && productCategory) {
      const matchedConfiguredProduct = _find(subProductCategories, [
        'productCategoryName',
        productCategory
      ]);

      // Default handing for new product category
      if (!matchedConfiguredProduct) {
        this.policySuperFamily = 'DEFAULT';
        this.stackingFamily = 'DEFAULT';
        this.policyObj.stackingFamily = 'DEFAULT';
        return;
      }
      this.policySuperFamily = matchedConfiguredProduct.superFamily;
      const { isSummaryCalled } = this.props;
      if (isSummaryCalled) {
        this.stackingFamily =
          _.camelCase(this.policyObj.productFamily) || 'DEFAULT';
      } else {
        this.stackingFamily =
          _.camelCase(
            this.policyObj.policyDetails &&
              this.policyObj.policyDetails.policyOverview &&
              this.policyObj.policyDetails.policyOverview.productFamily
          ) || 'DEFAULT';
      }
      this.policyObj.stackingFamily = this.stackingFamily;
      this.productCategoryKey = matchedConfiguredProduct.productCategoryKey;
    }
  }

  getServiceOptions(productCategory) {
    const {
      servicingOptionsConfig: { order, uid, digiService, whitelistIdentifier },
      networkProvidersMappingsConfig,
      functionEligibility
    } = this.props;
    const {
      policyDetails: { policyServicingOptions }
    } = this.policyObj;
    // order which needs to be followed when displaying in the quicklink section.
    const sequencedServiceOptions = _.intersection(
      order,
      policyServicingOptions
    );
    /*  let serviceUrlWithPolicy = url.replace(
      /placeholder_policyNumber/gi,
      policyNumber
    );

    serviceUrlWithPolicy = serviceUrlWithPolicy.replace(
      /placeholder_encodePolicyNumber/gi,
      encodePolicyNumber
    ); */
    _.each(sequencedServiceOptions, service => {
      /**
       * To check is the customer is whitelisted if the whitelistIdentifier[service]
       * is present or simply return true, so as to go ahead with innate logic.
       */
      this.isCustomerWhiteListedForDigiService[service] = whitelistIdentifier[
        service
      ]
        ? isCustomerWhiteListed(
            whitelistIdentifier[service],
            functionEligibility
          )
        : true;

      const redirectToDigiService =
        digiService[service] &&
        this.isCustomerWhiteListedForDigiService[service] &&
        checkIfInstructionLinkWithSuperFamily(
          this.policySuperFamily,
          digiService[service]
        );

      if (redirectToDigiService) {
        const digiServiceObj = getDigiServiceLink(
          this.policyObj.policyDetails,
          digiService[service],
          networkProvidersMappingsConfig
        );
        if (digiServiceObj) {
          this.serviceOptions[service] = digiServiceObj;
        }
      } else {
        /* P2G url code below need to refactor as below:
           1) need to pass '' url as it required in SerivicingOption component to display
           2) uid[service] needs to decide whether that option is eligible to display for life/gi products
        */
        if (typeof uid[service] === 'object') {
          if (productCategory.toLowerCase() in uid[service]) {
            this.serviceOptions[service] = { url: '' };
          }
        } else {
          this.serviceOptions[service] = { url: '' };
        }
      }
    });
  }

  checkForEmpty = responseObject =>
    !_.isEmpty(responseObject) && responseObject;

  render() {
    const {
      policyByEncodeId,
      match,
      subProductCategories,
      selectedLocale,
      langObj,
      warningsOrErrors: { details = null },
      isPolicyDetailsAvailable,
      isPolicyExceptional,
      isPolicyLapsed,
      faqSection,
      policyTagsMedicalAndCIConfig,
      pageSaasFail,
      isLoading,
      functionEligibility,
      contactUsConfig,
      documentsWarningsOrErrors,
      fundSelectionData,
      fundSelectionWarningsOrErrors,
      rpqUTBConfig,
      surrenderChargeConfig,
      indicativeGainLossConfig
    } = this.props;
    const {
      params: { encodedProductId }
    } = match;

    setBackGroundColorDetailSummaryInstPage();
    if (details && details.errors) {
      this.isDetailsHasError = true;
    }
    /** encodeId check for case where reprojection page reloads and we redirect to details , get encodeId from reprojection */
    if (_.isEmpty(policyByEncodeId)) {
      return null;
    }

    this.getPolicyFromEncodeId(encodedProductId, policyByEncodeId);

    /**
     *
     * Using dashboardCategory to find out section ordering and configs needed for a policy.
     * Eg:For Annuity, section configs of Whole Life can be shown.
     *
     */

    let dashboardCategory = 'DEFAULT';
    dashboardCategory =
      this.policyObj.policyDetails &&
      this.policyObj.policyDetails.policyOverview.dashboardCategory;

    const productCategory =
      this.policyObj.productCategory ||
      this.policyObj.policyDetails.policyOverview.productCategory;
    this.getPolicySuperFamily(subProductCategories, productCategory);

    const isPolicyDetailsAvailableState = this.isDetailsHasError
      ? false
      : isPolicyDetailsAvailable;

    const warningsOrErrors = this.isDetailsHasError
      ? details && details.errors
      : [];

    /**
     * Calling servicing feature only when proper details response is available.
     * In case of exceptions/lapsed/detailsIndicator, servicing options are not needed.
     */
    if (this.policyObj.policyDetails) {
      this.getServiceOptions(productCategory);
    }

    const documentsError = documentsWarningsOrErrors[encodedProductId] || {};
    const { errors } = documentsError;
    const policy = policyByEncodeId[encodedProductId];

    return (
      <>
        <ErrorBoundary>
          <FullWidthSectionContainer>
            <DetailNotification
              superFamily={this.policySuperFamily}
              policyDetailsTealium={this.policyDetailsTealium}
            />
            {errors ? <ErrorNotification policy={policy} /> : null}
          </FullWidthSectionContainer>
          {!isLoading && pageSaasFail ? (
            <WhiteSpaceContainer />
          ) : (
            <>
              <PolicySectionBuilder
                summaryResponse={this.policyObj}
                policyDetails={this.policyObj.policyDetails}
                productCategory={productCategory}
                productCategoryKey={this.productCategoryKey}
                superFamily={this.policySuperFamily}
                stackingFamily={this.policyObj.stackingFamily}
                subProductCategories={subProductCategories}
                selectedLocale={selectedLocale}
                langObj={langObj}
                servicingOptionsURL={this.serviceOptions}
                isPolicyDetailsAvailableState={isPolicyDetailsAvailableState}
                isPolicyExceptionalState={isPolicyExceptional}
                isPolicyLapsedState={isPolicyLapsed}
                dashboardCategory={dashboardCategory || productCategory}
                warningsOrErrors={warningsOrErrors}
                faqSection={faqSection}
                policyTagsMedicalAndCIConfig={policyTagsMedicalAndCIConfig}
                indicativeGainLossConfig={indicativeGainLossConfig}
                functionEligibility={functionEligibility}
                encodedProductId={encodedProductId}
                contactUsConfig={contactUsConfig}
                fundSelectionData={this.checkForEmpty(fundSelectionData)}
                fundSelectionWarningsOrErrors={this.checkForEmpty(
                  fundSelectionWarningsOrErrors
                )}
                rpqUTBConfig={rpqUTBConfig}
                surrenderChargeConfig={surrenderChargeConfig}
                customerWhiteListedForDigiServiceLinks={
                  this.isCustomerWhiteListedForDigiService
                }
              />
            </>
          )}
        </ErrorBoundary>
        {this.policySuperFamily && (
          <DisclaimerSectionNote policySuperFamily={this.policySuperFamily} />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPolicyInfo: policyEncodeID => getPolicyInfo(policyEncodeID),
      initialiseDetailsError: errorWarning => setWarningsOrErrors(errorWarning),
      resetPolicyCheckValues: setPolicyCheckValue,
      updateIdleState: updateIdleFlag,
      startLoaderHandler: startLoader,
      endLoaderHandler: endLoader,
      updateSAASPageFailHandler: updateSAASPageFail
    },
    dispatch
  );

DetailPage.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    configurations: {
      productConfig: { subProductFamilies },
      genericConfig: { servicingOptions, networkProvidersMappings, rpqUTB },
      faqConfig: { faqSection },
      policyTagsMedicalAndCIConfig,
      indicativeGainLossConfig,
      contactUsConfig,
      surrenderChargeConfig
    },
    policy: {
      byEncodeId,
      warningsOrErrors,
      isPolicyExceptional,
      isPolicyLapsed,
      isPolicyDetailsAvailable,
      isSummaryCalled,
      isPolicyGeneral
    },
    eWelcomePolicyDocuments: { documentsWarningsOrErrors },
    app: { isLoading, pageSaasFail },
    content: { selectedLocale, langObj },
    customer: { functionEligibility },
    fundSelection: { fundSelectionData, fundSelectionWarningsOrErrors }
  } = state;
  return {
    policyByEncodeId: byEncodeId,
    subProductCategories: subProductFamilies,
    selectedLocale,
    langObj,
    servicingOptionsConfig: servicingOptions,
    networkProvidersMappingsConfig: networkProvidersMappings,
    rpqUTBConfig: rpqUTB,
    warningsOrErrors,
    isPolicyDetailsAvailable,
    isPolicyExceptional,
    isPolicyLapsed,
    isSummaryCalled,
    isPolicyGeneralValue: isPolicyGeneral,
    faqSection,
    policyTagsMedicalAndCIConfig,
    indicativeGainLossConfig,
    isLoading,
    pageSaasFail,
    functionEligibility,
    contactUsConfig,
    documentsWarningsOrErrors,
    fundSelectionData,
    fundSelectionWarningsOrErrors,
    surrenderChargeConfig
  };
};

DetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  fetchPolicyInfo: PropTypes.func.isRequired,
  policyByEncodeId: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  langObj: PropTypes.object.isRequired,
  servicingOptionsConfig: PropTypes.object.isRequired,
  networkProvidersMappingsConfig: PropTypes.object.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired,
  warningsOrErrors: PropTypes.object.isRequired,
  initialiseDetailsError: PropTypes.func.isRequired,
  isPolicyDetailsAvailable: PropTypes.bool,
  isPolicyExceptional: PropTypes.bool.isRequired,
  isPolicyLapsed: PropTypes.bool.isRequired,
  isSummaryCalled: PropTypes.bool.isRequired,
  resetPolicyCheckValues: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  faqSection: PropTypes.object.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired,
  indicativeGainLossConfig: PropTypes.object.isRequired,
  updateIdleState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageSaasFail: PropTypes.bool.isRequired,
  startLoaderHandler: PropTypes.func.isRequired,
  endLoaderHandler: PropTypes.func.isRequired,
  updateSAASPageFailHandler: PropTypes.func.isRequired,
  functionEligibility: PropTypes.array.isRequired,
  contactUsConfig: PropTypes.object,
  documentsWarningsOrErrors: PropTypes.object.isRequired,
  fundSelectionData: PropTypes.object.isRequired,
  fundSelectionWarningsOrErrors: PropTypes.object.isRequired,
  surrenderChargeConfig: PropTypes.object
};
DetailPage.defaultProps = {
  isPolicyDetailsAvailable: false,
  contactUsConfig: {},
  surrenderChargeConfig: {}
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
