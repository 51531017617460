/*
This Utility is used to capture client side logs
*/
let ws;
function getCurrentInstance() {
  return ws;
}
const defaultSocketURL =
  'wss://dia4pzbbo9.execute-api.us-east-1.amazonaws.com/dev'; // TODO CHANGE URL AS PER ENV

function connect(url = defaultSocketURL) {
  ws = new WebSocket(url);

  ws.onclose = function _onclose(e) {
    /* eslint-disable no-console */
    console.log('Socket is closed, Reconnecting.', e.reason);
    connect(url);
  };

  ws.onerror = function _error(err) {
    /* eslint-disable no-console */
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    ws.close();
  };

  return getCurrentInstance;
}

function send(options = {}) {
  /*
  readyState status code
   0	CONNECTING	Socket has been created. The connection is not yet open.
   1	OPEN	The connection is open and ready to communicate.
   2	CLOSING	The connection is in the process of closing.
   3	CLOSED	The connection is closed or couldn't be opened.
   */
  if (ws.readyState === 1) {
    ws.send(JSON.stringify(options));
  }
  return getCurrentInstance();
}

const UILogger = {
  connect,
  send
};
export default UILogger;
