const keys = {
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13
};

const determineNextTabIndex = (
  keyCode,
  minTabIndex,
  maxTabIndex,
  currentTabIndex
) => {
  switch (keyCode) {
    case keys.left:
    case keys.up:
      return currentTabIndex === minTabIndex
        ? maxTabIndex
        : currentTabIndex - 1;

    case keys.right:
    case keys.down:
      return currentTabIndex === maxTabIndex ? 0 : currentTabIndex + 1;
    case keys.home:
      return minTabIndex;
    case keys.end:
      return maxTabIndex;
    default:
      break;
  }
  return currentTabIndex;
};

export default determineNextTabIndex;
