import React from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from '../../common/tooltipWrapper/TooltipWrapper';
import {
  ContactInfoLeft,
  ContactInfoRight
} from '../sections/contactInfoStyles';

const ContactField = (props, context) => {
  const {
    id,
    labelContent,
    fieldData,
    toolTipContent,
    idx,
    isDependentContent,
    sectionHeading
  } = props;
  let fontChange;

  const {
    intl: { formatMessage }
  } = context;
  if (!fieldData || typeof fieldData !== 'string') {
    return <></>;
  }

  if (isDependentContent) {
    fontChange = true;
  }

  return (
    <>
      <ContactInfoLeft id={`label_${idx}`}>
        {formatMessage({ id: labelContent })}
        <TooltipWrapper
          id={`tooltip_${id}`}
          contentKey={toolTipContent}
          sectionHeading={sectionHeading}
          labelContent={labelContent}
        />
      </ContactInfoLeft>
      <ContactInfoRight id={`value_${idx}`} fontChange={fontChange}>
        {fieldData}
      </ContactInfoRight>
    </>
  );
};

ContactField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string,
  idx: PropTypes.number,
  isDependentContent: PropTypes.bool,
  sectionHeading: PropTypes.string.isRequired
};

ContactField.defaultProps = {
  toolTipContent: null,
  idx: 0,
  isDependentContent: false
};

ContactField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ContactField;
