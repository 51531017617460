import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getImagePath } from '../../../utils/routeUtils';
import {
  ImgCenter,
  ImgStyle,
  NoBeneficiaryCard
} from './beneficiarySectionStyles';

const NoDataImages = props => {
  const { noDataImage } = props;

  const blankBeneImage = getImagePath(
    process.env.REACT_APP_IMAGES_URL,
    noDataImage
  );

  return (
    <NoBeneficiaryCard>
      <ImgCenter>
        <ImgStyle aria-hidden="true" alt="" src={blankBeneImage} />
      </ImgCenter>
    </NoBeneficiaryCard>
  );
};

NoDataImages.propTypes = {
  noDataImage: PropTypes.object
};

NoDataImages.defaultProps = {
  noDataImage: null
};

const mapStateToProps = state => {
  const {
    configurations: {
      detailsConfig: {
        BeneficiariesListInfo: { emptyBeneImage }
      }
    }
  } = state;
  return {
    noDataImage: emptyBeneImage
  };
};

export default connect(mapStateToProps, null)(NoDataImages);
