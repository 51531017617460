const getPaymentAlertList = function getPaymentAlertList(
  paymentAlerts,
  fieldConfigInstance,
  notificationMessages
) {
  if (
    paymentAlerts.some(alert => alert.type === fieldConfigInstance.uiFormat)
  ) {
    const alertObj = paymentAlerts.find(
      message => message.type === fieldConfigInstance.uiFormat
    );
    alertObj.fields.push(fieldConfigInstance);
  } else {
    const alertObj = notificationMessages.find(
      message => message.type === fieldConfigInstance.uiFormat
    );
    alertObj.fields = [fieldConfigInstance];
    paymentAlerts.push(alertObj);
  }
  return paymentAlerts;
};

export default getPaymentAlertList;
