import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CurrencyWithSymbol from '../../../common/currency/CurrencyWithSymbol';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import {
  FundsTotalPremiumPaidFieldWrapper,
  TotalPremiumPaidLabel,
  TotalPremiumPaidAmount,
  SubHeaderReturns
} from '../../detailPageStyle';

const FundsTotalPremiumPaidField = function FundsTotalPremiumPaidField(
  props,
  context
) {
  const {
    intl: { formatMessage }
  } = context;
  const {
    labelContent,
    sectionHeading,
    toolTipContent,
    fieldData,
    fieldData: { currency, amount },
    dependentContentData,
    idx
  } = props;
  if (!labelContent || typeof fieldData !== 'object') {
    return null;
  }
  return (
    <>
      <FundsTotalPremiumPaidFieldWrapper id="fundsTotalPremiumPaidField">
        <TotalPremiumPaidLabel id={`label_${idx}`}>
          <FormattedMessage id={labelContent} />
          <TooltipWrapper
            id={`tooltip_${labelContent}`}
            contentKey={toolTipContent}
            labelContent={labelContent}
            sectionHeading={sectionHeading}
            maxWidth={300}
          />
        </TotalPremiumPaidLabel>
        <TotalPremiumPaidAmount>
          <CurrencyWithSymbol currencyCode={currency} amount={amount} />
        </TotalPremiumPaidAmount>
      </FundsTotalPremiumPaidFieldWrapper>
      <>
        {dependentContentData && (
          <SubHeaderReturns>
            {formatMessage({ id: dependentContentData })}
          </SubHeaderReturns>
        )}
      </>
    </>
  );
};

FundsTotalPremiumPaidField.propTypes = {
  idx: PropTypes.number.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  dependentContentData: PropTypes.string.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string.isRequired
};

FundsTotalPremiumPaidField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundsTotalPremiumPaidField;
