import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import specialCaseResolver from '../../sectionUtils/OverviewAmountUtils';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  OverviewInfoFieldAmountCurrency,
  SpcialCaseWrapper
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';
import { planCodeLabelGenerator } from '../../../policysummary/productTemplates/templateUtils';

const OverviewAmountField = (props, context) => {
  const {
    intl: { formatMessage, messages }
  } = context;
  const {
    fieldData,
    dependentFieldData,
    secondDependentFieldData,
    isMedical,
    dependentComponentValue,
    planCodesToCheck,
    planCodesToolTip
  } = props;
  let { labelContent, toolTipContent } = props;

  if (
    !labelContent ||
    typeof fieldData !== 'object' ||
    !fieldData.currency ||
    (!fieldData.amount && fieldData.amount !== 0)
  ) {
    return <></>;
  }

  if (secondDependentFieldData) {
    const fieldObject = {
      fieldLabel: labelContent,
      conditionalFieldValue: secondDependentFieldData
    };
    labelContent = planCodeLabelGenerator(fieldObject, messages);
    toolTipContent = `${labelContent}_Tooltip`;
  }

  if (dependentComponentValue) {
    const isPlanCodeApplicable = _.includes(
      planCodesToCheck,
      dependentComponentValue
    );
    if (isPlanCodeApplicable) {
      toolTipContent = planCodesToolTip;
    }
  }

  let amountCurrencyComponent = '';
  if (dependentFieldData) {
    const returnSpecialCaseKey = specialCaseResolver(
      dependentFieldData,
      'spcialCase'
    );
    amountCurrencyComponent = (
      <SpcialCaseWrapper>
        <FormattedMessage
          id={returnSpecialCaseKey}
          aria-label={formatMessage({
            id: `${returnSpecialCaseKey}`
          })}
          values={{
            amountValue: (
              <Currency
                currencyCode={fieldData.currency}
                amount={fieldData.amount}
              />
            )
          }}
        />
      </SpcialCaseWrapper>
    );
  } else {
    amountCurrencyComponent = (
      <Currency
        id="CurrencyLabel"
        currencyCode={fieldData.currency}
        amount={fieldData.amount}
      />
    );
  }
  return (
    <OverviewFieldWrapper id={labelContent} isMedical={isMedical}>
      <LabelWrapper
        {...props}
        labelContent={labelContent}
        toolTipContent={toolTipContent}
      />
      <OverviewInfoFieldAmountCurrency id="amountLabelDD">
        {amountCurrencyComponent}
      </OverviewInfoFieldAmountCurrency>
    </OverviewFieldWrapper>
  );
};

OverviewAmountField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.string,
  sectionHeading: PropTypes.string.isRequired,
  secondDependentFieldData: PropTypes.string,
  isMedical: PropTypes.bool,
  dependentComponentValue: PropTypes.string,
  planCodesToCheck: PropTypes.array,
  planCodesToolTip: PropTypes.string
};

OverviewAmountField.defaultProps = {
  toolTipContent: null,
  dependentFieldData: '',
  secondDependentFieldData: '',
  isMedical: false,
  dependentComponentValue: '',
  planCodesToCheck: [],
  planCodesToolTip: ''
};

OverviewAmountField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default OverviewAmountField;
