import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ContactList } from './ContactList';
import DisclaimerSectionNote from '../../../policydetail/DisclaimerSection';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import { ButtonLinkStyle } from '../../../common/customButton/CustomButtonStyledComp';
import { ParagraphDiv } from '../../../common/styles/modalStyles';
import { isIE, setLinkFocus } from '../../../utils/domUtils';
import {
  ContactSection,
  LinkSection,
  ContactImagePath,
  ContactImgSpan,
  ModalDescWrapper,
  ModalNoteWrapper,
  NoteText,
  ModalListHeaderSection,
  ListItem,
  HeaderSectionWrapper,
  HeaderSection,
  ListRow,
  ListRowHeader,
  SectionBody,
  ListRowDetails,
  ListRowData,
  ContactLabel,
  ContactNumber,
  ContactDetail,
  ContactLabelWrapper,
  ContactCard,
  ContactCardLabel,
  DisclaimerWrapper
} from './FooterStyles';

import ContactIcon from '../../../assets/images/contact.svg';
import ContactIconBlack from '../../../assets/images/Contactphone.svg';
import ClaimWrapper from '../../../policydetail/sections/ClaimWrapper';
import ContactLink from '../../../common/contactLink/contactLink';

import {
  trackEvents,
  createEventActionContent
} from '../../../utils/tealiumUtils';

const customStylesPopup = () => {
  return {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 5,
      backgroundColor: 'white',
      overflowY: 'auto'
    },
    content: {
      maxWidth: '1180px',
      paddingTop: '20px'
    }
  };
};

const ContactUsFooter = (props, context) => {
  const {
    sectionHeading,
    showContactUsFooterPopup,
    setContactUsPopupHandler
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  const linkRef = React.createRef();
  const contactUsTitleKeyLangSpecific = 'contact us';
  const onCancelClick = () => {
    setContactUsPopupHandler(false);
    trackEvents({
      eventName: 'popup',
      eventContent: formatMessage({ id: 'contactUsTitle' }).toLowerCase()
    });
    setLinkFocus(linkRef.current, sectionHeading !== 'policyOverview');
  };
  const footerButtonList = [
    {
      className: 'redBtn',
      label: <FormattedMessage id="cancelPopup" />,
      id: 'cancel_btn',
      align: 'right'
    }
  ];
  const closeModel = e => {
    setContactUsPopupHandler(false);
    trackEvents({
      eventName: 'contactUs',
      eventContent: contactUsTitleKeyLangSpecific
    });
    if (e) {
      e.preventDefault();
    }
  };

  const anchorToContactCard = () => {
    const scrollToElement = document.getElementById('contactInfoGeneralCard');
    if (scrollToElement) {
      window.scrollTo(0, scrollToElement.offsetTop - 150);
    }
  };

  let returnContactUsLink = '';
  if (sectionHeading !== 'policyOverview') {
    returnContactUsLink = (
      <ContactSection>
        <LinkSection>
          <ButtonLinkStyle
            ref={linkRef}
            type="button"
            id="contactUsFooter"
            onClick={() => {
              setContactUsPopupHandler(true);
              trackEvents({
                eventName: 'contactUsFooter',
                eventData: createEventActionContent(
                  'button',
                  contactUsTitleKeyLangSpecific,
                  'disclaimer'
                )
              });
            }}
          >
            <ContactImgSpan>
              <ContactImagePath src={ContactIcon} alt="" aria-hidden="true" />
            </ContactImgSpan>
            <FormattedMessage id="contactUsTitle" />
          </ButtonLinkStyle>
        </LinkSection>
      </ContactSection>
    );
  } else {
    returnContactUsLink = (
      <ClaimWrapper
        id="contactUs"
        ariaLabel={formatMessage({ id: 'contactUsTitle' })}
        onClick={e => {
          e.preventDefault();
          anchorToContactCard();
          trackEvents({
            eventName: 'contactUsFooter',
            eventData: createEventActionContent(
              'button',
              contactUsTitleKeyLangSpecific,
              'policy overview'
            )
          });
        }}
        altText="contactUs-icon"
        iconSrc={ContactIconBlack}
        linkLabel={formatMessage({ id: 'contactUsTitle' })}
        linkRef={linkRef}
      />
    );
  }
  return (
    <>
      {returnContactUsLink}
      <ModalPopUp
        isModalOpen={showContactUsFooterPopup}
        onClose={closeModel}
        modalHeading={formatMessage({ id: 'contactUsTitle' })}
        footerButtonList={footerButtonList}
        role="dialog"
        contentStyle={customStylesPopup()}
        btnHandler={{
          cancel_btn: onCancelClick
        }}
      >
        <ParagraphDiv>
          <ModalDescWrapper>
            <ModalNoteWrapper>
              <NoteText>
                <FormattedMessage id="contactUsTitleDetails" />
              </NoteText>
            </ModalNoteWrapper>
            <ModalListHeaderSection>
              {ContactList.map(field => (
                <div key={field}>
                  <ListItem>{formatMessage({ id: field.listHeader })}</ListItem>
                  <HeaderSectionWrapper>
                    <HeaderSection>
                      <ListRow>
                        {field.policyTypes.map(type => (
                          <ListRowHeader
                            key={type}
                            singlePolicy={field.singlePolicy}
                          >
                            <FormattedMessage id={type.title} />
                          </ListRowHeader>
                        ))}
                      </ListRow>
                    </HeaderSection>
                    <SectionBody>
                      <ListRowDetails>
                        {field.policyTypes.map(data => (
                          <ListRowData
                            key={data}
                            singlePolicy={field.singlePolicy}
                          >
                            {data.contact.map(contact => (
                              <ContactLabelWrapper
                                hideBottomSpacing={contact.hideBottomSpacing}
                                key={contact}
                              >
                                {contact.label && (
                                  <ContactLabel>
                                    {formatMessage({ id: contact.label })}
                                  </ContactLabel>
                                )}
                                {contact.numberLabel && (
                                  <ContactNumber>
                                    <ContactLink
                                      sentenceWithContact={contact.numberLabel}
                                      contactsList={[contact.number]}
                                      typeOfCall={data.typeOfCall}
                                    />
                                  </ContactNumber>
                                )}
                                {contact.details && (
                                  <ContactDetail
                                    id={contact.detailsClass}
                                    isIE={isIE}
                                  >
                                    {formatMessage({ id: contact.details })}
                                  </ContactDetail>
                                )}
                              </ContactLabelWrapper>
                            ))}
                          </ListRowData>
                        ))}
                      </ListRowDetails>
                    </SectionBody>
                  </HeaderSectionWrapper>
                </div>
              ))}
              <ContactCard>
                <ContactLabel id="Contact_label">
                  {formatMessage({ id: 'policyHotline_Contact_label' })}
                </ContactLabel>
                <ContactCardLabel>
                  <ContactNumber id="Contact_Number">
                    <ContactLink
                      sentenceWithContact="policyApplyPlans_Contact_Number"
                      contactsList={['policyHotline_Contact_Number']}
                      typeOfCall="emergency hotline"
                    />
                  </ContactNumber>
                  <ContactDetail id="Contact_Details">
                    {formatMessage({ id: 'policyHotline_Contact_Details' })}
                  </ContactDetail>
                </ContactCardLabel>
              </ContactCard>
              <DisclaimerWrapper isIE={isIE}>
                <DisclaimerSectionNote />
              </DisclaimerWrapper>
            </ModalListHeaderSection>
          </ModalDescWrapper>
        </ParagraphDiv>
      </ModalPopUp>
    </>
  );
};

ContactUsFooter.contextTypes = {
  intl: PropTypes.object.isRequired
};

ContactUsFooter.propTypes = {
  sectionHeading: PropTypes.string,
  showContactUsFooterPopup: PropTypes.bool.isRequired,
  setContactUsPopupHandler: PropTypes.func.isRequired
};
ContactUsFooter.defaultProps = {
  sectionHeading: ''
};

export default ContactUsFooter;
