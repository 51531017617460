import React from 'react';
import PropTypes from 'prop-types';
import { LI, TabLabel } from '../styles/commonStyle';

const LiComponent = props => {
  const {
    tabreference,
    children,
    selectedTab,
    sectionStyle,
    ...otherProps
  } = props;
  return (
    <LI
      ref={tabreference}
      sectionStyle={sectionStyle}
      selectedTab={selectedTab}
      {...otherProps}
    >
      <TabLabel selectedTab={selectedTab}>{children}</TabLabel>
    </LI>
  );
};
LiComponent.propTypes = {
  id: PropTypes.string.isRequired,
  tabreference: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  tabIndex: PropTypes.string.isRequired,
  'aria-controls': PropTypes.string.isRequired,
  'aria-selected': PropTypes.bool.isRequired,
  selectedTab: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  sectionStyle: PropTypes.string
};

LiComponent.defaultProps = {
  sectionStyle: ''
};

export default LiComponent;
