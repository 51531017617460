import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';

const FundTableRatingDate = function FundTableRatingDate(props, context) {
  const { fieldData } = props;
  const {
    intl: { formatMessage }
  } = context;

  if (!fieldData) {
    return <React.Fragment />;
  }

  return (
    <>
      <ShowIf condition={!!fieldData}>{`${formatMessage({
        id: 'riskRatingDate'
      })}${fieldData}`}</ShowIf>
    </>
  );
};

FundTableRatingDate.propTypes = {
  fieldData: PropTypes.array.isRequired
};

FundTableRatingDate.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundTableRatingDate;
