import React from 'react';
import PropTypes from 'prop-types';
import { MedicalDisclaimerWrapper } from '../../detailPageStyle';

const MedicalDisclaimer = function MedicalDisclaimer(props, context) {
  const { labelContent } = props;
  const {
    intl: { formatMessage }
  } = context;

  return (
    <>
      <MedicalDisclaimerWrapper>
        {formatMessage({ id: labelContent })}
      </MedicalDisclaimerWrapper>
    </>
  );
};

MedicalDisclaimer.propTypes = {
  labelContent: PropTypes.string.isRequired
};

MedicalDisclaimer.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default MedicalDisclaimer;
