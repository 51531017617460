import React from 'react';
import PropTypes from 'prop-types';
import ServicingKeyValue from './ServicingKeyValue';
import ServicingAccountCurrency from './ServicingAccountCurrency';
import { getFieldData } from '../instructionUtils';

const SettlementInstruction = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { paymentInfo, individualFund } = props;

  const paymentMethod = getFieldData(paymentInfo, 'paymentMethod');
  const paymentCurrencyCode = getFieldData(paymentInfo, 'paymentCurrencyCode');

  const showSettlementInstruction = () => {
    if (paymentMethod === '7') {
      const paymentAccountNumber = getFieldData(
        paymentInfo,
        'paymentAccountNumber'
      );
      return (
        <ServicingAccountCurrency
          removeLeftSpacing
          label="settlementLabel"
          accountNumber={paymentAccountNumber}
          currency={paymentCurrencyCode}
          individualFund={individualFund}
        />
      );
    }
    if (paymentMethod === 'C') {
      const fieldValue = formatMessage({
        id: `paymentMethod_${paymentMethod}`
      });
      return (
        <ServicingKeyValue
          label="settlementLabel"
          fieldValue={fieldValue}
          removeLeftSpacing
          individualFund={individualFund}
        />
      );
    }
    return null;
  };

  return <>{showSettlementInstruction()}</>;
};

SettlementInstruction.propTypes = {
  paymentInfo: PropTypes.object,
  individualFund: PropTypes.bool
};

SettlementInstruction.defaultProps = {
  paymentInfo: {},
  individualFund: false
};
SettlementInstruction.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SettlementInstruction;
