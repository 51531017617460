import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import { FontFamily } from '../../utils/font';
import theme from '../../common/styles/theme';

const DisclaimerCommonTextStyle = css`
  font-size: ${em('14px')};
  line-height: ${rem('20px')};
  color: ${theme.fontColor};
`;

export const DisclaimerNotesList = styled.ol`
  ${FontFamily.RegularFontW01};
  counter-reset: explanationnotescounter;
  display: table;
  border-spacing: 0 ${rem('5px')};
  margin: 0;
  padding-left: ${rem('10px')};
  margin-bottom: ${rem('35px')};
`;

export const OptimisticNotesWrapper = styled.div`
  ${DisclaimerCommonTextStyle}
`;

export const OptimisticNoteListItem = styled.div`
  display: ${props => (props.id ? 'block' : 'none')};
  line-height: ${rem('20px')};
  padding-bottom: ${props =>
    props.selectedFutureBenefitsTab === 'PayoutBenefit' ? 0 : `${rem('20px')}`};
  p {
    padding-bottom: ${rem('20px')};
    line-height: ${rem('20px')};
  }
`;

export const DisclaimerNotesListItem = styled.li`
  ${DisclaimerCommonTextStyle}
  counter-increment: explanationnotescounter;
  display: table-row;
  &::before {
    content: counter(explanationnotescounter) '. ';
    text-align: left;
    display: table-cell;
    padding-right: ${rem('5px')};
  }
  span > span {
    display: block;
    line-height: ${rem('20px')};
  }
`;

export const DisclaimerHeading = styled.h3`
  ${FontFamily.BoldFontW01};
  ${DisclaimerCommonTextStyle};
  margin-bottom: ${rem('20px')};
`;
export const SingleAggreStyle = styled.div`
  p {
    padding-bottom: ${rem('20px')};
    line-height: ${rem('20px')};
    font-size: ${em('14px')};
  }
  p > span {
    display: block;
    line-height: ${rem('20px')};
  }
`;

export const DisclaimerLink = styled.a`
  color: ${theme.fontColor};
  &:focus {
    outline: ${rem('1px')} dotted;
  }
`;

export const DisclaimerLinkText = styled.span``;

export const ExplanationNotesIntroduction = styled.p`
  margin: 5px 0 20px 0;
  line-height: ${rem('20px')};
  font-size: ${em('14px')};
`;

export const DisclaimerParagraph = styled.p`
  line-height: ${rem('20px')}
    ${props => props.indexVal === 1 && `padding-top: ${rem('5px')}`};
`;
