import React from 'react';
import PropTypes from 'prop-types';
import {
  TableShowMoreLessIcon,
  TableShowMoreLessWrapper
} from '../styles/reprojectionTableStyle';

const TableShowMoreLessButtonIcon = (
  { iconSrc, expanded, title, testId },
  context
) => {
  const {
    intl: { formatMessage }
  } = context;
  return (
    <TableShowMoreLessWrapper>
      <TableShowMoreLessIcon
        src={iconSrc}
        data-testid={testId}
        expanded={expanded}
        focusable={false}
        aria-hidden
        alt=""
      />
      <span>{`${formatMessage({
        id: title
      })}`}</span>
    </TableShowMoreLessWrapper>
  );
};
TableShowMoreLessButtonIcon.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired
};

TableShowMoreLessButtonIcon.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TableShowMoreLessButtonIcon;
