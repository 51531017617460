import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import theme from '../common/styles/theme';
import media from '../utils/media';
import { FontFamily } from '../utils/font';

export const SummaryParentSection = styled.section`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: ${em(theme.maxWidth)};
  width: 100%;
  margin: 0 auto;
  clear: both;
  padding: 0 ${rem('50px')};
  ${media.tablet`
		padding: 0 ${rem('24px')};
    `};
  ${media.phone`
		padding: 0 ${rem('15px')};
	`};
`;

export const SummaryHeaderSection = styled.section`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0px auto ${rem('30px')};
  clear: both;
  padding: ${rem('0px')} ${rem('30px')} ${rem('0px')} ${rem('0px')};
  overflow: hidden;
  ${FontFamily.RegularFontW01}
  ${media.tablet`
    margin: 0px auto ${rem('30px')}};
  `};
  ${media.phone`
    margin: 0px auto ${rem('19px')}};
  `};
`;

export const ProductFamilyHeaderSection = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: ${rem('40px')} auto ${rem('30px')};
  margin-top: ${props => props.categoryStackIndex === 0 && 'auto'};
  clear: both;
  overflow: hidden;
  ${FontFamily.RegularFontW01}
  ${media.tablet`
    margin: ${rem('30px')} auto ${rem('20px')};
    margin-top: ${props => props.categoryStackIndex === 0 && 'auto'};
  `};
  ${media.phone`
    margin: ${rem('30px')} auto ${rem('15px')};
    margin-top: ${props => props.categoryStackIndex === 0 && 'auto'};
  `};
`;

export const SummaryTitle = styled.h1`
  ${FontFamily.LightFontW01}
  font-size: ${em('28px')};
  letter-spacing: 0;
  ${media.tablet`
    font-size: ${em('25px')};
  `};
  ${media.phone`
    font-size: ${em('22px')};
  `};
`;

export const ExploreTitle = styled(SummaryTitle)`
  padding: 0 ${rem('40px')};
  ${media.tablet`
    padding: 0 ${rem('30px')};
  `};
  ${media.phone`
    padding: 0 ${rem('15px')};
  `};
`;
export const LearnMoreLink = styled.a`
  text-decoration: none;
  ${FontFamily.RegularFont};
  font-size: ${em(16)};
  color: #333333;
  line-height: 22px;
  &:focus {
    outline: 1px dotted #333;
    outline: -webkit-focus-ring-color auto 5px;
  }
  span {
    text-decoration: underline;

    &:hover,
    &:focus {
      border-bottom: none;
      cursor: pointer;
    }
  }
  ${media.tablet`padding: 5px 0 5px 0;
    margin-left:0px;`};
`;

export const RedHR = styled.div`
  height: 2px;
  width: 30px;
  background-color: #db0011;
  margin-bottom: 10px;
  ${media.phone`
    margin-top: 10px;
  `};
`;

export const ProductCardSection = styled.section`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: ${em(theme.maxWidth)};
  width: 100%;
  margin: 0 auto;
  clear: both;
  padding: ${rem('18px')} ${rem('30px')} ${rem('20px')};
  overflow: hidden;
  margin-bottom: ${rem('7px')};
  background-color: ${theme.whiteColor};
  box-shadow: 0 0 2px 0 #cccccc;
  border: 1px solid ${theme.detailNotificationBorder};
  h1 {
    font-size: 1.5em;
  }

  ${media.tablet`
		padding: ${rem('18px')} ${rem('20px')};
    `};
  ${media.phone`
		padding: ${rem('14px')} ${rem('14px')};
	`};
`;

export const CardFields = styled.div``;

export const LeftColumn = styled.div`
  flex-grow: 1;
  width: calc(100% - 384px);
  ${media.tablet`
    width : calc(100% - 240px);
  `}
  ${media.phone`
    width : 100%;
  `}
`;
export const RightColumn = styled.div`
  flex-grow: 1;
  text-align: right;
  width: calc(100% - 728px);
  ${media.tablet`
    width : calc(100% - 440px);
  `}
  ${media.phone`
  width:100%;
  display:flex;
  flex-direction : row;
		text-align : left;
	`};
`;

export const CardFlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${media.phone`
		flex-direction : column;
	`};
`;

export const CardStatementsWrapper = styled.div`
  flex-direction: ${props =>
    props.conditionalFieldValueOne || props.conditionalFieldValueTwo
      ? 'column'
      : ''};
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const fieldCommon = styled.div`
  margin: 20px 0;
  ${FontFamily.RegularFontW01}
`;

export const ProjectStatusField = styled(fieldCommon)`
  margin: 0;
  line-height: ${em('20px')};
`;
export const ProductCodeField = styled.h3`
  font-size: ${em('20px')};
  line-height: ${rem('30px')};
  margin: ${rem('6px')} 0;
  ${media.phone`
    font-size: ${em('18px')};
    line-height: ${rem('24px')};
    margin: ${rem('4px')} 0;
    width:100%;
  `}
`;
export const PolicyNumberWrapper = styled(fieldCommon)`
  font-size: ${em('14px')};
  line-height: ${rem('18px')};
  margin: ${rem('6px')} 0;
  color: ${theme.greyLabels};
  ${media.phone`
    font-size: ${em('12px')};
    margin: ${rem('4px')} 0;
  `}
`;
export const PolicyNumberLabel = styled.span``;
export const PolicyNumberField = styled.span`
  padding-left: ${rem('3px')};
`;
export const PolicyDueDate = styled(fieldCommon)``;
export const SumInsuredOrPolicyValueField = styled(fieldCommon)`
  margin: ${rem('10px')} ${rem('0px')} ${rem('5px')} ${rem('0px')};
  /* Amount */
  span span {
    font-size: ${rem('20px')};
    padding-right: ${rem('3px')};
    color: ${theme.fontColor};
    ${media.phone`
    font-size: ${rem('14px')};
    `};
  }
  /* HKD  */
  span {
    font-size: ${rem('16px')};
    color: ${theme.greyLabels};
    ${media.phone`
    font-size: ${rem('12px')};
    `};
  }

  ${media.phone`
  text-align:right;
  margin : 0;
  flex-grow:1;
  `};
`;

export const StatusBulb = styled.span`
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: ${props => props.colorCode};
  margin-right: 7px;
  position: relative;
  bottom: 1px;
`;

export const StatusLabel = styled.span`
  font-size: ${em('12px')};
  line-height: ${rem('20px')};
`;

export const CashValueOrSumInsuredAmount = styled.div`
  display: inline-block;
  ${FontFamily.RegularFontW01};
  font-size: ${em('20px')};
  color: ${theme.fontColor};
  line-height: 30px;
`;
export const CashValueOrSumInsuredLabel = styled.div`
  margin: ${rem('20px')} ${rem('0px')} ${rem('5px')} ${rem('0px')};
  color: ${theme.fontColor};
  ${FontFamily.RegularFontW01};
  font-size: ${em('14px')};
  ${media.phone`
  margin: ${rem('0px')};
  flex-grow:1;
  `};
`;

export const CashValueOrSumInsuredCurrency = styled.div`
  display: inline-block;
  color: #666666;
  font-size: ${em('16px')};
  ${FontFamily.RegularFontW01};
  margin-left: 5px;
  line-height: 30px;
`;

export const AsOfDate = styled.div`
  ${FontFamily.RegularFontW01};
  font-size: ${em('14px')};
  color: ${theme.fontColor};
  margin-top: ${rem('10px')};

  ${media.phone`margin-top : ${rem('5px')};
  font-size: ${em('12px')};
  `};
`;
const commonPaddingNextActionStyle = css`
  padding-top: ${props =>
    props.conditionalFieldValueOne || props.conditionalFieldValueTwo
      ? rem('15px')
      : null};
`;
export const NextActionMessageWrapper = styled.div`
    ${commonPaddingNextActionStyle}
  width: ${props =>
    props.conditionalFieldValueOne || props.conditionalFieldValueTwo
      ? '100%'
      : '75%'};
  display: inline-block;
  line-height: ${rem('22px')};
  font-size: ${rem('16px')};
  ${media.tablet`
  line-height: ${rem('20px')};
  font-size: ${rem('14px')};
  `};
  ${media.phone`
  ${commonPaddingNextActionStyle}
  width: ${props =>
    props.conditionalFieldValueOne || props.conditionalFieldValueTwo
      ? '100%'
      : '85%'};
  line-height: ${rem('18px')};
  font-size: ${rem('12px')};
  `};
`;

export const SeeDetailsWrapper = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  color: ${theme.fontColor};
  justify-content: flex-end;
  line-height: ${rem('22px')};
  font-size: ${rem('16px')};
  ${media.tablet`
  line-height: ${rem('20px')};
  font-size: ${rem('14px')};
  `};
  ${media.phone`
  width:15%;
  line-height: ${rem('18px')};
  font-size: ${rem('12px')};
  `};
  > a {
    padding:0
    display: flex;
    cursor: pointer;
    background: ${theme.whiteColor};
    border: none;
    color: ${theme.fontColor};
    text-decoration:none;
    &:focus, &:hover {
      outline: 1px dotted black;
    }
  }
`;

export const SeeDetail = styled.span`
  display: block;
  margin-right: ${rem('8px')};
  ${media.phone`
    display:none;
  `}
`;

export const ImgWrapper = styled.span`
  width: ${rem('8px')};
  height: ${rem('14px')};
  display: block;
  ${media.tablet`
    width:${rem('7.4px')};
    height:${rem('12.5px')};
  `}
`;
export const IconRightImg = styled.img`
  width: 100%;
  height: 100%;
`;
export const ShowMoreSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${theme.whiteColor};
  border: 1px solid ${theme.buttonBorder};
  margin: 0 0 ${rem('7px')} 0;
`;

export const FullWidthButton = styled.button`
  width: 100%;
  -webkit-appearance: initial;
  background: ${theme.whiteColor};
  border: none;
  ${FontFamily.RegularFontW01}
  font-size: ${em('14px')};
  font: 100 ${em('14px')} UniversNextforHSBC-Regular;
  color: ${theme.fontColor};
  letter-spacing: 0;
  text-align: center;
  padding: ${rem('11px')} 0;
  cursor: pointer;
  &:focus, &:hover{
    outline: 1px dotted black;
  }
`;

export const PersonInsuredField = styled.div`
  margin: ${rem('10px')} 0 ${rem('15px')} 0;
  display: flex;
  width: 100%;
  font-size: ${em('16px')};
  flex-direction: row;

  ${media.tablet`
font-size : ${em('14px')};
`};

  ${media.phone`
font-size : ${em('14px')};
`};
`;

export const PersonInsuredValues = styled.div`
  flex-grow: 1;
  text-align: right;

  ${media.phone`
  width:50%;
`};
`;

export const PersonInsuredLabel = styled.div`
  ${FontFamily.RegularFontW01};
  flex-grow: 1;

  ${media.phone`
  width:50%;
`};
`;

export const TextWrapper = styled.span``;
export const TextSpan = styled.span`
  padding-right: ${rem('4px')};
`;
export const CategoryIconImg = styled.span`
  max-height: 21px;
  max-width: 21px;
  margin-right: 10px;
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
  ${media.tablet`
    max-height: ${rem('15px')};
    max-width: ${rem('15px')};
    margin-right: ${rem('7px')};
  `};
`;

export const CategoryLabel = styled.h2`
  font-size: ${em('22px')};
  letter-spacing: 0;
  line-height: ${rem('24px')};
  ${media.tablet`
    font-size: ${em('18px')};
`};
`;

export const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const SuperFamilyWrapper = styled.div``;

export const PageLevelWarningSection = styled(SummaryParentSection)`
  max-width: 1180px;
  padding: 0;
  ${media.tablet`
    padding: ${rem('17px')} ${rem('24px')} ${rem('7px')}
  `};
`;

// Product template component styling start ***
export const WelcomeNotificationSection = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: ${props => (props.isPolicyCard ? null : '24px')};
  margin-top: ${props => (props.isPolicyCard ? '10px' : null)};
  padding: 0;
  ${props =>
    props.isPolicyCard
      ? null
      : media.tablet`
    padding: ${rem('17px')} ${rem('24px')} ${rem('7px')}
  `};
`;
export const ExploreParentSection = styled(SummaryParentSection)`
  background: ${theme.whiteColor};
  max-width: 1180px;
  padding: ${rem('40px')} 0 0 0;
  margin-bottom: ${rem('40px')};
  ${media.tablet`
		padding: ${rem('30px')} ${rem('0px')};
    `};
  ${media.phone`
		padding: ${rem('20px')} ${rem('0px')};
	`};
`;

export const ProductWrapper = styled.section`
  display: inline-block;
  width: 24%;
  padding: 0 ${rem('12px')} ${rem('20px')} 0;
  box-sizing: border-box;
  vertical-align: top;
  ${media.tablet`
  width: 33.33%;
  `};
  ${media.phone`
width: 100%;
`};
`;

export const Title = styled.a`
${FontFamily.BoldFontW01}
  font-size: ${em('16px')};
  font-weight: 500;
  line-height: 1.38;
  color: ${theme.fontColor};
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:hover{
    cursor: pointer;
  }
`;

export const SubHeadingSection = styled.div`
  margin: ${rem('10px')} 0;
  margin-right: ${rem('10px')};
`;

export const Tags = styled.h2`
${FontFamily.RegularFontW01}
  font-size: ${em('14px')};
  color:#575757;
`;

export const Divider = styled.span`
  display: inline-block;
  height: ${rem('3px')};
  width: ${rem('3px')};
  border-radius: 50%;
  background-color: #575757;
  position: relative;
  bottom: ${rem('2px')};
  margin: 0 ${rem('5px')};
`;

export const Content = styled.span`
  > span{
    ${FontFamily.RegularFontW01}
    font-size: ${em('14px')};
    color:#575757;
    line-height:1.3;
    width: 100%
    margin-top: ${rem('10px')};
    span{
      display: block;
      line-height: 20px;
      padding-top: 10px;
    }
  }
`;

export const Icon = styled.img`
  margin-left: ${rem('10px')};
  height: ${rem('12px')};
  width: ${rem('12px')};
`;

export const CollapsibleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${theme.detailNotificationBorder};
  &:last-child {
    border-bottom: 0;
  }
`;

export const ExploreCollapsibleButton = styled.button`
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  padding: ${rem('20px')} 0;
  ${FontFamily.BoldFontW01};
  font-size: ${em('16px')};
  font-weight: bold;
  line-height: 1.38;
  color: ${theme.fontColor};
  &:focus,
  &:hover {
    outline: 1px dotted black;
  }
`;

export const Image = styled.img`
  margin-left: auto;
  object-fit: contain;
  width: ${props => (props.setRotate ? '13px' : '12px')};
`;

export const BrowseSection = styled.div`
  padding-left: ${rem('50px')};
  padding-right: ${rem('50px')};
  display: block;
  padding-top: ${rem('40px')};
  border-bottom: 1px solid ${theme.detailNotificationBorder};
  ${media.tablet`
		padding-left: ${rem('24px')};
    `};
  ${media.phone`
		padding-left: ${rem('15px')};
	`};
`;

export const HightlightDiv = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: ${rem('40px')};
  ${FontFamily.RegularFontW01};
  font-size: ${em('16px')};
  ${media.phone`
  margin-right: ${rem('18px')};
  font-size: ${em('14px')};
  `};
`;

export const CategoryDiv = styled.div`
  cursor: pointer;
  display: inline-block;
  padding-bottom: ${rem('18px')};
  border-bottom: ${rem('4px')} solid #db0011;
  ${FontFamily.BoldFontW01};
  font-size: ${em('16px')};
  ${media.phone`
    font-size: ${em('14px')};
    `};
`;

export const InfoDiv = styled.div`
    ${FontFamily.RegularFontW01}
  font-size: ${em('16px')}
  margin-top:${rem('40px')};
    margin-bottom: ${rem('10px')};`;

export const ProductGroupImage = styled.img`
  height: ${rem('21px')};
  width: ${rem('21px')};
  margin-right: ${rem('10px')};
  position: relative;
  bottom: ${rem('1px')};
`;

export const ProductTitle = styled.p`
  margin: 0;
`;

export const ExploreSectionWrapper = styled.div`
  padding: ${rem('40px')} ${rem('40px')} ${rem('16px')} ${rem('40px')};
  ${media.tablet`
    padding: ${rem('40px')} ${rem('24px')} ${rem('16px')} ${rem('24px')};
    `};
  ${media.phone`
    padding: ${rem('40px')} ${rem('15px')} ${rem('16px')} ${rem('15px')};
	`};
`;

export const CarouselWrapper = styled.div`
  height: 400px;
  background-color: ${theme.whiteColor};
  margin: auto;
  padding: ${rem('40px')} ${rem('40px')} ${rem('36px')} ${rem('40px')};

  ${media.miniDesktop`
    height: 450px;
  `};

  ${media.tablet`
    padding: ${rem('30px')} ${rem('30px')} ${rem('36px')} ${rem('30px')};

  `};

  ${media.phone`
    padding: ${rem('25px')} ${rem('15px')} ${rem('36px')} ${rem('15px')};
  `};
`;
export const CarouselBodyWrapper = styled.div`
  display: inline-block;
  position: relative;
  border: 1px solid ${theme.detailNotificationBorder};
  vertical-align: middle;
  width: ${rem('250px')};
  height: ${rem('415px')};
  margin: 0 ${rem('20px')} ${rem('20px')} 0;
  &:last-child {
    margin-right: 0;
  }
  ${media.tablet`
    width: ${rem('322px')};
    height: ${rem('405px')};
  `};
  ${media.phone`
    width: ${rem('260px')};
  `};
`;

export const CarouselImage = styled.img`
  margin: ${rem('1px')} 0 0 ${rem('1px')};
  width: ${rem('249px')};
  height: ${rem('102px')};
  ${media.tablet`
    width: ${rem('320px')};
    height: ${rem('137px')};
  `};
  ${media.phone`
    width: ${rem('258px')};
    height: ${rem('111px')};
  `};
`;

export const CarouselContentWrapper = styled.div`
  padding: ${rem('15px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
`;

export const CarouselTitleWrapper = styled.span`
  display: block;
  font-size: ${rem('20px')};
  line-height: ${rem('28px')};
`;
export const CarouselDescriptionWrapper = styled.span`
  display: block;
  margin-top: ${rem('20px')};
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
`;

export const CarouselDisclaimerWrapper = styled.p`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  margin-top: ${rem('10px')};
`;

export const SliderBackImage = styled.img`
  width: ${rem('32px')};
  height: ${rem('32px')};
  border: ${rem('32px')};
  opacity: ${props => props.disabled && 0.3};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${theme.whiteColor};
  background-color: ${theme.whiteColor};
`;

export const SliderForwardImage = styled.img`
  width: ${rem('32px')};
  height: ${rem('32px')};
  border: ${rem('32px')};
  opacity: ${props => props.disabled && 0.3};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${theme.whiteColor};
  background-color: ${theme.whiteColor};
`;
export const CarouselLinkWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: ${rem('20px')};
  display: flex;
  line-height: ${rem('22px')};
  font-size: ${rem('16px')};
  ${media.tablet`
  line-height: ${rem('20px')};
  `};
  ${media.phone`
  line-height: ${rem('18px')};
  `};
  > a {
    padding:0
    display: flex;
    cursor: pointer;
    background: ${theme.whiteColor};
    border: none;
    color: ${theme.fontColor};
    text-decoration:none;
    &:focus, &:hover {
      outline: 1px dotted black;
    }
}
`;

export const CarouselLinkTitle = styled.a`
  width: 85%;
  font-size: ${rem('16px')};
`;

export const PagingDotsWrapper = styled.ul`
  position: relative;
  margin: 0;
  padding: 0;
`;

export const PagingDotListItem = styled.li`
  list-style-type: none;
  display: inline-block;
  &:focus,
  &:hover {
    cursor: pointer;
  }
`;

export const PagingDotButton = styled.button`
  border: 0;
  background: transparent;
  color: ${props => (props.active ? '#db0011' : '#b2b2b2')};
  &:focus,
  &:hover {
    cursor: pointer;
  }
  padding: 0 ${rem('2px')} ${rem('8px')} ${rem('1px')};
  outline: 0;
  opacity: ${props => (props.active ? 1 : 0.5)};
`;

export const SimplyBookWrapper = styled(SummaryParentSection)`
  display: flex;
  padding: 0 ${rem('40px')} ${rem('25px')};
  ${media.tablet`
    flex-direction: column
		padding: 0 ${rem('30px')};
    `};
  ${media.phone`
		padding: 0 ${rem('20px')};
	`};
`;

export const SimplyBookDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.tablet`
    align-items: flex-start;
  `};
`;

export const SimplyBookDescription = styled.div`
  display: block;
  margin-left: ${rem('10px')};
  line-height: ${rem('20px')};
  width: auto;
  height: auto;
  ${media.phone`
    width: 100%;
    height: 100%;
  `};
`;

export const SimplyBookLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${rem('1px')} 0 0 ${rem('10px')};
  > a {
    padding:0
    display: flex;
    cursor: pointer;
    background: ${theme.whiteColor};
    border: none;
    color: ${theme.fontColor};
    text-decoration:none;
    &:focus, &:hover {
      outline: 1px dotted black;
    }
  }
  ${media.tablet`
      margin-left: 0;
      padding: ${rem('10px')} 0 0 ${rem('30px')};

    `};
  ${media.phone`
      padding: ${rem('18px')} 0 0 ${rem('25px')};
      justify-content: space-between;
  `};
  @media (max-width: 320px) {
    align-items: flex-start;
  }
`;

export const SimplyBookLink = styled.a`
  margin-right: ${rem('7px')};
  line-height: ${rem('24px')};
`;

export const SimplyBookIconWrapper = styled.span`
  width: ${rem('18px')};
  height: ${rem('18px')};
  display: block;
`;

export const PolicyCardTag = styled.span`
  width: ${rem('89px')};
  height: ${rem('28px')};
  padding: 5px 8px;
  background-color: #f3f3f3;
  margin-left: 8px;
  white-space: nowrap;
  font-size: 14px;
`;

export const SeeDetailsTag = styled.a``;
