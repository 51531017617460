import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';
import {
  getPolicyInfo,
  updateIdleFlag,
  startLoader,
  endLoader,
  updateSAASPageFail,
  getInstructionDetails,
  cancelRequest,
  updateLoaderOpacity,
  resetCancelErrorStore,
  setVIHLinkOnStatementPreference
} from '../app/appActions';
import {
  FullWidthSectionContainer,
  PolicyDetailSection,
  InstructionHistoryWrapper,
  InstructionHeader,
  Header,
  Text,
  HorizontalLine,
  OverviewSection,
  InstructionDisclaimer
} from './instructionHistoryStyle';
import ErrorBoundary from '../app/ErrorBoundary';
import { fetchSaasToken } from '../utils/saasAuthModuleUtil';
import { isApplicationStubbed } from '../utils/routeUtils';
import {
  getElement,
  isMiniMobile,
  setBackGroundColorDetailSummaryInstPage
} from '../utils/domUtils';
import Breadcrumbs from './components/BreadCrumbs';
import PolicyDetails from './components/PolicyDetails';
import RequestType from './components/RequestType';
import BackToParentLink from './components/BackToParentLink';
import {
  getInstructionListAsPerRequest,
  sortInstructionListByDate,
  getPolicyDataForExceptionPage
} from './instructionUtils';
import InstructionHistoryException from './components/exceptionsection/InstructionHistoryException';
import ContactLink from '../common/contactLink/contactLink';
import getContentInSpecificLang from '../utils/localeUtils';
import { trackViews } from '../utils/tealiumUtils';
import UpsellingBanners from '../common/upsellingBanner/UpsellingBanners';

class InstructionHistory extends Component {
  constructor(props) {
    super(props);
    this.policyObj = {};
    this.policySuperFamily = '';
    this.stackingFamily = '';
    this.productCategoryKey = '';
    this.serviceOptions = {};
    this.policyDetailsTealium = {};
    this.isDetailsHasError = false;
  }

  componentDidMount() {
    const {
      updateIdleState,
      startLoaderHandler,
      endLoaderHandler,
      updateSAASPageFailHandler
    } = this.props;
    startLoaderHandler();
    if (isApplicationStubbed()) {
      this.onPageMount();
    } else {
      fetchSaasToken()
        .then(() => {
          this.onPageMount();
        })
        .catch(() => {
          updateIdleState(true);
          updateSAASPageFailHandler();
          endLoaderHandler();
        });
    }
  }

  componentDidCatch(error, info) {
    /* eslint-disable no-console */
    // TODO: add UI-logger debug
    console.log('Error', error, info);
  }

  onPageMount() {
    window.scrollTo(0, 0);
    if (isMiniMobile()) {
      getElement('menuBtn');
    }
    const {
      fetchPolicyInfo,
      match,
      setVIHLinkOnStatementPreferenceHandler
    } = this.props;
    const {
      params: { encodedProductId }
    } = match;
    /**
     * Calling the policy saga which has the following logic
     *  1) Firstly do no api call if the policy information or policy details for a particular policy exists.
     *  2) Would be responsible to call the respective API, basis the encodedID or Not.
     *  3) Added the trackview for details page(When the encodedPolicyNumber present).
     *  4) Adding any new API call like rpq etc
     */
    fetchPolicyInfo(encodedProductId);
    setVIHLinkOnStatementPreferenceHandler({
      isVIHLinkVisitedFromEstmt: false
    });
  }

  getPolicyFromEncodeId(encodedProductId, byEncodeId) {
    this.policyObj = byEncodeId[encodedProductId];
  }

  fetchInstructionData = async (
    transCode,
    referenceNumber,
    apiCallCount,
    source
  ) => {
    const { fetchInstructionDetails, match } = this.props;
    const {
      params: { encodedProductId }
    } = match;
    // eslint-disable-next-line no-return-await
    return await fetchInstructionDetails({
      transCode,
      referenceNo: referenceNumber,
      encodedProductId,
      apiCallCount,
      source
    });
  };

  getFormFieldBasedOnRequestType = (requestTypeArray, langObj) => {
    const formFieldBasedOnRequestType = [];
    _.each(requestTypeArray, currentRequestType => {
      const { referenceNo, transCode } = currentRequestType;
      formFieldBasedOnRequestType.push(
        `${_.toLower(
          getContentInSpecificLang(`title_${transCode}`, 'en', langObj)
        )} ${referenceNo}`
      );
    });
    return _.uniq(formFieldBasedOnRequestType);
  };

  callTealiumView = function callTealiumView(
    requestTypeFilterFunds,
    langObj,
    planName,
    customerSegment
  ) {
    const { pending, history } = requestTypeFilterFunds;

    const formField1 = this.getFormFieldBasedOnRequestType(pending, langObj);
    const formField2 = this.getFormFieldBasedOnRequestType(history, langObj);

    trackViews({
      viewName: 'onInstructionPageLoad',
      formField1,
      formField2,
      planName,
      customerSegment
    });
  };

  render() {
    const {
      policyByEncodeId,
      match,
      pageSaasFail,
      isLoading,
      subProductCategories,
      selectedLocale,
      langObj,
      colorCodesForInstructionHistory,
      instHistoryResponse,
      dateFormatAsPerLocale,
      cancelRequestHandler,
      instHistoryWarningsOrErrors: { instructionHistoryErrors = [] },
      cancelRequestWarningsOrErrors,
      updateLoaderOpacityHandler,
      fetchInstructionDetails,
      cancelRequestData,
      resetCancelData,
      policyTagsMedicalAndCIConfig,
      customerSegment,
      upsellingBannersConfig
    } = this.props;
    const {
      params: { encodedProductId }
    } = match;
    setBackGroundColorDetailSummaryInstPage();
    if (_.isEmpty(policyByEncodeId)) {
      return null;
    }

    this.getPolicyFromEncodeId(encodedProductId, policyByEncodeId);
    const productCategory =
      (this.policyObj && this.policyObj.productCategory) ||
      (this.policyObj &&
        this.policyObj.policyDetails.policyOverview.productCategory);

    const instructionListToMap = instHistoryResponse[encodedProductId];
    const requestTypeFilterFunds = getInstructionListAsPerRequest(
      instructionListToMap
    );

    const policyCurrency = _.get(
      this.policyObj && this.policyObj.policyDetails.policyOverview,
      'policyCurrency'
    );

    const planName = _.get(
      this.policyObj && this.policyObj.policyDetails.policyOverview,
      'planName'
    );

    const policyNumber = _.get(
      this.policyObj && this.policyObj.policyDetails.policyOverview,
      'policyNumber'
    );

    this.callTealiumView(
      requestTypeFilterFunds,
      langObj,
      planName,
      customerSegment
    );
    if (instructionHistoryErrors && instructionHistoryErrors.length > 0) {
      return (
        <InstructionHistoryException
          policyDetailsTealium={{}}
          appData={getPolicyDataForExceptionPage(
            planName,
            encodedProductId,
            policyNumber
          )}
          apiErrors={instructionHistoryErrors}
        />
      );
    }

    return (
      <>
        <ErrorBoundary>
          <FullWidthSectionContainer>
            {!isLoading && pageSaasFail ? null : (
              <>
                <Breadcrumbs policyDetailsTealium={{}} />
                <OverviewSection>
                  <PolicyDetailSection>
                    <PolicyDetails
                      currentPolicyDetail={
                        this.policyObj && this.policyObj.policyDetails
                      }
                      productCategory={productCategory}
                      subProductCategories={subProductCategories}
                      selectedLocale={selectedLocale}
                      langObj={langObj}
                      summaryResponse={this.policyObj}
                      policyTagsMedicalAndCIConfig={
                        policyTagsMedicalAndCIConfig
                      }
                    />
                  </PolicyDetailSection>
                </OverviewSection>
                <InstructionHistoryWrapper>
                  <InstructionHeader>
                    <Header>
                      <Text>
                        <FormattedMessage id="instruction_history" />
                      </Text>
                    </Header>
                  </InstructionHeader>
                  <RequestType
                    requestTitle={<FormattedMessage id="pending_Request" />}
                    requestTitleToCheck="pending_Request"
                    insDetails={sortInstructionListByDate(
                      requestTypeFilterFunds.pending
                    )}
                    colorCodes={colorCodesForInstructionHistory}
                    fetchInstructionData={this.fetchInstructionData}
                    policyCurrency={policyCurrency}
                    dateFormatAsPerLocale={dateFormatAsPerLocale}
                    noRequestLabel="noRequest_pending"
                    cancelRequestHandler={cancelRequestHandler}
                    documentDownloadLoaderHandler={updateLoaderOpacityHandler}
                    planName={planName}
                    fetchInstructionDetails={fetchInstructionDetails}
                    encodePolicyNumber={encodedProductId}
                    policyNumber={policyNumber}
                    requestType="pending"
                    cancelRequestWarningsOrErrors={
                      cancelRequestWarningsOrErrors
                    }
                    langObj={langObj}
                    cancelRequestData={cancelRequestData}
                    resetCancelData={resetCancelData}
                    customerSegment={customerSegment}
                  />
                  <RequestType
                    requestTitle={<FormattedMessage id="request_history" />}
                    requestTitleToCheck="request_History"
                    insDetails={sortInstructionListByDate(
                      requestTypeFilterFunds.history
                    )}
                    colorCodes={colorCodesForInstructionHistory}
                    fetchInstructionData={this.fetchInstructionData}
                    policyCurrency={policyCurrency}
                    dateFormatAsPerLocale={dateFormatAsPerLocale}
                    noRequestLabel="noRequest_history"
                    cancelRequestHandler={cancelRequestHandler}
                    documentDownloadLoaderHandler={updateLoaderOpacityHandler}
                    planName={planName}
                    fetchInstructionDetails={fetchInstructionDetails}
                    encodePolicyNumber={encodedProductId}
                    policyNumber={policyNumber}
                    requestType="cancel"
                    cancelRequestWarningsOrErrors={
                      cancelRequestWarningsOrErrors
                    }
                    langObj={langObj}
                    cancelRequestData={cancelRequestData}
                    resetCancelData={resetCancelData}
                    customerSegment={customerSegment}
                  />
                  <InstructionDisclaimer>
                    <ContactLink
                      sentenceWithContact="instructionHistory_disclaimer"
                      contactsList={['lifeContactUsNumber']}
                      typeOfCall="general enquiry"
                    />
                  </InstructionDisclaimer>
                  <HorizontalLine />
                  <UpsellingBanners
                    upsellingBannersConfig={upsellingBannersConfig}
                  />
                  <HorizontalLine />
                  <BackToParentLink encodePolicyNumber={encodedProductId} />
                </InstructionHistoryWrapper>
              </>
            )}
          </FullWidthSectionContainer>
        </ErrorBoundary>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPolicyInfo: policyEncodeID => getPolicyInfo(policyEncodeID),
      updateIdleState: updateIdleFlag,
      startLoaderHandler: startLoader,
      endLoaderHandler: endLoader,
      updateSAASPageFailHandler: updateSAASPageFail,
      fetchInstructionDetails: (transCode, refNum) =>
        getInstructionDetails(transCode, refNum),
      cancelRequestHandler: refNum => cancelRequest(refNum),
      updateLoaderOpacityHandler: updateLoaderOpacity,
      resetCancelData: refNum => resetCancelErrorStore(refNum),
      setVIHLinkOnStatementPreferenceHandler: setVIHLinkOnStatementPreference
    },
    dispatch
  );

InstructionHistory.contextTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    configurations: {
      productConfig: { subProductFamilies },
      genericConfig: { colorCodesForInstructionHistory, dateFormatAsPerLocale },
      policyTagsMedicalAndCIConfig,
      upsellingBannersConfig
    },
    policy: { byEncodeId },
    app: { isLoading, pageSaasFail },
    content: { selectedLocale, langObj },
    instHistory: { instHistoryResponse, instHistoryWarningsOrErrors },
    cancelInstructionRequest: {
      cancelRequestWarningsOrErrors,
      cancelRequestData
    },
    customer: { customerSegment }
  } = state;
  return {
    policyByEncodeId: byEncodeId,
    subProductCategories: subProductFamilies,
    selectedLocale,
    isLoading,
    pageSaasFail,
    colorCodesForInstructionHistory,
    langObj,
    instHistoryResponse,
    dateFormatAsPerLocale,
    instHistoryWarningsOrErrors,
    cancelRequestWarningsOrErrors,
    cancelRequestData,
    policyTagsMedicalAndCIConfig,
    customerSegment,
    upsellingBannersConfig
  };
};

InstructionHistory.propTypes = {
  match: PropTypes.object.isRequired,
  fetchPolicyInfo: PropTypes.func.isRequired,
  policyByEncodeId: PropTypes.object.isRequired,
  subProductCategories: PropTypes.array.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  updateIdleState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageSaasFail: PropTypes.bool.isRequired,
  startLoaderHandler: PropTypes.func.isRequired,
  endLoaderHandler: PropTypes.func.isRequired,
  updateSAASPageFailHandler: PropTypes.func.isRequired,
  langObj: PropTypes.object.isRequired,
  colorCodesForInstructionHistory: PropTypes.object.isRequired,
  instHistoryResponse: PropTypes.object.isRequired,
  instHistoryWarningsOrErrors: PropTypes.array.isRequired,
  cancelRequestWarningsOrErrors: PropTypes.object.isRequired,
  dateFormatAsPerLocale: PropTypes.object.isRequired,
  fetchInstructionDetails: PropTypes.func.isRequired,
  cancelRequestHandler: PropTypes.func.isRequired,
  updateLoaderOpacityHandler: PropTypes.func.isRequired,
  cancelRequestData: PropTypes.func.isRequired,
  resetCancelData: PropTypes.func.isRequired,
  policyTagsMedicalAndCIConfig: PropTypes.object.isRequired,
  customerSegment: PropTypes.string.isRequired,
  setVIHLinkOnStatementPreferenceHandler: PropTypes.func.isRequired,
  upsellingBannersConfig: PropTypes.object.isRequired
};
InstructionHistory.defaultProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(InstructionHistory);
