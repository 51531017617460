import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  DataTextBold,
  OverviewInfoInsuredFieldDataValue
} from '../../detailPageStyle';

const OverviewInsuredAddress = props => {
  const { labelContent, fieldData } = props;

  if (
    !labelContent ||
    !fieldData ||
    typeof fieldData !== 'object' ||
    !fieldData.length
  ) {
    return <></>;
  }
  const insuredAddressArrayList = _.filter(fieldData, 'insuredAddress');

  const insuredAddressField = !insuredAddressArrayList.length ? (
    <></>
  ) : (
    <OverviewFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoInsuredFieldDataValue>
        {insuredAddressArrayList.map(insuredIndex => {
          return (
            <DataTextBold key={insuredIndex} name="true">
              {insuredIndex.insuredAddress}
            </DataTextBold>
          );
        })}
      </OverviewInfoInsuredFieldDataValue>
    </OverviewFieldWrapper>
  );

  return insuredAddressField;
};

OverviewInsuredAddress.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.array.isRequired
};

export default OverviewInsuredAddress;
