import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  PagingDotsWrapper,
  PagingDotListItem,
  PagingDotButton
} from '../summaryPageStyles';
import { isTab } from '../../utils/domUtils';

export default class PagingDots extends Component {
  getIndexes = (count, inc) => {
    const arr = [];
    for (let i = 0; i < count; i += inc) {
      arr.push(i);
    }
    return arr;
  };

  render() {
    const { slideCount, slidesToScroll, goToSlide } = this.props;
    let { currentSlide, slidesToShow } = this.props;
    currentSlide = Math.ceil(currentSlide);
    slidesToShow = Math.floor(slidesToShow);
    const setAriaLabel = (currentSlides, count, index) => {
      if (isTab()) {
        if (currentSlides === index) {
          return `Current Slide ${index + 1} and ${index + 2} of ${count}`;
        }
        return `Slide ${index + 1} and ${index + 2} of ${count}`;
      }
      if (currentSlides === index) {
        return `Current Slide ${index + 1} of ${count}`;
      }
      return `Slide ${index + 1} of ${count}`;
    };

    const indexes = this.getIndexes(
      slideCount - slidesToShow + 1,
      slidesToScroll
    );

    return (
      <PagingDotsWrapper>
        {indexes.map(index => {
          return (
            <PagingDotListItem key={index}>
              <PagingDotButton
                type="button"
                active={currentSlide === index}
                aria-label={setAriaLabel(currentSlide, slideCount, index)}
                onClick={() => {
                  goToSlide(index);
                }}
              >
                <span aria-hidden="true">&bull;</span>
              </PagingDotButton>
            </PagingDotListItem>
          );
        })}
      </PagingDotsWrapper>
    );
  }
}

PagingDots.propTypes = {
  slideCount: PropTypes.number.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  slidesToScroll: PropTypes.number.isRequired,
  currentSlide: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired
};

PagingDots.contextTypes = {
  intl: PropTypes.object.isRequired
};
