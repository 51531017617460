import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ChartWrapper } from './doughnutChartStyles';
import { chartColors } from './chartUtils';

class DoughnutChart extends Component {
  componentDidMount() {
    const { fieldData, uniqueID } = this.props;

    if (
      !fieldData ||
      !uniqueID ||
      typeof fieldData !== 'object' ||
      fieldData.length < 1
    ) {
      return;
    }
    const fieldNames = Object.keys(fieldData[0]);
    const chartCategory = fieldNames[0];
    let chartValue;
    fieldNames.forEach(fieldName => {
      // Check for integer type value in object that would be used to plot chart
      if (_.isNumber(fieldData[0][fieldName])) {
        chartValue = fieldName;
      }
    });
    if (!chartValue) {
      return;
    }

    const chart = window.am4core.create(uniqueID, window.am4charts.PieChart);

    chart.data = fieldData;

    // Set inner radius
    chart.innerRadius = window.am4core.percent(40);

    // Add and configure Series
    const pieSeries = chart.series.push(new window.am4charts.PieSeries());
    pieSeries.dataFields.category = chartCategory;
    pieSeries.dataFields.value = chartValue;
    pieSeries.slices.template.stroke = window.am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 3;
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.colors.list = [];

    chartColors.forEach(chartColor =>
      pieSeries.colors.list.push(window.am4core.color(chartColor))
    );
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Tootlip hiding
    pieSeries.slices.template.tooltipText = '';

    // Disable hover effect
    const hoverShift = pieSeries.slices.template.states.getKey('hover');
    hoverShift.properties.scale = 1;

    // Disable on active effect
    const activeShift = pieSeries.slices.template.states.getKey('active');
    activeShift.properties.shiftRadius = 0;

    // Disable ticks and labels
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    const { uniqueID } = this.props;
    return (
      <>
        <ChartWrapper id={uniqueID} />
      </>
    );
  }
}

DoughnutChart.propTypes = {
  fieldData: PropTypes.array.isRequired,
  uniqueID: PropTypes.array.isRequired
};

export default DoughnutChart;
