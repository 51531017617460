import PropTypes from 'prop-types';
import * as _ from 'lodash';
import React from 'react';
import { FirstHeaderSubField } from './beneficiarySectionStyles';
import BeneficiaryNilNameField from './BeneficiaryNilNameField';

const BeneficiaryFirstField = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    field,
    item,
    irrevocableAssigned,
    isNameFieldEmpty,
    beneficiaryType,
    missingInfoFlag
  } = props;

  const irrevocableBeneficiaryCondition = text =>
    _.trim(text, ' ').replace(' ', '');

  return (
    <>
      <BeneficiaryNilNameField
        beneficiaryName={field}
        isNameFieldEmpty={isNameFieldEmpty}
        beneficiaryType={beneficiaryType}
        missingInfoFlag={missingInfoFlag}
      />
      {irrevocableAssigned &&
        item?.irrevocableBeneficiary &&
        irrevocableBeneficiaryCondition(item?.irrevocableBeneficiary) !==
          '' && (
          <FirstHeaderSubField>
            {formatMessage({
              id: 'irrevocableBeneficiary'
            })}
          </FirstHeaderSubField>
        )}
    </>
  );
};

BeneficiaryFirstField.propTypes = {
  field: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.any),
  isNameFieldEmpty: PropTypes.bool.isRequired,
  beneficiaryType: PropTypes.string.isRequired,
  irrevocableAssigned: PropTypes.bool,
  missingInfoFlag: PropTypes.bool.isRequired
};

BeneficiaryFirstField.defaultProps = {
  item: null,
  irrevocableAssigned: false
};

BeneficiaryFirstField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BeneficiaryFirstField;
