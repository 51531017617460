import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldDataValue,
  DataTextBold
} from '../../detailPageStyle';
import getDescription from '../../../utils/reducerUtils';

const PaymentInfoNarrativeField = (props, context) => {
  const { labelContent, fieldData, dependentFieldData } = props;
  const {
    intl: { messages, locale, formatMessage }
  } = context;
  let paymentModeKey = '';

  if (dependentFieldData) {
    if (dependentFieldData.key) {
      if (
        messages[`paymentMode_label_${dependentFieldData.key}${fieldData.key}`]
      ) {
        paymentModeKey = `paymentMode_label_${dependentFieldData.key}${fieldData.key}`;
      } else {
        paymentModeKey = `paymentMode_label_${fieldData.key}`;
      }
    } else {
      return <></>;
    }
  } else {
    const fieldDataNarrativeArray =
      fieldData &&
      fieldData.narrative &&
      fieldData.key &&
      fieldData.narrative.length !== 0;
    if (!fieldDataNarrativeArray) {
      return <></>;
    }
  }

  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>
          {dependentFieldData
            ? formatMessage({
                id: paymentModeKey
              })
            : getDescription(locale, fieldData.narrative)}
        </DataTextBold>
      </OverviewInfoFieldDataValue>
    </PaymentInfoFieldWrapper>
  );
};

PaymentInfoNarrativeField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.object
};

PaymentInfoNarrativeField.defaultProps = {
  toolTipContent: null,
  dependentFieldData: null
};

PaymentInfoNarrativeField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PaymentInfoNarrativeField;
