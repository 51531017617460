import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LabelWrapper } from './OverviewField';
import {
  OverviewFieldWrapper,
  DataTextBold,
  OverviewInfoInsuredFieldDataValue
} from '../../detailPageStyle';
import getDescription from '../../../utils/reducerUtils';

const PolicyInsuredPerson = (props, context) => {
  const { labelContent, fieldData, dependentComponentData } = props;
  const {
    intl: { locale }
  } = context;
  if (
    !labelContent &&
    !fieldData &&
    typeof fieldData !== 'object' &&
    !fieldData.length &&
    !dependentComponentData &&
    !dependentComponentData.length
  ) {
    return <></>;
  }
  // getting  insuredObjectType from axaInsuredObjectDetails and memberName from memberInformation
  const insuredTypeArrayList = _.filter(fieldData, 'insuredObjectType');
  const memberArrayList = _.filter(dependentComponentData, 'memberName');
  // sorting memberName list by alphabetically
  const insuredMemberArrayList = _.sortBy(memberArrayList, ({ memberName }) =>
    memberName.toLowerCase()
  );

  if (!insuredTypeArrayList && !insuredMemberArrayList) {
    return <></>;
  }

  const insuredPerson =
    !insuredTypeArrayList.length && !insuredMemberArrayList.length ? (
      <></>
    ) : (
      <OverviewFieldWrapper id={labelContent}>
        <LabelWrapper {...props} />
        <OverviewInfoInsuredFieldDataValue>
          {insuredTypeArrayList.map(insuredIndex => {
            let insuredNameNarrative = '';
            const fieldDataNarrativeArray =
              insuredIndex.insuredObjectType &&
              insuredIndex.insuredObjectType.narrative &&
              insuredIndex.insuredObjectType.narrative.length !== 0;

            if (fieldDataNarrativeArray) {
              insuredNameNarrative = getDescription(
                locale,
                insuredIndex.insuredObjectType.narrative
              );
            }
            if (!fieldDataNarrativeArray || !insuredNameNarrative) {
              return <></>;
            }
            return (
              <DataTextBold key={insuredIndex} name="true">
                {insuredNameNarrative}
              </DataTextBold>
            );
          })}
          {insuredMemberArrayList.map(insuredMemberIndex => {
            return (
              <DataTextBold key={insuredMemberIndex.memberName} name="true">
                {insuredMemberIndex.memberName}
              </DataTextBold>
            );
          })}
        </OverviewInfoInsuredFieldDataValue>
      </OverviewFieldWrapper>
    );

  return insuredPerson;
};

PolicyInsuredPerson.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.array,
  dependentComponentData: PropTypes.array
};

PolicyInsuredPerson.defaultProps = {
  dependentComponentData: [],
  fieldData: [],
  id: '',
  labelContent: ''
};

PolicyInsuredPerson.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PolicyInsuredPerson;
