import styled from 'styled-components';
import { rem } from 'polished';
import media from '../utils/media';
import { FontFamily } from '../utils/font';
import CustomButton from '../common/customButton/CustomButton';

export const Content = styled.div`
  ${media.phone`
        height: calc(100% - (240px));
        display:block;
        margin-top:-70px
    `};
  display: flex;
  margin-left: -10px;
`;

export const ModalImage = styled.img`
  height: 231px;
  width: 308px;
  display: block;
  ${media.tablet`
    margin-top: 20px;
    `};
`;

export const DocumentGuidelinesModalLi = styled.li`
  line-height: 24px;
  font-size: ${rem('16px')};
  ${FontFamily.RegularFont};
`;

export const DocumentGuidelinesModalUl = styled.ul`
  padding-left: 30px;
  ${media.phone`
    margin-top: 0px;
    margin-bottom: 30px;
    `};
`;

export const OverlayHeaderStyles = styled.h2`
  margin-top: 65px;
  ${FontFamily.RegularFont};
  font-size: ${rem('35px')};
  color: #333333;
  line-height: 42px;
  ${media.tablet`
        font-size:${rem('28px')};
        margin-bottom: 30px;
        line-height: 36px;
        margin-top: 90px;
    `};
  ${media.phone`
        font-size: ${rem('28px')};
        margin-bottom: 20px;
        line-height: 30px;
        
    `};
`;

export const FirstRecapHeading = styled.h3`
  margin-top: ${props => (props.setMargin ? '20px' : '5px')};
  ${FontFamily.RegularFont};
  font-size: ${rem('16px')};
  color: #333333;
  line-height: 24px;
  font-weight: bold;
  margin-left: -10px;
`;

export const DownloadAllWrapper = styled.a`
  display: inline-block;
  font-size: ${props => (props.setbottomMargin ? `${rem('14px')}` : '')};
  margin-top: ${props => (props.setMargin ? '8px' : '12px')};
  margin-bottom: ${props => (props.setbottomMargin ? '8px' : '')};
  margin-left: ${props => (props.setLeftMergin ? '' : '33px')};
  font-weight: ${props => (props.setLeftMergin ? 'bold' : '')};
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'auto')};
  &:focus {
    outline: 1px dotted #333;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

export const Policy = styled.li`
  display: flex;
  font-size: ${props => (props.setbottomMargin ? `${rem('14px')}` : '')};
  margin-top: ${props => (props.setMargin ? '8px' : '12px')};
  margin-bottom: ${props => (props.setbottomMargin ? '8px' : '')};
  margin-left: ${props => (props.setLeftMergin ? '' : '33px')};
  font-weight: ${props => (props.setLeftMergin ? 'bold' : '')};
  cursor: ${props => (props.cursorPointer ? 'pointer' : 'auto')};
`;

export const Useful = styled.p`
  font-size: ${rem('16px')};
  line-height: 36px;
`;

export const ImgIcon = styled.span``;

export const NeedInfo = styled.ul`
  margin-left: ${props => (props.setMargin ? '-25px' : '25px')};
  margin-bottom: 24px;
  ${props => props.noPadding && `padding : 0;`}
`;

export const NeedInfoDiv = styled.div`
  margin-left: ${props => (props.setMargin ? '-25px' : '25px')};
  margin-bottom: 24px;
  ${props => props.noPadding && `padding : 0;`}
`;

export const InfoBox = styled.ul`
  margin: 0px 7px 24px -15px;
  padding: 24px 5px 20px 20px;
`;

export const ListItems = styled.li`
  margin-left: 5px;
  margin-bottom: 5px;
  color: #333;
  font-size: ${props =>
    props.isBigFont ? `${rem('16px')}` : `${rem('12px')}`};
  line-height: 1.33;
`;

export const NeedBox = styled.div`
  width: auto;
  margin: 24px 7px 24px -15px;
  padding: 20px;
  padding-bottom: 0px;
  border: solid 1px #d9d9d9;
  background-color: #fff;
`;

export const DocumentsBox = styled.div`
  background-color: #f8f8f8;
  padding: 0px 18px 0px;
  margin-top: 10px;
  font-size: ${rem('14px')};
  margin-left: 25px;
  display: ${props => (props.isMiniMobile ? '' : 'table')};
  width: ${props => (props.isMiniMobile ? '' : '93%')};
`;

export const CategorySection = styled.div`
  display: ${props => (props.isMiniMobile ? '' : 'table-row')};
  border-bottom: ${props => (props.hideBorder ? '' : 'solid 1px #d7d8d6')};
  padding-bottom: ${props => (props.isMiniMobile ? '14px' : '')};
`;

export const CategorySectionCol1 = styled.span`
  padding-top: 22px;
  padding-bottom: 22px;
  width: ${props => (props.isMiniMobile ? '' : '50%')};
  font-size: ${rem('14px')};
  display: inline-block;
  font-weight: bold;
  display: ${props => (props.isMiniMobile ? '' : 'table-cell')};
  border: ${props => (props.isMiniMobile ? '' : 'inherit')};
`;

export const DocumentList = styled.div`
  display: flex;
  padding-top: 3px;
`;

export const CategorySectionCol2 = styled.span`
  padding-top: 22px;
  padding-bottom: 22px;
  width: ${props => (props.isMiniMobile ? '' : '50%')};
  display: ${props => (props.isMiniMobile ? '' : 'table-cell')};
  border: ${props => (props.isMiniMobile ? '' : 'inherit')};
`;
export const LocaterButton = styled(CustomButton)`
  margin-top: 0px;
  margin-left: 346px;
  line-height: 24px;
  margin-right: 24px;
  width: 294px;
  padding: 9px 20px 11px;
  margin-bottom: -54px;
  position: relative;
  bottom: 5px
  &:focus {
    outline: 1px dotted #333;
    outline: -webkit-focus-ring-color auto 5px;
  }
  ${media.phone`
  width: 90%;
  margin :30px 15px ! important;
  margin-bottom: 20px;`};
`;

export const IconSpan = styled.span`
  margin-right: 10px;
`;

export const IconDiv = styled.span`
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const PdfLinks = styled.a`
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: 1px dotted #333;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

export const AccordionBox = styled.div`
  margin-top: 22px;
`;

export const AccordionInfo = styled.div`
  line-height: 24px;
  margin-top: ${props => (props.noMargin ? '' : '16px')};
  font-weight: ${props => (props.isBold ? '600' : '')};
  font-size: ${props =>
    props.isSmallFont ? `${rem('12px')}` : `${rem('16px')}`};
  padding-bottom: ${props => (props.showBorder ? '24px' : '')};
  border-bottom: ${props => (props.showBorder ? 'solid 1px #d7d8d6' : '')};
`;

export const Cardheading = styled.h3`
  font-weight: bold;
`;
