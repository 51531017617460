import * as _ from 'lodash';
import getLocaleSpecificUrl from '../../utils/reducerUtils';

/**
 * @param  {string} documentType
 * @param {Array Object} documentResponses
 * @param {string} locale
 * @description return links for the document type in respective language selected
 * @return {string, null} The url which will be providing the document in the respective language
 */
const getDocumentLink = function getDocumentLink(
  documentType,
  documentResponses = [],
  locale = 'en'
) {
  // Look for the documentType in the documentResponse
  if (documentType) {
    const documentResponse = _.find(documentResponses, function (documentInfo) {
      return documentInfo.documentType === documentType;
    });
    if (documentResponse) {
      let documentLink = getLocaleSpecificUrl(
        locale,
        documentResponse.documentDetails,
        'url'
      );
      if (documentLink) {
        documentLink += `?t=${Date.now()}`;
      }
      return documentLink;
    }
  }
  return null;
};
/**
 * check if any pending instruction for estatement and return booleans based
 * on the finding from instruction history , true: pending, false : no-pending
 * @param {Object} currentPolicyDetail
 * @returns boolean
 */
export const checkPendingEstatementInstruction = currentPolicyDetail => {
  let matchedInstruction;
  const pendingRequestList = _.get(
    currentPolicyDetail,
    'instructionHistoryDetails.pendingRequestList'
  );
  if (pendingRequestList) {
    matchedInstruction = _.find(pendingRequestList, {
      transCode: 'ESTATMNT',
      status: 'P'
    });
  }
  return !_.isUndefined(matchedInstruction);
};
export default getDocumentLink;

export const captureCustomerGroup = function captureCustomerGroup(
  customer,
  genericConfig
) {
  const { customerSegment } = customer;
  const { customerGroupForTealium } = genericConfig;
  return {
    ...(!_.isEmpty(customerSegment) && {
      customer_group: customerGroupForTealium[customerSegment]
    })
  };
};

export const getCurrentPolicyType = currentSuperFamily => {
  return {
    isLifePolicy: currentSuperFamily === 'life',
    isGIPolicy: currentSuperFamily === 'gi'
  };
};

export const SERVICING_OPTIONS_KEPT_HIDDEN = [
  'InstructionHistory'
];
export const P2G_DEMISE_SERVICING_OPTIONLIST = [
  'amendPlan',
  'ClaimHistory',
  'TransferOfFundsP2G',
  'ChangeInPremiumAllocation',
  'DividendOrCashBonusWithdrawal',
  'upgradePlan',
  'InstructionHistory',
  'ChangeInPremiumPaymentInstruction',
  'Withdrawal_Link',
  'ChangeInPremium_Link'
];

/**
 * check if current option like amandPlan, upgradePlan, etc should eligible to p2gDemise functionality
 * true: if can part of p2g demise list for gi policy, false : otherwise
 * @param currentPolicyTypePassed true/false which could be GI/Life bool variable passed from where this function called
 * @returns boolean
 */
export const isServicngOptionApplicableToDemise = (
  currentPolicyTypePassed,
  currentServicngOption
) =>
  currentPolicyTypePassed &&
  _.includes(P2G_DEMISE_SERVICING_OPTIONLIST, currentServicngOption);
