import styled from 'styled-components';
import { rem } from 'polished';
import media from '../utils/media';

export const ToggleSectionWrapper = styled.div`
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: solid 1px #d7d8d6;
  border-bottom: solid 1px #d7d8d6;
`;

export const ToggleTitleHeading = styled.h3`
  padding: 0;
`;

export const ToggleTitle = styled.button`
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  display: flex;
  background: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  text-decoration: none;
  text-align: initial;
  &:focus,
  &:hover {
    outline: 1px dotted #333;
  }
`;
export const ToggleImgWrapper = styled.span`
  cursor: pointer;
  width: 16px;
  height: 10px;
  position: absolute;
  top: 15px;
  right: 10px;
  padding-left: ${rem('8px')};
  ${media.mediumTablet`
    padding-left:${rem('10px')};
  `}
`;
export const ToggleDescription = styled.div``;

export const ImagePath = styled.img`
  height: 100%;
  width: 100%;
`;
