import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal } from 'ui-component-library';
import {
  ModalWrapper,
  ContentWrapper,
  FooterWrapper,
  ButtonsWrapper,
  Button
} from '../styles/modalStyles';
import {
  AnchorLinkStyle,
  AnchorLinkWrapper
} from '../customButton/CustomButtonStyledComp';
import CustomButton from '../customButton/CustomButton';
import ModalScrollHOC from './ModalScrollHOC';

export const PopUpTemplate = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    hideButton,
    isModalOpen,
    onClose,
    rootElement,
    modalHeading,
    footerButtonList,
    proceedToLink,
    btnHandler,
    children,
    contentStyle,
    id,
    maxWidth,
    hideHorizonatalLine
  } = props;

  return (
    <>
      <Modal
        id={id || 'max-amount-exceeded-modal'}
        data-focus={false}
        role="dialog"
        aria={{
          label: 'Label'
        }}
        appElement={rootElement}
        title={modalHeading}
        isOpen={isModalOpen}
        autoHeight
        showCloseButton
        closeButtonAriaValues={{
          'aria-label': formatMessage({ id: 'popupCloseButton' })
        }}
        onClose={() => onClose(!isModalOpen)}
        closeOnOverlayClick={false}
        style={contentStyle}
        maxWidth={maxWidth}
      >
        <ModalWrapper>
          <ContentWrapper modalHeading={modalHeading}>
            {children}
          </ContentWrapper>
          <FooterWrapper hideHorizonatalLine={hideHorizonatalLine} hideButton>
            {hideButton ? null : (
              <ButtonsWrapper>
                {_.map(footerButtonList, (field, index) => {
                  const { align } = field;
                  return (
                    <Button key={index} align={align} id={id}>
                      {field.id === 'cancel_btn' ? (
                        <CustomButton
                          className={field.className}
                          label={field.label}
                          type="button"
                          onClick={() => btnHandler[field.id](!isModalOpen)}
                          id={field.id}
                        />
                      ) : (
                        <AnchorLinkWrapper>
                          <AnchorLinkStyle
                            rel="noopener noreferrer"
                            id={field.id}
                            className={field.className}
                            label={field.label}
                            href={proceedToLink}
                            target="_blank"
                            onClick={() => {
                              btnHandler[field.id](!isModalOpen);
                            }}
                          >
                            {field.label}
                          </AnchorLinkStyle>
                        </AnchorLinkWrapper>
                      )}
                    </Button>
                  );
                })}
              </ButtonsWrapper>
            )}
          </FooterWrapper>
        </ModalWrapper>
      </Modal>
    </>
  );
};

PopUpTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  hideButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  rootElement: PropTypes.string,
  modalHeading: PropTypes.string.isRequired,
  proceedToLink: PropTypes.string.isRequired,
  footerButtonList: PropTypes.array.isRequired,
  btnHandler: PropTypes.object.isRequired,
  contentStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  maxWidth: PropTypes.string,
  hideHorizonatalLine: PropTypes.bool
};

PopUpTemplate.contextTypes = {
  intl: PropTypes.object.isRequired
};

PopUpTemplate.defaultProps = {
  rootElement: '#root',
  children: [],
  contentStyle: {},
  maxWidth: '48em',
  hideHorizonatalLine: false,
  hideButton: false
};

export default ModalScrollHOC(PopUpTemplate);
