import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  setEWelcomeDocumentsErrorsWarnings,
  endLoaderEWelcome,
  startLoaderEWelcome,
  setEWelcomeDownloadPolicyDocumentDetails,
  eWelcomePackAck
} from '../app/appActions';
import { E_WELCOME_DOCUMENT_RETRIEVE } from '../actionTypes';
import {
  policyDocumentsSagaErrorHandler,
  prepareEWelcomeDownloadDocumentAPIConfig,
  prepareEWelcomePolicyDocumentsStorePayload
} from '../utils/sagaUtils';
import { eWelcomePackEnabled } from '../utils/policyUtils';

const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* retrievePolicyDocument(action) {
  const {
    payload: {
      documentID,
      apiCallCount,
      encodePolicyNumber,
      documentCode,
      policy,
      policyNumber
    }
  } = action;
  try {
    const configurationObj = yield select(getConfigurations);
    const saasAuth = yield select(getSaasToken);
    const {
      genericConfig: { commonHeaders, addnCustomersHeader },
      serviceConfig: { eWelcomeRetrieveDocuments }
    } = configurationObj;
    const idcHeaders = {
      Authorization: `Bearer ${saasAuth}`,
      ...commonHeaders,
      ...addnCustomersHeader,
      'content-type': 'application/json',
      'X-HSBC-Global-Channel-Id': 'OHI',
      'Accept-Language': 'en-US,en;q=0.9',
      Cookie: ''
    };

    const documentRetrieveConfig = prepareEWelcomeDownloadDocumentAPIConfig(
      documentID,
      eWelcomeRetrieveDocuments,
      idcHeaders
    );

    yield put(startLoaderEWelcome());
    const documentResponse = yield call(axios, documentRetrieveConfig);
    const { data } = documentResponse;
    const policyDocumentsData = prepareEWelcomePolicyDocumentsStorePayload(
      documentID,
      data,
      encodePolicyNumber
    );
    if (policyDocumentsData && documentCode === 'WLT') {
      const eWelcomeEnabled = eWelcomePackEnabled(policy);
      if (eWelcomeEnabled) {
        yield put(eWelcomePackAck({ policyNumber }));
      }
    }
    yield put(setEWelcomeDownloadPolicyDocumentDetails(policyDocumentsData));
    yield put(endLoaderEWelcome());
  } catch (err) {
    let errorCount = apiCallCount;
    const errorDispatchObject = policyDocumentsSagaErrorHandler(
      documentID,
      err
    );
    if (errorCount < 2) {
      errorCount += 1;
      yield retrievePolicyDocument({
        payload: {
          documentID,
          apiCallCount: errorCount,
          encodePolicyNumber,
          documentCode,
          policy,
          policyNumber
        }
      });
    } else {
      yield put(setEWelcomeDocumentsErrorsWarnings(errorDispatchObject));

      yield put(endLoaderEWelcome());
    }
  }
}

export default function* eWelcomeDocumentRetrieveSaga() {
  yield takeEvery(E_WELCOME_DOCUMENT_RETRIEVE, retrievePolicyDocument);
}
