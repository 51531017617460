import theme from '../../../common/styles/theme';

export const FundsRiskLevelDataList = [
  {
    riskLevelColor: `${theme.fundRating1}`,
    riskLevelRating: 1,
    riskLevel: 'riskLevelRating1',
    riskDescription: 'riskLevel_Low_Description'
  },
  {
    riskLevelColor: `${theme.fundRating2}`,
    riskLevelRating: 2,
    riskLevel: 'riskLevelRating2',
    riskDescription: 'riskLevel_Low_To_Medium_Description'
  },
  {
    riskLevelColor: `${theme.fundRating3}`,
    riskLevelRating: 3,
    riskLevel: 'riskLevelRating3',
    riskDescription: 'riskLevel_Medium_Description'
  },
  {
    riskLevelColor: `${theme.fundRating4}`,
    riskLevelRating: 4,
    riskLevel: 'riskLevelRating4',
    riskDescription: 'riskLevel_Medium_To_High_Description'
  },
  {
    riskLevelColor: `${theme.fundRating5}`,
    riskLevelRating: 5,
    riskLevel: 'riskLevelRating5',
    riskDescription: 'riskLevel_High_Description'
  }
];

export default FundsRiskLevelDataList;
