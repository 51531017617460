import React from 'react';

const Context = React.createContext({
  currencyCodes: [],
  copyResponse: {},
  applicationUrl: {},
  dateFormatAsPerLocale: {},
  colorCodesForPolicyStatus: {}
});
export default Context;
