import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  setEWelcomeDocumentsErrorsWarnings,
  endLoaderEWelcome,
  startLoaderEWelcome,
  setEWelcomeDownloadAllDetails,
  eWelcomePackAck
} from '../app/appActions';
import { E_WELCOME_DOWNLOAD_ALL } from '../actionTypes';
import {
  policyDocumentsSagaErrorHandler,
  prepareEWelcomeDownloadAllAPIConfig,
  prepareEWelcomePolicyDocumentsStorePayload
} from '../utils/sagaUtils';
import { eWelcomePackEnabled } from '../utils/policyUtils';

const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* eWelcomeDownloadAllDocs(action) {
  const {
    payload: { encodePolicyNumber, policyNumber, documentIDList, policy }
  } = action;
  try {
    const configurationObj = yield select(getConfigurations);
    const saasAuth = yield select(getSaasToken);
    const {
      genericConfig: { commonHeaders, addnCustomersHeader },
      serviceConfig: { eWelcomeDownloadAll }
    } = configurationObj;
    const idcHeaders = {
      Authorization: `Bearer ${saasAuth}`,
      ...commonHeaders,
      ...addnCustomersHeader,
      'Content-Type': 'application/json',
      'X-HSBC-Global-Channel-Id': 'OHI',
      'Accept-Language': 'en_HK'
    };

    const reqPayload = {
      policyNumber,
      documentIDList
    };

    const getDocumentsConfig = prepareEWelcomeDownloadAllAPIConfig(
      policyNumber,
      eWelcomeDownloadAll,
      idcHeaders,
      reqPayload
    );

    yield put(startLoaderEWelcome());
    const documentResponse = yield call(axios, getDocumentsConfig); // add request payload
    const { data } = documentResponse;
    const policyDocumentsData = prepareEWelcomePolicyDocumentsStorePayload(
      policyNumber,
      data,
      encodePolicyNumber
    );
    if (policyDocumentsData) {
      const eWelcomeEnabled = eWelcomePackEnabled(policy);
      if (eWelcomeEnabled) {
        yield put(eWelcomePackAck({ policyNumber }));
      }
    }
    yield put(setEWelcomeDownloadAllDetails(policyDocumentsData));
    yield put(endLoaderEWelcome());
  } catch (err) {
    const errorDispatchObject = policyDocumentsSagaErrorHandler(
      policyNumber,
      err
    );
    yield put(setEWelcomeDocumentsErrorsWarnings(errorDispatchObject));
    yield put(endLoaderEWelcome());
  }
}

export default function* eWelcomeDownloadAllSaga() {
  yield takeEvery(E_WELCOME_DOWNLOAD_ALL, eWelcomeDownloadAllDocs);
}
