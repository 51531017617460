import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import CurrencyWithSymbol from '../../../common/currency/CurrencyWithSymbol';

const FundAmount = function FundAmount(props) {
  const { fieldData: { currency, amount } = {}, decimalRange } = props;

  if (!amount || !currency) {
    return '-';
  }
  return (
    <>
      <ShowIf condition={!!currency && !!amount}>
        <CurrencyWithSymbol
          decimalRange={decimalRange}
          currencyCode={currency}
          amount={amount}
        />
      </ShowIf>
    </>
  );
};

FundAmount.propTypes = {
  fieldData: PropTypes.array.isRequired,
  decimalRange: PropTypes.number
};
FundAmount.defaultProps = {
  decimalRange: 2
};
export default FundAmount;
