import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  BaneerDescription,
  BannerDetails,
  BannerImage,
  BannerLink,
  BannerLinkDescription,
  BannerLinkWrapper,
  BannerWrapper,
  CaretImg,
  StepTitleDiv,
  StepTitleHeader
} from '../styles/bannerStyles';
import caretIcon from '../../assets/images/Caret.svg';

const UpsellingBanners = (props, context) => {
  const {
    upsellingBannersConfig: { bannersList }
  } = props;
  const {
    intl: { locale, formatMessage }
  } = context;

  return (
    <>
      <StepTitleDiv id="upsellingBannerTitle_Header">
        <StepTitleHeader>
          {formatMessage({ id: 'upsellingBannerTitle' })}
        </StepTitleHeader>
      </StepTitleDiv>
      <BannerWrapper>
        {_.map(bannersList, currentBanner => {
          const {
            productImage,
            productName,
            productDescription,
            productJourneyLink: { journeyLinkPath, isOpenInNewTab }
          } = currentBanner;
          return (
            <BannerDetails>
              <BannerImage aria-hidden="true" alt="banner" src={productImage} />
              <BannerLinkDescription>
                <BannerLinkWrapper>
                  <BannerLink
                    href={journeyLinkPath[locale]}
                    target={isOpenInNewTab ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                  >
                    {formatMessage({ id: productName })}
                  </BannerLink>
                  <CaretImg aria-hidden="true" alt="caret" src={caretIcon} />
                </BannerLinkWrapper>
                <BaneerDescription>
                  {formatMessage({ id: productDescription })}
                </BaneerDescription>
              </BannerLinkDescription>
            </BannerDetails>
          );
        })}
      </BannerWrapper>
    </>
  );
};

UpsellingBanners.contextTypes = {
  intl: PropTypes.object.isRequired
};

UpsellingBanners.propTypes = {
  upsellingBannersConfig: PropTypes.object.isRequired
};

export default UpsellingBanners;
