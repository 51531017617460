import { select, takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { GET_CORE_FUND_DETAILS } from '../actionTypes';
import {
  setCoreFundDetails,
  setFundSelectionErrorsWarnings
} from '../app/appActions';
import { prepareServiceConfigPAPI, papiErrorHandler } from '../utils/sagaUtils';

const getCustomerIdentifier = state => state.customer.customerIdentifier;
const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* getCoreFundDetails(fundSelectionRequestParams) {
  try {
    const customerIdentifier = yield select(getCustomerIdentifier);
    const configurationObj = yield select(getConfigurations);
    if (customerIdentifier) {
      const {
        genericConfig: { commonHeaders },
        serviceConfig: { fundSelection }
      } = configurationObj;
      const saasAuth = yield select(getSaasToken);
      const headers = {
        ...commonHeaders,
        Authorization: `Bearer ${saasAuth}`,
        'X-HSBC-CustomerId': customerIdentifier
      };
      const {
        planCode,
        customerRpqLevel,
        policyCurrency
      } = fundSelectionRequestParams.payload;
      let serviceConfigUrl = fundSelection.url.replace('{plancode}', planCode);
      serviceConfigUrl = serviceConfigUrl.replace(
        '{currencyCode}',
        policyCurrency
      );
      serviceConfigUrl = serviceConfigUrl.replace(
        '{riskLevel}',
        customerRpqLevel
      );

      const fundDetailsConfig = prepareServiceConfigPAPI(
        fundSelection,
        serviceConfigUrl,
        headers
      );
      const fundDetailsResponse = yield call(axios, fundDetailsConfig);
      const fundDetailsResponseData = fundDetailsResponse.data;
      yield put(setCoreFundDetails(fundDetailsResponseData));
    }
  } catch (err) {
    if (err && err.response) {
      const errorDispatchObject = papiErrorHandler(err.response);
      const { message } = errorDispatchObject;
      yield put(
        setFundSelectionErrorsWarnings({
          errors: [
            {
              errorCode: errorDispatchObject['exception-code'],
              causes: message
            }
          ]
        })
      );
    } else {
      yield put(
        setFundSelectionErrorsWarnings({
          errors: [{ errorCode: 'UI0001', causes: 'Invalid Request' }]
        })
      );
    }
  }
}

export default function* fundSelectionSaga() {
  yield takeEvery(GET_CORE_FUND_DETAILS, getCoreFundDetails);
}
