import React from 'react';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../PolicyOverview/OverviewField';
import {
  PaymentInfoFieldWrapper,
  OverviewInfoFieldAmountCurrency,
  LevyLocalCurrency,
  LevyWrapper
} from '../../detailPageStyle';
import Currency from '../../../common/currency/Currency';

const PaymentInfoLevyField = props => {
  const {
    id,
    fieldData,
    labelContent,
    sectionHeading,
    dependentFieldData,
    toolTipContent
  } = props;
  if (
    !fieldData.currency ||
    fieldData.currency === '' ||
    (!fieldData.amount && fieldData.amount !== 0)
  ) {
    return null;
  }
  return (
    <PaymentInfoFieldWrapper id={labelContent}>
      <LabelWrapper
        id={id}
        labelContent={labelContent}
        toolTipContent={toolTipContent}
        sectionHeading={sectionHeading}
        isFormatHtml
      />
      <OverviewInfoFieldAmountCurrency>
        <LevyWrapper>
          <Currency
            currencyCode={fieldData.currency}
            amount={fieldData.amount}
          />
          {dependentFieldData.currency &&
            dependentFieldData.currency !== '' &&
            dependentFieldData.amount !== undefined && (
              <LevyLocalCurrency>
                (
                <Currency
                  currencyCode={dependentFieldData.currency}
                  amount={dependentFieldData.amount}
                />
                )
              </LevyLocalCurrency>
            )}
        </LevyWrapper>
      </OverviewInfoFieldAmountCurrency>
    </PaymentInfoFieldWrapper>
  );
};

PaymentInfoLevyField.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  fieldData: PropTypes.object.isRequired,
  toolTipContent: PropTypes.string,
  dependentFieldData: PropTypes.object
};

PaymentInfoLevyField.defaultProps = {
  toolTipContent: null,
  dependentFieldData: {}
};

export default PaymentInfoLevyField;
