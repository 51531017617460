import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';
import {
  OverviewFieldWrapper,
  OverviewInfoFieldLabelKey,
  OverviewInfoFieldDataValue,
  LabelText,
  DataTextBold
} from '../../detailPageStyle';

export const LabelWrapper = ({
  id,
  labelContent,
  toolTipContent,
  sectionHeading,
  isFormatHtml
}) => (
  <OverviewInfoFieldLabelKey>
    <LabelText>
      <FormattedMessage id={labelContent} />
      <TooltipWrapper
        id={`tooltip_${id}`}
        contentKey={toolTipContent}
        sectionHeading={sectionHeading}
        labelContent={labelContent}
        isFormatHtml={isFormatHtml}
      />
    </LabelText>
  </OverviewInfoFieldLabelKey>
);
const OverviewField = props => {
  const { fieldData, labelContent } = props;
  if (!labelContent || !fieldData || typeof fieldData !== 'string') {
    return <></>;
  }
  return (
    <OverviewFieldWrapper id={fieldData}>
      <LabelWrapper {...props} />
      <OverviewInfoFieldDataValue>
        <DataTextBold>{fieldData}</DataTextBold>
      </OverviewInfoFieldDataValue>
    </OverviewFieldWrapper>
  );
};

LabelWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  sectionHeading: PropTypes.string.isRequired,
  toolTipContent: PropTypes.string,
  isFormatHtml: PropTypes.bool
};

LabelWrapper.defaultProps = {
  toolTipContent: null,
  isFormatHtml: false
};

OverviewField.propTypes = {
  labelContent: PropTypes.string.isRequired,
  fieldData: PropTypes.string,
  toolTipContent: PropTypes.string
};

OverviewField.defaultProps = {
  toolTipContent: null,
  fieldData: ''
};

export default OverviewField;
