import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  UpdateStmtPreWrapper,
  UpdateStmtPrefHeading,
  NewStmtPrefLabel,
  NewStmtPrefValue,
  NoteSection,
  UpdateStmtPrefSection
} from '../instructionHistoryStyle';
import { getFieldData } from '../instructionUtils';

const UpdateStatementPreference = props => {
  const { changeStatementPreference } = props;

  const optinEstatement = getFieldData(
    changeStatementPreference,
    'optinEstatement'
  );

  return (
    <UpdateStmtPreWrapper>
      <UpdateStmtPrefHeading>
        <FormattedMessage id="newStmtPrefLable" />
      </UpdateStmtPrefHeading>
      <UpdateStmtPrefSection>
        <NewStmtPrefLabel>
          <FormattedMessage id="yourNewStmtPrefLable" />
        </NewStmtPrefLabel>
        <NewStmtPrefValue>
          <FormattedHTMLMessage
            id={optinEstatement === 'N' ? 'paperStmtLable' : 'eStmtLable'}
          />
        </NewStmtPrefValue>
      </UpdateStmtPrefSection>
      <NoteSection>
        <FormattedMessage id="stmtPrefNoticeLable" />
      </NoteSection>
    </UpdateStmtPreWrapper>
  );
};

UpdateStatementPreference.propTypes = {
  changeStatementPreference: PropTypes.object
};

UpdateStatementPreference.defaultProps = {
  changeStatementPreference: {}
};
export default UpdateStatementPreference;
