import React from 'react';
import PropTypes from 'prop-types';
import linkIcon from '../../../../assets/images/Newmodal.svg';
import {
  FundDetailLinkIconWrapper,
  FundDetailLink,
  LinkImgSpan,
  LinkImage
} from '../../../../common/styles/fundsRPQPopupStyle';
import getDescription from '../../../../utils/reducerUtils';
import { isIE } from '../../../../utils/domUtils';

const FundDetails = function FundDetails(props, context) {
  const {
    fundName,
    fundCode,
    rpqUTB: { url, locale, target, rel }
  } = props;
  const {
    intl: { locale: selectedLocale }
  } = context;
  const placeholderLocale = locale[selectedLocale];
  let rpqUTBLink = url.replace(/placeholder_fundCode/gi, fundCode);
  rpqUTBLink = rpqUTBLink.replace(/placeholder_locale/gi, placeholderLocale);
  const getFundName = getDescription(selectedLocale, fundName);
  return (
    <FundDetailLinkIconWrapper isIE={isIE} id="fundDetailLinkIconWrapper">
      <FundDetailLink
        id={`${fundCode}_link`}
        href={rpqUTBLink}
        rel={rel}
        target={target}
        icon={linkIcon}
      >
        {getFundName}
        <LinkImgSpan
          imageWidth={16}
          imageHeight={16}
          marginRight={0}
          marginLeft={5}
        >
          <LinkImage src={linkIcon} alt="" aria-hidden="true" />
        </LinkImgSpan>
      </FundDetailLink>
    </FundDetailLinkIconWrapper>
  );
};

FundDetails.propTypes = {
  fundName: PropTypes.array.isRequired,
  fundCode: PropTypes.string.isRequired,
  rpqUTB: PropTypes.object.isRequired
};

FundDetails.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundDetails;
