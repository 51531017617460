export const COMPLETE_APP_BOOTSTRAP = 'COMPLETE_APP_BOOTSTRAP';
export const START_LOADER = 'START_LOADER';
export const END_LOADER = 'END_LOADER';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const GET_CONTENT = 'GET_CONTENT';
export const ADD_LANG_DATA = 'ADD_LANG_DATA';
export const SET_SAAS_TOKEN = 'SET_SAAS_TOKEN';
export const MERGE_PREVIEW_CONTENT = 'MERGE_PREVIEW_CONTENT';
export const GET_EXTERNAL_CONFIGURATIONS = 'GET_EXTERNAL_CONFIGURATIONS';
export const SET_EXTERNAL_CONFIGURATION = 'SET_EXTERNAL_CONFIGURATION';
export const INIT_LOGGER = 'INIT_LOGGER';
export const LOGGER_READY = 'LOGGER_READY';
export const APP_BOOTSTRAP = 'APP_BOOTSTRAP';
export const SERVICE_CONFIGS_STUBBING = 'SERVICE_CONFIGS_STUBBING';
export const REFRESH_SAAS_TOKEN = 'REFRESH_SAAS_TOKEN';
export const SET_IDLE_TIMER = 'SET_IDLE_TIMER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const GET_POLICY = 'GET_POLICY';
export const SET_POLICY = 'SET_POLICY';
export const SET_POLICY_SUMMARY = 'SET_POLICY_SUMMARY';
export const SET_ERRORS_WARNINGS = 'SET_ERRORS_WARNINGS';
export const SET_IS_STUBBED = 'SET_IS_STUBBED';
export const SET_POLICY_CHECK_VALUE = 'SET_POLICY_CHECK_VALUE';
export const SET_POLICY_STATE = 'SET_POLICY_STATE';
export const SET_ANCHORING_TO_POLICIES = 'SET_ANCHORING_TO_POLICIES';
export const SET_IDLE_FLAG = 'SET_IDLE_FLAG';
export const SET_PAGE_SAAS_FAIL = 'SET_PAGE_SAAS_FAIL';
export const SET_PAGE_CONTACTUS_POP = 'SET_PAGE_CONTACTUS_POP';
export const SET_SELECT_TAB = 'SET_SELECT_TAB';
// Reprojection action types
export const GET_REPROJECTIONS = 'GET_REPROJECTIONS';
export const SET_REPROJECTIONS = 'SET_REPROJECTIONS';
export const SET_REPROJECTIONS_ERRORS_WARNINGS =
  'SET_REPROJECTIONS_ERRORS_WARNINGS';
export const SET_REPROJECTIONS_AGE_SELECTION =
  'SET_REPROJECTIONS_AGE_SELECTION';
export const SET_REPROJECTIONS_MIN_AGE = 'SET_REPROJECTIONS_MIN_AGE';
export const SET_REPROJECTIONS_MAX_AGE = 'SET_REPROJECTIONS_MAX_AGE';
export const SET_INCLUDE_NON_GUARANTEED_PAYOUT =
  'SET_INCLUDE_NON_GUARANTEED_PAYOUT';
export const TOGGLE_INCLUDE_NON_GUARANTEED_PAYOUT =
  'TOGGLE_INCLUDE_NON_GUARANTEED_PAYOUT';
export const SET_REPROJECTIONS_SELECTED_TAB = 'SET_REPROJECTIONS_SELECTED_TAB';
export const RESET_REPROJECTIONS_ERRORS_WARNINGS =
  'RESET_REPROJECTIONS_ERRORS_WARNINGS';
export const SET_POLICY_DOCUMENTS_DETAILS = 'SET_POLICY_DOCUMENTS_DETAILS';
export const GET_POLICY_DOCUMENTS_DETAILS = 'GET_POLICY_DOCUMENTS_DETAILS';
export const SET_DOCUMENTS_ERRORS_WARNINGS = 'SET_DOCUMENTS_ERRORS_WARNINGS';
export const RESET_DOCUMENTS_ERRORS_WARNINGS =
  'RESET_DOCUMENTS_ERRORS_WARNINGS';
export const RESET_DOCUMENTS_DETAILS = 'RESET_DOCUMENTS_DETAILS';
export const E_WELCOME_PACK_ACK = 'E_WELCOME_PACK_ACK';
export const E_WELCOME_DOCUMENT_RETRIEVE = 'E_WELCOME_DOCUMENT_RETRIEVE';
export const E_WELCOME_GET_DOCUMENTS = 'E_WELCOME_GET_DOCUMENTS';
export const SET_E_WELCOME_DOCUMENT_RETRIEVE =
  'SET_E_WELCOME_DOCUMENT_RETRIEVE';
export const SET_E_WELCOME_GET_DOCUMENTS = 'SET_E_WELCOME_GET_DOCUMENTS';
export const E_WELCOME_RESET_DOCUMENTS_ERRORS_WARNINGS =
  'E_WELCOME_RESET_DOCUMENTS_ERRORS_WARNINGS';
export const SET_E_WELCOME_DOCUMENTS_ERRORS_WARNINGS =
  'SET_E_WELCOME_DOCUMENTS_ERRORS_WARNINGS';
export const E_WELCOME_START_LOADER = 'E_WELCOME_START_LOADER';
export const E_WELCOME_END_LOADER = 'E_WELCOME_END_LOADER';
export const POLICY_DETAILS_START_LOADER = 'POLICY_DETAILS_START_LOADER';
export const E_WELCOME_DOWNLOAD_ALL = 'E_WELCOME_DOWNLOAD_ALL';
export const SET_E_WELCOME_DOWNLOAD_ALL_DETAILS =
  'SET_E_WELCOME_DOWNLOAD_ALL_DETAILS';

export const SET_INSTRUCTION_HISTORY = 'SET_INSTRUCTION_HISTORY';
export const GET_INSTRUCTION_DETAIL = 'GET_INSTRUCTION_DETAIL';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS';
export const CANCEL_REQUEST_ERRORS_WARNINGS = 'CANCEL_REQUEST_ERRORS_WARNINGS';
export const SET_INSTRUCTION_HISTORY_ERRORS_WARNINGS =
  'SET_INSTRUCTION_HISTORY_ERRORS_WARNINGS';
export const GET_CORE_FUND_DETAILS = 'GET_CORE_FUND_DETAILS';
export const SET_CORE_FUND_DETAILS = 'SET_CORE_FUND_DETAILS';
export const SET_FUND_SELECTION_ERRORS_WARNINGS =
  'SET_FUND_SELECTION_ERRORS_WARNINGS';
// View instruction/document download loader handler
export const UPDATE_LOADER_OPACITY = 'UPDATE_LOADER_OPACITY';
export const SET_INSTRUCTION_HISTORY_DETAIL_ERROR =
  'SET_INSTRUCTION_HISTORY_DETAIL_ERROR';
export const RESET_CANCER_ERROR_STORE = 'RESET_CANCER_ERROR_STORE';
export const SET_GA_ELIGIBLE = 'SET_GA_ELIGIBLE';
export const SUBMIT_ESTATEMENT_PREFERENCE = 'SUBMIT_ESTATEMENT_PREFERENCE';
export const SET_ESTATEMENT_PREFERENCE_ACK_DETAILS =
  'SET_ESTATEMENT_PREFERENCE_ACK_DETAILS';
export const SET_ESTATEMENT_PREFERENCE_WARNINGS_OR_ERRORS =
  'SET_ESTATEMENT_PREFERENCE_WARNINGS_OR_ERRORS';
export const SAVE_PAPER_STATEMENT_PREFERENCE =
  'SAVE_PAPER_STATEMENT_PREFERENCE';
export const SET_VIH_LINK_STATEMENT_PREFERENCE =
  'SET_VIH_LINK_STATEMENT_PREFERENCE';

export const REMOVE_POLICY_DETAIL = 'REMOVE_POLICY_DETAIL';
