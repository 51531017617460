import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

const env = process.env.REACT_APP_ENV;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const enhancers =
  env !== 'prod'
    ? composeEnhancers(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware);

const configureStore = (initialState = {}) => {
  const store = createStore(reducer, initialState, enhancers);
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
