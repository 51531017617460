import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CustomInputBoxWrapper,
  InputBoxButton,
  InputBoxImage,
  InputField,
  AccessibleHidden
} from '../styles/commonStyle';
import additionButton from '../../assets/images/additionButton.svg';
import substractionButton from '../../assets/images/substractionButton.svg';
import additionButtonDisabled from '../../assets/images/additionButtonDisabled.svg';
import substractionButtonDisabled from '../../assets/images/substractionButtonDisabled.svg';
import { trackEvents } from '../../utils/tealiumUtils';

const CustomInputBox = (props, context) => {
  const {
    setValue,
    minAllowedVal,
    maxAllowedVal,
    setOutOfRange,
    inputValue,
    setInputValue,
    policyYearValue
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  const inputRef = useRef(null);

  const inputBoxTealiumEvent = {
    plusButton: '+',
    minusButton: '-',
    agaeLabel: 'age: '
  };

  const { plusButton, minusButton, agaeLabel } = inputBoxTealiumEvent;

  const blurHandler = function blurHandler() {
    if (+inputValue < minAllowedVal || +inputValue > maxAllowedVal) {
      setInputValue(minAllowedVal);
      setOutOfRange(true);
      setValue(minAllowedVal);
    } else {
      setValue(+inputValue);
      setOutOfRange(false);
    }
  };

  const verifyValue = function verifyValue(val) {
    if (`${val}`.length <= 2) {
      setInputValue(val);
    }
  };

  const verifyClick = function verifyClick(val, inputBoxButton) {
    if (val >= minAllowedVal && val <= maxAllowedVal) {
      setInputValue(val);
      setValue(val);
      setOutOfRange(false);
      trackEvents({
        eventName: 'commonButtonEvents',
        eventContent: `${agaeLabel}${inputBoxButton}`
      });
    }
  };
  const ageLabel = `${formatMessage({
    id: 'payoutBenefitAgeLabel'
  })} ${inputValue}`;
  const policyYearLabel = `${formatMessage({
    id: 'payoutBenefitPolicyYearLabel'
  })} ${policyYearValue}`;
  const ariaTextUpdatedAgePolicyValue = ` ${ageLabel} ${policyYearLabel}`;

  return (
    <CustomInputBoxWrapper ref={inputRef}>
      <InputBoxButton
        id="minusButton"
        onClick={() => {
          verifyClick(+inputValue - 1, minusButton);
        }}
        aria-label={formatMessage({ id: 'ariaTextMinusButton' })}
        aria-describedby="decInputAge ifPlusButtonDisabled"
        aria-hidden={+inputValue === minAllowedVal}
        tabIndex={+inputValue === minAllowedVal ? -1 : 0}
        disabled={+inputValue === minAllowedVal}
      >
        <InputBoxImage
          src={
            +inputValue === minAllowedVal
              ? substractionButtonDisabled
              : substractionButton
          }
          alt=""
          aria-hidden="true"
        />
        {+inputValue === minAllowedVal && (
          <AccessibleHidden id="ifMinusButtonDisabled">
            {formatMessage({ id: 'minusButtonDisabledAriaLabel' })}
          </AccessibleHidden>
        )}
        {+inputValue === maxAllowedVal && (
          <AccessibleHidden id="ifPlusButtonDisabled">
            {formatMessage({ id: 'plusButtonDisabledAriaLabel' })}
          </AccessibleHidden>
        )}
        <AccessibleHidden id="decInputAge" role="alert" aria-live="assertive">
          {ariaTextUpdatedAgePolicyValue}
        </AccessibleHidden>
      </InputBoxButton>
      <InputField
        id="ageInput"
        type="number"
        onChange={e => {
          verifyValue(e.target.value);
        }}
        value={inputValue}
        onBlur={() => {
          blurHandler();
        }}
      />
      <InputBoxButton
        id="plusButton"
        onClick={() => {
          verifyClick(+inputValue + 1, plusButton);
        }}
        aria-label={formatMessage({ id: 'ariaTextPlusButton' })}
        aria-describedby="decInputAge ifMinusButtonDisabled"
        tabIndex={+inputValue === maxAllowedVal ? -1 : 0}
        aria-hidden={+inputValue === maxAllowedVal}
        disabled={+inputValue === maxAllowedVal}
      >
        <InputBoxImage
          src={
            +inputValue === maxAllowedVal
              ? additionButtonDisabled
              : additionButton
          }
          alt=""
          aria-hidden="true"
        />
      </InputBoxButton>
    </CustomInputBoxWrapper>
  );
};

CustomInputBox.propTypes = {
  setValue: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  inputValue: PropTypes.number.isRequired,
  minAllowedVal: PropTypes.number.isRequired,
  maxAllowedVal: PropTypes.number.isRequired,
  setOutOfRange: PropTypes.func.isRequired,
  policyYearValue: PropTypes.number.isRequired
};

CustomInputBox.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default CustomInputBox;
