import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  amountGenerator,
  labelGenerator,
  personInsuredGenerator,
  checkFieldAmtBasedOnAccumulationFlag
} from './templateUtils';
import {
  LeftColumn,
  ProjectStatusField,
  ProductCodeField,
  PolicyNumberWrapper,
  PolicyNumberLabel,
  PolicyNumberField,
  RightColumn,
  SumInsuredOrPolicyValueField,
  CardFlexContainer,
  StatusBulb,
  StatusLabel,
  CardStatementsWrapper,
  RedHR,
  CashValueOrSumInsuredLabel,
  AsOfDate,
  PersonInsuredField,
  PersonInsuredLabel,
  PersonInsuredValues,
  PolicyCardTag
} from '../summaryPageStyles';
import { ShowIfDesktop, ShowIfMobile } from '../../utils/showIf';
import NextActionMessage from '../components/NextActionMessage';
import getDescription from '../../utils/reducerUtils';
import Currency from '../../common/currency/Currency';
import DateComponent from '../../common/date/Date';
import SeeDetailsLink from '../components/SeeDetailsLink';
import { getPolicyStatusCopyLabel } from '../../utils/policyUtils';

const LifeTemplate = (props, context) => {
  const {
    intl: { messages, formatMessage, locale }
  } = context;

  const {
    productFieldsObject,
    superCategory,
    productCategory,
    encodePolicyNumber,
    colorCodesForPolicyStatus,
    notificationMessages,
    policyOnHold,
    eWelcomePack
  } = props;
  const {
    field6: { conditionalFieldValueOne, conditionalFieldValueTwo }
  } = productFieldsObject;

  /**
   * Layout of the LIFE Template is as below :
   * The template for Life would contain fields like :
   * 1 - Policy Status : policyStatus
   * 2 - Product Name :  planName
   * 3 - Policy Number
   * 4 - Cash Value/ Sum Insured/ Total Fund Balance (Only for Unit-Link Product)
   *       / Guaranteed Monthly Annuity Payment (only for Annuity)/ Guaranteed Cash Value (only for Annuity)
   * 5 - Next Premium Amount/ Payout Start Date/ Next Payout Date - The later two are for Annuity Products only
   * 6 - Premium Due Date
   * 7 - PolicyCurrency
   * 8 - As of Date
   * 9 - Policy Expiry Date
   * 10 - Next Payout Date
   * 11 - Payout Start Date
   * 12 - Payout End Date
   * 13 - Policy Accumulation Flag
   * 14 - Involved Insured Parties - Policy holder & other insured applicants
   *
   */

  /**
   *
   * Through the function amountAndAsOfDateLayout() --- Finding out whether to show the amount or as of Date values on the product cards
   * based upon the certain scenarios as following -
   *
   *  - Amount Need to be shown along with As of Date, If As of Date is not returned from API, Amount has to be suppressed
   *  - Amount need to be shown without As of Date, if As of Date is not returned from API, Amount still to be shown on UI.
   *  - Amount Need to be shown along with As of Date, If As of Date is returned from API, Both Amount and As of Date has to be shown on UI
   *  - For Annuity, if the accumulation of the premiums is complete, then the As of Date is to be shown on the UI.
   *  - If the accumulation of the premiums is not completed, the the As of the Date is not to be shown on the UI.
   *
   *
   * Through the function asOfDateResolver() --- Finding out whether to show As of Date or based upon the Annuity accumulation flag
   * coming from the API.
   *  - If the accumulation of the premium is ON/AF, As of date is to be shown.
   */

  const asOfDateResolver = () => {
    const { field3, field8, field13 } = productFieldsObject;
    if (
      field8 &&
      field8.showOnUI &&
      field8.showValuationDate &&
      field13 &&
      field13.value !== undefined
    ) {
      if (field13.value === 'AF' || field13.value === 'ON') {
        return (
          <AsOfDate id={`as_of_${field3.value}`}>
            <FormattedMessage id="asOfDateLabel" />
            <DateComponent
              date={field8.value}
              locale={locale}
              apiDateFormat="YYYY-MM-DD"
              id="standaradDateFormat"
            />
          </AsOfDate>
        );
      }
      return null;
    }
    return (
      <AsOfDate id={`as_of_${field3.value}`}>
        <FormattedMessage id="asOfDateLabel" />
        <DateComponent
          date={field8.value}
          apiDateFormat="YYYY-MM-DD"
          locale={locale}
          id="standaradDateFormat"
        />
      </AsOfDate>
    );
  };

  const amountAndAsOfDateLayout = () => {
    if (
      productFieldsObject.field4 &&
      productFieldsObject.field4.showOnUI &&
      productFieldsObject.field4.value &&
      productFieldsObject.field8 &&
      productFieldsObject.field8.showValuationDate
    ) {
      if (productFieldsObject.field8.value) {
        const sumInsuredOrPolicyValueFieldAmount = amountGenerator(
          productFieldsObject.field4,
          productFieldsObject.field13,
          formatMessage
        );
        if (
          checkFieldAmtBasedOnAccumulationFlag(
            sumInsuredOrPolicyValueFieldAmount,
            productFieldsObject.field13
          )
        ) {
          return (
            <>
              <CashValueOrSumInsuredLabel>
                {labelGenerator(
                  productFieldsObject.field4,
                  productFieldsObject.field13,
                  formatMessage
                )}
              </CashValueOrSumInsuredLabel>

              <SumInsuredOrPolicyValueField>
                <Currency
                  currencyCode={productFieldsObject.field4.value.currency}
                  amount={sumInsuredOrPolicyValueFieldAmount}
                />
                {asOfDateResolver()}
              </SumInsuredOrPolicyValueField>
            </>
          );
        }
      }
      return null;
    }
    return (
      productFieldsObject.field4 &&
      productFieldsObject.field4.showOnUI &&
      productFieldsObject.field4.value &&
      productFieldsObject.field4.value.amount &&
      productFieldsObject.field4.value.currency && (
        <>
          <CashValueOrSumInsuredLabel>
            {productFieldsObject.field4.value.amount &&
              productFieldsObject.field4.value.currency &&
              labelGenerator(
                productFieldsObject.field4,
                productFieldsObject.field13,
                formatMessage,
                messages
              )}
          </CashValueOrSumInsuredLabel>
          <SumInsuredOrPolicyValueField>
            <Currency
              currencyCode={productFieldsObject.field4.value.currency}
              amount={amountGenerator(
                productFieldsObject.field4,
                productFieldsObject.field13,
                formatMessage
              )}
            />
          </SumInsuredOrPolicyValueField>
        </>
      )
    );
  };
  const policyStatusLabelId = getPolicyStatusCopyLabel(
    productFieldsObject.field1.value
  );

  const insuredList =
    productFieldsObject.field14 &&
    productFieldsObject.field14.value &&
    productFieldsObject.field14.showOnUI &&
    personInsuredGenerator(productFieldsObject.field14);

  let showWelcomePack = false;
  let currentCustomerPolicy = null;
  eWelcomePack.map(eWelcome => {
    if (
      eWelcome.showWelcomePack &&
      eWelcome.encodePolicyNumber === encodePolicyNumber
    ) {
      showWelcomePack = true;
      const { customerPolicy } = eWelcome;
      currentCustomerPolicy = customerPolicy;
    }
    return false;
  });

  return (
    <>
      {productFieldsObject.field1 &&
        productFieldsObject.field1.showOnUI &&
        productFieldsObject.field1.value !== undefined && (
          <ProjectStatusField>
            <StatusBulb
              colorCode={
                colorCodesForPolicyStatus[productFieldsObject.field1.value]
              }
              aria-hidden="true"
            />
            <StatusLabel
              id={`policy_status_${productFieldsObject.field1.value}`}
            >
              {policyStatusLabelId &&
                formatMessage({
                  id: policyStatusLabelId
                })}
            </StatusLabel>
          </ProjectStatusField>
        )}
      <CardFlexContainer>
        <LeftColumn>
          {productFieldsObject.field2 &&
            productFieldsObject.field2.showOnUI &&
            productFieldsObject.field2.value && (
              <>
                <ProductCodeField
                  id={`plan_code_${productFieldsObject.field3.value}`}
                >
                  {getDescription(locale, productFieldsObject.field2.value)}
                  {(process.env.REACT_APP_ENV === 'local' ||
                    process.env.REACT_APP_ENV === 'dev') &&
                    productCategory &&
                    ` (${productCategory})`}
                  {showWelcomePack && (
                    <PolicyCardTag>
                      <FormattedMessage
                        id="newPolicy"
                        defaultMessage="New Policy"
                      />
                    </PolicyCardTag>
                  )}
                </ProductCodeField>
              </>
            )}

          {productFieldsObject.field3 &&
            productFieldsObject.field3.showOnUI &&
            productFieldsObject.field3.value && (
              <PolicyNumberWrapper>
                <PolicyNumberLabel id="policyNumber">
                  {formatMessage({ id: 'policyNumberFieldLabel' })}
                </PolicyNumberLabel>
                <PolicyNumberField
                  id={`policy_number_${productFieldsObject.field3.value}`}
                >
                  {productFieldsObject.field3.value}
                </PolicyNumberField>
              </PolicyNumberWrapper>
            )}

          <ShowIfDesktop>
            {insuredList && (
              <PersonInsuredField>
                {`${formatMessage({
                  id: productFieldsObject.field14.fieldLabel
                })} ${insuredList}`}
              </PersonInsuredField>
            )}
          </ShowIfDesktop>
        </LeftColumn>

        <RightColumn>{amountAndAsOfDateLayout()}</RightColumn>
      </CardFlexContainer>
      <ShowIfMobile>
        {insuredList && (
          <PersonInsuredField>
            <PersonInsuredLabel>
              {formatMessage({
                id: productFieldsObject.field14.fieldLabel
              })}
            </PersonInsuredLabel>{' '}
            <PersonInsuredValues>{insuredList}</PersonInsuredValues>
          </PersonInsuredField>
        )}
      </ShowIfMobile>
      <>
        <RedHR />
        <CardStatementsWrapper
          conditionalFieldValueOne={conditionalFieldValueOne}
          conditionalFieldValueTwo={conditionalFieldValueTwo}
        >
          <NextActionMessage
            productFieldsObject={productFieldsObject}
            productCategory={productCategory}
            superCategory={superCategory}
            locale={locale}
            notificationMessages={notificationMessages}
            policyOnHold={policyOnHold}
          />
          <SeeDetailsLink
            showWelcomePack={showWelcomePack}
            customerPolicy={currentCustomerPolicy}
            policynumber={productFieldsObject.field3.value}
            encodePolicyNumber={encodePolicyNumber}
            productFieldsObject={productFieldsObject}
          />
        </CardStatementsWrapper>
      </>
    </>
  );
};

LifeTemplate.propTypes = {
  productFieldsObject: PropTypes.object.isRequired,
  productCategory: PropTypes.string.isRequired,
  superCategory: PropTypes.string.isRequired,
  colorCodesForPolicyStatus: PropTypes.object.isRequired,
  notificationMessages: PropTypes.array.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  policyOnHold: PropTypes.bool.isRequired,
  eWelcomePack: PropTypes.object.isRequired
};

LifeTemplate.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default LifeTemplate;
