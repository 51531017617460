import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  SimplyBookWrapper,
  SimplyBookDescriptionWrapper,
  SimplyBookDescription,
  SimplyBookLinkWrapper,
  SimplyBookLink,
  ImgWrapper,
  SimplyBookIconWrapper,
  IconRightImg
} from '../summaryPageStyles';
import iconRight from '../../assets/images/Icon_right.svg';
import Contactlivechat from '../../assets/images/Contactlivechat.svg';
import { setLinkFocus } from '../../utils/domUtils';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import { trackViews } from '../../utils/tealiumUtils';
import { checkCustomerSegment } from '../summaryUtils/simplyBookUtils';

const SimplyBook = function SimplyBook(props, context) {
  const { simplyBookData, customer, customerSegmentValues } = props;
  const {
    simplyBookLinkDescription,
    simplyBookLinkName,
    simplyBookUrls,
    simplyBookModalContent: { dependentDescriptionOne, dependentDescriptionTwo }
  } = simplyBookData;

  const {
    intl: { formatMessage, locale }
  } = context;

  const linkRef = React.createRef();
  const [isModalOpen, updateModalState] = useState(false);

  if (checkCustomerSegment(customer, customerSegmentValues)) {
    return <></>;
  }

  const footerButtonList = [
    {
      className: 'whiteBtn',
      label: formatMessage({ id: 'cancelPopup' }),
      id: 'cancel_btn',
      align: 'left'
    },
    {
      className: 'redBtn',
      label: formatMessage({ id: 'continuePopup' }),
      id: 'continue_btn',
      align: 'right'
    }
  ];

  const handleModal = e => {
    e.preventDefault();
    trackViews({ viewName: 'simplyBook' });
    updateModalState(!isModalOpen);
  };

  const onContinueClick = () => {
    updateModalState(!isModalOpen);
  };

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
    setLinkFocus(linkRef.current);
  };

  return (
    <SimplyBookWrapper>
      <SimplyBookDescriptionWrapper>
        <SimplyBookIconWrapper>
          <IconRightImg aria-hidden="true" alt="" src={Contactlivechat} />
        </SimplyBookIconWrapper>
        <SimplyBookDescription>
          {formatMessage({ id: simplyBookLinkDescription })}
        </SimplyBookDescription>
      </SimplyBookDescriptionWrapper>
      <SimplyBookLinkWrapper>
        <SimplyBookLink
          ariaLabel={formatMessage({ id: simplyBookLinkName })}
          href="#"
          rel="noopener noreferrer nofollow"
          onClick={e => {
            handleModal(e);
          }}
        >
          {formatMessage({ id: simplyBookLinkName })}
        </SimplyBookLink>
        <ImgWrapper>
          <IconRightImg aria-hidden="true" alt="" src={iconRight} />
        </ImgWrapper>
      </SimplyBookLinkWrapper>
      <ModalPopUp
        isModalOpen={isModalOpen}
        onClose={onCancelClick}
        modalHeading=""
        footerButtonList={footerButtonList}
        proceedToLink={simplyBookUrls[locale]}
        btnHandler={{
          continue_btn: onContinueClick,
          cancel_btn: onCancelClick
        }}
      >
        <ParagraphDiv>
          {formatMessage({ id: dependentDescriptionOne })}
          <br />
          {formatMessage({ id: dependentDescriptionTwo })}
        </ParagraphDiv>
      </ModalPopUp>
    </SimplyBookWrapper>
  );
};

SimplyBook.propTypes = {
  simplyBookData: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  customerSegmentValues: PropTypes.array.isRequired
};

SimplyBook.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SimplyBook;
