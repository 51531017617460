import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ContactExceptionInfoWrapper,
  ContactInfoHeader,
  ContactImgWrapper,
  ImgStyle,
  ContactCardDetailsWrapper,
  ContactCardRight,
  ContactCardImage,
  ContactCardLeft,
  ContactCardBody,
  ContactCardText,
  ContactInfoLabel,
  ContactCardDetails,
  ContactCardTitle,
  ContactCardNumber,
  ContactCardDesc
} from '../../../policydetail/sections/contactInfoStyles';
import Contactphone from '../../../assets/images/Contactphone.svg';
import ContactFallBack from '../../../assets/images/contact_fallback.svg';

const ContactUsExceptionCard = function ContactUsExceptionCard(props, context) {
  const {
    intl: { formatMessage }
  } = context;

  const conactList = [
    {
      contactLabel: 'insuranceHotlineTitle',
      contactUsNumber: 'lifeContactUsNumber',
      timelineWD: 'insuranceHotlineTime_WD',
      timelineWE: 'insuranceHotlineTime_WE',
      timelinePB: 'insuranceHotlineTime_PB'
    },
    {
      contactLabel: 'insuranceHotlineTitle_Jade',
      contactUsNumber: 'JadeContactUsNumber',
      timelineWD: 'insuranceHotlineTime_Jade',
      timelineWE: 'insuranceHotlineTime_WE_Jade',
      timelinePB: 'insuranceHotlineTime_PB_Jade'
    }
  ];
  return (
    <ContactExceptionInfoWrapper>
      <ContactInfoHeader>
        <ContactImgWrapper>
          <ImgStyle aria-hidden="true" alt="" src={Contactphone} />
        </ContactImgWrapper>
        <ContactInfoLabel id="contact_card_title">
          {formatMessage({ id: 'contactCardHeading' })}
        </ContactInfoLabel>
      </ContactInfoHeader>
      <ContactCardText>
        {formatMessage({ id: 'contactCardText' })}
      </ContactCardText>
      <ContactCardBody>
        <ContactCardLeft>
          <ContactCardImage>
            <ImgStyle aria-hidden="true" alt="" src={ContactFallBack} />
          </ContactCardImage>
        </ContactCardLeft>
        <ContactCardRight>
          <ContactCardDetailsWrapper>
            {_.map(conactList, listItem => {
              const {
                contactLabel,
                contactUsNumber,
                timelineWD,
                timelineWE,
                timelinePB
              } = listItem;
              return (
                <>
                  <ContactCardDetails>
                    <ContactCardTitle>
                      {formatMessage({ id: contactLabel })}
                    </ContactCardTitle>
                    <ContactCardNumber>
                      {formatMessage({ id: contactUsNumber })}
                    </ContactCardNumber>
                    <ContactCardDesc>
                      <span>{formatMessage({ id: timelineWD })}</span>
                    </ContactCardDesc>
                    <ContactCardDesc>
                      <span>{formatMessage({ id: timelineWE })}</span>
                    </ContactCardDesc>
                    <ContactCardDesc>
                      <span>{formatMessage({ id: timelinePB })}</span>
                    </ContactCardDesc>
                  </ContactCardDetails>
                </>
              );
            })}
          </ContactCardDetailsWrapper>
        </ContactCardRight>
      </ContactCardBody>
    </ContactExceptionInfoWrapper>
  );
};

ContactUsExceptionCard.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ContactUsExceptionCard;
