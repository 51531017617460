import React from 'react';
import PropTypes from 'prop-types';
import {
  PolicyNameRow,
  PolicyNameHeader,
  PolicyFieldWrapper,
  PolicyNumberLabel,
  PolicyNumberValue,
  PolicyNameNumberWrapper
} from './instructionHistoryExceptionStyles';
import getDescription from '../../../utils/reducerUtils';

const ExceptionPolicySection = function ExceptionPolicySection(props, context) {
  const {
    appData: { planName, policyNumber }
  } = props;
  const {
    intl: { locale, formatMessage }
  } = context;
  return (
    <PolicyNameNumberWrapper>
      <PolicyNameRow>
        <PolicyNameHeader>{getDescription(locale, planName)}</PolicyNameHeader>
      </PolicyNameRow>
      <PolicyFieldWrapper>
        <PolicyNumberLabel>
          {formatMessage({ id: 'policyNumber' })}
        </PolicyNumberLabel>
        <PolicyNumberValue>{policyNumber}</PolicyNumberValue>
      </PolicyFieldWrapper>
    </PolicyNameNumberWrapper>
  );
};

ExceptionPolicySection.propTypes = {
  appData: PropTypes.object.isRequired,
  planName: PropTypes.array.isRequired,
  policyNumber: PropTypes.number.isRequired
};

ExceptionPolicySection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ExceptionPolicySection;
