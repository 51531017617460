import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ShowIfDesktop, ShowIfMobile } from '../../utils/showIf';
import LinkContainer from './contactLinkStyles';
import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';

const eventAction = 'button';
const title = 'call';
let callData = {};

const onClickHandler = function onContactLinkClick(typeOfCall) {
  callData = createEventActionContent(eventAction, typeOfCall, title);
  trackEvents({ eventName: 'call', callData });
};

export const getContactsForMobile = (
  contactsList,
  formatMessage,
  typeOfCall,
  includeErrorCode,
  errorCode
) => {
  const contactHtmlObjects = {};
  _.forEach(contactsList, (contact, index) => {
    const contactNumber = formatMessage({ id: contact });
    contactHtmlObjects[`contact${index}`] = (
      <LinkContainer onClick={() => onClickHandler(typeOfCall)}>
        {React.createElement(
          'a',
          { href: `tel: ${contactNumber}` },
          `${contactNumber}`
        )}
      </LinkContainer>
    );
  });
  if (includeErrorCode) {
    return { ...contactHtmlObjects, errorCode };
  }
  return contactHtmlObjects;
};

export const getContactsForDesktop = (
  contactsList,
  formatMessage,
  includeErrorCode,
  errorCode
) => {
  const contactHtmlObjects = {};

  _.forEach(contactsList, (contact, index) => {
    contactHtmlObjects[`contact${index}`] = formatMessage({ id: contact });
  });
  if (includeErrorCode) {
    return { ...contactHtmlObjects, errorCode };
  }
  return contactHtmlObjects;
};

const ContactLink = (
  {
    sentenceWithContact,
    contactsList,
    typeOfCall,
    includeErrorCode,
    errorCode
  },
  context
) => {
  const {
    intl: { formatMessage }
  } = context;

  return (
    <>
      <ShowIfDesktop>
        <FormattedMessage
          id={sentenceWithContact}
          defaultMessage={sentenceWithContact}
          values={getContactsForDesktop(
            contactsList,
            formatMessage,
            includeErrorCode,
            errorCode
          )}
        />
      </ShowIfDesktop>
      <ShowIfMobile>
        <FormattedMessage
          id={sentenceWithContact}
          values={getContactsForMobile(
            contactsList,
            formatMessage,
            typeOfCall,
            includeErrorCode,
            errorCode
          )}
        />
      </ShowIfMobile>
    </>
  );
};

ContactLink.contextTypes = {
  intl: PropTypes.object.isRequired
};
ContactLink.propTypes = {
  sentenceWithContact: PropTypes.string.isRequired,
  contactsList: PropTypes.array.isRequired,
  typeOfCall: PropTypes.string.isRequired,
  includeErrorCode: PropTypes.bool,
  errorCode: PropTypes.string
};

ContactLink.defaultProps = {
  includeErrorCode: false,
  errorCode: ''
};
export default ContactLink;
