import { em, rem } from 'polished';
import styled from 'styled-components';
import theme from '../../../common/styles/theme';
import media from '../../../utils/media';
import { FontFamily } from '../../../utils/font';

export const ContactInfoRow = styled.dl`
  margin: 0;
  width: 100%;
  font-size: ${em('14px')};
  color: ${theme.greyNumberColor};
  display: flex;
  flex-wrap: wrap;
`;

export const ManageBeneficiariesWrapper = styled.div`
  display: block;
  align-items: center;
  margin: ${rem('30px')} 0 ${rem('30px')} 0;
  padding: ${rem('38px')} ${rem('40px')} ${rem('30px')} ${rem('40px')};
  background: ${theme.whiteColor};
  border: 1px solid #d9d9d9;
  @media only screen and (max-width: 959px) {
    margin: ${rem('24px')} 0 ${rem('24px')} 0;
    padding: ${rem('30px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};
  }
  @media only screen and (max-width: 667px) {
    margin: ${rem('15px')} 0 ${rem('15px')} 0;
    padding: ${rem('15px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
  }
  background-color: #ffffff;
`;

export const ManageBeneficiariesHeader = styled.div`
  display: flex;
  @media only screen and (max-width: 667px) {
    padding-top: ${rem('7px')};
  }
`;

export const ManageBeneficiariesImgWrapper = styled.span`
  width: ${rem('25px')};
  height: ${rem('25px')};
  @media only screen and (max-width: 667px) {
    width: ${rem('18px')};
    height: ${rem('18px')};
    position: relative;
    top: ${rem('4px')};
  }
`;

export const BeneficiariesInfoLabel = styled.h2`
font-weight:normal
font-family: UniversNextforHSBC-Light;
font-size:${em('28px')};
color: ${theme.fontColor};
line-height: ${rem('24px')};
padding-left: ${rem('16px')};
@media only screen and (max-width: 1024px) {
  font-size:${em('24px')};
}
@media only screen and (max-width: 667px) {
  font-size:${em('23px')};
  padding-left:${rem('10px')};
}
`;

export const ManageBeneficiariesBody = styled.div`
  margin: ${rem('18px')} ${rem('5px')} ${rem('27px')} ${rem('5px')};
  @media only screen and (max-width: 959px) {
    margin: ${rem('26px')} 0 ${rem('22px')} 0;
  }
  @media only screen and (max-width: 667px) {
    margin : ${rem('17px')} 0 ${rem('18px')} 0;
  }
 }
`;

export const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
`;

export const HeadingBeneficiariesRow = styled.h3`
  color: ${theme.fontColor};
  font-family: UniversNextforHSBC-Light;
  font-size: ${rem('22.4px')};
  padding-top: ${rem('36px')};
  padding-bottom: ${rem('16px')};
  @media only screen and (max-width: 667px) {
    font-size:${rem('20px')}
    padding-top: ${rem('25px')};
    padding-bottom: 0;
  }
`;

export const Table = styled.table`
  font-family: UniversNextforHSBC-Regular;
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  @media only screen and (max-width: 959px) {
    table-layout: inherit;
  }
  @media only screen and (max-width: 667px) {
    display: none;
  }
`;

export const FieldTooltip = styled.span`
  font-family: UniversNextforHSBC-Light;
`;

export const FirstHeaderSubFieldContainer = styled.span`
  display: flex;
  align-items: baseline;
`;

export const Tbody = styled.tbody`
  tr {
    td {
      font-family: UniversNextforHSBC-Bold;
      color: #252525;
      font-size: 14px;
      @media only screen and (max-width: 768px) {
        font-size: 11px;
      }
    }

    td:first-child {
      font-family: UniversNextforHSBC-Bold;
      padding-left: rem('0px');
      @media only screen and (max-width: 768px) {
        font-size: 11px;
      }
    }

    td:last-child {
      text-align: right;
    }
  }
`;

export const Tr = styled.tr`
  padding: 10px 0;
  border-bottom: ${props =>
    props.boderColor ? `1px solid ${props.boderColor}` : 'none'};
`;

export const Td = styled.td`
  vertical-align: top;
  line-height: ${rem('22px')};
  border-left: 'none';
  padding: ${rem('11px')} ${rem('5px')};
  :first-child {
    width: '300px';
  }

  @media only screen and (max-width: 1024px) {
    :first-child {
      width: '130px';
    }
  }
`;

export const Th = styled.th`
  vertical-align: top;
  line-height: ${rem('22px')};
  border-left: 'none';
  padding: ${rem('11px')} ${rem('5px')};
  :first-child {
    width: '300px';
  }
  @media only screen and (max-width: 1024px) {
    :first-child {
      width: '130px';
    }
  }
`;

export const Thead = styled.thead`
  color: #575757;
  border-bottom: 1px solid #d7d8d6;
  th {
    font-weight: normal;
    font-size: 1rem;
    padding-left: 0;
    text-align: left;
    color: #575757;
    line-height: 18px;
    :first-child {
      width: 50%;
    }
  }
  th:last-child {
    text-align: right;
  }
`;

export const TableIconWrapper = styled.span`
  background-color: #f3f3f3;
  width: ${rem('25px')};
  height: ${rem('25px')};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: ${rem('5px')};
  margin-right: ${rem('5px')};
`;

export const WrapperContainer = styled.span`
  display: ${props => (props.isNameFieldEmpty ? `block` : 'flex')};
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const ImgStyleProfileIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const FundWrapper = styled.div`
  > div:first-child {
    border-top: none;
  }
`;

export const TableIconWrapperContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const ProjectedDeathBenefitsAmountCard = styled.div`
width: 100%;
justify-content: space-between;
padding-top: 10px
display: flex;
background: white;
border: none;
color: ${theme.fontColor};
text-decoration:none;
@media only screen and (max-width: 667px) {
  width:100%;
align-items: center;
}
`;

export const ProjectedDeathBenefits = styled.div`
  align-items: baseline;
  display: flex;
  margin: 0 6px 0 0;
  font-family: UniversNextforHSBC-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--ui-styles-icon-on-l-ight-default);
  @media only screen and (max-width: 667px) {
    word-break: normal;
    display: block;
    position: relative;
    span[data-targetid='tooltip_detail_disclaimer'] {
      position: absolute;
      top: 15px;
      right: 70px;
    }
  }
`;
export const ProjectedDeathBenefitsContainer = styled.h1``;

export const ProjectedDeathBenefitsAmount = styled.span`
  font-family: UniversNextforHSBC-Medium;
  font-size: ${em('16px')};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: var(--ui-styles-icon-on-l-ight-default);
  @media only screen and (max-width: 667px) {
    width: 60%;
    justify-content: center;
  }
`;

export const ManageBeneficiariesFooter = styled.div`
  font-family: UniversNextforHSBC-Regular;
  padding-top: ${rem('12px')};
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  font-size: ${rem('16px')};
  display: flex;
  justify-content: flex-end;
`;

export const CurrencyWrapper = styled.span`
  span span {
    font-family: UniversNextforHSBC-Bold;
    margin: 0 ${rem('5px')} 0 0;
  }
`;

export const BeneficiaryPrimaryWrapper = styled.span`
  font-family: UniversNextforHSBC-Bold;
`;

export const TableIconWrapperDextop = styled.span`
  background-color: #f3f3f3;
  width: ${rem('26px')};
  height: ${rem('22px')};
  border-radius: 50%;
  padding: ${rem('5px')};
  margin-right: ${rem('5px')};
  @media only screen and (max-width: 959px) {
    width: ${rem('16px')};
    height: ${rem('16px')};
  }
`;

export const ModalQuesTop = styled.div`
  width: ${rem('660px')};
  height: ${rem('36px')};
  margin: ${rem('16px')} ${rem('7px')} ${rem('32px')} 0;
  font-family: UniversNextforHSBC-Regular;
  font-size: ${em('28px')};
  @media only screen and (max-width: 667px) {
    width: ${rem('327px')};
    height: ${rem('60px')};
  }
`;

export const ImgModal = styled.img`
  width: ${rem('48px')};
  height: ${rem('48px')};
  margin: ${rem('6px')} ${rem('288px')};
  @media only screen and (max-width: 667px) {
    margin: ${rem('7px')} ${rem('118px')} 0 ${rem('140px')};
  }
`;

export const FirstHeaderField = styled.div``;

export const FirstHeaderSubField = styled.p`
  font-family: UniversNextforHSBC-Regular;
  margin-top: 10px;
`;

export const ParagraphDiv = styled.div`
  font-family: UniversNextforHSBC-Regular;
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('18px')};
  margin-bottom: ${rem('20px')};
  @media only screen and (max-width: 1024px) {
    font-size: ${em('14px')};
    line-height: ${rem('18px')};
  }
  @media only screen and (max-width: 667px) {
    font-size: ${em('12px')};
    line-height: ${rem('21px')};
    margin-bottom: ${rem('10px')};
  }
`;

export const ParagraphDivQues = styled.div`
  font-family: UniversNextforHSBC-Regular;
  font-size: ${em('20px')};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: 0;
  margin-bottom: ${rem('20px')};
  @media only screen and (max-width: 1024px) {
    font-size: ${em('18px')};
    line-height: 0;
  }
  @media only screen and (max-width: 667px) {
    font-size: ${em('16px')};
    line-height: 20px;
    margin-bottom: ${rem('10px')};
  }
`;

export const FirstStep = styled.div`
  margin: 0 0 ${rem('50px')} 0;
  @media only screen and (max-width: 667px) {
    margin: 0;
  }
`;

export const DotContainer = styled.div`
  height: ${rem('8px')};
  width: ${rem('8px')};
  margin-right: ${rem('5px')};
  margin-left: 5px;
`;

export const Dot = styled.div`
  height: ${rem('8px')};
  width: ${rem('8px')};
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin: 5px 10px 0 0;
`;

export const ItemRowItem = styled.div`
  padding-top: ${props => (props.nonPersonal ? `5px` : 0)};
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  @media only screen and (max-width: 767px) {
    padding-top: 0;
  }
`;

export const UploadIconContainer = styled.div`
  height: 20px;
  width: 18px;
  margin: 5px 5px 0 0;
`;

export const ButtonAction = styled.a`
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
`;

export const UploadButtonTitle = styled.div`
  font-family: UniversNextforHSBC-Bold;
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('18px')};
  @media only screen and (max-width: 1024px) {
    font-size: ${em('14px')};
    line-height: ${rem('18px')};
  }
  @media only screen and (max-width: 667px) {
    font-size: ${em('12px')};
    line-height: ${rem('21px')};
  }
`;

export const ModalWrapperBeneHeader = styled.div`
  color: #333;
  line-height: 1.34;
  text-align: left;
  font-size: 1.5625em;
  font-family: UniversNextforHSBC-Light;
`;

export const ParagraphItemDiv = styled.div`
  font-family: UniversNextforHSBC-Regular;
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('18px')};
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('10px')};
  @media only screen and (max-width: 1024px) {
    font-size: ${em('14px')};
    line-height: ${rem('18px')};
  }
  @media only screen and (max-width: 667px) {
    font-size: ${em('12px')};
    line-height: ${rem('21px')};
    margin-bottom: ${rem('10px')};
  }
`;

export const GapMaker = styled.div`
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('20px')};
`;

export const ParagraphStep2Div = styled.div`
  font-family: UniversNextforHSBC-Regular;
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('18px')};
  @media only screen and (max-width: 1024px) {
    font-size: ${em('14px')};
    line-height: ${rem('18px')};
  }
  @media only screen and (max-width: 667px) {
    font-size: ${em('12px')};
    line-height: ${rem('21px')};
  }
`;

export const GapMakerLastItem = styled.div`
  margin-top: ${rem('10px')};
  margin-bottom: ${rem('20px')};
  @media only screen and (max-width: 667px) {
    margin-bottom: ${rem('10px')};
  }
`;

export const ModalMainContainer = styled.div`
  padding: 0 0 30px 0;
  @media (max-width: 667px) {
    padding-bottom: ${rem('85px')};
  }
`;

export const ModalMainHeadContainer = styled.div`
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const ModalMainHeadImageContainer = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ModalMainHeadImageTextContainer = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const NoBeneficiaryCard = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BeneFooterText = styled.div`
  margin-top: ${rem('20px')};
  font-size: 1em;
  line-height: 1.3;
  @media (max-width: 667px) {
    margin-top: ${rem('25px')};
  }
`;
export const ImgCenter = styled.div`
  width: 40%;
  @media (max-width: 667px) {
    width: 100%;
  }
`;

export const BellIconWrapper = styled.span`
  width: ${rem('26px')};
  height: ${rem('22px')};
  justify-content: center;
  align-items: center;
  display: flex;
  padding: ${rem('5px')};
  @media only screen and (max-width: 959px) {
    width: ${rem('32px')};
    height: ${rem('32px')};
  }
  @media only screen and (max-width: 667px) {
    width: ${rem('82px')};
    height: ${rem('22px')};
    margin-bottom: ${rem('63px')};
  }
`;

export const NoBeneficiaryTextContainer = styled.span`
  font-family: UniversNextforHSBC-Regular;
  @media only screen and (max-width: 959px) {
    font-size: ${rem('18px')};
    padding-right: ${rem('16px')};
  }
  @media only screen and (max-width: 667px) {
    line-height: 25px;
  }
`;

export const DrawLine = styled.div`
  font-family: UniversNextforHSBC-Regular;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #d7d8d6;
  @media only screen and (max-width: 667px) {
    padding: 10px 0;
  }
`;

export const NoSecondaryContainer = styled.div`
  font-family: UniversNextforHSBC-Regular;
  width: 100%;
  padding: 16px 0;
  justify-content: flex-start;
  align-items: center;
`;

export const ProjectedDeathBenefitsSecondaryFooter = styled.div`
  font-family: UniversNextforHSBC-Bold;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const MissingInfoImgWrapper = styled.div`
  width: ${rem(`20px`)};
  height: ${rem(`15px`)};
`;

export const MissingBeneText = styled.span`
  padding-left: 5px;
  padding-top: 1px;
  font-size: 15px;
  @media only screen and (max-width: 667px) {
    font-size: 12px;
  }
`;

export const ChevronDotContainer = styled.div`
  display: flex;
`;

export const MissingBeneDot = styled.img`
  width: 100%;
  height: 100%;
  padding-left: 2px;
`;

export const IconRightImg = styled.img`
  width: 100%;
  height: 100%;
  height: 13px;
  @media (max-width: 320px) {
    height: ${rem('11px')};
  }
`;

export const MissingInfoSubContainer = styled.div`
  display: flex;
  padding-left: 5px;
  padding-bottom: 10px;
  font-family: 'UniversNextforHSBC-Regular';
  @media only screen and (max-width: 667px) {
    padding-bottom: 0;
    padding-left: 0;
    padding-top: 8px;
  }
`;

export const MissingTr = styled.tr`
  border-bottom: 1px solid #d7d8d6;
`;
export const DeathBenefitOverviewInfoFieldLabelKey = styled.dt`
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
  padding-bottom: 11px;
  width: 60%;
  @media (max-width: 720px) {
    display: inline;
  }
`;
export const DeathBenefitOverviewInfoFieldLabelKeyHeader = styled.p`
  @media (max-width: 720px) {
    display: contents;
  }
`;
export const OverviewInfoFieldDataValue = styled.dd`
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
  @media (max-width: 374px) {
    width: 52%;
    justify-content: flex-end;
    text-align: right;
  }
  @media ((min-width: 375px) and (max-width: 720px)) {
    width: 40%;
    justify-content: flex-end;
    text-align: right;
  }
`;
export const OverviewFieldWrapper = styled.dl`
  margin: 0;
  width: ${rem('204px')};
  padding: ${props =>
    props.id === 'sumInsuredLabel'
      ? `0 ${rem('16px')} ${rem('30px')} ${rem('24px')}`
      : `0 ${rem('24px')} ${rem('30px')} ${rem('24px')}`};
  box-sizing: border-box;
  position:relative;
  @media (max-width: 667px) {
    position: unset;
  }
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: ${rem('50px')};
    top: 0;
    right: 0;
    background: ${theme.bgGreyColor};
  }
  &:first-of-type {
    padding: 0 ${rem('24px')} ${rem('30px')} 0;
  }
  &:nth-of-type(1),
  &:nth-of-type(6),
  &:nth-of-type(11) {
    padding: 0 ${rem('24px')} ${rem('30px')} 0;
  }
  &:nth-of-type(5),
  &:nth-of-type(10) {
    &:after {
      visibility: hidden;
    }
  }
  &:last-of-type {
    &:after {
      visibility: hidden;
    }
  }
  ${props =>
    props.isMedical &&
    `
    &:first-of-type {
      &:after {
        visibility: hidden;
      }
    }
  `}

  @media (max-width: 1119px) {
   width: ${rem('204px')}
   &:nth-of-type(6),&:nth-of-type(10),
   &:nth-of-type(11) {
     padding: 0 ${rem('24px')} ${rem('30px')} ${rem('24px')};
   }
    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9),
    &:nth-of-type(15) {
      padding: 0 ${rem('24px')} ${rem('30px')} 0;
    }
    &:nth-of-type(5),
    &:nth-of-type(10) {
      &:after {
        visibility: visible;
      }
    }
    &:nth-of-type(4),
    &:nth-of-type(8),
    &:nth-of-type(12) {
      &:after {
        visibility: hidden;
      }
    }
    &:last-of-type {
      &:after {
        visibility: hidden;
      }
    }
  }
  @media (max-width: 959px) {
  width: ${rem('190px')}
  &:nth-of-type(6),&:nth-of-type(10),
  &:nth-of-type(11) {
    padding: 0 ${rem('23px')} ${rem('30px')} ${rem('23px')};
  }
   &:nth-of-type(1),
   &:nth-of-type(5),
   &:nth-of-type(9),
   &:nth-of-type(15) {
     padding: 0 ${rem('23px')} ${rem('30px')} 0;
   }
   &:nth-of-type(5),
   &:nth-of-type(10) {
     &:after {
       visibility: visible;
     }
   }
   &:nth-of-type(4),
   &:nth-of-type(8),
   &:nth-of-type(12) {
     &:after {
       visibility: hidden;
     }
   }
   &:last-of-type {
     &:after {
       visibility: hidden;
     }
   }
  }
  @media (max-width: 807px) {
    width: ${rem('180px')};
    padding: 0 ${rem('23px')} ${rem('30px')} ${rem('23px')};
    &:nth-of-type(6),
    &:nth-of-type(10),
    &:nth-of-type(11) {
      padding: 0 ${rem('23px')} ${rem('30px')} ${rem('23px')};
    }
    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9),
    &:nth-of-type(15) {
      padding: 0 ${rem('23px')} ${rem('30px')} 0;
    }
  }
  @media (max-width: 767px) {
    width: ${rem('169px')};
    padding: 0 ${rem('20px')} ${rem('30px')} ${rem('20px')};
    &:nth-of-type(6),
    &:nth-of-type(10),
    &:nth-of-type(11) {
      padding: 0 ${rem('20px')} ${rem('30px')} ${rem('20px')};
    }
    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9),
    &:nth-of-type(15) {
      padding: 0 ${rem('20px')} ${rem('30px')} 0;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
    padding: ${rem('12px')} 0 !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &:after {
     display:none;
    }
    &:first-of-type {
      padding: ${rem('12px')} 0;
    }
`;
export const GapMakerLarge = styled.div`
  margin-bottom: ${rem('30px')};
  @media (max-width: 767px) {
    margin-bottom: ${rem('10px')};
  }
`;

export const BeneficiaryLinkWrapper = styled.dt`
  width: 100%;
  line-height: ${rem('24px')};
  display:flex;
  justify-content: flex-end;
  > a {
    padding:0
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${theme.whiteColor};
    border: none;
    color: ${theme.fontColor};
    text-decoration:none;
    &:focus, &:hover {
      outline: 1px dotted black;
    }
    @media (max-width: 667px) {
      width: 100%;
      justify-content: space-between;
    }
  }
  @media (max-width: 320px) {
    font-size:${rem('12px')};
  }

`;
export const RightIconImgWrapper = styled.span`
  width: ${rem('8px')};
  height: ${rem('12px')};
  display: block;
  position: relative;
  top: 0;
  @media (max-width: 667px) {
    width: ${rem('9.7px')};
    height: ${rem('16px')};
    top: 2.5px;
  }
`;

export const NilDetailsWrapper = styled.div``;

export const NilDetailsWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${media.phone`
    display: none;
  `};
`;

export const NilDetailsWrapperTab = styled.div`
  display: none;
  ${media.tablet`
    display: block;
    position: relative;
  `};
`;

export const NilText = styled.div``;

export const NilDetails = styled.div``;

export const NilTextTab = styled.div`
  position: relative;
  ${media.phone`
  top: 0;
  left: 0;
    ${props =>
      props.isNameFieldEmpty &&
      `
      top: -25px;
      left: 45px;
    `}
  `}
`;

export const NilDetailsDesktop = styled.div`
  display: flex;
`;

export const NilDetailsTab = styled.div`
  top: 20px;
  left: -38px;
  position: relative;
  ${media.phone`
    top:-2px;
    left:0;
  `};
`;

export const NilDetailsWarningDiv = styled.a`
  display: flex;
  ${FontFamily.RegularFontW01};
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  color: ${theme.fontColor};
  gap: 5px;
  align-items: center;
  width: fit-content;
  margin-top: 7px;
  ${media.tablet`
   margin-top: 12px;
  `};
  ${media.phone`
  margin-top:0;
`};
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: 1px dotted;
  }
`;

export const NilDetailsImagePath = styled.img`
  display: block;
  width: ${rem('14px')};
  height: ${rem('14px')};
`;

export const ModalContentFormatted = styled.div`
  span {
    line-height: 24px;
  }
`;

export const BulletUL = styled.ul`
  position: relative;
  margin: 0;
  padding: 0 0 0 17px;
`;

export const BulletLI = styled.li`
  margin: 6px 0;
  padding: 0;
  &:focus,
  &:hover {
    cursor: pointer;
  }
`;
