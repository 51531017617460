import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FundDetailWrapper, FundTypeHeading } from '../instructionHistoryStyle';
import ServicingKeyValue from '../templates/ServicingKeyValue';
import ServicingAccountCurrency from '../templates/ServicingAccountCurrency';
import { getFieldData } from '../instructionUtils';
import getDescription from '../../utils/reducerUtils';
import SlaNoteSection from '../templates/SlaNoteSection';

const ChangePaymentInstruction = (props, context) => {
  const { funds, status } = props;

  if (_.isEmpty(funds)) {
    return null;
  }

  const { billingInfo, payerAccount } = funds;

  const billingMethod = getFieldData(billingInfo, 'billingMethod');
  const billingFrequency = getFieldData(billingInfo, 'billingFrequency');

  const payerAccountValue =
    !_.isEmpty(payerAccount) && payerAccount[0].newPayerAccountNumber;

  const payerAccountCurrency =
    !_.isEmpty(payerAccount) && payerAccount[0].payerAccountCurrency;

  const {
    intl: { formatMessage, locale }
  } = context;

  return (
    <>
      <FundDetailWrapper>
        <FundTypeHeading>
          {formatMessage({ id: 'newPaymentInstLabel' })}
        </FundTypeHeading>
        <ServicingKeyValue
          label="paymentFrequency"
          fieldValue={getDescription(locale, billingFrequency.narrative)}
        />
        <ServicingKeyValue
          label="paymentMethod"
          fieldValue={getDescription(locale, billingMethod.narrative)}
        />
        <ServicingAccountCurrency
          label="paymentAccount"
          accountNumber={payerAccountValue}
          currency={payerAccountCurrency}
        />
        <SlaNoteSection labelId="paymentSlaText" status={status} />
      </FundDetailWrapper>
    </>
  );
};

ChangePaymentInstruction.propTypes = {
  funds: PropTypes.object,
  status: PropTypes.string.isRequired
};

ChangePaymentInstruction.defaultProps = {
  funds: {}
};

ChangePaymentInstruction.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ChangePaymentInstruction;
