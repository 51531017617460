import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ParagraphDiv } from '../../common/styles/modalStyles';
import iconRight from '../../assets/images/Icon_right.svg';
import claim from '../../assets/images/claim.svg';
import ClaimWrapper from './ClaimWrapper';
import ModalPopUp from '../../common/modal/PopUpTemplate';
import { trackEvents } from '../../utils/tealiumUtils';
import { setLinkFocus } from '../../utils/domUtils';
import getContentInSpecificLang from '../../utils/localeUtils';

const openLink = linkInfo => {
  if (linkInfo) {
    Object.assign(document.createElement('a'), {
      target: linkInfo.target,
      href: linkInfo.url,
      rel: 'noopener noreferrer nofollow'
    }).click();
  }
};

const getClaimURL = (link, lang) => link[lang];
const MakeAClaim = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    superFamily,
    footerButtonList,
    langObj,
    encodedProductId,
    claimURLGILink,
    selectedLocale
  } = props;
  const linkRef = React.createRef();
  const [isModalOpen, updateModalState] = useState(false);
  let urlToRedirect = '';

  const isLifePolicy = superFamily === 'life';
  const redirectToClaim = `${window.location.origin}/documents-service/makeaclaim/${encodedProductId}`;

  urlToRedirect = isLifePolicy
    ? redirectToClaim
    : getClaimURL(claimURLGILink, selectedLocale);

  const makeaClaimKeyLangSpecific = getContentInSpecificLang(
    'makeaClaim',
    'en',
    langObj
  );

  const onContinueClick = () => {
    trackEvents({
      eventName: 'popup',
      eventContent: makeaClaimKeyLangSpecific.toLowerCase()
    });
    updateModalState(!isModalOpen);
  };

  const onCancelClick = () => {
    updateModalState(!isModalOpen);
    trackEvents({
      eventName: 'popup',
      eventContent: makeaClaimKeyLangSpecific.toLowerCase()
    });
    setLinkFocus(linkRef.current);
  };

  return (
    <>
      <ClaimWrapper
        id="make_a_claim"
        ariaLabel={formatMessage({ id: 'makeaClaim' })}
        onClick={e => {
          if (isLifePolicy) {
            openLink({
              url: urlToRedirect,
              target: '_self'
            });
          } else {
            updateModalState(!isModalOpen);
          }
          trackEvents({ eventName: 'makeClaim' });
          e.preventDefault();
        }}
        altText="edit-icon"
        iconSrc={claim}
        linkLabel={formatMessage({ id: 'makeaClaim' })}
        altTextIconRight="right-icon"
        iconRight={iconRight}
        linkRef={linkRef}
      />
      <ModalPopUp
        isModalOpen={isModalOpen}
        onClose={onCancelClick}
        modalHeading=""
        footerButtonList={footerButtonList}
        proceedToLink={urlToRedirect}
        btnHandler={{
          continue_btn: onContinueClick,
          cancel_btn: onCancelClick
        }}
      >
        <ParagraphDiv>
          {formatMessage({ id: 'makeaClaimPopUpText' })}
        </ParagraphDiv>
      </ModalPopUp>
    </>
  );
};

export default MakeAClaim;

MakeAClaim.contextTypes = {
  intl: PropTypes.object.isRequired
};

MakeAClaim.propTypes = {
  superFamily: PropTypes.string.isRequired,
  footerButtonList: PropTypes.array.isRequired,
  langObj: PropTypes.object.isRequired,
  encodedProductId: PropTypes.string.isRequired,
  claimURLGILink: PropTypes.string.isRequired,
  selectedLocale: PropTypes.string.isRequired
};
