import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import * as icons from '../../assets/images/NotificationIcons';
import {
  NotificationMessageType,
  NotificationMessageIcon,
  ImagePath,
  NotificationMessage,
  NotificationTextSpan
} from '../../common/styles/commonStyle';
import {
  InstructionLinkStyle,
  InstructionNotificationSpan
} from '../detailPageStyle';
import Context from '../../app/Context';
import { generateCommaSeparatedRefNumbers } from '../../instructionhistory/instructionUtils';
import { trackEvents } from '../../utils/tealiumUtils';

const InstructionInfoNotification = function FundRPQNotification(
  props,
  context
) {
  const {
    messageId,
    encodePolicyNumber,
    matchedReferenceNumbers,
    servicingOptionsURL
  } = props;
  const {
    intl: { formatMessage }
  } = context;

  const customerGroup = useSelector(state => {
    const {
      customer: { customerSegment }
    } = state;
    return customerSegment;
  });
  const getFormFieldValue = () =>
    matchedReferenceNumbers.length === 1
      ? matchedReferenceNumbers.toString()
      : matchedReferenceNumbers.join(',');

  const callTealiumEventOnLoad = () => {
    trackEvents({
      eventName: 'onViewInstructionInfoNotification',
      eventContent: `We've received your instruction of switching investment choices (${getFormFieldValue()}). It may take around 3 to 10 working days for us to process your instruction. View instruction history`,
      eventAction: 'message',
      customerGroup
    });
  };

  useEffect(() => {
    callTealiumEventOnLoad();
  }, []);

  const { InstructionHistory = {} } = servicingOptionsURL;
  if (_.isEmpty(InstructionHistory)) {
    return null;
  }

  const { type, url } = InstructionHistory;

  const callTealiumEvent = () => {
    trackEvents({
      eventName: 'onViewInstructionHistoryLink',
      eventContent: 'view instruction history',
      formField2: `message: we've received your instruction of switching investment choices (${getFormFieldValue()}). It may take around 3 to 10 working days for us to process your instruction.`,
      customerGroup
    });
  };

  const routeToNewInstructionHistory = () => {
    return (
      <Context.Consumer>
        {contextProvider => {
          const {
            applicationUrl: { forLink }
          } = contextProvider;
          const { instructionhistory } = forLink || {};
          return (
            <InstructionLinkStyle>
              <Link
                to={`${instructionhistory}${encodePolicyNumber}`}
                id="view_history"
                onClick={() => {
                  callTealiumEvent();
                }}
              >
                {formatMessage({ id: 'instruction_view' })}
              </Link>
            </InstructionLinkStyle>
          );
        }}
      </Context.Consumer>
    );
  };

  return (
    <NotificationMessageType
      rpqNotification
      bgColor="#e5f2f2"
      borderColor="#99cecc"
    >
      <NotificationMessageIcon rpqNotification role="alert">
        <InstructionNotificationSpan>
          <ImagePath
            rpqNotification
            aria-hidden="true"
            alt=""
            src={icons.Success}
          />
        </InstructionNotificationSpan>
        <NotificationMessage rpqNotification>
          <NotificationTextSpan rpqNotification id="abc">
            <span>
              {formatMessage(
                { id: messageId },
                {
                  referenceNumber: generateCommaSeparatedRefNumbers(
                    matchedReferenceNumbers,
                    'commaLabel',
                    formatMessage
                  )
                }
              )}
              {type === 'instructionHistory' ? (
                routeToNewInstructionHistory()
              ) : (
                <InstructionLinkStyle>
                  <a
                    href={url}
                    id="view_history"
                    aria-label={formatMessage({ id: 'instruction_view' })}
                    rel="noopener noreferrer"
                    target="_self"
                  >
                    {formatMessage({ id: 'instruction_view' })}
                  </a>
                </InstructionLinkStyle>
              )}
            </span>
          </NotificationTextSpan>
        </NotificationMessage>
      </NotificationMessageIcon>
    </NotificationMessageType>
  );
};

InstructionInfoNotification.propTypes = {
  messageId: PropTypes.string.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  matchedReferenceNumbers: PropTypes.array.isRequired,
  servicingOptionsURL: PropTypes.object.isRequired
};

InstructionInfoNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default InstructionInfoNotification;
