import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getImagePath } from '../../utils/routeUtils';
import ExploreCollapsibleSection from './ExploreCollapsibleSection';
import ProductTemplate from './ProductTemplate';
import { ExploreSectionWrapper } from '../summaryPageStyles';

const ExploreSection = (props, context) => {
  const { exploreSection } = props;

  const {
    intl: { formatMessage }
  } = context;

  return (
    <ExploreSectionWrapper>
      {_.map(exploreSection, (product, index) => {
        const { products } = product;
        let displayProduct = false;
        _.map(products, field => {
          if (field.displayProduct) {
            displayProduct = true;
          }
        });
        return (
          products &&
          _.size(products) !== 0 &&
          displayProduct &&
          !_.isNull(products) && (
            <ExploreCollapsibleSection
              key={index}
              imagePath={getImagePath(process.env.PUBLIC_URL, product.image)}
              expandedSectionId={`${product.productgroup}_${index}`}
              title={formatMessage({ id: product.productgroup })}
              content={products.map(field => {
                return (
                  field.displayProduct && (
                    <ProductTemplate
                      key={field.link}
                      tags={field.tags}
                      productDescription={formatMessage({ id: field.content })}
                      productTitle={formatMessage({ id: field.title })}
                      link={field.link}
                      isOpenInNewTab={field.isOpenInNewTab}
                    />
                  )
                );
              })}
            />
          )
        );
      })}
    </ExploreSectionWrapper>
  );
};

ExploreSection.propTypes = {
  exploreSection: PropTypes.array.isRequired
};

ExploreSection.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default ExploreSection;
