export const summaryUrl = '/';
export const detailsUrl = '/details/';
export const reprojectionsUrl = '/reprojections/';
export const policiesUrl = '/policies';
export const browsebycategoriesUrl = '/browsebycategories';
export const instructionHistoryUrl = '/instructionhistory/';
export const estatementUrl = '/estatement/';
export const estatementPendingInstructionUrl = '/estatementpendinginstruction/';
export const estatementConfirmationUrl = '/estatementconfirmation/';
export const isApplicationStubbed = (
  stubUrl = process.env.REACT_APP_STUB_URL,
  environment = process.env.REACT_APP_ENV
) => {
  return environment !== 'prod' && window.location.href.indexOf(stubUrl) !== -1;
};
const isStubbed = isApplicationStubbed();
const getEstatementUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${estatementUrl}:encodedProductId`
    : `${estatementUrl}:encodedProductId`;
};
const getEstatementConfirmationUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${estatementConfirmationUrl}:encodedProductId`
    : `${estatementConfirmationUrl}:encodedProductId`;
};
const getEstatementPendingInstructionUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${estatementPendingInstructionUrl}:encodedProductId`
    : `${estatementPendingInstructionUrl}:encodedProductId`;
};

const getBrowsebyCategoriesUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${browsebycategoriesUrl}`
    : browsebycategoriesUrl;
};

const getDetailsUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${detailsUrl}:encodedProductId`
    : `${detailsUrl}:encodedProductId`;
};

const getReprojectionsUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${reprojectionsUrl}:encodedProductId`
    : `${reprojectionsUrl}:encodedProductId`;
};

const getInstructionHistoryUrl = stubUrl => {
  return isStubbed
    ? `${stubUrl}${instructionHistoryUrl}:encodedProductId`
    : `${instructionHistoryUrl}:encodedProductId`;
};

export const getRoutesPagePath = (stubUrl = process.env.REACT_APP_STUB_URL) => {
  return {
    forRoute: {
      summary: isStubbed ? `${stubUrl}${summaryUrl}` : summaryUrl,
      policies: isStubbed ? `${stubUrl}${policiesUrl}` : policiesUrl,
      estatement: getEstatementUrl(stubUrl),
      estatementConfirmation: getEstatementConfirmationUrl(stubUrl),
      browsebycategories: getBrowsebyCategoriesUrl(stubUrl),
      details: getDetailsUrl(stubUrl),
      reprojections: getReprojectionsUrl(stubUrl),
      instructionhistory: getInstructionHistoryUrl(stubUrl),
      estatementpendinginstruction: getEstatementPendingInstructionUrl(stubUrl)
    },
    forLink: {
      summary: isStubbed ? `${stubUrl}${summaryUrl}` : summaryUrl,
      policies: isStubbed ? `${stubUrl}${policiesUrl}` : policiesUrl,
      estatement: isStubbed ? `${stubUrl}${estatementUrl}` : estatementUrl,
      estatementConfirmation: isStubbed
        ? `${stubUrl}${estatementConfirmationUrl}`
        : estatementConfirmationUrl,
      browsebycategories: isStubbed
        ? `${stubUrl}${browsebycategoriesUrl}`
        : browsebycategoriesUrl,
      details: isStubbed ? `${stubUrl}${detailsUrl}` : `${detailsUrl}`,
      reprojections: isStubbed
        ? `${stubUrl}${reprojectionsUrl}`
        : `${reprojectionsUrl}`,
      instructionhistory: isStubbed
        ? `${stubUrl}${instructionHistoryUrl}`
        : `${instructionHistoryUrl}`,
      estatementpendinginstruction: isStubbed
        ? `${stubUrl}${estatementPendingInstructionUrl}`
        : `${estatementPendingInstructionUrl}`
    }
  };
};

export const getImagePath = (baseurl, relativePath) => {
  return `${baseurl}${relativePath}`;
};

export const isDashboardAccessed = () => {
  // In dashboard need to check if summary page or details page
  const { location } = window;
  let summaryUrlWithOrigin = `${location.origin}${summaryUrl}`;
  let detailsUrlWithOrigin = `${location.origin}${detailsUrl}`;
  if (isApplicationStubbed()) {
    summaryUrlWithOrigin = `${location.origin}${process.env.REACT_APP_STUB_URL}${summaryUrl}`;
    detailsUrlWithOrigin = `${location.origin}${process.env.REACT_APP_STUB_URL}${detailsUrl}`;
  }
  if (
    location.href === summaryUrlWithOrigin ||
    location.href.indexOf(detailsUrlWithOrigin) > -1
  ) {
    return true;
  }
  return false;
};

export const determineEncodeID = (url = '') => {
  if (url.split(detailsUrl)[1]) {
    return url.split(detailsUrl)[1];
  }
  if (url.split(reprojectionsUrl)[1]) {
    return url.split(reprojectionsUrl)[1];
  }
  return '';
};

export const checkLocation = (pathname, routename) => {
  return pathname.includes(routename);
};

export const megaMenuPathToMatch = () => {
  return {
    policies: 'policies',
    browsebycategories: 'browsebycategories'
  };
};
