import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { Table, Tbody, Td, Tr, Thead } from '../detailPageStyle';
import { chartColors } from '../../charts/chartUtils';
import theme from '../../common/styles/theme';

function TabelField(props, context) {
  const { fieldData, type } = props;
  const {
    intl: { formatMessage }
  } = context;
  if (!fieldData || typeof fieldData !== 'object' || fieldData.length < 1) {
    return <></>;
  }
  const fieldNames = Object.keys(fieldData[0]);
  const numberOfColumns = Object.keys(fieldData[0]).length;

  /**
   * Can be used for identifying the data type to align the cell values i.e. right for amounts/numbers and left for strings/textual data
   */

  function alignmentFinder(cellData = {}) {
    const fieldType = cellData.props && cellData.props.fieldType;
    switch (fieldType) {
      case 'String':
        return { textAlign: 'left', wordBreak: 'normal' };
      case 'Number':
        return { textAlign: 'right', wordBreak: 'break-all' };
      default:
        return { textAlign: 'left', wordBreak: 'normal' };
    }
  }

  return (
    <Table x-ms-format-detection="none" type={type}>
      <Thead style={{ borderBottom: '1px solid {theme.lightGreyColor}' }}>
        <Tr>
          {_.map(fieldNames, fieldName => (
            <Td
              textAlign={alignmentFinder(fieldData[0][fieldName]).textAlign}
              id={`${type}_${fieldName}`}
              type={type}
            >
              {formatMessage({ id: fieldName })}
            </Td>
          ))}
        </Tr>
      </Thead>
      <Tbody type={type}>
        {_.map(fieldData, (fieldDataInstance, rowIndex) => (
          <Tr>
            {_.map(fieldNames, (fieldName, index) => (
              <>
                {index === 0 && (
                  <Td
                    borderColor={chartColors[rowIndex]}
                    otherBorderColor={theme.subLinksText}
                    toShow={numberOfColumns >= 4}
                    type={type}
                    id={`${type}_${fieldName}_${rowIndex}_${index}`}
                  >
                    {fieldDataInstance[fieldName]}
                  </Td>
                )}
                {index !== 0 && (
                  <Td
                    textAlign={
                      alignmentFinder(fieldDataInstance[fieldName]).textAlign
                    }
                    wordBreak={
                      alignmentFinder(fieldDataInstance[fieldName]).wordBreak
                    }
                    id={`${type}_${fieldName}_${rowIndex}_${index}`}
                  >
                    {fieldDataInstance[fieldName]}
                  </Td>
                )}
              </>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

TabelField.propTypes = {
  fieldData: PropTypes.array.isRequired,
  type: PropTypes.string
};

TabelField.defaultProps = {
  type: ''
};

TabelField.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TabelField;
