import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  SeeDetailsWrapper,
  SeeDetail,
  ImgWrapper,
  IconRightImg,
  SeeDetailsTag
} from '../summaryPageStyles';
import getDescription from '../../utils/reducerUtils';
import iconRight from '../../assets/images/Icon_right.svg';
import Context from '../../app/Context';
import WelcomeModal from '../../eWelcome/WelcomeModal';
import { trackEvents, getPlanName } from '../../utils/tealiumUtils';

const SeeDetailsLink = (props, context) => {
  const {
    policynumber,
    encodePolicyNumber,
    productFieldsObject,
    showWelcomePack,
    customerPolicy
  } = props;
  const {
    intl: { formatMessage, locale }
  } = context;
  const [welcomeModelOverlay, setWelcomeModelOverlay] = useState(false);

  const onClickLink = e => {
    trackEvents({ eventName: 'viewDetails' });
    if (showWelcomePack) {
      e.preventDefault();
      const { planName } = customerPolicy;
      getPlanName(getDescription('en', planName)); // to get plan name only in English for tealium tagging
      const planNameValue = getDescription(locale, planName);
      const eventContent = `your new policy '${planNameValue}' is ready`;
      trackEvents({
        eventName: 'eWelcomeViewDetails',
        eventData: {
          eventAction: 'message',
          eventContent,
          customerPolicy
        }
      });
      setWelcomeModelOverlay(!welcomeModelOverlay);
    }
  };

  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;
        const { details } = forLink || {};
        const policyName = getDescription(
          locale,
          productFieldsObject.field2.value
        );
        return (
          <>
            <SeeDetailsWrapper>
              {showWelcomePack ? (
                <SeeDetailsTag
                  id={`view_details_${policynumber}`}
                  aria-label={`${policyName} ${formatMessage({
                    id: 'seeDetails'
                  })}`}
                  href=""
                  onClick={onClickLink}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onClickLink();
                    }
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <SeeDetail>{formatMessage({ id: 'seeDetails' })}</SeeDetail>
                  <ImgWrapper>
                    <IconRightImg aria-hidden="true" alt="" src={iconRight} />
                  </ImgWrapper>
                </SeeDetailsTag>
              ) : (
                <Link
                  to={`${details}${encodePolicyNumber}`}
                  id={`view_details_${policynumber}`}
                  aria-label={`${policyName} ${formatMessage({
                    id: 'seeDetails'
                  })}`}
                  onClick={onClickLink}
                >
                  <SeeDetail>{formatMessage({ id: 'seeDetails' })}</SeeDetail>
                  <ImgWrapper>
                    <IconRightImg aria-hidden="true" alt="" src={iconRight} />
                  </ImgWrapper>
                </Link>
              )}
            </SeeDetailsWrapper>
            {welcomeModelOverlay ? (
              <WelcomeModal
                pathName={details}
                policyNumber={policynumber}
                encodePolicyNumber={encodePolicyNumber}
                isOpenModal={welcomeModelOverlay}
                customerPolicy={customerPolicy}
                onCancel={() => setWelcomeModelOverlay(!welcomeModelOverlay)}
              />
            ) : null}
          </>
        );
      }}
    </Context.Consumer>
  );
};

SeeDetailsLink.propTypes = {
  policynumber: PropTypes.string.isRequired,
  encodePolicyNumber: PropTypes.string.isRequired,
  productFieldsObject: PropTypes.object.isRequired,
  showWelcomePack: PropTypes.bool.isRequired,
  customerPolicy: PropTypes.object.isRequired
};

SeeDetailsLink.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default SeeDetailsLink;
