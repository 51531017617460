import React, { Component } from 'react';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  HsbcLogo,
  ULSection,
  LISection,
  ButtonText,
  LinkText,
  NavSection,
  HeaderContainer,
  HsbcFlag,
  LogoffWrapper,
  LinkTextPib,
  FlagText
} from './MenuBarStyles';
import LanguageSelector from './LanguageSelector';
import hsbcLogoEn from '../../../assets/images/hsbc_logo_en.svg';
import hsbcLogoSc from '../../../assets/images/hsbc_logo_sc.svg';
import hsbcLogoTc from '../../../assets/images/hsbc_logo_tc.svg';
import CustomButton from '../../../common/customButton/CustomButton';
import { trackEvents } from '../../../utils/tealiumUtils';
import MegaMenuDesktop from './MegaMenu/MegaMenuDesktop';
import {
  checkMastheadMenuLinks,
  checkMegaMenuFor,
  keyboardHandler
} from './MegaMenu/megaMenuUtils';

class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isDesktop: window.innerWidth > 1024 });
  };

  handleMegaMenu = e => {
    keyboardHandler(e);
  };

  render() {
    const {
      languageConfig,
      onLanguageChange,
      onLogOff,
      selectedLanguage,
      mastHeaderLinks,
      intl: { locale, messages = {}, formatMessage } = {},
      megaMenuConfig: { megaMenuSection, megaMenuForLink },
      totalPolicyCount,
      setSelectedTabHandler,
      langObj,
      customerSegmentValues,
      customer
    } = this.props;
    const { isDesktop } = this.state;
    let hsbcLogoUrl = null;
    if (Object.keys(messages).length < 1) {
      return <></>;
    }

    switch (locale) {
      case 'zh':
        hsbcLogoUrl = hsbcLogoSc;
        break;
      case 'zh-tc':
        hsbcLogoUrl = hsbcLogoTc;
        break;
      default:
        hsbcLogoUrl = hsbcLogoEn;
    }

    return (
      <NavSection id="navSection">
        <HeaderContainer>
          <HsbcLogo id="hsbcLogo">
            <img alt={formatMessage({ id: 'logoAltText' })} src={hsbcLogoUrl} />
          </HsbcLogo>
          <React.Fragment role="menubar">
            <ULSection id="mastheadLinks" topLeft role="presentation">
              {_.map(mastHeaderLinks, (link, idx) => {
                let currentMenuItemIdx = idx;
                currentMenuItemIdx += 1;
                return (
                  <LISection
                    key={idx}
                    id={link.text}
                    role="presentation"
                    className={
                      checkMegaMenuFor(link.text, megaMenuForLink)
                        ? 'hasMegaMenu'
                        : ''
                    }
                  >
                    {link.text === megaMenuForLink ? (
                      <ButtonText
                        id={`${link.text}_link`}
                        onKeyUp={this.handleMegaMenu}
                        aria-label={formatMessage({ id: link.text })}
                        role="menuitem"
                        aria-setsize={mastHeaderLinks.length}
                        aria-posinset={currentMenuItemIdx}
                        aria-haspopup
                      >
                        <LinkTextPib>
                          {' '}
                          {formatMessage({ id: link.text })}
                        </LinkTextPib>
                      </ButtonText>
                    ) : (
                      <LinkText
                        id={`${link.text}_link`}
                        href={checkMastheadMenuLinks(
                          link.text,
                          megaMenuForLink,
                          link.url
                        )}
                        isActive={link.isActive}
                        aria-label={formatMessage({ id: link.text })}
                        role="menuitem"
                        aria-setsize={mastHeaderLinks.length}
                        aria-posinset={currentMenuItemIdx}
                      >
                        <LinkTextPib>
                          {' '}
                          {formatMessage({ id: link.text })}
                        </LinkTextPib>
                      </LinkText>
                    )}
                    {checkMegaMenuFor(link.text, megaMenuForLink) && (
                      <MegaMenuDesktop
                        megaMenuItems={megaMenuSection}
                        totalPolicyCount={totalPolicyCount}
                        setSelectedTabHandler={setSelectedTabHandler}
                        langObj={langObj}
                        customer={customer}
                        customerSegmentValues={customerSegmentValues}
                      />
                    )}
                  </LISection>
                );
              })}
            </ULSection>
          </React.Fragment>
          <ULSection rightAlign topRight>
            <LISection displayline role="presentation">
              <HsbcFlag>
                <FlagText id="hongkongText">
                  {isDesktop ? (
                    <FormattedMessage
                      id="hong_Kong"
                      defaultMessage="Hong Kong"
                    />
                  ) : (
                    <FormattedMessage id="hkg" defaultMessage="HKG" />
                  )}
                </FlagText>
              </HsbcFlag>
            </LISection>
            <LISection displayline role="presentation">
              <LanguageSelector
                options={languageConfig}
                onChange={onLanguageChange}
                value={selectedLanguage}
                id="languageSelector"
              />
            </LISection>
            <LISection>
              <LogoffWrapper>
                <CustomButton
                  data-locator-id="btnLogoff"
                  className="redBtn pull-right log_OFF"
                  label={formatMessage({ id: 'log_Off' })}
                  onClick={() => {
                    onLogOff();
                    trackEvents({ eventName: 'logOff' });
                  }}
                  id="logOffBtn"
                />
              </LogoffWrapper>
            </LISection>
          </ULSection>
        </HeaderContainer>
      </NavSection>
    );
  }
}

MenuBar.propTypes = {
  languageConfig: PropTypes.array.isRequired,
  onLogOff: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  mastHeaderLinks: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  megaMenuConfig: PropTypes.object.isRequired,
  megaMenuSection: PropTypes.object.isRequired,
  megaMenuForLink: PropTypes.string.isRequired,
  totalPolicyCount: PropTypes.number.isRequired,
  setSelectedTabHandler: PropTypes.func.isRequired,
  langObj: PropTypes.object.isRequired,
  customerSegmentValues: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired
};

export default injectIntl(MenuBar);
