import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ModalPopUp from '../../../common/modal/PopUpTemplate';

import singleUser from '../../../assets/images/User.svg';
import warningIcon from '../../../assets/images/warningBlue.svg';
import BeneficaryFirstField from './BeneficiaryFirstField';
import {
  ImgStyleProfileIcon,
  TableIconWrapperContainer,
  TableIconWrapperDextop,
  Td,
  NilDetailsWarningDiv,
  NilDetailsImagePath,
  NilDetails,
  ModalContentFormatted
} from './beneficiarySectionStyles';

function TableNameWithImage(props, context) {
  const {
    id,
    field,
    item,
    irrevocableAssigned,
    rawData,
    rowIndex,
    isNameFieldEmpty,
    beneficiaryType,
    missingInfoFlag
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const [isModalOpen, updateModalState] = useState(false);
  const linkRef = React.createRef();
  const showPopupLink = missingInfoFlag || isNameFieldEmpty;

  return (
    <Td id={id} isNameFieldEmpty={isNameFieldEmpty}>
      <TableIconWrapperContainer>
        <TableIconWrapperDextop>
          <ImgStyleProfileIcon aria-hidden="true" alt="" src={singleUser} />
        </TableIconWrapperDextop>
        <BeneficaryFirstField
          field={field}
          item={item}
          irrevocableAssigned={irrevocableAssigned}
          rawData={rawData}
          id={rowIndex}
          beneficiaryType={beneficiaryType}
          missingInfoFlag={missingInfoFlag}
        />
      </TableIconWrapperContainer>

      {showPopupLink && (
        <>
          <NilDetailsWarningDiv
            role="alert"
            aria-live="assertive"
            href={linkRef}
            onClick={e => {
              updateModalState(!isModalOpen);
              e.preventDefault();
            }}
          >
            <NilDetailsImagePath src={warningIcon} alt="warning-icon" />
            <NilDetails>
              {formatMessage({ id: 'modalDetailsMissingTitle' })}
            </NilDetails>
          </NilDetailsWarningDiv>

          <ModalPopUp
            isModalOpen={isModalOpen}
            onClose={() => {
              updateModalState(!isModalOpen);
            }}
            modalHeading={formatMessage({
              id: 'modalDetailsMissingTitle'
            })}
            id="nilDetailsModal"
          >
            <ModalContentFormatted>
              <FormattedHTMLMessage
                id={
                  beneficiaryType === 'primary'
                    ? 'modalDetailsMissingDescription1'
                    : 'modalDetailsMissingDescription2'
                }
              />
            </ModalContentFormatted>
          </ModalPopUp>
        </>
      )}
    </Td>
  );
}

TableNameWithImage.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.any),
  irrevocableAssigned: PropTypes.bool.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowIndex: PropTypes.number.isRequired,
  isNameFieldEmpty: PropTypes.bool.isRequired,
  beneficiaryType: PropTypes.string.isRequired,
  missingInfoFlag: PropTypes.bool.isRequired
};

TableNameWithImage.defaultProps = {
  item: null
};

TableNameWithImage.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default TableNameWithImage;
