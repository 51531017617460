import {
  SET_REPROJECTIONS,
  SET_REPROJECTIONS_ERRORS_WARNINGS,
  SET_REPROJECTIONS_AGE_SELECTION,
  SET_REPROJECTIONS_MIN_AGE,
  SET_REPROJECTIONS_MAX_AGE,
  SET_INCLUDE_NON_GUARANTEED_PAYOUT,
  TOGGLE_INCLUDE_NON_GUARANTEED_PAYOUT,
  SET_REPROJECTIONS_SELECTED_TAB,
  RESET_REPROJECTIONS_ERRORS_WARNINGS
} from '../actionTypes';

export const INITIAL_STATE = {
  ageSelection: null,
  byEncodeId: {},
  reprojectionsWarningsOrErrors: {},
  minAge: null,
  maxAge: null,
  selectedTab: null,
  tabValues: {}
};

const reprojectionReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
  switch (type) {
    case SET_REPROJECTIONS: {
      const updatedReprojections = { ...state.byEncodeId, ...payload };
      return {
        ...state,
        byEncodeId: updatedReprojections
      };
    }
    case SET_REPROJECTIONS_ERRORS_WARNINGS: {
      const warnings = {
        ...state.reprojectionsWarningsOrErrors,
        ...payload
      };
      return {
        ...state,
        reprojectionsWarningsOrErrors: warnings
      };
    }
    case RESET_REPROJECTIONS_ERRORS_WARNINGS: {
      return {
        ...state,
        reprojectionsWarningsOrErrors: {}
      };
    }

    case SET_REPROJECTIONS_AGE_SELECTION: {
      return {
        ...state,
        ageSelection: payload
      };
    }

    case SET_REPROJECTIONS_MIN_AGE: {
      return {
        ...state,
        minAge: payload
      };
    }

    case SET_REPROJECTIONS_MAX_AGE: {
      return {
        ...state,
        maxAge: payload
      };
    }

    case TOGGLE_INCLUDE_NON_GUARANTEED_PAYOUT: {
      const { section, includeNonGuaranteedPayout } = payload;
      const benefits = {
        tabValues: {
          ...state.tabValues,
          [section]: {
            ...state.tabValues[section],
            includeNonGuaranteedPayout
          }
        }
      };
      return {
        ...state,
        ...benefits
      };
    }

    case SET_INCLUDE_NON_GUARANTEED_PAYOUT: {
      const { defaultTabValues } = payload;
      const initialTabValues = {
        tabValues: defaultTabValues
      };
      return {
        ...state,
        ...initialTabValues
      };
    }

    case SET_REPROJECTIONS_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: payload
      };
    }

    default:
      return state;
  }
};

export default reprojectionReducer;
