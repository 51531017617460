import React from 'react';
import PropTypes from 'prop-types';
import {
  PendingDealingNotificationWrapper,
  PendingDealingNotificationIcon,
  PendingDealingNotificationMessage
} from '../../../common/styles/fundsRPQPopupStyle';

const PendingDealingNotification = function PendingDealingNotification(
  props,
  context
) {
  const { pendingDealingFieldData, contentKey, pendingDealinIcon } = props;
  const {
    intl: { formatMessage }
  } = context;

  return (
    pendingDealingFieldData && (
      <PendingDealingNotificationWrapper id="PendingDealingNotification">
        <PendingDealingNotificationIcon
          aria-hidden="true"
          alt=""
          src={pendingDealinIcon}
        />
        <PendingDealingNotificationMessage>
          {formatMessage({ id: contentKey })}
        </PendingDealingNotificationMessage>
      </PendingDealingNotificationWrapper>
    )
  );
};

PendingDealingNotification.propTypes = {
  pendingDealingFieldData: PropTypes.bool.isRequired,
  contentKey: PropTypes.string.isRequired,
  pendingDealinIcon: PropTypes.string.isRequired
};

PendingDealingNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default PendingDealingNotification;
