import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Policy,
  NeedBox,
  IconSpan,
  InfoBox,
  ListItems,
  AccordionBox,
  AccordionInfo,
  Cardheading,
  NeedInfoDiv
} from './WelcomeModalStyle';
import RectanguleIcon from '../assets/images/Modal/icon/Portfolio.svg';
import ToggleHandler from './ToggleHandler';

const AccordionSection = props => {
  const { langObj } = props;
  return (
    <>
      <NeedBox>
        <Policy setLeftMergin>
          {' '}
          <IconSpan>
            {' '}
            <img
              data-locator-id="Don'tNeedIcon"
              alt=""
              src={RectanguleIcon}
            />{' '}
          </IconSpan>
          <Cardheading>
            <FormattedMessage
              id="dontNeedLabel"
              defaultMessage="Don't need them right now?"
            />
          </Cardheading>
        </Policy>
        <br />
        <NeedInfoDiv>
          <FormattedMessage
            id="downloadLaterLabel"
            defaultMessage="You can also go to policy detail page to download the documents when you need them later."
          />
        </NeedInfoDiv>
        <NeedInfoDiv>
          <FormattedMessage
            id="downloadLaterLabelQues"
            defaultMessage="If you have any questions, you can call us on (852) 2583 8000."
          />
        </NeedInfoDiv>
      </NeedBox>
      <ToggleHandler
        sectionTitleFormat={[
          <FormattedMessage
            id="accordionTitle"
            defaultMessage="Your Right to Change Your Mind"
          />
        ]}
        expandedSectionId="expanded_accordion"
        titleId="accordion_content"
        langObj={langObj}
      >
        <AccordionBox>
          <AccordionInfo isBold>
            <FormattedMessage
              id="accordionDesc1"
              defaultMessage="If you are not fully satisfied with this policy, you have the right to change your mind."
            />
          </AccordionInfo>
          <AccordionInfo isBold>
            <FormattedMessage
              id="accordionDesc2"
              defaultMessage=" We trust that this policy will satisfy your financial needs. However, if you are not completely satisfied then you should:"
            />
          </AccordionInfo>
          <AccordionInfo>
            <ListItems isBigFont>
              <FormattedMessage
                id="accordionPoint1"
                defaultMessage="(a) return the policy, if applicable, and"
              />
            </ListItems>
            <ListItems isBigFont>
              <FormattedMessage
                id="accordionPoint2"
                defaultMessage="(b) provide us with written notice, [signed by you], requesting cancellation."
              />
            </ListItems>
          </AccordionInfo>
          <AccordionInfo>
            <FormattedMessage
              id="accordionDesc3"
              defaultMessage="The policy will then be cancelled and the premium(s) paid will be refunded*."
              values={{ val: <sup>1</sup> }}
            />
          </AccordionInfo>
          <AccordionInfo isBold>
            <FormattedMessage
              id="accordionDesc4"
              defaultMessage="These cancellation rights have the following conditions:"
            />
          </AccordionInfo>
          <AccordionInfo>
            <ListItems isBigFont>
              <FormattedMessage
                id="accordionPoint3"
                defaultMessage="you have a right to cancel it within the cooling-off period and obtain a refund of any premiums and levies paid. A written notice signed by you togeether with your policy (if received) should be received by the office of HSBC Life (International) Limited at 18/F, Tower 1, HSBC Centre, 1 Sham Mong Road, Kowloon, Hong Kong within the cooling-off period (that is, a period of 21 calendar days immediately following either the day of delivery of the policy or the day of delivery of the cooling-off notice to the policyholder or the nominated representative, whichever is earlier), and"
              />
            </ListItems>
            <ListItems isBigFont>
              <FormattedMessage
                id="accordionPoint4"
                defaultMessage=" no refund can be made if a claim payment has been made under the policy."
              />
            </ListItems>
          </AccordionInfo>
          <AccordionInfo>
            <FormattedMessage
              id="accordionDesc5"
              defaultMessage="Should you have any further queries, please contact HSBC Insurance Service Hotline on (852)2583-8000. We are happy to explain your cancellation right further."
              values={{ val: <sup>2</sup> }}
            />
          </AccordionInfo>
          <AccordionInfo isBold isSmallFont>
            <FormattedMessage id="accordionDesc6" defaultMessage=" Notes:" />
          </AccordionInfo>
          <AccordionInfo>
            <ListItems>
              <FormattedMessage
                id="accordionPoint5"
                defaultMessage="If your policy is an investment-linked life insurance plan, the premium(s) paid will be refunded less any market value adjustment calculated solely with reference to the loss amount the Company has incurred when realising the assets acquired through investment of premiums made under the plan. If your policy is an non investment -linked single payment life insurance plan, the premium(s) paid will be refunded subject to any market value adjustment."
                values={{ val: <sup>1</sup> }}
              />
            </ListItems>
            <ListItems>
              <FormattedMessage
                id="accordionPoint6"
                defaultMessage="Please note that tele-conversation may be recorded to ensure service quality."
                values={{ val: <sup>2</sup> }}
              />
            </ListItems>
          </AccordionInfo>
        </AccordionBox>
      </ToggleHandler>
      <AccordionInfo showBorder>
        <FormattedMessage
          id="accordionDesc7"
          defaultMessage="By opening / clicking the welcome letter, you acknowledge the receipt of the welcome pack and read the cooling-off reminder"
        />
      </AccordionInfo>
      <InfoBox>
        <ListItems>
          <FormattedMessage id="info1" />
        </ListItems>
        <ListItems>
          <FormattedMessage id="info2" />
        </ListItems>
      </InfoBox>
    </>
  );
};

AccordionSection.propTypes = {
  langObj: PropTypes.object.isRequired
};
export default AccordionSection;
