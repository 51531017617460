import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import ModalPopUp from '../../../common/modal/PopUpTemplate';
import RpqPopupTableFormatter from './popUpModal/RpqPopupTableFormatter';
import * as icons from '../../../assets/images/NotificationIcons';
import {
  NotificationMessageType,
  NotificationMessageIcon,
  NotificationIconSpan,
  ImagePath,
  NotificationMessage,
  NotificationTextSpan,
  RPQNotificationWrapper
} from '../../../common/styles/commonStyle';
import { ButtonLinkStyle } from '../../../common/customButton/CustomButtonStyledComp';
import {
  SubHeaderText,
  ListWrapperDiv,
  ListHeading,
  ListWrapperUL,
  ListWrapperLI,
  ListHeadingLI,
  ListInfoLI,
  ListLinksWrapper,
  ListLinks,
  ListHeadingWrapper,
  ErrorDescription
} from '../../../common/styles/fundsRPQPopupStyle';
import { matchedRiskLevel, columnData } from './popUpModal/fundsPopupTestData';
import { trackViews } from '../../../utils/tealiumUtils';
import Currency from '../../../common/currency/Currency';
import {
  getMatchedCoreFund,
  getFundBalanceWithPremiumAllocation,
  canDisplayRPQMismatchNotification
} from '../../sectionUtils/FundUtils';
import { isIE } from '../../../utils/domUtils';

const FundRPQNotification = function FundRPQNotification(props, context) {
  const {
    displayFor,
    rpqNotification,
    getServicingLink,
    ChangeInPremiumAllocation,
    TransferOfFunds,
    fundSectionHeading,
    currentPolicyDetail,
    updateModalState,
    isModalOpen,
    fundSelectionData,
    fundSelectionWarningsOrErrors,
    rpqUTBConfig,
    transferTypeLabel,
    isCustomerWhiteListedForTFF,
    matchedReferenceNumbers,
    transferFundPendingIndicator
  } = props;
  const [isRPQModalOpen, updateRPQModalState] = useState(false);
  const {
    intl: { formatMessage }
  } = context;

  useEffect(() => {
    if (isRPQModalOpen === undefined) {
      updateModalState(true);
    }
  }, [isRPQModalOpen]);

  const onCancelClick = () => {
    updateRPQModalState(!isRPQModalOpen);
  };
  const footerButtonList = [
    {
      className: 'redBtn',
      label: formatMessage({ id: 'closeRPQMismatchPopup' }),
      id: 'cancel_btn',
      align: 'right',
      type: 'button'
    }
  ];

  const hideChangePremiumAllocation = _.isEmpty(ChangeInPremiumAllocation);
  let rpqMismatchPopupAmountData = {};
  if (currentPolicyDetail) {
    rpqMismatchPopupAmountData = getFundBalanceWithPremiumAllocation(
      currentPolicyDetail
    );
  }
  const {
    fundBalance,
    premiumAmount,
    policyCurrency
  } = rpqMismatchPopupAmountData;
  const canShowRPQMismatchNotification = rpqValue => {
    const rpqZeroOrChanged =
      displayFor === rpqValue &&
      rpqNotification === rpqValue &&
      isCustomerWhiteListedForTFF;
    const dynamicNotification = {
      ZeroRPQ: rpqZeroOrChanged,
      ChangeRPQ: rpqZeroOrChanged,
      UnchangeRPQ: displayFor === rpqValue && rpqNotification === rpqValue
    };
    return dynamicNotification[displayFor];
  };

  const isUnchangeRPQDisplay =
    canShowRPQMismatchNotification('UnchangeRPQ') &&
    transferFundPendingIndicator === false;

  return (
    <>
      {canDisplayRPQMismatchNotification(matchedReferenceNumbers) && (
        <RPQNotificationWrapper>
          {canShowRPQMismatchNotification('ZeroRPQ') && (
            <>
              {trackViews({
                viewName: 'onRPQNotificationPopupLoad'
              })}
              <NotificationMessageType
                rpqNotification
                bgColor="#ebeff4"
                borderColor="#acbdce"
              >
                <NotificationMessageIcon rpqNotification role="alert">
                  <NotificationIconSpan>
                    <ImagePath
                      rpqNotification
                      aria-hidden="true"
                      alt=""
                      src={icons.Information}
                    />
                  </NotificationIconSpan>
                  <NotificationMessage rpqNotification>
                    <NotificationTextSpan
                      rpqNotification
                      id="zeroRPQNotification"
                    >
                      <span>
                        {formatMessage({ id: 'zeroRPQNotification' })}
                        <ButtonLinkStyle
                          type="button"
                          id="rpqModalButton"
                          onClick={e => {
                            updateRPQModalState(!isRPQModalOpen);
                            e.preventDefault();
                          }}
                        >
                          {formatMessage({ id: 'changeRPQUpdateNowButton' })}
                        </ButtonLinkStyle>
                      </span>
                    </NotificationTextSpan>
                  </NotificationMessage>
                </NotificationMessageIcon>
              </NotificationMessageType>
              <ModalPopUp
                isModalOpen={isRPQModalOpen}
                onClose={onCancelClick}
                footerButtonList={footerButtonList}
                modalHeading={formatMessage({ id: 'zeroRPQModalTitle' })}
                hideButton
              >
                <SubHeaderText>
                  {formatMessage({ id: 'zeroRPQModalDescription' })}
                </SubHeaderText>
                <ListWrapperDiv>
                  <ListHeading>
                    {formatMessage({ id: 'rpqModalListTitle' })}
                  </ListHeading>
                  <ListWrapperUL isIE={isIE}>
                    <ListWrapperLI>
                      <ListHeadingWrapper isIE={isIE}>
                        <ListHeadingLI>
                          {formatMessage({ id: 'rpqModalList_1_title' })}
                        </ListHeadingLI>
                        <ListInfoLI>
                          {/* TBD */}
                          <FormattedMessage
                            id="rpqModalList_1_Notes"
                            values={{
                              fundBalance: (
                                <Currency
                                  amount={fundBalance}
                                  currencyCode={policyCurrency}
                                />
                              )
                            }}
                          />
                        </ListInfoLI>
                      </ListHeadingWrapper>
                      <ListLinksWrapper isIE={isIE}>
                        <ListLinks>
                          {getServicingLink(
                            TransferOfFunds,
                            'TransferOfFunds_Link',
                            transferTypeLabel,
                            fundSectionHeading,
                            formatMessage,
                            currentPolicyDetail,
                            updateModalState,
                            false,
                            updateRPQModalState
                          )}
                        </ListLinks>
                      </ListLinksWrapper>
                    </ListWrapperLI>
                    {!hideChangePremiumAllocation && (
                      <ListWrapperLI>
                        <ListHeadingWrapper isIE={isIE}>
                          <ListHeadingLI>
                            {formatMessage({ id: 'rpqModalList_2_title' })}
                          </ListHeadingLI>
                          <ListInfoLI>
                            <FormattedMessage
                              id="rpqModalList_2_Notes"
                              values={{
                                investingBalance: (
                                  <Currency
                                    amount={premiumAmount}
                                    currencyCode={policyCurrency}
                                  />
                                )
                              }}
                            />
                          </ListInfoLI>
                        </ListHeadingWrapper>
                        <ListLinksWrapper isIE={isIE}>
                          <ListLinks>
                            {getServicingLink(
                              ChangeInPremiumAllocation,
                              'ChangeInPremium_Link',
                              'ChangeInPremiumAllocation',
                              fundSectionHeading,
                              formatMessage,
                              currentPolicyDetail,
                              updateModalState,
                              isModalOpen,
                              updateRPQModalState
                            )}
                          </ListLinks>
                        </ListLinksWrapper>
                      </ListWrapperLI>
                    )}
                  </ListWrapperUL>
                </ListWrapperDiv>
              </ModalPopUp>
            </>
          )}
          {canShowRPQMismatchNotification('ChangeRPQ') && (
            <>
              <NotificationMessageType
                rpqNotification
                bgColor="#ebeff4"
                borderColor="#acbdce"
              >
                <NotificationMessageIcon rpqNotification role="alert">
                  <NotificationIconSpan>
                    <ImagePath
                      rpqNotification
                      aria-hidden="true"
                      alt=""
                      src={icons.Information}
                    />
                  </NotificationIconSpan>
                  <NotificationMessage rpqNotification>
                    <NotificationTextSpan
                      rpqNotification
                      id="changeRPQNotification"
                    >
                      <span>
                        {formatMessage({ id: 'changeRPQNotification' })}
                        <ButtonLinkStyle
                          type="button"
                          id="rpqModalButton"
                          onClick={e => {
                            updateRPQModalState(!isRPQModalOpen);
                            e.preventDefault();
                          }}
                        >
                          {formatMessage({ id: 'changeRPQUpdateNowButton' })}
                        </ButtonLinkStyle>
                      </span>
                    </NotificationTextSpan>
                  </NotificationMessage>
                </NotificationMessageIcon>
              </NotificationMessageType>
              {fundSelectionWarningsOrErrors ? (
                <ModalPopUp
                  isModalOpen={isRPQModalOpen}
                  onClose={onCancelClick}
                  modalHeading={formatMessage({
                    id: 'fund_rpq_mismatch_error_heading'
                  })}
                  footerButtonList={footerButtonList}
                  maxWidth="32em"
                  hideHorizonatalLine
                  btnHandler={{
                    cancel_btn: onCancelClick
                  }}
                >
                  <ErrorDescription>
                    {formatMessage({
                      id: 'fund_rpq_mismatch_error_description'
                    })}
                  </ErrorDescription>
                </ModalPopUp>
              ) : (
                <ModalPopUp
                  isModalOpen={isRPQModalOpen}
                  onClose={onCancelClick}
                  footerButtonList={footerButtonList}
                  modalHeading={formatMessage({ id: 'rpqModalTitle' })}
                  hideButton
                >
                  <SubHeaderText>
                    {formatMessage({ id: 'rpqModalNote' })}
                  </SubHeaderText>
                  <RpqPopupTableFormatter
                    fundsColumnData={columnData}
                    fundsTableData={getMatchedCoreFund(
                      currentPolicyDetail,
                      fundSelectionData
                    )}
                    matchedRiskLevel={matchedRiskLevel}
                    rpqUTBConfig={rpqUTBConfig}
                  />
                  <ListWrapperDiv>
                    <ListHeading>
                      {formatMessage({ id: 'rpqModalListTitle' })}
                    </ListHeading>
                    <ListWrapperUL isIE={isIE}>
                      <ListWrapperLI hideBorder={hideChangePremiumAllocation}>
                        <ListHeadingWrapper isIE={isIE}>
                          <ListHeadingLI>
                            {formatMessage({ id: 'rpqModalList_1_title' })}
                          </ListHeadingLI>
                          <ListInfoLI>
                            <FormattedMessage
                              id="rpqModalList_1_Notes"
                              values={{
                                fundBalance: (
                                  <Currency
                                    amount={fundBalance}
                                    currencyCode={policyCurrency}
                                  />
                                )
                              }}
                            />
                          </ListInfoLI>
                        </ListHeadingWrapper>
                        <ListLinksWrapper isIE={isIE}>
                          <ListLinks>
                            {getServicingLink(
                              TransferOfFunds,
                              'TransferOfFunds_Link',
                              transferTypeLabel,
                              fundSectionHeading,
                              formatMessage,
                              currentPolicyDetail,
                              updateModalState,
                              false,
                              updateRPQModalState,
                              isRPQModalOpen
                            )}
                          </ListLinks>
                        </ListLinksWrapper>
                      </ListWrapperLI>
                      {!hideChangePremiumAllocation && (
                        <ListWrapperLI>
                          <ListHeadingWrapper isIE={isIE}>
                            <ListHeadingLI>
                              {formatMessage({ id: 'rpqModalList_2_title' })}
                            </ListHeadingLI>
                            <ListInfoLI>
                              <FormattedMessage
                                id="rpqModalList_2_Notes"
                                values={{
                                  investingBalance: (
                                    <Currency
                                      amount={premiumAmount}
                                      currencyCode={policyCurrency}
                                    />
                                  )
                                }}
                              />
                            </ListInfoLI>
                          </ListHeadingWrapper>
                          <ListLinksWrapper isIE={isIE}>
                            <ListLinks>
                              {getServicingLink(
                                ChangeInPremiumAllocation,
                                'ChangeInPremium_Link',
                                'ChangeInPremiumAllocation',
                                fundSectionHeading,
                                formatMessage,
                                currentPolicyDetail,
                                updateModalState,
                                isModalOpen,
                                updateRPQModalState,
                                isRPQModalOpen
                              )}
                            </ListLinks>
                          </ListLinksWrapper>
                        </ListWrapperLI>
                      )}
                    </ListWrapperUL>
                  </ListWrapperDiv>
                </ModalPopUp>
              )}
            </>
          )}
          {isUnchangeRPQDisplay && (
            <NotificationMessageType
              unchangeRPQ
              bgColor="#fff"
              borderColor="#fff"
            >
              <NotificationMessageIcon role="alert">
                <NotificationIconSpan>
                  <ImagePath
                    rpqNotification
                    aria-hidden="true"
                    alt=""
                    src={icons.Information}
                  />
                </NotificationIconSpan>
                <NotificationMessage rpqNotification>
                  <span>
                    {formatMessage({ id: 'unchangeRPQNotification' })}
                  </span>
                </NotificationMessage>
              </NotificationMessageIcon>
            </NotificationMessageType>
          )}
        </RPQNotificationWrapper>
      )}
    </>
  );
};

FundRPQNotification.propTypes = {
  displayFor: PropTypes.string.isRequired,
  rpqNotification: PropTypes.string.isRequired,
  getServicingLink: PropTypes.func.isRequired,
  ChangeInPremiumAllocation: PropTypes.string,
  TransferOfFunds: PropTypes.string,
  fundSectionHeading: PropTypes.string,
  currentPolicyDetail: PropTypes.object.isRequired,
  updateModalState: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  fundSelectionData: PropTypes.object.isRequired,
  fundSelectionWarningsOrErrors: PropTypes.object.isRequired,
  rpqUTBConfig: PropTypes.object.isRequired,
  transferTypeLabel: PropTypes.string,
  isCustomerWhiteListedForTFF: PropTypes.bool,
  matchedReferenceNumbers: PropTypes.array.isRequired,
  transferFundPendingIndicator: PropTypes.bool
};

FundRPQNotification.defaultProps = {
  displayFor: '',
  rpqNotification: '',
  getServicingLink: () => {
    // do nothing
  },
  currentPolicyDetail: null,
  updateModalState: () => {
    // do nothing
  },
  isModalOpen: false,
  fundSelectionData: null,
  fundSelectionWarningsOrErrors: null,
  rpqUTBConfig: null,
  matchedReferenceNumbers: null,
  TransferOfFunds: '',
  fundSectionHeading: '',
  ChangeInPremiumAllocation: '',
  transferTypeLabel: '',
  isCustomerWhiteListedForTFF: false,
  transferFundPendingIndicator: false
};
FundRPQNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default FundRPQNotification;
