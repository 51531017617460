import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  SurrenderChargeWrapper,
  SurrenderChargeLeftSection,
  SurrenderChargeRightSection,
  SurrenderChargeIconWrapper,
  SurrenderImage,
  SurrenderChargeText,
  SurrenderChargeSectionWrapper,
  SectionFooterButton,
  SurrenderLinkLabel
} from '../../detailPageStyle';
import { FooterLinkImageWrapper, IconRightImg } from '../SectionStyle';
import * as icons from '../../../assets/images/SurrenderCharge';
import iconRight from '../../../assets/images/Icon_right.svg';
import ContactLink from '../../../common/contactLink/contactLink';
import EECModal from './EECModal';
import { trackEvents, trackViews } from '../../../utils/tealiumUtils';
import getContentInSpecificLang from '../../../utils/localeUtils';

const SurrenderChargeSubSection = (props, context) => {
  const {
    template: { iconSrcType, contentKey, showLinkRef, showContactNumber },
    tableData,
    currentPlan,
    langObj
  } = props;
  const {
    intl: { formatMessage }
  } = context;
  const [isModalOpen, updateModalState] = useState(false);

  const onCancelClick = (updateModalStateFunc, isModalOpenState) => {
    updateModalStateFunc(!isModalOpenState);
  };

  trackViews({
    viewName: 'earlyEncashmentDescription',
    formField5: `message: ${_.toLower(
      getContentInSpecificLang(contentKey, 'en', langObj)
    )}`
  });

  return (
    <>
      {isModalOpen && (
        <EECModal
          isModalOpen={isModalOpen}
          onClose={() => onCancelClick(updateModalState, isModalOpen)}
          tableList={tableData}
          currentPlan={currentPlan}
          langObj={langObj}
        />
      )}
      <SurrenderChargeSectionWrapper>
        <SurrenderChargeWrapper>
          <SurrenderChargeLeftSection showLink={showLinkRef}>
            <SurrenderChargeIconWrapper>
              <SurrenderImage
                aria-hidden="true"
                alt=""
                src={icons[iconSrcType]}
              />
            </SurrenderChargeIconWrapper>

            <SurrenderChargeText>
              {showContactNumber ? (
                <ContactLink
                  sentenceWithContact={contentKey}
                  contactsList={['lifeContactUsNumber']}
                  typeOfCall="general enquiry"
                />
              ) : (
                formatMessage({ id: contentKey })
              )}
            </SurrenderChargeText>
          </SurrenderChargeLeftSection>
          {showLinkRef && (
            <SurrenderChargeRightSection>
              <SectionFooterButton
                id="surrenderChargeButton"
                onClick={() => {
                  trackEvents({
                    eventName: 'eecButtonClick',
                    eventContent: 'learn how it work'
                  });
                  return updateModalState(!isModalOpen);
                }}
              >
                <SurrenderLinkLabel>
                  {formatMessage({ id: 'encashmentRefLabel' })}
                </SurrenderLinkLabel>
                <FooterLinkImageWrapper>
                  <IconRightImg src={iconRight} aria-hidden="true" alt="" />
                </FooterLinkImageWrapper>
              </SectionFooterButton>
            </SurrenderChargeRightSection>
          )}
        </SurrenderChargeWrapper>
      </SurrenderChargeSectionWrapper>
    </>
  );
};

SurrenderChargeSubSection.propTypes = {
  template: PropTypes.object.isRequired,
  showContactNumber: PropTypes.bool,
  currentPlan: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  langObj: PropTypes.object.isRequired
};

SurrenderChargeSubSection.defaultProps = {
  showContactNumber: false
};

SurrenderChargeSubSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SurrenderChargeSubSection;
