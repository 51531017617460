import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BackIcon from '../../assets/images/cevrron-right.svg';
import titleHandler from '../../utils/titleUtils';
import {
  DetailsNotification,
  NotificationRow,
  LeftNotificationRow,
  BackButton,
  ButtonWrapperOL,
  ListLI,
  ImageSpan,
  ImagePath
} from './breadCrumbStyle';
import Context from '../../app/Context';
import { trackEvents } from '../../utils/tealiumUtils';

const BreadCrumbs = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const title = formatMessage({ id: 'instruction_history' });
  titleHandler(title);

  const { policyDetailsTealium } = props;
  return (
    <Context.Consumer>
      {contextProvider => {
        const {
          applicationUrl: { forLink }
        } = contextProvider;

        return (
          <>
            <DetailsNotification>
              <NotificationRow>
                <LeftNotificationRow>
                  <ButtonWrapperOL>
                    <ListLI>
                      <Link
                        to={forLink.summary}
                        id="my_insurance_back_link"
                        onClick={() =>
                          trackEvents({
                            eventName: 'myInsurance',
                            policyDetailsTealium
                          })
                        }
                      >
                        <BackButton
                          aria-label={formatMessage({
                            id: 'BackMyInsuranceAriaText'
                          })}
                        >
                          {formatMessage({ id: 'BackMyInsurance' })}
                          <ImageSpan>
                            <ImagePath src={BackIcon} alt="" />
                          </ImageSpan>
                        </BackButton>
                      </Link>
                    </ListLI>
                    <ListLI currentPage>
                      <span aria-current="page">
                        {formatMessage({ id: 'instruction_history' })}
                      </span>
                    </ListLI>
                  </ButtonWrapperOL>
                </LeftNotificationRow>
              </NotificationRow>
            </DetailsNotification>
          </>
        );
      }}
    </Context.Consumer>
  );
};

BreadCrumbs.contextTypes = {
  intl: PropTypes.object.isRequired,
  applicationUrl: PropTypes.object
};

BreadCrumbs.propTypes = {
  policyDetailsTealium: PropTypes.object.isRequired
};

export default BreadCrumbs;
