import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  FundDetailWrapper,
  FundTypeHeading,
  FundName,
  FundAllocation,
  FundNameSection,
  SwitchOutName,
  SwitchOutAllocation
} from '../instructionHistoryStyle';
import Currency from '../../common/currency/Currency';
import getDescription from '../../utils/reducerUtils';
import FundNameAllocation from '../templates/FundNameAllocation';
import SlaNoteSection from '../templates/SlaNoteSection';
import { checkAmountZero } from '../instructionUtils';
import { doAND } from '../../common/util/commonUtil';

const SwitchInvestmentChoices = (props, context) => {
  const {
    intl: { formatMessage, locale }
  } = context;
  const { funds, currency, status, source } = props;

  if (_.isEmpty(funds)) {
    return null;
  }

  const { fundSwitchInfo } = funds;
  const { switchOutFundList = [], switchInFundList = [] } = fundSwitchInfo;

  const getTotalAmount = () => {
    return switchOutFundList.reduce((acc, value) => {
      const amount = _.get(value, 'amount');
      return acc + amount * 100;
    }, 0);
  };

  const totalSwitchOutAmount = getTotalAmount() / 100;

  const checkAmountLabel = (amount, switchOutLabel) =>
    !checkAmountZero(amount) ? switchOutLabel : 'switchInValue';

  const getInvestmentAmount = (amount, percent) => {
    if (doAND(source === 'PIB', status === 'C')) {
      return (
        <FormattedMessage
          id={checkAmountLabel(amount, 'switchOutValWithAmount')}
          values={{
            percentage: _.toNumber(percent),
            amountCurrency: (
              <Currency currencyCode={`${currency}`} amount={amount} />
            )
          }}
        />
      );
    }
    if (doAND(source === 'PIB', status !== 'C')) {
      return (
        <FormattedMessage
          id={checkAmountLabel(amount, 'switchOutValue')}
          values={{
            percentage: _.toNumber(percent),
            amountCurrency: (
              <Currency currencyCode={`${currency}`} amount={amount} />
            )
          }}
        />
      );
    }
    return null;
  };

  const investmentChoiceAmountSourceType = (amount, percent) => {
    if (source === 'P2G') {
      return (
        <FormattedMessage
          id="switchInValue"
          values={{
            percentage: _.toNumber(percent)
          }}
        />
      );
    }
    return getInvestmentAmount(amount, percent);
  };

  const showTotalAmount = () => {
    const amountLabel =
      status === 'C' ? 'totalSwitchOutLabel' : 'approxSwitchOutLabel';
    return (
      <FundNameSection>
        <SwitchOutName>{formatMessage({ id: amountLabel })}</SwitchOutName>
        <SwitchOutAllocation>
          <Currency
            currencyCode={`${currency}`}
            amount={totalSwitchOutAmount}
          />
        </SwitchOutAllocation>
      </FundNameSection>
    );
  };

  return (
    <>
      {switchOutFundList.length > 0 && (
        <FundDetailWrapper>
          <FundTypeHeading>
            {formatMessage({ id: 'switchOutLabel' })}
          </FundTypeHeading>
          {_.map(switchOutFundList, switchOut => {
            const { fundName, amount, percent } = switchOut;
            return (
              <FundNameSection>
                <FundName>{getDescription(locale, fundName)}</FundName>
                <FundAllocation hideSpace>
                  {investmentChoiceAmountSourceType(amount, percent)}
                </FundAllocation>
              </FundNameSection>
            );
          })}
          {doAND(
            !Number.isNaN(totalSwitchOutAmount),
            totalSwitchOutAmount > 0,
            source === 'PIB'
          ) && showTotalAmount()}
        </FundDetailWrapper>
      )}
      <FundDetailWrapper>
        <FundTypeHeading>
          {formatMessage({ id: 'switchInLabel' })}
        </FundTypeHeading>
        {_.map(switchInFundList, switchIn => {
          const { fundName, percent } = switchIn;
          return (
            <FundNameAllocation
              locale={locale}
              fundName={fundName}
              percent={_.toNumber(percent)}
              labelId="switchInValue"
            />
          );
        })}
        <SlaNoteSection labelId="slaText" status={status} />
      </FundDetailWrapper>
    </>
  );
};

SwitchInvestmentChoices.propTypes = {
  funds: PropTypes.object,
  currency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
};

SwitchInvestmentChoices.defaultProps = {
  funds: {}
};
SwitchInvestmentChoices.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default SwitchInvestmentChoices;
