import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';
import {
  getOptimisticNotesObj,
  getInterestRateObj,
  getListOfExplanationNotes,
  orderExplanationNotes,
  getFieldsReferredValues,
  getAmountLabel,
  checkPlanCodes,
  checkIfPlanCodeMatch
} from './DisclaimerUtils';
import {
  DisclaimerNotesList,
  DisclaimerNotesListItem,
  DisclaimerHeading,
  OptimisticNotesWrapper,
  OptimisticNoteListItem,
  DisclaimerLink,
  DisclaimerLinkText,
  ExplanationNotesIntroduction
} from './DisclaimerStyle';
import SurrenderChargeConfig from './SurrenderChargeConfig';
import { checkContentInContext } from '../../utils/localeUtils';

/**
 *  List of functions and their purpose:
 * renderExplanationNotes - pass the api explanationNotes array and the explanatoryNotesConfigurations(from Json)
 * describe: Function is expected to return the list of explanation notes. It will basically Loop through the api explanationNotes array,
 * And if the respective  definition present, add those disclaimerNoteText to the array,
 * then for the next configuration add the new textdefinition if not present already,
 * Now we need to order it, so we pass the  list of notes that we have determined from above  operations.
 * Now that we have the ordered list, we would loop it and display as per the types.
 *
 */
const checkDisclaimerNoteHasTableToDisplay = (
  fieldsReferred,
  reprojectionsDetails
) => {
  return (
    <SurrenderChargeConfig
      fieldsReferred={fieldsReferred}
      reprojectionsDetails={reprojectionsDetails}
    />
  );
};

const getDisclaimerComponentForPlanCode = (
  exceptionalPlanCodeCheck,
  reprojectionsDetails,
  amountLabel,
  contentKey
) => {
  const {
    planCodesToCheck,
    ifMatchFound,
    fieldParent,
    fieldName
  } = exceptionalPlanCodeCheck;
  let disclaimerNotesListItem = '';
  const ifPlanCodeMatch = checkIfPlanCodeMatch(
    planCodesToCheck,
    reprojectionsDetails,
    `${fieldParent}.${fieldName}`
  );
  if (ifPlanCodeMatch) {
    disclaimerNotesListItem = (
      <DisclaimerNotesListItem id={ifMatchFound}>
        <FormattedHTMLMessage id={ifMatchFound} values={amountLabel} />
      </DisclaimerNotesListItem>
    );
  } else {
    disclaimerNotesListItem = (
      <DisclaimerNotesListItem id={contentKey}>
        <FormattedHTMLMessage id={contentKey} values={amountLabel} />
      </DisclaimerNotesListItem>
    );
  }
  return disclaimerNotesListItem;
};
const renderExplanationNotes = (
  reprojectionsDetails,
  explanatoryNotesConfigurationsUI,
  context
) => {
  const {
    reprojectionDetails: { explanatoryNoteConfig: explanatoryNoteConfigAPI }
  } = reprojectionsDetails;
  const {
    explanatoryDefinitions,
    explanatoryText,
    order
  } = explanatoryNotesConfigurationsUI;
  const {
    intl: { messages, formatMessage }
  } = context;
  const explanationNotesList = getListOfExplanationNotes(
    explanatoryNoteConfigAPI,
    explanatoryDefinitions
  );
  const orderedExplanationNotesList = orderExplanationNotes(
    explanationNotesList,
    order
  );

  return _.map(orderedExplanationNotesList, explanationNotes => {
    const {
      type,
      fieldsReferred,
      contentKey,
      linkProps = null,
      defaultContentKeyIfNoMatch = '',
      exceptionalPlanCodeCheck = {}
    } = explanatoryText[explanationNotes];
    let disclaimerNotesListItem = '';
    switch (type) {
      case 'textWithRef': {
        const fieldsReferredValues = getFieldsReferredValues(
          reprojectionsDetails,
          fieldsReferred,
          context
        );
        if (fieldsReferredValues.length === fieldsReferred.length) {
          disclaimerNotesListItem = (
            <DisclaimerNotesListItem id={contentKey}>
              <FormattedHTMLMessage
                id={contentKey}
                values={getInterestRateObj(fieldsReferredValues)}
              />
            </DisclaimerNotesListItem>
          );
        }
        break;
      }
      case 'textWithOutRef':
        disclaimerNotesListItem = (
          <DisclaimerNotesListItem id={contentKey}>
            <FormattedHTMLMessage id={contentKey} />
          </DisclaimerNotesListItem>
        );
        break;
      case 'hasLink': {
        const { href, label, rel, target } = linkProps;
        const valuesDefn = {};
        valuesDefn[label] = (
          <DisclaimerLink
            href={checkContentInContext(href, messages)}
            aria-label={formatMessage({
              id: label
            })}
            rel={rel}
            target={target}
          >
            <DisclaimerLinkText>
              {formatMessage({ id: label })}
            </DisclaimerLinkText>
          </DisclaimerLink>
        );
        disclaimerNotesListItem = (
          <DisclaimerNotesListItem id={contentKey}>
            <FormattedMessage id={contentKey} values={valuesDefn} />
          </DisclaimerNotesListItem>
        );
        break;
      }

      case 'table':
        disclaimerNotesListItem = (
          <DisclaimerNotesListItem id={contentKey}>
            <FormattedHTMLMessage id={contentKey} />
            {checkDisclaimerNoteHasTableToDisplay(
              fieldsReferred,
              reprojectionsDetails
            )}
          </DisclaimerNotesListItem>
        );
        break;
      case 'textForAmountLabel': {
        const amountLabel = getAmountLabel(
          fieldsReferred,
          reprojectionsDetails,
          context
        );
        if (!_.isEmpty(exceptionalPlanCodeCheck)) {
          disclaimerNotesListItem = getDisclaimerComponentForPlanCode(
            exceptionalPlanCodeCheck,
            reprojectionsDetails,
            amountLabel,
            contentKey
          );
        } else {
          disclaimerNotesListItem = (
            <DisclaimerNotesListItem id={contentKey}>
              <FormattedHTMLMessage id={contentKey} values={amountLabel} />
            </DisclaimerNotesListItem>
          );
        }
        break;
      }
      case 'textWithCondition': {
        const ifPlanCodeMatch = checkPlanCodes(
          fieldsReferred,
          reprojectionsDetails
        );
        if (ifPlanCodeMatch) {
          disclaimerNotesListItem = (
            <DisclaimerNotesListItem id={contentKey}>
              <FormattedHTMLMessage id={contentKey} />
            </DisclaimerNotesListItem>
          );
        } else if (defaultContentKeyIfNoMatch) {
          disclaimerNotesListItem = (
            <DisclaimerNotesListItem id={defaultContentKeyIfNoMatch}>
              <FormattedHTMLMessage id={defaultContentKeyIfNoMatch} />
            </DisclaimerNotesListItem>
          );
        }
        break;
      }

      default:
        break;
    }
    return disclaimerNotesListItem;
  });
};

const renderOptimisticNote = (
  optimisticNote,
  reprojectionsDetails,
  selectedFutureBenefitsTab
) => {
  return _.map(optimisticNote, optimisticListItem => {
    const optimisticObj = getOptimisticNotesObj(
      optimisticListItem,
      reprojectionsDetails,
      selectedFutureBenefitsTab
    );
    const { contentId, fieldsReferredValues = [] } = optimisticObj;
    if (fieldsReferredValues.length > 0 && contentId) {
      return (
        <OptimisticNoteListItem
          id={contentId}
          selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        >
          <FormattedHTMLMessage
            id={contentId}
            values={getInterestRateObj(fieldsReferredValues)}
          />
        </OptimisticNoteListItem>
      );
    }
    if (contentId) {
      return (
        <OptimisticNoteListItem
          id={contentId}
          selectedFutureBenefitsTab={selectedFutureBenefitsTab}
        >
          <FormattedHTMLMessage id={contentId} />
        </OptimisticNoteListItem>
      );
    }
    return null;
  });
};

/**
 * DisclaimerBuilder- Display Explanation notes / Optimistic Notes from the disclaimer configuration
 * renderList:
 *  a) Iterate through disclaimerNotes array and display it on UI in a <li></li> tag
 *  b) For disclaimer point 3 we are grouping the content-keys to be displayed as a paragraph
 * renderDisclaimerArray - generate a disclaimer array for cases of content-keys grouping
 * renderDisclaimerNotes - returns a li tag showing the disclaimer content-key value returned from the disclaimer configuration
 * renderParagraph - returns a list of p tags inside li tag for cases of content-grouping
 */
const DisclaimerBuilder = (props, context) => {
  const {
    disclaimerConfig: { optimisticNotes },
    explanatoryNotesConfigurations: explanatoryNotesConfigurationsUI,
    reprojectionsDetails,
    selectedFutureBenefitsTab
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const explanationNotes = renderExplanationNotes(
    reprojectionsDetails,
    explanatoryNotesConfigurationsUI,
    context
  );
  return (
    <>
      <OptimisticNotesWrapper id="optimisticNotesWrapper">
        {optimisticNotes &&
          renderOptimisticNote(
            optimisticNotes,
            reprojectionsDetails,
            selectedFutureBenefitsTab
          )}
      </OptimisticNotesWrapper>
      {explanationNotes.length > 0 && (
        <>
          <ExplanationNotesIntroduction id="disclaimerExplainationNotesIntroduction">
            {formatMessage({ id: 'disclaimerExplainationNotesIntroduction' })}
          </ExplanationNotesIntroduction>
          <DisclaimerHeading id="disclaimerExplainationNotesHeading">
            {formatMessage({ id: 'disclaimerExplainationNotesHeading' })}
          </DisclaimerHeading>
          <DisclaimerNotesList id="disclaimerNotesWrapper">
            {renderExplanationNotes(
              reprojectionsDetails,
              explanatoryNotesConfigurationsUI,
              context
            )}
          </DisclaimerNotesList>
        </>
      )}
    </>
  );
};

DisclaimerBuilder.propTypes = {
  disclaimerConfig: PropTypes.object.isRequired,
  explanatoryNotesConfigurations: PropTypes.object.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  selectedFutureBenefitsTab: PropTypes.string.isRequired
};

DisclaimerBuilder.contextTypes = {
  intl: PropTypes.object.isRequired
};
export default DisclaimerBuilder;
