import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { fetchSaasToken } from '../../../utils/saasAuthModuleUtil';

export default class SessionManager extends Component {
  constructor(props) {
    super(props);
    this.isActive = false;
    this.idleTimer = null;
  }

  componentDidMount() {
    const { minInactivityTime } = this.props;
    this.intervalTimerAction = setInterval(
      () => this.onInterval(),
      minInactivityTime
    );
  }

  onInterval() {
    if (this.isActive) {
      this.refreshSession();
    }
  }

  onAction() {
    const { minInactivityTime } = this.props;
    this.isActive = true;
    if (this.idleTimer.getElapsedTime() > minInactivityTime) {
      this.refreshSession();
    }
  }

  refreshSession() {
    const {
      minInactivityTime,
      onActionHandler,
      isIdle,
      updateIdleState
    } = this.props;
    const self = this;
    fetchSaasToken()
      .then(saasToken => {
        onActionHandler(saasToken);
      })
      .catch(error => {
        /* eslint-disable no-console */
        console.log(error);
        clearInterval(self.intervalTimerAction);
        updateIdleState(true);
      });
    if (this.idleTimer && this.intervalTimerAction && !isIdle) {
      clearInterval(this.intervalTimerAction);
      this.intervalTimerAction = setInterval(
        () => this.onInterval(),
        minInactivityTime
      );
      this.idleTimer.reset();
      this.isActive = false;
    }
  }

  render() {
    const { idleTimeout, children, isIdle } = this.props;
    return (
      <>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          timeout={idleTimeout}
          onAction={() => this.onAction()}
          startOnMount
        />
        {isIdle && children}
      </>
    );
  }
}

SessionManager.propTypes = {
  minInactivityTime: PropTypes.number.isRequired,
  idleTimeout: PropTypes.number.isRequired,
  onActionHandler: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  isIdle: PropTypes.bool.isRequired,
  updateIdleState: PropTypes.func.isRequired
};
