import React from 'react';
import PropTypes from 'prop-types';
import ServicingKeyValue from './ServicingKeyValue';
import { getFieldData } from '../instructionUtils';

const SettlementCurrency = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;

  const { paymentInfo, individualFund } = props;

  const paymentCurrencyCode = getFieldData(paymentInfo, 'paymentCurrencyCode');

  return (
    <>
      {paymentCurrencyCode && (
        <ServicingKeyValue
          label="settlementCurrency"
          fieldValue={formatMessage({ id: `currency_${paymentCurrencyCode}` })}
          removeLeftSpacing
          individualFund={individualFund}
        />
      )}
    </>
  );
};

SettlementCurrency.propTypes = {
  paymentInfo: PropTypes.object,
  individualFund: PropTypes.bool
};

SettlementCurrency.defaultProps = {
  paymentInfo: {},
  individualFund: false
};
SettlementCurrency.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SettlementCurrency;
