import styled, { css } from 'styled-components';
import { em, rem } from 'polished';
import theme from '../../common/styles/theme';
import media from '../../utils/media';
import { FontFamily } from '../../utils/font';

const onePixelSolidD9d9d9 = '1px solid #d9d9d9';
export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
`;
export const FundSectionHeader = styled(SectionHeader)`
  ${media.phone`
box-sizing: border-box;
padding: ${rem('15px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
`}
`;
export const ReturnsSectionHeader = styled(SectionHeader)`
  margin-bottom: 30px;
  align-items: flex-start;
  flex-direction: column;
  ${media.tablet`
    margin-bottom: 20px;
`}
  ${media.phone`
  box-sizing: border-box;
  padding-bottom:${rem('20px')};
  margin-bottom:0;
`}
`;
export const HeaderWrapper = styled.div`
  display: flex;
`;
export const HeaderSubText = styled.div`
  font-size: ${rem('16px')};
  line-height: ${rem('22px')};
  color: ${theme.fontColor};
  box-sizing: border-box;
  margin-top: 38px;
  width: 100%;
  ${media.tablet`
    margin-top: 35px;
`}
  ${media.phone`
font-size: ${rem('14px')};
line-height: ${rem('20px')};
margin-top: 20px;
`}
`;

export const BFWrapper = styled.div`
  margin-bottom: 10px;
`;
export const SectionSubTitle = styled.h3`
  ${FontFamily.RegularFontW01};
  margin-bottom: ${rem('25px')};
  font-size: ${rem('18px')};
  color: #575757;
`;
export const BasicPlanHeader = styled.h4`
  ${FontFamily.RegularFontW01};
  color: ${theme.fontColor};
  font-size: ${rem('22px')};
  line-height: ${rem('32px')};
  ${media.tablet`
    width:75%;
`};
  ${media.phone`
    width:100%;

  `};
`;
export const PolicyName = styled.div`
  display: block;
  margin-bottom: ${rem('19px')};
`;
export const CentralImage = styled.div`
  width: ${rem('220px')};
  height: ${rem('178px')};
  ${media.tablet`
    margin: 0 auto;
    padding: 0 0 ${rem('30px')} 0;
  `};
  ${media.phone`
  flex-grow : 1;
  padding:${rem('25px')};
  margin:0;

  `};
`;
export const SectionHeaderText = styled.h2`
  display: block;
  ${FontFamily.LightFontW01}
  font-size:${em('28px')};
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  padding-left: ${rem('16px')};
  ${media.tablet`
    font-size:${em('24px')};
  `}
  ${media.phone`
    font-size:${em('20px')};
    padding-left:${rem('10px')};
  `}
`;
export const BenefitSectionBody = styled.div`
  box-sizing: border-box;
  border-bottom: ${props =>
    props.hasRiderData ? onePixelSolidD9d9d9 : 'none'};
  padding-top: ${rem('38px')};
  padding-bottom: ${rem('30px')};
  ${media.phone`
  border-bottom: none;
  padding-bottom: 0;
  `}
`;
export const SectionBody = styled.div`
  display: flex;
  padding: 38px 0 44px 0;
  box-sizing: border-box;
  ${media.phone`
    display: block;
    padding:${rem('38px')} ${rem('0px')} ${rem('0px')} ${rem('0px')};
  `}
`;
export const FullPaidSectionBody = styled.div`
  display: flex;
  padding: ${rem('30px')} ${rem('0px')} ${rem('0px')} ${rem('0px')};
  box-sizing: border-box;
  ${media.phone`
    display: block;
  `}
`;
export const SectionImgSpan = styled.span`
  display: block;
  width: ${rem('25px')};
  height: ${rem('25px')};
  ${media.phone`
  width: ${rem('18px')};
  height: ${rem('18px')};
  `}
`;
export const RiskLevelImgSpan = styled.span`
  display: block;
  width: ${rem('14px')};
  height: ${rem('14px')};
  margin-right: 8px;
  ${media.phone`
  width: ${rem('12px')};
  height: ${rem('12px')};
  `}
`;
export const SectionImgPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  ${media.phone`
  width:100%;
  `}
`;
export const SectionFooter = styled.div`
  padding: ${rem('40px')} 0 0 0;
  box-sizing: border-box;
  clear: both;
  border-top: ${props =>
    props.borderNone ? `0 solid transparent;` : onePixelSolidD9d9d9};
  ${media.phoneToMiniTablet`
    border-top:none;
    padding:0;
  `}
`;
export const FundAllocation = styled.div`
  padding: ${rem('20px')} 0 0 0;
  border-top: ${props => (props.borderNeeded ? onePixelSolidD9d9d9 : 'none')};
  ${media.tablet`
  padding: ${rem('30px')} 0 0 0;
`}
  ${media.phone`
padding: 0;
border-top:none;
`}
`;
export const PortFolioWrapper = styled.div`
  padding: 0 0 ${rem('30px')} 0;
  ${media.tablet`
  padding: 0 0 ${rem('30px')} 0;
`}
  ${media.phone`
   padding: 0;
`}
`;
export const SectionFooterLinkWrapper = styled.div`
${FontFamily.RegularFontW01}
  color: ${theme.fontColor};
  line-height: ${rem('24px')};
  font-size: ${rem('16px')};
  padding-top: ${rem('30px')};
  display:flex;
  justify-content:flex-end;
   &:empty{
    padding-top:0;
  }
    ${media.phone`
    align-items: center;
    flex-direction: column;
    padding:${props =>
      props.isPaymentSection
        ? `${rem('15px')} ${rem('15px')} ${rem('15px')} 0`
        : 0};
    padding:${props =>
      props.isFundSection ? `0 ${rem('15px')} 0 ${rem('15px')}` : 0};
`}
`;
export const SectionFooterLink = styled.a`
  padding:0
  display: flex;
  cursor: pointer;
  background: white;
  border: none;
  color: ${theme.fontColor};
  text-decoration:none;
  justify-content: flex-end
  align-items: center;
  margin: 0;
  &:focus,&:hover{
    outline: 1px dotted black;
  }
  &:nth-child(-n + 2) {
    margin:0 ${rem('39px')} 0 0;
  }
  &:last-child{
    margin: 0;
  }
  ${media.phoneToMiniTablet`
  margin: 0;
  width:100%;
  align-items: center;
`}
${media.phone`
&:nth-child(-n + 2) {
  margin:0 0 ${rem('12px')} 0;
  }
`}
`;
export const FooterLinkLabel = styled.span`
  display: block;
  margin-right: ${rem('9px')};
  ${media.phone`
    width:100%;
  `}
`;
export const IconRightImg = styled.img`
  width: 100%;
  height: 100%;
`;
export const FooterLinkImageWrapper = styled.span`
  width: ${rem('8px')};
  height: ${rem('12px')};
  display: block;
  position: relative;
  top: -1px;
  ${media.phone`
  width:${rem('9.7px')};
  height:${rem('16px')};
  top:0;
`}
`;
export const BenefitSectionFooter = styled.div`
  padding: 31px 0 31px 0;
  box-sizing: border-box;
  border-bottom: ${props =>
    props.hasRiderData && !props.suppressBorder ? onePixelSolidD9d9d9 : 'none'};
  clear: both;
  ${media.phone`
  padding: ${props =>
    props.hasRiderData && !props.supressPadding
      ? `20px 0 20px 0`
      : `0 0 20px 0`};
  ${props => props.hasMedicalStack && `padding : 20px 0 20px 0`};
  border-top: none;
  `}
`;
export const SectionWrapper = styled.div`
  display: block;
  align-items: center;
  margin: ${rem('30px')} 0 ${rem('30px')} 0;
  padding: ${rem('38px')} ${rem('40px')} ${rem('30px')} ${rem('40px')};
  background: ${theme.whiteColor};
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  ${media.mediumTablet`
    margin: ${rem('24px')} 0 ${rem('24px')} 0;
    padding: ${rem('30px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};
  `}
  ${media.phone`
    margin: ${rem('15px')} 0 ${rem('15px')} 0;
    padding: ${rem('15px')} ${rem('15px')} ${rem('20px')} ${rem('15px')};
  `}
`;
export const FundSectionWrapper = styled(SectionWrapper)`
  display: block;
  align-items: center;
  margin: ${rem('30px')} 0 ${rem('30px')} 0;
  padding: ${rem('38px')} ${rem('40px')} ${rem('30px')} ${rem('40px')};
  background: ${theme.whiteColor};
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  ${media.mediumTablet`
    margin: ${rem('24px')} 0 ${rem('24px')} 0;
    padding: ${rem('30px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};
  `}
  ${media.phone`
    margin: ${rem('15px')} 0 ${rem('15px')} 0;
    box-sizing: border-box;
    padding: 0;
  `}
`;
export const ReturnsSectionWrapper = styled(SectionWrapper)`
  display: block;
  align-items: center;
  margin: ${rem('30px')} 0 ${rem('30px')} 0;
  padding: ${rem('38px')} ${rem('40px')} ${rem('30px')} ${rem('40px')};
  background: ${theme.whiteColor};
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  ${media.mediumTablet`
  margin: ${rem('24px')} 0 ${rem('24px')} 0;
  padding: ${rem('30px')} ${rem('30px')} ${rem('20px')} ${rem('30px')};
`}
  ${media.phone`
  margin: ${rem('15px')} 0 ${rem('15px')} 0;
  box-sizing: border-box;
  padding:${rem('15px')}  ${rem('15px')}  ${rem('15px')}  ${rem('20px')};
`}
`;
export const BenefitsSubSectionWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  ${media.tablet`
  flex-wrap : wrap;
  `};
  ${media.phone`
  flex-direction: column;
  `};
`;
export const LeftSection = styled.div`
  flex-grow: 1;
  width: 45%;
  ${media.tablet`
  width : 60%;
  `};
  ${media.phone`
  width : 100%;
  `};
`;
export const CentreSection = styled.div`
  flex-grow: 1;
  width: 25%;
  margin-top: ${rem('-16px')};
  ${media.tablet`
  width : 40%;
  margin-top:${rem('-26px')};
  `};
  ${media.phone`
  display:flex;
  width : 100%;
  border-bottom: 1px solid ${theme.bgGreyColor};
  margin-top:0;
  `};
`;
export const RightSection = styled.div`
  dl {
    &:after {
      display: none;
    }

    padding-left: ${rem('0px')};
  }
  flex-grow: 1;
  width: 30%;
  ${media.tablet`
    padding-top:${rem('25px')};
    padding-bottom:${rem('25px')};
    border-top: 1px solid #d9d9d9;
    width:100%;
    :empty{
      border-top:none;
      padding-top:0;
    }
    dl{
      border-bottom:none;
      padding: 0 !important;
      display: inline-block;
      width: 50%;
      margin-bottom:${rem('20px')};
    }

  `};
  ${media.phone`
  padding-top:${rem('0px')};
  border-top: none;
  width : 100%;
    dl{
      display:flex;
      width:100%;
      padding: ${rem('11px')} ${rem('11px')} !important;
      border-bottom: 1px solid ${theme.bgGreyColor};
      margin-bottom:${rem('0px')};
    }
    dl > span {
      width: 50%;
      text-align: right;
      justify-content: flex-end;
    }
    dl:last-child{
      ${props => !props.hasCoverageName && 'border-bottom:none;'}
    }
  `};
`;
export const ModalDescWrapper = styled.div`
  box-sizing: border-box;
`;
export const ModalNoteWrapper = styled.div`
  padding: 20px 0;
  box-sizing: border-box;
  border-top: 1px solid ${theme.lightGreyColor};
  border-bottom: 1px solid ${theme.lightGreyColor};
`;
export const NoteText = styled.div`
${FontFamily.RegularFontW01}
font-size: ${rem('14px')};
line-height:${rem('22px')};
`;
export const ModalListHeaderSection = styled.div``;
export const ModalListDescWrapper = styled.dl`
padding: ${rem('17px')} ${rem('21px')} ${rem('19px')} ${rem('20px')};
box-sizing: border-box;
background: ${theme.greyBG};
margin: 0;
${FontFamily.BoldFontW01}
color:${theme.fontColor};
font-size: ${rem('14px')};
line-height:${rem('22px')};
display: flex;
${media.phone`
  display:none;
`}
`;
export const ModalListSection = styled.dl`
  padding: ${rem('20px')} ${rem('26px')} ${rem('23px')} ${rem('20px')};
  font-size: ${rem('14px')};
  ${FontFamily.RegularFontW01}
  box-sizing: border-box;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${theme.lightGreyColor};
  ${media.phone`
flex-direction: column;
padding: ${rem('24px')} ${rem('6px')};
`}
`;
export const ListDescLevel = styled.dt`
  margin: 0;
  width: ${rem('228px')};
  line-height: ${rem('22px')};
  word-break: break-word;
  padding-right: 10px;
  box-sizing: border-box;
`;
export const ListLevelDesc = styled.dd`
  margin: 0;
  line-height: ${rem('22px')};
  word-break: break-word;
  padding-right: 10px;
  box-sizing: border-box;
  width: calc(100% - ${rem('228px')});
`;
export const RiskListType = styled.dt`
  margin: 0;
  width: ${rem('228px')};
  line-height: ${rem('22px')};
  color: ${theme.downIconColor} ${media.phone`
width:100%;
`};
`;
export const RiskListTypeText = styled.dt`
  margin: 0;
  line-height: ${rem('22px')};
  color: ${theme.fontColor};
  width: calc(100% - ${rem('228px')});
  flex: 1;
  ${media.phone`
width:100%;
`}
`;
export const RiskLableWrapper = styled.span`
  display: flex;
  align-items: center;
  ${media.phone`
margin-bottom: 12px;
`}
`;
export const RiskTypeColor = styled.span`
  ${FontFamily.BoldFontW01}
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  height: 20px;
  width: 20px;
  background-color: ${props => props.riskTypeColor};
  color: #ffffff;
  font-size: 12px;
`;

export const ReturnsSectionBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${rem('20px')};
`;
export const ReturnsTotalSectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  ${media.phone`
  margin-bottom: 10px
`};
`;

export const ReturnsAnnuitySection = styled.div`
  padding: ${props =>
    props.id === 'totalPremiumPaid'
      ? `${rem('15px')} ${rem('25px')}`
      : `${rem('30px')} ${rem('25px')}`}
  background-color: #f8f8f8;
  margin-top:${props =>
    props.id === 'totalPremiumPaid' ? `${rem('0px')}` : `${rem('15px')}`};
  ${media.tablet`
  padding: ${props =>
    props.id === 'totalPremiumPaid'
      ? `${rem('15px')} ${rem('25px')}`
      : `${rem('25px')} ${rem('25px')}`}
  margin-top:${rem('15px')}
  `};

  ${media.phone`
  padding: ${props =>
    props.id === 'totalPremiumPaid'
      ? `${rem('15px')} ${rem('15px')}`
      : `${rem('20px')} ${rem('15px')}`}
  `};
`;
export const OptionSectionWrapper = styled.div`
  padding: ${rem('30px')} 0 0 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${theme.disableText};
  dl {
    width: ${rem('254px')};
  }
  ${media.tablet`
  padding: ${rem('12px')} 0 0 0;
  dl {
    width:50%;
    padding-right: ${rem('35px')};
  }
  `};

  ${media.phone`
  padding: ${rem('12px')} 0 0 0;
  dl {
    width:100%;
    dt{
      width:50%;
    }
  }
  `};
`;
export const OptionSection = styled.div`
 button{
  ${FontFamily.RegularFontW01}
  font-size:${em('18px')};
  line-height:${rem('24px')};
  color:${theme.fontColor};
  padding: 12px 0;
  box-sizing: border-box;
  border-top:1px solid ${theme.disableText};
  align-items: inherit;
  text-align: left;
  span[data-attribute="img"] {
    height: inherit;
    position:inherit;
  }
 }
`;

export const OptionToggleLabel = styled.span`
  // padding: 15px 0;
  box-sizing: border-box;
  display: block;
  width: calc(100% - 11px);
`;
export const ReturnsAnnuityFlexWrap = styled.div`
  display: flex;
  dl {
    flex-grow: 1;
  }
  ${media.tablet`
  flex-direction : column;
  `};
  ${media.phone`
  flex-direction : column;
  `};
`;

export const AnnuityInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const LastMonthlyPaymentWrapper = styled.div`
  margin-top: 20px;
`;

export const AnnuityBasicInformation = styled.div`
  display: flex;
`;

export const ReturnsIndividualFieldLabel = styled.div`
  margin-bottom: 10px;
  ${FontFamily.RegularFontW01}
  font-size: 16px;
  line-height: 24px;
  color: #333333;

  ${media.tablet`
  width:50%;
  flex-grow : 1;
  `}

  ${media.phone`
  width:50%;
  flex-grow : 1;
  line-height : 22px;
  `}
`;

export const ReturnsIndividualFieldValue = styled.div`
  font-size: 18px;
  color: #333333;
  ${props =>
    props.amountField
      ? `${FontFamily.RegularFontW01}`
      : `${FontFamily.BoldFontW01}`};
  line-height: ${rem('24px')};
  ${props =>
    props.amountField
      ? css`
          span span {
            ${FontFamily.BoldFontW01};
            margin-right: ${rem('3px')};
          }
        `
      : css`
          span span {
            ${FontFamily.BoldFontW01}
          }
        `}


  ${media.tablet`
  text-align:right;
  width:50%;
  flex-grow : 1
`}

  ${media.phone`
  text-align:right;
  width:50%;
  flex-grow : 1
font-size: 16px;
line-height : 22px;
`}
`;

export const ReturnsIndividualFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  padding-right: ${rem('20px')};
  ${media.tablet`
  margin-bottom:${rem('18px')};
  width : 100%
  flex-direction : row;
  `}

  ${media.phone`
  margin-bottom:${rem('20px')};
  width : 100%
  flex-direction : row;
  padding-right:0;
`}
`;

export const StaticAnnuityBFStatement = styled.p`
  display: block;
  width: 100%;
  margin-top: ${rem('20px')};
`;

export const ReturnsAnnuityBeforeHeader = styled.h3`
  color: #333333;
  line-height: 32px;
  font-size: 22px;
  ${FontFamily.RegularFontW01};
  margin-bottom: ${rem('20px')};
`;

export const TotalPaymentsWrapper = styled.div``;
export const ReturnsAfterAnnuityFieldsAndStatement = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  &:last-child {
    width: 100%;
  }
  ${media.tablet`
width:100%;
`};
`;

export const ReturnsAfterAnnuityFields = styled.div`
  display: flex;
  ${media.tablet`
flex-direction : column;
width:100%;
`};
  ${media.phone`
flex-direction : column;
width:100%;
`};
`;

export const AnnuityAFStatement = styled.p`
color: #575757;
line-height: 20px;
font-size: 14px;
${FontFamily.RegularFontW01}
margin-top : ${rem('20px')};
${media.tablet`margin : 0`};
${media.phone`margin : 0; line-height: ${rem('18px')};
font-size: 12px;`};

`;

// return section payment made style start

export const PaymentWrapper = styled.div`
  width: ${rem('358px')};
  display: flex;
  flex-direction: column;
  ${media.tablet`
  width:100%;
  margin-bottom: ${rem('21px')};
`};
  ${media.phone`
  margin-bottom: ${rem('17px')};
`};
`;

export const LabelText = styled.span`
  ${FontFamily.RegularFontW01}
  font-size: ${em('16px')};
  color: ${theme.fontColor};
  line-height: ${rem('23px')};
  margin-bottom: ${rem('15px')};
  ${media.tablet`
    margin-bottom: ${rem('10px')};
`};
${media.phone`
  font-size: ${em('14px')};
  line-height: ${rem('22px')};
  margin-bottom: ${rem('7px')};
`};
`;
export const FieldWrapper = styled.div`
  display:flex;
  align-items: center;
  ${FontFamily.LightFontW01}
  font-size: ${em('36px')};
  line-height: ${rem('56px')};
  color: ${theme.fontColor};
  ${media.phone`
  font-size: ${em('28px')};
  line-height: ${rem('24px')};
  margin-bottom: ${rem('7px')};
`};
`;
export const FieldValue = styled.span`
  color: ${theme.fontColor};
  line-height: ${rem('56px')};
`;
export const FieldDependent = styled.span`
  color: ${theme.greyValue};
`;

// return section payment made style end
export const LastMonthlyAnnuityPaymentWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: ${rem('20px')};
  color: ${theme.greyNumberColor};

  span {
    line-height: ${rem('20px')};
  }

  ${media.phone`
  span {
    line-height: ${rem('18px')};
  }
  font-size: 12px;
  `};
`;

export const LastMonthlyAnnuityAmountCurrency = styled.span`
  ${FontFamily.BoldFontW01};
  span span {
    margin: 0 ${rem('5px')} 0 0;
  }
`;

export const ToggleSectionWrapper = styled.div``;

export const ToggleTitle = styled.button`
  width:100%
  cursor: pointer;
  display: flex;
  background: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  text-decoration: none;
  border-bottom: 1px solid ${theme.lightGreyColor};
  padding-bottom:${rem('10px')};
  text-align: initial;
  &:focus, &:hover {
    outline: 1px dotted #333;
  }
`;
export const ToggleImgWrapper = styled.span`
  width: 11px;
  height: 7px;
  position: relative;
  top: 2px;
  padding-left: ${rem('8px')};
  ${media.mediumTablet`
    padding-left:${rem('10px')};
  `}
`;
export const ToggleDescription = styled.div``;
export const LoanFieldWrapper = styled.div`
  dl:first-child {
    border-top: 1px solid ${theme.fontColor};
  }
  dl:last-child {
    border-bottom: 1px solid ${theme.fontColor};
  }
`;
export const ReturnsValuationDateWrapper = styled.div`
  margin-bottom: ${rem('19px')};

  span {
    ${FontFamily.RegularFontW01};
    color: ${theme.fontColor};
  }
  ${media.tablet`
    margin-bottom: ${rem('7px')};
  `}
  ${media.phone`
  margin-bottom: 0
  `}
`;

export const ReturnsValuation = styled.dl`
  display: flex;
  margin: 0;
  ${media.phone`
    display:block;
  `}
`;

export const ReturnsValuationLeft = styled.dt`
  width: 50%;
  flex-grow: 1;
  text-align: left;
  ${media.phone`
  margin-bottom: 0
  `}
`;

export const ReturnsValuationRight = styled.dt`
  width: 50%;
  flex-grow: 1;
  text-align: right;
  font-size: ${rem('16px')};
`;

export const WhoIsCoveredHeader = styled.div`
  margin-bottom: ${rem('30px')};
  display: flex;
  ${media.phone`
margin-bottom : ${rem('0px')};
box-sizing: border-box;
padding: ${rem('15px')} ${rem('15px')} ${rem('12px')} ${rem('15px')};
`}
`;

export const LinkWrapper = styled.div`
  display: flex;
  > a {
    align-items: center;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 43px 0;
  ${media.tablet`
  padding: 20px 0;
  `}
  ${media.phoneToMiniTablet`
  flex-direction: column;
  padding: 4px 0;
  `}
`;

export const RowLeftDiv = styled.div`
  display: flex;
  width: 40%;
  box-sizing: border-box;
  padding:0 72px 0 24px;
  @media (max-width: 1090px) {
    padding:0 30px 0 0;
  }
}
${media.tablet`
  width:50%;
padding:0 30px 0 0;
`}
  ${media.phoneToMiniTablet`
    width: 100%;
    padding: 0;
  `}
`;
export const RowRightDiv = styled.div`
  display: block;
  width: 60%;
  ${media.tablet`
  width:50%
`}
  ${media.phoneToMiniTablet`
    width: 100%;
  `}
`;

export const MedicalGraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.phoneToMiniTablet`
  width: 100%;
  dt,dd{
    width: 100%;
  }
  dd{
    justify-content: left;
    text-align: left;
  }
  `}
  dl {
    padding: 0 !important;
    width: 100% !important;

    ${media.phoneToMiniTablet`
    flex-direction: column !important;
    border-bottom: none;
    margin-bottom: 16px;
  `}
  }
  dd#amountLabelDD {
    span span {
      ${FontFamily.MediumFontW01};
      font-size: ${rem('35px')};
      line-height: ${rem('42px')};
      ${media.tablet`
      font-size: ${rem('28px')};
      line-height: ${rem('36px')};
    `}
    }
    span {
      ${FontFamily.LightFontW01};
      font-size: ${rem('22px')};
      line-height: ${rem('31px')};
      ${media.tablet`
      font-size: ${rem('19px')};
      line-height: ${rem('27px')};
    `}
    }
  }
`;

export const ProgressBarRow = styled.div`
  display: block;
  box-sizing: border-box;
  padding: 20px 0;
  ${media.phoneToMiniTablet`
    padding:0 0 20px 0;
  `}
`;
