import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../../../utils/showIf';
import { formatCurrency } from '../../../utils/formatter';

const FundTableNumber = function FundTableNumber(props) {
  const { fieldData, decimalRange } = props;

  if (!fieldData) {
    return '-';
  }
  return (
    <>
      <ShowIf condition={!!fieldData}>
        {formatCurrency(fieldData, decimalRange)}
      </ShowIf>
    </>
  );
};

FundTableNumber.propTypes = {
  fieldData: PropTypes.array.isRequired,
  decimalRange: PropTypes.number
};
FundTableNumber.defaultProps = {
  decimalRange: 2
};
export default FundTableNumber;
