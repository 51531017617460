import React, { useState } from 'react';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import { isMiniMobile } from '../utils/domUtils';
import { getTooltipContents, xAxisLabelFrequncyHandler } from './chartUtils';
import {
  CustomTooltip,
  ChartDiv,
  LoaderDiv
} from '../reprojection/reprojectionPageStyle';
import { trackEvents } from '../utils/tealiumUtils';
import {
  captureChartEvents,
  chartDataForTealium
} from '../reprojection/utils/reprojectionTealiumHandlers';
import ChartLoader from '../common/loader/ChartLoader';

const getChartDimension = isMobile => ({
  width: '100%',
  paddingLeft: isMobile ? '63' : '75',
  paddingRight: isMobile ? '28' : '30',
  height: isMobile ? '250px' : '364px'
});

const getGridLines = showGridLines =>
  showGridLines ? { count: 6 } : { color: 'transparent' };

const canShowTooltip = (row, pos) =>
  typeof row === 'number' && pos.x !== 0 && pos.y !== 0;

const getLeftTooltipPos = (pos, tooltipPosition) =>
  pos.x + tooltipPosition.clientWidth + 10 < document.body.clientWidth
    ? `${pos.x}px`
    : `${document.body.clientWidth - 40 - tooltipPosition.clientWidth}px`;

const getTopTooltipPos = (pos, tooltipPosition) =>
  pos.y + tooltipPosition.clientHeight + 10 < document.body.clientHeight
    ? `${pos.y + 20}px`
    : `${document.body.clientHeight + 5 - tooltipPosition.clientHeight}px`;

const isPointsVisible = function isPointsVisible(pointsVisible) {
  return isMiniMobile() ? false : pointsVisible;
};

const getPointSize = function getPointSize(pointsVisible, pointSize) {
  return pointsVisible ? pointSize : 0;
};

const {
  lineChart: { chartEventType }
} = chartDataForTealium;

const LineCharts = props => {
  const [age, setAge] = useState(0);
  const [isCallTealium, showTealium] = useState(false);
  const {
    data,
    rawData,
    colors,
    seriesObj,
    pointsVisible,
    pointSize,
    showVerticalGridLines,
    showHorizontalGridLines,
    isInteractive,
    hAxisTitle,
    hAxisPrefix,
    vAxisTitle,
    chartFields,
    imagePath,
    xAxisField,
    policyCurrency,
    tooltipId,
    vAxisTicks,
    maxAllowedVal,
    selectedTabName
  } = props;
  const pos = {
    x: 0,
    y: 0
  };

  const setPosition = e => {
    pos.x = e.clientX;
    pos.y = e.clientY;
  };

  const chartDimension = getChartDimension(isMiniMobile());
  const ticks = xAxisLabelFrequncyHandler(data.length - 1, data);

  const content = getTooltipContents(
    data,
    rawData,
    chartFields,
    age,
    imagePath,
    xAxisField,
    policyCurrency,
    maxAllowedVal,
    tooltipId,
    selectedTabName
  );

  return (
    <>
      <ChartDiv
        id={`lineChart-${tooltipId}`}
        onMouseOver={e => setPosition(e)}
        onFocus={e => setPosition(e)}
      >
        <Chart
          data={data}
          chartType="ComboChart"
          height={chartDimension.height}
          loader={
            <LoaderDiv>
              <ChartLoader height={chartDimension.height} />
            </LoaderDiv>
          }
          options={{
            isStacked: false,
            pointsVisible: isPointsVisible(pointsVisible),
            pointSize: getPointSize(pointsVisible, pointSize),
            hAxis: {
              title: hAxisTitle,
              format: hAxisPrefix,
              ticks,
              gridlines: getGridLines(showVerticalGridLines)
            },
            vAxis: {
              title: vAxisTitle,
              gridlines: getGridLines(showHorizontalGridLines),
              ticks: vAxisTicks
            },
            chartArea: {
              backgroundColor: {
                fill: '#fff'
              },
              width: chartDimension.width,
              left: chartDimension.paddingLeft,
              right: chartDimension.paddingRight
            },
            tooltip: {
              isHtml: true,
              trigger: 'none'
            },
            series: seriesObj,
            colors,
            legend: { position: 'none' }
          }}
          chartEvents={[
            isInteractive && {
              eventName: 'ready',
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                const tooltipPosition = document.getElementById(tooltipId);
                google.visualization.events.addListener(
                  chart,
                  'onmouseover',
                  e => {
                    const { row } = e;
                    if (canShowTooltip(row, pos)) {
                      if (!isCallTealium) {
                        captureChartEvents(
                          data[row + 1][0],
                          maxAllowedVal,
                          trackEvents,
                          tooltipId,
                          isMiniMobile() ? 'click' : chartEventType
                        );
                      }
                      setAge(row);
                      showTealium(true);
                      tooltipPosition.style.display = 'block';
                      tooltipPosition.style.left = getLeftTooltipPos(
                        pos,
                        tooltipPosition
                      );
                      tooltipPosition.style.top = getTopTooltipPos(
                        pos,
                        tooltipPosition
                      );
                    }
                  }
                );
                // added select event to capture selected point-cursor on
                // line chart and remove circular selection
                function selectHandler() {
                  if (chart.getSelection()[0]) {
                    chart.setSelection([]);
                  }
                }
                google.visualization.events.addListener(
                  chart,
                  'select',
                  selectHandler
                );
                const chartEventListener = function chartEventListener() {
                  captureChartEvents(null, null, trackEvents({}), '', '');
                  tooltipPosition.style.display = 'none';
                  tooltipPosition.style.left = 'none';
                  tooltipPosition.style.top = 'none';
                  chart.setSelection([]);
                };
                google.visualization.events.addListener(
                  chart,
                  'onmouseout',
                  chartEventListener
                );
                google.visualization.events.addListener(
                  chart,
                  'scroll',
                  chartEventListener
                );

                google.visualization.events.addListener(
                  chart,
                  'touchmove',
                  chartEventListener
                );

                // handle scroll event for full page
                const scrollListener = function scrollListener() {
                  tooltipPosition.style.display = 'none';
                  tooltipPosition.style.left = 'none';
                  tooltipPosition.style.top = 'none';
                  chart.setSelection([]);

                  document.removeEventListener('scroll', scrollListener, true);
                };
                document.addEventListener('scroll', scrollListener, true);
                document.addEventListener('touchmove', scrollListener, true);
              }
            }
          ]}
        />
      </ChartDiv>
      <CustomTooltip id={tooltipId}>{content}</CustomTooltip>
    </>
  );
};

LineCharts.propTypes = {
  data: PropTypes.array.isRequired,
  rawData: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  seriesObj: PropTypes.array.isRequired,
  policyCurrency: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
  pointsVisible: PropTypes.bool,
  pointSize: PropTypes.number,
  showVerticalGridLines: PropTypes.bool,
  showHorizontalGridLines: PropTypes.bool,
  isInteractive: PropTypes.bool,
  vAxisTitle: PropTypes.string,
  hAxisTitle: PropTypes.string,
  hAxisPrefix: PropTypes.string,
  chartFields: PropTypes.array,
  imagePath: PropTypes.string,
  xAxisField: PropTypes.string,
  vAxisTicks: PropTypes.array,
  maxAllowedVal: PropTypes.number.isRequired,
  selectedTabName: PropTypes.string.isRequired
};
LineCharts.defaultProps = {
  pointsVisible: true,
  pointSize: 5,
  showVerticalGridLines: false,
  showHorizontalGridLines: true,
  isInteractive: true,
  vAxisTitle: '',
  hAxisTitle: '',
  hAxisPrefix: '',
  chartFields: null,
  imagePath: null,
  xAxisField: null,
  vAxisTicks: []
};
export default LineCharts;
