import styled from 'styled-components';
import { rem } from 'polished';
import theme from '../../common/styles/theme';
import media from '../../utils/media';

export const ToggleSectionWrapper = styled.div``;

export const ToggleTitle = styled.button`
  width:100%
  cursor: pointer;
  display: flex;
  background: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  text-decoration: none;
  border-bottom: ${props =>
    props.hideBorderBottom ? 'none' : `1px solid ${theme.lightGreyColor}`};
  padding-bottom: ${props => (props.hideBorderBottom ? `0` : `${rem('24px')}`)};
  text-align: initial;
  &:focus, &:hover {
    outline: 1px dotted #333;
  }
`;
export const ToggleImgWrapper = styled.span`
  width: 16px;
  height: 10px;
  position: relative;
  top: 2px;
  padding-left: ${rem('8px')};
  ${media.mediumTablet`
    padding-left:${rem('10px')};
  `}
`;
export const ToggleDescription = styled.div``;

export const ImagePath = styled.img`
  height: 100%;
  width: 100%;
`;
