import styled from 'styled-components';
import { em, rem } from 'polished';
import theme from './theme';
import media from '../../utils/media';

export const ModalWrapper = styled.div`
  box-sizing: border-box;
  @media only screen and (min-width: 960px) {
    height: calc(100% - 3.75em - 2.5em - 2.85em);
  }
  ${media.mediumTablet`
            height: calc(100% - 3.375em - 2.25em - 2.4em);
   `};
  /*iphone 6*/
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
    height: calc(100vh - 100px - 77px);
    overflow-y: auto;
  }
  /*iphone 5*/
  @media only screen and (min-device-width: 350px) and (max-device-width: 568px) {
    height: calc(100vh - 100px - 30px);
    overflow-y: auto;
  }
  /*iphone 5 portrait*/
  @media (max-width: 320px) {
    height: calc(100vh - 100px - 80px);
  }
`;

export const ContentWrapper = styled.div`
  overflow-y: auto;
  box-sizing: border-box;
  max-height: calc(100vh - 72px - 245px);
  ${props => props.modalHeading && 'padding-top:20px'}
  @media screen and (max-height: 480px) {
    max-height: calc(100vh - 72px - 145px);
  }
  @media only screen and (max-width: 667px) {
    max-height: 100%;
  }
`;

export const ParagraphDiv = styled.div`
  font-size: ${em('14px')};
  color: ${theme.fontColor};
  letter-spacing: 0;
  line-height: ${rem('18px')};
  margin-bottom: ${rem('20px')};
  ${media.tablet`
     font-size: ${em('16px')};
	 line-height: ${rem('18px')};
    `} ${media.phone`
        font-size: ${em('14px')};
        line-height: ${rem('21px')};
        margin-bottom: ${rem('10px')};
    `};
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    box-sizing: border-box;
  }
  padding: ${props =>
    props.hideHorizonatalLine ? `0` : `${rem('20px')} 0 0 0`};
  border-top: ${props =>
    props.hideHorizonatalLine || props.hideButton
      ? `none`
      : `2px solid ${theme.lightGreyColor}`};
  ${media.phone`
     padding: ${rem('20px')} 0 0 0;
    `};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
`;

export const CancelButton = styled.div`
  float: left;
`;

export const ContinueButton = styled.div`
  float: right;
`;
export const Button = styled.div`
  float: ${props => props.align};
`;
