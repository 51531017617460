import styled from 'styled-components';
import { rem } from 'polished';

const TooltipContainer = styled.span`
  padding-left: 4px;
  > span {
    bottom: -1px;
  }
  div[role='tooltip'] {
    line-height: ${rem('18px')};
  }
  &[data-targetid='tooltip_detail_disclaimer']
    div[aria-hidden='true']
    div[role='tooltip'] {
    top: -1000px !important;
    position: absolute;
  }
`;
export default TooltipContainer;
