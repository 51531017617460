import styled from 'styled-components';

export const MainContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const AccessibleHidden = styled.span`
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  width: 1px;
  height: 1px;
  position: absolute !important;
`;
