import React from 'react';
import PropTypes from 'prop-types';
import riderIconMapping from './data/RiderIconMapping';
import getDescription from '../../../utils/reducerUtils';
import { RiderDesc } from '../../detailPageStyle';

const RiderDescription = function RiderDescription(props, context) {
  const {
    intl: { formatMessage, locale }
  } = context;

  const {
    fieldData,
    dependentFieldData,
    labelContent
  } = props;
  let contentDescription = '';
  let riderLabelContent = `${labelContent}_${riderIconMapping.generic.label}`;
  contentDescription = formatMessage({ id: riderLabelContent });

  if (fieldData && fieldData.length) {
    contentDescription =
      getDescription(locale, fieldData) ||
      formatMessage({ id: riderLabelContent });
  } else if (dependentFieldData) {
    const riderCategory = dependentFieldData.toLowerCase();
    if (riderCategory in riderIconMapping) {
      riderLabelContent = `${labelContent}_${riderIconMapping[riderCategory].label}`;
    }
    contentDescription = formatMessage({ id: riderLabelContent });
  }

  if (!contentDescription) {
    return <></>;
  }
  return <RiderDesc>{contentDescription}</RiderDesc>;
};

RiderDescription.propTypes = {
  dependentFieldData: PropTypes.string,
  fieldData: PropTypes.array.isRequired,
  labelContent: PropTypes.string.isRequired
};

RiderDescription.defaultProps = {
  dependentFieldData: ''
};
RiderDescription.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default RiderDescription;
