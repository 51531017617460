import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Tooltip } from 'ui-component-library';
import theme from '../styles/theme';
import TooltipContainer from './tooltipWrapperStyles';
import getTealiumSectionName from '../../tealium/teliumSectionName';
import {
  trackEvents,
  createEventActionContent
} from '../../utils/tealiumUtils';
import { isTab } from '../../utils/domUtils';

const TooltipWrapper = (
  {
    contentKey,
    id,
    sectionHeading,
    labelContent,
    isFormatHtml,
    maxWidth,
    isReprojectionsTooltip
  },
  context
) => {
  const {
    intl,
    intl: { formatMessage }
  } = context;
  let toolTipText = '';
  const eventAction = 'tooltip';
  let tooltipData = {};
  const headerContent = getTealiumSectionName(sectionHeading);

  tooltipData = createEventActionContent(
    eventAction,
    contentKey,
    headerContent,
    isReprojectionsTooltip
  );

  if (sectionHeading && labelContent) {
    toolTipText = `${formatMessage({ id: sectionHeading })} ${formatMessage({
      id: labelContent
    })} ${formatMessage({ id: 'helpIcon' })} `;
  }

  const getDynamicWidtForCuttingTooltip = () =>
    labelContent === 'monthlyGuaranteedAnnuity_label' && isTab() ? 140 : 150;

  if (
    contentKey &&
    !!intl.messages[contentKey] &&
    intl.messages[contentKey] !== ''
  ) {
    return (
      <TooltipContainer data-targetid={id}>
        <Tooltip
          targetID={id}
          icon="circle-help"
          iconColor={theme.greyNumberColor}
          iconSize={18}
          ariaLabel={toolTipText}
          heightOfSticky={200}
          maxWidth={maxWidth || getDynamicWidtForCuttingTooltip()}
          onOpen={() =>
            trackEvents({ eventName: 'detailsTooltip', tooltipData })
          }
        >
          {isFormatHtml ? (
            <FormattedHTMLMessage id={contentKey} />
          ) : (
            formatMessage({ id: contentKey })
          )}
        </Tooltip>
      </TooltipContainer>
    );
  }
  return null;
};
TooltipWrapper.contextTypes = {
  intl: PropTypes.object.isRequired
};
TooltipWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  contentKey: PropTypes.string,
  sectionHeading: PropTypes.string.isRequired,
  labelContent: PropTypes.string.isRequired,
  isFormatHtml: PropTypes.bool,
  maxWidth: PropTypes.number,
  isReprojectionsTooltip: PropTypes.bool
};
TooltipWrapper.defaultProps = {
  contentKey: null,
  isFormatHtml: false,
  maxWidth: 0,
  isReprojectionsTooltip: false
};

export default TooltipWrapper;
