import { select, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  setReprojectionsInfo,
  setReprojectionsSelectedTab,
  setReprojectionsWarningsOrErrors,
  endLoader
} from '../app/appActions';
import { GET_REPROJECTIONS } from '../actionTypes';
import prepareReprojectionStore from '../utils/reprojectionsUtils';
import { filterTabList } from '../reprojection/utils/reprojectionDataUtils';
import { getRoutesPagePath } from '../utils/routeUtils';
import isCustomerWhiteListed from '../utils/customerUtils';
import { prepareServiceConfig } from '../utils/sagaUtils';
import { trackViews } from '../utils/tealiumUtils';

const getCustomerIdentifier = state => state.customer.customerIdentifier;
const getCustomerEligibilityList = state => state.customer.functionEligibility;
const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;

export function* getReprojections(action) {
  try {
    const encodePolicyNumber = action && action.payload;
    const customerIdentifier = yield select(getCustomerIdentifier);
    const customerEligibilityList = yield select(getCustomerEligibilityList);
    const configurationObj = yield select(getConfigurations);
    if (customerIdentifier) {
      /**
       * Will redirect to  the  policy summary page if the customer is not whitelisted for reprojections.
       * customer eligibility: "Reprojection_Web"
       */
      if (!isCustomerWhiteListed('Reprojection_Web', customerEligibilityList)) {
        const { summary } = getRoutesPagePath().forLink;
        window.location.href = summary;
      }
      const {
        genericConfig: { commonHeaders },
        serviceConfig: { reprojections },
        reprojectionTabList
      } = configurationObj;
      const saasAuth = yield select(getSaasToken);
      const headers = {
        ...commonHeaders,
        Authorization: `Bearer ${saasAuth}`,
        'X-HSBC-CustomerId': customerIdentifier
      };
      const reprojectionsConfig = prepareServiceConfig(
        reprojections,
        reprojections.url.replace('{encode-policynumber}', encodePolicyNumber),
        headers
      );
      const reprojectionsResponse = yield call(axios, reprojectionsConfig);
      const reprojectionsResponseData = reprojectionsResponse.data;
      const updatedByEncodeId = prepareReprojectionStore(
        reprojectionsResponseData
      );

      /**
       *  tabList: Contains the list of tabs to be displayed for reprojection.
       */
      const {
        reprojections: reprojectionsForEncodeId
      } = reprojectionsResponseData;
      const {
        policyOverview: { productCategory }
      } = reprojectionsForEncodeId;
      const reprojectionTabListByCategory =
        reprojectionTabList[productCategory] || reprojectionTabList.DEFAULT;
      const tabList = filterTabList(
        reprojectionTabListByCategory,
        reprojectionsForEncodeId
      );
      const { tabIdentifier: selectedTab } = tabList[0];

      yield put(setReprojectionsSelectedTab(selectedTab));
      yield put(setReprojectionsInfo(updatedByEncodeId));

      trackViews({
        viewName: 'onReprojectionsPageLoad',
        pageTealiumDetails: reprojectionsResponseData.reprojections,
        selectedTab
      });

      /**
       * Checking if there is any warning available in the API's response, if there is any, then updating that warning code in the Redux store.
       */

      const reprojectionsWarningsOrErrors = reprojectionsResponseData.warnings;
      if (
        reprojectionsWarningsOrErrors &&
        reprojectionsWarningsOrErrors.length > 0
      ) {
        /**
         * Dispatching an action which would save the warning code in the Redux store.
         */
        const { warningMessage, warningCode } = reprojectionsWarningsOrErrors;
        yield put(
          setReprojectionsWarningsOrErrors({
            warnings: reprojectionsWarningsOrErrors
          })
        );

        trackViews({
          viewName: 'onReprojectionsPageWarningsOrErrors',
          message: warningMessage,
          messageType: warningCode
        });
      }
    }
    yield put(endLoader());
  } catch (err) {
    if (err.response.data.errors) {
      yield put(
        setReprojectionsWarningsOrErrors({ errors: err.response.data.errors })
      );

      trackViews({
        viewName: 'onReprojectionsPageWarningsOrErrors',
        message: err.response.data.errors.causes,
        messageType: err.response.data.errors.errorCode
      });
    } else {
      yield put(
        setReprojectionsWarningsOrErrors({
          errors: [{ errorCode: 'UI0001', causes: 'Invalid Request' }]
        })
      );
      trackViews({
        viewName: 'onReprojectionsPageWarningsOrErrors',
        message: 'Invalid Request',
        messageType: 'UI0001'
      });
    }
    yield put(endLoader());
  }
}

export default function* reprojectionsSaga() {
  yield takeEvery(GET_REPROJECTIONS, getReprojections);
}
