import { select, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { startLoader, endLoader, updateLoaderOpacity } from '../app/appActions';
import { GET_INSTRUCTION_DETAIL } from '../actionTypes';
import {
  addInstructionDetailsToStore,
  prepareServiceConfigPAPI,
  instructionDetailErrorHandler,
  addErrorListToInstructionStore
} from '../utils/sagaUtils';

const getConfigurations = state => state.configurations;
const getSaasToken = state => state.saasToken;
const getInstructionList = state => state.instHistory.instHistoryResponse;

export function* getInstructionDetail(action) {
  try {
    yield put(updateLoaderOpacity(true));
    yield put(startLoader());
    const payload = action && action.payload;
    const configurationObj = yield select(getConfigurations);
    const {
      genericConfig: { commonHeaders },
      serviceConfig: { viewInstructionHistory }
    } = configurationObj;
    const saasAuth = yield select(getSaasToken);
    const headers = {
      ...commonHeaders,
      Authorization: `Bearer ${saasAuth}`
    };
    const { transCode, referenceNo, source } = payload;
    let serviceConfigUrl = viewInstructionHistory.url.replace(
      '{transCode}',
      transCode
    );
    serviceConfigUrl = serviceConfigUrl.replace('{referenceNo}', referenceNo);
    serviceConfigUrl = serviceConfigUrl.replace('{source}', source);
    const insHistoryConfig = prepareServiceConfigPAPI(
      viewInstructionHistory,
      serviceConfigUrl,
      headers
    );
    const insHistoryDetailResponse = yield call(axios, insHistoryConfig);
    const insHistoryDetailResponseData = insHistoryDetailResponse.data;
    const instructionList = yield select(getInstructionList);
    const updateInstructionHistoryList = addInstructionDetailsToStore(
      payload,
      insHistoryDetailResponseData,
      instructionList
    );
    yield put(updateInstructionHistoryList);
    yield put(endLoader());
    yield put(updateLoaderOpacity(false));
  } catch (err) {
    const instructionList = yield select(getInstructionList);
    const payload = action && action.payload;
    const {
      apiCallCount,
      transCode,
      referenceNo,
      encodedProductId,
      source
    } = payload;
    let errorCount = apiCallCount;

    if (errorCount < 2) {
      errorCount += 1;
      yield getInstructionDetail({
        payload: {
          transCode,
          referenceNo,
          encodedProductId,
          apiCallCount: errorCount,
          source
        }
      });
    } else {
      const errorDispatchList = instructionDetailErrorHandler(err);
      const updateInstructionListWithError = addErrorListToInstructionStore(
        errorDispatchList,
        instructionList,
        payload
      );
      yield put(updateInstructionListWithError);
    }
    yield put(endLoader());
    yield put(updateLoaderOpacity(false));
  }
}

export default function* instructionHistorySaga() {
  yield takeEvery(GET_INSTRUCTION_DETAIL, getInstructionDetail);
}
