/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Spinner } from 'ui-component-library';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isMiniMobile } from '../utils/domUtils';
import ModalPopup from '../common/modal/index';
import {
  OverlayHeaderStyles,
  Content,
  ModalImage,
  Useful,
  FirstRecapHeading,
  Policy,
  LocaterButton,
  IconSpan,
  NeedInfo
} from './WelcomeModalStyle';
import img from '../assets/images/Modal/group@3x.png';
import {
  eWelcomePackAck,
  getPolicyInfo,
  startLoaderEWelcome,
  getEWelcomeDocuments,
  startPolicyDetailsLoader,
  eWelcomeDownloadAll
} from '../app/appActions';
import { documentsArray, keyFeaturesArray } from './utils/eWelcomeUtils';
import getDescription from '../utils/reducerUtils';
import { personInsuredGenerator } from '../policysummary/productTemplates/templateUtils';
import getContentInSpecificLang from '../utils/localeUtils';
import { trackEvents, trackViews } from '../utils/tealiumUtils';
import DownloadErrorModal from './DownloadErrorModal';
import Currency from '../common/currency/Currency';
import AccordionSection from './AccordionSection';
import DownloadSection from './DownloadSection';

const customStyle = {
  top: '20%',
  left: '30%',
  right: '30%',
  width: '100%',
  paddingDesktop: '44px 35px 35px 35px',
  paddingTop: '44px'
};

const gotItClickHandler = (
  policyDetailPage,
  pathName,
  encodePolicyNumber,
  onCancel,
  history
) => {
  trackEvents({
    eventName: 'gotIt'
  });
  if (!policyDetailPage) {
    let path = pathName;
    if (!pathName) {
      const tempPath = window.location.pathname;
      if (tempPath.charAt(tempPath.length - 1) === '/') {
        path = `${window.location.pathname}details/`;
      } else {
        path = `${window.location.pathname}/details/`;
      }
    }
    history.push(`${path}${encodePolicyNumber}`);
  }
  onCancel();
};

const downloadAllClick = (
  encodedPolicyNumber,
  policyNum,
  documentIDs,
  totalDocs,
  policyNumber,
  setDownloadAllClicked,
  downloadAllContent,
  eWelcomeDownloadAllHandler,
  policy
) => {
  const eventContent = `download all ${totalDocs} documents`;
  if (policyNumber) {
    setDownloadAllClicked(true);
    if (!downloadAllContent) {
      eWelcomeDownloadAllHandler(
        encodedPolicyNumber,
        policyNum,
        documentIDs,
        policy
      );
    }
    trackEvents({
      eventName: 'eWelcomeDownloadAll',
      eventContent
    });
  }
};

const fetchDocs = (
  policy,
  documentsList,
  encodePolicyNumber,
  isLoading,
  startPolicyDetailsLoaderHandler,
  fetchPolicyInfo,
  startLoaderHandler,
  getEWelcomeDocument
) => {
  if (policy) {
    const { policyDetails } = policy;
    const docs = documentsList[encodePolicyNumber];
    if (isLoading === false) {
      if (!policyDetails) {
        startPolicyDetailsLoaderHandler();
        fetchPolicyInfo(encodePolicyNumber);
      }
      if (!docs) {
        startLoaderHandler();
        getEWelcomeDocument(encodePolicyNumber, policyDetails || policy);
      }
    }
  }
};

const downloadAllDocsHandler = (
  downloadAllContent,
  isDownloadAllClicked,
  setDownloadAllClicked
) => {
  if (downloadAllContent && isDownloadAllClicked) {
    const pdfString = downloadAllContent;
    const linkSource = `data:application/zip;base64,${pdfString}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = 'Policy Pack';
    downloadLink.click();
    setDownloadAllClicked(false);
  }
};

const documentErrorHandler = (
  documentsError,
  setModalOpen,
  pathName,
  encodePolicyNumber,
  onCancel,
  history,
  isModalOpen
) => {
  if (documentsError && documentsError.errors && isModalOpen) {
    setModalOpen(false);
    let path = pathName;
    if (!pathName) {
      path = `${window.location.pathname}/details/`;
    }
    history.push(`${path.replaceAll('//', '/')}${encodePolicyNumber}`);
    onCancel();
  }
};

const noDocumentsHandler = (
  docRecordList,
  setModalOpen,
  pathName,
  encodePolicyNumber,
  onCancel,
  history
) => {
  if (docRecordList && docRecordList.length === 0) {
    setModalOpen(false);
    let path = pathName;
    if (!pathName) {
      path = `${window.location.pathname}/details/`;
    }
    history.push(`${path.replaceAll('//', '/')}${encodePolicyNumber}`);
    onCancel();
  } else if (docRecordList && docRecordList.length) {
    trackViews({
      viewName: 'eWelcomeModalView'
    });
  }
};

const getPolicyFeaturesConfig = (
  policy,
  selectedLocale,
  langObj,
  eWelcomeKeyFeatureConfig
) => {
  let planCode;
  let planName;
  let productCategory;
  let involvedParties;
  let featuresConfig = [];
  let policyNumber;
  const { policyDetails = {} } = policy;
  const {
    premiumPaymentDetails: {
      premiumAmount = {},
      paymentMode: { key } = {}
    } = {}
  } = policyDetails;

  if (!_.isEmpty(policyDetails)) {
    ({
      policyOverview: {
        policyNumber,
        planCode,
        planName,
        productCategory
      } = {},
      involvedParties = []
    } = policyDetails);
  } else if (policy.policyNumber) {
    ({
      policyNumber,
      planCode,
      planName,
      involvedParties = [],
      productCategory
    } = policy);
  }
  const planNameDesc = getDescription(selectedLocale, planName);
  const insuredPerson = personInsuredGenerator({ value: involvedParties });
  const paymentModeKeyLangSpecific = getContentInSpecificLang(
    `premium_label_${key}`,
    selectedLocale,
    langObj
  );
  const paymentMode = paymentModeKeyLangSpecific;
  const { amount = '', currency = '' } = premiumAmount;

  const formatCurrencyAmount = (
    <Currency currencyCode={currency} amount={amount} />
  );

  featuresConfig = keyFeaturesArray(
    eWelcomeKeyFeatureConfig,
    planCode,
    planNameDesc,
    insuredPerson,
    productCategory
  );
  if (!planNameDesc || !insuredPerson) {
    featuresConfig = [];
  }
  return { featuresConfig, formatCurrencyAmount, policyNumber, paymentMode };
};

const WelcomeModal = ({
  isOpenModal,
  onContinue,
  onCancel,
  policyDetailPage,
  pathName,
  encodePolicyNumber: encodedPolicyNum,
  eWelcomeKeyFeatureConfig,
  eWelcomeDocumentConfig,
  selectedLocale,
  customerPolicy,
  fetchPolicyInfo,
  startLoaderHandler,
  isLoading,
  policies,
  langObj,
  documentsList,
  getEWelcomeDocument,
  startPolicyDetailsLoaderHandler,
  eWelcomeDownloadAllHandler,
  documentsWarningsOrErrors
}) => {
  const history = useHistory();
  const [isModalOpen, setModalOpen] = useState(isOpenModal);
  let encodePolicyNumber = encodedPolicyNum;
  if (!encodePolicyNumber) {
    ({
      policyOverview: { encodePolicyNumber }
    } = customerPolicy || {});
  }
  let policy = policies[encodePolicyNumber];
  let policyNumber = '';

  useEffect(() => {
    fetchDocs(
      policy,
      documentsList,
      encodePolicyNumber,
      isLoading,
      startPolicyDetailsLoaderHandler,
      fetchPolicyInfo,
      startLoaderHandler,
      getEWelcomeDocument
    );
  }, []);

  const [isDownloadAllClicked, setDownloadAllClicked] = useState(false);
  const currentDocument = documentsList[encodePolicyNumber] || {};
  const { downloadAllContent } = currentDocument;

  useEffect(() => {
    downloadAllDocsHandler(
      downloadAllContent,
      isDownloadAllClicked,
      setDownloadAllClicked
    );
  }, [downloadAllContent, isDownloadAllClicked]);

  const documentsError = documentsWarningsOrErrors[encodePolicyNumber] || {};

  useEffect(() => {
    documentErrorHandler(
      documentsError,
      setModalOpen,
      pathName,
      encodePolicyNumber,
      onCancel,
      history,
      isModalOpen
    );
  }, [documentsError]);

  let featuresConfig = [];
  if (!policy && customerPolicy && customerPolicy.policyOverview) {
    policy = {
      policyDetails: customerPolicy
    };
  }

  let formatCurrencyAmount = null;
  let paymentMode = null;

  if (policy) {
    const policyConfig = getPolicyFeaturesConfig(
      policy,
      selectedLocale,
      langObj,
      eWelcomeKeyFeatureConfig
    );
    ({
      featuresConfig,
      formatCurrencyAmount,
      policyNumber,
      paymentMode
    } = policyConfig);
  }

  const documents = documentsList[encodePolicyNumber] || {};
  const documentData = documents.documentData || {};
  const docRecordList = documents.docRecordList;

  useEffect(() => {
    noDocumentsHandler(
      docRecordList,
      setModalOpen,
      pathName,
      encodePolicyNumber,
      onCancel,
      history
    );
  }, [docRecordList]);

  const documentsConfig = documentsArray(
    eWelcomeDocumentConfig,
    docRecordList || [],
    selectedLocale,
    policy
  );

  const { totalDocuments, documentIDList, categoryConfig } = documentsConfig;

  return (
    <ModalPopup
      type="content"
      isModalOpen={isModalOpen}
      contentStyle={customStyle}
      showCancelButton={false}
      showContinueButton={isMiniMobile()}
      showDeleteIcon
      onContinue={onContinue}
      onCancel={onCancel}
      isContinueDisabled={false}
      isCancelDisabled={false}
      isFixFooter={isMiniMobile()}
      showFooterDivider={false}
      opacity={isLoading ? '0.3' : '1'}
    >
      {isLoading && (
        <Spinner color="black" center accessibilityLabel="Loading" />
      )}
      <>
        <Content data-locator-id="Policy_ReadyMsg">
          <OverlayHeaderStyles data-locator-id="policy_Ready_Msg">
            <FormattedMessage
              id="policyReady"
              defaultMessage="Your policy is now ready!"
            />
            <br />

            <Useful>
              {' '}
              <FormattedMessage
                id="useFullInformation"
                defaultMessage="Here is some useful information for you."
              />
            </Useful>
          </OverlayHeaderStyles>

          <ModalImage data-locator-id="modal-image" alt="" src={img} />
        </Content>
        {featuresConfig.length ? (
          <>
            <FirstRecapHeading>
              {' '}
              <FormattedMessage
                id="recap_heading"
                defaultMessage=" 1. Firstly, a recap of your policy."
              />
            </FirstRecapHeading>
            <NeedInfo setMargin noPadding>
              {featuresConfig.map(features => (
                <Policy key={features.featureType}>
                  {' '}
                  <IconSpan>
                    {' '}
                    <img
                      data-locator-id="icon"
                      alt=""
                      src={features.icon}
                    />{' '}
                  </IconSpan>
                  <FormattedMessage
                    id={features.featureLabel}
                    defaultMessage={features.featureName}
                    values={{
                      value: features.value
                    }}
                  />
                  {features.featureType === 'paymentInfo' && (
                    <span>
                      &#160;{paymentMode}&#160;{formatCurrencyAmount}
                    </span>
                  )}
                </Policy>
              ))}
            </NeedInfo>
          </>
        ) : null}
        <DownloadSection
          featuresConfig={featuresConfig}
          downloadAllClick={downloadAllClick}
          categoryConfig={categoryConfig}
          encodePolicyNumber={encodePolicyNumber}
          policyNumber={policyNumber}
          documentIDList={documentIDList}
          totalDocuments={totalDocuments}
          setDownloadAllClicked={setDownloadAllClicked}
          downloadAllContent={downloadAllContent}
          eWelcomeDownloadAllHandler={eWelcomeDownloadAllHandler}
          policy={policy}
          documentData={documentData}
        />
        <AccordionSection langObj={langObj} />
        <LocaterButton
          id="locaterButton"
          data-locator-id="locaterButton"
          label={<FormattedMessage id="getItText" defaultMessage="Got it" />}
          className="redBtn"
          onClick={() =>
            gotItClickHandler(
              policyDetailPage,
              pathName,
              encodePolicyNumber,
              onCancel,
              history
            )
          }
        />
      </>
      <DownloadErrorModal
        selectedDocId={isDownloadAllClicked ? policyNumber : ''}
        onCancelDownloadError={() => setDownloadAllClicked(false)}
      />
    </ModalPopup>
  );
};
WelcomeModal.propTypes = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  isOpenModal: PropTypes.bool,
  policyDetailPage: PropTypes.bool,
  pathName: PropTypes.string,
  encodePolicyNumber: PropTypes.string,
  eWelcomeKeyFeatureConfig: PropTypes.object.isRequired,
  eWelcomeDocumentConfig: PropTypes.object.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  customerPolicy: PropTypes.object,
  fetchPolicyInfo: PropTypes.func.isRequired,
  startLoaderHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  policies: PropTypes.object.isRequired,
  langObj: PropTypes.object.isRequired,
  documentsList: PropTypes.object.isRequired,
  getEWelcomeDocument: PropTypes.func.isRequired,
  startPolicyDetailsLoaderHandler: PropTypes.func.isRequired,
  eWelcomeDownloadAllHandler: PropTypes.func.isRequired,
  documentsWarningsOrErrors: PropTypes.object.isRequired
};
WelcomeModal.defaultProps = {
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  isOpenModal: true,
  policyDetailPage: false,
  pathName: '',
  encodePolicyNumber: '',
  customerPolicy: null
};
const mapStateToProps = state => {
  const {
    configurations: { eWelcomeKeyFeatureConfig, eWelcomeDocumentConfig },
    eWelcomePolicyDocuments: {
      isEWelcomeLoading,
      isPolicyDetailsLoading,
      documentsList,
      documentsWarningsOrErrors
    },
    policy: { byEncodeId },
    content: { selectedLocale, langObj }
  } = state;
  return {
    eWelcomeKeyFeatureConfig,
    eWelcomeDocumentConfig,
    selectedLocale,
    langObj,
    isLoading: isEWelcomeLoading || isPolicyDetailsLoading,
    documentsList,
    documentsWarningsOrErrors,
    policies: byEncodeId
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEWelcomePackAck: eWelcomePackAck,
      fetchPolicyInfo: policyEncodeID => getPolicyInfo(policyEncodeID),
      startLoaderHandler: startLoaderEWelcome,
      getEWelcomeDocument: (encodePolicyNumber, policy) =>
        getEWelcomeDocuments({ encodePolicyNumber, policy }),
      startPolicyDetailsLoaderHandler: startPolicyDetailsLoader,
      eWelcomeDownloadAllHandler: (
        encodePolicyNumber,
        policyNumber,
        documentIDList,
        policy
      ) =>
        eWelcomeDownloadAll({
          encodePolicyNumber,
          policyNumber,
          documentIDList,
          policy
        })
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);
