import React from 'react';
import PropTypes from 'prop-types';

const Tab = props => {
  const { children } = props;
  return <div>{children} </div>;
};

Tab.propTypes = {
  children: PropTypes.element.isRequired
};

export default Tab;
