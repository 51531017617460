import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ShowIf from '../../../utils/showIf';
import { OverviewItemWrapper, OverviewItem } from '../../detailPageStyle';
import { currencyMapper } from '../../../utils/formatter';

const FundCurrencyDefinition = function FundCurrencyDefinition(props) {
  const { fieldData, labelContent, transferFundPendingIndicator } = props;

  if (
    !fieldData ||
    (typeof fieldData !== 'object' && !fieldData.currency) ||
    transferFundPendingIndicator
  ) {
    return null;
  }
  const { currency } = fieldData;
  const symbol = currencyMapper(currency);
  const currencyLabel = `currency_${currency}`;
  return (
    <>
      <ShowIf condition={!!fieldData && !!currency && !!symbol}>
        <OverviewItemWrapper>
          <OverviewItem id="fundCurrency">
            <FormattedMessage id={labelContent} />
            {` `}
            {symbol}
            <FormattedMessage id="currency_Equals" />
            <FormattedMessage id={currencyLabel} defaultMessage={currency} />
          </OverviewItem>
        </OverviewItemWrapper>
      </ShowIf>
    </>
  );
};

FundCurrencyDefinition.propTypes = {
  fieldData: PropTypes.object.isRequired,
  labelContent: PropTypes.string.isRequired,
  transferFundPendingIndicator: PropTypes.bool.isRequired
};

export default FundCurrencyDefinition;
